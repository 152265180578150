import React, { useMemo } from "react";
import styled from "styled-components";
import { OrderData, ORDER_TIME_TYPE, ORDER_STATE_TYPE } from "records/OrderData";
import { ShopData } from "records/ShopData";
import { PendingOrderList } from "./PendingOrderList";

const Container = styled.div``;

type Props = {
  orderList: OrderData[];
  shopData: ShopData;
  handleClick: (orderId: string) => void;
};

export const PendingOrderListContainer: React.FC<Props> = React.memo(
  ({ orderList, shopData, handleClick }) => {
    const pendingOrderList = useMemo(
      () => OrderData.getSplittedPendingOrderList(orderList),
      [orderList],
    );

    const existsApprovedOrder = useMemo(
      () => pendingOrderList.approved.length > 0,
      [pendingOrderList],
    );

    const existsUnapprovedOrder = useMemo(
      () => pendingOrderList.unapproved.length > 0,
      [pendingOrderList],
    );

    return (
      <Container>
        {ShopData.isAcceptingReservedOrder(shopData) ? (
          <>
            {existsApprovedOrder ? (
              <PendingOrderList
                type="approved"
                orderList={pendingOrderList.approved}
                handleClick={handleClick}
              />
            ) : undefined}
            {existsUnapprovedOrder ? (
              <PendingOrderList
                type="unapproved"
                orderList={pendingOrderList.unapproved}
                handleClick={handleClick}
              />
            ) : undefined}
          </>
        ) : (
          <>
            {existsUnapprovedOrder ? (
              <PendingOrderList
                type="unapproved"
                orderList={pendingOrderList.unapproved}
                handleClick={handleClick}
              />
            ) : undefined}
            {existsApprovedOrder ? (
              <PendingOrderList
                type="approved"
                orderList={pendingOrderList.approved}
                handleClick={handleClick}
              />
            ) : undefined}
          </>
        )}
      </Container>
    );
  },
);
