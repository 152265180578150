import React, { useState, useMemo, useCallback, useEffect } from "react";
import { batch, useSelector, useDispatch } from "react-redux";
import { ListRange } from "react-virtuoso";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { SubmittableOptionSetType } from "records/ShopItemOptionSet";
import { ShopItemParentCategory } from "records/ShopItemParentCategory";
import { API_KEY } from "apis";
import { systemOpenedModal } from "modules/app/actions";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import {
  userAccessedToPageThatNeedsShopData,
  userAccessedToPageThatNeedsShopItems,
  userAccessedToPageThatNeedsShopItemOptions,
  userAccessedToPageThatNeedsShopItemParentCategories,
  userSubmittedStopOrderByItems,
  userSubmittedRestartOrderByItems,
  userSubmittedStopReleaseByItems,
  userSubmittedStopOrderByOptionSets,
  userSubmittedRestartOrderByOptionSets,
  userSubmittedStopReleaseByOptionSets,
} from "modules/shop/actions";
import {
  ShopModel,
  ShopItemSubmitType,
  SHOP_ITEM_SUBMIT_TYPE,
  DEFAULT_SELECTED_PARENT_CATEGORY_ID,
} from "modules/shop/model";
import {
  shopItemsSelector,
  optionSetListSelector,
  shopItemParentCategoriesSelector,
} from "modules/shop/selectors";
import { colorsConst } from "styles/const";
import { TabButton } from "components/atoms/TabButton";
import { Text } from "components/atoms/Text";
import { TextInput } from "components/atoms/FormFields/TextInput";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { ShopItemParentCategorySelect } from "components/molecules/ShopItemParentCategorySelect";
import { Article } from "components/organisms/Article";
import { ShopItemList } from "components/organisms/ShopItemList";
import { ShopOptionSetList } from "components/organisms/ShopOptionSetList";
import { ShopItemsModal } from "components/organisms/ShopItemsModal";
import { BulkUpdateOptionStatusButtonContainer } from "components/organisms/BulkUpdateOptionStatusButtonContainer";
import { OrderItemImageDialog } from "components/organisms/OrderDetail/OrderItemImageDialog";

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const ScrollContainer = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const TabButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1em;
`;

const CategorySelectContainer = styled.div`
  padding: 0 1em 1em;
`;

const CategorySelectLabel = styled.div`
  margin-bottom: 0.25em;
`;

const SearchContainer = styled.div`
  position: relative;
  padding: 0 1em 1em;
`;

const SearchIconContainer = styled.div`
  position: absolute;
  left: 1.5em;
  top: 50%;
  margin-top: -1em;
  line-height: 1;
`;

const SearchInput = styled(TextInput).attrs({ type: "text" })`
  padding: 0.5em 0.5em 0.5em 2em;
  border: none;
`;

const SubButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1em;
`;

const SoldOutButton = styled.label`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 0.25em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
  border: 1px solid ${colorsConst.BORDER.DEFAULT};
  border-radius: 4px;
`;

const SoldOutCheck = styled.input.attrs({ type: "checkbox" })`
  margin-right: 0.5em;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
`;

type TabType = "ITEM" | "OPTION";

const isConnectedApiSelector = isConnectedApiSelectorFactory([
  API_KEY.GET_SHOP_DATA,
  API_KEY.GET_SHOP_ITEM_LIST,
  API_KEY.GET_SHOP_OPTION_SET_LIST,
  API_KEY.GET_PARENT_CATEGORY_LIST,
]);

const isSubmittingSelector = isConnectedApiSelectorFactory([
  API_KEY.RESTART_ORDER_BY_ITEM,
  API_KEY.STOP_ORDER_BY_ITEM,
  API_KEY.STOP_RELEASE_BY_ITEM,
  API_KEY.RESTART_ORDER_BY_OPTION_SET,
  API_KEY.STOP_ORDER_BY_OPTION_SET,
  API_KEY.STOP_RELEASE_BY_OPTION_SET,
]);

type Props = {};

export const ShopItemsTemplate: React.FC<Props> = React.memo(() => {
  const loading = useSelector(isConnectedApiSelector);
  const submitting = useSelector(isSubmittingSelector);
  const shopItems = useSelector(shopItemsSelector);
  const optionSetList = useSelector(optionSetListSelector);

  const parentCategories = useSelector(shopItemParentCategoriesSelector);

  const [tabType, setTabType] = useState<TabType>("ITEM");
  const [searchWord, setSearchWord] = useState<string>("");
  const [soldOutFlag, setSoldOutFlag] = useState<boolean>(false);
  const [selectedParentCategoryId, setSelectedParentCategoryId] = useState<number>(
    DEFAULT_SELECTED_PARENT_CATEGORY_ID,
  );

  const [visibleRange, setVisibleRange] = useState<ListRange>({
    startIndex: 0,
    endIndex: 0,
  });

  const soldOutLength = useMemo(
    () =>
      tabType === "ITEM"
        ? shopItems.reduce((acc, crr) => acc + crr.item_list.filter(i => i.sold_out_flag).length, 0)
        : optionSetList.reduce(
            (acc, crr) => acc + crr.option_list.filter(o => o.is_order_stop).length,
            0,
          ),
    [shopItems, optionSetList, tabType],
  );

  const placeholderText = useMemo(
    () => (tabType === "ITEM" ? "商品名" : "オプションセット名またはオプション名"),
    [tabType],
  );

  const canDisplayShopItemParentCategories = useMemo(
    () => tabType === "ITEM" && ShopItemParentCategory.exists(parentCategories),
    [parentCategories, tabType],
  );

  const filteredShopItems = useMemo(
    () =>
      ShopModel.getFilteredShopItemList(
        shopItems,
        searchWord,
        soldOutFlag,
        selectedParentCategoryId,
      ),
    [shopItems, searchWord, soldOutFlag, selectedParentCategoryId],
  );

  const filteredOptions = useMemo(
    () => ShopModel.getFilteredOptionList(optionSetList, searchWord, soldOutFlag),
    [optionSetList, searchWord, soldOutFlag],
  );

  const existsFilteredOptions = useMemo(
    () => filteredOptions.options.length > 0,
    [filteredOptions],
  );

  const dispatch = useDispatch();

  const handleChangeType = useCallback((type: TabType) => {
    setSearchWord("");
    setSoldOutFlag(false);
    setTabType(type);
  }, []);

  const handleChangeSearchWord = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    document.body.scrollTop = 0;
  }, []);

  const handleChangeSoldOutFlag = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSoldOutFlag(event.target.checked);
  }, []);

  const handleShopItemStatus = useCallback(
    (itemId: number, submitType: ShopItemSubmitType) => {
      switch (submitType) {
        case SHOP_ITEM_SUBMIT_TYPE.RESTART:
          dispatch(userSubmittedRestartOrderByItems(itemId, false));
          break;
        case SHOP_ITEM_SUBMIT_TYPE.STOP:
          dispatch(userSubmittedStopOrderByItems(itemId));
          break;
        case SHOP_ITEM_SUBMIT_TYPE.SUSPENSION:
          dispatch(userSubmittedStopReleaseByItems(itemId));
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  const handleShopItemOptionStatus = useCallback(
    (optionSet: SubmittableOptionSetType, submitType: ShopItemSubmitType) => {
      switch (submitType) {
        case SHOP_ITEM_SUBMIT_TYPE.RESTART:
          dispatch(userSubmittedRestartOrderByOptionSets(optionSet));
          break;
        case SHOP_ITEM_SUBMIT_TYPE.STOP:
          dispatch(userSubmittedStopOrderByOptionSets(optionSet, false));
          break;
        case SHOP_ITEM_SUBMIT_TYPE.SUSPENSION:
          dispatch(userSubmittedStopReleaseByOptionSets(optionSet));
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  const handleBulkUpdateOptionStatus = useCallback(
    (submitType: ShopItemSubmitType) => {
      dispatch(
        systemOpenedModal("CONFIRM_BULK_UPDATE_OPTION_STATUS", {
          filteredOptions,
          submitType,
        }),
      );
    },
    [dispatch, filteredOptions],
  );

  useEffect(() => {
    batch(() => {
      dispatch(userAccessedToPageThatNeedsShopData());
      dispatch(userAccessedToPageThatNeedsShopItems());
      dispatch(userAccessedToPageThatNeedsShopItemOptions());
      dispatch(userAccessedToPageThatNeedsShopItemParentCategories());
    });
  }, [dispatch]);

  return (
    <>
      <Article
        withHeader
        title="商品一覧"
        goBack="/orderList"
        withNavigation
        activePath="SHOP_ITEM"
      >
        {loading ? (
          <LoadingContainer />
        ) : (
          <Container>
            <ScrollContainer>
              <TabButtonContainer>
                <TabButton handleClick={() => handleChangeType("ITEM")} active={tabType === "ITEM"}>
                  商品
                </TabButton>
                <TabButton
                  handleClick={() => handleChangeType("OPTION")}
                  active={tabType === "OPTION"}
                >
                  オプション
                </TabButton>
              </TabButtonContainer>
              {canDisplayShopItemParentCategories ? (
                <CategorySelectContainer>
                  <CategorySelectLabel>
                    <Text size="SMALL" weight="BOLD">
                      セクションカテゴリで絞り込み
                    </Text>
                  </CategorySelectLabel>
                  <ShopItemParentCategorySelect
                    parentCategories={parentCategories}
                    selectedParentCategoryId={selectedParentCategoryId}
                    setSelectedParentCategoryId={setSelectedParentCategoryId}
                    includeAllOption={true}
                  />
                </CategorySelectContainer>
              ) : undefined}
              <SearchContainer>
                <SearchIconContainer>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIconContainer>
                <SearchInput
                  value={searchWord}
                  onChange={handleChangeSearchWord}
                  onBlur={handleBlur}
                  placeholder={`${placeholderText}を検索`}
                />
              </SearchContainer>
              <SubButtonContainer>
                <SoldOutButton htmlFor="soldOutFlag">
                  <SoldOutCheck
                    id="soldOutFlag"
                    checked={soldOutFlag}
                    onChange={handleChangeSoldOutFlag}
                  />
                  {`売り切れ(${soldOutLength}点)のみ表示`}
                </SoldOutButton>
              </SubButtonContainer>
              {tabType === "ITEM" ? (
                <ShopItemList
                  shopItems={filteredShopItems.items}
                  shopItemsCategories={filteredShopItems.categories}
                  groupCounts={filteredShopItems.groupCounts}
                  selectedParentCategoryId={selectedParentCategoryId}
                  submitting={submitting}
                  setVisibleRange={setVisibleRange}
                  handleStatus={handleShopItemStatus}
                />
              ) : (
                <ShopOptionSetList
                  options={filteredOptions.options}
                  optionSets={filteredOptions.optionSets}
                  groupCounts={filteredOptions.groupCounts}
                  submitting={submitting}
                  setVisibleRange={setVisibleRange}
                  handleStatus={handleShopItemOptionStatus}
                />
              )}
            </ScrollContainer>
          </Container>
        )}
        {tabType === "OPTION" && searchWord !== "" ? (
          <BulkUpdateOptionStatusButtonContainer
            disabled={!existsFilteredOptions}
            handleClick={handleBulkUpdateOptionStatus}
          />
        ) : undefined}
      </Article>
      <ShopItemsModal />
      <OrderItemImageDialog />
    </>
  );
});
