import React from "react";
import styled from "styled-components";
import { colorsConst, fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CountChangeButton = styled.button<{ disabled: boolean }>`
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.25em;
  appearance: none;
  ${fontSize.DEFAULT};
  font-weight: ${fontWeightsConst.BOLD};
  border-radius: 25px;
  background-color: ${colorsConst.BUTTON.BACKGROUND.WHITE};
  ${p =>
    p.disabled
      ? `
      color: ${colorsConst.BUTTON.TEXT.DISABLED};
      border: 2px solid ${colorsConst.BUTTON.TEXT.DISABLED};
  `
      : `
      color: ${colorsConst.TEXT.LINK};
      border: 2px solid ${colorsConst.TEXT.LINK};
  `}
`;

type Props = {
  canPlus: boolean;
  canMinus: boolean;
  handlePlusCount: () => void;
  handleMinusCount: () => void;
};

export const OrderDetailItemCountChange: React.FC<Props> = React.memo(
  ({ canPlus, canMinus, handlePlusCount, handleMinusCount }) => (
    <Container>
      <CountChangeButton type="button" onClick={handleMinusCount} disabled={!canMinus}>
        ー
      </CountChangeButton>
      <CountChangeButton type="button" onClick={handlePlusCount} disabled={!canPlus}>
        ＋
      </CountChangeButton>
    </Container>
  ),
);
