import produce from "immer";
import { NewsItem } from "records/NewsItem";

export type InformationState = Readonly<{
  newsList: NewsItem[];
  unreadNewsFlag: boolean;
}>;

const initialState: InformationState = {
  newsList: [],
  unreadNewsFlag: false,
};

const updateNewsList = (state: InformationState, list: NewsItem[]) =>
  produce(state, draft => {
    draft.newsList = list.map(item => NewsItem.create(item));
    draft.unreadNewsFlag = list.some(item => !item.is_read);
  });

const getNewsList = (state: InformationState) =>
  state.newsList.sort((a: NewsItem, b: NewsItem) => b.id - a.id);

const updateUnreadNewsFlag = (state: InformationState, bool: boolean) =>
  produce(state, draft => {
    draft.unreadNewsFlag = bool;
  });

export const InformationModel = Object.freeze({
  initialState,
  updateNewsList,
  getNewsList,
  updateUnreadNewsFlag,
});
