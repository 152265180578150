import { put, call, select, all } from "redux-saga/effects";
import { PROCESSING_FLAG } from "records/ProcessingFlag";
import { PrintUtils } from "utils/PrintUtils";
import { CordovaUtil } from "utils/CordovaUtil";
import {
  updateDiscoveredPrinter,
  setProcessingFlag,
  removeProcessingFlag,
  systemSetPrinterStatus,
} from "../actions";
import { enebleBluetoothSelector } from "../selectors";

export function* systemSearchPrinterSaga() {
  const enebleBluetooth: ReturnType<typeof enebleBluetoothSelector> = yield select(
    enebleBluetoothSelector,
  );
  if (CordovaUtil.isCordovaEnabled() && enebleBluetooth) {
    yield put(setProcessingFlag(PROCESSING_FLAG.SEACHING_PRINTER));
    yield put(systemSetPrinterStatus("searching"));
    const { result, error } = yield call(PrintUtils.search);
    if (result && !error) {
      yield all([
        put(systemSetPrinterStatus("discovered")),
        put(updateDiscoveredPrinter(result[0])),
      ]);
    } else {
      yield all([put(systemSetPrinterStatus()), put(updateDiscoveredPrinter())]);
    }
    yield put(removeProcessingFlag(PROCESSING_FLAG.SEACHING_PRINTER));
  }
}
