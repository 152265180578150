import React from "react";
import styled from "styled-components";
import { ShopReopenTimeValueType, displayableShopReopenTime } from "modules/shop/model";
import { selectStyle } from "components/atoms/FormFields/SelectField";

const Select = styled.select`
  ${selectStyle};
`;

const getPresentationReopenTimeText = (value: ShopReopenTimeValueType) => {
  switch (value) {
    case 0:
      return "設定なし";
    case 60:
      return "1時間後";
    case 120:
      return "2時間後";
    // skip default case
  }
};

type Props = {
  value: ShopReopenTimeValueType;
  disabled: boolean;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const ShopReopenTimeField: React.FC<Props> = React.memo(
  ({ value, disabled, handleChange }) => (
    <Select onChange={handleChange} value={value} disabled={disabled}>
      {displayableShopReopenTime.map(value => (
        <option key={value} value={value}>
          {getPresentationReopenTimeText(value)}
        </option>
      ))}
    </Select>
  ),
);
