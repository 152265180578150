import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconFindingCrew: React.FC<Props> = React.memo(({ color, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 174 147" fill="none" {...props}>
    <path
      d="M55.0399 128.885C65.0448 118.88 65.0448 102.659 55.0399 92.654C45.0349 82.6491 28.8138 82.6491 18.8089 92.654C8.80394 102.659 8.80395 118.88 18.8089 128.885C28.8138 138.89 45.0349 138.89 55.0399 128.885Z"
      fill="#FFFBFA"
    />
    <path
      d="M137.468 128.959C147.376 119.051 147.376 102.988 137.468 93.0802C127.561 83.1726 111.497 83.1726 101.59 93.0802C91.6821 102.988 91.6821 119.051 101.59 128.959C111.497 138.866 127.561 138.866 137.468 128.959Z"
      fill="#FFFBFA"
    />
    <path
      d="M97.6221 22.4792C97.6221 22.4792 96.8565 23.5578 95.1611 23.7766C93.4656 23.9923 90.5215 23.5214 90.5215 23.5214C90.5215 23.5214 90.8405 22.2331 91.6973 21.3672L97.6221 22.4792Z"
      fill="#BF3008"
    />
    <path
      d="M89.8776 29.0099C89.7105 29.0646 89.4127 29.0858 89.4127 29.0858L89.4765 29.2924C89.4765 29.2924 89.7408 29.7938 89.5676 29.9457C89.5646 29.9457 89.5646 29.9487 89.5616 29.9487C89.5525 29.9578 89.5403 29.967 89.5282 29.9761C89.519 29.9852 89.5099 29.9943 89.4978 30.0034C89.3428 30.1158 89.1392 30.2131 88.9752 30.4349C88.9721 30.4379 88.9721 30.444 88.9661 30.447C88.96 30.4561 88.9509 30.4683 88.9448 30.4774L88.1791 31.9662C88.1305 32.0573 88.0272 32.1029 87.93 32.0725C87.6261 31.9844 87.0185 31.7414 86.584 31.474C86.584 31.4709 86.5809 31.4679 86.5749 31.4679C85.2775 30.6658 83.3512 30.2404 83.099 30.3194C82.6858 30.447 82.4093 30.6536 81.9444 31.1884C81.5768 31.6077 81.1241 32.1941 80.9478 32.428C80.8992 32.4918 80.8202 32.5222 80.7443 32.5101C78.8422 32.2001 77.8821 30.2981 76.1806 27.6244C76.1077 27.512 76.1563 27.36 76.2809 27.3084C76.9828 27.0258 78.8848 25.8317 79.1917 23.9085C79.5563 21.6115 81.9262 23.2886 82.0264 23.3616C82.0295 23.3646 82.0325 23.3646 82.0356 23.3676L82.9653 24.1272C83.102 24.2396 83.3117 24.1455 83.3147 23.9662C83.3269 23.2157 83.5335 21.7269 84.8916 20.9126C84.9615 20.8701 85.004 20.7972 84.998 20.7182C84.9706 20.3354 84.9007 19.202 84.9828 18.6308C85.0709 18.0323 85.718 17.2453 85.9003 17.0357C85.9307 16.9992 85.9702 16.978 86.0158 16.9689L87.8145 16.5982C87.8905 16.583 87.9664 16.6073 88.0181 16.665C88.4678 17.1724 90.6888 19.6183 91.7462 19.3236C91.8738 19.2871 92.0014 19.3752 92.0135 19.5059L92.0652 21.3988C92.0682 21.4322 92.0652 21.4626 92.053 21.493C91.9923 21.657 91.4028 22.6992 91.0078 23.2765C90.8043 23.5742 90.8833 24.0422 91.0078 24.3824L92.0257 26.9529C92.047 27.041 92.0105 27.1382 91.9315 27.1838C91.5365 27.4056 90.9623 27.5606 90.3667 27.6092C90.2452 27.6183 90.154 27.7246 90.1662 27.8462L90.2695 28.8276C90.2665 28.8276 90.1054 28.9369 89.8776 29.0099Z"
      fill="#FFB38D"
    />
    <path
      d="M89.0392 54.5783L105.613 62.5175L106.947 60.0625C106.947 60.0625 97.1303 53.4754 92.6761 49.7686C88.4376 46.2411 89.0392 54.5783 89.0392 54.5783Z"
      fill="#FF9261"
    />
    <path
      d="M70.5936 40.9585C70.5936 40.9585 82.194 51.5958 83.9076 52.7534C87.1739 53.5707 88.9088 50.2589 90.5647 47.8009C90.5647 47.8009 79.4807 37.2943 78.5054 35.5411C77.5301 33.785 69.761 35.1066 70.5936 40.9585Z"
      fill="#BF3008"
    />
    <path
      d="M98.7832 65.879L100.904 65.0678L101.141 65.9246C103.219 65.2653 107.072 63.9375 107.072 63.9375L107.239 65.6663L101.636 67.5197L102.147 69.601L100.208 69.8927L98.7832 65.879Z"
      fill="#E0E0E0"
    />
    <path
      d="M65.4791 62.793C65.4791 62.793 79.6196 64.8955 91.2504 74.3539C91.8642 74.8522 92.3351 75.5085 92.6086 76.2529L92.9397 77.1553C93.1585 77.7508 93.0886 78.4132 92.7544 78.954C91.2778 81.33 86.7233 88.0934 79.6257 93.5837L76.3108 87.3429L77.8239 83.8518C78.4954 82.3053 79.5467 80.9532 80.8835 79.9232L82.4544 78.714L63.3066 72.3273L65.4791 62.793Z"
      fill="#2B2B2B"
    />
    <path
      d="M75.9722 104.336L81.268 106.499V108.328C81.268 108.328 77.3182 109.17 74.9817 107.523C73.0797 106.183 75.9722 104.336 75.9722 104.336Z"
      fill="#424242"
    />
    <path
      d="M43.8483 114.4C45.2806 110.795 43.5188 106.711 39.913 105.278C36.3073 103.846 32.2231 105.608 30.7908 109.213C29.3584 112.819 31.1203 116.903 34.726 118.336C38.3317 119.768 42.4159 118.006 43.8483 114.4Z"
      fill="#BDBDBD"
    />
    <path
      d="M37.3143 116.55C39.9354 116.55 42.0602 114.426 42.0602 111.805C42.0602 109.183 39.9354 107.059 37.3143 107.059C34.6932 107.059 32.5684 109.183 32.5684 111.805C32.5684 114.426 34.6932 116.55 37.3143 116.55Z"
      fill="#E0E0E0"
    />
    <path
      d="M41.5016 87.116L35.7676 111.773L36.0339 111.835L41.7679 87.178L41.5016 87.116Z"
      fill="#EEEEEE"
    />
    <path
      d="M33.1648 87.1295L32.8984 87.1914L38.6299 111.849L38.8962 111.787L33.1648 87.1295Z"
      fill="#EEEEEE"
    />
    <path
      d="M33.9228 87.0232L33.6504 87.0469L35.8377 112.269L36.1101 112.245L33.9228 87.0232Z"
      fill="#EEEEEE"
    />
    <path
      d="M25.7302 89.6205L25.4961 89.7617L38.5741 111.437L38.8083 111.296L25.7302 89.6205Z"
      fill="#EEEEEE"
    />
    <path
      d="M26.4296 89.2802L26.1777 89.3867L36.0382 112.702L36.29 112.596L26.4296 89.2802Z"
      fill="#EEEEEE"
    />
    <path
      d="M19.431 94.2972L19.252 94.5039L38.3855 111.081L38.5645 110.874L19.431 94.2972Z"
      fill="#EEEEEE"
    />
    <path
      d="M19.9938 93.7623L19.7871 93.9414L36.3663 113.072L36.5729 112.893L19.9938 93.7623Z"
      fill="#EEEEEE"
    />
    <path
      d="M14.8877 100.682L14.7812 100.934L38.0994 110.789L38.2059 110.537L14.8877 100.682Z"
      fill="#EEEEEE"
    />
    <path
      d="M15.2604 100.008L15.1191 100.242L36.7944 113.323L36.9356 113.089L15.2604 100.008Z"
      fill="#EEEEEE"
    />
    <path
      d="M12.5334 108.153L12.5098 108.426L37.7292 110.616L37.7528 110.343L12.5334 108.153Z"
      fill="#EEEEEE"
    />
    <path
      d="M12.6772 107.402L12.6152 107.668L37.2726 113.402L37.3346 113.136L12.6772 107.402Z"
      fill="#EEEEEE"
    />
    <path
      d="M37.2726 110.278L12.6152 116.012L12.6772 116.278L37.3346 110.544L37.2726 110.278Z"
      fill="#EEEEEE"
    />
    <path
      d="M37.7415 113.075L12.5195 115.242L12.5429 115.515L37.7649 113.348L37.7415 113.075Z"
      fill="#EEEEEE"
    />
    <path
      d="M36.793 110.368L15.1152 123.441L15.2564 123.676L36.9342 110.603L36.793 110.368Z"
      fill="#EEEEEE"
    />
    <path
      d="M38.1053 112.895L14.7871 122.75L14.8936 123.002L38.2118 113.147L38.1053 112.895Z"
      fill="#EEEEEE"
    />
    <path
      d="M36.3604 110.615L19.7812 129.746L19.9879 129.925L36.5671 110.794L36.3604 110.615Z"
      fill="#EEEEEE"
    />
    <path
      d="M38.3933 112.601L19.2598 129.18L19.4389 129.386L38.5724 112.807L38.3933 112.601Z"
      fill="#EEEEEE"
    />
    <path
      d="M36.0311 110.967L26.1758 134.285L26.4277 134.392L36.283 111.073L36.0311 110.967Z"
      fill="#EEEEEE"
    />
    <path
      d="M38.5652 112.24L25.4922 133.918L25.7264 134.059L38.7993 112.381L38.5652 112.24Z"
      fill="#EEEEEE"
    />
    <path
      d="M35.8233 111.415L33.6562 136.637L33.9287 136.66L36.0957 111.438L35.8233 111.415Z"
      fill="#EEEEEE"
    />
    <path
      d="M38.6344 111.843L32.9004 136.5L33.1667 136.562L38.9007 111.905L38.6344 111.843Z"
      fill="#EEEEEE"
    />
    <path
      d="M36.0398 111.852L35.7734 111.914L41.51 136.571L41.7763 136.509L36.0398 111.852Z"
      fill="#EEEEEE"
    />
    <path
      d="M38.831 111.414L38.5586 111.438L40.7459 136.659L41.0183 136.636L38.831 111.414Z"
      fill="#EEEEEE"
    />
    <path
      d="M36.0955 112.238L35.8613 112.379L48.9394 134.054L49.1735 133.913L36.0955 112.238Z"
      fill="#EEEEEE"
    />
    <path
      d="M38.6405 110.968L38.3887 111.074L48.2466 134.392L48.4984 134.286L38.6405 110.968Z"
      fill="#EEEEEE"
    />
    <path
      d="M36.2806 112.602L36.1016 112.809L55.2326 129.388L55.4116 129.181L36.2806 112.602Z"
      fill="#EEEEEE"
    />
    <path
      d="M38.3082 110.606L38.1016 110.785L54.6807 129.916L54.8874 129.737L38.3082 110.606Z"
      fill="#EEEEEE"
    />
    <path
      d="M36.5772 112.889L36.4707 113.141L59.7889 122.999L59.8954 122.747L36.5772 112.889Z"
      fill="#EEEEEE"
    />
    <path
      d="M37.8756 110.364L37.7344 110.598L59.4096 123.676L59.5508 123.442L37.8756 110.364Z"
      fill="#EEEEEE"
    />
    <path
      d="M36.9377 113.06L36.9141 113.332L62.136 115.519L62.1596 115.247L36.9377 113.06Z"
      fill="#EEEEEE"
    />
    <path
      d="M37.3959 110.277L37.334 110.543L61.9914 116.277L62.0533 116.011L37.3959 110.277Z"
      fill="#EEEEEE"
    />
    <path
      d="M61.9914 107.407L37.334 113.141L37.3959 113.407L62.0533 107.673L61.9914 107.407Z"
      fill="#EEEEEE"
    />
    <path
      d="M62.134 108.169L36.9121 110.336L36.9355 110.608L62.1574 108.441L62.134 108.169Z"
      fill="#EEEEEE"
    />
    <path
      d="M59.4141 100.009L37.7363 113.082L37.8775 113.316L59.5553 100.243L59.4141 100.009Z"
      fill="#EEEEEE"
    />
    <path
      d="M59.785 100.672L36.4668 110.527L36.5733 110.779L59.8914 100.924L59.785 100.672Z"
      fill="#EEEEEE"
    />
    <path
      d="M54.6749 93.7674L38.0957 112.898L38.3023 113.078L54.8815 93.9465L54.6749 93.7674Z"
      fill="#EEEEEE"
    />
    <path
      d="M55.2429 94.288L36.1094 110.867L36.2885 111.074L55.422 94.4947L55.2429 94.288Z"
      fill="#EEEEEE"
    />
    <path
      d="M48.2421 89.2873L38.3867 112.605L38.6386 112.712L48.494 89.3937L48.2421 89.2873Z"
      fill="#EEEEEE"
    />
    <path
      d="M48.9343 89.623L35.8613 111.301L36.0955 111.442L49.1685 89.7642L48.9343 89.623Z"
      fill="#EEEEEE"
    />
    <path
      d="M40.7373 87.0242L38.5703 112.246L38.8428 112.27L41.0098 87.0476L40.7373 87.0242Z"
      fill="#EEEEEE"
    />
    <path
      d="M41.5016 87.116L35.7676 111.773L36.0339 111.835L41.7679 87.178L41.5016 87.116Z"
      fill="#EEEEEE"
    />
    <path
      d="M33.1648 87.1295L32.8984 87.1914L38.6299 111.849L38.8962 111.787L33.1648 87.1295Z"
      fill="#EEEEEE"
    />
    <path
      d="M11.084 111.811C11.084 126.018 22.6054 137.537 36.8128 137.537C51.0232 137.537 62.5386 126.015 62.5386 111.811C62.5386 97.6004 51.0202 86.082 36.8128 86.082C22.6054 86.079 11.084 97.6004 11.084 111.811ZM14.411 111.811C14.411 99.4386 24.4436 89.406 36.8158 89.406C49.188 89.406 59.2207 99.4386 59.2207 111.811C59.2207 124.183 49.188 134.213 36.8158 134.213C24.4436 134.21 14.411 124.183 14.411 111.811Z"
      fill="#EEEEEE"
    />
    <path
      d="M93.7363 111.811C93.7363 126.018 105.258 137.537 119.468 137.537C133.679 137.537 145.194 126.015 145.194 111.811C145.194 97.6004 133.676 86.082 119.468 86.082C105.258 86.079 93.7363 97.6004 93.7363 111.811ZM97.0633 111.811C97.0633 99.4386 107.096 89.406 119.468 89.406C131.84 89.406 141.873 99.4386 141.873 111.811C141.873 124.183 131.84 134.213 119.468 134.213C107.096 134.21 97.0633 124.183 97.0633 111.811Z"
      fill="#EEEEEE"
    />
    <path
      d="M10.3184 111.81C10.3184 126.44 22.1801 138.304 36.8159 138.304C51.4486 138.304 63.3073 126.443 63.3073 111.81C63.3073 97.1773 51.4486 85.3125 36.8159 85.3125C22.1831 85.3125 10.3184 97.1742 10.3184 111.81ZM13.7456 111.81C13.7456 99.0702 24.076 88.7398 36.8159 88.7398C49.5557 88.7398 59.8861 99.0702 59.8861 111.81C59.8861 124.55 49.5557 134.877 36.8159 134.877C24.076 134.877 13.7456 124.55 13.7456 111.81Z"
      fill="#424242"
    />
    <path
      d="M62.1068 111.498C61.9397 97.6705 50.6826 86.5106 36.8155 86.5106C24.6104 86.5106 14.4957 95.1699 12.0468 106.652C10.8497 112.261 8.79883 111.498 8.79883 111.498C8.96594 96.1665 21.4445 83.7852 36.8155 83.7852C52.1866 83.7852 64.6621 96.1634 64.8292 111.498H62.1068Z"
      fill="#FF4F2B"
    />
    <path
      d="M104.984 83.3287L104.392 83.903L71.1307 116.201L69.6875 114.39L103.28 81.0743L104.021 80.3359L104.984 83.3287Z"
      fill="#FF3008"
    />
    <path
      d="M105.559 80.2371L102.973 80.2948L58.8959 81.2519L57.793 79.4198L102.25 78.4323L104.414 78.3867L105.559 80.2371Z"
      fill="#FF3008"
    />
    <path
      d="M123.439 87.12L117.705 111.777L117.971 111.839L123.705 87.1819L123.439 87.12Z"
      fill="#EEEEEE"
    />
    <path
      d="M115.106 87.1178L114.84 87.1797L120.574 111.837L120.84 111.775L115.106 87.1178Z"
      fill="#EEEEEE"
    />
    <path
      d="M115.86 87.0232L115.588 87.0469L117.775 112.269L118.048 112.245L115.86 87.0232Z"
      fill="#EEEEEE"
    />
    <path
      d="M107.67 89.6283L107.436 89.7695L120.511 111.447L120.745 111.306L107.67 89.6283Z"
      fill="#EEEEEE"
    />
    <path
      d="M108.361 89.2763L108.109 89.3828L117.97 112.698L118.222 112.592L108.361 89.2763Z"
      fill="#EEEEEE"
    />
    <path
      d="M101.372 94.2933L101.193 94.5L120.327 111.077L120.506 110.87L101.372 94.2933Z"
      fill="#EEEEEE"
    />
    <path
      d="M101.929 93.7624L101.723 93.9414L118.299 113.075L118.506 112.896L101.929 93.7624Z"
      fill="#EEEEEE"
    />
    <path
      d="M96.8233 100.67L96.7168 100.922L120.035 110.777L120.141 110.525L96.8233 100.67Z"
      fill="#EEEEEE"
    />
    <path
      d="M97.192 100.008L97.0508 100.242L118.726 113.32L118.867 113.086L97.192 100.008Z"
      fill="#EEEEEE"
    />
    <path
      d="M94.4768 108.153L94.4531 108.426L119.675 110.616L119.699 110.343L94.4768 108.153Z"
      fill="#EEEEEE"
    />
    <path
      d="M94.6088 107.406L94.5469 107.672L119.204 113.403L119.266 113.137L94.6088 107.406Z"
      fill="#EEEEEE"
    />
    <path
      d="M119.21 110.278L94.5527 116.012L94.6147 116.278L119.272 110.544L119.21 110.278Z"
      fill="#EEEEEE"
    />
    <path
      d="M119.673 113.067L94.4512 115.234L94.4746 115.507L119.697 113.34L119.673 113.067Z"
      fill="#EEEEEE"
    />
    <path
      d="M118.725 110.365L97.0469 123.438L97.1881 123.672L118.866 110.599L118.725 110.365Z"
      fill="#EEEEEE"
    />
    <path
      d="M120.041 112.887L96.7227 122.742L96.8291 122.994L120.147 113.139L120.041 112.887Z"
      fill="#EEEEEE"
    />
    <path
      d="M118.302 110.615L101.723 129.746L101.929 129.925L118.508 110.794L118.302 110.615Z"
      fill="#EEEEEE"
    />
    <path
      d="M120.335 112.597L101.201 129.176L101.38 129.382L120.514 112.803L120.335 112.597Z"
      fill="#EEEEEE"
    />
    <path
      d="M117.969 110.967L108.113 134.285L108.365 134.392L118.221 111.073L117.969 110.967Z"
      fill="#EEEEEE"
    />
    <path
      d="M120.503 112.24L107.43 133.918L107.664 134.059L120.737 112.381L120.503 112.24Z"
      fill="#EEEEEE"
    />
    <path
      d="M117.763 111.419L115.596 136.641L115.868 136.664L118.035 111.442L117.763 111.419Z"
      fill="#EEEEEE"
    />
    <path
      d="M120.57 111.843L114.836 136.5L115.102 136.562L120.836 111.905L120.57 111.843Z"
      fill="#EEEEEE"
    />
    <path
      d="M117.968 111.852L117.701 111.914L123.435 136.571L123.702 136.51L117.968 111.852Z"
      fill="#EEEEEE"
    />
    <path
      d="M120.772 111.414L120.5 111.438L122.687 136.659L122.96 136.636L120.772 111.414Z"
      fill="#EEEEEE"
    />
    <path
      d="M118.031 112.249L117.797 112.391L130.872 134.068L131.107 133.927L118.031 112.249Z"
      fill="#EEEEEE"
    />
    <path
      d="M120.58 110.96L120.328 111.066L130.186 134.385L130.438 134.278L120.58 110.96Z"
      fill="#EEEEEE"
    />
    <path
      d="M118.22 112.602L118.041 112.809L137.172 129.388L137.351 129.181L118.22 112.602Z"
      fill="#EEEEEE"
    />
    <path
      d="M120.242 110.61L120.035 110.789L136.614 129.92L136.821 129.741L120.242 110.61Z"
      fill="#EEEEEE"
    />
    <path
      d="M118.511 112.885L118.404 113.137L141.722 122.995L141.829 122.743L118.511 112.885Z"
      fill="#EEEEEE"
    />
    <path
      d="M119.815 110.364L119.674 110.598L141.349 123.676L141.49 123.442L119.815 110.364Z"
      fill="#EEEEEE"
    />
    <path
      d="M118.873 113.06L118.85 113.332L144.072 115.519L144.095 115.247L118.873 113.06Z"
      fill="#EEEEEE"
    />
    <path
      d="M119.337 110.277L119.275 110.543L143.933 116.279L143.995 116.013L119.337 110.277Z"
      fill="#EEEEEE"
    />
    <path
      d="M143.929 107.411L119.271 113.145L119.333 113.411L143.991 107.677L143.929 107.411Z"
      fill="#EEEEEE"
    />
    <path
      d="M144.066 108.161L118.844 110.328L118.867 110.601L144.089 108.434L144.066 108.161Z"
      fill="#EEEEEE"
    />
    <path
      d="M141.346 100.005L119.668 113.078L119.809 113.312L141.487 100.239L141.346 100.005Z"
      fill="#EEEEEE"
    />
    <path
      d="M141.721 100.668L118.402 110.523L118.509 110.775L141.827 100.92L141.721 100.668Z"
      fill="#EEEEEE"
    />
    <path
      d="M136.614 93.7674L120.035 112.898L120.242 113.078L136.821 93.9465L136.614 93.7674Z"
      fill="#EEEEEE"
    />
    <path
      d="M137.182 94.2841L118.049 110.863L118.228 111.07L137.361 94.4908L137.182 94.2841Z"
      fill="#EEEEEE"
    />
    <path
      d="M130.18 89.2834L120.324 112.602L120.576 112.708L130.431 89.3898L130.18 89.2834Z"
      fill="#EEEEEE"
    />
    <path
      d="M130.87 89.6269L117.797 111.305L118.031 111.446L131.104 89.7682L130.87 89.6269Z"
      fill="#EEEEEE"
    />
    <path
      d="M122.675 87.0242L120.508 112.246L120.78 112.27L122.947 87.0476L122.675 87.0242Z"
      fill="#EEEEEE"
    />
    <path
      d="M123.439 87.12L117.705 111.777L117.971 111.839L123.705 87.1819L123.439 87.12Z"
      fill="#EEEEEE"
    />
    <path
      d="M115.106 87.1178L114.84 87.1797L120.574 111.837L120.84 111.775L115.106 87.1178Z"
      fill="#EEEEEE"
    />
    <path
      d="M92.9707 111.81C92.9707 126.44 104.832 138.304 119.468 138.304C134.101 138.304 145.96 126.443 145.96 111.81C145.96 97.1773 134.101 85.3125 119.468 85.3125C104.835 85.3125 92.9707 97.1742 92.9707 111.81ZM96.398 111.81C96.398 99.0702 106.728 88.7398 119.468 88.7398C132.208 88.7398 142.538 99.0702 142.538 111.81C142.538 124.55 132.208 134.877 119.468 134.877C106.725 134.877 96.398 124.55 96.398 111.81Z"
      fill="#424242"
    />
    <path
      d="M104.022 80.3359L104.985 83.3287L104.392 83.903C104.007 82.9307 103.633 81.9858 103.277 81.0743L104.022 80.3359Z"
      fill="#BF3008"
    />
    <path
      d="M69.3236 111.812L37.123 111.426L37.3053 113.273L71.1314 113.872L69.3236 111.812Z"
      fill="#FF3008"
    />
    <path
      d="M105.559 80.2371L102.973 80.2918C102.721 79.6507 102.481 79.0309 102.25 78.4323L104.413 78.3867L105.559 80.2371Z"
      fill="#BF3008"
    />
    <path
      d="M91.4551 111.356C91.6981 96.0914 104.146 83.7891 119.469 83.7891C123.628 83.7891 127.575 84.6975 131.127 86.3231C131.127 86.3231 130.744 88.7385 127.229 87.7268C124.762 87.0158 122.176 86.5145 119.469 86.5145C107.002 86.5145 96.8299 95.5657 94.5572 107.4C93.7703 111.499 91.4551 111.356 91.4551 111.356Z"
      fill="#FF4F2B"
    />
    <path
      d="M36.7756 114.036L36.4414 113.954L36.4961 110.573L75.3567 107.367L74.5728 123.24L36.7756 114.036ZM37.3438 111.366L37.3134 113.277L73.7646 122.155L74.4482 108.306L37.3438 111.366Z"
      fill="#BDBDBD"
    />
    <path
      d="M116.025 111.098C116.031 111.335 116.035 111.572 116.035 111.809C116.035 112.046 116.031 112.283 116.025 112.52V111.098Z"
      fill="#FFB300"
    />
    <path
      d="M117.207 111.805C117.207 112.945 118.131 113.868 119.27 113.868C120.409 113.868 121.333 112.945 121.333 111.805C121.333 110.666 120.409 109.742 119.27 109.742C118.131 109.742 117.207 110.666 117.207 111.805Z"
      fill="#FF3008"
    />
    <path
      d="M102.408 69.3672C102.408 69.3672 108.43 86.2848 116.296 104.333C119.049 110.646 120.562 111.777 120.562 111.777L119.468 112.837C113.385 107.371 99.8828 70.221 99.8828 70.221L102.408 69.3672Z"
      fill="#FF3008"
    />
    <path
      d="M70.0264 111.804L58.5718 74.6875H55.8828L67.3222 113.867L70.0264 111.804Z"
      fill="#FF3008"
    />
    <path
      d="M72.8674 112.251L40.8431 111.807C40.8431 111.807 39.4303 109.382 42.9669 109.382C46.5036 109.382 74.9274 107.371 74.9274 107.371L72.8674 112.251Z"
      fill="#BF3008"
    />
    <path
      d="M76.7153 122.492C80.8334 121.508 83.3745 117.372 82.391 113.254C81.4075 109.136 77.2719 106.595 73.1538 107.579C69.0357 108.562 66.4945 112.698 67.478 116.816C68.4615 120.934 72.5972 123.475 76.7153 122.492Z"
      fill="#BF3008"
    />
    <path
      d="M58.888 81.7041L36.6836 111.711L35.7812 110.414L58.4626 78.8359L58.888 81.7041Z"
      fill="#FF3008"
    />
    <path d="M81.7324 128.629V131.504H74.1699V128.152L81.7324 128.629Z" fill="#424242" />
    <path
      d="M37.8518 114.073C39.1043 113.776 39.8791 112.52 39.5824 111.268C39.2857 110.015 38.0299 109.24 36.7775 109.537C35.5251 109.834 34.7503 111.09 35.0469 112.342C35.3436 113.594 36.5994 114.369 37.8518 114.073Z"
      fill="#FF3008"
    />
    <path
      d="M76.3491 117.314L78.4699 129.999L76.9963 130.404L72.8672 117.02L76.3491 117.314Z"
      fill="#424242"
    />
    <path
      d="M38.7168 108.524C38.7168 108.524 50.6515 91.8189 50.2687 91.4816C49.8828 91.1444 38.0727 107.804 38.7168 108.524Z"
      fill="#FF6B43"
    />
    <path
      d="M74.9266 118.963C77.0963 118.963 78.8552 117.204 78.8552 115.034C78.8552 112.864 77.0963 111.105 74.9266 111.105C72.7569 111.105 70.998 112.864 70.998 115.034C70.998 117.204 72.7569 118.963 74.9266 118.963Z"
      fill="#424242"
    />
    <path
      d="M83.9024 101.521C83.9024 101.521 101.482 85.5664 101.175 85.1562C100.872 84.746 83.4193 100.685 83.9024 101.521Z"
      fill="#FF6B43"
    />
    <path
      d="M75.4191 120.47C75.4191 120.47 75.3462 123.028 76.0784 123.76C74.298 125.541 72.6421 125.905 70.2266 124.198C70.7461 123.396 71.0226 121.664 70.5669 119.297L75.4191 120.47Z"
      fill="#FFB38D"
    />
    <path
      d="M69.8702 101.628C69.8945 106.146 70.1437 107.793 70.2227 110.883C70.2591 112.936 69.4935 115.039 69.4935 115.039C69.4935 115.039 69.6667 117.318 69.6879 118.196C69.7092 119.074 68.661 120.256 68.661 120.256C68.661 120.256 70.3776 121.347 72.7415 121.347C75.1053 121.347 76.6245 120.624 76.6245 120.624C76.6245 120.624 76.9678 110.509 77.7973 105.088C78.4475 100.832 79.8816 93.3604 80.468 90.3433C80.6351 89.4804 80.5106 88.5871 80.1156 87.8002C72.8326 73.2768 64.5622 62.199 64.5622 62.199L54.4111 58.8203C54.4111 58.8203 51.6522 63.0284 51.3606 65.6657C51.3606 65.6657 50.4703 71.4022 58.0176 77.1538C59.7343 78.4633 68.3784 87.2229 71.3924 90.9601C71.3955 90.957 69.8459 97.1097 69.8702 101.628Z"
      fill="#303030"
    />
    <path
      d="M72.3678 64.4469C68.898 60.816 70.9397 55.9729 72.3647 54.7818C72.781 54.4355 78.6389 47.3774 79.3378 45.8643C79.5352 45.4359 79.6598 45.1654 79.6598 45.1654L80.2705 40.2463L80.468 37.0713L80.544 35.8134L74.8562 27.625C74.8562 27.625 68.1444 31.8331 64.2189 38.7302C62.6754 41.4434 59.5793 47.0371 56.4316 51.391C53.0377 56.0853 49.6348 59.4548 49.6348 59.4548C49.6348 59.4548 54.6238 63.587 59.6887 64.4438C64.7597 65.3037 72.3678 64.4469 72.3678 64.4469Z"
      fill="#FF3008"
    />
    <path
      d="M81.9994 33.9171L79.7996 37.3292C79.7996 37.3292 77.6393 29.6725 73.2793 27.6247L76.3116 25.8047C76.3116 25.8047 79.4958 31.4165 81.9994 33.9171Z"
      fill="#FF4F2B"
    />
    <path
      d="M102.397 68.9297L103.093 70.218L99.8846 71.4637L99.4805 69.8564L102.397 68.9297Z"
      fill="#BF3008"
    />
    <path
      d="M104.876 61.7406C104.931 61.1268 106.818 59.4922 106.818 59.4922L110.303 59.8507L112.086 62.88L111.226 64.8549L108.929 66.6657C108.449 66.2708 108.814 65.0007 108.814 64.5298C108.814 64.5267 104.709 63.5879 104.876 61.7406Z"
      fill="#FFB38D"
    />
    <path
      d="M105.945 64.4445C105.945 65.3986 106.717 66.1703 107.671 66.1703C108.625 66.1703 109.397 65.3986 109.397 64.4445C109.397 63.4905 108.625 62.7188 107.671 62.7188C106.717 62.7188 105.945 63.4935 105.945 64.4445Z"
      fill="#424242"
    />
    <path d="M110.301 59.8555L112.084 61.323V62.8847L110.301 59.8555Z" fill="#FF9261" />
    <path
      d="M86.9957 129.804C86.9957 129.804 81.5935 131.326 78.7891 130.22C75.9877 129.114 74.8028 128.972 74.8028 128.972L74.7511 129.613L69.7318 129.212C69.7318 129.212 69.434 127.781 69.5252 126.179C69.5859 125.098 70.069 123.867 70.3151 123.238C73.0223 124.271 73.9156 125.356 76.1397 123.563C76.1397 123.563 81.5267 127.024 84.5863 127.27C87.6459 127.516 88.284 128.434 88.284 128.434C88.284 128.434 88.6364 128.874 88.2566 129.187C87.8799 129.503 86.9957 129.804 86.9957 129.804Z"
      fill="#FF3008"
    />
    <path
      d="M70.9395 42.4757C70.9395 42.4757 77.0952 47.0059 74.1753 52.6421L78.4807 47.9174L79.4985 46.7051L72.2459 41.5156L70.9395 42.4757Z"
      fill="#BF3008"
    />
    <path
      d="M89.2997 46.4043L84.7847 52.0435C84.7543 52.0799 84.7573 52.1346 84.7908 52.168L86.0334 53.4077C86.0517 53.4259 86.0729 53.435 86.1003 53.435H87.2397C87.2853 53.435 87.3248 53.4685 87.3308 53.514L87.4402 54.2736C87.4463 54.307 87.4675 54.3344 87.4979 54.3465L89.5033 55.1274C89.5519 55.1456 89.6035 55.1213 89.6218 55.0757L92.3016 48.4673C92.3593 48.3245 92.3137 48.1604 92.1892 48.0693L91.3141 47.4252C91.2868 47.4039 91.2503 47.4008 91.2199 47.416L90.9434 47.5467C90.8948 47.571 90.8341 47.5437 90.8158 47.492L90.7004 47.1578C90.6943 47.1426 90.6852 47.1274 90.673 47.1183L89.798 46.3617C89.6521 46.228 89.4243 46.2493 89.2997 46.4043Z"
      fill="#CCC2C0"
    />
    <path
      d="M81.5586 21.5156C81.5586 21.5156 81.908 23.2627 81.8776 23.9889L83.7128 24.3565C83.7128 24.3565 83.9315 21.8377 84.2627 21.4062L81.5586 21.5156Z"
      fill="#683B27"
    />
    <path
      d="M78.859 21.1211L79.2358 24.0622C79.2358 24.0622 78.8195 25.0892 78.5552 25.4113C78.5552 25.4113 78.1298 24.7064 77.6437 23.5639C77.1758 22.464 77.1211 21.4037 77.1211 21.4037L78.859 21.1211Z"
      fill="#683B27"
    />
    <path
      d="M86.8132 21.928L86.002 22.6997C86.002 22.6997 89.2135 22.9185 90.3225 21.928L89.4718 22.721C89.4718 22.721 91.553 23.1828 92.9385 22.0799L93.1178 21.293L87.035 21.4327L86.8132 21.928Z"
      fill="#683B27"
    />
    <path
      d="M77.111 21.7983C77.111 21.7983 75.4885 14.2662 84.0415 13.0083C84.0415 13.0083 93.5911 12.3885 92.5884 21.856C92.5884 21.856 89.3829 21.5613 84.9196 21.5613C80.4563 21.5613 77.111 21.7983 77.111 21.7983Z"
      fill="#FF3008"
    />
    <path
      d="M92.0181 20.2773L98.4199 21.8937C96.679 24.604 89.7363 21.6507 89.7363 21.6507L92.0181 20.2773Z"
      fill="#FF3008"
    />
    <path
      d="M81.7846 23.8272C81.7846 23.8272 82.216 22.1105 81.2164 21.0137C80.2168 19.9168 78.4485 20.6825 78.8252 22.6787C79.202 24.6779 79.8431 25.951 80.4386 25.4223C81.0341 24.8936 81.7846 23.8272 81.7846 23.8272Z"
      fill="#FFB38D"
    />
    <path
      d="M80.7444 22.1129C80.7444 22.1129 80.0547 22.4197 80.1033 23.0973C80.1519 23.7718 79.3711 22.3711 80.2978 21.7422L80.7444 22.1129Z"
      fill="#E28C52"
    />
    <path
      d="M79.349 21.4635C78.7838 21.6306 80.4002 21.6549 80.8347 22.6393C81.2692 23.6268 80.9563 24.6021 81.1294 24.119C81.3421 23.5144 81.5275 22.4358 80.9957 21.8433C80.6737 21.4878 79.9171 21.2964 79.349 21.4635Z"
      fill="#E28C52"
    />
    <path
      d="M76.9983 20.7488L78.4719 20.6729C78.4719 20.6729 78.56 18.8073 77.2626 18.2422C77.2596 18.2422 76.8585 19.4606 76.9983 20.7488Z"
      fill="#683B27"
    />
    <path
      d="M52.1133 52.0038L55.4129 48.3578C57.9226 48.215 60.6116 47.9628 63.4524 47.5617C67.46 46.9966 71.1182 46.2218 74.3844 45.3711C75.0468 45.9393 75.7061 46.5074 76.3685 47.0756C73.2663 48.3183 69.4957 49.5671 65.1326 50.4968C60.1984 51.5541 55.7684 51.9309 52.1133 52.0038Z"
      fill="#303030"
    />
    <path
      d="M69.2753 27.0234L67.0938 28.807C68.8287 29.6182 71.1013 30.9034 73.4257 32.9087C75.5009 34.6983 76.9836 36.5183 78.0106 37.9858C78.6213 38.6452 78.8887 38.9429 79.4994 39.6022C78.6972 37.5453 77.6946 34.8715 75.0755 31.8848C73.0672 29.5908 70.8917 28.0626 69.2753 27.0234Z"
      fill="#303030"
    />
    <path
      d="M49.9813 53.3191L35.6979 44.4562C34.2547 43.5599 33.8111 41.667 34.7074 40.2238L49.7382 15.9929C50.6345 14.5496 52.5274 14.106 53.9706 15.0024L68.2539 23.8653C69.6972 24.7616 70.1408 26.6545 69.2445 28.0977L54.2137 52.3286C53.3204 53.7688 51.4245 54.2124 49.9813 53.3191Z"
      fill="#303030"
    />
    <path
      d="M51.9681 19.4181L51.1367 18.9023L35.3956 44.2756L36.227 44.7914L51.9681 19.4181Z"
      fill="#222222"
    />
    <path
      d="M51.3717 15.4961C50.4966 16.915 50.7944 18.7016 52.031 19.4612L65.1203 27.5219C66.3569 28.2845 68.0827 27.7437 68.9577 26.3248L51.3717 15.4961Z"
      fill="#858585"
    />
    <path
      d="M87.6147 55.21L105.696 62.0584L106.817 59.5001C106.817 59.5001 96.3986 54.183 91.3428 51.0717L88.4594 49.1211L84.7344 53.0831L87.6147 55.21Z"
      fill="#FFB38D"
    />
    <path
      d="M70.5287 42.0621C70.5287 42.0621 82.6214 52.3226 84.414 53.3556C87.7289 53.942 87.8717 52.1464 89.3514 49.5759C89.3514 49.5759 85.8816 46.1517 82.6305 42.8368C80.0631 40.2208 77.6294 37.6716 77.1463 36.9272C76.0494 35.247 69.2861 36.2831 70.5287 42.0621Z"
      fill="#FF3008"
    />
    <path
      d="M87.6596 48.0067L83.6398 53.0261C83.4727 53.2327 83.4909 53.5335 83.6793 53.7188L84.442 54.4814C84.5574 54.5969 84.7154 54.6607 84.8795 54.6607H85.4385C85.6664 54.6607 85.8578 54.8278 85.8912 55.0526L85.9125 55.2015C85.9399 55.396 86.0705 55.5601 86.2559 55.633L87.5806 56.1495C87.8206 56.2437 88.091 56.1252 88.1883 55.8882L90.5582 50.0454C90.6493 49.8176 90.5764 49.5563 90.3789 49.4104L89.6922 48.9061C89.6527 48.8787 89.6041 48.8726 89.5616 48.8939L89.3398 49.0002C89.2669 49.0337 89.1818 48.9972 89.1605 48.9182L89.0846 48.6448C89.0785 48.6204 89.0633 48.5992 89.0451 48.5809L88.3067 47.9429C88.1123 47.7788 87.8176 47.8062 87.6596 48.0067Z"
      fill="#E5D9D6"
    />
    <circle cx="137.588" cy="22.2422" r="22" fill="#F3FCFF" />
    <rect
      x="150.588"
      y="39.0703"
      width="4"
      height="18"
      transform="rotate(-45 150.588 39.0703)"
      fill="#303030"
    />
    <rect
      x="156.893"
      y="46.7656"
      width="5.96686"
      height="18"
      transform="rotate(-45 156.893 46.7656)"
      fill="#727272"
    />
    <path
      d="M156.896 46.7656L161.108 42.5447L159.588 42.2422L156.588 45.2422L156.896 46.7656Z"
      fill="#949494"
    />
    <circle cx="137.588" cy="22.2422" r="20.5" stroke="#303030" strokeWidth="3" />
    <path
      d="M143.148 7.69476C140.003 6.51213 136.493 7.05106 133.822 7.85576C132.79 8.16666 133.096 9.68676 134.164 9.8296C136.205 10.1025 138.76 10.6611 141.501 11.7763C144.533 13.0102 147.062 14.5736 148.9 15.8932C149.759 16.5104 150.926 15.6477 150.402 14.7286C148.919 12.1298 146.515 8.96062 143.148 7.69476Z"
      fill="white"
    />
  </svg>
));
