import React from "react";

type Props = React.SVGProps<SVGSVGElement> & {};

export const IconWaitComboOrder: React.FC<Props> = React.memo(({ ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 175 126" fill="none" {...props}>
    <path
      d="M173.453 110.581C173.453 110.581 174.934 100.118 165.172 100.315C167.197 98.6981 168.461 96.237 168.328 93.5369C168.132 89.2969 164.627 85.9499 160.284 85.6475C158.824 81.6747 154.788 78.9254 150.158 79.1364C144.502 79.4036 136.549 86.7796 136.807 92.2009C137.051 97.6223 146.353 99.2043 140.801 100.308C132.387 101.989 141.667 100.611 142.086 100.54C142.24 100.751 141.625 102.797 141.8 103.001C135.934 104.879 142.854 106.215 143.12 112.044C143.441 119.16 150.179 123.998 158.154 124.265C163.454 124.441 167.211 123.54 169.739 122.015C169.739 122.008 177.992 117.592 173.453 110.581Z"
      fill="#B3DB60"
    />
    <path
      d="M31.9418 75.2227C36.5436 75.2227 40.2655 78.8087 40.2655 83.2386L50.2441 117.187V123.093H19.4074V123.058C12.7108 122.517 7.43164 116.554 7.43164 109.276C7.43164 104.375 9.82679 100.072 13.416 97.6111C12.9412 96.4016 12.6619 95.0727 12.6619 93.6875C12.6619 87.9216 17.2846 83.2386 22.9897 83.2386C23.1992 83.2386 23.4017 83.2597 23.6111 83.2667V83.2386C23.6181 78.8158 27.34 75.2227 31.9418 75.2227Z"
      fill="#B3DB60"
    />
    <path
      d="M172.913 89.5797L170.309 102.764V125.623H167.152V102.461L169.485 90.6766L169.82 88.9609L172.913 89.5797Z"
      fill="#5E5E5E"
    />
    <path
      d="M172.9 89.7789L170.309 102.935V125.745H167.16V102.633L169.485 90.8759L169.821 89.1602L172.9 89.7789Z"
      fill="#2E2633"
    />
    <path
      d="M154.535 122.941H28.248C28.248 119.116 31.3345 116.008 35.1332 116.008H147.65C151.448 116.001 154.535 119.116 154.535 122.941Z"
      fill="#808080"
    />
    <path d="M145.345 37.3945H37.4238V116.007H145.345V37.3945Z" fill="#D8D8D8" />
    <path
      d="M145.348 104.449H37.4263C36.1624 104.449 35.1289 105.49 35.1289 106.763V116.009H147.638V106.763C147.638 105.49 146.605 104.449 145.348 104.449Z"
      fill="#979797"
    />
    <path
      d="M145.348 104.449H37.4263C36.1624 104.449 35.1289 105.49 35.1289 106.763H147.638C147.638 105.49 146.605 104.449 145.348 104.449Z"
      fill="#B2B2B2"
    />
    <path d="M138.467 53.5781H81.0605V97.5111H138.467V53.5781Z" fill="#A9A9A9" />
    <path d="M136.162 55.8867H83.3496V95.193H136.162V55.8867Z" fill="#8F8F8F" />
    <path d="M136.162 55.8867H83.3496V58.2001H136.162V55.8867Z" fill="#6B6B6B" />
    <path d="M74.1705 53.5781H44.3184V104.444H74.1705V53.5781Z" fill="#A9A9A9" />
    <path d="M74.1705 104.441H44.3184V113.688H74.1705V104.441Z" fill="#5E5E5E" />
    <path d="M71.8667 55.8867H46.6094V113.693H71.8667V55.8867Z" fill="#E9E9E9" />
    <path d="M69.5688 58.1992H48.9062V104.439H69.5688V58.1992Z" fill="#8F8F8F" />
    <path d="M69.5688 58.1992H48.9062V60.5126H69.5688V58.1992Z" fill="#6B6B6B" />
    <path d="M69.5688 92.8867H48.9062V95.2001H69.5688V92.8867Z" fill="#5E5E5E" />
    <path
      d="M71.8667 88.2587V90.5721C71.8667 91.8448 70.8332 92.8854 69.5693 92.8854H48.9068C47.6429 92.8854 46.6094 91.8448 46.6094 90.5721V88.2587L48.9068 85.9453H50.052V88.2587H68.4241V85.9453H69.5693L71.8667 88.2587Z"
      fill="#757575"
    />
    <path
      d="M68.4307 88.2578H50.0586V89.411C50.0586 90.0438 50.5753 90.5642 51.2038 90.5642H67.2786C67.907 90.5642 68.4238 90.0438 68.4238 89.411V88.2578H68.4307Z"
      fill="#CDCDCD"
    />
    <path d="M74.1705 113.691H44.3184V116.005H74.1705V113.691Z" fill="#B2B2B2" />
    <path
      d="M62.6816 38.5477C62.6816 41.74 65.2514 44.3276 68.4216 44.3276C71.5919 44.3276 74.1616 41.74 74.1616 38.5477V37.3945H62.6816V38.5477Z"
      fill="#E3E3E3"
    />
    <path
      d="M39.7285 38.5477C39.7285 41.74 42.2982 44.3276 45.4685 44.3276C48.6388 44.3276 51.2085 41.74 51.2085 38.5477V37.3945H39.7285V38.5477Z"
      fill="#E3E3E3"
    />
    <path
      d="M85.6445 38.5477C85.6445 41.74 88.2143 44.3276 91.3845 44.3276C94.5548 44.3276 97.1245 41.74 97.1245 38.5477V37.3945H85.6445V38.5477Z"
      fill="#E3E3E3"
    />
    <path
      d="M131.562 37.3945V38.5477C131.562 41.74 134.132 44.3276 137.302 44.3276C140.473 44.3276 143.042 41.74 143.042 38.5477V37.3945H131.562Z"
      fill="#E3E3E3"
    />
    <path
      d="M108.605 38.5477C108.605 41.74 111.175 44.3276 114.345 44.3276C117.516 44.3276 120.085 41.74 120.085 38.5477V37.3945H108.605V38.5477Z"
      fill="#E3E3E3"
    />
    <path d="M51.0339 16.5781L39.7285 37.3915H51.2085L60.021 16.5781H51.0339Z" fill="#E9E9E9" />
    <path d="M69.0082 16.5781L62.6816 37.3915H74.1616L77.9883 16.5781H69.0082Z" fill="#E9E9E9" />
    <path d="M104.783 16.5781L108.61 37.3915H120.09L113.763 16.5781H104.783Z" fill="#E9E9E9" />
    <path d="M86.9783 16.5781L85.6445 37.3915H97.1245L95.7908 16.5781H86.9783Z" fill="#E9E9E9" />
    <path d="M122.752 16.5781L131.571 37.3915H143.051L131.739 16.5781H122.752Z" fill="#E9E9E9" />
    <path
      d="M74.1641 38.5477C74.1641 41.74 76.7338 44.3276 79.904 44.3276C83.0743 44.3276 85.644 41.74 85.644 38.5477V37.3945H74.1641V38.5477Z"
      fill="#9D9D9D"
    />
    <path
      d="M51.2109 38.5477C51.2109 41.74 53.7807 44.3276 56.9509 44.3276C60.1212 44.3276 62.6909 41.74 62.6909 38.5477V37.3945H51.2109V38.5477Z"
      fill="#9D9D9D"
    />
    <path
      d="M28.2344 38.5477C28.2344 41.74 30.8041 44.3276 33.9744 44.3276C37.1446 44.3276 39.7143 41.74 39.7143 38.5477V37.3945H28.2344V38.5477Z"
      fill="#9D9D9D"
    />
    <path
      d="M143.049 37.3867V38.5399C143.049 41.7322 145.619 44.3198 148.789 44.3198C151.959 44.3198 154.529 41.7322 154.529 38.5399V37.3867H143.049Z"
      fill="#9D9D9D"
    />
    <path
      d="M97.123 38.5477C97.123 41.74 99.6928 44.3276 102.863 44.3276C106.033 44.3276 108.603 41.74 108.603 38.5477V37.3945H97.123V38.5477Z"
      fill="#9D9D9D"
    />
    <path
      d="M120.086 38.5477C120.086 41.74 122.656 44.3276 125.826 44.3276C128.996 44.3276 131.566 41.74 131.566 38.5477V37.3945H120.086V38.5477Z"
      fill="#9D9D9D"
    />
    <path d="M77.9837 16.5781L74.1641 37.3915H85.644L86.9708 16.5781H77.9837Z" fill="#B2B2B2" />
    <path d="M60.0234 16.5781L51.2109 37.3915H62.6909L69.0175 16.5781H60.0234Z" fill="#B2B2B2" />
    <path
      d="M40.685 18.6392L28.2344 37.3923H39.7143L51.0198 16.586H44.4837C42.9684 16.579 41.5299 17.3524 40.685 18.6392Z"
      fill="#B2B2B2"
    />
    <path
      d="M131.742 16.5773L143.048 37.3836H154.528L142.084 18.6306C141.232 17.3508 139.801 16.5703 138.264 16.5703H131.742V16.5773Z"
      fill="#B2B2B2"
    />
    <path d="M113.764 16.5781L120.09 37.3915H131.57L122.751 16.5781H113.764Z" fill="#B2B2B2" />
    <path d="M95.793 16.5781L97.1267 37.3915H108.607L104.787 16.5781H95.793Z" fill="#B2B2B2" />
    <path d="M154.523 101.828H116.654V114.541H154.523V101.828Z" fill="#9B9B9B" />
    <path d="M154.523 97.0664H116.654V109.779H154.523V97.0664Z" fill="#CDCDCD" />
    <path d="M157.676 95.4688H113.502V98.647H157.676V95.4688Z" fill="#5E5E5E" />
    <path d="M157.676 98.6523H113.502V108.187H157.676V98.6523Z" fill="#C0C0C0" />
    <path d="M137.168 98.6523H134.012V125.66H137.168V98.6523Z" fill="#5E5E5E" />
    <path d="M154.523 95.4688H116.654V108.182H154.523V95.4688Z" fill="#ABABAB" />
    <path d="M151.359 95.4766H119.803V105.011H151.359V95.4766Z" fill="#727272" />
    <path d="M148.21 95.4766H122.967V101.833H148.21V95.4766Z" fill="#ABABAB" />
    <path d="M116.657 111.332H100.883V117.689H116.657V111.332Z" fill="#9B9B9B" />
    <path d="M116.658 111.332H113.502V125.627H116.658V111.332Z" fill="#5E5E5E" />
    <path d="M116.657 108.16H100.883V111.338H116.657V108.16Z" fill="#727272" />
    <path d="M116.653 109.77H100.879V111.323H116.653V109.77Z" fill="#525252" />
    <path
      d="M98.2754 89.5797L100.88 102.771V125.63H104.036V102.469L101.704 90.6766L101.369 88.9609L98.2754 89.5797Z"
      fill="#5E5E5E"
    />
    <path d="M170.306 111.312H154.531V117.669H170.306V111.312Z" fill="#9B9B9B" />
    <path d="M157.684 111.32H154.527V125.615H157.684V111.32Z" fill="#5E5E5E" />
    <path d="M170.306 108.137H154.531V111.315H170.306V108.137Z" fill="#727272" />
    <path d="M170.302 109.77H154.527V111.323H170.302V109.77Z" fill="#525252" />
    <path
      d="M145.045 93.8945H156.092C156.092 94.7664 155.38 95.4837 154.514 95.4837H146.623C145.75 95.4837 145.045 94.7664 145.045 93.8945Z"
      fill="#E9E9E9"
    />
    <path
      d="M138.734 90.7127V85.9453H143.469V90.7127C143.469 91.5846 142.757 92.3018 141.891 92.3018V93.8909C142.757 93.8909 143.469 94.6082 143.469 95.4801H138.734C138.734 94.6082 139.447 93.8909 140.313 93.8909V92.3018C139.447 92.3018 138.734 91.5846 138.734 90.7127Z"
      fill="#FEFEFE"
    />
    <path
      d="M126.113 93.8945H115.066C115.066 94.7664 115.779 95.4837 116.645 95.4837H124.535C125.408 95.4837 126.113 94.7664 126.113 93.8945Z"
      fill="#E9E9E9"
    />
    <path
      d="M132.424 90.7127V85.9453H127.689V90.7127C127.689 91.5846 128.402 92.3018 129.268 92.3018V93.8909C128.402 93.8909 127.689 94.6082 127.689 95.4801H132.424C132.424 94.6082 131.712 93.8909 130.846 93.8909V92.3018C131.719 92.3018 132.424 91.5846 132.424 90.7127Z"
      fill="#FEFEFE"
    />
    <path d="M141.897 92.3008H140.318V93.8899H141.897V92.3008Z" fill="#CDCDCD" />
    <path d="M130.85 92.3008H129.271V93.8899H130.85V92.3008Z" fill="#CDCDCD" />
    <path
      d="M154.336 122.628H28.0078C28.0078 118.802 31.0943 115.688 34.9 115.688H147.444C151.25 115.695 154.336 118.802 154.336 122.628Z"
      fill="#555152"
    />
    <path d="M145.149 37.0547H37.1992V115.688H145.149V37.0547Z" fill="#CACDD1" />
    <path
      d="M145.145 104.125H37.1958C35.9319 104.125 34.8984 105.166 34.8984 106.438V115.692H147.443V106.438C147.443 105.166 146.409 104.125 145.145 104.125Z"
      fill="#3C966E"
    />
    <path
      d="M145.145 104.125H37.1958C35.9319 104.125 34.8984 105.166 34.8984 106.438H147.443C147.443 105.166 146.409 104.125 145.145 104.125Z"
      fill="#74D4A8"
    />
    <path d="M138.257 53.25H80.8359V97.1971H138.257V53.25Z" fill="#A7A7B8" />
    <path d="M135.953 55.5625H83.127V94.8828H135.953V55.5625Z" fill="#5AA5AD" />
    <path d="M135.953 55.5625H83.127V57.8759H135.953V55.5625Z" fill="#1D7789" />
    <path d="M73.9392 53.25H44.0801V104.13H73.9392V53.25Z" fill="#A7A7B8" />
    <path d="M73.9392 104.125H44.0801V113.378H73.9392V104.125Z" fill="#555152" />
    <path d="M71.6452 55.5625H46.3809V113.383H71.6452V55.5625Z" fill="#E6EBEA" />
    <path d="M69.3453 57.875H48.6758V104.135H69.3453V57.875Z" fill="#5AA5AD" />
    <path d="M69.3453 57.875H48.6758V60.1884H69.3453V57.875Z" fill="#1D7789" />
    <path d="M69.3453 92.5703H48.6758V94.8837H69.3453V92.5703Z" fill="#1D7789" />
    <path
      d="M71.6452 87.9462V90.2596C71.6452 91.5323 70.6117 92.5729 69.3478 92.5729H48.6783C47.4143 92.5729 46.3809 91.5323 46.3809 90.2596V87.9462L48.6783 85.6328H49.8235V87.9462H68.1956V85.6328H69.3408L71.6452 87.9462Z"
      fill="#767475"
    />
    <path
      d="M68.2022 87.9414H49.8301V89.0946C49.8301 89.7274 50.3468 90.2478 50.9753 90.2478H67.05C67.6785 90.2478 68.1952 89.7274 68.1952 89.0946V87.9414H68.2022Z"
      fill="#CACDD1"
    />
    <path d="M73.9392 113.383H44.0801V115.696H73.9392V113.383Z" fill="#74D4A8" />
    <path
      d="M37.1992 48.5934C38.5888 47.5387 39.4966 45.8793 39.4966 43.9878C39.4966 47.1801 42.0663 49.7677 45.2366 49.7677C48.4069 49.7677 50.9766 47.1801 50.9766 43.9878C50.9766 47.1801 53.5463 49.7677 56.7166 49.7677C59.8868 49.7677 62.4566 47.1801 62.4566 43.9878C62.4566 47.1801 65.0263 49.7677 68.1966 49.7677C71.3668 49.7677 73.9366 47.1801 73.9366 43.9878C73.9366 47.1801 76.5063 49.7677 79.6765 49.7677C82.8468 49.7677 85.4165 47.1801 85.4165 43.9878C85.4165 47.1801 87.9863 49.7677 91.1565 49.7677C94.3268 49.7677 96.8965 47.1801 96.8965 43.9878C96.8965 47.1801 99.4662 49.7677 102.636 49.7677C105.807 49.7677 108.376 47.1801 108.376 43.9878C108.376 47.1801 110.946 49.7677 114.116 49.7677C117.287 49.7677 119.856 47.1801 119.856 43.9878C119.856 47.1801 122.426 49.7677 125.596 49.7677C128.767 49.7677 131.336 47.1801 131.336 43.9878C131.336 47.1801 133.906 49.7677 137.076 49.7677C140.247 49.7677 142.816 47.1801 142.816 43.9878C142.816 45.8722 143.724 47.5246 145.114 48.5934V37.0547H37.1992V48.5934Z"
      fill="#A7A7B8"
    />
    <path
      d="M62.4601 38.2079C62.4601 41.4002 65.0298 43.9878 68.2001 43.9878C71.3704 43.9878 73.9401 41.4002 73.9401 38.2079V37.0547H62.4531V38.2079H62.4601Z"
      fill="#CACDD1"
    />
    <path
      d="M39.4941 38.2079C39.4941 41.4002 42.0639 43.9878 45.2341 43.9878C48.4044 43.9878 50.9741 41.4002 50.9741 38.2079V37.0547H39.4941V38.2079Z"
      fill="#CACDD1"
    />
    <path
      d="M85.4316 38.2079C85.4316 41.4002 88.0014 43.9878 91.1716 43.9878C94.3419 43.9878 96.9116 41.4002 96.9116 38.2079V37.0547H85.4316V38.2079Z"
      fill="#CACDD1"
    />
    <path
      d="M131.365 37.0547V38.2079C131.365 41.4002 133.935 43.9878 137.105 43.9878C140.275 43.9878 142.845 41.4002 142.845 38.2079V37.0547H131.365Z"
      fill="#CACDD1"
    />
    <path
      d="M108.394 38.2079C108.394 41.4002 110.963 43.9878 114.134 43.9878C117.304 43.9878 119.874 41.4002 119.874 38.2079V37.0547H108.387V38.2079H108.394Z"
      fill="#CACDD1"
    />
    <path d="M50.8065 16.2422L39.4941 37.0555H50.9741L59.8006 16.2422H50.8065Z" fill="#E6EBEA" />
    <path d="M68.7855 16.2422L62.459 37.0555H73.939L77.7656 16.2422H68.7855Z" fill="#E6EBEA" />
    <path d="M104.576 16.2422L108.396 37.0555H119.883L113.556 16.2422H104.576Z" fill="#E6EBEA" />
    <path d="M86.7654 16.2422L85.4316 37.0555H96.9186L95.5849 16.2422H86.7654Z" fill="#E6EBEA" />
    <path d="M122.547 16.2422L131.366 37.0555H142.846L131.541 16.2422H122.547Z" fill="#E6EBEA" />
    <path
      d="M73.9464 38.2079C73.9464 41.4002 76.5162 43.9878 79.6864 43.9878C82.8567 43.9878 85.4264 41.4002 85.4264 38.2079V37.0547H73.9395V38.2079H73.9464Z"
      fill="#3C966E"
    />
    <path
      d="M50.9718 38.2079C50.9718 41.4002 53.5416 43.9878 56.7118 43.9878C59.8821 43.9878 62.4518 41.4002 62.4518 38.2079V37.0547H50.9648V38.2079H50.9718Z"
      fill="#3C966E"
    />
    <path
      d="M28.0078 38.2079C28.0078 41.4002 30.5775 43.9878 33.7478 43.9878C36.9181 43.9878 39.4878 41.4002 39.4878 38.2079V37.0547H28.0078V38.2079Z"
      fill="#3C966E"
    />
    <path
      d="M142.848 37.0547V38.2079C142.848 41.4002 145.417 43.9878 148.588 43.9878C151.758 43.9878 154.328 41.4002 154.328 38.2079V37.0547H142.848Z"
      fill="#3C966E"
    />
    <path
      d="M96.9093 38.2079C96.9093 41.4002 99.4791 43.9878 102.649 43.9878C105.82 43.9878 108.389 41.4002 108.389 38.2079V37.0547H96.9023V38.2079H96.9093Z"
      fill="#3C966E"
    />
    <path
      d="M119.886 38.2079C119.886 41.4002 122.456 43.9878 125.626 43.9878C128.796 43.9878 131.366 41.4002 131.366 38.2079V37.0547H119.879V38.2079H119.886Z"
      fill="#3C966E"
    />
    <path d="M77.7708 16.2422L73.9512 37.0555H85.4312L86.7649 16.2422H77.7708Z" fill="#74D4A8" />
    <path d="M59.7921 16.2422L50.9727 37.0555H62.4596L68.7862 16.2422H59.7921Z" fill="#74D4A8" />
    <path
      d="M40.4584 18.2993L28.0078 37.0595H39.4948L50.8072 16.2461H44.2711C42.7418 16.2391 41.3103 17.0126 40.4584 18.2993Z"
      fill="#74D4A8"
    />
    <path
      d="M131.541 16.2414L142.853 37.0547H154.34L141.89 18.2946C141.038 17.0078 139.606 16.2344 138.063 16.2344H131.541V16.2414Z"
      fill="#74D4A8"
    />
    <path d="M113.555 16.2422L119.881 37.0555H131.368L122.549 16.2422H113.555Z" fill="#74D4A8" />
    <path d="M95.582 16.2422L96.9158 37.0555H108.403L104.576 16.2422H95.582Z" fill="#74D4A8" />
    <path d="M115.703 77.2891H104.32V79.5673H115.703V77.2891Z" fill="white" />
    <path d="M115.703 81.8438H104.32V84.122H115.703V81.8438Z" fill="white" />
    <path
      d="M119.572 71.0777C113.497 65.5158 106.528 65.5158 100.453 71.0777C101.235 71.9988 101.633 72.4699 102.422 73.3911C107.667 68.912 112.359 68.912 117.589 73.3911C118.392 72.4699 118.776 71.9988 119.572 71.0777Z"
      fill="white"
    />
    <path d="M154.572 102.008H116.787V114.693H154.572V102.008Z" fill="#D68189" />
    <path d="M154.572 97.25H116.787V109.935H154.572V97.25Z" fill="#CACDD1" />
    <path d="M157.717 95.6602H113.641V98.8314H157.717V95.6602Z" fill="#555152" />
    <path d="M157.717 98.8359H113.641V108.35H157.717V98.8359Z" fill="#CACDD1" />
    <path d="M137.257 98.8359H134.107V125.788H137.257V98.8359Z" fill="#2E2633" />
    <path d="M154.572 95.6602H116.787V108.345H154.572V95.6602Z" fill="#FF6B6B" />
    <path d="M151.422 95.6602H119.936V105.174H151.422V95.6602Z" fill="#C44D58" />
    <path d="M148.264 95.6602H123.076V102.003H148.264V95.6602Z" fill="#FF6B6B" />
    <path d="M116.783 111.488H101.043V117.831H116.783V111.488Z" fill="#D68189" />
    <path d="M116.78 111.48H113.631V125.747H116.78V111.48Z" fill="#2E2633" />
    <path d="M116.783 108.309H101.043V111.48H116.783V108.309Z" fill="#C44D58" />
    <path d="M116.783 109.922H101.043V111.476H116.783V109.922Z" fill="#555152" />
    <path
      d="M98.4453 89.7828L101.043 102.939V125.749H104.192V102.636L101.86 90.8798L101.532 89.1641L98.4453 89.7828Z"
      fill="#2E2633"
    />
    <path d="M170.306 111.48H154.566V117.823H170.306V111.48Z" fill="#D68189" />
    <path d="M157.708 111.48H154.559V125.747H157.708V111.48Z" fill="#2E2633" />
    <path d="M170.306 108.301H154.566V111.472H170.306V108.301Z" fill="#C44D58" />
    <path d="M170.306 109.938H154.566V111.491H170.306V109.938Z" fill="#555152" />
    <path
      d="M145.121 94.0781H156.14C156.14 94.95 155.435 95.6602 154.569 95.6602H146.699C145.833 95.6673 145.121 94.95 145.121 94.0781Z"
      fill="#E6EBEA"
    />
    <path
      d="M138.826 90.9135V86.1602H143.547V90.9135C143.547 91.7854 142.841 92.4956 141.975 92.4956V94.0777C142.841 94.0777 143.547 94.7878 143.547 95.6598H138.826C138.826 94.7878 139.531 94.0777 140.397 94.0777V92.4956C139.531 92.4956 138.826 91.7854 138.826 90.9135Z"
      fill="#E6EBEA"
    />
    <path
      d="M126.232 94.0781H115.213C115.213 94.95 115.918 95.6602 116.784 95.6602H124.654C125.527 95.6673 126.232 94.95 126.232 94.0781Z"
      fill="#E6EBEA"
    />
    <path
      d="M132.527 90.9135V86.1602H127.807V90.9135C127.807 91.7854 128.512 92.4956 129.378 92.4956V94.0777C128.512 94.0777 127.807 94.7878 127.807 95.6598H132.527C132.527 94.7878 131.822 94.0777 130.956 94.0777V92.4956C131.815 92.4956 132.527 91.7854 132.527 90.9135Z"
      fill="#E6EBEA"
    />
    <path d="M141.964 92.4961H140.393V94.0782H141.964V92.4961Z" fill="#CACDD1" />
    <path d="M130.95 92.4961H129.379V94.0782H130.95V92.4961Z" fill="#CACDD1" />
    <path d="M15.948 23.4219H8.40234V24.4173H15.948V23.4219Z" fill="#393239" />
    <path d="M20.0392 10.1211H4.04102V12.3494H20.0392V10.1211Z" fill="#3B3B3B" />
    <path d="M24.0485 118.383H0.767578V122.821H24.0485V118.383Z" fill="#444443" />
    <path
      d="M19.5777 10.1187C19.5777 10.1187 17.9362 4.27395 13.3924 3.09588V0H11.2067V3.09588C6.66303 4.27395 5.02148 10.1187 5.02148 10.1187L6.75372 11.2328L11.2067 10.6392V118.392H13.3924V10.6392L17.8455 11.2328L19.5777 10.1187Z"
      fill="#3B3B3B"
    />
    <path d="M6.39844 118.388L8.31206 93.6484H16.3112L18.1341 118.388H6.39844Z" fill="#383838" />
    <path d="M8.22079 23.4252L5.5 12.3477H18.8591L16.0929 23.4252H8.22079Z" fill="#ECC44B" />
  </svg>
));
