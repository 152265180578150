/* eslint-disable no-alert */
import { CordovaUtil } from "utils/CordovaUtil";
import { Cordova } from "../@types/Cordova";
import { AuthorizationStatusType } from "../@types/Diagnostic";

declare const cordova: Cordova;

const enebleDiagnostic = () => "plugins" in cordova && "diagnostic" in cordova.plugins;

const getBluetoothAuthorizationStatus = (): Promise<AuthorizationStatusType | undefined> =>
  new Promise(resolve => {
    if (!CordovaUtil.isCordovaEnabled()) {
      resolve(undefined);
      return;
    }
    if (!enebleDiagnostic()) {
      /**
       * appVersion < 1.8.0
       */
      resolve("GRANTED");
      return;
    }
    cordova.plugins.diagnostic.getBluetoothAuthorizationStatus(
      (status: AuthorizationStatusType) => {
        resolve(status);
      },
      (error: string) => {
        alert(`${error}\nBluetoothの認証ステータスの取得に失敗しました。`);
        resolve(undefined);
      },
    );
  });

const requestBluetoothAuthorization = () => {
  if (!CordovaUtil.isCordovaEnabled() || !enebleDiagnostic()) {
    return;
  }
  cordova.plugins.diagnostic.requestBluetoothAuthorization(
    () => undefined,
    (error: string) => {
      alert(`${error}\nBluetoothの認証に失敗しました。`);
    },
    ["BLUETOOTH_ADVERTISE", "BLUETOOTH_CONNECT", "BLUETOOTH_SCAN"],
  );
};

export const DiagnosticUtil = Object.freeze({
  getBluetoothAuthorizationStatus,
  requestBluetoothAuthorization,
});
