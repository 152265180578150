import React, { useContext } from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { ShopData } from "records/ShopData";
import { OrderListTabType, displayableOrderListTabType } from "modules/order/model";
import {
  HeaderContainer,
  HeaderInner,
  HeaderTitle,
  BackButtonContainer,
} from "components/atoms/HeaderElement";
import { BackButton } from "components/atoms/BackButton";
import { OrderListHeaderTab } from "components/molecules/OrderListHeaderTab";
import { OrderListContext } from "./OrderListContext";

const Container = styled.div`
  flex-grow: 1;
`;

const OrderListHeaderTop = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OrderListTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin-bottom: -1.25em;
`;

type Props = {
  orderList: OrderData[];
  shopData: ShopData;
  orderListTabType: OrderListTabType;
};

export const SimulatorHeader: React.FC<Props> = React.memo(
  ({ orderList, shopData, orderListTabType }) => (
    <HeaderContainer>
      <HeaderInner>
        <Container>
          <OrderListHeaderTop>
            <HeaderTitle>注文一覧</HeaderTitle>
          </OrderListHeaderTop>
          <OrderListTabContainer>
            {displayableOrderListTabType.map(tabType => (
              <OrderListHeaderTab
                key={tabType}
                orderList={orderList}
                shopData={shopData}
                tabType={tabType}
                currentTabType={orderListTabType}
              />
            ))}
          </OrderListTabContainer>
        </Container>
      </HeaderInner>
    </HeaderContainer>
  ),
);
