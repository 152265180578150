import React from "react";
import styled from "styled-components";
import { colorsConst, fontWeightsConst } from "styles/const";

const Container = styled.div`
  text-align: center;
  font-weight: ${fontWeightsConst.BOLD};
  color: ${colorsConst.TEXT.PRIMARY};
  background-color: ${colorsConst.BACKGROUND.INFO};
`;

type Props = {
  children: React.ReactNode;
};

export const PickerInfo: React.FC<Props> = React.memo(({ children }) => (
  <Container>{children}</Container>
));
