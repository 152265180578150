export type AppError = {
  code: number;
  title: string;
  message: string;
};

const initialState: AppError = {
  code: 0,
  title: "",
  message: "",
};

const create = (args: Partial<AppError> = {}) => ({
  ...initialState,
  ...args,
});

const hasError = (error: AppError) => error.code !== 0;

export const AppError = Object.freeze({
  initialState,
  create,
  hasError,
});
