import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ShopItem } from "records/ShopItem";
import { ShopItemOptionSet } from "records/ShopItemOptionSet";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { ShopModel } from "modules/shop/model";
import { shopItemsSelector, optionSetListSelector } from "modules/shop/selectors";
import { colorsConst } from "styles/const";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalCloseButton,
  ModalBody,
  ScrollContainer,
  ScrollInner,
} from "components/molecules/ModalDialog";

const contentStyle: React.CSSProperties = {
  flexGrow: 1,
  flexShrink: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
} as const;

const innerStyle: React.CSSProperties = {
  flexGrow: 1,
  flexShrink: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
  padding: 0,
  backgroundColor: colorsConst.BACKGROUND.SECONDARY,
} as const;

const BodyInner = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1em;
`;

const Caption = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 1em;
  text-align: left;
`;

const BlankContainer = styled.div`
  padding: 2em 0;
  text-align: center;
`;

const MODAL_KEY = "FILTERED_SHOP_ITEMS_BY_OPTION_SET";
const useModal = modalHooksFactory(MODAL_KEY);

type Props = {};

export const FilteredShopItemsByOptionSetDialog: React.FC<Props> = React.memo(() => {
  const shopItems = useSelector(shopItemsSelector);
  const optionSetList = useSelector(optionSetListSelector);
  const { data, close } = useModal();

  const filteredShopItemList: ShopItem[] = useMemo(
    () =>
      "payload" in data && "optionSetId" in data.payload
        ? ShopModel.getFilteredShopItemListByOptionSetId(shopItems, data.payload.optionSetId)
        : [],
    [data, shopItems],
  );

  const targetOptionSet = useMemo(
    () =>
      "payload" in data && "optionSetId" in data.payload
        ? ShopModel.getOptionSetById(optionSetList, data.payload.optionSetId) ||
          ShopItemOptionSet.create()
        : ShopItemOptionSet.create(),
    [data, optionSetList],
  );

  return (
    <ModalDialog
      isOpen={data.isOpen}
      close={close}
      contentStyle={contentStyle}
      innerStyle={innerStyle}
    >
      <ModalDialogHeader handleClose={close}>設定されている商品</ModalDialogHeader>
      <ModalBody>
        <BodyInner>
          <Caption>以下の商品に「{targetOptionSet.option_set_name}」が設定されています。</Caption>
          {filteredShopItemList.length > 0 ? (
            <ScrollContainer>
              <ScrollInner>
                {filteredShopItemList.map(item => (
                  <div key={item.shop_item_id}>{item.item_name}</div>
                ))}
              </ScrollInner>
            </ScrollContainer>
          ) : (
            <BlankContainer>設定されている商品はありません</BlankContainer>
          )}
        </BodyInner>
      </ModalBody>
    </ModalDialog>
  );
});
