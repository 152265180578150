/* eslint-disable no-alert */
import React, { useCallback, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { OrderNotification, NOTIFICATION_TYPE } from "records/OrderNotification";
import { ORDER_RECEIVE_TYPE } from "records/OrderData";
import { systemOpenedModal, userTouchedLogoutButton } from "modules/app/actions";
import { AppModel, DEVICE_TOKEN_KEY } from "modules/app/model";
import { appVersionSelector, printerSelector } from "modules/app/selectors";
import { userDebugUpdateNotificationStack } from "modules/order/actions";
import {
  userAccessedToPageThatNeedsShopData,
  userAccessedToPageThatNeedsShopItemParentCategories,
} from "modules/shop/actions";
import {
  shopStaffSelector,
  shopDataSelector,
  isGrocerySelector,
  existsShopItemParentCategoriesSelector,
} from "modules/shop/selectors";
import { LocalStorage } from "utils/LocalStorage";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { IconShop } from "components/atoms/Icons/IconShop";
import { IconShopItem } from "components/atoms/Icons/Navigation/IconShopItem";
import { IconChartBar } from "components/atoms/Icons/IconChartBar";
import { IconQuestion } from "components/atoms/Icons/IconQuestion";
import { Contents } from "components/atoms/Contents";
import { Text } from "components/atoms/Text";
import { textLinkStyle } from "components/atoms/TextLink";
import { Article } from "components/organisms/Article";
import {
  SettingListItem,
  SettingListItemInner,
  SettingListItemTitle,
  SettingListItemCaption,
} from "components/organisms/SettingListItem";
import { LocalPaymentNoticeDialog } from "components/organisms/OrderDetail/LocalPaymentNoticeDialog";
import { FindingCrewDialog } from "components/organisms/OrderDetail/FindingCrewDialog";
import { WaitComboOrderDialog } from "components/organisms/OrderDetail/WaitComboOrderDialog";
import { ConfirmOrderRestartByItemDialog } from "components/organisms/ConfirmOrderRestartByItemDialog";
import { ForceUpdateDialog } from "components/organisms/ForceUpdateDialog";
import { PermissionContainer, PermissionContext } from "components/organisms/PermissionContext";
import { ShopModel } from "modules/shop/model";

const Container = styled.div``;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5em 1em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

const InfoLabel = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
`;

const InfoValue = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: auto;
`;

const SettingListTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1em;
`;
const SettingListTitleIconContainer = styled.div`
  ${fontSize.XXLARGE};
`;
const SettingListTitleText = styled.div`
  ${fontSize.XLARGE};
`;

const StoreManagerLinkContainer = styled.div`
  margin-top: 1em;
`;

const TextLink = styled.a`
  ${textLinkStyle}
`;

type Props = {};

export const SettingTemplate: React.FC<Props> = React.memo(() => {
  const { role } = useContext(PermissionContext);
  const appVersion = useSelector(appVersionSelector);
  const shopStaff = useSelector(shopStaffSelector);
  const shopData = useSelector(shopDataSelector);
  const isGrocery = useSelector(isGrocerySelector);
  const existsShopItemParentCategories = useSelector(existsShopItemParentCategoriesSelector);
  const { isDiscoveredPrinter } = useSelector(printerSelector);

  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(userTouchedLogoutButton());
  }, [dispatch]);

  const handleInitDailyLogin = useCallback(() => {
    LocalStorage.removeItem("daylyLogin");
  }, []);

  const handleInitCurrentBuildVersion = useCallback(() => {
    LocalStorage.removeItem("currentBuildVersion");
  }, []);

  const displayDeviceToken = useCallback(() => {
    alert(`デバイストークン: ${LocalStorage.getItemOrDefault(DEVICE_TOKEN_KEY, "")}`);
  }, []);

  const handleUpdateOrderNotificationStack = useCallback(
    (notification: OrderNotification) => {
      dispatch(userDebugUpdateNotificationStack(notification));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopData());
    dispatch(userAccessedToPageThatNeedsShopItemParentCategories());
  }, [dispatch]);

  return (
    <>
      <Article withHeader title="その他" goBack="/orderList" withNavigation activePath="SETTING">
        <Contents>
          <InfoContainer>
            <InfoLabel>
              <Text size="SMALL" color="SECONDARY">
                ログイン中のアカウント
              </Text>
            </InfoLabel>
            <InfoValue>
              <Text>{shopStaff.display_name}</Text>
            </InfoValue>
          </InfoContainer>

          {AppModel.existsAppVersion(appVersion) ? (
            <InfoContainer>
              <InfoLabel>
                <Text size="SMALL" color="SECONDARY">
                  アプリバージョン
                </Text>
              </InfoLabel>
              <InfoValue>
                <Text>{`v${appVersion}`}</Text>
              </InfoValue>
            </InfoContainer>
          ) : undefined}

          {AppModel.isDevelopment() ? (
            <InfoContainer>
              <InfoLabel>
                <Text size="SMALL" color="SECONDARY">
                  URL（デバッグ表示）
                </Text>
              </InfoLabel>
              <InfoValue>
                <Text>{document.URL}</Text>
              </InfoValue>
            </InfoContainer>
          ) : undefined}

          <PermissionContainer role={role} performance="normalOnly">
            <SettingListTitle>
              <SettingListTitleIconContainer>
                <IconShop color={colorsConst.ICON.ACTIVE} />
              </SettingListTitleIconContainer>
              <SettingListTitleText>店舗の設定</SettingListTitleText>
            </SettingListTitle>
            <SettingListItem withArrow to="/shop">
              <SettingListItemInner>
                <SettingListItemTitle>
                  <Text size="LARGE">店舗情報の確認</Text>
                </SettingListItemTitle>
                <SettingListItemCaption>
                  <Text color="SECONDARY">公開されている店舗情報を確認できます</Text>
                </SettingListItemCaption>
              </SettingListItemInner>
            </SettingListItem>

            <SettingListItem withArrow to="/shopBusiness">
              <SettingListItemInner>
                <SettingListItemTitle>
                  <Text size="LARGE">営業時間の設定</Text>
                </SettingListItemTitle>
                <SettingListItemCaption>
                  <Text color="SECONDARY">曜日別や祝日の営業時間を設定できます</Text>
                </SettingListItemCaption>
              </SettingListItemInner>
            </SettingListItem>

            <SettingListItem withArrow to="/shopBusinessHoliday">
              <SettingListItemInner>
                <SettingListItemTitle>
                  <Text size="LARGE">臨時休業日の設定</Text>
                </SettingListItemTitle>
                <SettingListItemCaption>
                  <Text color="SECONDARY">店舗の休業日を設定できます</Text>
                </SettingListItemCaption>
              </SettingListItemInner>
            </SettingListItem>

            <StoreManagerLinkContainer>
              <div>
                <Text weight="BOLD">店舗情報の変更や商品の編集</Text>
              </div>
              <div>
                <TextLink href={`${process.env.REACT_APP_STORE_MANAGER_DOMAIN}/chain/login/index`}>
                  ストアマネージャーに移動する
                </TextLink>
              </div>
            </StoreManagerLinkContainer>

            {existsShopItemParentCategories ? (
              <>
                <SettingListTitle>
                  <SettingListTitleIconContainer>
                    <IconShopItem color={colorsConst.ICON.ACTIVE} />
                  </SettingListTitleIconContainer>
                  <SettingListTitleText>商品の設定</SettingListTitleText>
                </SettingListTitle>

                <SettingListItem withArrow to="/shopItemParentCategories">
                  <SettingListItemInner>
                    <SettingListItemTitle>
                      <Text size="LARGE">セクションカテゴリの並び替え</Text>
                    </SettingListItemTitle>
                    <SettingListItemCaption>
                      <Text color="SECONDARY">
                        商品一覧内のセクションカテゴリの並び替えができます
                      </Text>
                    </SettingListItemCaption>
                  </SettingListItemInner>
                </SettingListItem>
              </>
            ) : undefined}
            <SettingListItem withArrow to="/showImageSettingTemplate">
              <SettingListItemInner>
                <SettingListItemTitle>
                  <Text size="LARGE">商品画像の表示設定</Text>
                </SettingListItemTitle>
                <SettingListItemCaption>
                  <Text color="SECONDARY">商品画像の表示を設定できます</Text>
                </SettingListItemCaption>
              </SettingListItemInner>
            </SettingListItem>

            <SettingListItem withArrow to="/shopItemsStockCheck">
              <SettingListItemInner>
                <SettingListItemTitle>
                  <Text size="LARGE">在庫チェック</Text>
                </SettingListItemTitle>
                <SettingListItemCaption>
                  <Text color="SECONDARY">商品の在庫チェックと受付設定の変更を一括でできます</Text>
                </SettingListItemCaption>
              </SettingListItemInner>
            </SettingListItem>

            {ShopModel.isLazonaStaff(shopData, shopStaff) ? undefined : (
              <>
                <SettingListTitle>
                  <SettingListTitleIconContainer>
                    <IconChartBar color={colorsConst.ICON.ACTIVE} />
                  </SettingListTitleIconContainer>
                  <SettingListTitleText>売上管理</SettingListTitleText>
                </SettingListTitle>

                <SettingListItem withArrow to="/sales">
                  <SettingListItemInner>
                    <SettingListItemTitle>
                      <Text size="LARGE">売上管理</Text>
                    </SettingListItemTitle>
                    <SettingListItemCaption>
                      <Text color="SECONDARY">月ごとの売上が確認できます</Text>
                    </SettingListItemCaption>
                  </SettingListItemInner>
                </SettingListItem>
              </>
            )}
          </PermissionContainer>

          <SettingListTitle>
            <SettingListTitleIconContainer>
              <IconQuestion color={colorsConst.ICON.ACTIVE} />
            </SettingListTitleIconContainer>
            <SettingListTitleText>その他</SettingListTitleText>
          </SettingListTitle>

          <SettingListItem withArrow to="/pushNotificationSetting">
            <SettingListItemInner>
              <SettingListItemTitle>
                <Text size="LARGE">通知設定</Text>
              </SettingListItemTitle>
              <SettingListItemCaption>
                <Text color="SECONDARY">各種の通知設定ができます</Text>
              </SettingListItemCaption>
            </SettingListItemInner>
          </SettingListItem>

          {isDiscoveredPrinter && (
            <SettingListItem withArrow to="/printerSetting">
              <SettingListItemInner>
                <SettingListItemTitle>
                  <Text size="LARGE">プリンターの印刷設定</Text>
                </SettingListItemTitle>
                <SettingListItemCaption>
                  <Text color="SECONDARY">プリンターに関する設定ができます</Text>
                </SettingListItemCaption>
              </SettingListItemInner>
            </SettingListItem>
          )}

          {/*
          <PermissionContainer role={role} performance="normalOnly">
            <SettingListItem withArrow to="/shopInvitationCode">
              <SettingListItemInner>
                <SettingListItemTitle>
                  <Text size="LARGE">お客様招待コード</Text>
                </SettingListItemTitle>
                <SettingListItemCaption>
                  <Text color="SECONDARY">
                    初めてmenuを利用するお客様に配布が可能な招待コードを確認できます
                  </Text>
                </SettingListItemCaption>
              </SettingListItemInner>
            </SettingListItem>
          </PermissionContainer>
          */}

          <SettingListItem withArrow to="/simulator/2/1">
            <SettingListItemInner>
              <SettingListItemTitle>
                <Text size="LARGE">注文シミュレーション</Text>
              </SettingListItemTitle>
              <SettingListItemCaption>
                <Text color="SECONDARY">
                  基本的な注文の流れをシュミレーションで体験することができます
                </Text>
              </SettingListItemCaption>
            </SettingListItemInner>
          </SettingListItem>

          <PermissionContainer role={role} performance="normalOnly">
            {!isGrocery ? (
              <SettingListItem withArrow to="/simulator/1/2">
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">注文シミュレーション（予約）</Text>
                  </SettingListItemTitle>
                  <SettingListItemCaption>
                    <Text color="SECONDARY">
                      予約時の注文の流れをシュミレーションで体験することができます
                    </Text>
                  </SettingListItemCaption>
                </SettingListItemInner>
              </SettingListItem>
            ) : undefined}

            <SettingListItem withArrow to="/faq">
              <SettingListItemInner>
                <SettingListItemTitle>
                  <Text size="LARGE">よくある質問</Text>
                </SettingListItemTitle>
                <SettingListItemCaption>
                  <Text color="SECONDARY">よくある質問と回答を確認することができます</Text>
                </SettingListItemCaption>
              </SettingListItemInner>
            </SettingListItem>
          </PermissionContainer>

          {AppModel.isDevelopment() ? (
            <>
              <SettingListTitle>
                <SettingListTitleText>デバッグ機能</SettingListTitleText>
              </SettingListTitle>

              <SettingListItem withArrow to="/printerTest">
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">プリンターテスト</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.FASTEST_ORDER,
                      order_no: "",
                      receive_type: ORDER_RECEIVE_TYPE.DELIVERY,
                      is_combo: false,
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">新規注文通知ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.RESERVED_ORDER,
                      order_no: "",
                      receive_type: ORDER_RECEIVE_TYPE.TAKEOUT,
                      is_combo: false,
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">予約注文通知ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.FASTEST_ORDER,
                      order_no: "",
                      receive_type: ORDER_RECEIVE_TYPE.DELIVERY,
                      is_combo: true,
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">コンボ注文通知ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.COOKING_START,
                      order_no: "",
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">準備開始通知ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.EXIST_NOT_FINISHED_ORDER,
                      order_no: "",
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">準備未完了通知ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.APPROVE_COMBO_ORDER,
                      order_no: "",
                      driver_arrival_datetime: "1900-01-01 00:00:00",
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">注文成立通知ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.CANCEL_COMBO_ORDER,
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">コンボ注文不成立通知ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.NOT_FOUND_CREW,
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">ローカルエリア注文不成立通知ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.CREW_MISMATCH,
                      order_no: "",
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">クルーミスマッチ通知ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.BEFORE_AUTO_CANCEL,
                      order_no: "",
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">自動キャンセル2分前ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.AUTO_CANCEL,
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">自動キャンセル通知ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.BEFORE_DISABLE_APPROVAL,
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">受付不可前通知ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  handleUpdateOrderNotificationStack(
                    OrderNotification.create({
                      notification_type: NOTIFICATION_TYPE.ORDER_RESTART_BY_SHOP,
                      order_no: "",
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">店舗受付再開ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() => dispatch(systemOpenedModal("LOCAL_PAYMENT_NOTICE", {}))}
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">現地払い未決済ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() => dispatch(systemOpenedModal("FINDING_CREW", {}))}
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">配達員検索中ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() => dispatch(systemOpenedModal("WAIT_COMBO_ORDER", {}))}
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">コンボ注文受付待ちダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  dispatch(
                    systemOpenedModal("CONFIRM_ORDER_RESTART_BY_ITEMS", {
                      selectedItemIds: [],
                      stopItemIds: [16816],
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">商品受付再開確認ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  dispatch(
                    systemOpenedModal("CONFIRM_ORDER_RESTART_BY_ITEMS", {
                      selectedItemIds: [],
                      stopItemIds: [16816],
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">商品受付再開確認ダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem
                withArrow
                handleClick={() =>
                  dispatch(
                    systemOpenedModal("FORCE_UPDATE", {
                      message: `テキストが入ります。
テキストが入ります。
`,
                    }),
                  )
                }
              >
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">アプリ強制アップデートダイアログ</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem withArrow handleClick={displayDeviceToken}>
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">デバイストークン表示</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem withArrow handleClick={handleInitDailyLogin}>
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">日別ログイン初期化</Text>
                  </SettingListItemTitle>
                  <SettingListItemCaption>
                    <Text color="SECONDARY">
                      CMSで「未ログインにする」を実行後に使用、60秒以内に再送信される
                    </Text>
                  </SettingListItemCaption>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem withArrow handleClick={handleInitCurrentBuildVersion}>
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">webリソース再読み込み有効化</Text>
                  </SettingListItemTitle>
                  <SettingListItemCaption>
                    <Text color="SECONDARY">
                      端末に保存されたリソース管理番号を消去し、120秒以内に再読み込み処理を実行
                    </Text>
                  </SettingListItemCaption>
                </SettingListItemInner>
              </SettingListItem>

              <SettingListItem withArrow handleClick={handleLogout}>
                <SettingListItemInner>
                  <SettingListItemTitle>
                    <Text size="LARGE">ログアウト</Text>
                  </SettingListItemTitle>
                </SettingListItemInner>
              </SettingListItem>
            </>
          ) : undefined}
        </Contents>
      </Article>
      <LocalPaymentNoticeDialog />
      <FindingCrewDialog />
      <WaitComboOrderDialog />
      <ForceUpdateDialog />
      <ConfirmOrderRestartByItemDialog />
    </>
  );
});
