import React, { useMemo, useCallback } from "react";
import { OrderData, ORDER_STATE_TYPE, DELIVERY_STATE_TYPE } from "records/OrderData";
import { colorsConst } from "styles/const";
import { IconCancel } from "components/atoms/Icons/IconCancel";
import { IconCooking } from "components/atoms/Icons/IconCooking";
import { IconFinished } from "components/atoms/Icons/IconFinished";
import { IconHold } from "components/atoms/Icons/IconHold";
import { IconReserved } from "components/atoms/Icons/IconReserved";
import { IconSearch } from "components/atoms/Icons/IconSearch";
import { IconUnapproved } from "components/atoms/Icons/IconUnapproved";
import { IconWait } from "components/atoms/Icons/IconWait";
import { IconPicking } from "../atoms/Icons/IconPicking";
import { IconOnDelivery } from "../atoms/Icons/IconOnDelivery";
import { IconDelivered } from "../atoms/Icons/IconDelivered";
import { IconCrewUnmatch } from "../atoms/Icons/IconCrewUnmatch";

type Props = {
  orderData: OrderData;
  isGrocery?: boolean;
};

export const OrderStatusIcon: React.FC<Props> = React.memo(({ orderData, isGrocery = false }) => {
  const iconColor = useMemo(() => {
    if (isGrocery) {
      if (
        OrderData.isPendingOrder(orderData.order_state) ||
        OrderData.isNotFinishedOrder(orderData)
      ) {
        return colorsConst.ICON.INVERT;
      }
    } else {
      if (
        OrderData.isUnapprovedOrder(orderData.order_state) ||
        OrderData.isNotFinishedOrder(orderData)
      ) {
        return colorsConst.ICON.INVERT;
      }
      if (OrderData.isContractedOrder(orderData.order_state)) {
        return colorsConst.ICON.DISABLED;
      }
    }
    return colorsConst.ICON.ACTIVE;
  }, [isGrocery, orderData]);

  const renderIcon = useCallback(() => {
    switch (orderData.order_state) {
      case ORDER_STATE_TYPE.PENDING:
        return <IconUnapproved color={iconColor} />;
      case ORDER_STATE_TYPE.HOLD:
        return isGrocery ? <IconPicking color={iconColor} /> : <IconHold color={iconColor} />;
      case ORDER_STATE_TYPE.LOCAL_AREA_SHOP_APPROVE:
        return <IconSearch color={iconColor} />;
      case ORDER_STATE_TYPE.ONE_STORE_APPROVE:
        return <IconWait color={iconColor} />;
      case ORDER_STATE_TYPE.APPROVE:
        return OrderData.isReservedOrder(orderData.time_type) &&
          !orderData.cooking_start_able_flag ? (
          <IconReserved color={iconColor} />
        ) : (
          <IconCooking color={iconColor} />
        );
      case ORDER_STATE_TYPE.LOCAL_AREA_DRIVER_APPROVE:
      case ORDER_STATE_TYPE.ALL_STORES_APPROVE:
        return <IconCooking color={iconColor} />;
      case ORDER_STATE_TYPE.FINISH:
        if (orderData.delivery_state === DELIVERY_STATE_TYPE.ON_DELIVERY) {
          return <IconOnDelivery color={iconColor} />;
        }
        if (orderData.delivery_state === DELIVERY_STATE_TYPE.DELIVERED) {
          return <IconDelivered color={iconColor} />;
        }
        return <IconFinished color={iconColor} />;
      case ORDER_STATE_TYPE.AUTO_CANCEL:
      case ORDER_STATE_TYPE.DENY:
      case ORDER_STATE_TYPE.CANCEL:
      case ORDER_STATE_TYPE.PAYMENT_CANCEL:
      case ORDER_STATE_TYPE.OTHER_STORE_CANCEL:
      case ORDER_STATE_TYPE.LOCAL_AREA_DRIVER_NOT_FOUND_CANCEL:
        return orderData.delivery_state === DELIVERY_STATE_TYPE.CREW_UNMATCH_CANCEL ? (
          <IconCrewUnmatch color={iconColor} />
        ) : (
          <IconCancel color={iconColor} />
        );
      default:
        return undefined;
    }
  }, [orderData, iconColor, isGrocery]);
  return <>{renderIcon()}</>;
});
