import React, { useMemo } from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { fontSize } from "styles/mixins";
import { OrderList } from "./OrderList";

const Container = styled.div``;

const OrderListLabel = styled.div`
  ${fontSize.XXLARGE};
  margin-bottom: 0.5em;
`;

type Props = {
  orderList: OrderData[];
  type: "approved" | "unapproved";
  handleClick: (orderId: string) => void;
};

export const PendingOrderList: React.FC<Props> = React.memo(({ orderList, type, handleClick }) => {
  const labelText = useMemo(() => {
    switch (type) {
      case "approved":
        return "成立済み";
      case "unapproved":
        return "成立待ち";
      // skip default case
    }
  }, [type]);

  return (
    <Container>
      <OrderListLabel>{`${labelText}（${orderList.length}件）`}</OrderListLabel>
      <OrderList list={orderList} handleClick={handleClick} />
    </Container>
  );
});
