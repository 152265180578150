import React, { useState, useMemo, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { OrderItemData, DraftItem } from "records/OrderItemData";
import { colorsConst } from "styles/const";
import { PermissionData } from "utils/RbacUtils";
import { PermissionContext } from "components/organisms/PermissionContext";
import { ShopStaff } from "records/ShopStaff";
import { ShopModel } from "modules/shop/model";
import { shopStaffSelector, shopDataSelector } from "modules/shop/selectors";
import { OrderDetailItemCount } from "./OrderDetailItemCount";
import { OrderDetailItemInfo } from "./OrderDetailItemInfo";

const Container = styled.div`
  padding-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`;

const ItemCountContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 1em;
  border-right: 1px solid ${colorsConst.BORDER.THIN};
`;

const ItemInfoContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 1em;
`;

type Props = {
  isHistory: boolean;
  draftItem: DraftItem;
  orderData: OrderData;
  draftItems: DraftItem[];
  canChangeItemCount: boolean;
  canDisplayCheckBox: boolean;
  maxCount: number;
  isContainRequiredItemOrder: boolean;
  isAllRequiredItemsChecked: boolean;
  isSubstitute?: boolean;
  isSubsutituteItemActive?: boolean;
  isGrocery: boolean;
  submitting: boolean;
  setDraftItems?: (value: React.SetStateAction<DraftItem[]>) => void;
  handleChangePickStatus?: (draftItem: DraftItem, checked: boolean) => void;
};

export const OrderDetailItemCountContainer: React.FC<Props> = React.memo(
  ({
    isHistory,
    draftItem,
    orderData,
    draftItems,
    canChangeItemCount,
    canDisplayCheckBox,
    maxCount,
    isContainRequiredItemOrder,
    isAllRequiredItemsChecked,
    isSubstitute = false,
    isSubsutituteItemActive = false,
    isGrocery,
    submitting,
    setDraftItems,
    handleChangePickStatus,
  }) => {
    const { id, role } = useContext(PermissionContext);

    const shopData = useSelector(shopDataSelector);
    const shopStaff = useSelector(shopStaffSelector);

    const [isValidItemCount, setIsValidItemCount] = useState<boolean>(true);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const targetItem = useMemo(() => {
      if (OrderData.isUnapprovedOrder(orderData.order_state) && draftItem.is_substitute_item) {
        return Object.values(orderData.substitute_item_list).find(
          value => value.order_shop_item_id_group === draftItem.order_shop_item_id_group,
        );
      }
      return orderData.item_list.find(
        i => i.order_shop_item_id_group === draftItem.order_shop_item_id_group,
      );
    }, [orderData, draftItem]);

    const isRequired = useMemo(() => OrderItemData.isRequired(draftItem), [draftItem]);

    const permissionData: PermissionData = useMemo(
      () =>
        ShopModel.isLazonaStaff(shopData, shopStaff)
          ? {
              staff_id: ShopStaff.getLazonaStaffParentCategoryId(shopStaff) as number,
              owner_id: ShopStaff.getLazonaStaffParentCategoryId(orderData.staff) as number,
            }
          : {
              staff_id: id,
              owner_id: orderData.staff.staff_id,
            },
      [orderData, shopStaff, shopData, id],
    );

    useEffect(() => {
      if (typeof targetItem !== "undefined") {
        if (isGrocery && targetItem.is_sell_by_weight && !targetItem.is_picked) {
          setIsValidItemCount(false);
        } else {
          setIsValidItemCount(true);
        }
      }
    }, [isGrocery, targetItem]);

    return typeof targetItem !== "undefined" ? (
      <Container>
        <ItemCountContainer>
          <OrderDetailItemCount
            isHistory={isHistory}
            draftItem={draftItem}
            targetItem={targetItem}
            orderData={orderData}
            draftItems={draftItems}
            canChangeItemCount={canChangeItemCount}
            maxCount={maxCount}
            isGrocery={isGrocery}
            isContainRequiredItemOrder={isContainRequiredItemOrder}
            isAllRequiredItemsChecked={isAllRequiredItemsChecked}
            permissionData={permissionData}
            role={role}
            submitting={submitting}
            setIsDirty={setIsDirty}
            setDraftItems={setDraftItems}
            setIsValidItemCount={setIsValidItemCount}
          />
        </ItemCountContainer>
        <ItemInfoContainer>
          <OrderDetailItemInfo
            draftItem={draftItem}
            targetItem={targetItem}
            draftItems={draftItems}
            isRequired={isRequired}
            isContainRequiredItemOrder={isContainRequiredItemOrder}
            isAllRequiredItemsChecked={isAllRequiredItemsChecked}
            isSubstitute={isSubstitute}
            isSubsutituteItemActive={isSubsutituteItemActive}
            isDirty={isDirty}
            isValidItemCount={isValidItemCount}
            canDisplayCheckBox={canDisplayCheckBox}
            submitting={submitting}
            permissionData={permissionData}
            role={role}
            handleChangePickStatus={handleChangePickStatus}
          />
        </ItemInfoContainer>
      </Container>
    ) : null;
  },
);
