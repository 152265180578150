import React, { useMemo, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { zIndexConst } from "styles/const";
import { NOTIFICATION_TYPE } from "records/OrderNotification";
import { systemInitNotificationStack } from "modules/order/actions";
import { OrderModel } from "modules/order/model";
import { notificationStackSelector } from "modules/order/selectors";
import { shopDataSelector } from "modules/shop/selectors";
import { NotificationBanner } from "./NotificationBanner";
import { NotificationDialog } from "./NotificationDialog";

const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: ${zIndexConst.MODAL - 1};
  top: 0;
  left: 0;
  width: 100vw;
  display: ${p => (p.isOpen ? "flex" : "none")};
  overflow: visible;
`;

type Props = {
  onOrderDetail: boolean;
};

export const OrderNotification: React.FC<Props> = React.memo(({ onOrderDetail }) => {
  const { notifications, isDebug, isSimulator } = useSelector(notificationStackSelector);
  const shopData = useSelector(shopDataSelector);

  const displayableNotifications = useMemo(
    () => OrderModel.getDisplayableNotifications(notifications, onOrderDetail),
    [notifications, onOrderDetail],
  );

  const isOpen = useMemo(
    () =>
      typeof displayableNotifications.fullScreen !== "undefined" ||
      typeof displayableNotifications.banner !== "undefined",
    [displayableNotifications],
  );

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(systemInitNotificationStack());
  }, [dispatch]);

  useEffect(
    () => () => {
      handleClose();
    },
    [handleClose],
  );

  return (
    <Container isOpen={isOpen}>
      {isOpen ? (
        <>
          {typeof displayableNotifications.banner !== "undefined" ? (
            <NotificationBanner
              notification={displayableNotifications.banner}
              shopData={shopData}
              handleClose={handleClose}
              backdrop={displayableNotifications.backdrop}
              isDebug={isDebug}
              isSimulator={isSimulator}
            />
          ) : undefined}
          {typeof displayableNotifications.fullScreen !== "undefined" ? (
            <NotificationDialog
              notification={displayableNotifications.fullScreen}
              shopData={shopData}
              handleClose={handleClose}
              isDebug={isDebug}
              isSimulator={isSimulator}
            />
          ) : undefined}
        </>
      ) : undefined}
    </Container>
  );
});
