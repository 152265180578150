import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
`;

const ScrollContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1em;
  overflow: auto;
`;

type Props = {
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
};

export const Contents: React.FC<Props> = React.memo(({ children, containerStyle, innerStyle }) => (
  <Container style={containerStyle}>
    <ScrollContainer style={innerStyle}>{children}</ScrollContainer>
  </Container>
));
