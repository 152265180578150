import firebase from "firebase/app";
import { put, delay, select } from "redux-saga/effects";
import { fb } from "utils/Firebase";
import { Utility } from "utils/Utility";
import { DateUtils } from "utils/DateUtils";
import { LocalStorage } from "utils/LocalStorage";
import { AppModel, INTERVAL_UPDATE_DAILY_LOGIN } from "../model";
import { serverDateTimeSelector } from "../selectors";
import { updateServerDateTime, SystemUpdateDailyLoginAction } from "../actions";

export function* systemSendDailyLoginSaga(action: SystemUpdateDailyLoginAction) {
  while (true) {
    try {
      const latestServerDateTime: string = yield select(serverDateTimeSelector);
      yield delay(INTERVAL_UPDATE_DAILY_LOGIN);
      const currentServerDateTime: string = yield select(serverDateTimeSelector);
      const targetDate = DateUtils.dateToString(
        DateUtils.getDateWithAddMinutes(latestServerDateTime, 1),
        "YYYY-MM-DD HH:mm:ss",
      );
      if (latestServerDateTime === currentServerDateTime) {
        yield put(updateServerDateTime(targetDate));
      }
      const { shopId, shopStaffId, useFirestore } = action.payload;
      if (useFirestore) {
        const targetDateStr = DateUtils.isOverMidNight(targetDate)
          ? DateUtils.dateToString(DateUtils.subtractOneDay(targetDate), "YYYY-MM-DD")
          : DateUtils.dateToString(targetDate, "YYYY-MM-DD");
        if (!AppModel.isSubmittedTodaysLogin(targetDateStr)) {
          const hash = `${Utility.getMd5String(shopId.toString())}_${shopId}`;
          fb.firestore()
            .collection(process.env.REACT_APP_FIREBASE_DAILY_LOGIN_COLLECTION_NAME as string)
            .doc(hash)
            .set({
              shop_id: shopId,
              shop_staff_login_id: shopStaffId,
              login_date: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
              LocalStorage.setItem("daylyLogin", JSON.stringify([targetDateStr]));
            });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
}
