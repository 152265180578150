import React, { useCallback } from "react";
import styled from "styled-components";
import { DateUtils } from "utils/DateUtils";
import { colorsConst } from "styles/const";
import { textLinkStyle } from "components/atoms/TextLink";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5em;
  padding: 0.5em 1em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

const Holiday = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const TextLink = styled.button`
  ${textLinkStyle}
`;

type Props = {
  holiday: string;
  handleClick: (date: string) => void;
};

export const HolidayListItem: React.FC<Props> = React.memo(({ holiday, handleClick }) => {
  const handleClickElement = useCallback(() => {
    handleClick(holiday);
  }, [holiday, handleClick]);
  return (
    <Container onClick={handleClickElement}>
      <Holiday>{`${DateUtils.dateToString(holiday, "YYYY年MM月DD日")}（${DateUtils.getWeekdayString(
        holiday,
      )}）`}</Holiday>
      <TextLink>削除</TextLink>
    </Container>
  );
});
