export type LatLng = Readonly<{
  latitude: string;
  longitude: string;
}>;

const initialState: LatLng = {
  latitude: "",
  longitude: "",
};

const create = (args: Partial<LatLng> = {}) => ({
  ...initialState,
  ...args,
});

export const LatLng = Object.freeze({
  initialState,
  create,
});
