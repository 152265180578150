import { Task } from "redux-saga";
import { fork, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../actions";
import { disconnectPrinterSaga } from "./disconnectPrinterSaga";

export function* systemDisconnectPrinterSaga() {
  const task: Task = yield fork(disconnectPrinterSaga);
  yield takeLatest(ActionTypes.S_STOP_DISCONNECT_PRINTER, stopDisconnectPrinterSaga);
  function* stopDisconnectPrinterSaga() {
    if (!task.isCancelled()) {
      yield task.cancel();
    }
  }
}
