import React, { useMemo, useCallback } from "react";
import styled, { keyframes, Keyframes } from "styled-components";
import { OrderData } from "records/OrderData";
import { ShopData } from "records/ShopData";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Utility } from "utils/Utility";
import { Text } from "components/atoms/Text";
import { PickerInfo } from "components/atoms/PickerInfo";
import { OrderItem } from "components/molecules/OrderItem";
import { OrderInfo } from "components/molecules/OrderInfo";
import { ArrowContainer } from "components/molecules/ArrowContainer";
import { OrderStatusIcon } from "./OrderStatusIcon";

const Container = styled.div`
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  & + & {
    margin-top: 1em;
  }
`;

const AnimationContainer = styled.div<{ isWarning: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  ${p =>
    p.isWarning
      ? `
    color: ${colorsConst.TEXT.WHITE};
    background-color: ${colorsConst.BACKGROUND.DANGER};
  `
      : `
    background-color: ${colorsConst.BACKGROUND.WHITE};
  `}
  overflow: hidden;
`;

const animations: Keyframes = keyframes`
    0% {
      left: 0;
    }
    50% {
      left: 150%;
    }
    100% {
      left: 150%;
    }
  `;

const LightSweep = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3em;
  margin-left: -3em;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
  animation: 2.5s ${animations} ease 1s infinite normal;
`;

const Inner = styled.div`
  position: relative;
  z-index: 2;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.5em;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const OrderStatusContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OrderStatusIconContainer = styled.div`
  font-size: 2.5em;
  line-height: 1;
`;

const OrderStatusTextContainer = styled.div`
  width: 5em;
  ${fontSize.SMALL};
  text-align: center;
`;

const OrderStatusText = styled.div`
  white-space: nowrap;
`;

const OrderItemContainer = styled.div``;

type Props = {
  orderData: OrderData;
  shopData: ShopData;
  notDisplayOrderItem?: boolean;
  isHistory?: boolean;
  handleClick: (orderId: string) => void;
};

export const OrderListItem: React.FC<Props> = React.memo(
  ({ orderData, shopData, notDisplayOrderItem = true, isHistory, handleClick }) => {
    const isGrocery = useMemo(() => ShopData.isGrocery(shopData.shop_type), [shopData]);

    const isUnapproved = useMemo(
      () =>
        isGrocery
          ? OrderData.isPendingOrder(orderData.order_state)
          : OrderData.isUnapprovedOrder(orderData.order_state),
      [orderData, isGrocery],
    );

    const isNotFinished = useMemo(() => OrderData.isNotFinishedOrder(orderData), [orderData]);

    const arrowContainerStyle: React.CSSProperties = useMemo(
      () => ({
        position: "relative",
        zIndex: 2,
        backgroundColor: isUnapproved || isNotFinished ? "inherit" : undefined,
      }),
      [isUnapproved, isNotFinished],
    );

    const canDisplayPickerInfo = useMemo(
      () => OrderData.canDisplayPickerInfo(isGrocery, orderData),
      [isGrocery, orderData],
    );

    const displayableStateTextArray = useMemo(
      () =>
        Utility.splitTextByLineFeed(
          OrderData.getPresentationOrderStateText({ orderData, isGrocery, useLinefeed: true }),
        ),
      [orderData, isGrocery],
    );

    const handleClickWrap = useCallback(() => {
      handleClick(orderData.order_no);
    }, [orderData, handleClick]);

    return (
      <Container onClick={handleClickWrap}>
        {canDisplayPickerInfo ? (
          <PickerInfo>{`対応者：${orderData.staff.display_name}`}</PickerInfo>
        ) : undefined}
        <AnimationContainer isWarning={isUnapproved || isNotFinished}>
          <Inner>
            <Header>
              <OrderInfo
                orderData={orderData}
                isWarning={isUnapproved || isNotFinished}
                isHistory={isHistory}
              />
              <OrderStatusContainer>
                <OrderStatusIconContainer>
                  <OrderStatusIcon orderData={orderData} isGrocery={isGrocery} />
                </OrderStatusIconContainer>
                <OrderStatusTextContainer>
                  {displayableStateTextArray.map((text, index) => (
                    <OrderStatusText key={index}>
                      <Text size="SMALL" weight="BOLD">
                        {text}
                      </Text>
                    </OrderStatusText>
                  ))}
                </OrderStatusTextContainer>
              </OrderStatusContainer>
            </Header>
            {!isHistory && !notDisplayOrderItem ? (
              <OrderItemContainer>
                {orderData.item_list.map((item, index) => (
                  <OrderItem key={index} item={item} isWarning={isUnapproved || isNotFinished} />
                ))}
              </OrderItemContainer>
            ) : undefined}
          </Inner>
          <ArrowContainer style={arrowContainerStyle} />
          {isUnapproved ? <LightSweep /> : undefined}
        </AnimationContainer>
      </Container>
    );
  },
);
