import { put, call, all, delay } from "redux-saga/effects";
import { Printer } from "records/Printer";
import { PrintUtils } from "utils/PrintUtils";
import { systemSetPrinterStatus, updateConnectedPrinter } from "../actions";
import { MAX_CONNECT_PRINTER_RETRY_COUNT, CONNECT_PRINTER_RETRY_INTERVAL } from "../model";

export function* connectPrinterSaga(printer: Printer) {
  yield put(systemSetPrinterStatus("connecting"));
  let retryCount = 1;
  let errorString = "";
  while (retryCount <= MAX_CONNECT_PRINTER_RETRY_COUNT) {
    const {
      connectResult,
      connectError,
    }: {
      connectResult: string;
      connectError: string;
    } = yield call(PrintUtils.connect, printer.portName, printer.modelName);
    if (connectResult && !connectError) {
      yield all([put(updateConnectedPrinter(true)), put(systemSetPrinterStatus("connected"))]);
      return { connectResult };
    }
    errorString = connectError;
    yield delay(CONNECT_PRINTER_RETRY_INTERVAL);
    retryCount += 1;
  }
  yield put(systemSetPrinterStatus("connectedFailed"));
  return { connectError: errorString };
}
