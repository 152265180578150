import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { FormState } from "final-form";
import { useForm, useFormState } from "react-final-form";
import {
  UpdateBusinessNationalHolidayForm,
  updateBusinessNationalHolidayFormValidationSchema,
} from "forms/updateBusinessNationalHoliday";
import { BusinessTime } from "records/BusinessTime";
import { BusinessType, BUSINESS_TYPE } from "records/BusinessWeekTime";
import { BusinessNationalHolidaySelectField } from "./BusinessNationalHolidaySelectField";
import { BusunessTypeField } from "./BusunessTypeField";
import { BusinessTimeField } from "./BusinessTimeField";

const Container = styled.div``;

type Props = {};

export const BusinessNationalHolidayFields: React.FC<Props> = React.memo(() => {
  const formProps = useForm();
  const formStateProps: FormState<UpdateBusinessNationalHolidayForm> = useFormState();

  const isSet = useMemo(
    () => formStateProps.values.business_national_holiday.business_type !== BUSINESS_TYPE.UNSET,
    [formStateProps],
  );

  const handleBusinessTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, type: BusinessType) => {
      formProps.change("business_national_holiday.business_type", type);
      if (type === BUSINESS_TYPE.UNSET) {
        formProps.change("business_national_holiday.business_times", []);
      } else {
        const updatedBusinessTimeArray = BusinessTime.getUpdatedBusinessTimesByBusinessType(
          formStateProps.values.business_national_holiday.business_times,
          type,
        );
        formProps.change("business_national_holiday.business_times", updatedBusinessTimeArray);
      }
    },
    [formProps, formStateProps],
  );

  return (
    <Container>
      <BusinessNationalHolidaySelectField
        current={formStateProps.values.business_national_holiday.business_type}
        handleChange={handleBusinessTypeChange}
      />
      {isSet ? (
        <>
          <BusunessTypeField
            name="business_national_holiday"
            handleChange={handleBusinessTypeChange}
          />
          <BusinessTimeField name="business_national_holiday.business_times" />
        </>
      ) : undefined}
    </Container>
  );
});
