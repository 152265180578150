export type ShopItemParentCategory = {
  parent_category_id: number;
  parent_category_name: string;
  parent_category_sort_order: number;
  is_stock_check: boolean;
};

const initialState: ShopItemParentCategory = {
  parent_category_id: 0,
  parent_category_name: "",
  parent_category_sort_order: 0,
  is_stock_check: false,
};

const create = (args: Partial<ShopItemParentCategory> = {}) => ({
  ...initialState,
  ...args,
});

const exists = (categories: ShopItemParentCategory[]) => categories.length > 0;

const getShopItemParentCategoryById = (id: number, categories: ShopItemParentCategory[]) =>
  categories.find(i => i.parent_category_id === id);

export const ShopItemParentCategory = Object.freeze({
  initialState,
  create,
  exists,
  getShopItemParentCategoryById,
});
