import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { userAccessedToPageThatNeedsShopBusiness } from "modules/shop/actions";
import { Article } from "components/organisms/Article";
import { Text } from "components/atoms/Text";
import { Contents } from "components/atoms/Contents";
import { ShopBusinessNationalHolidayEdit } from "components/organisms/ShopBusinessNationalHolidayEdit";

const TextContainer = styled.div`
  margin-bottom: 1em;
`;
const NoticeContainer = styled.div`
  padding-top: 1em;
`;

type Props = {};

export const ShopBusinessNationalHolidayEditTemplate: React.FC<Props> = React.memo(() => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopBusiness());
  }, [dispatch]);
  return (
    <Article
      withHeader
      title="祝日の営業時間設定"
      goBack="/shopBusiness"
      withNavigation
      activePath="SETTING"
    >
      <Contents>
        <TextContainer>
          <Text>
            祝日の営業時間を、「曜日別営業時間」と別にしたい場合に設定してください。ここで設定した営業時間は「曜日別営業時間」よりも優先して適用されます。
          </Text>
          <NoticeContainer>
            <Text>
              ※祝日の定義は
              <a href="https://www8.cao.go.jp/chosei/shukujitsu/gaiyou.html">
                内閣府が定める国民の祝日
              </a>
              に準じています。
            </Text>
          </NoticeContainer>
        </TextContainer>
        <ShopBusinessNationalHolidayEdit />
      </Contents>
    </Article>
  );
});
