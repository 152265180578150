import { put, call, fork, all } from "redux-saga/effects";
import { API_KEY, RestartOrderByOptionSetRequest, RestartOrderByOptionSetResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { updateSnackBar } from "modules/app/actions";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import {
  systemUpdateShopItemOptionStatus,
  UserSubmittedRestartOrderByOptionSetsAction,
} from "../actions";
import { ShopModel, SHOP_ITEM_SUBMIT_TYPE } from "../model";

export function* userSubmittedRestartOrderByOptionSetsSaga(
  action: UserSubmittedRestartOrderByOptionSetsAction,
) {
  try {
    const { optionSet } = action.payload;
    const params: RestartOrderByOptionSetRequest = {
      option_set_list: [optionSet],
    };
    const { result, error }: { result: RestartOrderByOptionSetResponse; error: unknown } =
      yield call(commonApiSaga, API_KEY.RESTART_ORDER_BY_OPTION_SET, params);
    const updatedIds = ShopModel.getShopItemIdsAndOptionIdsToBeUpdated(
      [optionSet],
      result.option_set_list,
    );
    if (result && !error) {
      yield all([
        put(systemUpdateShopItemOptionStatus(updatedIds.optionIds, SHOP_ITEM_SUBMIT_TYPE.RESTART)),
        put(updateSnackBar("オプションの受付を再開しました")),
      ]);
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
