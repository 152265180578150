import React from "react";

type Props = {};

export const SelectTimeOptions: React.FC<Props> = React.memo(() => {
  const diff = 15;
  const minTime = (60 * 6) / diff;
  const maxTime = (60 * 30) / diff;
  return (
    <>
      {Array(maxTime - minTime)
        .fill("")
        .reduce(
          (acc: JSX.Element[], _, i) => {
            const h = `00${Math.floor((diff * i) / 60) + 6}`.slice(-2);
            const m = `00${(diff * i) % 60}`.slice(-2);
            acc.push(
              <option value={h + m} key={i + 1}>
                {`${h}:${m}`}
              </option>,
            );
            return acc;
          },
          [
            <option value="" key={0}>
              --:--
            </option>,
          ],
        )}
    </>
  );
});
