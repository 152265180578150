import { put, fork, call, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { API_KEY, RestartOrderByShopResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { systemClosedModal, updateSnackBar } from "modules/app/actions";

export function* userSubmittedRestartOrderByShopSaga() {
  try {
    const { result, error }: { result: RestartOrderByShopResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.RESTART_ORDER_BY_SHOP,
      {},
    );
    if (result && !error) {
      yield all([put(push("/orderList")), put(updateSnackBar("店舗の受付を再開しました"))]);
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  } finally {
    yield put(systemClosedModal("ORDER_RESTART_BY_SHOP"));
  }
}
