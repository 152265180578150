import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API_KEY } from "apis";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { userSubmittedRestartOrderByShop } from "modules/shop/actions";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
} from "components/molecules/ModalDialog";

const MODAL_KEY = "ORDER_RESTART_BY_SHOP";
const useModal = modalHooksFactory(MODAL_KEY);

const isConnectedApiSelector = isConnectedApiSelectorFactory(API_KEY.RESTART_ORDER_BY_SHOP);

type Props = {};

export const OrderRestartByShopDialog: React.FC<Props> = React.memo(() => {
  const submitting = useSelector(isConnectedApiSelector);
  const { data, close } = useModal();

  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    dispatch(userSubmittedRestartOrderByShop());
  }, [dispatch]);

  return (
    <ModalDialog isOpen={data.isOpen} close={close}>
      <ModalDialogHeader handleClose={close}>店舗の受付再開</ModalDialogHeader>
      <ModalBody>
        <Text>店舗の受付を再開してよろしいですか？</Text>
      </ModalBody>
      <ModalFooter>
        <Button appearance="primary" handleClick={handleSubmit} loading={submitting}>
          店舗受付を再開する
        </Button>
      </ModalFooter>
    </ModalDialog>
  );
});
