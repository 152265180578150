import styled, { keyframes, Keyframes } from "styled-components";
import React, { useMemo } from "react";
import { IconCircle } from "./Icons/IconCircle";
import { Utility } from "../../utils/Utility";

const Container = styled("div")<{ invert?: boolean }>`
  display: inline-block;
  position: relative;
  width: 4em;
  height: 1em;
  line-height: 1;
  svg {
    stroke: ${p => (p.invert ? "#ffffff" : "#464646")};
  }
`;

const animations: Keyframes[] = Utility.rangeInt(0, 4).map(
  (val, index) => keyframes`
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(${300 - 300 * (index / 3)}%);
    }
    100% {
      transform: translateX(300%);
    }
  `,
);

const Item = styled("div")<{ index: number }>`
  position: absolute;
  top: 0;
  left: 0;
  animation: 1.5s ${p => animations[p.index]} ease-in-out 0s infinite alternate;
`;

type Props = {
  invert?: boolean;
  style?: React.CSSProperties;
};

export const Loading: React.FC<Props> = React.memo(props => {
  const loadingAnimation = useMemo(
    () =>
      Utility.rangeInt(0, 4).map(index => (
        <Item key={index} index={index}>
          <IconCircle />
        </Item>
      )),
    [],
  );
  return <Container {...props}>{loadingAnimation}</Container>;
});
