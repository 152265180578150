/* eslint-disable func-names*/
import * as Yup from "yup";
import { BusinessTime } from "records/BusinessTime";
import { BusinessWeekTime, BusinessType, BUSINESS_TYPE } from "records/BusinessWeekTime";

export type UpdateBusinessTimeForm = {
  business_week_time: BusinessWeekTime[];
};

export const initialUpdateBusinessTimeForm: UpdateBusinessTimeForm = {
  business_week_time: [],
};

const isAfter = (value: string | undefined, parent: BusinessTime) => {
  if (typeof value === "undefined") {
    return false;
  }
  const startTimeInt = parseInt(parent["start_time"], 10);
  const endTimeInt = parseInt(value, 10);
  if (Number.isNaN(startTimeInt) || Number.isNaN(endTimeInt)) {
    return false;
  }
  return startTimeInt < endTimeInt;
};

export const updateBusinessTimeFormValidationSchema = Yup.object({
  business_week_time: Yup.array().of(
    Yup.object().shape({
      week: Yup.number(),
      business_type: Yup.mixed<BusinessType>().oneOf(Object.values(BUSINESS_TYPE)),
      business_times: Yup.array().of(
        Yup.object()
          .shape({
            start_time: Yup.string().required("時間帯を選択してください"),
            end_time: Yup.string()
              .required("時間帯を選択してください")
              .test("isAfter", "正しい時間帯を選択してください", function (value) {
                return isAfter(value, this.parent);
              }),
          })
          .test("invalidSecondBusinessTime", "正しい時間帯を選択してください", function (value) {
            const businessTimes = this.parent;
            if (businessTimes.length < 2) {
              return true;
            }
            const firstBusinessEndTimeInt = parseInt(businessTimes[0]["end_time"], 10);
            const secondBusinessStartTimeInt = parseInt(businessTimes[1]["start_time"], 10);
            if (Number.isNaN(firstBusinessEndTimeInt) || Number.isNaN(secondBusinessStartTimeInt)) {
              return false;
            }
            return firstBusinessEndTimeInt < secondBusinessStartTimeInt;
          }),
      ),
    }),
  ),
})
  .strict(true)
  .noUnknown();
