import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { ShopItemCategory } from "records/ShopItemCategory";
import { systemOpenedModal } from "modules/app/actions";
import { colorsConst } from "styles/const";
import { Text } from "components/atoms/Text";
import { ShopItemForStockCheck } from "components/organisms/ShopItemForStockCheck";

const Container = styled.div``;

const CategoryNameContainer = styled.div`
  margin-top: 1em;
`;

const ItemListContainer = styled.div`
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

type Props = {
  shopItemCategory: ShopItemCategory;
  selectedItemIds: number[];
  handleCheck: (itemId: number, checked: boolean) => void;
};

export const ShopItemListForStockCheck: React.FC<Props> = React.memo(
  ({ shopItemCategory, selectedItemIds, handleCheck }) => {
    const dispatch = useDispatch();

    const handleClick = useCallback(
      (imagePath: string) => {
        dispatch(systemOpenedModal("ORDER_ITEM_IMAGE", { imagePath }));
      },
      [dispatch],
    );

    return (
      <Container>
        <CategoryNameContainer>
          <Text size="SMALL" weight="BOLD">{`カテゴリ：${shopItemCategory.category_name}`}</Text>
        </CategoryNameContainer>
        <ItemListContainer>
          {shopItemCategory.item_list.map(item => (
            <ShopItemForStockCheck
              key={item.shop_item_id}
              item={item}
              selectedItemIds={selectedItemIds}
              handleClick={handleClick}
              handleCheck={handleCheck}
            />
          ))}
        </ItemListContainer>
      </Container>
    );
  },
);
