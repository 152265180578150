import React, { useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import { OrderItemData } from "records/OrderItemData";
import { systemOpenedModal } from "modules/app/actions";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Text } from "components/atoms/Text";

const Container = styled.div``;

const ClickableText = styled.span`
  text-decoration: underline;
`;

const BuyOneLabel = styled.span`
  display: inline-block;
  margin-left: 1em;
  padding: 0.25em 0.5em;
  ${fontSize.XSMALL};
  color: ${colorsConst.TEXT.WHITE};
  background-color: ${colorsConst.TEXT.DANGER};
`;

const IconContainer = styled.div`
  display: inline-block;
  margin-left: 0.5em;
  ${fontSize.LARGE};
  color: ${colorsConst.TEXT.LINK};
`;

type Props = {
  item: OrderItemData;
  isDetail?: boolean;
};

export const ItemName: React.FC<Props> = React.memo(({ item, isDetail }) => {
  const isBuyOne = useMemo(() => OrderItemData.isBuyOne(item), [item]);

  const isCampaign = useMemo(() => OrderItemData.isCampaign(item), [item]);

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(systemOpenedModal("ORDER_ITEM_IMAGE", { imagePath: item.image_path }));
  }, [dispatch, item]);

  return (
    <Container>
      {isDetail ? (
        <ClickableText onClick={handleClick}>
          <Text size="XXLARGE" weight="BOLD">
            {item.item_name}
          </Text>
          <IconContainer>
            <FontAwesomeIcon icon={faImages} />
          </IconContainer>
        </ClickableText>
      ) : (
        <Text size="XLARGE" weight="BOLD">
          {item.item_name}
        </Text>
      )}
      {isBuyOne ? <BuyOneLabel>1品につき1品無料</BuyOneLabel> : undefined}
      {isCampaign ? <BuyOneLabel>+1無料</BuyOneLabel> : undefined}
    </Container>
  );
});
