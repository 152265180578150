import React, { useMemo, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { ShopData } from "records/ShopData";
import { systemOpenedModal } from "modules/app/actions";
import { userAccessedToPageThatNeedsShopData } from "modules/shop/actions";
import { shopDataSelector } from "modules/shop/selectors";
import { Contents } from "components/atoms/Contents";
import { Text } from "components/atoms/Text";
import { TextLink } from "components/atoms/TextLink";
import { IconBusinessSetting } from "components/atoms/Icons/IconBusinessSetting";
import { IconStopFastestOrder } from "components/atoms/Icons/IconStopFastestOrder";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Article } from "components/organisms/Article";
import { SettingListItem } from "components/organisms/SettingListItem";
import { OrderStopModal } from "components/organisms/OrderStopModal";

const ListItemInner = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
`;

const IconContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4em;
  height: 4em;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
  border-radius: 100%;
  svg {
    width: 2.5em;
    height: 2.5em;
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 1em;
  padding-left: 1em;
`;

const TextLinkContainer = styled.div`
  padding-top: 1em;
`;

type Props = {};

const ShopBusinessSettingTemplate: React.FC<Props> = React.memo(() => {
  const shopData = useSelector(shopDataSelector);

  const existsShopData = useMemo(() => ShopData.existsShopData(shopData.id), [shopData]);

  const canDisplayStopFastestOrder = useMemo(
    () => shopData.is_reserve_order_pick_time && shopData.is_immediate_reserve_hybrid,
    [shopData],
  );

  const dispatch = useDispatch();

  const handleClickStopFastestOrder = useCallback(() => {
    if (!shopData.is_immediate_delivery_paused) {
      dispatch(systemOpenedModal("STOP_FASTEST_ORDER", {}));
    }
  }, [dispatch, shopData]);

  const handleOpenOrderStopModal = useCallback(() => {
    if (shopData.shop_order_stop_flag) {
      dispatch(systemOpenedModal("ORDER_RESTART_BY_SHOP", {}));
    } else {
      dispatch(systemOpenedModal("ORDER_STOP_BY_SHOP", {}));
    }
  }, [dispatch, shopData]);

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopData());
  }, [dispatch]);

  return (
    <>
      <Article
        withHeader
        title="店舗受付設定"
        goBack="/orderList"
        withNavigation
        activePath="ORDER_LIST"
      >
        {!existsShopData ? (
          <LoadingContainer />
        ) : (
          <Contents>
            {canDisplayStopFastestOrder ? (
              <SettingListItem handleClick={handleClickStopFastestOrder} withArrow={false}>
                <ListItemInner>
                  <IconContainer>
                    <IconStopFastestOrder
                      color={
                        shopData.is_immediate_delivery_paused
                          ? colorsConst.ICON.DISABLED
                          : undefined
                      }
                    />
                  </IconContainer>
                  <TextContainer>
                    <div>
                      <Text
                        size="LARGE"
                        color={shopData.is_immediate_delivery_paused ? "QUATERNARY" : "PRIMARY"}
                      >
                        即配注文の停止
                      </Text>
                    </div>
                    <div>
                      <Text
                        color={shopData.is_immediate_delivery_paused ? "QUATERNARY" : "SECONDARY"}
                      >
                        店舗の受付を予約注文のみに変更できます
                      </Text>
                    </div>
                  </TextContainer>
                </ListItemInner>
              </SettingListItem>
            ) : undefined}
            <SettingListItem handleClick={handleOpenOrderStopModal}>
              <ListItemInner>
                <IconContainer>
                  <IconBusinessSetting />
                </IconContainer>
                <TextContainer>
                  <div>
                    <Text size="LARGE">
                      {shopData.shop_order_stop_flag
                        ? "店舗の受付を再開する"
                        : "店舗の受付を停止する"}
                    </Text>
                  </div>
                  <div>
                    <Text color="SECONDARY">店舗の受付状態を変更できます</Text>
                  </div>
                </TextContainer>
              </ListItemInner>
            </SettingListItem>
            <TextLinkContainer>
              <div>
                <Text weight="BOLD">定期的に準備時間の延長をしたい場合</Text>
              </div>
              <div>
                <TextLink to="/shopBusiness">営業時間の設定へ</TextLink>
              </div>
            </TextLinkContainer>
          </Contents>
        )}
      </Article>
      <OrderStopModal />
    </>
  );
});

export default withRouter(ShopBusinessSettingTemplate);
