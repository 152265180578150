import React, { useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { userAccessedToPageThatNeedsShopBusiness } from "modules/shop/actions";
import { Contents } from "components/atoms/Contents";
import { Text } from "components/atoms/Text";
import { Article } from "components/organisms/Article";
import { ShopBusinessBusyTimeEdit } from "components/organisms/ShopBusinessBusyTimeEdit";

const TextContainer = styled.div`
  margin-bottom: 1em;
`;

type Props = RouteComponentProps<{ week: string }> & {};

const ShopBusinessBusyTimeEditTemplate: React.FC<Props> = React.memo(
  ({
    match: {
      params: { week },
    },
  }) => {
    const weekNum = useMemo(() => parseInt(week, 10), [week]);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(userAccessedToPageThatNeedsShopBusiness());
    }, [dispatch]);

    return (
      <Article
        withHeader
        title="忙しい時間帯設定"
        goBack="/shopBusiness"
        withNavigation
        activePath="SETTING"
      >
        <Contents>
          <TextContainer>
            <Text>
              忙しい時間帯を登録すると、この時間帯に注文がきた時に、準備時間を延長することができます。
            </Text>
          </TextContainer>
          <ShopBusinessBusyTimeEdit week={weekNum} canSubmit={true} />
        </Contents>
      </Article>
    );
  },
);

export default withRouter(ShopBusinessBusyTimeEditTemplate);
