import { put, call, select } from "redux-saga/effects";
import {
  systemDisconnectPrinter,
  systemStopDisconnectPrinter,
  SystemPrintReceiptAction,
} from "../actions";
import { printerSelector } from "../selectors";
import { connectPrinterSaga } from "./connectPrinterSaga";
import { printReceiptSaga } from "./printReceiptSaga";

export function* systemPrintReceiptSaga(action: SystemPrintReceiptAction) {
  // 実行中の切断処理をキャンセル
  yield put(systemStopDisconnectPrinter());
  const { orderData, printCount } = action.payload;
  const { discoveredPrinter }: ReturnType<typeof printerSelector> = yield select(printerSelector);
  const {
    connectResult,
    connectError,
  }: {
    connectResult: string;
    connectError: string;
  } = yield call(connectPrinterSaga, discoveredPrinter);
  if (connectResult && !connectError) {
    yield call(printReceiptSaga, orderData, discoveredPrinter, printCount);
  }
  yield put(systemDisconnectPrinter());
}
