import { BusinessType, BUSINESS_TYPE } from "./BusinessWeekTime";
import { BusinessTime } from "./BusinessTime";

export type BusinessNationalHoliday = Readonly<{
  business_type: BusinessType;
  business_times: BusinessTime[];
  busy_times: BusinessTime[];
}>;

const initialState: BusinessNationalHoliday = {
  business_type: BUSINESS_TYPE.UNSET,
  business_times: [],
  busy_times: [],
};

const create = (args: Partial<BusinessNationalHoliday> = {}) => ({
  ...initialState,
  ...args,
  business_times: args.business_times ? args.business_times.map(i => BusinessTime.create(i)) : [],
  busy_times: args.busy_times ? args.busy_times.map(i => BusinessTime.create(i)) : [],
});

const existsNationalHolidaySetting = (type: BusinessType) => type !== BUSINESS_TYPE.UNSET;

export const BusinessNationalHoliday = Object.freeze({
  initialState,
  create,
  existsNationalHolidaySetting,
});
