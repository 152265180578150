import produce from "immer";
import {
  OrderData,
  OrderStateType,
  DeliveryStateType,
  ORDER_STATE_TYPE,
  ORDER_TIME_TYPE,
  ORDER_RECEIVE_TYPE,
  DELIVERY_STATE_TYPE,
} from "records/OrderData";
import { OrderItemData, DraftItem } from "records/OrderItemData";
import { OrderNotification, NotificationType, NOTIFICATION_TYPE } from "records/OrderNotification";
import { OrderNotificationStack } from "records/OrderNotificationStack";
import { ShopData, SHOP_TYPE } from "records/ShopData";
import { ShopStaff } from "records/ShopStaff";
import { DateUtils } from "utils/DateUtils";

export const REG_EXP_ORDER_LIST_PATH = /^\/orderList/;
export const REG_EXP_ORDER_DETAIL_PATH = /^\/orderDetail\/([A-Z0-9]{6})/;

export const DEFAULT_POLLING_INTERVAL = 30000;
export const WATCH_ORDER_LIST_INTERVAL = 60000;

export const ORDER_STATE_FILTER_TYPE = {
  APPROVED: 1,
  UNAPPROVED: 2,
  CANCELLED: 3,
} as const;

export type OrderStateFilterType = ValueOf<typeof ORDER_STATE_FILTER_TYPE>;

export const ORDER_HISTORY_STATE_FILTER_TYPE = {
  ALL: 1,
  FINISHED: 2,
  CANCELLED: 3,
} as const;

export type OrderHistoryStateFilterType = ValueOf<typeof ORDER_HISTORY_STATE_FILTER_TYPE>;

export const displayableOrderHistoryStateFilterType: ReadonlyArray<OrderHistoryStateFilterType> = [
  ORDER_HISTORY_STATE_FILTER_TYPE.ALL,
  ORDER_HISTORY_STATE_FILTER_TYPE.FINISHED,
  ORDER_HISTORY_STATE_FILTER_TYPE.CANCELLED,
] as const;

export type OrderDetailButtonContainerTextType = Readonly<{ text: string; emphasis: boolean }>;

export const ORDER_LIST_TAB_TYPE = {
  PENDING: 1,
  ON_PREPARING: 2,
  ON_DELIVERY: 3,
  FINISHED: 4,
} as const;

export type OrderListTabType = ValueOf<typeof ORDER_LIST_TAB_TYPE>;

export const displayableOrderListTabType: ReadonlyArray<OrderListTabType> = [
  ORDER_LIST_TAB_TYPE.PENDING,
  ORDER_LIST_TAB_TYPE.ON_PREPARING,
  ORDER_LIST_TAB_TYPE.ON_DELIVERY,
  ORDER_LIST_TAB_TYPE.FINISHED,
] as const;

export type OrderState = Readonly<{
  orderList: OrderData[];
  orderData: OrderData;
  busy_flag: boolean;
  can_deny_after_approve_flag: boolean;
  orderHistory: OrderData[];
  orderItemTotalPrice: number;
  notificationStack: OrderNotificationStack;
  orderListTabType: OrderListTabType;
}>;

const initialState: OrderState = {
  orderList: [],
  orderData: OrderData.create(),
  busy_flag: false,
  can_deny_after_approve_flag: false,
  orderHistory: [],
  orderItemTotalPrice: 0,
  notificationStack: OrderNotificationStack.create(),
  orderListTabType: ORDER_LIST_TAB_TYPE.PENDING,
};

const updateOrderList = (state: OrderState, list: OrderData[]) =>
  produce(state, draft => {
    draft.orderList = list.map(i => OrderData.create(i));
  });

const updateOrderDetail = (state: OrderState, order: OrderData) =>
  produce(state, draft => {
    draft.orderData = OrderData.create(order);
  });

const updateBusyFlag = (state: OrderState, flag: boolean) =>
  produce(state, draft => {
    draft.busy_flag = flag;
  });

const updateOrderHistory = (state: OrderState, list: OrderData[]) =>
  produce(state, draft => {
    draft.orderHistory = list.map(i => OrderData.create(i));
  });

const updateOrderItemTotalPrice = (state: OrderState, value: number) =>
  produce(state, draft => {
    draft.orderItemTotalPrice = value;
  });

const updateNotificationStack = (state: OrderState, notification: OrderNotificationStack) =>
  produce(state, draft => {
    draft.notificationStack = notification;
  });

const updateOrderListTabType = (state: OrderState, tabType: OrderListTabType) =>
  produce(state, draft => {
    draft.orderListTabType = tabType;
  });

const canStopPolling = (shop_data: ShopData, order: OrderData, staffId: number) =>
  (shop_data.shop_type === SHOP_TYPE.RETAIL ||
    (shop_data.shop_type === SHOP_TYPE.GROCERY && OrderData.isOwnAssignedOrder(order, staffId))) &&
  (
    [
      ORDER_STATE_TYPE.HOLD,
      ORDER_STATE_TYPE.APPROVE,
      ORDER_STATE_TYPE.LOCAL_AREA_DRIVER_APPROVE,
    ] as Array<OrderStateType>
  ).includes(order.order_state);

const isStateThatCanChangeItemCount = (orderData: OrderData, shopData: ShopData) =>
  orderData.order_state === ORDER_STATE_TYPE.HOLD ||
  (ShopData.isLocalAreaAndAutoApprove(shopData) &&
    orderData.order_state === ORDER_STATE_TYPE.LOCAL_AREA_DRIVER_APPROVE);

const canChangeItemCount = (orderData: OrderData, shopData: ShopData, isHistory: boolean = false) =>
  !isHistory &&
  ShopData.existsShopData(shopData.id) &&
  ShopData.isShopTypeThatCanChangeItemCount(shopData.shop_type) &&
  orderData.is_allowed_out_of_stock &&
  isStateThatCanChangeItemCount(orderData, shopData) &&
  orderData.is_operable;

const canDisplayPrintReceiptButton = (order: OrderData) =>
  !OrderData.isCancelStatus(order.order_state);

const canDisplayPickedCheckBox = (orderData: OrderData, shopData: ShopData) =>
  ShopData.isGrocery(shopData.shop_type) &&
  isStateThatCanChangeItemCount(orderData, shopData) &&
  orderData.is_operable;

const canDisplayExtendCookingTime = (orderData: OrderData, shopData: ShopData, isBusy: boolean) => {
  if (!isBusy || orderData.is_combo || orderData.time_type !== ORDER_TIME_TYPE.FASTEST) {
    return false;
  }
  if (ShopData.isRetail(shopData.shop_type)) {
    return orderData.order_state === ORDER_STATE_TYPE.HOLD;
  }
  if (ShopData.isGrocery(shopData.shop_type)) {
    return orderData.order_state === ORDER_STATE_TYPE.PENDING;
  }
  return ([ORDER_STATE_TYPE.PENDING, ORDER_STATE_TYPE.HOLD] as Array<OrderStateType>).includes(
    orderData.order_state,
  );
};

const sortOrderByReceiveDateTime = (list: OrderData[]) =>
  list.sort((a: OrderData, b: OrderData) =>
    DateUtils.diffTime(a.receive_datetime, b.receive_datetime),
  );

const getFilteredOrderListByOrderState = (
  list: OrderData[],
  filter: OrderStateFilterType,
  reservedOrderOnly: boolean,
  deliveryReservationFlag: boolean,
) => {
  const filteredList = list.filter(i => {
    if (reservedOrderOnly && i.time_type !== ORDER_TIME_TYPE.SPECIFIED) {
      return false;
    }
    if (deliveryReservationFlag) {
      if (OrderData.isPendingOrder(i.order_state)) {
        return filter === ORDER_STATE_FILTER_TYPE.UNAPPROVED;
      }
      return filter === ORDER_STATE_FILTER_TYPE.APPROVED;
    }
    if (OrderData.isContractedOrder(i.order_state)) {
      return filter === ORDER_STATE_FILTER_TYPE.UNAPPROVED;
    }
    return filter === ORDER_STATE_FILTER_TYPE.APPROVED;
  });
  if (deliveryReservationFlag) {
    return sortOrderByReceiveDateTime(filteredList);
  }
  return filteredList;
};

const getFilteredOrderListByTabType = (
  list: OrderData[],
  tabType: OrderListTabType,
  shopData: ShopData,
  reservedOrderOnly: boolean,
) => {
  const filteredList = list.filter(i => {
    const { order_state, time_type, cooking_start_able_flag, receive_type, delivery_state } = i;
    if (reservedOrderOnly && time_type !== ORDER_TIME_TYPE.SPECIFIED) {
      return false;
    }
    switch (order_state) {
      case ORDER_STATE_TYPE.PENDING:
      case ORDER_STATE_TYPE.LOCAL_AREA_SHOP_APPROVE:
      case ORDER_STATE_TYPE.ONE_STORE_APPROVE:
        /**
         * 受付待ち
         * スタンバイエリアの店舗のみ承認
         * コンボ注文の一部店舗のみ承認
         * …受付待ちタブへ
         */
        return tabType === ORDER_LIST_TAB_TYPE.PENDING;
      case ORDER_STATE_TYPE.HOLD:
        // グロサリー店舗の保留状態…準備中タブへ
        if (ShopData.isGrocery(shopData.shop_type)) {
          return tabType === ORDER_LIST_TAB_TYPE.ON_PREPARING;
        }
        // 上記以外...受付待ちタブへ
        return tabType === ORDER_LIST_TAB_TYPE.PENDING;
      case ORDER_STATE_TYPE.APPROVE:
        // 予約注文の準備開始フラグ偽…受付待ちタブ（成立済み）へ
        if (time_type === ORDER_TIME_TYPE.SPECIFIED && !cooking_start_able_flag) {
          return tabType === ORDER_LIST_TAB_TYPE.PENDING;
        }
        // 上記以外...準備中タブへ
        return tabType === ORDER_LIST_TAB_TYPE.ON_PREPARING;
      case ORDER_STATE_TYPE.LOCAL_AREA_DRIVER_APPROVE:
      case ORDER_STATE_TYPE.ALL_STORES_APPROVE:
        /**
         * スタンバイエリアのクルー承認
         * コンボ注文の全店舗承認
         * …準備中タブへ
         */
        return tabType === ORDER_LIST_TAB_TYPE.ON_PREPARING;
      case ORDER_STATE_TYPE.FINISH:
        // テイクアウトの準備完了…完了タブへ
        if (receive_type === ORDER_RECEIVE_TYPE.TAKEOUT) {
          return tabType === ORDER_LIST_TAB_TYPE.FINISHED;
        }
        /**
         * デリバリーの準備完了
         *  配達完了…完了タブへ
         *  上記以外…配達中タブへ
         */
        if (delivery_state === DELIVERY_STATE_TYPE.DELIVERED) {
          return tabType === ORDER_LIST_TAB_TYPE.FINISHED;
        }
        return tabType === ORDER_LIST_TAB_TYPE.ON_DELIVERY;
      case ORDER_STATE_TYPE.DENY:
      case ORDER_STATE_TYPE.CANCEL:
      case ORDER_STATE_TYPE.AUTO_CANCEL:
      case ORDER_STATE_TYPE.PAYMENT_CANCEL:
      case ORDER_STATE_TYPE.OTHER_STORE_CANCEL:
      case ORDER_STATE_TYPE.AUTO_CANCEL_ERROR:
      case ORDER_STATE_TYPE.LOCAL_AREA_DRIVER_NOT_FOUND_CANCEL:
      case ORDER_STATE_TYPE.ERROR:
        // 上記以外の注文状態…非表示
        return false;
      default:
        return false;
    }
  });
  // 予約注文を受け付けている場合、受け取り時間でソート。それ以外サーバーデフォルト
  if (ShopData.isAcceptingReservedOrder(shopData)) {
    return sortOrderByReceiveDateTime(filteredList);
  }
  return filteredList;
};

const getFilteredOrderHistoryList = (list: OrderData[], filter: OrderHistoryStateFilterType) =>
  list.filter(i => {
    switch (filter) {
      case ORDER_HISTORY_STATE_FILTER_TYPE.CANCELLED:
        return (
          OrderData.isCancelStatus(i.order_state) ||
          i.order_state === ORDER_STATE_TYPE.AUTO_CANCEL_ERROR
        );
      case ORDER_HISTORY_STATE_FILTER_TYPE.FINISHED:
        return OrderData.isFinishStatus(i.order_state);
      case ORDER_HISTORY_STATE_FILTER_TYPE.ALL:
      default:
        return true;
    }
  });

const getCurrentOrderDetailNo = (pathname: string) => {
  const regArray = REG_EXP_ORDER_DETAIL_PATH.exec(pathname);
  if (regArray !== null && regArray.length > 1) {
    return regArray[1];
  }
  return undefined;
};

const isCurrentOrdersNotification = (
  notificationType: NotificationType,
  orderNo: string,
  currentOrderNo?: string,
) =>
  typeof currentOrderNo !== "undefined" &&
  orderNo === currentOrderNo &&
  (
    [NOTIFICATION_TYPE.FASTEST_ORDER, NOTIFICATION_TYPE.RESERVED_ORDER] as NotificationType[]
  ).includes(notificationType);

const getOwnAssignedOrderList = (orderList: OrderData[], staff: ShopStaff) =>
  orderList.find(
    order => order.staff.staff_id === staff.staff_id && order.order_state === ORDER_STATE_TYPE.HOLD,
  );

const getPresentationOrderDetailButtonContainerText = (
  orderData: OrderData,
  isRetail: boolean,
  isGrocery: boolean,
): OrderDetailButtonContainerTextType[] => {
  if (OrderData.isPendingOrder(orderData.order_state)) {
    if (isGrocery) {
      if (
        OrderData.isDeliveryOrder(orderData.receive_type) &&
        OrderData.isReservedOrder(orderData.time_type)
      ) {
        return [
          { text: "準備開始時間になったらピックアップを開始できます。", emphasis: false },
          {
            text: `（準備開始時間：${DateUtils.dateToString(
              orderData.cooking_start_datetime,
              "M月D日 HH:mm",
            )}）`,
            emphasis: false,
          },
        ];
      }
      return [{ text: "準備するボタンを押して、ピックアップを開始してください", emphasis: false }];
    }
    if (isRetail) {
      if (orderData.is_allowed_out_of_stock) {
        return [
          { text: "注文を保留して、在庫を確認してください。", emphasis: true },
          { text: "品切れ商品は、数量を変更してから注文受付を行ってください。", emphasis: false },
        ];
      }
      return [
        { text: "注文を保留して、在庫を確認してください。", emphasis: false },
        {
          text: "この注文は数量を変更できません。品切れ商品が1つでもある場合はキャンセルしてください。",
          emphasis: true,
        },
      ];
    }
    return [
      {
        text: "注文内容を確認して注文受付を行ってください。品切れやカトラリー不足などで対応が遅れる場合は保留を押してお客様に連絡してください。",
        emphasis: false,
      },
    ];
  }
  if (OrderData.isHoldOrder(orderData.order_state)) {
    if (isGrocery) {
      return [
        {
          text: "品切れ商品は、数量を変更してください。確認した商品にチェックを入れてから準備完了してください。",
          emphasis: false,
        },
      ];
    }
    if (isRetail) {
      if (orderData.is_allowed_out_of_stock) {
        return [
          { text: "在庫を確認してください。", emphasis: true },
          { text: "品切れ商品は、数量を変更してから注文受付を行ってください。", emphasis: false },
        ];
      }
      return [
        { text: "在庫を確認してください。", emphasis: true },
        {
          text: "この注文は数量を変更できません。品切れ商品が1つでもある場合はキャンセルしてください。",
          emphasis: true,
        },
      ];
    }
    return [
      {
        text: "注文内容を確認して注文受付を行ってください。品切れやカトラリー不足などで対応が遅れる場合は保留を押してお客様に連絡してください。",
        emphasis: false,
      },
    ];
  }
  if (orderData.order_state === ORDER_STATE_TYPE.ONE_STORE_APPROVE) {
    return [
      {
        text: "他の店舗が受付完了するまで、準備をお待ちください。",
        emphasis: true,
      },
    ];
  }
  if (orderData.order_state === ORDER_STATE_TYPE.LOCAL_AREA_SHOP_APPROVE) {
    return [
      {
        text: "配達員が見つかるまで、準備をお待ちください。",
        emphasis: true,
      },
    ];
  }
  if (OrderData.isDeliveryOrder(orderData.receive_type)) {
    return [
      {
        text: "配達員の来店に合わせて、準備を開始してください。",
        emphasis: false,
      },
    ];
  }
  return [
    {
      text: "お客様の来店に合わせて、準備を開始してください。",
      emphasis: false,
    },
  ];
};

const totalOrderItemsCountReducer = (acc: number, crr: OrderItemData) => {
  if (crr.is_sell_by_weight) {
    if (crr.total_order_count > 0) {
      return acc + 1;
    }
    return acc;
  }
  return acc + crr.total_order_count;
};

const getTotalOrderItemsCount = (list: DraftItem[]) => list.reduce(totalOrderItemsCountReducer, 0);

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const getDraftTotalItemsCount = (list: DraftItem[], orderData: OrderData) =>
  list.reduce((acc, crr) => {
    // 量り売り商品の場合
    if (crr.is_sell_by_weight) {
      // 保留前の場合1を返す
      if (OrderData.isPendingOrder(orderData.order_state)) {
        return acc + 1;
      }
      // 保留以降数量1以上の場合1を返す
      return crr.pick_count > 0 ? acc + 1 : acc;
    }
    // buy1の場合pick_count*2を返す
    if (OrderItemData.isBuyOne(crr)) {
      return acc + crr.pick_count * 2;
    }
    // 代替商品がある場合は代替商品のpick_countを加えて返す
    if (OrderItemData.existsSubstituteItem(crr)) {
      return acc + crr.pick_count + crr.substituteItem!.pick_count;
    }
    // pick_countを返す
    return acc + crr.pick_count;
  }, 0);

const getDraftItemPrice = (item: DraftItem) => {
  const itemPrice = Math.round(OrderItemData.getTotalItemPrice(item) * item.pick_count);
  const substituteItemPrice = OrderItemData.existsSubstituteItem(item)
    ? Math.round(
        OrderItemData.getTotalItemPrice(item.substituteItem!) * item.substituteItem!.pick_count,
      )
    : 0;
  return itemPrice + substituteItemPrice;
};
/* eslint-enable @typescript-eslint/no-non-null-assertion */

const getDisplayablePaymentPrice = (
  orderData: OrderData,
  shopData: ShopData,
  isLocalPayment: boolean,
  draftItems: DraftItem[],
) => {
  const { order_state, total_price, user_payment_price, shop_support_price, discount_price } =
    orderData;
  if (!isStateThatCanChangeItemCount(orderData, shopData)) {
    return isLocalPayment ? user_payment_price : total_price + shop_support_price;
  }
  const reducer = (accum: number, curr: DraftItem) => accum + getDraftItemPrice(curr);
  const orderItemsTotalPrice = draftItems.reduce(reducer, 0);
  if (isLocalPayment) {
    if (orderItemsTotalPrice - discount_price > 0) {
      return orderItemsTotalPrice - discount_price + shop_support_price;
    }
    return shop_support_price;
  }
  return orderItemsTotalPrice + shop_support_price;
};

const getDisplayableNotifications = (
  notifications: OrderNotification[],
  onOrderDetail: boolean,
): {
  fullScreen: OrderNotification | undefined;
  banner: OrderNotification | undefined;
  backdrop: boolean;
} => {
  /**
   * 通知件数が0件の場合
   */
  if (notifications.length === 0) {
    return {
      fullScreen: undefined,
      banner: undefined,
      backdrop: false,
    };
  }
  /**
   * 注文詳細画面かつ、1件目が自動キャンセル前通知または受付不可前通知のいずれか以外の場合(backdrop:true)
   * または1件目が自動キャンセル通知の場合(backdrop:false)
   * バナーのみ表示
   */
  if (
    (onOrderDetail &&
      !(
        [
          NOTIFICATION_TYPE.BEFORE_AUTO_CANCEL,
          NOTIFICATION_TYPE.BEFORE_DISABLE_APPROVAL,
        ] as NotificationType[]
      ).includes(notifications[0].notification_type)) ||
    notifications[0].notification_type === NOTIFICATION_TYPE.AUTO_CANCEL
  ) {
    return {
      fullScreen: undefined,
      banner: notifications[0],
      backdrop: notifications[0].notification_type !== NOTIFICATION_TYPE.AUTO_CANCEL,
    };
  }
  /**
   * 1件目をフルスクリーン表示
   * 2件以上の場合2件目をバナー表示
   */
  return {
    fullScreen: notifications[0],
    banner: notifications.length > 1 ? notifications[1] : undefined,
    backdrop: false,
  };
};

const getOrderItemDraftCount = (
  itemData: OrderItemData,
  orderData: OrderData,
  isGrocery: boolean,
) => {
  // グロサリー店舗以外、または完了済の場合はorder_countをそのまま返す
  if (!isGrocery || OrderData.isFinishStatus(orderData.order_state)) {
    return itemData.order_count;
  }

  // 数量変更中の量り売り商品の初期値は0
  if (
    itemData.is_sell_by_weight &&
    OrderData.isHoldOrder(orderData.order_state) &&
    !itemData.is_picked
  ) {
    return 0;
  }

  // 保存されたpick_countを返す（初期値はorder_countと同数）
  return itemData.pick_count;
};

const getSubstituteItem = (itemData: OrderItemData, orderData: OrderData) => {
  if (
    !itemData.is_substitute_specified ||
    !(itemData.order_shop_item_id_group.toString() in orderData.substitute_item_list)
  ) {
    return undefined;
  }
  return orderData.substitute_item_list[itemData.order_shop_item_id_group.toString()];
};

const getPresentationOrderListTabText = (tabType: OrderListTabType) => {
  switch (tabType) {
    case ORDER_LIST_TAB_TYPE.PENDING:
      return "受付待ち";
    case ORDER_LIST_TAB_TYPE.ON_PREPARING:
      return "準備中";
    case ORDER_LIST_TAB_TYPE.ON_DELIVERY:
      return "配達中";
    case ORDER_LIST_TAB_TYPE.FINISHED:
      return "完了";
    // skip default case
  }
};

const getPresentationOrderHistoryFilterTypeText = (filterType: OrderHistoryStateFilterType) => {
  switch (filterType) {
    case ORDER_HISTORY_STATE_FILTER_TYPE.ALL:
      return "すべて";
    case ORDER_HISTORY_STATE_FILTER_TYPE.FINISHED:
      return "完了";
    case ORDER_HISTORY_STATE_FILTER_TYPE.CANCELLED:
      return "キャンセル";
    // skip default case
  }
};

export const OrderModel = Object.freeze({
  initialState,
  updateOrderList,
  updateOrderDetail,
  updateBusyFlag,
  updateOrderHistory,
  updateOrderItemTotalPrice,
  updateNotificationStack,
  updateOrderListTabType,
  canStopPolling,
  canChangeItemCount,
  canDisplayPrintReceiptButton,
  canDisplayPickedCheckBox,
  canDisplayExtendCookingTime,
  getFilteredOrderListByOrderState,
  getFilteredOrderListByTabType,
  getFilteredOrderHistoryList,
  getCurrentOrderDetailNo,
  isCurrentOrdersNotification,
  getOwnAssignedOrderList,
  getPresentationOrderDetailButtonContainerText,
  getTotalOrderItemsCount,
  getDraftTotalItemsCount,
  getDisplayablePaymentPrice,
  getDisplayableNotifications,
  getOrderItemDraftCount,
  getSubstituteItem,
  getPresentationOrderListTabText,
  getPresentationOrderHistoryFilterTypeText,
});
