import { put, fork, call } from "redux-saga/effects";
import { API_KEY, GetNewsReadFlagResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateUnreadNewsFlag } from "../actions";

export function* userAccessedToPageThatNeedsNewsReadFlagSaga() {
  try {
    const { result, error }: { result: GetNewsReadFlagResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.GET_NEWS_READ_FLAG,
      {},
    );
    if (result && !error) {
      yield put(updateUnreadNewsFlag(!result.news_read_flag));
    } else {
      console.error(error);
      throw error;
    }
  } catch (exception) {
    yield fork(commonErrorSaga);
  }
}
