export const PRINTER_FONT_SIZE_TYPE = { SMALL: 1, MEDIUM: 2, LARGE: 3 } as const;

export type PrinterFontSizeType = ValueOf<typeof PRINTER_FONT_SIZE_TYPE>;

export type PrinterPaperWidthType = 384 | 576;

export const displayablePrinterFontSize: ReadonlyArray<PrinterFontSizeType> = [
  PRINTER_FONT_SIZE_TYPE.SMALL,
  PRINTER_FONT_SIZE_TYPE.MEDIUM,
  PRINTER_FONT_SIZE_TYPE.LARGE,
] as const;

export type AutoPrintSetting = {
  is_auto_printing: boolean;
  print_count: number;
  font_size: PrinterFontSizeType;
};

const initialState: AutoPrintSetting = {
  is_auto_printing: true,
  print_count: 1,
  font_size: PRINTER_FONT_SIZE_TYPE.MEDIUM,
};

const create = (args: Partial<AutoPrintSetting> = {}) => ({
  ...initialState,
  ...args,
});

const enable = (setting: AutoPrintSetting) => setting.is_auto_printing;

export const AutoPrintSetting = Object.freeze({
  initialState,
  create,
  enable,
});
