import { put, call, fork, all } from "redux-saga/effects";
import { API_KEY, RestartOrderByItemRequest, RestartOrderByItemResponse } from "apis";
import { systemOpenedModal, systemClosedModal, updateSnackBar } from "modules/app/actions";
import { commonApiSaga, commonErrorSaga } from "modules/app/sagas";
import { REASON_FOR_ITEMS_STOP } from "records/ShopItem";
import { systemUpdateShopItemSelectStopReasonType } from "modules/shop/actions";
import {
  systemUpdateShopItemStatus,
  UserSubmittedRestartOrderByStockCheckAction,
} from "../actions";
import { SHOP_ITEM_SUBMIT_TYPE } from "../model";

export function* userSubmittedRestartOrderByStockCheckSaga(
  action: UserSubmittedRestartOrderByStockCheckAction,
) {
  try {
    const { shopItemIds, callback } = action.payload;
    const params: RestartOrderByItemRequest = {
      shop_item_ids: shopItemIds,
      confirm_flag: true,
      use_stock_check: true,
    };
    const { result, error }: { result: RestartOrderByItemResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.RESTART_ORDER_BY_ITEM,
      params,
    );
    if (result && !error) {
      if (!result.result) {
        yield all([
          put(systemClosedModal("CONFIRM_STOCK_CHECK")),
          put(
            systemOpenedModal("CONFIRM_ORDER_RESTART_BY_ITEMS", {
              selectedItemId: action.payload.shopItemIds,
              errorIds: result.error_ids,
            }),
          ),
        ]);
      } else {
        yield all([
          put(systemUpdateShopItemStatus(shopItemIds, SHOP_ITEM_SUBMIT_TYPE.RESTART)),
          put(systemClosedModal("CONFIRM_STOCK_CHECK")),
          put(updateSnackBar("商品の受付を再開しました")),
        ]);
        if (typeof callback !== "undefined") {
          callback();
        }
      }
    } else {
      throw error;
    }
    yield put(systemUpdateShopItemSelectStopReasonType(shopItemIds, REASON_FOR_ITEMS_STOP.DEFAULT));
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
