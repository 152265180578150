import { put, select } from "redux-saga/effects";
import { updateShopItems, SystemUpdateShopItemSelectStopReasonTypeAction } from "../actions";
import { shopItemsSelector } from "../selectors";
import { ShopModel } from "../model";

export function* systemUpdateShopItemSelectStopReasonTypeSaga(
  action: SystemUpdateShopItemSelectStopReasonTypeAction,
) {
  const { shopItemIds, reasonForItemsStop } = action.payload;
  const shopItems: ReturnType<typeof shopItemsSelector> = yield select(shopItemsSelector);
  const updatedShopItems = ShopModel.getUpdatedShopItemsBySelectStopReasonType(
    shopItems,
    shopItemIds,
    reasonForItemsStop,
  );

  yield put(updateShopItems(updatedShopItems));
}
