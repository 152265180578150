import { put, fork, call } from "redux-saga/effects";
import { API_KEY, SetPushNotificationRequest, SetPushNotificationResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import {
  updatePushNotificationSetting,
  UserSubmittedPushNotificationSettingAction,
} from "../actions";

export function* userSubmittedPushNotificationSettingSaga(
  action: UserSubmittedPushNotificationSettingAction,
) {
  try {
    const { notificationType, enable } = action.payload;
    const params: SetPushNotificationRequest = {
      notification_type: notificationType,
      enable,
    };
    const { result, error }: { result: SetPushNotificationResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.SET_PUSH_NOTIFICATION,
      params,
    );
    if (result && !error) {
      yield put(updatePushNotificationSetting(notificationType, enable));
    } else {
      throw error;
    }
  } catch {
    yield fork(commonErrorSaga);
  }
}
