import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {};

export const IconNotFinishedOrder: React.FC<Props> = React.memo(({ ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 194 194" {...props}>
    <path
      fill="#F49E96"
      d="M86.6,28.4c4.6-8,16.2-8,20.8,0l72.3,125.2c4.6,8-1.2,18-10.4,18H24.7c-9.2,0-15-10-10.4-18L86.6,28.4z"
    />
    <circle fill={colorsConst.ICON.DANGER} cx="97.5" cy="142.4" r="10" />
    <path
      fill={colorsConst.ICON.DANGER}
      d="M98.9,123c-0.3,1.6-2.5,1.6-2.8,0l-7.8-46.7c-0.9-5.7,3.4-10.9,9.2-10.9s10.1,5.2,9.2,10.9L98.9,123z"
    />
  </svg>
));
