import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { systemOpenedModal } from "modules/app/actions";
import { Text } from "components/atoms/Text";
import { Button, ButtonMainText, ButtonSubText } from "components/atoms/Button";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
} from "components/molecules/ModalDialog";

const BodyInner = styled.div`
  padding: 1em;
`;

const TextContainer = styled.div`
  & + & {
    margin-top: 1em;
  }
`;

type Props = {};

const MODAL_KEY = "UNABLE_APPROVE_ORDER";
const useModal = modalHooksFactory(MODAL_KEY);

export const UnableApproveOrderDialog: React.FC<Props> = React.memo(() => {
  const { data, close } = useModal();

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    close();
    dispatch(systemOpenedModal("CANCEL_ORDER", {}));
  }, [dispatch, close]);

  return (
    <ModalDialog isOpen={data.isOpen} close={close}>
      <ModalDialogHeader handleClose={close}>受け付けできません</ModalDialogHeader>
      <ModalBody>
        <BodyInner>
          <TextContainer>
            <Text>
              受付可能時間を超過したため、受け付けできませんでした。店舗都合キャンセルを行ってください。
            </Text>
          </TextContainer>
          <TextContainer>
            <Text>
              Your order isn&apos;t available by on time is late. Please cancel your order.
            </Text>
          </TextContainer>
        </BodyInner>
        <ModalFooter>
          <Button appearance="secondary" handleClick={handleClick}>
            <ButtonMainText>店舗都合キャンセルする</ButtonMainText>
            <ButtonSubText>Yes, cancel</ButtonSubText>
          </Button>
        </ModalFooter>
      </ModalBody>
    </ModalDialog>
  );
});
