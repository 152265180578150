import { put, call, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { OrderNotification } from "records/OrderNotification";
import { userAccessedToPageThatNeedsShopData } from "modules/shop/actions";
import {
  systemInitNotificationStack,
  userAccessedToPageThatNeedsOrderList,
  userAccessedToPageThatNeedsOrderDetail,
  UserTouchedOrderNotificationDialogAction,
  systemInitOrderListTabType,
} from "../actions";
import { OrderModel, REG_EXP_ORDER_LIST_PATH } from "../model";
import { readNotificationSaga } from "./readNotificationSaga";

export function* userTouchedOrderNotificationDialogSaga(
  action: UserTouchedOrderNotificationDialogAction,
) {
  const { notifcation, shopData } = action.payload;
  const pathName = window.location.pathname;
  const currentOrderDetailNo = OrderModel.getCurrentOrderDetailNo(pathName);
  yield call(readNotificationSaga, notifcation.order_no, notifcation.notification_type);
  if (OrderNotification.isNotificationToCloseOnly(notifcation, shopData)) {
    // タップして閉じるだけの通知の場合
    if (REG_EXP_ORDER_LIST_PATH.test(pathName)) {
      // 注文一覧画面にいる場合、注文一覧を更新
      yield put(userAccessedToPageThatNeedsOrderList());
      if (OrderNotification.isNotificationThatNeedsUpdateShopData(notifcation.notification_type)) {
        // 店舗情報の更新が必要な通知の場合
        yield put(userAccessedToPageThatNeedsShopData());
      }
    } else {
      // その他の画面にいる場合、注文一覧に遷移
      yield put(push("/orderList"));
    }
  } else if (
    typeof currentOrderDetailNo !== "undefined" &&
    currentOrderDetailNo === notifcation.order_no
  ) {
    // 注文詳細画面へ遷移する通知の場合、当該注文の詳細画面にいる場合は注文詳細を更新
    yield put(userAccessedToPageThatNeedsOrderDetail(notifcation.order_no));
  } else {
    // その他の画面の場合は注文一覧のタブ選択を初期化して注文詳細画面に遷移
    yield all([
      put(systemInitOrderListTabType()),
      put(push(`/orderDetail/${notifcation.order_no}`)),
    ]);
  }
  yield put(systemInitNotificationStack());
}
