import React from "react";
import styled from "styled-components";
import { Loading } from "./Loading";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  invert?: boolean;
  style?: React.CSSProperties;
};

export const LoadingContainer: React.FC<Props> = React.memo(props => (
  <Container {...props}>
    <Loading />
  </Container>
));
