import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { OrderData, ORDER_RECEIVE_TYPE } from "records/OrderData";
import { ShopData } from "records/ShopData";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { IconBicycle } from "components/atoms/Icons/IconBicycle";
import { IconBag } from "components/atoms/Icons/IconBag";
import { Text } from "components/atoms/Text";
import { OrderDetailContext } from "./OrderDetailContext";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.25em 0 0;
`;

const IconContainer = styled.div`
  ${fontSize.XXLARGE};
`;

const TextContainer = styled.div`
  ${fontSize.XLARGE};
`;

type Props = {
  orderData: OrderData;
  shopData: ShopData;
  isGrocery: boolean;
  isSimulator: boolean;
};

export const OrderState: React.FC<Props> = React.memo(
  ({ orderData, shopData, isGrocery, isSimulator }) => {
    const displayableReceiveTypeText = useMemo(() => {
      if (shopData.is_costco && isSimulator) {
        return "配達（予約）";
      }
      return OrderData.getPresentationReceiveTypeText(orderData.receive_type, orderData.time_type);
    }, [orderData, shopData, isSimulator]);

    const renderIcon = useCallback(() => {
      switch (orderData.receive_type) {
        case ORDER_RECEIVE_TYPE.DELIVERY:
          return <IconBicycle color={colorsConst.ICON.ACTIVE} />;
        case ORDER_RECEIVE_TYPE.TAKEOUT:
          return <IconBag color={colorsConst.ICON.ACTIVE} />;
        // skip default case
      }
    }, [orderData]);

    return (
      <Container>
        <IconContainer>{renderIcon()}</IconContainer>
        <TextContainer>
          <Text weight="BOLD">{`${displayableReceiveTypeText}： `}</Text>
          <Text>{OrderData.getPresentationOrderStateText({ orderData, isGrocery })}</Text>
        </TextContainer>
      </Container>
    );
  },
);
