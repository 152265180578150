import React, { useCallback } from "react";
import styled from "styled-components";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
} from "components/molecules/ModalDialog";

const TextContainer = styled.div``;

type Props = {
  children?: never;
  submitting?: boolean;
  handleClilckCancelOrderButtonByRequiredItemShortage?: () => void;
};

const MODAL_KEY = "CANCEL_ORDER_BY_REQUIRED_ITEM_SHORTAGE";
const useModal = modalHooksFactory(MODAL_KEY);

export const CancelOrderDialogByRequiredItemShortage: React.FC<Props> = React.memo(
  ({ submitting, handleClilckCancelOrderButtonByRequiredItemShortage }) => {
    const { data, close } = useModal();

    const handleSubmit = useCallback(() => {
      if (typeof handleClilckCancelOrderButtonByRequiredItemShortage !== "undefined") {
        handleClilckCancelOrderButtonByRequiredItemShortage();
      }
    }, [handleClilckCancelOrderButtonByRequiredItemShortage]);

    return (
      <ModalDialog isOpen={data.isOpen} close={close} handleClickBackdrop={close}>
        <ModalDialogHeader handleClose={close}>キャンセルの確認</ModalDialogHeader>
        <ModalBody>
          <TextContainer>
            <Text>
              この注文は数量を変更できません。必須商品から品切れが1つでもある場合はキャンセルしてください。
            </Text>
          </TextContainer>
          <TextContainer>
            <Text>注文をキャンセルしますか？</Text>
          </TextContainer>
        </ModalBody>
        <ModalFooter>
          <Button appearance="secondary" handleClick={handleSubmit} loading={submitting}>
            キャンセルする
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  },
);
