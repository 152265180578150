import React, { useRef, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { OrderData } from "records/OrderData";
import { OrderItemData, DraftItem } from "records/OrderItemData";
import { Text } from "components/atoms/Text";
import { TextInput } from "components/atoms/FormFields/TextInput";
import { OutOfStockButton } from "./OutOfStockButton";

const inputStyle: React.CSSProperties = {
  textAlign: "center",
  borderRadius: 4,
  border: `1px solid ${colorsConst.BORDER.DEFAULT}`,
} as const;

const Container = styled.div`
  padding-left: 1em;
`;

const UnitContainer = styled.div`
  margin-top: 0.5em;
`;
const ButtonContainer = styled.div`
  margin-top: 1em;
`;

type Props = {
  draftItem: DraftItem;
  targetItem: OrderItemData;
  orderData: OrderData;
  draftItems: DraftItem[];
  canChange: boolean;
  setIsDirty: (value: React.SetStateAction<boolean>) => void;
  setDraftItems?: (value: React.SetStateAction<DraftItem[]>) => void;
  setIsValidItemCount: (value: React.SetStateAction<boolean>) => void;
};

export const OrderDetailItemCountInput: React.FC<Props> = React.memo(
  ({
    draftItem,
    targetItem,
    orderData,
    draftItems,
    canChange,
    setDraftItems,
    setIsValidItemCount,
    setIsDirty,
  }) => {
    const [tempText, setTempText] = useState<string>("");

    const ref = useRef<HTMLDivElement>(null);

    const handleFocus = useCallback(() => {
      ref?.current?.scrollIntoView(true);
    }, [ref]);

    const handleBlur = useCallback(() => {
      if (typeof setDraftItems === "undefined") {
        return;
      }
      setIsDirty(true);
      /* 全角数字を半角数字に置き換え、parseIntで整数に置き換え */
      const updatedCount = parseInt(
        tempText.replace(/[０-９．]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0)),
        10,
      );
      if (Number.isNaN(updatedCount)) {
        /* 数値型でない場合チェックボックスを無効化 */
        setIsValidItemCount(false);
      } else {
        /* 数値型の場合draftCountに挿入 */
        setDraftItems(OrderData.getUpdatedDraftItems(draftItem, updatedCount, false, draftItems));
        /* 範囲内または0の場合チェックボックスを有効化 */
        setIsValidItemCount(
          updatedCount === 0 ||
            (draftItem.input_min_quantity <= updatedCount &&
              updatedCount <= draftItem.input_max_quantity),
        );
        /* tempTtextを半角整数に置き換え */
        setTempText(updatedCount.toString());
      }
    }, [draftItem, draftItems, tempText, setIsDirty, setDraftItems, setIsValidItemCount]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setTempText(event.target.value);
    }, []);

    const handleClick = useCallback(() => {
      if (typeof setDraftItems === "undefined") {
        return;
      }
      setIsDirty(true);
      setTempText("0");
      setDraftItems(OrderData.getUpdatedDraftItems(draftItem, 0, false, draftItems));
      setIsValidItemCount(true);
    }, [draftItem, draftItems, setIsDirty, setDraftItems, setIsValidItemCount]);

    useEffect(() => {
      if (OrderData.isHoldOrder(orderData.order_state)) {
        /* order_state === 8(HOLD)の場合 */
        if (!targetItem.is_picked) {
          /* ピッキング未の場合空白を初期値として挿入 */
          setTempText("");
        } else {
          /* ピッキング済の場合pick_countを初期値として挿入 */
          setTempText(targetItem.pick_count.toString());
        }
      } else {
        /* order_state === 8(HOLD)以外の場合total_order_countを初期値として挿入 */
        setTempText(targetItem.total_order_count.toString());
      }
    }, [orderData, targetItem]);

    return (
      <Container ref={ref}>
        <TextInput
          type="text"
          inputMode="numeric"
          onChange={handleChange}
          handleBlur={handleBlur}
          onFocus={handleFocus}
          value={tempText}
          fontSize="XLARGE"
          inputStyle={inputStyle}
          readOnly={!OrderData.isHoldOrder(orderData.order_state) || !canChange}
        />
        <UnitContainer>
          <Text size="SMALL" color="SECONDARY">{`／${
            draftItem.input_max_quantity
          }${OrderItemData.getPresentationUnitString(draftItem.unit)}`}</Text>
        </UnitContainer>
        {OrderData.isHoldOrder(orderData.order_state) ? (
          <ButtonContainer>
            <OutOfStockButton handleClick={handleClick} disabled={!canChange}>
              該当量なし
            </OutOfStockButton>
          </ButtonContainer>
        ) : undefined}
      </Container>
    );
  },
);
