import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconCooking: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M23.5,2.5C22.1,2.5,21,3.6,21,5v1c-4.3,0.2-8.6,1-12.8,2.4c-1.2,0.4-2,1.5-2,2.7v2.6H1.9 c-0.8,0-1.4,0.6-1.4,1.4c0,3.7,2.3,7.3,5.7,7.7v1.5c0,4.1,3.3,7.4,7.4,7.4h19.8c4.1,0,7.4-3.3,7.4-7.4v-1.5c3.4-0.5,5.7-4,5.7-7.7 c0-0.8-0.6-1.4-1.4-1.4h-4.3v-2.5c0-1.3-0.9-2.5-2.1-2.9l-1.1-0.3C33.9,6.9,30,6.2,26.1,6V5C26.1,3.6,24.9,2.5,23.5,2.5z M9.1,11.2 c9-3,18.7-3.1,27.7-0.3l1.1,0.3c0,0,0.1,0.1,0.1,0.1v1.2H9.1L9.1,11.2L9.1,11.2z M6.2,20v-3.3H3.5C3.9,18.4,5,19.6,6.2,20z  M43.5,16.6c-0.4,1.8-1.5,3-2.7,3.3v-3.3H43.5z M9.1,24.5v-9.1H38v9.1c0,2.5-2,4.6-4.6,4.6H13.7C11.1,29,9.1,27,9.1,24.5z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M11,34.2c0.4-0.4,1.1-0.4,1.5,0l0,0l0,0l0,0l0,0c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.4 c0.4,0.3,0.9,0.8,1.3,1.4c0.5,0.6,1,1.3,1.4,2c0.4,0.7,0.7,1.6,0.7,2.5c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9 c0-0.9,0.3-1.7,0.7-2.5c0.4-0.7,0.9-1.4,1.4-2c0.5-0.6,1-1.1,1.3-1.4c0.2-0.2,0.3-0.3,0.4-0.4l0.1-0.1L11,34.2L11,34.2L11,34.2 L11,34.2L11,34.2z M11.8,36.8c-0.3,0.3-0.6,0.6-0.9,1c-0.4,0.5-0.8,1-1.1,1.6c-0.3,0.5-0.4,1-0.4,1.3c0,1.4,1.1,2.5,2.5,2.5 c1.4,0,2.5-1.1,2.5-2.5c0-0.3-0.1-0.8-0.4-1.3c-0.3-0.5-0.7-1.1-1.1-1.6C12.4,37.4,12,37,11.8,36.8z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M24.7,34.2c-0.4-0.4-1.1-0.4-1.5,0l0,0l0,0l0,0l0,0c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.2-0.4,0.4 c-0.4,0.3-0.9,0.8-1.3,1.4c-0.5,0.6-1,1.3-1.4,2c-0.4,0.7-0.7,1.6-0.7,2.5c0,2.7,2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9 c0-0.9-0.3-1.7-0.7-2.5c-0.4-0.7-0.9-1.4-1.4-2c-0.5-0.6-1-1.1-1.3-1.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2 C24.9,34.3,24.8,34.3,24.7,34.2L24.7,34.2L24.7,34.2L24.7,34.2L24.7,34.2z M23.1,37.8c0.3-0.4,0.6-0.7,0.9-1c0.3,0.3,0.6,0.6,0.9,1 c0.4,0.5,0.8,1,1.1,1.6c0.3,0.5,0.4,1,0.4,1.3c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5c0-0.3,0.1-0.8,0.4-1.3 C22.2,38.8,22.6,38.3,23.1,37.8z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M35.4,34.2c0.4-0.4,1.1-0.4,1.5,0l0,0l0,0l0,0l0,0l0.1,0.1l0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.4 c0.4,0.3,0.9,0.8,1.3,1.4c0.5,0.6,1,1.3,1.4,2c0.4,0.7,0.7,1.6,0.7,2.5c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9 c0-0.9,0.3-1.7,0.7-2.5c0.4-0.7,0.9-1.4,1.4-2c0.5-0.6,1-1.1,1.3-1.4c0.2-0.2,0.3-0.3,0.4-0.4C35.3,34.3,35.3,34.3,35.4,34.2 L35.4,34.2L35.4,34.2L35.4,34.2L35.4,34.2L35.4,34.2z M36.2,36.8c-0.3,0.3-0.6,0.6-0.9,1c-0.4,0.5-0.8,1-1.1,1.6 c-0.3,0.5-0.4,1-0.4,1.3c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5c0-0.3-0.1-0.8-0.4-1.3c-0.3-0.5-0.7-1.1-1.1-1.6 C36.8,37.4,36.5,37,36.2,36.8z"
      />
    </svg>
  );
});
