import React, { useMemo } from "react";
import styled from "styled-components";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { CordovaUtil } from "utils/CordovaUtil";
import { colorsConst } from "styles/const";
import { buttonStyle } from "components/atoms/Button";
import { ModalDialog, ModalBody, ModalFooter } from "components/molecules/ModalDialog";

const TextContainer = styled.div`
  text-align: center;
`;

const Button = styled.a`
  ${buttonStyle};
  color: ${colorsConst.BUTTON.TEXT.DEFAULT};
  background-color: ${colorsConst.BUTTON.BACKGROUND.PRIMARY};
  text-decoration: none;
`;

const MODAL_KEY = "FORCE_UPDATE";
const useModal = modalHooksFactory(MODAL_KEY);

type Props = {};

export const ForceUpdateDialog: React.FC<Props> = React.memo(() => {
  const { data, close } = useModal();

  const storeUrl = useMemo(() => {
    if (CordovaUtil.isCordovaEnabled()) {
      if (
        "payload" in data &&
        "app_store_url" in data.payload &&
        "google_play_url" in data.payload
      ) {
        const { app_store_url, google_play_url } = data.payload;
        const platform = CordovaUtil.getPlatformId();
        switch (platform) {
          case "android":
            return google_play_url;
          case "ios":
            return app_store_url;
          default:
            return "";
        }
      }
      return "";
    }
    return "";
  }, [data]);

  const messageArray = useMemo(() => {
    if ("payload" in data && "message" in data.payload) {
      const { message } = data.payload;
      return message.split("\n");
    }
    return [];
  }, [data]);

  return (
    <ModalDialog isOpen={data.isOpen} close={close}>
      <ModalBody>
        <TextContainer>
          <div>アプリが更新されました。</div>
          {messageArray.map((text, index) => (
            <div key={index}>{text}</div>
          ))}
        </TextContainer>
      </ModalBody>
      <ModalFooter>
        <Button href={storeUrl}>ストアへ</Button>
      </ModalFooter>
    </ModalDialog>
  );
});
