import React, { useMemo } from "react";
import styled from "styled-components";
import { colorsConst } from "styles/const";

const Container = styled.div`
  margin-top: 0.25em;
  color: ${colorsConst.TEXT.DANGER};
`;

type Props = {
  error: { start_time: string | undefined; end_time: string | undefined };
};

export const BusinessTimeError: React.FC<Props> = React.memo(({ error }) => {
  const ErrorText = useMemo(() => error.start_time || error.end_time || "", [error]);
  return <Container>{ErrorText}</Container>;
});
