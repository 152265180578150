import { put } from "redux-saga/effects";
import { OrderData, ORDER_STATE_TYPE } from "records/OrderData";
import { updateSnackBar } from "modules/app/actions";
import {
  updateSimulatorOrderData,
  updateSimulatorOrderList,
  updateSimulatorFinishedOrderList,
  updateSimulatorPageTransition,
  UserTouchedSimulatorFinishOrderButtonAction,
} from "../actions";

export function* userTouchedSimulatorFinishOrderButtonSaga(
  action: UserTouchedSimulatorFinishOrderButtonAction,
) {
  const { orderData } = action.payload;
  const updatedOrderData = OrderData.create({
    ...orderData,
    order_state: ORDER_STATE_TYPE.FINISH,
  });
  yield put(updateSimulatorOrderData(updatedOrderData));
  yield put(updateSimulatorOrderList([]));
  yield put(updateSimulatorFinishedOrderList([updatedOrderData]));
  yield put(updateSimulatorPageTransition(false));
  yield put(updateSnackBar("準備完了を連絡しました"));
}
