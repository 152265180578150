import React, { useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { API_KEY } from "apis";
import { systemOpenedModal } from "modules/app/actions";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { shopBusinessSelector } from "modules/shop/selectors";
import {
  userSubmittedRegisterBusinessHoliday,
  userSubmittedDeleteBusinessHoliday,
} from "modules/shop/actions";
import { DateUtils } from "utils/DateUtils";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Button } from "components/atoms/Button";
import { HolidayListItem } from "components/molecules/HolidayListItem";
import { ConfirmDeleteHolidayDialog } from "./ConfirmDeleteHolidayDialog";

const Text = styled.div`
  line-height: 1.5;
`;

const SelectedDateContainer = styled.div`
  margin-top: 1em;
`;

const SelectedDate = styled.button<{ active: boolean }>`
  position: relative;
  display: block;
  width: 100%;
  padding: 1em;
  ${fontSize.DEFAULT};
  color: ${p => (p.active ? colorsConst.TEXT.PRIMARY : colorsConst.TEXT.SECONDARY)};
  text-align: left;
  appearance: none;
  background-color: ${colorsConst.BACKGROUND.WHITE};
  border: none;
  border-radius: 4px;
  &::after {
    content: "▼";
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const NoticeText = styled.div`
  ${fontSize.SMALL};
  line-height: 1.5;
`;

const ButtonContainer = styled.div`
  margin-top: 1em;
`;

const HolidayListContainer = styled.div`
  padding-top: 2em;
`;

const HolidayListTitle = styled.div``;

const isConnectedApiSelector = isConnectedApiSelectorFactory([
  API_KEY.REGISTER_BUSINESS_HOLIDAY,
  API_KEY.DELETE_BUSINESS_HOLIDAY,
]);

type Props = {
  today: Date;
  maxDate: Date;
  selectedDate?: Date;
  toggleCalendar: () => void;
};

export const ShopBusinessHoliday: React.FC<Props> = React.memo(
  ({ today, maxDate, selectedDate, toggleCalendar }) => {
    const shopBusiness = useSelector(shopBusinessSelector);
    const submitting = useSelector(isConnectedApiSelector);

    const isSelected = useMemo(() => typeof selectedDate !== "undefined", [selectedDate]);

    const displayableSelectedDateText = useMemo(
      () =>
        typeof selectedDate === "undefined"
          ? "日付を選択してください"
          : DateUtils.dateToString(selectedDate, "YYYY年MM月DD日"),
      [selectedDate],
    );

    const existsHolidays = useMemo(() => shopBusiness.holiday_list.length > 0, [shopBusiness]);

    const dispatch = useDispatch();

    const handleOpenCalendar = useCallback(() => {
      toggleCalendar();
    }, [toggleCalendar]);

    const handleClickAddHoliday = useCallback(() => {
      if (typeof selectedDate === "undefined") {
        return;
      }
      dispatch(userSubmittedRegisterBusinessHoliday(selectedDate));
    }, [dispatch, selectedDate]);

    const handleClickHolidayListItem = useCallback(
      (date: string) => {
        dispatch(systemOpenedModal("CONFIRM_DELETE_HOLIDAY", { date }));
      },
      [dispatch],
    );

    const handleSubmitDeleteHoliday = useCallback(
      (date: string) => {
        dispatch(userSubmittedDeleteBusinessHoliday(date));
      },
      [dispatch],
    );

    return (
      <>
        <Text>
          曜日別の営業時間設定で設定された休業日以外に、臨時で休業する予定がある日を設定できます。
        </Text>
        <SelectedDateContainer>
          <SelectedDate onClick={handleOpenCalendar} active={isSelected}>
            {displayableSelectedDateText}
          </SelectedDate>
        </SelectedDateContainer>
        <NoticeText>
          {`${DateUtils.dateToString(today, "YYYY-MM-DD")}〜${DateUtils.dateToString(
            maxDate,
            "YYYY-MM-DD",
          )}の期間のみ設定可能です。`}
        </NoticeText>
        <ButtonContainer>
          <Button
            appearance="primary"
            handleClick={handleClickAddHoliday}
            disabled={!isSelected}
            loading={submitting}
          >
            追加する
          </Button>
        </ButtonContainer>

        {existsHolidays ? (
          <HolidayListContainer>
            <HolidayListTitle>追加済の臨時休業日</HolidayListTitle>
            {shopBusiness.holiday_list.map((holiday, index) => (
              <HolidayListItem
                holiday={holiday}
                key={index}
                handleClick={handleClickHolidayListItem}
              />
            ))}
          </HolidayListContainer>
        ) : undefined}
        <ConfirmDeleteHolidayDialog
          submitting={submitting}
          handleSubmit={handleSubmitDeleteHoliday}
        />
      </>
    );
  },
);
