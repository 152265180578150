import React, { useCallback } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { colorsConst, fontFamilyConst } from "styles/const";
import { fontSize } from "styles/mixins";

const textLinkStyle = css`
  display: inline;
  padding: 0;
  appearance: none;
  border: none;
  background: none;
  font-family: ${fontFamilyConst};
  ${fontSize.DEFAULT};
  color: ${colorsConst.TEXT.LINK};
  text-decoration: underline;
`;

const Container = styled.button`
  ${textLinkStyle}
`;

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  to?: string;
};

const TextLink: React.FC<Props> = React.memo(
  ({ children, to, onClick, type = "button", ...props }) => {
    const dispatch = useDispatch();

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (typeof onClick !== "undefined") {
          onClick(event);
          return;
        }
        if (typeof to !== "undefined") {
          dispatch(push(to));
        }
      },
      [to, onClick, dispatch],
    );
    return (
      <Container type={type} onClick={handleClick} {...props}>
        {children}
      </Container>
    );
  },
);

export { TextLink, textLinkStyle };
