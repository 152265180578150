import produce from "immer";
import { ApiKey } from "apis";
import { AppError } from "records/AppError";
import { OrderData } from "records/OrderData";
import { ProcessingFlag } from "records/ProcessingFlag";
import { ModalManager, ModalKey, ModalPayload } from "records/ModalManager";
import { SnackBar, SnackBarType } from "records/SnackBar";
import { Printer, PrinterStatus, PrintCountType } from "records/Printer";
import { LocalStorage } from "utils/LocalStorage";
import { DeviceUtils } from "utils/DeviceUtils";
import { DateUtils } from "utils/DateUtils";
import { CordovaUtil, PlatformId } from "utils/CordovaUtil";
import { AuthorizationStatusType } from "../../@types/Diagnostic";

export const SHOULD_UPDATE_RESOURCE_COOKIE_KEY = "shouldUpdateBuild";
export const CURRENT_RESOURCE_VERSION_COOKIE_KEY = "currentBuildVersion";
export const LATEST_RESOURCE_VERSION_COOKIE_KEY = "latestBuildVersion";
export const DEVICE_TOKEN_KEY = "deviceToken";
export const INTERVAL_CHECK_UPDATE_RESOURCE = 60000;
export const INTERVAL_UPDATE_DAILY_LOGIN = 60000;

export const AUTO_PRINT_INTERVAL = 5000;
export const MAX_CONNECT_PRINTER_RETRY_COUNT = 3;
export const CONNECT_PRINTER_RETRY_INTERVAL = 5000;
export const DISCONNECT_PRINTER_RETRY_INTERVAL = 5000;

const CORDOVA_FOLDER_LIST = ["1.9.0", "1.8.0", "1.7.0", "1.6.0"] as const;

/**
 * トークンエラーのときのメッセージ(リトライ後にこのメッセージを拾って初期化する)
 */
export const ERROR_MESSAGE_INVALID_TOKEN = "InvalidTokenException";
/**
 * fetchエラーでリトライする回数
 */
export const FETCH_RETRY_TIMES = 5;
/**
 * 再リトライまでの時間
 */
export const RETRY_INTERVAL_MILLI_SECOUNDS = 2000;

export type AppState = Readonly<{
  launched: boolean;
  loginToken: string | null;
  appVersion: string;
  serverDateTime: string;
  connectingApiSet: ApiKey[];
  processingFlagSet: ProcessingFlag[];
  modalManager: ModalManager;
  snackBar: SnackBar;
  /**
   * リソースのアップデートを実施するべきか
   */
  shouldUpdateResource: boolean;
  /**
   * 検索されたプリンター
   */
  discoveredPrinter: Printer;
  /**
   * プリンター接続状態
   */
  isConnectedPrinter: boolean;
  /**
   * プリンター状態
   */
  printerStatus: PrinterStatus | undefined;
  /**
   * bluetoothパーミッション
   */
  enebleBluetooth: boolean;
}>;

const initialState: AppState = {
  launched: false,
  loginToken: null, // APIリトライするトークンエラーと区別するため 初期値はnull
  appVersion: "",
  serverDateTime: "",
  connectingApiSet: [],
  processingFlagSet: [],
  modalManager: ModalManager.initialState,
  snackBar: SnackBar.create(),
  shouldUpdateResource: false,
  discoveredPrinter: Printer.create(),
  isConnectedPrinter: false,
  printerStatus: undefined,
  enebleBluetooth: false,
};

const setLaunched = (state: AppState) =>
  produce(state, draft => {
    draft.launched = true;
  });

const startConnect = (state: AppState, apiKey: ApiKey) =>
  produce(state, draft => {
    const { connectingApiSet } = draft;
    connectingApiSet.push(apiKey);
    draft.connectingApiSet = connectingApiSet;
  });

const finishConnect = (state: AppState, apiKey: ApiKey) =>
  produce(state, draft => {
    draft.connectingApiSet = draft.connectingApiSet.filter(key => key !== apiKey);
  });

const updateLoginToken = (state: AppState, value: string | null) =>
  produce(state, draft => {
    draft.loginToken = value;
  });

const setAppVersion = (state: AppState, value: string) =>
  produce(state, draft => {
    draft.appVersion = value;
  });

const updateProcessingFlagSet = (state: AppState, flagSet: ProcessingFlag[]) =>
  produce(state, draft => {
    draft.processingFlagSet = flagSet;
  });

const initLoginToken = (state: AppState) => {
  if (LocalStorage.hasItem("loginToken")) {
    LocalStorage.removeItem("loginToken");
  }
  return produce(state, draft => {
    draft.loginToken = null;
  });
};

const updateServerDataTime = (state: AppState, serverDateTime: string) =>
  produce(state, draft => {
    draft.serverDateTime = serverDateTime;
  });

const addModal = <K extends ModalKey>(state: AppState, key: K, payload: ModalPayload<K>) =>
  produce(state, draft => {
    const draftList = state.modalManager.list;
    const updatedList = draftList.concat({ key, payload });
    draft.modalManager = ModalManager.create({ list: updatedList });
  });

const removeModal = (state: AppState, targetKey: ModalKey) =>
  produce(state, draft => {
    const draftList = state.modalManager.list;
    const updatedList = draftList.filter(i => i.key !== targetKey);
    draft.modalManager = ModalManager.create({ list: updatedList });
  });

const updateModal = <K extends ModalKey>(state: AppState, key: K, payload: ModalPayload<K>) =>
  produce(state, draft => {
    const draftList = state.modalManager.list;
    const updatedList = draftList.some(modalData => modalData.key === key)
      ? draftList.map(modalData => {
          if (modalData.key === key) {
            return { key, payload };
          }
          return modalData;
        })
      : draftList.concat({ key, payload });
    draft.modalManager.list = updatedList;
  });

const initSnackBar = (state: AppState) =>
  produce(state, draft => {
    draft.snackBar = SnackBar.create();
  });

const updateSnackBar = (state: AppState, text: string, type: SnackBarType) =>
  produce(state, draft => {
    draft.snackBar = SnackBar.create({
      isOpen: true,
      snackBarText: text,
      snackBarType: type,
    });
  });

const setShouldUpdateResource = (state: AppState) =>
  produce(state, draft => {
    draft.shouldUpdateResource = true;
  });

const updateDiscoveredPrinter = (state: AppState, value?: Printer) =>
  produce(state, draft => {
    draft.discoveredPrinter = value || Printer.create();
  });

const updateConnectedPrinter = (state: AppState, value?: boolean) =>
  produce(state, draft => {
    draft.isConnectedPrinter = value || false;
  });

const setPrinterStatus = (state: AppState, status?: PrinterStatus) =>
  produce(state, draft => {
    draft.printerStatus = status;
  });

const setEnebleBluetooth = (state: AppState, bool: boolean) =>
  produce(state, draft => {
    draft.enebleBluetooth = bool;
  });

const isDevelopment = () => process.env.REACT_APP_ENV !== "production";

const hasError = (error: AppError) => AppError.hasError(error);

const getPlatform = () => DeviceUtils.getPlatform();

const existsAppVersion = (version: string) => version !== "";

const getParametersForReload = (flag: boolean = false) => {
  const appVersion = LocalStorage.getItem("appVersion");
  const platform = CordovaUtil.getPlatformId();
  return `platform=${platform}${
    platform !== "browser" && appVersion !== null ? `&appVersion=${appVersion}` : ""
  }${flag ? `&t=${new Date().getTime()}` : ""}`;
};

const hardReload = (flag: boolean = false) => {
  window.history.replaceState(
    "",
    document.title,
    `${window.location.pathname}?${getParametersForReload(flag)}`,
  );
  window.location.reload();
};

const hardReloadIfShouldUpdateResource = () => {
  const { hash } = window.location;
  if (hash === `#${SHOULD_UPDATE_RESOURCE_COOKIE_KEY}`) {
    hardReload(true);
  }
};

const updateResource = () => {
  LocalStorage.removeItem(SHOULD_UPDATE_RESOURCE_COOKIE_KEY);
  if (LocalStorage.hasItem(LATEST_RESOURCE_VERSION_COOKIE_KEY)) {
    LocalStorage.setItem(
      CURRENT_RESOURCE_VERSION_COOKIE_KEY,
      LocalStorage.getItem(LATEST_RESOURCE_VERSION_COOKIE_KEY) as string,
    );
    LocalStorage.removeItem(LATEST_RESOURCE_VERSION_COOKIE_KEY);
  }
  if (window.location.pathname === "/") {
    hardReload(true);
  } else {
    window.location.href = `/?${getParametersForReload(true)}#${SHOULD_UPDATE_RESOURCE_COOKIE_KEY}`;
  }
};

const existsLoginToken = (token: string | null) => token !== null && token !== "";

const getLoginToken = (state: AppState) => state.loginToken;

const existsServerDateTime = (serverDateTime: string) =>
  serverDateTime !== "" && DateUtils.isValidDateString(serverDateTime);

const getFormattedServerDateTime = (serverDateTime: string) =>
  !DateUtils.isValidDateString(serverDateTime)
    ? ""
    : `${DateUtils.dateToString(serverDateTime, "M月D日")}（${DateUtils.getWeekdayString(
        serverDateTime,
      )}）${DateUtils.dateToString(serverDateTime, "HH:mm")}`;

const isConnectedApi = (state: AppState, apiKey: ApiKey | ApiKey[]) => {
  if (Array.isArray(apiKey)) {
    return apiKey.some(key => state.connectingApiSet.includes(key));
  }
  return state.connectingApiSet.includes(apiKey);
};

const isConnectedAnyApi = (state: AppState) => state.connectingApiSet.length !== 0;

const getPrintCountObject = () => LocalStorage.getItem("printCount");

const autoPrintCompletedOrderNumbers = () => LocalStorage.getItem("autoPrinted");

const getPrintCount = (orderNo: string) => {
  const printCountObjectString = getPrintCountObject();
  if (printCountObjectString !== null) {
    const printCountObject: PrintCountType = JSON.parse(printCountObjectString);
    if (orderNo in printCountObject) {
      return printCountObject[orderNo];
    }
    return 0;
  }
  return 0;
};

const isCompletedAutoPrint = (orderNo: string) => {
  const autoPrintCompleted = autoPrintCompletedOrderNumbers();
  if (autoPrintCompleted === null) {
    return false;
  }
  const autoPrintCompletedArray: string[] = JSON.parse(autoPrintCompleted);
  return autoPrintCompletedArray.includes(orderNo);
};

const increasePrintCount = (orderNo: string) => {
  const printCountObjectString = getPrintCountObject();
  if (printCountObjectString === null) {
    LocalStorage.setItem("printCount", JSON.stringify({ orderNo: 1 }));
    return;
  }
  const printCountObject: PrintCountType = JSON.parse(printCountObjectString);
  const updatedCount = orderNo in printCountObject ? printCountObject[orderNo] + 1 : 1;
  const updatedObject = Object.assign(printCountObject, {
    ...printCountObject,
    [orderNo]: updatedCount,
  });
  LocalStorage.setItem("printCount", JSON.stringify(updatedObject));
};

const setAutoPrintCompleted = (orderNo: string) => {
  const autoPrintCompleted = autoPrintCompletedOrderNumbers();
  if (autoPrintCompleted === null) {
    LocalStorage.setItem("autoPrinted", JSON.stringify([orderNo]));
    return;
  }
  const autoPrintCompletedArray: string[] = JSON.parse(autoPrintCompleted);
  if (autoPrintCompletedArray.includes(orderNo)) {
    return;
  }
  const updatedArray = autoPrintCompletedArray.concat(orderNo);
  LocalStorage.setItem("autoPrinted", JSON.stringify(updatedArray));
};

const removePrintCount = (orderList: OrderData[]) => {
  // 印刷回数消去処理
  const printCountObjectString = getPrintCountObject();
  if (printCountObjectString !== null) {
    const printCountObject: PrintCountType = JSON.parse(printCountObjectString);
    const updatedObject = Object.keys(printCountObject).reduce(
      (acc: PrintCountType, crr: string) => {
        if (orderList.some(i => i.order_no === crr)) {
          return Object.assign(acc, {
            [crr]: printCountObject[crr],
          });
        }
        return acc;
      },
      {},
    );
    LocalStorage.setItem("printCount", JSON.stringify(updatedObject));
  }
  // 自動印刷完了消去処理
  const autoPrintCompleted = autoPrintCompletedOrderNumbers();
  if (autoPrintCompleted !== null) {
    const autoPrintCompletedArray: string[] = JSON.parse(autoPrintCompleted);
    const updatedArray = autoPrintCompletedArray.filter(orderNo =>
      orderList.some(orderData => orderData.order_no === orderNo),
    );
    LocalStorage.setItem("autoPrinted", JSON.stringify(updatedArray));
  }
};

const isConnectedPrinter = (state: AppState) =>
  typeof state.printerStatus !== "undefined" &&
  ["connected", "printing", "printSuccess", "checking"].includes(state.printerStatus);

const isPrinterProcessing = (state: AppState, status: PrinterStatus | PrinterStatus[]) => {
  if (Array.isArray(status)) {
    return status.some(s => state.printerStatus === s);
  }
  return state.printerStatus === status;
};

const isSubmittedTodaysLogin = (dateTime: string) => {
  const savedDate = LocalStorage.getItem("daylyLogin");
  if (savedDate === null) {
    return false;
  }
  return (JSON.parse(savedDate) as string[]).includes(dateTime);
};

const isGrantedBluetooth = (status: AuthorizationStatusType | undefined) =>
  typeof status !== "undefined" &&
  (
    ["GRANTED", "GRANTED_WHEN_IN_USE", "authorized", "allowedAlways"] as AuthorizationStatusType[]
  ).includes(status);

const isNeverAskBluetoothAuthorization = (status: AuthorizationStatusType | undefined) =>
  status === "DENIED_ALWAYS" || status === "RESTRICTED";

/**
 * バージョン番号をドットで分割した配列に変更
 * @param appVersion
 * @returns number[]
 */
const splitAppVersionByDot = (appVersion: string) =>
  appVersion.split(".").map(value => {
    const valueInt = parseInt(value, 10);
    if (Number.isNaN(valueInt)) {
      return 0;
    }
    return valueInt;
  });

/**
 * バージョン番号の配列を指定した配列数まで0埋め
 * @param appVersionArray
 * @param count
 * @returns number[]
 */
const fillAppVersionArray = (appVersionArray: number[], count: number = 3) =>
  [...Array(count)].map((_, index) =>
    typeof appVersionArray[index] !== "undefined" ? appVersionArray[index] : 0,
  );

const compareAppVersion = (versionA: string, versionB: string) => {
  const arrayA = splitAppVersionByDot(versionA);
  const arrayB = splitAppVersionByDot(versionB);

  // 比較対象の配列数を揃える
  const count = Math.max(arrayA.length, arrayB.length);
  const filledA = fillAppVersionArray(arrayA, count);
  const filledB = fillAppVersionArray(arrayB, count);

  // メジャー→マイナー→リビジョンの順に比較
  for (let i = 0; i < count; i += 1) {
    if (filledA[i] > filledB[i]) {
      return 1;
    }
    if (filledA[i] < filledB[i]) {
      return -1;
    }
  }
  return 0;
};

const getCordovaFolder = (platform: PlatformId, appVersion: string | null) => {
  /**
   * @browser
   */
  if (platform === "browser" || platform === null) {
    return "/js/browser/cordova.js";
  }
  /**
   * バージョン番号が取得できない場合、各プラットフォームフォルダ直下を参照
   * @ios ~1.4.3
   * @android ~1.5.0
   */
  if (appVersion === null || appVersion === "") {
    return `/js/${platform}/cordova.js`;
  }
  /**
   * バージョン番号が取得できる場合、各プラットフォームフォルダのバージョン別フォルダをCORDOVA_FOLDER_LISTより参照
   * @ios 1.8.0~
   * @android 1.6.0~
   */
  for (const version of CORDOVA_FOLDER_LIST) {
    if (compareAppVersion(appVersion, version) >= 0) {
      return `/js/${platform}/${version}/cordova.js`;
    }
  }
  /**
   * @other unexpected
   */
  return `/js/browser/cordova.js`;
};

export const AppModel = Object.freeze({
  initialState,
  setLaunched,
  startConnect,
  finishConnect,
  updateLoginToken,
  setAppVersion,
  updateProcessingFlagSet,
  updateServerDataTime,
  addModal,
  removeModal,
  updateModal,
  initSnackBar,
  updateSnackBar,
  setShouldUpdateResource,
  updateDiscoveredPrinter,
  updateConnectedPrinter,
  setPrinterStatus,
  setEnebleBluetooth,
  isDevelopment,
  hasError,
  getLoginToken,
  initLoginToken,
  getPlatform,
  existsAppVersion,
  getParametersForReload,
  hardReload,
  hardReloadIfShouldUpdateResource,
  updateResource,
  existsLoginToken,
  existsServerDateTime,
  getFormattedServerDateTime,
  isConnectedApi,
  isConnectedAnyApi,
  getPrintCountObject,
  getPrintCount,
  isCompletedAutoPrint,
  increasePrintCount,
  setAutoPrintCompleted,
  removePrintCount,
  isConnectedPrinter,
  isPrinterProcessing,
  isSubmittedTodaysLogin,
  isGrantedBluetooth,
  isNeverAskBluetoothAuthorization,
  getCordovaFolder,
});
