import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { colorsConst } from "styles/const";
import {
  ShopItem,
  RELEASE_FLAG,
  SOLD_OUT_FLAG,
  ReasonForItemsStop,
  REASON_FOR_ITEMS_STOP_VIEW,
} from "records/ShopItem";
import { ShopItemSubmitType } from "modules/shop/model";
import { ReasonForItemsStopSelectField } from "components/organisms/OrderStopDialog/ReasonForItemsStopSelectField";
import { userSubmittedUpdateShopItemStoppedReasons } from "modules/shop/actions";
import { ShopItemStatus } from "../molecules/ShopItemStatus";
import { ShopItemName } from "./ShopItemName";

const Container = styled.div`
  padding: 0.5em;
  background-color: #fff;
  border-top: 1px solid ${colorsConst.BORDER.THIN};
`;

const SelectContainer = styled.div`
  margin-top: 0.75em;
  margin-bottom: 0.5em;
`;

type Props = {
  item: ShopItem;
  submitting: boolean;
  handleStatus: (itemId: number, submitType: ShopItemSubmitType) => void;
};

export const ShopItemListItem: React.FC<Props> = React.memo(
  ({ item, submitting, handleStatus }) => {
    const dispatch = useDispatch();
    const isOrderStop = useMemo(
      () =>
        item.sold_out_flag === SOLD_OUT_FLAG.SOLD_OUT &&
        item.is_release !== RELEASE_FLAG.NOT_RELEASED,
      [item],
    );
    const isReleased = useMemo(() => item.is_release === RELEASE_FLAG.RELEASED, [item]);

    const handleChangeSelectStopReasonShopItems = useCallback(
      (shopItemId: number, stopReasonType: ReasonForItemsStop) => {
        dispatch(
          userSubmittedUpdateShopItemStoppedReasons(
            [
              {
                shop_item_id: shopItemId,
                reason_type: stopReasonType,
              },
            ],
            REASON_FOR_ITEMS_STOP_VIEW.ITEM_LIST,
          ),
        );
      },
      [dispatch],
    );

    return (
      <Container key={item.shop_item_id}>
        <ShopItemName item={item} />
        <ShopItemStatus
          itemId={item.shop_item_id}
          isReleased={isReleased}
          isOrderStop={isOrderStop}
          submitting={submitting}
          handleStatus={handleStatus}
        />
        {isOrderStop && (
          <SelectContainer>
            <ReasonForItemsStopSelectField
              isSelected={true}
              handleChange={handleChangeSelectStopReasonShopItems}
              shopItemId={item.shop_item_id}
              defaultValue={item.sold_out_reason_type}
            />
          </SelectContainer>
        )}
      </Container>
    );
  },
);
