import { put, delay, select, all } from "redux-saga/effects";
import { OrderData } from "records/OrderData";
import { OrderNotification, NOTIFICATION_TYPE } from "records/OrderNotification";
import { OrderNotificationStack } from "records/OrderNotificationStack";
import { updateOrderNotificationStack } from "modules/order/actions";
import { OrderListTabType, ORDER_LIST_TAB_TYPE } from "modules/order/model";
import { updateSimulatorOrderData, updateSimulatorOrderList } from "../actions";
import { isMountedSelector } from "../selectors";

export function* cookingStartSaga(
  orderData: OrderData,
  setTabType: (value: React.SetStateAction<OrderListTabType>) => void,
) {
  yield delay(5000);
  const isMounted: boolean = yield select(isMountedSelector);
  if (isMounted) {
    setTabType(ORDER_LIST_TAB_TYPE.ON_PREPARING);
    yield put(
      updateOrderNotificationStack(
        OrderNotificationStack.create({
          notifications: [
            OrderNotification.create({
              notification_type: NOTIFICATION_TYPE.COOKING_START,
              order_no: orderData.order_no,
              display_order_no: orderData.order_no,
            }),
          ],
          isSimulator: true,
        }),
      ),
    );
    const updatedOrderData = OrderData.create({
      ...orderData,
      cooking_start_able_flag: true,
    });
    yield all([
      put(updateSimulatorOrderData(updatedOrderData)),
      put(updateSimulatorOrderList([updatedOrderData])),
    ]);
  }
}
