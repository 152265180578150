import React, { useCallback } from "react";
import styled from "styled-components";
import { Button } from "components/atoms/Button";

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 1em;
  margin-left: 1em;
`;

type Props = {
  value: number;
  currentValue: number;
  handleSetExtendTime: (value: number) => void;
};

export const ExtendCookingTimeButton: React.FC<Props> = React.memo(
  ({ value, currentValue, handleSetExtendTime }) => {
    const handleClick = useCallback(() => {
      handleSetExtendTime(value);
    }, [value, handleSetExtendTime]);
    return (
      <Container>
        <Button invert={value !== currentValue} handleClick={handleClick}>
          {`+${value}分`}
        </Button>
      </Container>
    );
  },
);
