import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconStopFastestOrder: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.TEXT.LINK;
  return (
    <svg width="1em" height="1em" viewBox="0 0 41 31" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 5.3125C23.6904 5.3125 24.25 5.87214 24.25 6.5625V13.75H30.5C31.1904 13.75 31.75 14.3096 31.75 15C31.75 15.6904 31.1904 16.25 30.5 16.25H23C22.3096 16.25 21.75 15.6904 21.75 15V6.5625C21.75 5.87214 22.3096 5.3125 23 5.3125Z"
        fill={fillColor}
      />
      <rect width="6" height="3" rx="1.5" transform="matrix(-1 0 0 1 12 7)" fill={fillColor} />
      <rect width="10" height="3" rx="1.5" transform="matrix(-1 0 0 1 10 13)" fill={fillColor} />
      <rect width="7" height="3" rx="1.5" transform="matrix(-1 0 0 1 11 19)" fill={fillColor} />
      <path
        d="M40.1408 21.5732C40.6142 21.0998 40.6142 20.3323 40.1408 19.8589C39.6674 19.3856 38.8999 19.3856 38.4265 19.8589L34.9979 23.2876L31.5693 19.859C31.0959 19.3856 30.3284 19.3856 29.855 19.859C29.3817 20.3323 29.3817 21.0998 29.855 21.5732L33.2836 25.0019L29.8552 28.4304C29.3818 28.9038 29.3818 29.6712 29.8552 30.1446C30.3286 30.618 31.0961 30.618 31.5695 30.1446L34.9979 26.7162L38.4263 30.1446C38.8997 30.618 39.6672 30.618 40.1406 30.1446C40.614 29.6713 40.614 28.9038 40.1406 28.4304L36.7122 25.0019L40.1408 21.5732Z"
        fill={fillColor}
      />
      <path
        d="M29.4214 25.8394C28.8667 26.0787 28.9955 26.0462 28.3926 26.2802C26.76 27.0621 24.9311 27.5 23 27.5C16.0964 27.5 10.5 21.9036 10.5 15C10.5 8.09644 16.0964 2.5 23 2.5C29.9036 2.5 35.5 8.09644 35.5 15C35.5 16.8315 35.1061 18.5711 34.3984 20.1385L35.001 20.7412L37.1568 18.5854C37.3136 18.4286 37.4834 18.2927 37.6628 18.1777C37.8837 17.1534 38 16.0903 38 15C38 6.71573 31.2843 0 23 0C14.7157 0 8 6.71573 8 15C8 23.2843 14.7157 30 23 30C24.6429 30 26.2242 29.7359 27.7036 29.2478C27.7133 28.4899 28.0181 27.8313 28.5611 27.0915L29.4214 25.8394Z"
        fill={fillColor}
      />
      <circle cx="3.5" cy="8.5" r="1.5" fill={fillColor} />
    </svg>
  );
});
