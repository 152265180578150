import React from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { TextInput } from "./TextInput";

const Container = styled.div``;

const InputContainer = styled.div``;

const ErrorContainer = styled.div`
  ${fontSize.SMALL};
  color: ${colorsConst.TEXT.DANGER};
`;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type Props = FieldRenderProps<any, HTMLElement> & {
  containerStyle?: React.CSSProperties;
  inputContainerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  ErrorStyle?: React.CSSProperties;
};

export const TextField: React.FC<Props> = React.memo(props => {
  const { input, meta, containerStyle, inputContainerStyle, inputStyle, ErrorStyle, placeholder } =
    props;

  return (
    <Container style={containerStyle}>
      <InputContainer style={inputContainerStyle}>
        <TextInput {...input} style={inputStyle} placeholder={placeholder} />
      </InputContainer>
      {meta.touched && meta.error && (
        <ErrorContainer style={ErrorStyle}>{meta.error}</ErrorContainer>
      )}
    </Container>
  );
});
