import { SalesToday } from "./SalesToday";
import { SalesMonthly } from "./SalesMonthly";

export type Sales = Readonly<{
  today: SalesToday;
  monthly: SalesMonthly;
}>;

const initialState: Sales = {
  today: SalesToday.create(),
  monthly: SalesMonthly.create(),
};

const create = (args: Partial<Sales> = {}) => ({
  today: args.today ? SalesToday.create(args.today) : SalesToday.create(),
  monthly: args.monthly ? SalesMonthly.create(args.monthly) : SalesMonthly.create(),
});

export const Sales = Object.freeze({
  create,
  initialState,
});
