import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconPrinter: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 23" fill="none" {...props}>
      <path
        d="M5.29297 1.94141C5.29297 1.38912 5.74068 0.941406 6.29297 0.941406H18.8224C19.3747 0.941406 19.8224 1.38912 19.8224 1.94141V8.76494H5.29297V1.94141Z"
        fill={fillColor}
      />
      <path
        d="M5.29297 15.4688H19.8224V21.1746C19.8224 21.7269 19.3747 22.1746 18.8224 22.1746H6.29297C5.74068 22.1746 5.29297 21.7269 5.29297 21.1746V15.4688Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.824219 9.88281H24.2948V16.5887C24.2948 18.4405 22.7936 19.9416 20.9419 19.9416V15.471C20.9419 14.8538 20.4415 14.3534 19.8242 14.3534H5.29481C4.67755 14.3534 4.17716 14.8538 4.17716 15.471L4.17716 19.9416C2.32538 19.9416 0.824219 18.4405 0.824219 16.5887V9.88281ZM17.0301 11.0005C16.7215 11.0005 16.4713 11.2507 16.4713 11.5593C16.4713 11.8679 16.7215 12.1181 17.0301 12.1181H20.383C20.6917 12.1181 20.9419 11.8679 20.9419 11.5593C20.9419 11.2507 20.6917 11.0005 20.383 11.0005H17.0301Z"
        fill={fillColor}
      />
      <path d="M19.8242 15.471V19.9416H5.29481V15.471H19.8242Z" fill={fillColor} />
    </svg>
  );
});
