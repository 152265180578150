export * from "./loginShop";
export * from "./getShopData";
export * from "./getShopItemList";
export * from "./getShopOptionSetList";
export * from "./getParentCategoryList";
export * from "./getShopBusiness";
export * from "./getShopInvitationCode";
export * from "./getOrderList";
export * from "./getOrderDetail";
export * from "./getDailyOrderHistory";
export * from "./getSales";
export * from "./getMonthlySales";
export * from "./getInformation";
export * from "./getNewsReadFlag";
export * from "./getOrderNotificationsByStaff";
export * from "./readNews";
export * from "./forceUpdate";
export * from "./updateBusinessTime";
export * from "./updateBusinessBusyTime";
export * from "./updateBusinessNationalHoliday";
export * from "./registerBusinessHoliday";
export * from "./deleteBusinessHoliday";
export * from "./stopOrderByShop";
export * from "./restartOrderByShop";
export * from "./stopOrderByItem";
export * from "./restartOrderByItem";
export * from "./restartOrderByOptionSet";
export * from "./stopReleaseByItem";
export * from "./stopOrderByOptionSet";
export * from "./stopReleaseByOptionSet";
export * from "./updateOrderItemsCount";
export * from "./approveOrder";
export * from "./finishOrder";
export * from "./holdOrder";
export * from "./denyOrder";
export * from "./denyAfterApprove";
export * from "./readNotification";
export * from "./getUpdatedInfo";
export * from "./updatePickStatus";
export * from "./updateSortForShopManagement";
export * from "./setAutoPrinting";
export * from "./stopImmediateDeliveryOrder";
export * from "./readShopNotification";
export * from "./setDisplayImages";
export * from "./setShopStoppedItemReasons";
export * from "./setPushNotification";

export const API_KEY = {
  LOGIN_SHOP: "loginShop",
  GET_SHOP_DATA: "getShopData",
  GET_SHOP_BUSINESS: "getShopBusiness",
  GET_SHOP_INVITATION_CODE: "getShopInvitationCode",

  GET_SHOP_ITEM_LIST: "getShopItemList",
  GET_SHOP_OPTION_SET_LIST: "getShopOptionSetList",
  GET_PARENT_CATEGORY_LIST: "getParentCategoryList",

  REGISTER_BUSINESS_HOLIDAY: "registerBusinessHoliday",
  DELETE_BUSINESS_HOLIDAY: "deleteBusinessHoliday",
  UPDATE_BUSINESS_TIME: "updateBusinessTime",
  UPDATE_BUSINESS_BUSY_TIME: "updateBusinessBusyTime",
  UPDATE_BUSINESS_NATIONAL_HOLIDAY: "updateBusinessNationalHoliday",

  GET_ORDER_LIST: "getOrderList",
  GET_ORDER_DETAIL: "getOrderDetail",
  GET_DAILY_ORDER_HISTORY: "getDailyOrderHistory",
  GET_ORDER_NOTIFICATIONS_BY_STAFF: "getOrderNotificationsByStaff",

  APPROVE_ORDER: "approveOrder",
  DENY_ORDER: "denyOrder",
  DENY_AFTER_APPROVE: "denyAfterApprove",
  FINISH_ORDER: "finishOrder",
  FINISH_ORDER_FOR_LUXURY: "finishOrderForLuxury",
  CONFIRM_ARRIVAL_FOR_LUXURY: "confirmArrivalForLuxury",
  HOLD_ORDER: "holdOrder",

  UPDATE_ORDER_ITEMS_COUNT: "updateOrderItemsCount",
  UPDATE_PICK_STATUS: "updatePickStatus",

  RESTART_ORDER_BY_SHOP: "restartOrderByShop",
  RESTART_ORDER_BY_ITEM: "restartOrderByItem",
  RESTART_ORDER_BY_OPTION_SET: "restartOrderByOptionSet",

  STOP_ORDER_BY_ITEM: "stopOrderByItem",
  STOP_ORDER_BY_SHOP: "stopOrderByShop",
  STOP_ORDER_BY_OPTION_SET: "stopOrderByOptionSet",
  STOP_RELEASE_BY_ITEM: "stopReleaseByItem",
  STOP_RELEASE_BY_OPTION_SET: "stopReleaseByOptionSet",

  STOP_IMMEDIATE_DELIVERY_ORDER: "stopImmediateDeliveryOrder",

  SET_AUTO_PRINTING: "setAutoPrinting",

  GET_ORDER_STOP_ITEM_LIST: "getOrderStopItemList",

  GET_NEWS_READ_FLAG: "getNewsReadFlag",
  GET_INFORMATION: "getInformation",
  READ_NEWS: "readNews",

  GET_SALES: "getSales",
  GET_MONTHLY_SALES: "getMonthlySales",

  GET_PLAN_INFO: "getPlanInfo",
  PAY_ORDER: "payOrder",
  GET_UPDATED_INFO: "getUpdatedInfo",

  READ_NOTIFICATION: "readNotification",
  READ_SHOP_NOTIFICATION: "readShopNotification",

  UPDATE_SORT_FOR_SHOP_MANAGEMENT: "updateSortForShopManagement",

  SET_DISPLAY_IMAGES: "setDisplayImages",

  SET_SHOP_STOPPED_ITEM_REASONS: "setShopStoppedItemReasons",

  SET_PUSH_NOTIFICATION: "setPushNotification",
} as const;

export type ApiKey = ValueOf<typeof API_KEY>;
