import firebase from "firebase";
import { eventChannel } from "redux-saga";
import { take, fork } from "redux-saga/effects";
import { Utility } from "utils/Utility";
import { SentryUtil } from "utils/SentryUtil";
import { fb } from "utils/Firebase";
import { FirestorePickerAssignmentsData } from "records/FirestorePickerAssignmentsData";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { SystemWatchPickerAssignmentsAction } from "../actions";
import { updatePickerAssignmentsSaga } from "./updatePickerAssignmentsSaga";

export function* systemWatchPickerAssignmentsSaga(action: SystemWatchPickerAssignmentsAction) {
  while (true) {
    const { shopId, shopStaffId, useFirestore } = action.payload;
    if (useFirestore) {
      const hash = `${Utility.getMd5String(shopId.toString())}_${shopId}`;
      const ref = fb
        .firestore()
        .collection(process.env.REACT_APP_FIREBASE_PICKER_ASSIGN_COLLECTION_NAME as string)
        .doc(hash)
        .collection("order_picker_assign");
      const channel = eventChannel(emit => ref.onSnapshot(emit));
      try {
        while (true) {
          const data: firebase.firestore.QuerySnapshot<FirestorePickerAssignmentsData> = yield take(
            channel,
          );
          yield fork(updatePickerAssignmentsSaga, data, shopStaffId);
        }
      } catch (err) {
        SentryUtil.reportError(err);
        yield fork(commonErrorSaga);
      }
    }
  }
}
