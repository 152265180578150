import firebase from "firebase";
import { call, put, fork, select } from "redux-saga/effects";
import { ORDER_RECEIVE_TYPE } from "records/OrderData";
import { OrderNotification, NOTIFICATION_TYPE } from "records/OrderNotification";
import { FirestoreNotificationData } from "records/FirestoreNotificationData";
import { OrderNotificationStack } from "records/OrderNotificationStack";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { deliveryReservationFlagSelector, shopDataSelector } from "modules/shop/selectors";
import { getOrderNotificationsByStaffSaga } from "modules/shop/sagas/getOrderNotificationsByStaffSaga";
import { ShopData } from "records/ShopData";
import { REG_EXP_ORDER_LIST_PATH } from "../model";
import { updateOrderNotificationStack, userAccessedToPageThatNeedsOrderList } from "../actions";

export function* updateFirestoreOrderNotificationSaga(
  data: firebase.firestore.QuerySnapshot<FirestoreNotificationData>,
) {
  try {
    const notifications = data.docs.map(doc => doc.data());
    if (notifications.length > 0) {
      // 通知が1件以上ある場合
      const deliveryReservationFlag: boolean = yield select(deliveryReservationFlagSelector);
      if (deliveryReservationFlag) {
        // デリバリー予約フラグがある場合
        const orderNotifications = notifications
          // デリバリー予約注文通知以外をstack
          .filter(
            i =>
              !(
                i.notification_type === NOTIFICATION_TYPE.RESERVED_ORDER &&
                i.receive_type === ORDER_RECEIVE_TYPE.DELIVERY
              ),
          )
          .map(i =>
            OrderNotification.create({
              notification_type: i.notification_type,
              order_no: i.order_no,
              display_order_no: i.display_order_no,
              receive_type: i.receive_type,
              is_combo: i.is_combo,
            }),
          );
        if (orderNotifications.length > 0) {
          // stackする通知がある場合通常の通知処理
          const orderNotificationStack = OrderNotificationStack.create({
            notifications: orderNotifications,
          });
          yield put(updateOrderNotificationStack(orderNotificationStack));
        } else {
          // stackする通知がない場合注文一覧を更新
          yield put(updateOrderNotificationStack(OrderNotificationStack.create()));
          const pathName = window.location.pathname;
          if (REG_EXP_ORDER_LIST_PATH.test(pathName)) {
            yield put(userAccessedToPageThatNeedsOrderList());
          }
        }
      } else {
        let orderNotifications: OrderNotification[] = [];
        const shopData: ShopData = yield select(shopDataSelector);
        if (ShopData.isLazona(shopData.shop_commercial_facility_type)) {
          const { result, error } = yield call(getOrderNotificationsByStaffSaga);
          if (result && !error) {
            orderNotifications = notifications
              .filter(i => result.order_no_list.includes(i.order_no))
              .map(i =>
                OrderNotification.create({
                  notification_type: i.notification_type,
                  order_no: i.order_no,
                  display_order_no: i.display_order_no,
                  receive_type: i.receive_type,
                  is_combo: i.is_combo,
                }),
              );
          } else {
            throw error;
          }
        } else {
          orderNotifications = notifications.map(i =>
            OrderNotification.create({
              notification_type: i.notification_type,
              order_no: i.order_no,
              display_order_no: i.display_order_no,
              receive_type: i.receive_type,
              is_combo: i.is_combo,
            }),
          );
        }
        const orderNotificationStack = OrderNotificationStack.create({
          notifications: orderNotifications,
        });
        yield put(updateOrderNotificationStack(orderNotificationStack));
      }
    } else {
      yield put(updateOrderNotificationStack(OrderNotificationStack.create()));
      const pathName = window.location.pathname;
      if (REG_EXP_ORDER_LIST_PATH.test(pathName)) {
        yield put(userAccessedToPageThatNeedsOrderList());
      }
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
