/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { call } from "redux-saga/effects";
import { API_KEY, UpdateOrderItemsCountItemType, UpdateOrderItemsCountResponse } from "apis";
import { OrderItemData, DraftItem } from "records/OrderItemData";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";

export function* updateOrderItemsCountSaga(order_no: string, draftItems: DraftItem[]) {
  try {
    const updatedOrderItems = draftItems.reduce(
      (acc: UpdateOrderItemsCountItemType[], crr: DraftItem) => {
        acc.push({
          shop_item_id: crr.shop_item_id,
          order_shop_item_id_group: crr.order_shop_item_id_group,
          order_count: crr.pick_count,
        });
        if (OrderItemData.existsSubstituteItem(crr)) {
          acc.push({
            shop_item_id: crr.substituteItem!.shop_item_id,
            order_shop_item_id_group: crr.substituteItem!.order_shop_item_id_group,
            order_count: crr.substituteItem!.pick_count,
          });
        }
        return acc;
      },
      [],
    );
    const { result, error }: { result: UpdateOrderItemsCountResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.UPDATE_ORDER_ITEMS_COUNT,
      {
        order_no,
        order_items: updatedOrderItems,
      },
    );
    if (result && !error) {
      return { result };
    }
    throw error;
  } catch (error) {
    return { error };
  }
}
