import { fork, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import {
  API_KEY,
  DenyOrderRequest,
  DenyOrderResponse,
  SetShopStoppedItemReasonsResponse,
} from "apis";

import { systemClosedModal, updateSnackBar } from "modules/app/actions";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { REASON_FOR_ITEMS_STOP_VIEW } from "records/ShopItem";
import { OrderData } from "records/OrderData";
import { userSubmittedUpdateShopItemStoppedReasons } from "modules/shop/actions";
import { updateOrderDetail, UserSubmittedDenyOrderAction } from "../actions";

export function* userSubmittedDenyOrderSaga(action: UserSubmittedDenyOrderAction) {
  try {
    const { orderData, shopItemStopReasons } = action.payload;
    const params: DenyOrderRequest = {
      order_no: orderData.order_no,
    };
    const { result, error }: { result: DenyOrderResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.DENY_ORDER,
      params,
    );

    /*
     * NOTE: 本来店舗都合キャンセルの場合、その後自動で欠品処理になるが一部商品では欠品にならずエラーになる不具合あるため、
     * 一時対応として、店舗都合キャンセルでは欠品理由を選択されても送信しないようにする
     * サーバー側の不具合が解消されたら、こちらの処理復活させる
     */
    // if (shopItemStopReasons && shopItemStopReasons?.length > 0) {
    // yield put(
    //   userSubmittedUpdateShopItemStoppedReasons(
    //     shopItemStopReasons,
    //     REASON_FOR_ITEMS_STOP_VIEW.ORDER_DETAIL,
    //   ),
    // );
    // }

    if (result && !error) {
      yield all([
        put(systemClosedModal("CANCEL_ORDER")),
        put(updateOrderDetail(OrderData.create())),
        put(replace("/orderList", { from: { pathname: `/orderDetail/${orderData.order_no}` } })),
        put(updateSnackBar("注文をキャンセルしました")),
      ]);
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
