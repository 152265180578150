import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { Text } from "components/atoms/Text";
import { OrderDetailContext } from "./OrderDetailContext";
import { ExtendCookingTimeButton } from "./ExtendCookingTimeButton";

const Container = styled.div`
  padding: 1em;
  text-align: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1em;
`;

type Props = {};

export const ExtendCookingTime: React.FC<Props> = React.memo(() => {
  const { addMinutes = 0, setAddMinutes } = useContext(OrderDetailContext);

  const handleSetExtendTime = useCallback(
    (value: number) => {
      if (typeof setAddMinutes !== "undefined") {
        setAddMinutes(value);
      }
    },
    [setAddMinutes],
  );

  return (
    <Container>
      <div>
        <Text>準備時間を延長する場合は、時間を選択してください。</Text>
      </div>
      <div>
        <Text>Choose extra time if you need</Text>
      </div>
      <ButtonContainer>
        {Array(3)
          .fill("")
          .map((_, index) => (
            <ExtendCookingTimeButton
              key={index}
              value={index * 10}
              currentValue={addMinutes}
              handleSetExtendTime={handleSetExtendTime}
            />
          ))}
      </ButtonContainer>
    </Container>
  );
});
