import React, { useMemo } from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Text } from "components/atoms/Text";
import { ReceiveDateTime } from "./ReceiveDateTime";
import { OrderNumberContainer } from "./OrderNumberContainer";
import { OrderPrice } from "./OrderPrice";

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
`;

const ReceiveDateTimeContainer = styled.div`
  width: 6em;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OrderInfoContainer = styled.div<{ isWarning: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 0.5em;
  padding-right: 0.5em;
  padding-left: 0.5em;
  border-left: 1px solid
    ${p => (p.isWarning ? "rgba(255, 255, 255, 0.6)" : colorsConst.BORDER.THIN)};
`;

const OrderAddedInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const OrderCountContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 1em;
`;

const NewLabel = styled.div<{ isWarning: boolean }>`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 0.5em;
  padding: 0.25em;
  ${fontSize.XSMALL};
  line-height: 1;
  ${p =>
    p.isWarning
      ? `
    color: ${colorsConst.TEXT.DANGER};
    background-color: ${colorsConst.BACKGROUND.WHITE};
  `
      : `
    color: ${colorsConst.TEXT.WHITE};
    background-color: ${colorsConst.BACKGROUND.DANGER};
  `};
`;

const OrderCount = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const SupportPriceInfo = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const UserNameContainer = styled.div``;

type Props = {
  orderData: OrderData;
  isWarning: boolean;
  isHistory?: boolean;
};

export const OrderInfo: React.FC<Props> = React.memo(
  ({ orderData, isWarning, isHistory = false }) => (
    <Container>
      <ReceiveDateTimeContainer>
        <ReceiveDateTime orderData={orderData} />
      </ReceiveDateTimeContainer>
      <OrderInfoContainer isWarning={isWarning}>
        <OrderNumberContainer orderData={orderData} />
        {!isHistory ? (
          <OrderAddedInfoContainer>
            {typeof orderData.user_order_count !== "undefined" ? (
              <OrderCountContainer>
                {orderData.user_order_count === 0 ? (
                  <NewLabel isWarning={isWarning}>NEW</NewLabel>
                ) : undefined}
                <OrderCount>
                  <Text size="LARGE" weight="BOLD">
                    {orderData.user_order_count + 1}
                  </Text>
                  <Text color={isWarning ? "WHITE" : "SECONDARY"} size="SMALL">
                    回目
                  </Text>
                </OrderCount>
              </OrderCountContainer>
            ) : undefined}
            {OrderData.existsShopSupportPrice(orderData.shop_support_price) ? (
              <SupportPriceInfo>
                <Text size="SMALL">応援金あり</Text>
              </SupportPriceInfo>
            ) : undefined}
          </OrderAddedInfoContainer>
        ) : undefined}
        <UserNameContainer>
          <Text size="SMALL">{`${orderData.user_info.nick_name}さん`}</Text>
        </UserNameContainer>
        {isHistory ? <OrderPrice orderData={orderData} /> : undefined}
      </OrderInfoContainer>
    </Container>
  ),
);
