import firebase from "firebase";
import { fork, put, select } from "redux-saga/effects";
import { OrderNotificationStack } from "records/OrderNotificationStack";
import { FirestorePickerAssignmentsData } from "records/FirestorePickerAssignmentsData";
import { REG_EXP_ORDER_DETAIL_PATH } from "../model";
import { notificationStackSelector } from "../selectors";
import { updateOrderNotificationStack, userAccessedToPageThatNeedsOrderList } from "../actions";
import { updateOrderDetailSaga } from "./updateOrderDetailSaga";

export function* updatePickerAssignmentsSaga(
  data: firebase.firestore.QuerySnapshot<FirestorePickerAssignmentsData>,
  shopStaffId: number,
) {
  const notifications = data.docs.map(doc => doc.data());
  if (notifications.length > 0) {
    const pathName = window.location.pathname;
    const result = REG_EXP_ORDER_DETAIL_PATH.exec(pathName);
    if (result !== null && result.length > 1) {
      const orderNo = result[1];
      if (notifications.some(i => i.order_no === orderNo && i.shop_staff_id !== shopStaffId)) {
        yield fork(updateOrderDetailSaga, orderNo);
      }
    } else {
      const orderNotificationStack: OrderNotificationStack = yield select(
        notificationStackSelector,
      );
      if (orderNotificationStack.notifications.length > 0) {
        const updatedOrderNotifications = orderNotificationStack.notifications.filter(
          orderNotification =>
            !notifications.some(
              i => i.order_no === orderNotification.order_no && i.shop_staff_id !== shopStaffId,
            ),
        );
        if (updatedOrderNotifications.length > 0) {
          yield put(
            updateOrderNotificationStack({
              notifications: updatedOrderNotifications,
              isDebug: orderNotificationStack.isDebug,
              isSimulator: orderNotificationStack.isSimulator,
            }),
          );
        } else {
          yield put(updateOrderNotificationStack(OrderNotificationStack.create()));
        }
        yield put(userAccessedToPageThatNeedsOrderList());
      }
    }
  }
}
