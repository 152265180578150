import { fork, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import {
  userAccessedToPageThatNeedsNewsListSaga,
  userAccessedToPageThatNeedsNewsReadFlagSaga,
  userSubmittedReadNewsSaga,
} from "./sagas";

export function* informationSaga() {
  yield fork(watchUserAccessedToPageThatNeedsNewsListSaga);
  yield fork(watchUserAccessedToPageThatNeedsNewsReadFlagSaga);
  yield fork(watchUserSubmittedReadNewsSaga);
}

function* watchUserAccessedToPageThatNeedsNewsListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_NEWS_LIST,
    userAccessedToPageThatNeedsNewsListSaga,
  );
}

function* watchUserAccessedToPageThatNeedsNewsReadFlagSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_NEWS_READ_FLAG,
    userAccessedToPageThatNeedsNewsReadFlagSaga,
  );
}

function* watchUserSubmittedReadNewsSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_READ_NEWS, userSubmittedReadNewsSaga);
}
