import React, { useMemo } from "react";
import styled from "styled-components";
import { OrderItemData } from "records/OrderItemData";
import { PriceUtils } from "utils/PriceUtils";
import { Text } from "components/atoms/Text";
import { PaymentCancelledItemOptionSetList } from "./PaymentCancelledItemOptionSetList";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 1em;
`;

const OrderCountContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 2em;
  margin-right: 1em;
`;

const ItemDetailContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ItemNameContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const ItemPriceContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 4em;
  margin-left: 1em;
  text-align: right;
`;

type Props = {
  item: OrderItemData;
};

export const PaymentCancelledItem: React.FC<Props> = React.memo(({ item }) => {
  const existsOptionSets = useMemo(() => item.option_set_list.length > 0, [item]);

  const displayablePrice = useMemo(
    () =>
      item.is_sell_by_weight
        ? Math.round(item.user_display_quantity * item.unit_price)
        : item.unit_price,
    [item],
  );

  return (
    <Container>
      <OrderCountContainer>
        <Text size="SMALL">{`${item.order_count}${OrderItemData.getPresentationUnitString(
          item.unit,
        )}`}</Text>
      </OrderCountContainer>
      <ItemDetailContainer>
        <ItemContainer>
          <ItemNameContainer>
            <Text size="SMALL">{item.item_name}</Text>
          </ItemNameContainer>
          <ItemPriceContainer>
            <Text size="SMALL">{`-${PriceUtils.getPresentationValue(displayablePrice)}${
              item.is_sell_by_weight
                ? `／${item.user_display_quantity}${OrderItemData.getPresentationUnitString(
                    item.unit,
                  )}`
                : ""
            }`}</Text>
          </ItemPriceContainer>
        </ItemContainer>
        {existsOptionSets ? (
          <PaymentCancelledItemOptionSetList optionSets={item.option_set_list} />
        ) : undefined}
      </ItemDetailContainer>
    </Container>
  );
});
