import React from "react";
import { FieldArray } from "react-final-form-arrays";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { SelectTimeOptions } from "components/atoms/SelectTimeOptions";
import { SelectField } from "components/atoms/FormFields/SelectField";
import { BusinessTimeError } from "./BusinessTimeError";

const Container = styled.div`
  margin-top: 0.5em;
`;

const Label = styled.div`
  ${fontSize.SMALL};
  color: ${colorsConst.TEXT.SECONDARY};
`;

const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.25em;
`;

const FieldContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const Separator = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 0.5em;
  margin-left: 0.5em;
`;

type Props = {
  name: string;
};

export const BusinessTimeField: React.FC<Props> = React.memo(({ name }) => (
  <FieldArray name={name}>
    {({ fields, meta: { error } }) =>
      fields.map((name, index) => (
        <Container key={index}>
          <Label>営業時間{String.fromCharCode(9312 + index)}</Label>
          <FieldRow>
            <FieldContainer>
              <SelectField name={`${name}.start_time`} component="select">
                <SelectTimeOptions />
              </SelectField>
            </FieldContainer>
            <Separator>〜</Separator>
            <FieldContainer>
              <SelectField name={`${name}.end_time`} component="select">
                <SelectTimeOptions />
              </SelectField>
            </FieldContainer>
          </FieldRow>
          {error && error[index] !== null && typeof error[index] !== "undefined" ? (
            <BusinessTimeError error={error[index]} />
          ) : undefined}
        </Container>
      ))
    }
  </FieldArray>
));
