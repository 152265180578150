import { fork, call, put } from "redux-saga/effects";
import { API_KEY, HoldOrderRequest, HoldOrderResponse } from "apis";
import { ORDER_TIME_TYPE } from "records/OrderData";
import { NOTIFICATION_TYPE } from "records/OrderNotification";
import { systemOpenedModal } from "modules/app/actions";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { OrderModel } from "../model";
import { UserSubmittedHoldOrderAction } from "../actions";
import { updateOrderDetailSaga } from "./updateOrderDetailSaga";

export function* userSubmittedHoldOrderSaga(action: UserSubmittedHoldOrderAction) {
  try {
    const { orderData, isRetail } = action.payload;
    const params: HoldOrderRequest = {
      order_no: orderData.order_no,
      add_minutes: 0,
    };
    const { result, error }: { result: HoldOrderResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.HOLD_ORDER,
      params,
    );
    if (result && !error) {
      yield put(
        systemOpenedModal("HOLD_ORDER", {
          userInfoTel: orderData.user_info.tel,
          isRetail,
        }),
      );
      yield fork(updateOrderDetailSaga, orderData.order_no);
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
