import { put } from "redux-saga/effects";
import { OrderData } from "records/OrderData";
import {
  updateMountSimulator,
  updateSimulatorOrderData,
  updateSimulatorOrderList,
  updateSimulatorFinishedOrderList,
} from "../actions";

export function* systemUnmountSimulatorSaga() {
  yield put(updateSimulatorOrderData(OrderData.create()));
  yield put(updateSimulatorOrderList([]));
  yield put(updateSimulatorFinishedOrderList([]));
  yield put(updateMountSimulator(false));
}
