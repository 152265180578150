import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { OrderData } from "records/OrderData";
import {
  OrderHistoryStateFilterType,
  displayableOrderHistoryStateFilterType,
} from "modules/order/model";
import { DateUtils } from "utils/DateUtils";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import {
  HeaderContainer,
  HeaderInner,
  HeaderTitle,
  BackButtonContainer,
} from "components/atoms/HeaderElement";
import { BackButton } from "components/atoms/BackButton";
import { OrderHistoryHeaderTab } from "components/molecules/OrderHistoryHeaderTab";

const Inner = styled.div`
  flex-grow: 1;
`;

const OrderHistoryHeaderTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const OrderHistoryDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
`;

const OrderHistoryDate = styled.div`
  position: relative;
  margin: 0 1em;
  padding: 0.25em 2em 0.25em 1em;
  border: 1px solid ${colorsConst.BORDER.DEFAULT};
  border-radius: 4px;
  &::after {
    content: "▼";
    position: absolute;
    right: 1em;
    top: 50%;
    margin-top: -0.75em;
    ${fontSize.XXSMALL};
    color: ${colorsConst.TEXT.LINK};
  }
`;
const buttonStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  appearance: none;
  border: 2px solid ${colorsConst.TEXT.LINK};
  background: none;
  ${fontSize.XLARGE};
  color: ${colorsConst.TEXT.LINK};
  border-radius: 1em;
`;
const PrevButton = styled.button`
  ${buttonStyle};
`;
const NextButton = styled.button`
  ${buttonStyle};
`;

const OrderHistoryTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin-bottom: -1.25em;
`;

type Props = {
  orderHistoryList: OrderData[];
  selectedDate: string;
  handleGoBack: () => void;
  toggleDisplayCalendar: () => void;
  handleClickPrevDate: () => void;
  handleClickNextDate: () => void;
  displayableType: OrderHistoryStateFilterType;
  setDisplayableType: (value: React.SetStateAction<OrderHistoryStateFilterType>) => void;
};

export const OrderHistoryHeader: React.FC<Props> = React.memo(
  ({
    orderHistoryList,
    selectedDate,
    handleGoBack,
    toggleDisplayCalendar,
    handleClickPrevDate,
    handleClickNextDate,
    displayableType,
    setDisplayableType,
  }) => (
    <HeaderContainer>
      <HeaderInner>
        <BackButtonContainer>
          <BackButton handleClick={handleGoBack} />
        </BackButtonContainer>
        <Inner>
          <OrderHistoryHeaderTop>
            <HeaderTitle>注文履歴</HeaderTitle>
          </OrderHistoryHeaderTop>
          <OrderHistoryDateContainer>
            <PrevButton onClick={handleClickPrevDate}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </PrevButton>
            <OrderHistoryDate onClick={toggleDisplayCalendar}>
              {DateUtils.dateToString(selectedDate, "YYYY年M月D日")}
            </OrderHistoryDate>
            <NextButton onClick={handleClickNextDate}>
              <FontAwesomeIcon icon={faAngleRight} />
            </NextButton>
          </OrderHistoryDateContainer>
          <OrderHistoryTabContainer>
            {displayableOrderHistoryStateFilterType.map(filterType => (
              <OrderHistoryHeaderTab
                key={filterType}
                orderHistoryList={orderHistoryList}
                filterType={filterType}
                displayableType={displayableType}
                setDisplayableType={setDisplayableType}
              />
            ))}
          </OrderHistoryTabContainer>
        </Inner>
      </HeaderInner>
    </HeaderContainer>
  ),
);
