import { Action } from "redux";
import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";

import app from "modules/app";
import order from "modules/order";
import shop from "modules/shop";
import sales from "modules/sales";
import information from "modules/information";
import simulator from "modules/simulator";

import { AppState } from "modules/app/model";
import { OrderState } from "modules/order/model";
import { ShopState } from "modules/shop/model";
import { SalesState } from "modules/sales/model";
import { InformationState } from "modules/information/model";
import { SimulatorState } from "modules/simulator/model";

import { AppActions } from "modules/app/actions";
import { OrderActions } from "modules/order/actions";
import { ShopActions } from "modules/shop/actions";
import { SalesActions } from "modules/sales/actions";
import { InformationActions } from "modules/information/actions";
import { SimulatorActions } from "modules/simulator/actions";

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app,
    order,
    shop,
    sales,
    information,
    simulator,
  });

export type ReduxModel = {
  router: RouterState;
  app: AppState;
  order: OrderState;
  shop: ShopState;
  sales: SalesState;
  information: InformationState;
  simulator: SimulatorState;
};

export type ReduxAction =
  | AppActions
  | OrderActions
  | ShopActions
  | SalesActions
  | InformationActions
  | SimulatorActions
  | Action;
