import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { API_KEY } from "apis";
import { ShopItemOptionSet } from "records/ShopItemOptionSet";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import {
  userSubmittedStopOrderByFilteredOptions,
  userSubmittedStopReleaseByFilteredOptions,
} from "modules/shop/actions";
import {
  ShopModel,
  FilteredOptionList,
  ShopItemSubmitType,
  SHOP_ITEM_SUBMIT_TYPE,
} from "modules/shop/model";
import { colorsConst } from "styles/const";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
  ScrollContainer,
  ScrollInner,
} from "components/molecules/ModalDialog";

const contentStyle: React.CSSProperties = {
  flexGrow: 1,
  flexShrink: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
} as const;

const innerStyle: React.CSSProperties = {
  flexGrow: 1,
  flexShrink: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
  padding: 0,
  backgroundColor: colorsConst.BACKGROUND.SECONDARY,
} as const;

const BodyInner = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1em;
`;

const Caption = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.5em 1em 1em;
  text-align: left;
`;

const OptionSetContainer = styled.div`
  margin-bottom: 1em;
`;

const MODAL_KEY = "CONFIRM_BULK_UPDATE_OPTION_STATUS";
const useModal = modalHooksFactory(MODAL_KEY);

const isConnectedApiSelector = isConnectedApiSelectorFactory([
  API_KEY.STOP_ORDER_BY_OPTION_SET,
  API_KEY.STOP_RELEASE_BY_OPTION_SET,
]);

export const ConfirmBulkUpdateOptionStatusDialog: React.FC = React.memo(() => {
  const { data, close } = useModal();

  const submitting = useSelector(isConnectedApiSelector);

  const filteredOptions: FilteredOptionList | undefined = useMemo(
    () =>
      "payload" in data && "filteredOptions" in data.payload
        ? data.payload.filteredOptions
        : undefined,
    [data],
  );

  const submitType: ShopItemSubmitType = useMemo(
    () =>
      "payload" in data && "submitType" in data.payload
        ? data.payload.submitType
        : SHOP_ITEM_SUBMIT_TYPE.STOP,
    [data],
  );

  const displayableOptionSetList: ShopItemOptionSet[] = useMemo(() => {
    if (typeof filteredOptions === "undefined") {
      return [];
    }
    return ShopModel.getDisplayableOptionSetList(filteredOptions);
  }, [filteredOptions]);

  const totalOptionLength = useMemo(
    () => displayableOptionSetList.reduce((acc, crr) => acc + crr.option_list.length, 0),
    [displayableOptionSetList],
  );

  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    switch (submitType) {
      case SHOP_ITEM_SUBMIT_TYPE.STOP:
        dispatch(userSubmittedStopOrderByFilteredOptions(displayableOptionSetList));
        break;
      case SHOP_ITEM_SUBMIT_TYPE.SUSPENSION:
        dispatch(userSubmittedStopReleaseByFilteredOptions(displayableOptionSetList));
        break;
      default:
        break;
    }
  }, [dispatch, submitType, displayableOptionSetList]);

  return (
    <ModalDialog
      isOpen={data.isOpen}
      close={close}
      contentStyle={contentStyle}
      innerStyle={innerStyle}
    >
      <ModalDialogHeader handleClose={close}>オプションの受付を停止する</ModalDialogHeader>
      <ModalBody>
        <BodyInner>
          <Caption>
            <Text color="DANGER">
              {`${totalOptionLength}件が「
              ${submitType === SHOP_ITEM_SUBMIT_TYPE.STOP ? "本日売り切れ" : "再販予定なし"}
              」に設定されます。受付設定を変更してもよろしいですか？`}
            </Text>
          </Caption>
          <ScrollContainer>
            <ScrollInner>
              {displayableOptionSetList.map(optionSet => (
                <OptionSetContainer key={optionSet.option_set_id}>
                  <div>
                    <Text color="SECONDARY" size="SMALL">
                      {optionSet.option_set_name}
                    </Text>
                  </div>
                  <div>
                    {optionSet.option_list.map(option => (
                      <div key={`${optionSet.option_set_id}_${option.shop_option_id}`}>
                        {`・${option.option_name}`}
                      </div>
                    ))}
                  </div>
                </OptionSetContainer>
              ))}
            </ScrollInner>
          </ScrollContainer>
        </BodyInner>
      </ModalBody>
      <ModalFooter>
        <Button appearance="secondary" handleClick={handleSubmit} loading={submitting}>
          確定する
        </Button>
      </ModalFooter>
    </ModalDialog>
  );
});
