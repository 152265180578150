import React, { useMemo, useCallback, useEffect } from "react";
import styled from "styled-components";
import { colorsConst } from "styles/const";

const ToggleButtonStyle = styled.button.attrs({ type: "button" })<{ active: boolean }>`
  appearance: none;
  position: relative;
  display: block;
  width: 4em;
  height: 2em;
  padding: 0;
  border-width: 2px;
  border-style: solid;
  border-radius: 2em;
  box-sizing: content-box;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 2em;
    height: 2em;
    background-color: ${colorsConst.BACKGROUND.WHITE};
    border-radius: 1em;
    transition: 0.5s ease-out;
  }
  ${p =>
    p.active
      ? `
      border-color: ${colorsConst.BACKGROUND.SUCCESS};
      background-color: ${colorsConst.BACKGROUND.SUCCESS};
      &::before {
        left: 2em;
      }
  `
      : `
  border-color: ${colorsConst.BACKGROUND.DISABLED};
  background-color: ${colorsConst.BACKGROUND.DISABLED};

  `}
`;

type Props = {
  active: boolean;
  disabled?: boolean;
  handleClick: () => void;
};

export const ToggleButton: React.FC<Props> = React.memo(({ active, disabled, handleClick }) => (
  <ToggleButtonStyle active={active} onClick={handleClick} disabled={disabled} />
));
