/* eslint-disable import/extensions */
import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import styled, { css, keyframes, Keyframes } from "styled-components";
import { Utility } from "utils/Utility";
import { colorsConst } from "styles/const";
import { ShopData } from "records/ShopData";
import { OrderNotification, NotificationType, NOTIFICATION_TYPE } from "records/OrderNotification";
import {
  userTouchedOrderNotificationDialog,
  userTouchedOrderNotificationBackdrop,
} from "modules/order/actions";
import newOrderSoundfile from "components/atoms/Sounds/newOrder.mp3";
import reservedOrderSoundfile from "components/atoms/Sounds/reservedOrder.mp3";
import remindOrderSoundfile from "components/atoms/Sounds/remindOrder.mp3";

const inAnimations: Keyframes = keyframes`
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  `;

const blickAnimations: Keyframes = keyframes`
    0% {
      background-color: ${colorsConst.BACKGROUND.WHITE};
    }
    100% {
      background-color: #ffd8d8;
    }
  `;

const Container = styled.div`
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  padding: 1em;
  transform: translateY(-100%);
  animation-name: ${inAnimations};
  animation-duration: 0.1s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
`;

const Inner = styled.div<{ withAnimation: boolean }>`
  padding: 1em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  ${p =>
    p.withAnimation
      ? css`
          animation: 2s ${blickAnimations} ease 0s infinite alternate;
        `
      : ""}
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

type Props = {
  notification: OrderNotification;
  shopData: ShopData;
  handleClose: () => void;
  backdrop: boolean;
  isDebug: boolean;
  isSimulator: boolean;
};

export const NotificationBanner: React.FC<Props> = React.memo(
  ({ notification, shopData, handleClose, backdrop, isDebug }) => {
    const [canPlaySound, setCanPlaySound] = useState<boolean>(backdrop);

    const isAutoCancel = useMemo(
      () => notification.notification_type === NOTIFICATION_TYPE.AUTO_CANCEL,
      [notification],
    );

    const audio: HTMLAudioElement | undefined = useMemo(() => {
      if (isAutoCancel) {
        return undefined;
      }
      switch (notification.notification_type) {
        case NOTIFICATION_TYPE.RESERVED_ORDER:
          return new Audio(reservedOrderSoundfile);
        case NOTIFICATION_TYPE.EXIST_NOT_FINISHED_ORDER:
          return new Audio(remindOrderSoundfile);
        default:
          return new Audio(newOrderSoundfile);
      }
    }, [notification, isAutoCancel]);

    const notificationText = useMemo(
      () => OrderNotification.getNotificationBannerText(notification),
      [notification],
    );

    const withAnimation = useMemo(
      () =>
        (
          [
            NOTIFICATION_TYPE.FASTEST_ORDER,
            NOTIFICATION_TYPE.EXIST_NOT_FINISHED_ORDER,
          ] as NotificationType[]
        ).includes(notification.notification_type),
      [notification],
    );

    const dispatch = useDispatch();

    const handleClick = useCallback(() => {
      if (isDebug || notification.order_no === "") {
        handleClose();
      } else {
        dispatch(userTouchedOrderNotificationDialog(notification, shopData));
      }
    }, [dispatch, handleClose, notification, shopData, isDebug]);

    const handleClickBackdrop = useCallback(() => {
      setCanPlaySound(false);
      if (!isDebug && notification.order_no !== "") {
        dispatch(
          userTouchedOrderNotificationBackdrop(
            notification.order_no,
            notification.notification_type,
          ),
        );
      }
    }, [dispatch, notification, isDebug]);

    useEffect(() => {
      let canceled = false;
      if (typeof audio !== "undefined") {
        const PLAY_LIMIT_COUNT = 299;
        let playCount = 0;
        const sleep = (duration: number) =>
          new Promise(resolve => {
            setTimeout(resolve, duration);
          });
        const playSound = async () => {
          try {
            audio.currentTime = 0;
            await audio.play();
            await sleep(Math.floor(audio.duration * 1000));
            playCount += 1;
            if (PLAY_LIMIT_COUNT > playCount && !canceled) {
              playSound();
            }
          } catch (e) {
            console.log(e);
          }
        };
        if (backdrop && canPlaySound) {
          playSound();
        }
      }
      return () => {
        canceled = true;
        if (typeof audio !== "undefined") {
          audio.pause();
        }
      };
    }, [audio, canPlaySound, backdrop]);

    useLayoutEffect(() => {
      let timerId = 0;
      if (isAutoCancel) {
        timerId = window.setTimeout(() => {
          handleClose();
        }, 10000);
      }
      return () => {
        clearTimeout(timerId);
        if (!isDebug && isAutoCancel) {
          dispatch(userTouchedOrderNotificationDialog(notification, shopData));
        }
      };
    }, [dispatch, isDebug, isAutoCancel, notification, shopData, handleClose]);

    return (
      <>
        <Container onClick={handleClick}>
          <Inner withAnimation={withAnimation}>
            {Utility.splitTextByLineFeed(notificationText).map((text, index) => (
              <div key={index}>{text}</div>
            ))}
          </Inner>
        </Container>
        {backdrop && canPlaySound ? <Backdrop onClick={handleClickBackdrop} /> : undefined}
      </>
    );
  },
);
