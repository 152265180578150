import SecureLS from "secure-ls";

export class EncryptedLocalStorage {
  // LocalStorageに暗号化して保存
  public static set(key: string, value: string) {
    const ls = new SecureLS({ encodingType: "rc4" });
    return ls.set(key, value);
  }

  public static get(key: string) {
    const ls = new SecureLS({ encodingType: "rc4" });
    return ls.get(key);
  }
}
