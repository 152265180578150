import React from "react";
import { Field } from "react-final-form";
import styled from "styled-components";
import { BusinessType, BusinessWeekTime, displayableBusinessType } from "records/BusinessWeekTime";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Text } from "components/atoms/Text";

const Container = styled.div`
  margin-top: 0.25em;
`;

const Label = styled.div`
  ${fontSize.XSMALL};
  color: ${colorsConst.TEXT.SECONDARY};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const TypeContainer = styled.label`
  margin-right: 1em;
`;

type Props = {
  name: string;
  week?: number;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    type: BusinessType,
    week: number,
  ) => void;
};

export const BusunessTypeField: React.FC<Props> = React.memo(({ name, week = 0, handleChange }) => (
  <Container>
    <Label>営業タイプを設定してください</Label>
    <Row>
      {displayableBusinessType.map(type => (
        <TypeContainer key={type}>
          <Field
            name={`${name}.business_type`}
            value={type}
            component="input"
            type="radio"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, type, week)
            }
          />
          <Text size="SMALL">{BusinessWeekTime.getPresentationBusinessTypeString(type)}</Text>
        </TypeContainer>
      ))}
    </Row>
  </Container>
));
