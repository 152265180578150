import { put } from "redux-saga/effects";
import { OrderData, ORDER_STATE_TYPE } from "records/OrderData";
import { systemOpenedModal } from "modules/app/actions";
import { DateUtils } from "utils/DateUtils";
import {
  updateSimulatorOrderData,
  updateSimulatorOrderList,
  UserTouchedSimulatorFindCrewForLocalAreaButtonAction,
} from "../actions";
import { SimulatorModel } from "../model";

export function* userTouchedSimulatorFindCrewForLocalAreaButtonSaga(
  action: UserTouchedSimulatorFindCrewForLocalAreaButtonAction,
) {
  const { orderData, draftItems } = action.payload;
  const updatedOrderItems = SimulatorModel.getUpdatedOrderItems(orderData, draftItems);
  const updatedOrderData = OrderData.create({
    ...orderData,
    order_state: ORDER_STATE_TYPE.LOCAL_AREA_SHOP_APPROVE,
    item_list: updatedOrderItems,
    driver_search_expire_time: DateUtils.dateToString(
      DateUtils.getDateWithAddMinutes(new Date(), 5),
      "YYYY-MM-DD HH:mm:ss",
    ),
  });
  yield put(updateSimulatorOrderData(updatedOrderData));
  yield put(updateSimulatorOrderList([updatedOrderData]));
  yield put(systemOpenedModal("FINDING_CREW", {}));
}
