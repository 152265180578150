import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  invert?: boolean;
};

export const IconSetting: React.FC<Props> = React.memo(({ invert }) => {
  const color = invert ? colorsConst.WHITE : colorsConst.ICON.DEFAULT;
  return (
    <svg width="1em" height="1em" viewBox="0 0 34 34" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M13.1,3.8c0.1-1.1,1.1-2,2.2-2h3.5c1.1,0,2.1,0.9,2.2,2l0.2,2.5c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.2,0.1 c0.1,0.1,0.2,0,0.3,0l1.9-1.6c0.9-0.7,2.2-0.7,3,0.1L29,7.5c0.8,0.8,0.9,2.1,0.1,3l-1.6,1.9c0,0-0.1,0.1,0,0.3 c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0,0.1,0l2.5,0.2c1.1,0.1,2,1.1,2,2.2v3.5c0,1.1-0.9,2.1-2,2.2l-2.5,0.2c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.2c-0.1,0.1,0,0.2,0,0.3l1.6,1.9c0.7,0.9,0.7,2.2-0.1,3L26.5,29c-0.8,0.8-2.1,0.9-3,0.1l-1.9-1.6 c0,0-0.1-0.1-0.3,0c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0.1,0,0.1l-0.2,2.5c-0.1,1.1-1.1,2-2.2,2h-3.5c-1.1,0-2.1-0.9-2.2-2l-0.2-2.5 c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2,0-0.3,0l-1.9,1.6c-0.9,0.7-2.2,0.7-3-0.1L5,26.5c-0.8-0.8-0.9-2.1-0.1-3l1.6-1.9 c0,0,0.1-0.1,0-0.3c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1,0-0.1,0l-2.5-0.2c-1.1-0.1-2-1.1-2-2.2v-3.5c0-1.1,0.9-2.1,2-2.2l2.5-0.2 c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.2c0.1-0.1,0-0.2,0-0.3l-1.6-1.9C4.2,9.6,4.2,8.3,5,7.5L7.5,5c0.8-0.8,2.1-0.9,3-0.1l1.9,1.6 c0,0,0.1,0.1,0.3,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0-0.1,0-0.1L13.1,3.8z M18.6,4.2h-3.1l-0.2,2.3c-0.1,1.1-0.8,1.9-1.7,2.2 c-0.9,0.4-1.9,0.3-2.7-0.4L9.1,6.8L6.8,9.1l1.5,1.8c0.7,0.8,0.7,1.9,0.4,2.7c-0.4,0.9-1.2,1.6-2.2,1.7l-2.3,0.2v3.1l2.3,0.2 c1.1,0.1,1.9,0.8,2.2,1.7c0.4,0.9,0.3,1.9-0.4,2.7l-1.5,1.8l2.2,2.2l1.8-1.5c0.8-0.7,1.9-0.7,2.7-0.4c0.9,0.4,1.6,1.2,1.7,2.2 l0.2,2.3h3.1l0.2-2.3c0.1-1.1,0.8-1.9,1.7-2.2c0.9-0.4,1.9-0.3,2.7,0.4l1.8,1.5l2.2-2.2l-1.5-1.8c-0.7-0.8-0.7-1.9-0.4-2.7 c0.4-0.9,1.2-1.6,2.2-1.7l2.3-0.2v-3.1l-2.3-0.2c-1.1-0.1-1.9-0.8-2.2-1.7c-0.4-0.9-0.3-1.9,0.4-2.7l1.5-1.8l-2.2-2.2l-1.8,1.5 c-0.8,0.7-1.9,0.7-2.7,0.4c-0.9-0.4-1.6-1.2-1.7-2.2L18.6,4.2z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M17,20.9c2.2,0,3.9-1.8,3.9-3.9s-1.8-3.9-3.9-3.9s-3.9,1.8-3.9,3.9S14.8,20.9,17,20.9z M17,23.3 c3.5,0,6.3-2.8,6.3-6.3c0-3.5-2.8-6.3-6.3-6.3s-6.3,2.8-6.3,6.3C10.7,20.5,13.5,23.3,17,23.3z"
      />
    </svg>
  );
});
