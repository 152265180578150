/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * URLSearchParamsの代用クラス
 */
export class UrlSearchParams {
  public static get(key: string) {
    const ins = new UrlSearchParams();
    return ins.get(key);
  }

  public static has(key: string) {
    const ins = new UrlSearchParams();
    return ins.has(key);
  }

  public static exists(key: string) {
    const ins = new UrlSearchParams();
    return ins.has(key) && ins.get(key) !== null && ins.get(key) !== "";
  }

  private static memo: Map<string, any> = new Map();

  private params: Map<string, any>;

  // eslint-disable-next-line no-restricted-globals
  private constructor(search: string = self.location.search) {
    this.params = UrlSearchParams.memo.has(search)
      ? UrlSearchParams.memo.get(search)
      : search
          .substring(1)
          .split("&")
          .reduce((acc, crr) => {
            const [key, value] = crr.includes("=") ? crr.split("=") : [crr, true];
            return acc.set(key, value);
          }, new Map<string, any>());
    UrlSearchParams.memo.set(search, new Map(this.params));
  }

  private get(key: string): string | null {
    if (!this.has(key)) {
      return null;
    }
    return decodeURIComponent(this.params.get(key));
  }

  private has(key: string) {
    return this.params.has(key);
  }
}
