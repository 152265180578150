import { fork, call } from "redux-saga/effects";
import { API_KEY, ReadShopNotificationRequest, ReadShopNotificationResponse } from "apis";
import { ShopNotificationType } from "records/ShopNotification";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";

export function* readShopNotificationSaga(notificationType: ShopNotificationType) {
  try {
    const params: ReadShopNotificationRequest = {
      notification_type: notificationType,
    };
    const { result, error }: { result: ReadShopNotificationResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.READ_SHOP_NOTIFICATION,
      params,
    );
    if (error) {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
