/* eslint-disable func-names*/
import * as Yup from "yup";
import { BusinessBusyTime } from "records/BusinessBusyTime";

export type UpdateBusinessBusyTimeForm = {
  business_busy_time: BusinessBusyTime;
};

export const updateBusinessBusyTimeFormValidationSchema = Yup.object({
  business_busy_time: Yup.object().shape({
    week: Yup.number(),
    busy_times: Yup.array().of(
      Yup.object().shape({
        start_time: Yup.string().required("時間帯を選択してください"),
        end_time: Yup.string()
          .required("時間帯を選択してください")
          .test("isAfter", "正しい時間帯を選択してください", function (value) {
            if (typeof value === "undefined") {
              return false;
            }
            const startTimeInt = parseInt(this.parent["start_time"], 10);
            const endTimeInt = parseInt(value, 10);
            if (Number.isNaN(startTimeInt) || Number.isNaN(endTimeInt)) {
              return false;
            }
            return startTimeInt < endTimeInt;
          }),
      }),
    ),
  }),
})
  .strict(true)
  .noUnknown();
