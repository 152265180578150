import { ApiKey } from "apis";
import { LoginShopForm } from "forms/loginShop";
import { ModalKey, ModalPayload } from "records/ModalManager";
import { Printer, PrinterStatus } from "records/Printer";
import { OrderData } from "records/OrderData";
import { SnackBarType } from "records/SnackBar";
import { ProcessingFlag } from "records/ProcessingFlag";
import { printerSelector } from "./selectors";

export enum ActionTypes {
  U_TOUCHED_LOGIN_BUTTON = "app/U_TOUCHED_LOGIN_BUTTON",
  U_SUBMITTED_LOGIN_SHOP_FORM = "app/U_SUBMITTED_LOGIN_SHOP_FORM",
  U_TOUCHED_LOGOUT_BUTTON = "app/U_TOUCHED_LOGOUT_BUTTON",
  U_SUBMITTED_PRINT_RECEIPT = "app/U_SUBMITTED_PRINT_RECEIPT",

  S_LAUNCHED = "app/S_LAUNCHED",
  S_AUTO_LOGIN = "app/S_AUTO_LOGIN",
  S_STARTED_CONNECT_API = "app/S_STARTED_CONNECT_API",
  S_FINISHED_CONNECT_API = "app/S_FINISHED_CONNECT_API",
  S_SET_PROCESSING_FLAG = "app/S_SET_PROCESSING_FLAG",
  S_REMOVE_PROCESSING_FLAG = "app/S_REMOVE_PROCESSING_FLAG",
  S_OPENED_MODAL = "app/S_OPENED_MODAL",
  S_CLOSED_MODAL = "app/S_CLOSED_MODAL",
  S_UPDATED_MODAL = "spp/S_UPDATED_MODAL",
  S_INIT_SNACK_BAR = "app/S_INIT_SNACK_BAR",
  S_SEARCH_PRINTER = "app/S_SEARCH_PRINTER",
  S_SET_PRINTER_STATUS = "app/S_SET_PRINTER_STATUS",
  S_PRINT_RECEIPT = "app/S_PRINT_RECEIPT",
  S_AUTO_PRINT_RECEIPT = "app/S_AUTO_PRINT_RECEIPT",
  S_SEND_DAILY_LOGIN = "app/S_SEND_DAILY_LOGIN",
  S_DISCONNECT_PRINTER = "app/S_DISCONNECT_PRINTER",
  S_STOP_DISCONNECT_PRINTER = "app/S_STOP_DISCONNECT_PRINTER",

  R_SET_LAUNCHED = "app/R_SET_LAUNCHED",
  R_UPDATE_SERVER_DATE_TIME = "app/R_UPDATE_SERVER_DATE_TIME",
  R_UPDATE_LOGIN_TOKEN = "app/R_UPDATE_LOGIN_TOKEN",
  R_INIT_LOGIN_TOKEN = "app/R_INIT_LOGIN_TOKEN",
  R_SET_APP_VERSION = "app/R_SET_APP_VERSION",
  R_UPDATE_CONNECTING_API_SET = "app/R_UPDATE_CONNECTING_API_SET",
  R_UPDATE_PROCESSING_FLAG_SET = "app/R_UPDATE_PROCESSING_FLAG_SET",
  R_OPEN_MODAL = "app/R_OPEN_MODAL",
  R_CLOSE_MODAL = "app/R_CLOSE_MODAL",
  R_UPDATE_MODAL = "app/R_UPDATE_MODAL",
  R_INIT_SNACK_BAR = "app/R_INIT_SNACK_BAR",
  R_UPDATE_SNACK_BAR = "app/R_UPDATE_SNACK_BAR",
  R_SET_SHOULD_UPDATE_RESOURCE_FLAG = "app/R_SET_SHOULD_UPDATE_RESOURCE_FLAG",
  R_UPDATE_DISCOVERED_PRINTER = "app/R_UPDATE_DISCOVERED_PRINTER",
  R_UPDATE_CONNECTED_PRINTER = "app/R_UPDATE_CONNECTED_PRINTER",
  R_SET_PRINTER_STATUS = "app/R_SET_PRINTER_STATUS",
  R_SET_ENEBLE_BLUETOOTH = "app/R_SET_ENEBLE_BLUETOOTH",
}

export const userTouchedLoginButton = () =>
  ({
    type: ActionTypes.U_TOUCHED_LOGIN_BUTTON,
  } as const);
export type UserTouchedLoginWithTokenButtonAction = ReturnType<typeof userTouchedLoginButton>;

export const userSubmittedLoginShopForm = (values: LoginShopForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_LOGIN_SHOP_FORM,
    payload: {
      values,
    },
  } as const);
export type UserSubmittedLoginShopFormAction = ReturnType<typeof userSubmittedLoginShopForm>;

export const userTouchedLogoutButton = () =>
  ({
    type: ActionTypes.U_TOUCHED_LOGOUT_BUTTON,
  } as const);
export type UserTouchedLogoutButtonAction = ReturnType<typeof userTouchedLogoutButton>;

export const userSubmittedPrintReceipt = (order: OrderData) =>
  ({
    type: ActionTypes.U_SUBMITTED_PRINT_RECEIPT,
    payload: {
      order,
    },
  } as const);
export type UserSubmittedPrintReceiptAction = ReturnType<typeof userSubmittedPrintReceipt>;

export const systemLaunched = () =>
  ({
    type: ActionTypes.S_LAUNCHED,
  } as const);
export type SystemLaunchedAction = ReturnType<typeof systemLaunched>;

export const systemAutoLogin = () =>
  ({
    type: ActionTypes.S_AUTO_LOGIN,
  } as const);
export type SystemAutoLoginAction = ReturnType<typeof systemAutoLogin>;

export const systemStartedConnectApi = (apiKey: ApiKey) =>
  ({
    type: ActionTypes.S_STARTED_CONNECT_API,
    payload: {
      apiKey,
    },
  } as const);
export type SystemStartedConnectApiAction = ReturnType<typeof systemStartedConnectApi>;

export const systemFinishedConnectApi = (apiKey: ApiKey) =>
  ({
    type: ActionTypes.S_FINISHED_CONNECT_API,
    payload: {
      apiKey,
    },
  } as const);
export type SystemFinishedConnectApiAction = ReturnType<typeof systemFinishedConnectApi>;

export const setProcessingFlag = (flag: ProcessingFlag | ProcessingFlag[]) =>
  ({
    type: ActionTypes.S_SET_PROCESSING_FLAG,
    payload: {
      flag,
    },
  } as const);
export type SystemSetProcessingFlagAction = ReturnType<typeof setProcessingFlag>;

export const removeProcessingFlag = (flag: ProcessingFlag | ProcessingFlag[]) =>
  ({
    type: ActionTypes.S_REMOVE_PROCESSING_FLAG,
    payload: {
      flag,
    },
  } as const);
export type SystemRemoveProcessingFlagAction = ReturnType<typeof removeProcessingFlag>;

export const systemOpenedModal = <T extends ModalKey>(
  modalKey: ModalKey,
  modalPayload: ModalPayload<T>,
) =>
  ({
    type: ActionTypes.S_OPENED_MODAL,
    payload: {
      modalKey,
      modalPayload,
    },
  } as const);
export type SystemOpenedModalAction = ReturnType<typeof systemOpenedModal>;

export const systemClosedModal = (modalKey: ModalKey) =>
  ({
    type: ActionTypes.S_CLOSED_MODAL,
    payload: {
      modalKey,
    },
  } as const);
export type SystemClosedModalAction = ReturnType<typeof systemClosedModal>;

export const systemUpdatedModal = <T extends ModalKey>(
  modalKey: ModalKey,
  modalPayload: ModalPayload<T>,
) =>
  ({
    type: ActionTypes.S_UPDATED_MODAL,
    payload: {
      modalKey,
      modalPayload,
    },
  } as const);
export type SystemUpdatedModalAction = ReturnType<typeof systemUpdatedModal>;

export const systemInitSnackBar = () =>
  ({
    type: ActionTypes.S_INIT_SNACK_BAR,
  } as const);
export type SystemInitSnackBarAction = ReturnType<typeof systemInitSnackBar>;

export const systemSearchPrinter = () =>
  ({
    type: ActionTypes.S_SEARCH_PRINTER,
  } as const);
export type SystemSearchPrinterAction = ReturnType<typeof systemSearchPrinter>;

export const systemSendDailyLogin = (shopId: number, shopStaffId: string, useFirestore: boolean) =>
  ({
    type: ActionTypes.S_SEND_DAILY_LOGIN,
    payload: {
      shopId,
      shopStaffId,
      useFirestore,
    },
  } as const);
export type SystemUpdateDailyLoginAction = ReturnType<typeof systemSendDailyLogin>;

export const systemSetPrinterStatus = (status?: PrinterStatus) =>
  ({
    type: ActionTypes.S_SET_PRINTER_STATUS,
    payload: {
      status,
    },
  } as const);
export type SystemSetPrinterStatusAction = ReturnType<typeof systemSetPrinterStatus>;

export const systemPrintReceipt = (orderData: OrderData, printCount: number = 1) =>
  ({
    type: ActionTypes.S_PRINT_RECEIPT,
    payload: {
      orderData,
      printCount,
    },
  } as const);
export type SystemPrintReceiptAction = ReturnType<typeof systemPrintReceipt>;

export const systemAutoPrintReceipt = (orderData: OrderData, printCount: number = 1) =>
  ({
    type: ActionTypes.S_AUTO_PRINT_RECEIPT,
    payload: {
      orderData,
      printCount,
    },
  } as const);
export type SystemAutoPrintReceiptAction = ReturnType<typeof systemAutoPrintReceipt>;

export const systemDisconnectPrinter = () =>
  ({
    type: ActionTypes.S_DISCONNECT_PRINTER,
  } as const);
export type SystemDisconnectPrinterAction = ReturnType<typeof systemDisconnectPrinter>;

export const systemStopDisconnectPrinter = () =>
  ({
    type: ActionTypes.S_STOP_DISCONNECT_PRINTER,
  } as const);
export type SystemStopDisconnectPrinterAction = ReturnType<typeof systemStopDisconnectPrinter>;

export const setLaunched = () =>
  ({
    type: ActionTypes.R_SET_LAUNCHED,
  } as const);
export type ReducerSetLaunchedAction = ReturnType<typeof setLaunched>;

export const updateServerDateTime = (serverDateTime: string) =>
  ({
    type: ActionTypes.R_UPDATE_SERVER_DATE_TIME,
    payload: {
      serverDateTime,
    },
  } as const);
export type ReducerUpdateServerDateTimeAction = ReturnType<typeof updateServerDateTime>;

export const updateLoginToken = (token: string | null) =>
  ({
    type: ActionTypes.R_UPDATE_LOGIN_TOKEN,
    payload: {
      token,
    },
  } as const);
export type ReducerUpdateLoginTokenAction = ReturnType<typeof updateLoginToken>;

export const initLoginToken = () =>
  ({
    type: ActionTypes.R_INIT_LOGIN_TOKEN,
  } as const);
export type ReducerInitLoginTokenAction = ReturnType<typeof initLoginToken>;

export const setAppVersion = (value: string) =>
  ({
    type: ActionTypes.R_SET_APP_VERSION,
    payload: {
      value,
    },
  } as const);
export type ReducerSetAppVersionAction = ReturnType<typeof setAppVersion>;

export const updateConnectingApiSet = (apiKey: ApiKey, add: boolean) =>
  ({
    type: ActionTypes.R_UPDATE_CONNECTING_API_SET,
    payload: {
      apiKey,
      add,
    },
  } as const);
export type ReducerUpdateConnectingApiSetAction = ReturnType<typeof updateConnectingApiSet>;

export const updateProcessingFlagSet = (flagSet: ProcessingFlag[]) =>
  ({
    type: ActionTypes.R_UPDATE_PROCESSING_FLAG_SET,
    payload: {
      flagSet,
    },
  } as const);
export type ReducerUpdateProcessingFlagSetAction = ReturnType<typeof updateProcessingFlagSet>;

export const openModal = <T extends ModalKey>(key: T, payload: ModalPayload<T>) =>
  ({
    type: ActionTypes.R_OPEN_MODAL,
    payload: {
      key,
      payload,
    },
  } as const);
export type ReducerOpenModalAction = ReturnType<typeof openModal>;

export const closeModal = (key: ModalKey) =>
  ({
    type: ActionTypes.R_CLOSE_MODAL,
    payload: {
      key,
    },
  } as const);
export type ReducerCloseModalAction = ReturnType<typeof closeModal>;

export const updateModal = <T extends ModalKey>(key: T, payload: ModalPayload<T>) =>
  ({
    type: ActionTypes.R_UPDATE_MODAL,
    payload: {
      key,
      payload,
    },
  } as const);
export type ReducerUpdatenModalAction = ReturnType<typeof updateModal>;

export const initSnackBar = () =>
  ({
    type: ActionTypes.R_INIT_SNACK_BAR,
  } as const);
export type ReducerInitSnackBarAction = ReturnType<typeof initSnackBar>;

export const updateSnackBar = (text: string, type: SnackBarType = "DEFAULT") =>
  ({
    type: ActionTypes.R_UPDATE_SNACK_BAR,
    payload: {
      text,
      type,
    },
  } as const);
export type ReducerUpdateSnackBarAction = ReturnType<typeof updateSnackBar>;

export const setShouldUpdateResourceFlag = () =>
  ({
    type: ActionTypes.R_SET_SHOULD_UPDATE_RESOURCE_FLAG,
  } as const);
export type ReducerSetShouldUpdateResourceFlagAction = ReturnType<
  typeof setShouldUpdateResourceFlag
>;

export const updateDiscoveredPrinter = (value?: Printer) =>
  ({
    type: ActionTypes.R_UPDATE_DISCOVERED_PRINTER,
    payload: {
      value,
    },
  } as const);
export type ReducerUpdateDiscoveredPrinterAction = ReturnType<typeof updateDiscoveredPrinter>;

export const updateConnectedPrinter = (value?: boolean) =>
  ({
    type: ActionTypes.R_UPDATE_CONNECTED_PRINTER,
    payload: {
      value,
    },
  } as const);
export type ReducerUpdateConnectedPrinterAction = ReturnType<typeof updateConnectedPrinter>;

export const setPrinterStatus = (status?: PrinterStatus) =>
  ({
    type: ActionTypes.R_SET_PRINTER_STATUS,
    payload: {
      status,
    },
  } as const);
export type ReducerSetPrinterStatusAction = ReturnType<typeof setPrinterStatus>;

export const setEnebleBluetooth = (bool: boolean) =>
  ({
    type: ActionTypes.R_SET_ENEBLE_BLUETOOTH,
    payload: {
      bool,
    },
  } as const);
export type ReducerSetEnableBluetoothAction = ReturnType<typeof setEnebleBluetooth>;

export type AppActions =
  | UserTouchedLoginWithTokenButtonAction
  | UserSubmittedLoginShopFormAction
  | UserTouchedLogoutButtonAction
  | UserSubmittedPrintReceiptAction
  | SystemLaunchedAction
  | SystemAutoLoginAction
  | SystemStartedConnectApiAction
  | SystemFinishedConnectApiAction
  | SystemSetProcessingFlagAction
  | SystemRemoveProcessingFlagAction
  | SystemOpenedModalAction
  | SystemClosedModalAction
  | SystemUpdatedModalAction
  | SystemInitSnackBarAction
  | SystemUpdateDailyLoginAction
  | SystemSearchPrinterAction
  | SystemSetPrinterStatusAction
  | SystemPrintReceiptAction
  | SystemAutoPrintReceiptAction
  | SystemDisconnectPrinterAction
  | SystemStopDisconnectPrinterAction
  | ReducerSetLaunchedAction
  | ReducerUpdateServerDateTimeAction
  | ReducerUpdateLoginTokenAction
  | ReducerInitLoginTokenAction
  | ReducerSetAppVersionAction
  | ReducerUpdateConnectingApiSetAction
  | ReducerUpdateProcessingFlagSetAction
  | ReducerOpenModalAction
  | ReducerCloseModalAction
  | ReducerUpdatenModalAction
  | ReducerInitSnackBarAction
  | ReducerUpdateSnackBarAction
  | ReducerSetShouldUpdateResourceFlagAction
  | ReducerUpdateDiscoveredPrinterAction
  | ReducerUpdateConnectedPrinterAction
  | ReducerSetPrinterStatusAction
  | ReducerSetEnableBluetoothAction;
