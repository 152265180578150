import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { colorsConst, fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { ShopItemSubmitType, SHOP_ITEM_SUBMIT_TYPE } from "modules/shop/model";
import { buttonStyle } from "components/atoms/Button";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ButtonContainer = styled.div`
  width: ${100 / 3}%;
  flex-grow: 1;
  flex-shrink: 1;
`;

const Button = styled.button.attrs({ type: "button" })<{ active: boolean }>`
  ${buttonStyle};
  ${fontSize.SMALL};
  font-weight: ${fontWeightsConst.REGULAR};
  padding: 0.25em 0;
  border-radius: 0;
  border: 1px solid ${colorsConst.BORDER.DEFAULT};
  white-space: nowrap;
  ${p =>
    p.active
      ? `
    color: ${colorsConst.BUTTON.TEXT.DEFAULT};
    font-weight: ${fontWeightsConst.BOLD};
  `
      : ""}
`;

const RestartButton = styled(Button)<{ active: boolean }>`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  ${p => (p.active ? `background-color: ${colorsConst.BUTTON.BACKGROUND.TERTIARY}` : "")}
`;
const StopButton = styled(Button)<{ active: boolean }>`
  ${p => (p.active ? `background-color: ${colorsConst.BUTTON.BACKGROUND.SECONDARY}` : "")}
`;
const SuspentionButton = styled(Button)<{ active: boolean }>`
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  ${p => (p.active ? `background-color: ${colorsConst.BUTTON.BACKGROUND.DISABLED}` : "")}
`;

type Props = {
  itemId: number;
  isReleased: boolean;
  isOrderStop: boolean;
  submitting: boolean;
  handleStatus: (itemId: number, submitType: ShopItemSubmitType) => void;
};

export const ShopItemStatus: React.FC<Props> = React.memo(
  ({ itemId, isReleased, isOrderStop, submitting, handleStatus }) => {
    /**
     * isReleased,isOrderStopが排他的でないためボタンのdisabled状態を個別に定義
     */
    const isDisabledSubmitType = useMemo(() => {
      if (!isReleased) {
        return SHOP_ITEM_SUBMIT_TYPE.SUSPENSION;
      }
      if (isOrderStop) {
        return SHOP_ITEM_SUBMIT_TYPE.STOP;
      }
      return SHOP_ITEM_SUBMIT_TYPE.RESTART;
    }, [isReleased, isOrderStop]);

    const handleRestart = useCallback(() => {
      handleStatus(itemId, SHOP_ITEM_SUBMIT_TYPE.RESTART);
    }, [itemId, handleStatus]);

    const handleSoldOut = useCallback(() => {
      handleStatus(itemId, SHOP_ITEM_SUBMIT_TYPE.STOP);
    }, [itemId, handleStatus]);

    const handleSuspension = useCallback(() => {
      handleStatus(itemId, SHOP_ITEM_SUBMIT_TYPE.SUSPENSION);
    }, [itemId, handleStatus]);

    return (
      <Container>
        <ButtonContainer>
          <RestartButton
            onClick={handleRestart}
            active={isDisabledSubmitType === SHOP_ITEM_SUBMIT_TYPE.RESTART}
            disabled={submitting || isDisabledSubmitType === SHOP_ITEM_SUBMIT_TYPE.RESTART}
          >
            受付中
          </RestartButton>
        </ButtonContainer>
        <ButtonContainer>
          <StopButton
            onClick={handleSoldOut}
            active={isDisabledSubmitType === SHOP_ITEM_SUBMIT_TYPE.STOP}
            disabled={submitting || isDisabledSubmitType === SHOP_ITEM_SUBMIT_TYPE.STOP}
          >
            本日売り切れ
          </StopButton>
        </ButtonContainer>
        <ButtonContainer>
          <SuspentionButton
            onClick={handleSuspension}
            active={isDisabledSubmitType === SHOP_ITEM_SUBMIT_TYPE.SUSPENSION}
            disabled={submitting || isDisabledSubmitType === SHOP_ITEM_SUBMIT_TYPE.SUSPENSION}
          >
            再販予定なし
          </SuspentionButton>
        </ButtonContainer>
      </Container>
    );
  },
);
