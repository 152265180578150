export type SalesToday = Readonly<{
  sales: number;
  un_approve_count: number;
}>;

const initialState: SalesToday = {
  sales: 0,
  un_approve_count: 0,
};

const create = (args: Partial<SalesToday> = {}) => ({
  ...initialState,
  ...args,
});

export const SalesToday = Object.freeze({
  initialState,
  create,
});
