import React, { useContext } from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { OrderListItem } from "./OrderListItem";
import { OrderListContext } from "./OrderListContext";

const Container = styled.div`
  margin-bottom: 1em;
`;

type Props = {
  list: OrderData[];
  isHistory?: boolean;
  handleClick: (orderId: string) => void;
};

export const OrderList: React.FC<Props> = React.memo(({ list, isHistory, handleClick }) => {
  const { shopData, notDisplayOrderItem } = useContext(OrderListContext);
  return (
    <Container>
      {list.map(order => (
        <OrderListItem
          key={order.order_no}
          orderData={order}
          shopData={shopData}
          notDisplayOrderItem={notDisplayOrderItem}
          isHistory={isHistory}
          handleClick={handleClick}
        />
      ))}
    </Container>
  );
});
