import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import {
  UpdateBusinessTimeForm,
  updateBusinessTimeFormValidationSchema,
} from "forms/updateBusinessTime";
import { useValidationSchema } from "forms/utils/useValidationSchema";
import { API_KEY } from "apis";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { Contents } from "components/atoms/Contents";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import { businessWeekTimeSelector } from "modules/shop/selectors";
import { userSubmittedUpdateBusinessTime } from "modules/shop/actions";
import { BusinessWeekTimeField } from "components/molecules/BusinessWeekTimeField";

const Container = styled.form`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;
const ButtonContainer = styled.div<{ active: boolean }>`
  display: ${p => (p.active ? "block" : "none")};
  padding: 1em;
`;

const isConnectedApiSelector = isConnectedApiSelectorFactory([API_KEY.UPDATE_BUSINESS_TIME]);

type Props = {};

export const ShopBusinessTimeEdit: React.FC<Props> = React.memo(() => {
  const validate = useValidationSchema(updateBusinessTimeFormValidationSchema);
  const businessWeekTimes = useSelector(businessWeekTimeSelector);
  const loading = useSelector(isConnectedApiSelector);

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values: UpdateBusinessTimeForm) => {
      dispatch(userSubmittedUpdateBusinessTime(values));
    },
    [dispatch],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ business_week_time: businessWeekTimes }}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ handleSubmit, valid, submitting, values, dirty, touched }) => (
        <Container onSubmit={handleSubmit}>
          <Contents>
            <div>
              <Text>
                デリバリー･テイクアウト注文を受け付ける時間を曜日毎に設定することができます。ランチ、ディナータイムなど間を開けて2つの時間帯で注文を受け付けたい場合は、「1日2回営業」をお選びください。
              </Text>
              <br />
              <Text size="SMALL">※店舗自体の営業時間と合わせていただく必要はございません。</Text>
            </div>
            <BusinessWeekTimeField fieldArrayName="business_week_time" />
          </Contents>
          <ButtonContainer active={dirty}>
            <Button
              appearance="primary"
              type="submit"
              disabled={!dirty || !valid}
              loading={submitting || loading}
            >
              保存する
            </Button>
          </ButtonContainer>
        </Container>
      )}
    </Form>
  );
});
