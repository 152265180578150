import React, { useMemo } from "react";
import styled from "styled-components";
import { ShopItemOptionSet } from "records/ShopItemOptionSet";
import { ShopModel } from "modules/shop/model";

const OptionSetName = styled.div`
  font-weight: 500;
`;
const OptionName = styled.span`
  font-weight: 500;
  & + &::before {
    content: ", ";
  }
`;
const OptionContainer = styled.div`
  padding-left: 1em;
`;

type Props = {
  shopItemId: number;
  optionSetId: number;
  shopItemOptionSets: ShopItemOptionSet[];
};

export const UnRestartableOptionSet: React.FC<Props> = React.memo(
  ({ shopItemId, optionSetId, shopItemOptionSets }) => {
    const targetOptionSet = useMemo(
      () => ShopModel.getOptionSetById(shopItemOptionSets, optionSetId),
      [optionSetId, shopItemOptionSets],
    );
    return typeof targetOptionSet !== "undefined" ? (
      <div>
        <OptionSetName>{targetOptionSet.option_set_name}</OptionSetName>
        <OptionContainer>
          {targetOptionSet.option_list.map(option => (
            <OptionName key={`${shopItemId}_${optionSetId}_${option.shop_option_id}`}>
              {option.option_name}
            </OptionName>
          ))}
        </OptionContainer>
      </div>
    ) : null;
  },
);
