import { BusinessTime } from "./BusinessTime";

export type BusinessBusyTime = Readonly<{
  week: number;
  busy_times: BusinessTime[];
}>;

const initialState: BusinessBusyTime = {
  week: 0,
  busy_times: [],
};

const create = (args: Partial<BusinessBusyTime> = {}) => ({
  ...initialState,
  ...args,
  busy_times: args.busy_times ? args.busy_times.map(i => BusinessTime.create(i)) : [],
});

export const BusinessBusyTime = Object.freeze({
  initialState,
  create,
});
