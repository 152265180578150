import isEqual from "lodash.isequal";
import { useCallback } from "react";
import { modalManagerSelector } from "modules/app/selectors";
import { useSelector, useDispatch } from "react-redux";
import { systemOpenedModal, systemClosedModal } from "modules/app/actions";
import { ModalKey, ModalManager, ModalPayload } from "records/ModalManager";
import { createSelector } from "reselect";

function existsPayload<K extends ModalKey>(
  isOpen: boolean,
  payload: ModalPayload<K> | undefined,
): payload is ModalPayload<K> {
  return isOpen;
}

export const modalDataSelectorFactory = <K extends ModalKey>(key: K) =>
  createSelector(modalManagerSelector, modalManager => {
    const [_isOpen, _payload] = [
      ModalManager.canDisplay(modalManager, key),
      ModalManager.getPayload(modalManager, key),
    ];
    if (existsPayload<K>(_isOpen, _payload)) {
      return { isOpen: true, payload: _payload } as const;
    }
    return { isOpen: false, payload: {} } as const;
  });

export const modalHooksFactory = <K extends ModalKey>(key: K) => {
  const dataSelector = modalDataSelectorFactory(key);

  return () => {
    const data = useSelector(dataSelector, isEqual);
    const dispatch = useDispatch();

    const open = useCallback(
      (payload: ModalPayload<K>) => {
        dispatch(systemOpenedModal(key, payload));
      },
      [dispatch],
    );

    const close = useCallback(() => {
      dispatch(systemClosedModal(key));
    }, [dispatch]);

    return {
      data,
      open,
      close,
    };
  };
};
