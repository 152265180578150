import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { OrderData } from "records/OrderData";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { DateUtils } from "utils/DateUtils";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
} from "components/molecules/ModalDialog";

const innerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND.PRIMARY,
} as const;

const ReceiveTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
`;

const ReceiveTimeLabel = styled.div``;

const ReceiveTimeValue = styled.div`
  margin-left: 0.5em;
`;

const DiffTimeField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

const DiffTimeContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
`;

const ButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const CountButton = styled.button.attrs({ type: "button" })`
  appearance: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  ${fontSize.XLARGE};
  color: ${colorsConst.BUTTON.TEXT.PRIMARY};
  background: none;
  border: 2px solid ${colorsConst.BUTTON.TEXT.PRIMARY};
  border-radius: 1em;
`;

const MODAL_KEY = "UPDATE_COOKING_START_TIME";
const useModal = modalHooksFactory(MODAL_KEY);

type Props = {
  orderData: OrderData;
  handleClickApproveOrderWithUpdateCookingStartTimeButton?: (remindMinutes: number) => void;
};

export const UpdateCookingStartTimeDialog: React.FC<Props> = React.memo(
  ({ orderData, handleClickApproveOrderWithUpdateCookingStartTimeButton }) => {
    const { data, close } = useModal();

    const [remindMinutes, setRemindMinutes] = useState(0);

    const countUp = useCallback(() => {
      if (remindMinutes < 30) {
        setRemindMinutes(remindMinutes + 10);
      }
    }, [remindMinutes]);

    const countDown = useCallback(() => {
      if (remindMinutes > 0) {
        setRemindMinutes(remindMinutes - 10);
      }
    }, [remindMinutes]);

    const handleClick = useCallback(() => {
      if (typeof handleClickApproveOrderWithUpdateCookingStartTimeButton !== "undefined") {
        handleClickApproveOrderWithUpdateCookingStartTimeButton(remindMinutes);
      }
    }, [remindMinutes, handleClickApproveOrderWithUpdateCookingStartTimeButton]);

    return (
      <ModalDialog isOpen={data.isOpen} close={close} innerStyle={innerStyle}>
        <ModalDialogHeader handleClose={close}>受付時間の設定</ModalDialogHeader>
        <ModalBody>
          <div>準備開始時間になったらお知らせします</div>
          <ReceiveTimeContainer>
            <ReceiveTimeLabel>
              <Text size="XSMALL" color="SECONDARY">
                ご来店予定
              </Text>
            </ReceiveTimeLabel>
            <ReceiveTimeValue>
              <Text size="SMALL">
                {DateUtils.dateToString(orderData.receive_datetime, "M月D日 HH:mm")}
              </Text>
            </ReceiveTimeValue>
          </ReceiveTimeContainer>
          <DiffTimeField>
            <ButtonContainer>
              <CountButton onClick={countDown}>
                <FontAwesomeIcon icon={faMinus} />
              </CountButton>
            </ButtonContainer>
            <DiffTimeContainer>
              <div>
                <Text size="SMALL">来店</Text>
              </div>
              <div>
                <Text size="XXLARGE" weight="BOLD">{`${remindMinutes}分前`}</Text>
              </div>
            </DiffTimeContainer>
            <ButtonContainer>
              <CountButton type="button" onClick={countUp}>
                <FontAwesomeIcon icon={faPlus} />
              </CountButton>
            </ButtonContainer>
          </DiffTimeField>
        </ModalBody>
        <ModalFooter>
          <Button appearance="primary" handleClick={handleClick}>
            通知時間を確定する
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  },
);
