import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { API_KEY } from "apis";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import {
  UpdateBusinessNationalHolidayForm,
  updateBusinessNationalHolidayFormValidationSchema,
} from "forms/updateBusinessNationalHoliday";
import { userSubmittedUpdateBusinessNationalHoliday } from "modules/shop/actions";
import { businessNationalHolidaySelector } from "modules/shop/selectors";
import { useValidationSchema } from "forms/utils/useValidationSchema";
import { colorsConst, fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Button } from "components/atoms/Button";
import { BusinessNationalHolidayFields } from "components/molecules/BusinessNationalHolidayFields";

const Container = styled.form`
  padding: 0.5em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

const Label = styled.div`
  ${fontSize.XSMALL};
  color: ${colorsConst.TEXT.SECONDARY};
`;

const ButtonContainer = styled.div`
  padding-top: 1em;
`;

const isConnectedApiSelector = isConnectedApiSelectorFactory([
  API_KEY.UPDATE_BUSINESS_NATIONAL_HOLIDAY,
]);

type Props = {};

export const ShopBusinessNationalHolidayEdit: React.FC<Props> = React.memo(() => {
  const loading = useSelector(isConnectedApiSelector);
  const validate = useValidationSchema(updateBusinessNationalHolidayFormValidationSchema);
  const businessNationalHoliday = useSelector(businessNationalHolidaySelector);

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values: UpdateBusinessNationalHolidayForm) => {
      dispatch(userSubmittedUpdateBusinessNationalHoliday(values));
    },
    [dispatch],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        business_national_holiday: businessNationalHoliday,
      }}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ handleSubmit, valid, submitting, values, dirty }) => (
        <Container onSubmit={handleSubmit}>
          <Label>祝日の営業時間の指定</Label>
          <BusinessNationalHolidayFields />
          <ButtonContainer>
            <Button
              appearance="primary"
              type="submit"
              loading={submitting || loading}
              disabled={!valid}
            >
              保存する
            </Button>
          </ButtonContainer>
        </Container>
      )}
    </Form>
  );
});
