import { fork, call } from "redux-saga/effects";
import { API_KEY, ReadNotificationRequest, ReadNotificationResponse } from "apis";
import { NotificationType } from "records/OrderNotification";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { OrderModel } from "../model";

export function* readNotificationSaga(orderId: string, notificationType: NotificationType) {
  try {
    const params: ReadNotificationRequest = {
      read_notification_list: [
        {
          order_no: orderId,
          notification_type: notificationType,
        },
      ],
    };
    const { result, error }: { result: ReadNotificationResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.READ_NOTIFICATION,
      params,
    );
    if (/* result && ! */ error) {
      //   OrderModel.updateReadNotificationInStorage(orderId, notificationType);
      // } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
