import React, { useMemo, useContext } from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { ReceiveProgress } from "./ReceiveProgress";
import { OrderDetailContext } from "./OrderDetailContext";

const Container = styled.div`
  padding: 0.5em;
  background-color: ${colorsConst.BACKGROUND.SECONDARY};
`;

const ReceiveDateTimeInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const DriverInfo = styled.div`
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ArrivalInfo = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ArrivalAt = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Label = styled.span`
  margin-right: 0.25em;
  ${fontSize.SMALL};
  color: ${colorsConst.TEXT.SECONDARY};
`;

const DriverName = styled.div``;

type Props = {};

export const ReceiveInfo: React.FC<Props> = React.memo(() => {
  const { orderData } = useContext(OrderDetailContext);
  const isDelivery = useMemo(() => OrderData.isDeliveryOrder(orderData.receive_type), [orderData]);
  return (
    <Container>
      <ReceiveDateTimeInfo>
        {isDelivery ? (
          <DriverInfo>
            <Label>配達員：</Label>
            <DriverName>
              {OrderData.existsDriver(orderData.driver_name) ? orderData.driver_name : "-"}
            </DriverName>
          </DriverInfo>
        ) : undefined}
        <ArrivalInfo>
          {isDelivery && orderData.is_near ? (
            "まもなく到着します"
          ) : (
            <ArrivalAt>
              <Label>到着予定：</Label>
              <ReceiveProgress orderData={orderData} />
            </ArrivalAt>
          )}
        </ArrivalInfo>
      </ReceiveDateTimeInfo>
    </Container>
  );
});
