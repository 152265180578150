import React, { useContext } from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Text } from "components/atoms/Text";
import { IconMenuPass } from "components/atoms/Icons/IconMenuPass";
import { ReceiveDateTime } from "components/molecules/ReceiveDateTime";
import { OrderNumberContainer } from "components/molecules/OrderNumberContainer";
import { OrderPrice } from "components/molecules/OrderPrice";
import { PrintReceiptButton } from "./PrintReceiptButton";
import { OrderDetailContext } from "./OrderDetailContext";

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
`;

const OrderInfoContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 0.5em;
  padding-right: 0.5em;
  padding-left: 0.5em;
  border-left: 1px solid ${colorsConst.BORDER.THIN};
`;

const OrderAddedInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const OrderCountContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 1em;
`;

const NewLabel = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 0.5em;
  padding: 0.25em;
  ${fontSize.XSMALL};
  line-height: 1;
  color: ${colorsConst.TEXT.WHITE};
  background-color: ${colorsConst.BACKGROUND.DANGER};
`;

const OrderCount = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const SupportPriceInfo = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const UserNameContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const IconContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0.25em;
  svg {
    width: 1.5em;
    height: 1.5em;
  }
`;

const PrintReceiptButtonContainer = styled.div``;

type Props = {};

export const OrderDetailInfo: React.FC<Props> = React.memo(() => {
  const { isHistory, orderData, submitting, canDisplayPrintReceiptButton } =
    useContext(OrderDetailContext);
  return (
    <Container>
      <ReceiveDateTime orderData={orderData} />
      <OrderInfoContainer>
        <OrderNumberContainer orderData={orderData} />
        {!isHistory ? (
          <OrderAddedInfoContainer>
            {typeof orderData.user_order_count !== "undefined" ? (
              <OrderCountContainer>
                {orderData.user_order_count === 0 ? <NewLabel>NEW</NewLabel> : undefined}
                <OrderCount>
                  <Text size="LARGE" weight="BOLD">
                    {orderData.user_order_count + 1}
                  </Text>
                  <Text color="SECONDARY" size="SMALL">
                    回目
                  </Text>
                </OrderCount>
              </OrderCountContainer>
            ) : undefined}
            {OrderData.existsShopSupportPrice(orderData.shop_support_price) ? (
              <SupportPriceInfo>
                <Text size="SMALL">応援金あり</Text>
              </SupportPriceInfo>
            ) : undefined}
          </OrderAddedInfoContainer>
        ) : undefined}
        <UserNameContainer>
          <Text size="SMALL">{`${orderData.user_info.nick_name}さん`}</Text>
          {orderData.user_info.is_menu_pass_member ? (
            <IconContainer>
              <IconMenuPass />
            </IconContainer>
          ) : undefined}
        </UserNameContainer>
        {isHistory ? <OrderPrice orderData={orderData} /> : undefined}
      </OrderInfoContainer>
      {canDisplayPrintReceiptButton ? (
        <PrintReceiptButtonContainer>
          <PrintReceiptButton orderData={orderData} submitting={submitting} />
        </PrintReceiptButtonContainer>
      ) : undefined}
    </Container>
  );
});
