import React from "react";
import { Button, ButtonInner, ButtonMainText, ButtonSubText } from "components/atoms/Button";

type Props = {
  submitting?: boolean;
  disabled: boolean;
  handleClick?: () => void;
};

export const FindCrewForLocalAreaButton: React.FC<Props> = React.memo(
  ({ submitting, disabled, handleClick }) => (
    <Button appearance="primary" handleClick={handleClick} loading={submitting} disabled={disabled}>
      <ButtonInner>
        <ButtonMainText>配達員を探す</ButtonMainText>
        <ButtonSubText>Find the best Crew</ButtonSubText>
      </ButtonInner>
    </Button>
  ),
);
