import React, { useMemo, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Field } from "react-final-form";
import styled from "styled-components";
import { API_KEY } from "apis";
import {
  LoginShopForm,
  initialLoginShopForm,
  loginShopFormValidationSchema,
} from "forms/loginShop";
import { useValidationSchema } from "forms/utils/useValidationSchema";
import { userSubmittedLoginShopForm } from "modules/app/actions";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { Contents } from "components/atoms/Contents";
import { Button } from "components/atoms/Button";
import { TextField } from "components/atoms/FormFields/TextField";
import { Article } from "components/organisms/Article";

const Container = styled.div``;

const Label = styled.div`
  margin-bottom: 0.5em;
`;

const FieldContainer = styled.div`
  & + & {
    margin-top: 2em;
  }
`;

const ButtonContainer = styled.div`
  margin-top: ${(100 * 60) / 768}%;
  & + & {
    margin-top: ${(100 * 40) / 768}%;
  }
`;

const isConnectedApiSelector = isConnectedApiSelectorFactory([API_KEY.LOGIN_SHOP]);

type Props = {};

export const LoginTemplate: React.FC<Props> = React.memo(() => {
  const validate = useValidationSchema(loginShopFormValidationSchema);
  const loading = useSelector(isConnectedApiSelector);

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values: LoginShopForm) => {
      dispatch(userSubmittedLoginShopForm(values));
    },
    [dispatch],
  );

  return (
    <Article withHeader title="ログイン" goBack="/">
      <Contents>
        <Form onSubmit={handleSubmit} initialValues={initialLoginShopForm} validate={validate}>
          {({ handleSubmit, valid, submitting }) => (
            <form onSubmit={handleSubmit}>
              <FieldContainer>
                <Label>ログインID</Label>
                <Field
                  name="login_id"
                  type="text"
                  placeholder="ログインIDを入力"
                  render={props => <TextField {...props} />}
                />
              </FieldContainer>
              <FieldContainer>
                <Label>パスワード</Label>
                <Field
                  name="password"
                  type="password"
                  placeholder="パスワードを入力"
                  render={props => <TextField {...props} />}
                />
              </FieldContainer>
              <ButtonContainer>
                <Button
                  appearance="primary"
                  type="submit"
                  disabled={!valid}
                  loading={submitting || loading}
                >
                  送信
                </Button>
              </ButtonContainer>
            </form>
          )}
        </Form>
      </Contents>
    </Article>
  );
});
