import { replace } from "connected-react-router";
import { put, fork, call, select } from "redux-saga/effects";
import { API_KEY, GetOrderListRequest, GetOrderListResponse } from "apis";
import { LocalStorage } from "utils/LocalStorage";
import { ShopStaff, STAFF_ROLE_TYPE } from "records/ShopStaff";
import { OrderData, ORDER_PAYMENT_TYPE } from "records/OrderData";
import { AppModel, DEVICE_TOKEN_KEY } from "modules/app/model";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { shopStaffSelector, isGrocerySelector } from "modules/shop/selectors";
import { updateOrderList } from "../actions";
import { OrderModel } from "../model";

export function* userAccessedToPageThatNeedsOrderListSaga() {
  try {
    const params: GetOrderListRequest = {
      device_token: LocalStorage.getItemOrDefault(DEVICE_TOKEN_KEY, ""),
    };
    const { result, error }: { result: GetOrderListResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.GET_ORDER_LIST,
      params,
    );
    if (result && !error) {
      AppModel.removePrintCount(result.order_list);
      yield put(updateOrderList(result.order_list));
      const userData: ShopStaff = yield select(shopStaffSelector);
      const isGrocery: boolean = yield select(isGrocerySelector);
      if (isGrocery && userData.role === STAFF_ROLE_TYPE.PICKER) {
        const ownAssignedOrderList = OrderModel.getOwnAssignedOrderList(
          result.order_list,
          userData,
        );
        if (typeof ownAssignedOrderList !== "undefined") {
          yield put(replace(`/orderDetail/${ownAssignedOrderList.order_no}`));
        }
      }
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
