import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import styled from "styled-components";
import { unreadNewsFlagSelector } from "modules/information/selectors";
import { IconMegaphone } from "components/atoms/Icons/IconMegaphone";
import { fontSize } from "styles/mixins";
import { colorsConst } from "styles/const";

const Container = styled.div`
  position: relative;
`;

const Unread = styled.div`
  position: absolute;
  top: -0.25em;
  right: -0.5em;
  width: 1em;
  height: 1em;
  border: 3px solid ${colorsConst.BORDER.WHITE};
  border-radius: 100%;
  background-color: ${colorsConst.BACKGROUND.DANGER};
`;

const IconContainer = styled.div`
  ${fontSize.XXXLARGE};
`;

type Props = {};

export const InformationButton: React.FC<Props> = React.memo(() => {
  const unreadNewsFlag = useSelector(unreadNewsFlagSelector);
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(push("/information"));
  }, [dispatch]);
  return (
    <Container onClick={handleClick}>
      {unreadNewsFlag ? <Unread /> : undefined}
      <IconContainer>
        <IconMegaphone />
      </IconContainer>
    </Container>
  );
});
