/* eslint-disable default-param-last */
import { ActionTypes, AppActions } from "./actions";
import { AppState, AppModel } from "./model";

export default function reducer(state: AppState = AppModel.initialState, action: AppActions) {
  switch (action.type) {
    case ActionTypes.R_SET_LAUNCHED:
      return AppModel.setLaunched(state);
    case ActionTypes.R_UPDATE_CONNECTING_API_SET:
      return action.payload.add
        ? AppModel.startConnect(state, action.payload.apiKey)
        : AppModel.finishConnect(state, action.payload.apiKey);
    case ActionTypes.R_UPDATE_PROCESSING_FLAG_SET:
      return AppModel.updateProcessingFlagSet(state, action.payload.flagSet);
    case ActionTypes.R_UPDATE_LOGIN_TOKEN:
      return AppModel.updateLoginToken(state, action.payload.token);
    case ActionTypes.R_INIT_LOGIN_TOKEN:
      return AppModel.initLoginToken(state);
    case ActionTypes.R_SET_APP_VERSION:
      return AppModel.setAppVersion(state, action.payload.value);
    case ActionTypes.R_UPDATE_SERVER_DATE_TIME:
      return AppModel.updateServerDataTime(state, action.payload.serverDateTime);
    case ActionTypes.R_OPEN_MODAL:
      return AppModel.addModal(state, action.payload.key, action.payload.payload);
    case ActionTypes.R_CLOSE_MODAL:
      return AppModel.removeModal(state, action.payload.key);
    case ActionTypes.R_UPDATE_MODAL:
      return AppModel.updateModal(state, action.payload.key, action.payload.payload);
    case ActionTypes.R_INIT_SNACK_BAR:
      return AppModel.initSnackBar(state);
    case ActionTypes.R_UPDATE_SNACK_BAR:
      return AppModel.updateSnackBar(state, action.payload.text, action.payload.type);
    case ActionTypes.R_SET_SHOULD_UPDATE_RESOURCE_FLAG:
      return AppModel.setShouldUpdateResource(state);
    case ActionTypes.R_UPDATE_DISCOVERED_PRINTER:
      return AppModel.updateDiscoveredPrinter(state, action.payload.value);
    case ActionTypes.R_UPDATE_CONNECTED_PRINTER:
      return AppModel.updateConnectedPrinter(state, action.payload.value);
    case ActionTypes.R_SET_PRINTER_STATUS:
      return AppModel.setPrinterStatus(state, action.payload.status);
    case ActionTypes.R_SET_ENEBLE_BLUETOOTH:
      return AppModel.setEnebleBluetooth(state, action.payload.bool);
    default:
      return state;
  }
}
