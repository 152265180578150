import { put, fork, call, all } from "redux-saga/effects";
import { API_KEY, StopOrderByItemRequest, StopOrderByItemResponse } from "apis";
import { systemClosedModal, updateSnackBar } from "modules/app/actions";
import { commonApiSaga, commonErrorSaga } from "modules/app/sagas";
import { systemUpdateShopItemStatus, UserSubmittedStopOrderByStockCheckAction } from "../actions";
import { SHOP_ITEM_SUBMIT_TYPE } from "../model";

export function* userSubmittedStopOrderByStockCheckSaga(
  action: UserSubmittedStopOrderByStockCheckAction,
) {
  try {
    const { shopItemIds, callback } = action.payload;
    const params: StopOrderByItemRequest = { shop_item_ids: shopItemIds, use_stock_check: true };
    const {
      result,
      error,
    }: {
      result: StopOrderByItemResponse;
      error: unknown;
    } = yield call(commonApiSaga, API_KEY.STOP_ORDER_BY_ITEM, params);
    if (result && !error) {
      yield all([
        put(systemClosedModal("CONFIRM_STOCK_CHECK")),
        put(systemUpdateShopItemStatus(shopItemIds, SHOP_ITEM_SUBMIT_TYPE.STOP)),
        put(updateSnackBar("商品の受付を停止しました")),
      ]);
      if (typeof callback !== "undefined") {
        callback();
      }
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
