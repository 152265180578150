import { fork, takeEvery, takeLatest, spawn } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import {
  userAccessedToPageThatNeedsShopDataSaga,
  userAccessedToPageThatNeedsShopItemsSaga,
  userAccessedToPageThatNeedsShopItemOptionsSaga,
  userAccessedToPageThatNeedsShopItemParentCategoriesSaga,
  userAccessedToPageThatNeedsShopBusinessSaga,
  userAccessedToPageThatNeedsShopInvitationCodeSaga,
  userSubmittedUpdateBusinessTimeSaga,
  userSubmittedUpdateBusinessBusyTimeSaga,
  userSubmittedRegisterBusinessHolidaySaga,
  userSubmittedDeleteBusinessHolidaySaga,
  userSubmittedUpdateBusinessNationalHolidaySaga,
  userSubmittedStopOrderByShopSaga,
  userSubmittedStopOrderByItemsSaga,
  userSubmittedRestartOrderByShopSaga,
  userSubmittedRestartOrderByOptionSetsSaga,
  userSubmittedRestartOrderByItemsSaga,
  userSubmittedStopReleaseByItemsSaga,
  userSubmittedStopOrderByOptionSetsSaga,
  userSubmittedStopReleaseByOptionSetsSaga,
  userSubmittedSortShopItemParentCategorySaga,
  userSubmittedStopOrderByFilteredOptionsSaga,
  userSubmittedStopReleaseByFilteredOptionsSaga,
  userSubmittedStopOrderByStockCheckSaga,
  userSubmittedRestartOrderByStockCheckSaga,
  userSubmittedAutoPrintSettingSaga,
  userSubmittedStopFastestOrderSaga,
  userSubmittedUpdateDisplayImagesSaga,
  userSubmittedUpdateShopStoppedItemReasonsSaga,
  userSubmittedPushNotificationSettingSaga,
  systemUpdateShopItemStatusSaga,
  systemUpdateShopItemOptionStatusSaga,
  systemUpdateShopItemSelectStopReasonTypeSaga,
  systemWatchShopNotificationSaga,
} from "./sagas";

export function* shopSaga() {
  yield fork(watchUserAccessedToPageThatNeedsShopDataSaga);
  yield fork(watchUserAccessedToPageThatNeedsShopItemsSaga);
  yield fork(watchUserAccessedToPageThatNeedsShopItemOptionsSaga);
  yield fork(watchUserAccessedToPageThatNeedsShopItemParentCategoriesSaga);
  yield fork(watchUserAccessedToPageThatNeedsShopBusinessSaga);
  yield fork(watchUserAccessedToPageThatNeedsShopInvitationCodeSaga);
  yield fork(watchUserSubmittedUpdateBusinessTimeSaga);
  yield fork(watchUserSubmittedUpdateBusinessBusyTimeSaga);
  yield fork(watchUserSubmittedRegisterBusinessHolidaySaga);
  yield fork(watchUserSubmittedDeleteBusinessHolidaySaga);
  yield fork(watchUserSubmittedUpdateBusinessNationalHolidaySaga);
  yield fork(watchUserSubmittedStopOrderByShopSaga);
  yield fork(watchUserSubmittedRestartOrderByShopSaga);
  yield fork(watchUserSubmittedRestartOrderByOptionSetsSaga);
  yield fork(watchUserSubmittedStopOrderByItemsSaga);
  yield fork(watchUserSubmittedRestartOrderByItemsSaga);
  yield fork(watchUserSubmittedStopReleaseByItemsSaga);
  yield fork(watchUserSubmittedStopOrderByOptionSetsSaga);
  yield fork(watchUserSubmittedStopReleaseByOptionSetsSaga);
  yield fork(watchUserSubmittedSortShopItemParentCategorySaga);
  yield fork(watchUserSubmittedStopOrderByFilteredOptionsSaga);
  yield fork(watchUserSubmittedStopReleaseByFilteredOptionsSaga);
  yield fork(watchUserSubmittedStopOrderByStockCheckSaga);
  yield fork(watchUserSubmittedRestartOrderByStockCheckSaga);
  yield fork(watchUserSubmittedAutoPrintSettingSaga);
  yield fork(watchUserSubmittedStopFastestOrderSaga);
  yield fork(watchUserSubmittedUpdateDisplayImagesSaga);
  yield fork(watchUserSubmittedUpdateShopItemStoppedReasonsSaga);
  yield fork(watchUserSubmittedPushNotificationSettingSaga);

  yield fork(watchSystemUpdateShopItemStatusSaga);
  yield fork(watchSystemUpdateShopItemOptionStatusSaga);
  yield fork(watchSystemUpdateShopItemSelectStopReasonType);
  yield fork(watchSystemWatchShopNotificationSaga);
}

function* watchUserAccessedToPageThatNeedsShopDataSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_DATA,
    userAccessedToPageThatNeedsShopDataSaga,
  );
}

function* watchUserAccessedToPageThatNeedsShopItemsSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_ITEMS,
    userAccessedToPageThatNeedsShopItemsSaga,
  );
}

function* watchUserAccessedToPageThatNeedsShopItemOptionsSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_OPTIONS,
    userAccessedToPageThatNeedsShopItemOptionsSaga,
  );
}

function* watchUserAccessedToPageThatNeedsShopItemParentCategoriesSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_ITEM_PARENT_CATEGORIES,
    userAccessedToPageThatNeedsShopItemParentCategoriesSaga,
  );
}

function* watchUserAccessedToPageThatNeedsShopBusinessSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_BUSINESS,
    userAccessedToPageThatNeedsShopBusinessSaga,
  );
}

function* watchUserAccessedToPageThatNeedsShopInvitationCodeSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_INVITATION_CODE,
    userAccessedToPageThatNeedsShopInvitationCodeSaga,
  );
}

function* watchUserSubmittedUpdateBusinessTimeSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_UPDATE_BUSINESS_TIME,
    userSubmittedUpdateBusinessTimeSaga,
  );
}

function* watchUserSubmittedUpdateBusinessBusyTimeSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_UPDATE_BUSINESS_BUSY_TIME,
    userSubmittedUpdateBusinessBusyTimeSaga,
  );
}

function* watchUserSubmittedRegisterBusinessHolidaySaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_REGISTER_BUSINESS_HOLIDAY,
    userSubmittedRegisterBusinessHolidaySaga,
  );
}

function* watchUserSubmittedDeleteBusinessHolidaySaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_DELETE_BUSINESS_HOLIDAY,
    userSubmittedDeleteBusinessHolidaySaga,
  );
}

function* watchUserSubmittedUpdateBusinessNationalHolidaySaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_UPDATE_BUSINESS_NATIONAL_HOLIDAY,
    userSubmittedUpdateBusinessNationalHolidaySaga,
  );
}

function* watchUserSubmittedStopOrderByShopSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_STOP_ORDER_BY_SHOP, userSubmittedStopOrderByShopSaga);
}

function* watchUserSubmittedRestartOrderByShopSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_RESTART_ORDER_BY_SHOP,
    userSubmittedRestartOrderByShopSaga,
  );
}

function* watchUserSubmittedRestartOrderByOptionSetsSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_RESTART_ORDER_BY_OPTION_SETS,
    userSubmittedRestartOrderByOptionSetsSaga,
  );
}

function* watchUserSubmittedStopOrderByItemsSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_STOP_ORDER_BY_ITEMS, userSubmittedStopOrderByItemsSaga);
}

function* watchUserSubmittedRestartOrderByItemsSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_RESTART_ORDER_BY_ITEMS,
    userSubmittedRestartOrderByItemsSaga,
  );
}

function* watchUserSubmittedStopReleaseByItemsSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_STOP_RELEASE_BY_ITEMS,
    userSubmittedStopReleaseByItemsSaga,
  );
}
function* watchUserSubmittedStopOrderByOptionSetsSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_STOP_ORDER_BY_OPTION_SETS,
    userSubmittedStopOrderByOptionSetsSaga,
  );
}

function* watchUserSubmittedStopReleaseByOptionSetsSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_STOP_RELEASE_BY_OPTION_SETS,
    userSubmittedStopReleaseByOptionSetsSaga,
  );
}

function* watchUserSubmittedSortShopItemParentCategorySaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_SORT_SHOP_ITEM_PARENT_CATEGORY,
    userSubmittedSortShopItemParentCategorySaga,
  );
}

function* watchUserSubmittedStopOrderByFilteredOptionsSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_STOP_ORDER_BY_FILTERED_OPTIONS,
    userSubmittedStopOrderByFilteredOptionsSaga,
  );
}

function* watchUserSubmittedStopReleaseByFilteredOptionsSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_STOP_RELEASE_BY_FILTERED_OPTIONS,
    userSubmittedStopReleaseByFilteredOptionsSaga,
  );
}

function* watchUserSubmittedStopOrderByStockCheckSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_STOP_ORDER_BY_STOCK_CHECK,
    userSubmittedStopOrderByStockCheckSaga,
  );
}

function* watchUserSubmittedRestartOrderByStockCheckSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_RESTART_ORDER_BY_STOCK_CHECK,
    userSubmittedRestartOrderByStockCheckSaga,
  );
}

function* watchUserSubmittedAutoPrintSettingSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_AUTO_PRINT_SETTING, userSubmittedAutoPrintSettingSaga);
}

function* watchUserSubmittedStopFastestOrderSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_STOP_FASTEST_ORDER, userSubmittedStopFastestOrderSaga);
}

function* watchSystemWatchShopNotificationSaga() {
  yield takeLatest(ActionTypes.S_WATCH_SHOP_NOTIFICATION, systemWatchShopNotificationSaga);
}

function* watchUserSubmittedUpdateDisplayImagesSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_UPDATE_DISPLAY_IMAGES,
    userSubmittedUpdateDisplayImagesSaga,
  );
}

function* watchUserSubmittedUpdateShopItemStoppedReasonsSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_UPDATE_SHOP_ITEMS_STOPPED_REASONS,
    userSubmittedUpdateShopStoppedItemReasonsSaga,
  );
}

function* watchUserSubmittedPushNotificationSettingSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_PUSH_NOTIFICATION_SETTING,
    userSubmittedPushNotificationSettingSaga,
  );
}

function* watchSystemUpdateShopItemStatusSaga() {
  yield takeLatest(ActionTypes.S_UPDATE_SHOP_ITEM_STATUS, systemUpdateShopItemStatusSaga);
}

function* watchSystemUpdateShopItemSelectStopReasonType() {
  yield takeLatest(
    ActionTypes.S_UPDATE_SHOP_ITEM_SELECT_STOP_REASON_TYPE,
    systemUpdateShopItemSelectStopReasonTypeSaga,
  );
}

function* watchSystemUpdateShopItemOptionStatusSaga() {
  yield takeLatest(
    ActionTypes.S_UPDATE_SHOP_ITEM_OPTION_STATUS,
    systemUpdateShopItemOptionStatusSaga,
  );
}
