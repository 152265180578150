/* eslint-disable no-alert */
import React, { useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OrderData } from "records/OrderData";
import { ShopStaff } from "records/ShopStaff";
import { userSubmittedPrintReceipt } from "modules/app/actions";
import { printerSelector, isPrinterProcessingSelectorFactory } from "modules/app/selectors";
import { AppModel } from "modules/app/model";
import { autoPrintSettingSelector } from "modules/shop/selectors";
import { Utility } from "utils/Utility";
import { DateUtils } from "utils/DateUtils";
import { PrintUtils } from "utils/PrintUtils";
import { Contents } from "components/atoms/Contents";
import { Button } from "components/atoms/Button";
import { NoData } from "components/atoms/NoData";
import { Article } from "components/organisms/Article";

const isPrinterProcessingSelector = isPrinterProcessingSelectorFactory(["connecting", "printing"]);

export const PrinterTestTemplate: React.FC = React.memo(() => {
  const { discoveredPrinter, isDiscoveredPrinter } = useSelector(printerSelector);
  const isPrinterProcessing = useSelector(isPrinterProcessingSelector);

  const canPrintReceipt = useMemo(
    () => isDiscoveredPrinter && !isPrinterProcessing,
    [isDiscoveredPrinter, isPrinterProcessing],
  );

  const emulator = useMemo(
    () => PrintUtils.getEmulatorByModelName(discoveredPrinter.modelName),
    [discoveredPrinter],
  );

  const { font_size } = useSelector(autoPrintSettingSelector);

  const order = useMemo(() => {
    const orderNo = Utility.generateSimulatorOrderNo();
    return OrderData.create({
      order_no: orderNo,
      display_order_no: orderNo,
      user_info: {
        nick_name: "\u7409\u7403\u30c8\u30e0\u30af\u30eb\u30fc\u30ba",
        tel: "09000000000",
        is_menu_pass_member: false,
      },
      time_type: 1,
      receive_datetime: DateUtils.dateToString(
        DateUtils.getDateWithAddMinutes(new Date(), 30),
        "YYYY-MM-DD HH:mm:ss",
      ),
      order_state: 4,
      discount_price: 0,
      shop_support_price: 0,
      total_price: 98980,
      user_payment_price: 980,
      item_list: [
        {
          shop_item_id: 4725,
          order_shop_item_id_group: 1,
          total_order_count: 1,
          order_count: 1,
          free_order_count: 0,
          unit_price: 980,
          image_path: "/image/16/\u30ac\u30d1\u30aa\u30e9\u30a4\u30b9.jpg",
          item_name:
            "\u30ac\u30d1\u30aa\u30e9\u30a4\u30b9\u30dc\u30c3\u30af\u30b9\u30e9\u30f3\u30c1",
          instruction_text: "テキストが入ります。テキストが入ります。テキストが入ります。",
          supplement_text: "",
          option_set_list: [],
          is_campaign: false,
          pick_count: 0,
          is_picked: false,
          is_sell_by_weight: false,
          unit: "",
          input_min_quantity: 0,
          input_max_quantity: 0,
          user_display_quantity: 0,
          shop_item_parent_category_id: 0,
          shop_item_parent_category_group_order: 0,
          is_substitute_specified: false,
          is_substitute_item: false,
          can_order_max_count: 0,
        },
        {
          shop_item_id: 4725,
          order_shop_item_id_group: 1,
          total_order_count: 100,
          order_count: 100,
          free_order_count: 0,
          unit_price: 980,
          image_path: "/image/16/\u30ac\u30d1\u30aa\u30e9\u30a4\u30b9.jpg",
          item_name: "AAAAABBBBBCCCCCDDDDDEEEEEFFFFFAAAAABBBBBCCCCCDDDDDEEEEEFFFFF",
          instruction_text: "",
          supplement_text: "",
          option_set_list: [],
          is_campaign: false,
          pick_count: 0,
          is_picked: false,
          is_sell_by_weight: false,
          unit: "",
          input_min_quantity: 0,
          input_max_quantity: 0,
          user_display_quantity: 0,
          shop_item_parent_category_id: 0,
          shop_item_parent_category_group_order: 0,
          is_substitute_specified: false,
          is_substitute_item: false,
          can_order_max_count: 0,
        },
      ],
      order_date: DateUtils.dateToString(new Date(), "YYYY-MM-DD HH:mm:ss"),
      is_today_receive: false,
      cooking_start_able_flag: true,
      payment_type: 1,
      payment_flag: true,
      receive_type: 2,
      local_area_flag: false,
      driver_search_expire_time: "2020-11-19 15:56:32",
      is_allowed_out_of_stock: false,
      is_combo: true,
      is_driver_accepted: false,
      driver_name: "",
      is_near: false,
      is_un_match_cancel: false,
      user_order_count: 0,
      cooking_start_datetime: "",
      staff: ShopStaff.create(),
    });
  }, []);

  const dispatch = useDispatch();

  const handlePrint = useCallback(() => {
    dispatch(userSubmittedPrintReceipt(order));
  }, [dispatch, order]);

  const handleShowPrintCount = useCallback(() => {
    alert(AppModel.getPrintCountObject());
  }, []);

  return (
    <Article
      withHeader
      title="プリンターテスト"
      goBack="/setting"
      withNavigation
      activePath="SETTING"
    >
      {isDiscoveredPrinter ? (
        <Contents>
          <Button appearance="primary" disabled={!canPrintReceipt} handleClick={handlePrint}>
            レシート印刷
          </Button>
          <div style={{ marginTop: "1em" }}>
            <Button handleClick={handleShowPrintCount}>印刷回数</Button>
          </div>
          <div style={{ marginTop: "1em" }}>
            <div>{`portName: ${discoveredPrinter.portName}`}</div>
            <div>{`modelName: ${discoveredPrinter.modelName}`}</div>
            <div>{`macAddress: ${discoveredPrinter.macAddress}`}</div>
            <div>{`rasterObject: ${JSON.stringify(
              PrintUtils.generatePrintRasterObject(order, emulator, font_size),
            )}`}</div>
          </div>
        </Contents>
      ) : (
        <NoData>プリンターがありません。</NoData>
      )}
    </Article>
  );
});
