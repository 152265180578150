import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconChartBar: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 34 32" width="1em" height="1em" {...props}>
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="2"
        d="M5,17h4c0.6,0,1,0.4,1,1v8c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1v-8C4,17.4,4.4,17,5,17z"
      />
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="2"
        d="M15,10h4c0.6,0,1,0.4,1,1v15c0,0.6-0.4,1-1,1h-4c-0.6,0-1-0.4-1-1V11C14,10.4,14.4,10,15,10z"
      />
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="2"
        d="M25,5h4c0.6,0,1,0.4,1,1v20c0,0.6-0.4,1-1,1h-4c-0.6,0-1-0.4-1-1V6C24,5.4,24.4,5,25,5z"
      />
    </svg>
  );
});
