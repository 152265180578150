import { BusinessWeekTime } from "./BusinessWeekTime";
import { BusinessBusyTime } from "./BusinessBusyTime";
import { BusinessNationalHoliday } from "./BusinessNationalHoliday";

export type ShopBusiness = Readonly<{
  business_week_time: BusinessWeekTime[];
  business_busy_time: BusinessBusyTime[];
  business_national_holiday: BusinessNationalHoliday;
  holiday_list: string[];
}>;

const initialState: ShopBusiness = {
  business_week_time: [],
  business_busy_time: [],
  business_national_holiday: BusinessNationalHoliday.create(),
  holiday_list: [],
};

const create = (args: Partial<ShopBusiness> = {}) => ({
  ...initialState,
  ...args,
  business_week_time: args.business_week_time
    ? args.business_week_time.map(i => BusinessWeekTime.create(i))
    : [],
  business_busy_time: args.business_busy_time
    ? args.business_busy_time.map(i => BusinessBusyTime.create(i))
    : [],
  business_national_holiday: args.business_national_holiday
    ? BusinessNationalHoliday.create(args.business_national_holiday)
    : BusinessNationalHoliday.create(),
});

const exists = (shopBusinessData: ShopBusiness) => shopBusinessData.business_week_time.length > 0;

export const ShopBusiness = Object.freeze({
  initialState,
  create,
  exists,
});
