import { fork, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import {
  API_KEY,
  FinishOrderRequest,
  FinishOrderResponse,
  UpdateOrderItemsCountResponse,
} from "apis";
import { OrderData, ORDER_PAYMENT_TYPE } from "records/OrderData";
import { systemOpenedModal, updateSnackBar } from "modules/app/actions";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import {
  updateOrderDetail,
  UserSubmittedFinishOrderForLocalAreaAndAutoApproveAction,
} from "../actions";
import { updateOrderDetailSaga } from "./updateOrderDetailSaga";
import { updateOrderItemsCountSaga } from "./updateOrderItemsCountSaga";

export function* userSubmittedFinishOrderForLocalAreaAndAutoApproveSaga(
  action: UserSubmittedFinishOrderForLocalAreaAndAutoApproveAction,
) {
  try {
    const { orderData, draftItems, isAllowedOutOfStock } = action.payload;
    if (isAllowedOutOfStock) {
      const updateOrderItemsCountResult: {
        result: UpdateOrderItemsCountResponse;
        error: unknown;
      } = yield call(updateOrderItemsCountSaga, orderData.order_no, draftItems);
      if (updateOrderItemsCountResult.error) {
        throw updateOrderItemsCountResult.error;
      }
    }
    const params: FinishOrderRequest = {
      order_no: orderData.order_no,
    };
    const { result, error }: { result: FinishOrderResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.FINISH_ORDER,
      params,
    );
    if (result && !error) {
      if (orderData.payment_type === ORDER_PAYMENT_TYPE.LOCAL) {
        yield put(systemOpenedModal("LOCAL_PAYMENT_NOTICE", {}));
        yield fork(updateOrderDetailSaga, orderData.order_no);
      } else {
        yield all([
          put(updateOrderDetail(OrderData.create())),
          put(replace("/orderList", { from: { pathname: `/orderDetail/${orderData.order_no}` } })),
          put(updateSnackBar("準備完了を連絡しました")),
        ]);
      }
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
