import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconFinished: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 48 48" width="1em" height="1em" fill="none" {...props}>
      <path
        d="M8.30365 13.7109H39.5912L40.8949 44.9985H7L8.30365 13.7109Z"
        stroke={fillColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.0096 13.0614V8C31.0096 6.89543 30.1142 6 29.0096 6H18.8867C17.7821 6 16.8867 6.89543 16.8867 8V13.0614"
        stroke={fillColor}
        strokeWidth="3"
      />
    </svg>
  );
});
