import { fork, call, put } from "redux-saga/effects";
import { API_KEY, RegisterBusinessHolidayRequest, RegisterBusinessHolidayResponse } from "apis";
import { updateSnackBar } from "modules/app/actions";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { DateUtils } from "utils/DateUtils";
import { updateShopBusiness, UserSubmittedRegisterBusinessHolidayAction } from "../actions";

export function* userSubmittedRegisterBusinessHolidaySaga(
  action: UserSubmittedRegisterBusinessHolidayAction,
) {
  try {
    const params: RegisterBusinessHolidayRequest = {
      date: DateUtils.dateToString(action.payload.value, "YYYY-MM-DD"),
    };
    const { result, error }: { result: RegisterBusinessHolidayResponse; error: unknown } =
      yield call(commonApiSaga, API_KEY.REGISTER_BUSINESS_HOLIDAY, params);
    if (result && !error) {
      yield put(updateShopBusiness(result.shop_business));
      yield put(updateSnackBar("休業日を設定しました"));
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
