import React from "react";
import styled from "styled-components";

type Props = {
  width: number;
  height: number;
  imagePath: string;
};

const ThumbnailImg = styled.div<{ src: string; width: number; height: number }>`
  background: url(${p => p.src});
  background-size: cover;
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  background-position: 50% 50%;
`;

export const Thumbnail: React.FC<Props> = React.memo(({ width, height, imagePath }) => (
  <ThumbnailImg width={width} height={height} src={imagePath} />
));
