import { fork } from "redux-saga/effects";

import { appSaga } from "modules/app/saga";
import { shopSaga } from "modules/shop/saga";
import { orderSaga } from "modules/order/saga";
import { salesSaga } from "modules/sales/saga";
import { informationSaga } from "modules/information/saga";
import { simulatorSaga } from "modules/simulator/saga";

export function* rootSaga() {
  yield fork(appSaga);
  yield fork(shopSaga);
  yield fork(orderSaga);
  yield fork(salesSaga);
  yield fork(informationSaga);
  yield fork(simulatorSaga);
}
