import React, { useMemo } from "react";
import styled from "styled-components";
import { colorsConst } from "styles/const";

const Container = styled.div`
  margin-top: 0.25em;
  color: ${colorsConst.TEXT.DANGER};
`;

type Props = {
  index: number;
  error?: {
    business_times?: { [key: number]: string }[] | { start_time: string; end_time: string }[];
  }[];
};

export const BusinessTimeFieldError: React.FC<Props> = React.memo(({ index, error }) => {
  const ErrorText = useMemo(() => {
    if (
      typeof error === "undefined" ||
      typeof error[index] === "undefined" ||
      typeof error[index].business_times === "undefined"
    ) {
      return undefined;
    }
    const targetError = error[index].business_times?.find(
      e => typeof e !== "undefined" && Object.keys(e).some(key => !Number.isNaN(parseInt(key, 10))),
    );
    if (typeof targetError !== "undefined") {
      return Object.values(targetError).join("");
    }
    return undefined;
  }, [index, error]);
  return typeof ErrorText !== "undefined" ? <Container>{ErrorText}</Container> : null;
});
