import React from "react";
import { FilteredShopItemsByOptionSetDialog } from "./FilteredShopItemsByOptionSetDialog";
import { ConfirmOrderRestartByItemDialog } from "./ConfirmOrderRestartByItemDialog";
import { ConfirmBulkUpdateOptionStatusDialog } from "./ConfirmBulkUpdateOptionStatusDialog";

type Props = {};

export const ShopItemsModal: React.FC<Props> = React.memo(() => (
  <>
    <FilteredShopItemsByOptionSetDialog />
    <ConfirmOrderRestartByItemDialog />
    <ConfirmBulkUpdateOptionStatusDialog />
  </>
));
