import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API_KEY } from "apis";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { SetPushNotificationType, SET_PUSH_NOTIFICATION_TYPE } from "modules/shop/model";
import { shopDataSelector } from "modules/shop/selectors";
import { Contents } from "components/atoms/Contents";
import { Text } from "components/atoms/Text";
import { Article } from "components/organisms/Article";
import { ToggleButton } from "components/atoms/ToggleButton";
import {
  InputSettingListItem,
  InputSettingListItemInner,
  TextContainer,
  InputContainer,
} from "components/organisms/InputSettingListItem";
import { userSubmittedPushNotificationSetting } from "modules/shop/actions";

const submittingUpdateOrderSelector = isConnectedApiSelectorFactory([
  API_KEY.SET_PUSH_NOTIFICATION,
]);

export const PushNotificationSettingTemplate: React.FC = React.memo(() => {
  const shopData = useSelector(shopDataSelector);
  const submitting = useSelector(submittingUpdateOrderSelector);
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (notificationType: SetPushNotificationType, enable: boolean) => {
      dispatch(userSubmittedPushNotificationSetting(notificationType, enable));
    },
    [dispatch],
  );

  return (
    <Article withHeader title="通知設定" goBack="/setting" withNavigation activePath="SETTING">
      <Contents>
        <InputSettingListItem>
          <InputSettingListItemInner>
            <TextContainer>
              <Text color="SECONDARY" size="SMALL" weight="MEDIUM">
                配達員が到着する前の通知が届きます
              </Text>
            </TextContainer>
            <InputContainer>
              <ToggleButton
                active={shopData.is_crew_about_to_arrive_notification}
                handleClick={() =>
                  handleClick(
                    SET_PUSH_NOTIFICATION_TYPE.BEFORE_ARRIVE,
                    !shopData.is_crew_about_to_arrive_notification,
                  )
                }
                disabled={submitting}
              />
            </InputContainer>
          </InputSettingListItemInner>
        </InputSettingListItem>

        <InputSettingListItem>
          <InputSettingListItemInner>
            <TextContainer>
              <Text color="SECONDARY" size="SMALL" weight="MEDIUM">
                配達員が到着した時の通知が届きます
              </Text>
            </TextContainer>
            <InputContainer>
              <ToggleButton
                active={shopData.is_crew_arrived_notification}
                handleClick={() =>
                  handleClick(
                    SET_PUSH_NOTIFICATION_TYPE.ARRIVED,
                    !shopData.is_crew_arrived_notification,
                  )
                }
                disabled={submitting}
              />
            </InputContainer>
          </InputSettingListItemInner>
        </InputSettingListItem>
      </Contents>
    </Article>
  );
});
