import React, { useMemo } from "react";
import styled from "styled-components";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { Utility } from "utils/Utility";
import { ModalDialog, ModalDialogHeader, ModalBody } from "components/molecules/ModalDialog";

const contentStyle: React.CSSProperties = {
  width: "45%",
} as const;

const innerStyle: React.CSSProperties = {
  padding: 0,
} as const;

const ItemImg = styled.img.attrs({ alt: "" })`
  width: 100%;
  height: auto;
`;

const MODAL_KEY = "ORDER_ITEM_IMAGE";
const useModal = modalHooksFactory(MODAL_KEY);

type Props = {};

export const OrderItemImageDialog: React.FC<Props> = React.memo(() => {
  const defaultImageSize = 512;

  const { data, close } = useModal();

  const imagePath = useMemo(
    () => ("payload" in data && "imagePath" in data.payload ? data.payload.imagePath : ""),
    [data],
  );

  return (
    <ModalDialog
      isOpen={data.isOpen}
      close={close}
      contentStyle={contentStyle}
      innerStyle={innerStyle}
      handleClickBackdrop={close}
    >
      <ModalDialogHeader handleClose={close}>商品画像</ModalDialogHeader>
      <ModalBody style={{ padding: 0 }}>
        <ItemImg src={Utility.getCdnImagePath(imagePath, defaultImageSize)} />
      </ModalBody>
    </ModalDialog>
  );
});
