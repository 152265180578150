import { Printer } from "records/Printer";
import { ReduxModel } from "../../../reducer";

export function printerSelector(state: ReduxModel) {
  const { discoveredPrinter, isConnectedPrinter, printerStatus } = state.app;
  return {
    discoveredPrinter,
    printerStatus,
    isDiscoveredPrinter: Printer.isDiscovered(discoveredPrinter),
    isConnectedPrinter,
  };
}
