import React from "react";
import { OrderOptionSet } from "records/OrderOptionSet";
import { Text } from "components/atoms/Text";

type Props = {
  optionSet: OrderOptionSet;
  isWarning: boolean;
};

export const OrderItemOptionSet: React.FC<Props> = React.memo(({ optionSet, isWarning }) => (
  <div>
    <div>
      <Text size="SMALL" color={isWarning ? "WHITE" : "SECONDARY"}>
        {optionSet.option_set_name}
      </Text>
    </div>
    {optionSet.option_list.map((option, index) => (
      <div key={index}>
        <Text>{`・${option.option_name}`}</Text>
      </div>
    ))}
  </div>
));
