import React, { useMemo, useContext } from "react";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Text } from "components/atoms/Text";
import { OrderItemData } from "records/OrderItemData";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  padding: 0.25em;
  background-color: ${colorsConst.BACKGROUND.SECONDARY};
`;

const Label = styled.div`
  margin-right: 0.5em;
`;

type Props = {
  item: OrderItemData;
};

export const OrderDetailItemWeightRange: React.FC<Props> = React.memo(({ item }) => (
  <Container>
    <Label>
      <Text size="SMALL" color="SECONDARY">
        入力可能量：
      </Text>
    </Label>
    <div>
      <Text color="PRIMARY" weight="BOLD">{`${item.input_min_quantity}〜${
        item.input_max_quantity
      }${OrderItemData.getPresentationUnitString(item.unit)}`}</Text>
    </div>
  </Container>
));
