import React, { useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import { shopDataSelector } from "modules/shop/selectors";
import { ShopItem, ReasonForItemsStop, REASON_FOR_ITEMS_STOP_VIEW } from "records/ShopItem";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Text } from "components/atoms/Text";
import { Thumbnail } from "components/atoms/Thumbnail";
import { Utility } from "utils/Utility";
import { ShopData } from "records/ShopData";
import { userSubmittedUpdateShopItemStoppedReasons } from "modules/shop/actions";
import { ReasonForItemsStopSelectField } from "components/organisms/OrderStopDialog/ReasonForItemsStopSelectField";

const ThumbnailSize = 56;

const Container = styled.div`
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
  & + & {
    border-top: 1px solid ${colorsConst.BORDER.THIN};
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 1.25em;
`;

const ThumbnailContainer = styled.div`
  margin-right: 1em;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  width: 3em;
  height: 3em;
`;

const ItemNameContainer = styled.div``;

const ClickableText = styled.span`
  text-decoration: underline;
`;

const IconContainer = styled.div`
  display: inline-block;
  margin-left: 0.5em;
  ${fontSize.LARGE};
  color: ${colorsConst.TEXT.LINK};
`;

const SelectContainer = styled.div`
  margin: 0.5em 0 0.5em 0;
`;

type Props = {
  item: ShopItem;
  selectedItemIds: number[];
  handleClick: (imagePath: string) => void;
  handleCheck: (itemId: number, checked: boolean) => void;
};

export const ShopItemForStockCheck: React.FC<Props> = React.memo(
  ({ item, selectedItemIds, handleClick, handleCheck }) => {
    const dispatch = useDispatch();
    const shopData = useSelector(shopDataSelector);
    const isChecked = useMemo(
      () => selectedItemIds.includes(item.shop_item_id),
      [item, selectedItemIds],
    );

    const handleClickWrap = useCallback(() => {
      handleClick(item.image_path);
    }, [item, handleClick]);

    const handleCheckWrap = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        handleCheck(item.shop_item_id, event.target.checked);
      },
      [item, handleCheck],
    );

    const handleChangeSelectStopReasonShopItems = useCallback(
      (shopItemId: number, stopReasonType: ReasonForItemsStop) => {
        dispatch(
          userSubmittedUpdateShopItemStoppedReasons(
            [
              {
                shop_item_id: shopItemId,
                reason_type: stopReasonType,
              },
            ],
            REASON_FOR_ITEMS_STOP_VIEW.SHOP_ITEMS_STOCK_CHECK,
          ),
        );
      },
      [dispatch],
    );

    return (
      <Container key={item.shop_item_id}>
        <ItemContainer>
          <CheckboxContainer>
            <Checkbox onChange={handleCheckWrap} checked={isChecked} />
          </CheckboxContainer>
          {ShopData.isShowDisplayImages(shopData) && (
            <ThumbnailContainer onClick={handleClickWrap}>
              <Thumbnail
                width={ThumbnailSize}
                height={ThumbnailSize}
                imagePath={Utility.getCdnImagePath(item.image_path, ThumbnailSize)}
              />
            </ThumbnailContainer>
          )}
          <ItemNameContainer>
            <ClickableText onClick={handleClickWrap}>
              <Text size="LARGE" weight="BOLD">
                {item.item_name}
              </Text>
              <IconContainer>
                <FontAwesomeIcon icon={faImages} />
              </IconContainer>
            </ClickableText>
          </ItemNameContainer>
        </ItemContainer>
        {ShopItem.isSoldOut(item) && (
          <SelectContainer>
            <ReasonForItemsStopSelectField
              isSelected={true}
              handleChange={handleChangeSelectStopReasonShopItems}
              shopItemId={item.shop_item_id}
              defaultValue={item.sold_out_reason_type}
            />
          </SelectContainer>
        )}
      </Container>
    );
  },
);
