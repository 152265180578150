import styled from "styled-components";
import { listItemStyle } from "components/organisms/SettingListItem";

const InputSettingListItem = styled.div`
  ${listItemStyle};
`;

const InputSettingListItemInner = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const InputContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

export { InputSettingListItem, InputSettingListItemInner, TextContainer, InputContainer };
