import { put, call, all } from "redux-saga/effects";
import { CordovaUtil } from "utils/CordovaUtil";
import { LocalStorage } from "utils/LocalStorage";
import { setLaunched, updateLoginToken, setAppVersion, systemSearchPrinter } from "../actions";
import { AppModel } from "../model";
import { bluetoothAuthorizationSaga } from "./bluetoothAuthorizationSaga";

export function* systemLaunchedSaga() {
  const appVersion: string | null = yield call(CordovaUtil.getAppVersion);
  if (appVersion !== null) {
    LocalStorage.setItem("appVersion", appVersion);
    yield put(setAppVersion(appVersion));
  }
  const loginTokenFromStorage: string | null = LocalStorage.getItem("loginToken");
  if (AppModel.existsLoginToken(loginTokenFromStorage)) {
    yield put(updateLoginToken(loginTokenFromStorage));
  }
  yield call(bluetoothAuthorizationSaga);
  yield all([put(systemSearchPrinter()), put(setLaunched())]);
}
