import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
} from "components/molecules/ModalDialog";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import { ShopData } from "records/ShopData";
import { userSubmittedUpdateDisplayImages } from "modules/shop/actions";
import { shopDataSelector } from "modules/shop/selectors";

const MODAL_KEY = "CONFIRM_SHOW_THUMBNAIL_DIALOG";
const useModal = modalHooksFactory(MODAL_KEY);

const TextContainer = styled.div`
  margin-bottom: 1em;
`;

export const ConfirmShowThumbnailDialog: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const shopData = useSelector(shopDataSelector);
  const { data, close } = useModal();

  const handleSubmit = useCallback(() => {
    dispatch(userSubmittedUpdateDisplayImages(!ShopData.isShowDisplayImages(shopData)));
    close();
  }, [close, dispatch, shopData]);

  return (
    <ModalDialog isOpen={data.isOpen} close={close} handleClickBackdrop={close}>
      <ModalDialogHeader handleClose={close}>商品画像の表示</ModalDialogHeader>
      <ModalBody>
        <TextContainer>
          <Text>商品画像を表示してよろしいですか？</Text>
        </TextContainer>
        <Text color="DANGER">
          こちらの機能はデータ量を消費する恐れがあります。端末の利用環境にWifiがあることを確認してお使いください。
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button appearance="primary" handleClick={handleSubmit}>
          OK
        </Button>
      </ModalFooter>
    </ModalDialog>
  );
});
