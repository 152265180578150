import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { MonthlySales } from "records/MonthlySales";
import { monthlySalesSelector } from "modules/sales/selectors";
import { PriceUtils } from "utils/PriceUtils";
import { colorsConst, fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { NoData } from "components/atoms/NoData";

const Container = styled.div`
  padding-bottom: 3em;
`;

const YearContaienr = styled.div`
  padding-top: 1.5em;
  padding-bottom: 0.5em;
`;

const Year = styled.div`
  ${fontSize.LARGE};
  font-weight: ${fontWeightsConst.MEDIUM};
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 1em;
  border-bottom: 1px solid ${colorsConst.BORDER.DEFAULT};
`;

const MonthLabel = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const Value = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

type Props = {};

export const MonthlySalesDetail: React.FC<Props> = React.memo(() => {
  const monthlySales = useSelector(monthlySalesSelector);

  const monthlySalesDevidedByYear = useMemo(
    () => MonthlySales.getMonthlySalesDevidedByYear(monthlySales.sales_list),
    [monthlySales],
  );

  const existsMonthlySales = useMemo(
    () => MonthlySales.existsMonthlySales(monthlySales),
    [monthlySales],
  );

  const sortedYearsKey = useMemo(
    () => Object.keys(monthlySalesDevidedByYear).sort(MonthlySales.sortMonthlySalesByYear),
    [monthlySalesDevidedByYear],
  );

  return (
    <Container>
      {existsMonthlySales ? (
        <>
          {sortedYearsKey.map(year => (
            <div key={year}>
              <YearContaienr>
                <Year>{`${year}年売り上げ`}</Year>
              </YearContaienr>
              {Object.keys(monthlySalesDevidedByYear[year]).map(item => (
                <Row key={item}>
                  <MonthLabel>{`${item.slice(4)}月`}</MonthLabel>
                  <Value>
                    {PriceUtils.getPresentationValue(monthlySalesDevidedByYear[year][item])}
                  </Value>
                </Row>
              ))}
            </div>
          ))}
        </>
      ) : (
        <NoData>売上履歴はありません</NoData>
      )}
    </Container>
  );
});
