import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconQuestion: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 34 32" width="1em" height="1em" {...props}>
      <path
        fill={fillColor}
        d="M8.1,24l0.8-0.6C8.7,23.1,8.4,23,8.1,23V24z M13.7,31l-0.8,0.6c0.3,0.3,0.7,0.5,1.1,0.3 c0.4-0.1,0.7-0.5,0.7-0.9H13.7z M13.7,24v-1c-0.6,0-1,0.4-1,1H13.7z M3.5,3c0-0.6,0.4-1,1-1V0c-1.7,0-3,1.3-3,3H3.5z M3.5,22V3h-2 v19H3.5z M4.5,23c-0.6,0-1-0.4-1-1h-2c0,1.7,1.3,3,3,3V23z M8.1,23H4.5v2h3.6V23z M14.5,30.4l-5.6-7l-1.6,1.3l5.6,7L14.5,30.4z  M12.7,24v7h2v-7H12.7z M29.5,23H13.7v2h15.8V23z M30.5,22c0,0.6-0.4,1-1,1v2c1.7,0,3-1.3,3-3H30.5z M30.5,3v19h2V3H30.5z M29.5,2 c0.6,0,1,0.4,1,1h2c0-1.7-1.3-3-3-3V2z M4.5,2h25V0h-25V2z"
      />
      <path
        fill={fillColor}
        d="M15,15.8c0-0.3,0-0.3,0-0.8c0-1.3,0.2-2,0.8-2.7c0.4-0.5,0.6-0.6,1.8-1.4c0.8-0.5,1.1-0.9,1.1-1.5 c0-0.9-0.6-1.4-1.6-1.4c-0.8,0-1.3,0.3-1.7,0.9c-0.2,0.3-0.2,0.5-0.3,1.1l-3.6-0.3c0.2-1.3,0.4-2,1.1-2.8c1-1.2,2.5-1.8,4.5-1.8 c3.2,0,5.2,1.6,5.2,4.1c0,1.1-0.3,2-1.1,2.7c-0.4,0.4-0.6,0.5-2.1,1.5c-0.7,0.5-1,1-1,1.8c0,0.2,0,0.4,0.1,0.7H15z M18.4,17v3.4 h-3.5V17H18.4z"
      />
    </svg>
  );
});
