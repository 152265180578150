import React from "react";
import styled from "styled-components";
import { colorsConst, fontFamilyConst } from "styles/const";
import { fontSize } from "styles/mixins";

const Container = styled.button<{ disabled: boolean }>`
  margin-top: 0.5em;
  padding: 0;
  appearance: none;
  background: none;
  border: none;
  text-decoration: underline;
  color: ${p => (p.disabled ? colorsConst.TEXT.QUATERNARY : colorsConst.TEXT.DANGER)};
  ${fontSize.DEFAULT};
  font-family: ${fontFamilyConst};
`;

type Props = {
  children: React.ReactNode;
  disabled: boolean;
  handleClick: () => void;
};

export const OutOfStockButton: React.FC<Props> = React.memo(
  ({ children, disabled, handleClick }) => (
    <Container type="button" onClick={handleClick} disabled={disabled}>
      {children}
    </Container>
  ),
);
