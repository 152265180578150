import { Task } from "redux-saga";
import { put, takeLatest, fork } from "redux-saga/effects";
import { OrderData, ORDER_STATE_TYPE } from "records/OrderData";
import { updateSnackBar } from "modules/app/actions";
import {
  ActionTypes,
  updateSimulatorOrderData,
  updateSimulatorOrderList,
  updateSimulatorPageTransition,
  UserTouchedSimulatorApproveOrderButtonAction,
} from "../actions";
import { SimulatorModel } from "../model";
import { searchDriverSaga } from "./searchDriverSaga";

export function* userTouchedSimulatorApproveOrderButtonSaga(
  action: UserTouchedSimulatorApproveOrderButtonAction,
) {
  const { orderData, draftItems } = action.payload;
  const updatedOrderItems = SimulatorModel.getUpdatedOrderItems(orderData, draftItems);
  const updatedOrderData = OrderData.create({
    ...orderData,
    order_state: ORDER_STATE_TYPE.APPROVE,
    item_list: updatedOrderItems,
  });
  yield put(updateSimulatorOrderData(updatedOrderData));
  yield put(updateSimulatorOrderList([updatedOrderData]));
  yield put(updateSimulatorPageTransition(false));
  yield put(updateSnackBar("注文を受け付けました"));
  const searchDriverTask: Task = yield fork(searchDriverSaga, updatedOrderData);
  const stopTask: Task = yield takeLatest(ActionTypes.S_UNMOUNT_SIMULATOR, stopTaskSaga);
  function* stopTaskSaga() {
    if (!searchDriverTask.isCancelled()) {
      yield searchDriverTask.cancel();
      yield stopTask.cancel();
    }
  }
}
