import { CordovaUtil } from "utils/CordovaUtil";
import { Insomnia } from "../@types/Insomnia";

declare const window: Window & {
  plugins: {
    insomnia: Insomnia;
  };
};

const enebleInsomnia = () => "plugins" in window && "insomnia" in window.plugins;

const keepAwake = () => {
  if (CordovaUtil.isCordovaEnabled() && enebleInsomnia()) {
    window.plugins.insomnia.keepAwake();
  }
};

export const InsomniaUtil = Object.freeze({
  keepAwake,
});
