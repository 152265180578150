import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconPicking: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="2.5"
        strokeLinejoin="round"
        d="M37.6,14.5H2.5L7,31.6h26.2"
      />
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M45.8,2.5h-5.2l-9,35.1H8.5"
      />
      <circle fill="none" stroke={fillColor} strokeWidth="2.5" cx="8.6" cy="41.8" r="4.1" />
      <circle fill="none" stroke={fillColor} strokeWidth="2.5" cx="30.6" cy="41.8" r="4.1" />
      <path
        fill={fillColor}
        d="M18.8,4.2c-0.5-0.1-1.1,0.3-1.2,0.8l-1.1,6h17.3l0.5-2.9C34.4,7.5,34,7,33.5,6.9L18.8,4.2z"
      />
      <path fill={fillColor} d="M15.2,6.8L5.5,8.2C4.9,8.3,4.6,8.8,4.6,9.3L4.9,11h9.6L15.2,6.8z" />
    </svg>
  );
});
