import { fork, call, put } from "redux-saga/effects";
import { API_KEY, DeleteBusinessHolidayRequest, DeleteBusinessHolidayResponse } from "apis";
import { DateUtils } from "utils/DateUtils";
import { systemClosedModal, updateSnackBar } from "../../app/actions";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { commonErrorSaga } from "../../app/sagas/commonErrorSaga";
import { updateShopBusiness, UserSubmittedDeleteBusinessHolidayAction } from "../actions";

export function* userSubmittedDeleteBusinessHolidaySaga(
  action: UserSubmittedDeleteBusinessHolidayAction,
) {
  try {
    const params: DeleteBusinessHolidayRequest = {
      date: DateUtils.dateToString(action.payload.value, "YYYY-MM-DD"),
    };
    const { result, error }: { result: DeleteBusinessHolidayResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.DELETE_BUSINESS_HOLIDAY,
      params,
    );
    if (result && !error) {
      yield put(updateShopBusiness(result.shop_business));
      yield put(systemClosedModal("CONFIRM_DELETE_HOLIDAY"));
      yield put(updateSnackBar("休業日を削除しました"));
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
