import { spawn, delay, call, put, select } from "redux-saga/effects";
import { LocalStorage } from "utils/LocalStorage";
import {
  CURRENT_RESOURCE_VERSION_COOKIE_KEY,
  LATEST_RESOURCE_VERSION_COOKIE_KEY,
  INTERVAL_CHECK_UPDATE_RESOURCE,
} from "../model";
import { shouldUpdateResouceSelector, isConnectedAnyApiSelector } from "../selectors";
import { systemOpenedModal, setShouldUpdateResourceFlag } from "../actions";

export function* checkUpdateSaga() {
  yield spawn(checkUpdateResourceSaga);
  yield spawn(updateResourceSaga);
}

function* checkUpdateResourceSaga() {
  while (true) {
    try {
      yield delay(INTERVAL_CHECK_UPDATE_RESOURCE);
      const shouldUpdate: boolean = yield call(checkResourceVersion);
      if (shouldUpdate) {
        console.log("UPDATE RESOURCE");
        yield put(systemOpenedModal("SHOULD_UPDATE_RESOURCE", {}));
        yield put(setShouldUpdateResourceFlag());
      }
    } catch (e) {
      console.log(e);
    }
  }
}

async function checkResourceVersion() {
  try {
    const response = await fetch("/data/buildVersion.json", {
      cache: "no-cache",
    });
    const buildVersion: { build: number } = await response.json();
    const latestVersion = buildVersion.build.toString();
    const currentVersion = LocalStorage.getItem(CURRENT_RESOURCE_VERSION_COOKIE_KEY);
    if (currentVersion !== latestVersion) {
      LocalStorage.setItem(LATEST_RESOURCE_VERSION_COOKIE_KEY, latestVersion);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

function* updateResourceSaga() {
  while (true) {
    yield delay(15000);
    const shouldUpdateResouce: boolean = yield select(shouldUpdateResouceSelector);
    const isConnectedAnyApi: boolean = yield select(isConnectedAnyApiSelector);
    if (shouldUpdateResouce && !isConnectedAnyApi) {
      // ダイアログを表示してマニュアルでリソースを更新する。
      yield put(systemOpenedModal("SHOULD_UPDATE_RESOURCE", {}));
    }
  }
}
