import React, { useMemo, useContext } from "react";
import styled from "styled-components";
import { OrderListTabType, ORDER_LIST_TAB_TYPE } from "modules/order/model";
import { OrderListFilterItem } from "../molecules/OrderListFilterItem";
import { OrderListContext } from "./OrderListContext";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: 2em;
`;

type Props = {
  tabType: OrderListTabType;
};

export const OrderListFilter: React.FC<Props> = React.memo(({ tabType }) => {
  const {
    reservedOrderOnly = false,
    notDisplayOrderItem = false,
    setReservedOrderOnly,
    setNotDisplayOrderItem,
  } = useContext(OrderListContext);

  const canDisplayOrderListFilterItems = useMemo(
    () => tabType === ORDER_LIST_TAB_TYPE.PENDING || tabType === ORDER_LIST_TAB_TYPE.ON_PREPARING,
    [tabType],
  );

  return (
    <Container>
      {canDisplayOrderListFilterItems ? (
        <>
          {tabType === ORDER_LIST_TAB_TYPE.PENDING ? (
            <OrderListFilterItem active={reservedOrderOnly} handleClick={setReservedOrderOnly}>
              予約注文のみ表示
            </OrderListFilterItem>
          ) : undefined}
          <OrderListFilterItem active={notDisplayOrderItem} handleClick={setNotDisplayOrderItem}>
            商品情報は記載しない
          </OrderListFilterItem>
        </>
      ) : undefined}
    </Container>
  );
});
