import { put, select } from "redux-saga/effects";
import { CordovaUtil } from "utils/CordovaUtil";
import { systemPrintReceipt, SystemAutoPrintReceiptAction } from "../actions";
import { AppModel } from "../model";
import { enebleBluetoothSelector } from "../selectors";

export function* systemAutoPrintReceiptSaga(action: SystemAutoPrintReceiptAction) {
  const { orderData, printCount } = action.payload;
  const enebleBluetooth: ReturnType<typeof enebleBluetoothSelector> = yield select(
    enebleBluetoothSelector,
  );
  if (!CordovaUtil.isCordovaEnabled() || !enebleBluetooth) {
    return;
  }
  const isCompletedAutoPrint = AppModel.isCompletedAutoPrint(orderData.order_no);
  if (isCompletedAutoPrint) {
    return;
  }
  yield put(systemPrintReceipt(orderData, printCount));
}
