import md5 from "md5";

const rangeInt = (min: number, max: number) =>
  Array(max - min)
    .fill(null)
    .map((_, index) => index + min);

const escapeRegExp = (str: string) => {
  const reRegExp = /[\\^$.*+?()[\]{}|]/g;
  const reHasRegExp = new RegExp(reRegExp.source);
  return str && reHasRegExp.test(str) ? str.replace(reRegExp, "\\$&") : str;
};

const getEscapedSearchWordArray = (searchWord: string) => {
  const escapedSearchWordArray = searchWord.split(/[\x20\u3000]/g).map(str => escapeRegExp(str));
  return escapedSearchWordArray.filter(str => str !== "");
};

const getImageDomain = (): string => {
  const env = process.env.REACT_APP_IMAGE_BASE_PATH;
  return typeof env !== "undefined" ? env : "";
};

const getImagePath = (path: string): string => {
  const spliter = path.charAt(0) === "/" ? "" : "/";
  return getImageDomain() + spliter + path;
};

const getCdnImageDomain = (): string => {
  const env = process.env.REACT_APP_CDN_IMAGE_DOMAIN;
  return typeof env !== "undefined" ? env : "";
};

const getCdnImagePath = (path: string, width: number): string => {
  const spliter = path.charAt(0) === "/" ? "" : "/";
  const widthPath = `/w=${width}`;
  return getCdnImageDomain() + widthPath + spliter + path;
};

const getGoogleMapsApiKey = () => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  return typeof googleMapsApiKey !== "undefined" ? googleMapsApiKey : "";
};

const getMd5String = (text: string): string => md5(text);

const strBytes = (str: string) => encodeURIComponent(str).replace(/%../g, "x").length;

const isMultiByte = (str: string) => strBytes(str) > 1;

const generateSimulatorOrderNo = (): string => {
  const getRandamString = (source: string, length: number) =>
    Array(length)
      .fill("")
      .map(_ => source[Math.floor(Math.random() * source.length)])
      .join("");
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numerics = "0123456789";
  return `${getRandamString(letters, 2)}${getRandamString(numerics, 3)}${getRandamString(
    letters,
    1,
  )}`;
};

const splitTextByLineFeed = (text: string): string[] => text.split("\n");

const isJsonString = (data: string) => {
  try {
    JSON.parse(data);
  } catch {
    return false;
  }
  return true;
};

export const Utility = Object.freeze({
  rangeInt,
  escapeRegExp,
  getEscapedSearchWordArray,
  getImageDomain,
  getImagePath,
  getCdnImageDomain,
  getCdnImagePath,
  getGoogleMapsApiKey,
  getMd5String,
  strBytes,
  isMultiByte,
  generateSimulatorOrderNo,
  splitTextByLineFeed,
  isJsonString,
});
