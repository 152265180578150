import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { serverDateTimeSelector } from "modules/app/selectors";
import { AppModel } from "modules/app/model";
import { DateUtils } from "utils/DateUtils";
import { Text } from "components/atoms/Text";

const Container = styled.div``;

type Props = {};

export const LatestAccessTime: React.FC<Props> = React.memo(() => {
  const serverDateTime = useSelector(serverDateTimeSelector);

  return (
    <Container>
      {AppModel.existsServerDateTime(serverDateTime) ? (
        <Text>
          最終アクセス
          <br />
          {AppModel.getFormattedServerDateTime(serverDateTime)}
        </Text>
      ) : (
        <Text>
          アプリを
          <br />
          再起動してください
        </Text>
      )}
    </Container>
  );
});
