import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconMegaphone: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 27" fill="none" {...props}>
      <path
        d="M25.332 8.46484C27.2438 8.46484 28.7941 9.85609 28.7941 11.5705C28.7941 13.285 27.2438 14.6762 25.332 14.6762"
        stroke={fillColor}
        strokeWidth="2.4"
      />
      <path
        d="M18.3939 4.24811C13.8246 5.48875 8.47131 5.63489 8.23602 5.64077C8.22849 5.64095 8.22415 5.64096 8.21662 5.64097C4.78279 5.6469 2 8.34292 2 11.6663C2 14.9929 4.78817 17.6909 8.22923 17.6917C8.23 17.6917 8.23063 17.691 8.23063 17.6903V17.6903C8.23063 17.6895 8.22998 17.6888 8.23076 17.6889C8.2614 17.6896 13.734 17.8165 18.3965 19.0817C20.6534 19.6941 22.3471 20.4721 23.4954 21.1131C24.2459 21.5321 25.3304 20.9831 25.3304 20.1236V11.6663V2C25.3304 1.44771 24.9843 1.28524 24.5242 1.59061C23.4931 2.27484 21.4649 3.41474 18.3939 4.24811Z"
        stroke={fillColor}
        strokeWidth="2.4"
      />
      <path d="M8.64062 5.80078V16.9989" stroke={fillColor} strokeWidth="2.4" />
      <path
        d="M10.2563 25.5279H7.15968C6.8268 25.5279 6.57505 25.1636 6.6282 24.758L7.3974 18.9316C7.46306 18.4342 7.8871 18.0625 8.38879 18.0625H10.6962C11.3004 18.0625 11.7666 18.5942 11.6876 19.1933L10.9472 24.8061C10.8884 25.222 10.5975 25.5279 10.2563 25.5279Z"
        stroke={fillColor}
        strokeWidth="2.4"
      />
    </svg>
  );
});
