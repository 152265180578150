import React, { useMemo } from "react";
import styled from "styled-components";
import { OrderData, ORDER_PAYMENT_TYPE } from "records/OrderData";
import { ShopData } from "records/ShopData";
import { DraftItem } from "records/OrderItemData";
import { OrderModel } from "modules/order/model";
import { PriceUtils } from "utils/PriceUtils";
import { Text } from "components/atoms/Text";

const Container = styled.div`
  padding: 0.5em 1em;
`;

const TotalCountPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MethodStateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const TotalCountContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const PriceContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const PaymentMethod = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const PaymentStateText = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 1em;
  text-align: right;
`;

type Props = {
  orderData: OrderData;
  shopData: ShopData;
  draftItems: DraftItem[];
  draftTotalItemCounts: number;
};

export const PaymentInfo: React.FC<Props> = React.memo(
  ({ orderData, shopData, draftItems, draftTotalItemCounts }) => {
    const isLocalPayment = useMemo(
      () => orderData.payment_type === ORDER_PAYMENT_TYPE.LOCAL,
      [orderData],
    );

    const paymentStateLabel = useMemo(
      () => (isLocalPayment ? "現地支払い" : "支払い済み"),
      [isLocalPayment],
    );

    const paymentStateText = useMemo(
      () =>
        isLocalPayment
          ? "商品受け渡しのとき、代金をお受け取りください"
          : "そのまま商品をお渡しください",
      [isLocalPayment],
    );

    const paymentMethod = useMemo(
      () => (isLocalPayment ? "店頭支払い" : "menu決済支払い"),
      [isLocalPayment],
    );

    const displayablePaymentPrice = useMemo(
      () => OrderModel.getDisplayablePaymentPrice(orderData, shopData, isLocalPayment, draftItems),
      [orderData, shopData, draftItems, isLocalPayment],
    );

    return (
      <Container>
        <TotalCountPriceContainer>
          <TotalCountContainer>
            <Text weight="BOLD">
              計<Text size="XXXLARGE">{draftTotalItemCounts}</Text>個
            </Text>
          </TotalCountContainer>
          <PriceContainer>
            <Text size="XXLARGE" weight="BOLD">
              {PriceUtils.getPresentationValue(displayablePaymentPrice)}
            </Text>
            {orderData.shop_support_price > 0 ? (
              <Text size="SMALL">{`（応援金${PriceUtils.getPresentationValue(
                orderData.shop_support_price,
              )}を含む）`}</Text>
            ) : undefined}
          </PriceContainer>
        </TotalCountPriceContainer>
        <MethodStateContainer>
          <PaymentMethod>
            <Text size="SMALL">
              {paymentMethod}
              {isLocalPayment && orderData.discount_price > 0 ? `（クーポン利用）` : ""}：
              <Text weight="BOLD">{paymentStateLabel}</Text>
            </Text>
          </PaymentMethod>
          <PaymentStateText>
            <Text size="SMALL">{paymentStateText} </Text>
          </PaymentStateText>
        </MethodStateContainer>
      </Container>
    );
  },
);
