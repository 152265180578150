/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, cancelled, cancel, delay } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { AppError } from "records/AppError";
import { OrderData } from "records/OrderData";
import { updateOrderDetail } from "modules/order/actions";
import { systemOpenedModal, initLoginToken } from "../actions";

export const ERROR_CODE = {
  DataNotFoundException: -32001,
  InvalidParameterException: -32002,
  EmptyParameterException: -32003,
  ClassNotFoundException: -32004,
  TableNotFoundException: -32005,
  InvalidValueException: -32006,
  AlreadyExistsException: -32007,
  AuthFailedException: -32008,
  InvalidStateException: -32009,
  OutOfRangeException: -32010,
  SiteHeavyException: -32098,
  RuntimeException: -32099,
  InvalidTokenException: -32100,
  UpdateCreditCardInfoException: -32101,
  ExecuteCreditCardException: -32102,
  UserNotFoundException: -32103,
  LoginFailedException: -32104,
  OrderExpiredException: -32110,
  BankCodeNotExistException: -32111,
  OrderExclusionControlException: -32114,
  ReleaseStopByOptionSetFailedException: -32143,
  InvalidAccountLoginInException: -32147,
  ReExecuteCreditCardException: -32156,
  RestartReservedTimeSettingException: -32157,
  UpdateBusinessTimeSettingException: -32158,
  OrderReceiveTimeExceededException: -32201,
  ItemOutsideOfBusinessTimeException: -33021,
  ReceiveDuplicateOrderException: -33053,
} as const;

export function* commonApiFailedSaga(response: Response): any {
  try {
    const data = yield response.json();
    const errorObject =
      "error" in data
        ? AppError.create({
            code: "code" in data.error ? data.error.code : 0,
            title: "title" in data.error ? data.error.title : "エラー",
            message:
              "message" in data.error
                ? data.error.message
                : "エラーが発生しました。\nお手数ですが再度ログインしてください。",
          })
        : AppError.create({
            code: 0,
            title: "エラー",
            message: "エラーが発生しました。\nお手数ですが再度ログインしてください。",
          });
    switch (errorObject.code) {
      /**
       * サーバー発行のエラーメッセージを表示してその場で処理をキャンセル
       * - 必須オプションの販売停止エラー
       * - 商品の提供時間の不一致により営業時間の変更ができない場合
       * - ペイジェントの再与信時に決済エラー
       * - 不正な店舗受付再開の時間設定
       * - 不正な営業時間設定
       */
      case ERROR_CODE.ReleaseStopByOptionSetFailedException:
      case ERROR_CODE.ItemOutsideOfBusinessTimeException:
      case ERROR_CODE.ReExecuteCreditCardException:
      case ERROR_CODE.RestartReservedTimeSettingException:
      case ERROR_CODE.UpdateBusinessTimeSettingException:
        yield put(systemOpenedModal("APP_ERROR", errorObject));
        yield cancel();
        break;
      /**
       * 処理済のオーダーをupdateした場合、サーバー発行のエラーメッセージを表示しorder_dataを初期化、注文一覧画面へリダイレクト
       */
      case ERROR_CODE.OrderExpiredException:
      case ERROR_CODE.OrderExclusionControlException:
      case ERROR_CODE.ReceiveDuplicateOrderException:
        yield put(systemOpenedModal("APP_ERROR", errorObject));
        yield delay(500);
        yield put(updateOrderDetail(OrderData.create()));
        yield put(replace("/orderList"));
        yield cancel();
        break;
      /**
       * トークンが不正の場合または削除した店舗のトークンを使用している場合、tokenを初期化しTOPへリダイレクト
       */
      case ERROR_CODE.InvalidTokenException:
      case ERROR_CODE.InvalidAccountLoginInException:
        yield put(systemOpenedModal("APP_ERROR", errorObject));
        yield put(initLoginToken());
        yield put(replace("/"));
        yield cancel();
        break;
      /**
       * ログインエラー時、以下のメッセージを表示しTOPへリダイレクト
       */
      case ERROR_CODE.LoginFailedException:
        yield put(
          systemOpenedModal(
            "APP_ERROR",
            AppError.create({
              title: "ログインエラー",
              message: `ログインに失敗しました。\nIDまたはパスワードが間違っています。`,
            }),
          ),
        );
        yield put(replace("/"));
        yield cancel();
        break;
      /**
       * 受付可能時間を過ぎた後に受け付けした場合、固有のダイアログを表示し処理をキャンセル
       */
      case ERROR_CODE.OrderReceiveTimeExceededException:
        yield put(systemOpenedModal("UNABLE_APPROVE_ORDER", {}));
        yield cancel();
        break;
      /**
       * デフォルトのエラーメッセージを表示しTOPにリダイレクトして処理をキャンセル
       */
      default:
        yield put(systemOpenedModal("APP_ERROR", errorObject));
        yield put(replace("/"));
        yield cancel();
        break;
    }
    console.log("commonApiFailedSaga", data);
    return { error: data.error };
  } catch (error) {
    // エラー時の処理
    console.error(error);
  } finally {
    if (yield cancelled()) {
      // ここにキャンセルされた時の処理を記述
      console.log("commonApiFailedSaga is cancelled");
    }
  }
}
