import { fork, call, put } from "redux-saga/effects";
import { API_KEY, SetShopStoppedItemReasonsResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { SentryUtil } from "utils/SentryUtil";
import { REASON_FOR_ITEMS_STOP_VIEW } from "records/ShopItem";
import {
  systemUpdateShopItemSelectStopReasonType,
  UserSubmittedUpdateShopItemStoppedReasonsAction,
} from "modules/shop/actions";

export function* userSubmittedUpdateShopStoppedItemReasonsSaga(
  action: UserSubmittedUpdateShopItemStoppedReasonsAction,
) {
  try {
    const { items, view_type } = action.payload;
    const params = {
      shop_items: items,
      view_type,
    };
    const { error }: { result: SetShopStoppedItemReasonsResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.SET_SHOP_STOPPED_ITEM_REASONS,
      params,
    );
    if (error) {
      throw error;
    }

    if (view_type !== REASON_FOR_ITEMS_STOP_VIEW.ITEM_LIST) {
      for (const item of items) {
        yield put(systemUpdateShopItemSelectStopReasonType([item.shop_item_id], item.reason_type));
      }
    }
  } catch (error) {
    SentryUtil.reportError(error);
  }
}
