import { ShopItemOption } from "./ShopItemOption";

export const OPTION_SELECT_TYPE = {
  REQUIRED: 1,
  OPTIONAL: 2,
} as const;

type OptionSelectType = ValueOf<typeof OPTION_SELECT_TYPE>;

export type SelectedOptionSet = Readonly<{
  option_set_id: number;
  stop_option_id_list: number[];
}>;

export type SubmittableOptionSetType = Readonly<{
  option_set_id: number;
  stop_option_id_list: number[];
}>;

export type ShopItemOptionSet = {
  option_set_id: number;
  option_set_name: string;
  option_select_type: OptionSelectType;
  select_count_min: number;
  select_count_max: number;
  option_list: ShopItemOption[];
};

const initialState: ShopItemOptionSet = {
  option_set_id: 0,
  option_set_name: "",
  option_select_type: OPTION_SELECT_TYPE.REQUIRED,
  select_count_min: 0,
  select_count_max: 0,
  option_list: [],
};

const create = (args: Partial<ShopItemOptionSet> = {}) => ({
  ...initialState,
  ...args,
  option_list: args.option_list ? args.option_list.map(i => ShopItemOption.create(i)) : [],
});

const isRequired = (optionSet: ShopItemOptionSet) =>
  optionSet.option_select_type === OPTION_SELECT_TYPE.REQUIRED;

const isStopped = (optionSet: ShopItemOptionSet) =>
  optionSet.option_list.filter(option => !option.is_order_stop).length < optionSet.select_count_min;

const getPresentstionSelectTypeText = (type: OptionSelectType) => {
  switch (type) {
    case OPTION_SELECT_TYPE.OPTIONAL:
      return "任意";
    case OPTION_SELECT_TYPE.REQUIRED:
      return "必須";
    // skip default case
  }
};

export const ShopItemOptionSet = Object.freeze({
  initialState,
  create,
  isRequired,
  isStopped,
  getPresentstionSelectTypeText,
});
