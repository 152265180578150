import React, { useMemo } from "react";
import styled from "styled-components";
import { ShopItemCategory } from "records/ShopItemCategory";
import { ShopItemOptionSet } from "records/ShopItemOptionSet";
import { ShopModel } from "modules/shop/model";
import { fontSize } from "styles/mixins";
import { UnRestartableOptionSet } from "components/molecules/UnRestartableOptionSet";

const Container = styled.div`
  padding-bottom: 1em;
  & + & {
    padding-top: 0.5em;
    border-top: 1px solid #e3e0df;
  }
`;

const SectionTitle = styled.div`
  ${fontSize.SMALL};
`;

const ShopItemContainer = styled.div`
  margin-bottom: 0.5em;
`;
const ShopItemName = styled.div`
  font-weight: 500;
`;
const OptionSetContainer = styled.div``;

type Props = {
  errorItemId: number;
  errorOptionSetIds: number[];
  shopItems: ShopItemCategory[];
  shopItemOptionSets: ShopItemOptionSet[];
};

export const OrderUnRestartableItemByStoppedOptionSet: React.FC<Props> = React.memo(
  ({ errorItemId, errorOptionSetIds, shopItems, shopItemOptionSets }) => {
    const targetItem = useMemo(
      () => ShopModel.getShopItemById(shopItems, errorItemId),
      [errorItemId, shopItems],
    );

    return (
      <Container>
        {typeof targetItem !== "undefined" ? (
          <>
            <ShopItemContainer>
              <SectionTitle>商品名</SectionTitle>
              <ShopItemName>{targetItem.item_name}</ShopItemName>
            </ShopItemContainer>
            <OptionSetContainer>
              <SectionTitle>該当するオプション名</SectionTitle>
              {errorOptionSetIds.map(optionSetId => (
                <UnRestartableOptionSet
                  key={`${errorItemId}_${optionSetId}`}
                  shopItemId={errorItemId}
                  optionSetId={optionSetId}
                  shopItemOptionSets={shopItemOptionSets}
                />
              ))}
            </OptionSetContainer>
          </>
        ) : undefined}
      </Container>
    );
  },
);
