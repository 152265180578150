import { OrderOptionSet } from "./OrderOptionSet";

const REQUIRED_ORDER_ITEM_PARENT_CATEGORY_ID = -1;

export type OrderItemData = Readonly<{
  shop_item_id: number;
  order_shop_item_id_group: number;
  total_order_count: number;
  order_count: number;
  free_order_count: number;
  unit_price: number;
  image_path: string;
  item_name: string;
  instruction_text: string;
  supplement_text: string;
  option_set_list: OrderOptionSet[];
  is_campaign: boolean;
  pick_count: number;
  is_picked: boolean;
  is_sell_by_weight: boolean;
  unit: string;
  input_min_quantity: number;
  input_max_quantity: number;
  user_display_quantity: number;
  shop_item_parent_category_id: number;
  shop_item_parent_category_group_order: number;
  is_substitute_specified: boolean;
  is_substitute_item: boolean;
  can_order_max_count: number;
}>;

const initialState: OrderItemData = {
  shop_item_id: 0,
  order_shop_item_id_group: 0,
  total_order_count: 0,
  order_count: 0,
  free_order_count: 0,
  unit_price: 0,
  image_path: "",
  item_name: "",
  instruction_text: "",
  supplement_text: "",
  option_set_list: [],
  is_campaign: false,
  pick_count: 0,
  is_picked: false,
  is_sell_by_weight: false,
  unit: "",
  input_min_quantity: 0,
  input_max_quantity: 0,
  user_display_quantity: 0,
  shop_item_parent_category_id: 0,
  shop_item_parent_category_group_order: 0,
  is_substitute_specified: false,
  is_substitute_item: false,
  can_order_max_count: 0,
};

export type DraftItem = OrderItemData & {
  substituteItem?: OrderItemData;
};

const create = (args: Partial<OrderItemData> = {}): OrderItemData => ({
  ...initialState,
  ...args,
  option_set_list: args.option_set_list
    ? args.option_set_list.map(i => OrderOptionSet.create(i))
    : [],
});

const isBuyOne = (itemData: OrderItemData) => itemData.free_order_count > 0;

const isCampaign = (itemData: OrderItemData) => itemData.is_campaign;

const isRequired = (itemData: OrderItemData) =>
  itemData.shop_item_parent_category_id === REQUIRED_ORDER_ITEM_PARENT_CATEGORY_ID;

const existsSupplementText = (itemData: OrderItemData) => itemData.supplement_text !== "";

const existsSubstituteItem = (draftItem: DraftItem) =>
  draftItem.is_substitute_specified && typeof draftItem.substituteItem !== "undefined";

const getTotalItemPrice = (itemData: OrderItemData) =>
  itemData.option_set_list.reduce(
    (accum, curr) => accum + OrderOptionSet.getOptionSetPrice(curr),
    itemData.unit_price,
  );

const getPresentationUnitString = (unit: string) => (unit !== "" ? unit : "個");

export const OrderItemData = Object.freeze({
  initialState,
  create,
  isBuyOne,
  isCampaign,
  isRequired,
  existsSupplementText,
  existsSubstituteItem,
  getTotalItemPrice,
  getPresentationUnitString,
});
