import React, { useCallback } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { AppModel } from "modules/app/model";
import { colorsConst, zIndexConst } from "styles/const";
import { fontSize } from "styles/mixins";

const Container = styled.div`
  z-index: ${zIndexConst.FIXED};
  position: fixed;
  right: 1em;
  bottom: 8em;
  /*
  @media (orientation: landscape) {
    bottom: 3.5em;
  }
  @media (orientation: portrait) {
    bottom: 8em;
  }
  */
`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  appearance: none;
  ${fontSize.XXXLARGE};
  color: ${colorsConst.BUTTON.TEXT.DEFAULT};
  background-color: ${colorsConst.BUTTON.BACKGROUND.DEFAULT};
  border: none;
  border-radius: 1em;
`;

export const ReloadButton: React.FC = React.memo(() => {
  const handleClick = useCallback(() => {
    AppModel.hardReload(true);
  }, []);

  return (
    <Container>
      <Button onClick={handleClick}>
        <FontAwesomeIcon icon={faRedo} />
      </Button>
    </Container>
  );
});
