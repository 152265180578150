import { fork } from "redux-saga/effects";
import { UpdateBusinessBusyTimeRequest } from "apis";
import { UserSubmittedUpdateBusinessBusyTimeAction } from "../actions";
import { updateBusinessBusyTimeSaga } from "./updateBusinessBusyTimeSaga";

export function* userSubmittedUpdateBusinessBusyTimeSaga(
  action: UserSubmittedUpdateBusinessBusyTimeAction,
) {
  const params: UpdateBusinessBusyTimeRequest = {
    business_busy_time: action.payload.values.business_busy_time,
  };
  yield fork(updateBusinessBusyTimeSaga, params);
}
