import { put } from "redux-saga/effects";
import { OrderData, ORDER_STATE_TYPE } from "records/OrderData";
import { systemOpenedModal } from "../../app/actions";
import { updateSimulatorOrderData, UserTouchedSimulatorHoldOrderButtonAction } from "../actions";

export function* userTouchedSimulatorHoldOrderButtonSaga(
  action: UserTouchedSimulatorHoldOrderButtonAction,
) {
  const { orderData, isRetail } = action.payload;
  const updatedOrderData = OrderData.create({
    ...orderData,
    order_state: ORDER_STATE_TYPE.HOLD,
  });
  yield put(
    systemOpenedModal("HOLD_ORDER", {
      userInfoTel: orderData.user_info.tel,
      isRetail,
    }),
  );
  yield put(updateSimulatorOrderData(updatedOrderData));
}
