import React from "react";
import styled from "styled-components";
import {
  ORDER_STOP_REASON_BY_SHOP,
  OrderStopReasonByShopType,
  displayableOrderStopReasonByShop,
} from "modules/shop/model";
import { colorsConst, fontWeightsConst, fontFamilyConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { selectStyle } from "components/atoms/FormFields/SelectField";

const Select = styled.select<{ isSelected: boolean }>`
  ${selectStyle};
  ${p =>
    p.isSelected
      ? `
    ${fontSize.LARGE};
    font-weight: ${fontWeightsConst.BOLD};
  `
      : `
    ${fontSize.DEFAULT};
  `}
`;

const getPresentationOrderStopReasonText = (type: OrderStopReasonByShopType) => {
  switch (type) {
    case ORDER_STOP_REASON_BY_SHOP.NOT_SELECTED:
      return "停止させる理由を選んでください";
    case ORDER_STOP_REASON_BY_SHOP.SOLD_OUT:
      return "品切れ";
    case ORDER_STOP_REASON_BY_SHOP.BUSY:
      return "混雑中";
    case ORDER_STOP_REASON_BY_SHOP.TEMPORARY_CLOSED:
      return "臨時休業";
    case ORDER_STOP_REASON_BY_SHOP.CLOSED:
      return "営業終了";
    case ORDER_STOP_REASON_BY_SHOP.OTHER:
      return "その他";
    // skip default case
  }
};

type Props = {
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  isSelected: boolean;
};

export const OrderStopReasonField: React.FC<Props> = React.memo(({ handleChange, isSelected }) => (
  <Select onChange={handleChange} isSelected={isSelected}>
    {displayableOrderStopReasonByShop.map(type => (
      <option key={type} value={type}>
        {getPresentationOrderStopReasonText(type)}
      </option>
    ))}
  </Select>
));
