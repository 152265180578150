import { put, call } from "redux-saga/effects";
import { OrderNotification } from "records/OrderNotification";
import {
  userAccessedToPageThatNeedsOrderList,
  userAccessedToPageThatNeedsOrderDetail,
  UserTouchedOrderNotificationBackdropAction,
} from "../actions";
import { OrderModel, REG_EXP_ORDER_LIST_PATH } from "../model";
import { readNotificationSaga } from "./readNotificationSaga";

export function* userTouchedOrderNotificationBackdropSaga(
  action: UserTouchedOrderNotificationBackdropAction,
) {
  const { orderId, notifcationType } = action.payload;
  const pathName = window.location.pathname;
  const currentOrderDetailNo = OrderModel.getCurrentOrderDetailNo(pathName);
  // if (!OrderNotification.isNewOrder(notifcationType)) {
  yield call(readNotificationSaga, orderId, notifcationType);
  // }
  if (typeof currentOrderDetailNo !== "undefined" && currentOrderDetailNo === orderId) {
    yield put(userAccessedToPageThatNeedsOrderDetail(orderId));
  } else if (REG_EXP_ORDER_LIST_PATH.test(pathName)) {
    yield put(userAccessedToPageThatNeedsOrderList());
  }
}
