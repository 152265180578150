import { select, put, fork } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { loginTokenSelector } from "../selectors";
import { systemPreLoginSaga } from "./systemPreLoginSaga";

export function* userTouchedLoginButtonSaga() {
  const token: string | null = yield select(loginTokenSelector);
  if (token === null) {
    yield put(replace("/login"));
  } else {
    yield fork(systemPreLoginSaga);
  }
}
