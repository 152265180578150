import React, { useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { shopItemsSelector, optionSetListSelector } from "modules/shop/selectors";
import { colorsConst } from "styles/const";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
  ScrollContainer,
  ScrollInner,
} from "components/molecules/ModalDialog";
import { OrderUnRestartableItemByStoppedOptionSet } from "./OrderUnRestartableItemByStoppedOptionSet";

const contentStyle: React.CSSProperties = {
  flexGrow: 1,
  flexShrink: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
} as const;

const innerStyle: React.CSSProperties = {
  flexGrow: 1,
  flexShrink: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
  padding: 0,
  backgroundColor: colorsConst.BACKGROUND.SECONDARY,
} as const;

const BodyInner = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1em;
`;

const Caption = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.5em 1em 1em;
  text-align: left;
  white-space: pre-wrap;
`;

const CloseButtonContainer = styled.div`
  margin-top: 1em;
  text-align: center;
`;

const CloseButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  text-decoration: underline;
`;

const MODAL_KEY = "CONFIRM_ORDER_RESTART_BY_ITEMS";
const useModal = modalHooksFactory(MODAL_KEY);

type Props = {};

export const ConfirmOrderRestartByItemDialog: React.FC<Props> = React.memo(() => {
  const { data, close } = useModal();

  const shopItems = useSelector(shopItemsSelector);
  const shopItemOptionSets = useSelector(optionSetListSelector);

  const errorItemIds: {
    shop_item_id: number;
    option_set_ids: number[];
  }[] = useMemo(
    () => ("payload" in data && "errorIds" in data.payload ? data.payload.errorIds : []),
    [data],
  );

  return (
    <ModalDialog
      isOpen={data.isOpen}
      close={close}
      contentStyle={contentStyle}
      innerStyle={innerStyle}
    >
      <ModalDialogHeader handleClose={close}>商品の受付を再開する</ModalDialogHeader>
      <ModalBody>
        <BodyInner>
          <Caption>以下の商品に設定されている必須オプションが売り切れています。</Caption>
          <ScrollContainer>
            <ScrollInner>
              {errorItemIds.map(errorItemId => (
                <OrderUnRestartableItemByStoppedOptionSet
                  key={errorItemId.shop_item_id}
                  errorItemId={errorItemId.shop_item_id}
                  errorOptionSetIds={errorItemId.option_set_ids}
                  shopItems={shopItems}
                  shopItemOptionSets={shopItemOptionSets}
                />
              ))}
            </ScrollInner>
          </ScrollContainer>
        </BodyInner>
      </ModalBody>
      <ModalFooter>
        <CloseButtonContainer>
          <CloseButton onClick={close}>閉じる</CloseButton>
        </CloseButtonContainer>
      </ModalFooter>
    </ModalDialog>
  );
});
