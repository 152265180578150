import React, { useState, useMemo, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { serverDateTimeSelector } from "modules/app/selectors";
import { DateUtils } from "utils/DateUtils";
import { Button, ButtonInner, ButtonMainText, ButtonSubText } from "components/atoms/Button";
import { OrderDetailContext } from "../OrderDetailContext";

type Props = {};

export const FindingCrewButton: React.FC<Props> = React.memo(() => {
  const serverDateTime = useSelector(serverDateTimeSelector);

  const { orderData } = useContext(OrderDetailContext);

  const [now, setNow] = useState(new Date());
  const [addSecond, setAddSecond] = useState(1);

  const diff: number = useMemo(
    () => DateUtils.getPresentationDiffMinutesText(orderData.driver_search_expire_time, now) + 1,
    [orderData, now],
  );

  useEffect(() => {
    let timerId = 0;
    timerId = window.setInterval(() => {
      if (typeof serverDateTime !== "undefined") {
        setNow(DateUtils.getDateWithAddSeconds(serverDateTime, addSecond));
      }
      setAddSecond(addSecond + 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [serverDateTime, addSecond]);

  useEffect(() => {
    setAddSecond(1);
  }, [serverDateTime]);

  return (
    <Button disabled={true}>
      <ButtonInner>
        <ButtonMainText>{`配達員を探しています（残り${diff}分）`}</ButtonMainText>
        <ButtonSubText>find crew</ButtonSubText>
      </ButtonInner>
    </Button>
  );
});
