import * as React from "react";

type Props = React.SVGProps<SVGSVGElement> & {};

export const Logo: React.FC<Props> = React.memo(({ ...props }) => (
  <svg viewBox="0 0 331 157" fill="none" {...props}>
    <path
      d="M58.964 0.0262792C52.2809 0.0262792 46.2153 2.70629 41.7782 7.04334C37.3411 2.70629 31.2755 0.0262792 24.5966 0.0262792C11.0314 0.0262792 0 11.0507 0 24.6029V74.0647H14.8171V24.6029C14.8171 19.2175 19.2035 14.834 24.5923 14.834C29.9811 14.834 34.3675 19.2175 34.3675 24.6029V74.0647H49.1846V24.6029C49.1846 19.2175 53.571 14.834 58.9598 14.834C64.3486 14.834 68.735 19.2175 68.735 24.6029V74.0647H83.5521V24.6029C83.5606 11.0507 72.5249 0.0262792 58.964 0.0262792Z"
      fill="white"
    />
    <path
      d="M134.272 75.632C124.163 75.632 114.663 71.7008 107.514 64.5569C92.7607 49.8126 92.7607 25.8236 107.514 11.0793C114.663 3.93547 124.163 0 134.272 0C144.382 0 153.882 3.93547 161.026 11.0751L166.267 16.3125L124.091 58.4614C127.217 60.0043 130.681 60.8201 134.272 60.8201C140.423 60.8201 146.201 58.4276 150.549 54.0821C152.736 51.8966 154.428 49.3603 155.582 46.5366L169.3 52.1418C167.396 56.7959 164.613 60.9723 161.026 64.5569C153.882 71.6966 144.377 75.632 134.272 75.632ZM134.272 14.8119C128.122 14.8119 122.344 17.2045 117.996 21.55C110.852 28.6896 109.397 39.3885 113.622 47.9823L144.454 17.1707C141.324 15.6235 137.859 14.8119 134.272 14.8119Z"
      fill="white"
    />
    <path
      d="M215.045 0.0253662C196.104 0.0253662 180.691 15.4291 180.691 34.3582V74.0638H195.512V34.3582C195.512 23.5917 204.276 14.833 215.045 14.833C225.819 14.833 234.583 23.5917 234.583 34.3582V74.0638H249.405V34.3582C249.4 15.4248 233.987 0.0253662 215.045 0.0253662Z"
      fill="white"
    />
    <path
      d="M315.953 1.5894V41.295C315.953 52.0615 307.188 60.8201 296.415 60.8201C285.642 60.8201 276.882 52.0615 276.882 41.295V1.5894H262.06V41.295C262.06 60.2283 277.474 75.6278 296.415 75.6278C315.356 75.6278 330.77 60.2241 330.77 41.295V1.5894H315.953Z"
      fill="white"
    />
    <path
      d="M119.155 114.291C110.55 114.291 103.551 121.291 103.551 129.895C103.551 138.5 110.55 145.499 119.155 145.499C127.759 145.499 134.759 138.5 134.759 129.895C134.759 121.291 127.759 114.291 119.155 114.291ZM119.155 139.694C113.755 139.694 109.356 135.295 109.356 129.895C109.356 124.495 113.755 120.096 119.155 120.096C124.555 120.096 128.954 124.488 128.954 129.895C128.954 135.302 124.555 139.694 119.155 139.694ZM155.833 114.291C147.235 114.291 140.236 121.284 140.229 129.889V129.895V129.902V156.473H146.015V142.018C148.699 144.195 152.115 145.505 155.833 145.505C164.438 145.505 171.437 138.506 171.437 129.902C171.437 121.291 164.438 114.291 155.833 114.291ZM155.833 139.694C150.433 139.694 146.034 135.295 146.034 129.895C146.034 124.495 150.433 120.096 155.833 120.096C161.233 120.096 165.632 124.488 165.632 129.895C165.632 135.302 161.233 139.694 155.833 139.694ZM97.9841 127.256V144.991H92.1921V127.256C92.1921 123.448 89.0906 120.347 85.2828 120.347C81.52 120.347 78.4571 123.371 78.38 127.108V144.998H72.5752V105.43H78.38V116.603C80.3706 115.312 82.74 114.555 85.2828 114.555C92.2884 114.555 97.9841 120.257 97.9841 127.256Z"
      fill="white"
    />
    <path
      d="M201.379 114.292C192.774 114.292 185.775 121.291 185.775 129.895C185.775 138.5 192.774 145.499 201.379 145.499C209.983 145.499 216.982 138.5 216.982 129.895C216.989 121.291 209.983 114.292 201.379 114.292ZM201.379 139.694C195.978 139.694 191.58 135.296 191.58 129.895C191.58 124.495 195.978 120.096 201.379 120.096C206.779 120.096 211.177 124.489 211.177 129.895C211.177 135.302 206.785 139.694 201.379 139.694Z"
      fill="white"
    />
    <path
      d="M275.397 114.292C266.799 114.292 259.799 121.284 259.793 129.889V129.895V129.902V156.473H265.578V142.019C268.263 144.195 271.679 145.505 275.397 145.505C284.001 145.505 291 138.506 291 129.902C291 121.291 283.995 114.292 275.397 114.292ZM275.397 139.694C269.996 139.694 265.598 135.296 265.598 129.895C265.598 124.495 269.996 120.096 275.397 120.096C280.797 120.096 285.195 124.489 285.195 129.895C285.189 135.302 280.797 139.694 275.397 139.694Z"
      fill="white"
    />
    <path
      d="M238.802 114.292C230.204 114.292 223.205 121.284 223.198 129.889V129.895V129.902V156.473H228.984V142.019C231.668 144.195 235.084 145.505 238.802 145.505C247.406 145.505 254.405 138.506 254.405 129.902C254.405 121.291 247.406 114.292 238.802 114.292ZM238.802 139.694C233.402 139.694 229.003 135.296 229.003 129.895C229.003 124.495 233.402 120.096 238.802 120.096C244.202 120.096 248.601 124.489 248.601 129.895C248.601 135.302 244.202 139.694 238.802 139.694Z"
      fill="white"
    />
    <path d="M216.983 129.895H211.178V144.998H216.983V129.895Z" fill="white" />
    <path
      d="M65.5565 117.72H59.5912C59.2894 114.317 57.4144 111.357 54.7496 110.708C52.1811 110.086 49.7924 110.664 48.3219 112.089C47.179 113.2 46.646 114.825 46.9735 116.789C47.5642 120.366 51.5197 121.149 54.5891 122.363C59.9572 124.142 66.3143 127.558 66.0638 134.371C66.0125 135.706 65.8776 137.048 65.1905 138.641C64.3429 140.612 62.8596 142.346 60.6892 143.579C58.5638 144.786 55.9054 145.409 53.1507 145.422C51.1794 145.435 49.1567 145.178 47.2496 144.401C42.9987 142.686 40.1156 138.641 40 132.843H46.1965C46.601 136.374 47.6349 138.712 50.6914 139.572C54.878 140.753 60.503 138.846 59.7004 133.889C59.1931 130.749 55.4688 129.452 52.4893 128.277C50.2676 127.404 47.4936 126.518 45.2397 124.944C43.2877 123.583 41.7273 121.708 40.9889 119.371C40.4623 117.701 40.1862 114.15 41.5539 111.331C43.2299 107.89 46.967 105 52.9902 105C61.6524 105 65.3832 111.794 65.5565 117.72Z"
      fill="white"
    />
  </svg>
));
