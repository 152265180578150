import produce from "immer";
import { OrderData } from "records/OrderData";
import { OrderItemData, DraftItem } from "records/OrderItemData";
import { ShopItemParentCategory } from "records/ShopItemParentCategory";

export const SIMULATOR_SHOP_ITEM_PARENT_CATEGORIES: ShopItemParentCategory[] = [
  ShopItemParentCategory.create({
    parent_category_id: 1,
    parent_category_name: "カテゴリテスト1",
    parent_category_sort_order: 0,
  }),
  ShopItemParentCategory.create({
    parent_category_id: 2,
    parent_category_name: "カテゴリテスト2",
    parent_category_sort_order: 1,
  }),
];

export type SimulatorState = Readonly<{
  mount: boolean;
  // 注文シミュレーション用のorderList
  orderList: OrderData[];
  // 注文シミュレーション用のorderData
  orderData: OrderData;
  // 注文シミュレーション用の完了した注文
  finishedOrderList: OrderData[];
  // 注文詳細画面表示
  pageTransition: boolean;
  // 注文シミュレーション用の準備開始時間
  cookingStartDateTime: string;
}>;

const initialState: SimulatorState = {
  mount: false,
  orderList: [],
  orderData: OrderData.create(),
  finishedOrderList: [],
  pageTransition: false,
  cookingStartDateTime: "",
};

const updateMountSimulator = (state: SimulatorState, value: boolean) =>
  produce(state, draft => {
    draft.mount = value;
  });

const updateSimulatorOrderData = (state: SimulatorState, value: OrderData) =>
  produce(state, draft => {
    draft.orderList = [OrderData.create(value)];
    draft.orderData = OrderData.create(value);
  });

const updateSimulatorOrderList = (state: SimulatorState, value: OrderData[]) =>
  produce(state, draft => {
    draft.orderList = value;
  });

const updateSimulatorFinishedOrderList = (state: SimulatorState, value: OrderData[]) =>
  produce(state, draft => {
    draft.finishedOrderList = value;
  });

const updateSimulatorPageTransition = (state: SimulatorState, value: boolean) =>
  produce(state, draft => {
    draft.pageTransition = value;
  });

const updateSimulatorCookingStartDateTime = (state: SimulatorState, value: string) =>
  produce(state, draft => {
    draft.cookingStartDateTime = value;
  });

const getUpdatedOrderItems = (orderData: OrderData, draftItems: DraftItem[]) =>
  orderData.item_list.reduce((acc, crr) => {
    if (draftItems.some(d => d.order_shop_item_id_group === crr.order_shop_item_id_group)) {
      const targetItem = draftItems.find(
        d => d.order_shop_item_id_group === crr.order_shop_item_id_group,
      );
      if (typeof targetItem !== "undefined" && targetItem.total_order_count > 0) {
        acc.push({
          ...crr,
          order_count: targetItem.order_count,
          free_order_count: targetItem.free_order_count,
          total_order_count: targetItem.total_order_count,
        });
      }
    } else {
      acc.push(crr);
    }
    return acc;
  }, [] as OrderItemData[]);

export const SimulatorModel = {
  initialState,
  updateMountSimulator,
  updateSimulatorOrderData,
  updateSimulatorOrderList,
  updateSimulatorFinishedOrderList,
  updateSimulatorPageTransition,
  updateSimulatorCookingStartDateTime,
  getUpdatedOrderItems,
};
