import React from "react";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { ShopItemSubmitType, SHOP_ITEM_SUBMIT_TYPE } from "modules/shop/model";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";

const Container = styled.div`
  position: relative;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: -1em;
  padding: 0.5em 1em 1em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
`;

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 0.5em;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  width: 50%;
  & + & {
    margin-left: 1em;
  }
`;

type Props = {
  disabled: boolean;
  handleClick: (submitType: ShopItemSubmitType) => void;
};

export const BulkUpdateOptionStatusButtonContainer: React.FC<Props> = React.memo(
  ({ disabled, handleClick }) => (
    <Container>
      <TextContainer>
        <Text size="SMALL">表示されている全てのオプションの受付設定を変更できます。</Text>
      </TextContainer>
      <Row>
        <ButtonContainer>
          <Button
            appearance="primary"
            disabled={disabled}
            handleClick={() => handleClick(SHOP_ITEM_SUBMIT_TYPE.STOP)}
          >
            本日売り切れ
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button
            appearance="primary"
            disabled={disabled}
            handleClick={() => handleClick(SHOP_ITEM_SUBMIT_TYPE.SUSPENSION)}
          >
            再販予定なし
          </Button>
        </ButtonContainer>
      </Row>
    </Container>
  ),
);
