import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Utility } from "../../utils/Utility";

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 60%;
`;

const mapStyle: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
} as const;

type MapProps = google.maps.MapOptions & {
  children: React.ReactElement<google.maps.MarkerOptions>;
  style?: React.CSSProperties;
};

const Map: React.FC<MapProps> = React.memo(({ children, style, ...options }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map, options]);
  useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
        return undefined;
      })}
    </>
  );
});

const Marker: React.FC<google.maps.MarkerOptions> = React.memo(options => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }
    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
});

type Props = {
  latitude: number;
  longitude: number;
};

export const MapContainer: React.FC<Props> = React.memo(({ latitude, longitude }) => (
  <Container>
    <Wrapper apiKey={Utility.getGoogleMapsApiKey()}>
      <Map
        center={{ lat: latitude, lng: longitude }}
        zoom={18}
        draggable={false}
        disableDefaultUI={true}
        style={mapStyle}
      >
        <Marker title="店舗所在地" position={{ lat: latitude, lng: longitude }} />
      </Map>
    </Wrapper>
  </Container>
));
