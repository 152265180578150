import { Cordova } from "../@types/Cordova";

declare const cordova: Cordova;

export type PlatformId = "ios" | "android" | "browser";

export const CordovaUtil = {
  isCordovaEnabled() {
    return "cordova" in window;
  },
  initialize(callback: () => void) {
    document.addEventListener("deviceready", callback, false);
  },
  getPlatformId(): PlatformId {
    return CordovaUtil.isCordovaEnabled() ? cordova.platformId : "browser";
  },
  getAppVersion(): Promise<string | null> {
    if (!("cordova" in window && "getAppVersion" in cordova)) {
      return Promise.resolve(null);
    }
    return Promise.race<string | null>([
      new Promise<string | null>((resolve, reject) => {
        cordova.getAppVersion
          .getVersionNumber()
          .then((version: string) => resolve(version))
          .catch(_ => resolve(null));
      }),
      new Promise<null>(res => {
        setTimeout(() => res(null), 5000);
      }),
    ]);
  },
};
