export type MonthlySalesList = Readonly<{ [yearMonth: string]: number }>;
export type MonthlySalesDevidedByYear = Readonly<{ [year: string]: MonthlySalesList }>;

export type MonthlySales = Readonly<{
  sales_list: MonthlySalesList;
}>;

const initialState: MonthlySales = {
  sales_list: {},
};

const create = (args: Partial<MonthlySales> = {}) => ({
  ...initialState,
  ...args,
});

const existsMonthlySales = (monthlySales: MonthlySales) =>
  Object.keys(monthlySales.sales_list).length > 0;

const getMonthlySalesDevidedByYear = (salesList: MonthlySalesList): MonthlySalesDevidedByYear =>
  Object.keys(salesList).reduce((acc: MonthlySalesDevidedByYear, crr: string) => {
    const targetYear = crr.slice(0, 4);
    if (typeof acc[targetYear] !== "undefined") {
      return Object.assign(acc, {
        ...acc,
        [targetYear]: Object.assign(acc[targetYear], {
          ...acc[targetYear],
          [crr]: salesList[crr],
        }),
      });
    }
    return Object.assign(acc, {
      ...acc,
      [targetYear]: Object.assign(
        {},
        {
          [crr]: salesList[crr],
        },
      ),
    });
  }, {});

const sortMonthlySalesByYear = (yearA: string, yearB: string) => {
  const [a, b] = [parseInt(yearA, 10), parseInt(yearB, 10)];
  return a === b ? 0 : a < b ? 1 : -1;
};

export const MonthlySales = Object.freeze({
  initialState,
  create,
  existsMonthlySales,
  getMonthlySalesDevidedByYear,
  sortMonthlySalesByYear,
});
