import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { API_KEY } from "apis";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { userAccessedToPageThatNeedsSales } from "modules/sales/actions";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Contents } from "components/atoms/Contents";
import { Article } from "components/organisms/Article";
import { SalesDetail } from "components/organisms/SalesDetail";

const Container = styled.div``;

const isConnectedApiSelector = isConnectedApiSelectorFactory([API_KEY.GET_SALES]);

type Props = {};

export const SalesTemplate: React.FC<Props> = React.memo(() => {
  const loading = useSelector(isConnectedApiSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsSales());
  }, [dispatch]);

  return (
    <Article withHeader title="売上管理" goBack="/setting" withNavigation activePath="SETTING">
      {loading ? (
        <LoadingContainer />
      ) : (
        <Contents>
          <SalesDetail />
        </Contents>
      )}
    </Article>
  );
});
