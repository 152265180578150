import { DateUtils } from "utils/DateUtils";
import { OrderItemData, DraftItem } from "./OrderItemData";
import { OrderUserInfo } from "./OrderUserInfo";
import { ShopStaff } from "./ShopStaff";

/**
 * いますぐ受け取り or 予約注文
 */
export const ORDER_TIME_TYPE = {
  FASTEST: 1,
  SPECIFIED: 2,
} as const;

export type OrderTimeType = ValueOf<typeof ORDER_TIME_TYPE>;

/**
 * 注文状態タイプ
 */
export const ORDER_STATE_TYPE = {
  PENDING: 1, // 注文発生
  APPROVE: 2, // 注文受付
  DENY: 3, // 店舗キャンセル
  FINISH: 4, // 準備完了
  CANCEL: 5, // ユーザーキャンセル
  AUTO_CANCEL: 6, // 自動キャンセル
  PAYMENT_CANCEL: 7, // 決済キャンセル
  HOLD: 8, // 保留
  LOCAL_AREA_SHOP_APPROVE: 30, // 注文受付（ローカルエリア）
  LOCAL_AREA_DRIVER_APPROVE: 31, // ドライバー確定（ローカルエリア）
  ONE_STORE_APPROVE: 40, // 一部の店が承認
  ALL_STORES_APPROVE: 41, // 全ての店が承認
  OTHER_STORE_CANCEL: 42, // 他店キャンセルによるコンボ注文キャンセル
  AUTO_CANCEL_ERROR: 90, // 自動キャンセルエラー
  LOCAL_AREA_DRIVER_NOT_FOUND_CANCEL: 91, // ドライバー不在キャンセル（ローカルエリア）
  ERROR: 99, // エラー
} as const;

export type OrderStateType = ValueOf<typeof ORDER_STATE_TYPE>;

/**
 * 配達状態タイプ
 */
export const DELIVERY_STATE_TYPE = {
  ON_PREPARING: 21, // 準備中
  ON_DELIVERY: 24, // 配達中
  DELIVERED: 26, // 配達済み
  CREW_UNMATCH_CANCEL: 92, // 準備完了後のドライバー不在キャンセル
};

export type DeliveryStateType = ValueOf<typeof DELIVERY_STATE_TYPE>;

/**
 * 支払いの種類
 */
export const ORDER_PAYMENT_TYPE = {
  CARD: 1,
  LOCAL: 2,
  INVOICE: 3,
} as const;

export type OrderPaymentType = ValueOf<typeof ORDER_PAYMENT_TYPE>;

/**
 * 受け取りタイプ
 */
export const ORDER_RECEIVE_TYPE = {
  TAKEOUT: 1,
  DELIVERY: 2,
} as const;

export type OrderReceiveType = ValueOf<typeof ORDER_RECEIVE_TYPE>;

export const MOCK_ORDER_DATA: OrderData = {
  order_no: "AB123C",
  display_order_no: "DE456F",
  user_info: OrderUserInfo.create({
    nick_name: "メニュー太郎",
    tel: "09000000000",
    is_menu_pass_member: true,
  }),
  time_type: ORDER_TIME_TYPE.FASTEST,
  receive_datetime: "2022-03-01 23:59:59",
  order_state: ORDER_STATE_TYPE.PENDING,
  discount_price: 0,
  shop_support_price: 0,
  total_price: 4830,
  user_payment_price: 4830,
  order_date: "2022-03-01 22:59:59",
  is_today_receive: true,
  cooking_start_able_flag: false,
  payment_type: ORDER_PAYMENT_TYPE.CARD,
  payment_flag: true,
  receive_type: ORDER_RECEIVE_TYPE.DELIVERY,
  local_area_flag: false,
  driver_search_expire_time: "",
  item_list: [
    {
      shop_item_id: 3093,
      order_shop_item_id_group: 1,
      total_order_count: 2,
      order_count: 2,
      free_order_count: 0,
      unit_price: 1480,
      image_path: "/image/2/kuriya_1.jpg",
      item_name: "テスト商品1",
      instruction_text: "お箸はいらないです。",
      supplement_text: "",
      option_set_list: [
        {
          option_set_name: "ライス",
          option_list: [
            {
              option_name: "大ライス",
              price: 50,
            },
          ],
        },
        {
          option_set_name: "ソース",
          option_list: [
            {
              option_name: "サルサソース",
              price: 120,
            },
          ],
        },
      ],
      is_campaign: false,
      pick_count: 2,
      is_picked: false,
      is_sell_by_weight: false,
      unit: "",
      input_min_quantity: 0,
      input_max_quantity: 0,
      user_display_quantity: 0,
      shop_item_parent_category_id: 1,
      shop_item_parent_category_group_order: 1,
      is_substitute_specified: true,
      is_substitute_item: false,
      can_order_max_count: 0,
    },
    {
      shop_item_id: 3093,
      order_shop_item_id_group: 2,
      total_order_count: 2,
      order_count: 1,
      free_order_count: 1,
      unit_price: 1480,
      image_path: "/image/2/kuriya_1.jpg",
      item_name: "テスト商品2",
      instruction_text: "",
      supplement_text: "",
      option_set_list: [
        {
          option_set_name: "ライス",
          option_list: [
            {
              option_name: "大ライス",
              price: 50,
            },
          ],
        },
      ],
      is_campaign: false,
      pick_count: 1,
      is_picked: false,
      is_sell_by_weight: false,
      unit: "",
      input_min_quantity: 0,
      input_max_quantity: 0,
      user_display_quantity: 0,
      shop_item_parent_category_id: 2,
      shop_item_parent_category_group_order: 1,
      is_substitute_specified: false,
      is_substitute_item: false,
      can_order_max_count: 0,
    },
  ],
  is_allowed_out_of_stock: true,
  is_combo: false,
  is_driver_accepted: false,
  driver_name: "メニュー次郎",
  is_near: false,
  is_un_match_cancel: false,
  user_order_count: 0,
  cooking_start_datetime: "",
  staff: ShopStaff.create(),
  delivery_state: DELIVERY_STATE_TYPE.ON_PREPARING,
  substitute_item_list: {
    "1": {
      shop_item_id: 3093,
      order_shop_item_id_group: 3,
      total_order_count: 0,
      order_count: 0,
      free_order_count: 0,
      unit_price: 1480,
      image_path: "/image/2/kuriya_1.jpg",
      item_name: "テスト商品1",
      instruction_text: "お箸はいらないです。",
      supplement_text: "",
      option_set_list: [
        {
          option_set_name: "ライス",
          option_list: [
            {
              option_name: "大ライス",
              price: 50,
            },
          ],
        },
        {
          option_set_name: "ソース",
          option_list: [
            {
              option_name: "サルサソース",
              price: 120,
            },
          ],
        },
      ],
      is_campaign: false,
      pick_count: 0,
      is_picked: false,
      is_sell_by_weight: false,
      unit: "",
      input_min_quantity: 0,
      input_max_quantity: 0,
      user_display_quantity: 0,
      shop_item_parent_category_id: 1,
      shop_item_parent_category_group_order: 1,
      is_substitute_specified: false,
      is_substitute_item: true,
      can_order_max_count: 2,
    },
  },
  can_deny_after_approve_flag: false,
  order_finish_datetime: "",
  is_operable: true,
  payment_cancel_item_list: [],
  payment_cancel_price: 0,
};

export type OrderData = Readonly<{
  order_no: string;
  display_order_no: string;
  user_info: OrderUserInfo;
  time_type: OrderTimeType;
  receive_datetime: string;
  order_state: OrderStateType;
  discount_price: number;
  shop_support_price: number;
  total_price: number;
  user_payment_price: number;
  order_date: string;
  is_today_receive: boolean;
  cooking_start_able_flag: boolean;
  payment_type: OrderPaymentType;
  payment_flag: boolean;
  receive_type: OrderReceiveType;
  local_area_flag: boolean;
  is_allowed_out_of_stock: boolean;
  item_list: OrderItemData[];
  driver_search_expire_time: string;
  is_combo: boolean;
  is_driver_accepted: boolean;
  driver_name: string;
  is_near: boolean;
  is_un_match_cancel: boolean;
  user_order_count: number | undefined;
  cooking_start_datetime: string;
  staff: ShopStaff;
  delivery_state: DeliveryStateType;
  substitute_item_list: { [order_shop_item_id_group: string]: OrderItemData };
  can_deny_after_approve_flag: boolean;
  order_finish_datetime: string;
  is_operable: boolean;
  payment_cancel_item_list: OrderItemData[];
  payment_cancel_price: number;
}>;

const initialState: OrderData = {
  order_no: "",
  display_order_no: "",
  user_info: OrderUserInfo.create(),
  time_type: ORDER_TIME_TYPE.FASTEST,
  receive_datetime: "",
  order_state: ORDER_STATE_TYPE.PENDING,
  discount_price: 0,
  shop_support_price: 0,
  total_price: 0,
  user_payment_price: 0,
  order_date: "",
  is_today_receive: false,
  cooking_start_able_flag: false,
  payment_type: ORDER_PAYMENT_TYPE.CARD,
  payment_flag: false,
  receive_type: ORDER_RECEIVE_TYPE.TAKEOUT,
  local_area_flag: false,
  driver_search_expire_time: "",
  item_list: [],
  is_allowed_out_of_stock: false,
  is_combo: false,
  is_driver_accepted: false,
  driver_name: "",
  is_near: false,
  is_un_match_cancel: false,
  user_order_count: undefined,
  cooking_start_datetime: "",
  staff: ShopStaff.create(),
  delivery_state: DELIVERY_STATE_TYPE.ON_PREPARING,
  substitute_item_list: {},
  can_deny_after_approve_flag: false,
  order_finish_datetime: "",
  is_operable: true,
  payment_cancel_item_list: [],
  payment_cancel_price: 0,
};

const create = (args: Partial<OrderData> = {}) => ({
  ...initialState,
  ...args,
  user_info: args.user_info ? OrderUserInfo.create(args.user_info) : OrderUserInfo.create(),
  item_list: args.item_list ? args.item_list.map(i => OrderItemData.create(i)) : [],
  staff: args.staff ? ShopStaff.create(args.staff) : ShopStaff.create(),
  payment_cancel_item_list: args.payment_cancel_item_list
    ? args.payment_cancel_item_list.map(i => OrderItemData.create(i))
    : [],
});

const existsOrder = (orderData: OrderData) => orderData.order_no !== "";

const isPendingOrder = (orderState: OrderStateType) => orderState === ORDER_STATE_TYPE.PENDING;

const isHoldOrder = (orderState: OrderStateType) => orderState === ORDER_STATE_TYPE.HOLD;

const isUnapprovedOrder = (orderState: OrderStateType) =>
  ([ORDER_STATE_TYPE.PENDING, ORDER_STATE_TYPE.HOLD] as Array<OrderStateType>).includes(orderState);

const isContractedOrder = (orderState: OrderStateType) =>
  (
    [
      ORDER_STATE_TYPE.PENDING,
      ORDER_STATE_TYPE.HOLD,
      ORDER_STATE_TYPE.LOCAL_AREA_SHOP_APPROVE,
      ORDER_STATE_TYPE.ONE_STORE_APPROVE,
    ] as Array<OrderStateType>
  ).includes(orderState);

const isReservedOrder = (timeType: OrderTimeType) => timeType === ORDER_TIME_TYPE.SPECIFIED;

const isDeliveryOrder = (receiveType: OrderReceiveType) =>
  receiveType === ORDER_RECEIVE_TYPE.DELIVERY;

const isFinishStatus = (orderState: OrderStateType) => orderState === ORDER_STATE_TYPE.FINISH;

const isNotFinishedOrder = (orderData: OrderData) =>
  (
    [
      ORDER_STATE_TYPE.HOLD,
      ORDER_STATE_TYPE.APPROVE,
      ORDER_STATE_TYPE.LOCAL_AREA_DRIVER_APPROVE,
      ORDER_STATE_TYPE.ALL_STORES_APPROVE,
    ] as Array<OrderStateType>
  ).includes(orderData.order_state) && !DateUtils.isAfter(orderData.receive_datetime);

const isCancelStatus = (order_state: OrderStateType) =>
  (
    [
      ORDER_STATE_TYPE.DENY,
      ORDER_STATE_TYPE.CANCEL,
      ORDER_STATE_TYPE.AUTO_CANCEL,
      ORDER_STATE_TYPE.PAYMENT_CANCEL,
      ORDER_STATE_TYPE.OTHER_STORE_CANCEL,
      ORDER_STATE_TYPE.LOCAL_AREA_DRIVER_NOT_FOUND_CANCEL,
    ] as Array<OrderStateType>
  ).includes(order_state);

const existsShopSupportPrice = (shopSupportPrice: number) => shopSupportPrice > 0;

const existsDriver = (driverName: string) => driverName !== "";

const existsPicker = (orderData: OrderData) => ShopStaff.existsShopStaff(orderData.staff);

const isOwnAssignedOrder = (orderData: OrderData, staffId: number) =>
  orderData.staff.staff_id === staffId;

const canDisplayUpdateOrderStatusButton = (orderState: OrderStateType) =>
  !isFinishStatus(orderState) && !isCancelStatus(orderState);

const canDisplayCookingStartTime = (orderData: OrderData) =>
  orderData.time_type === ORDER_TIME_TYPE.SPECIFIED &&
  orderData.order_state === ORDER_STATE_TYPE.APPROVE &&
  orderData.cooking_start_datetime !== "" &&
  !orderData.cooking_start_able_flag;

const canDisplayPickerInfo = (isGrocery: boolean, orderData: OrderData) =>
  isGrocery && existsPicker(orderData);

const getPresentationReceiveTimeLabelText = (orderData: OrderData) => {
  if (orderData.receive_type === ORDER_RECEIVE_TYPE.TAKEOUT) {
    return "ご来店予定";
  }
  return orderData.delivery_state === DELIVERY_STATE_TYPE.DELIVERED
    ? "配達完了時間"
    : "準備完了目安";
};

const getPresentationReceiveTypeText = (receiveType: OrderReceiveType, timeType?: OrderTimeType) =>
  `${isDeliveryOrder(receiveType) ? "配達" : "持ち帰り"}${
    typeof timeType !== "undefined" && isReservedOrder(timeType) ? "（予約）" : ""
  }`;

const getPresentationOrderStateText = ({
  orderData,
  isGrocery = false,
  useLinefeed = false,
}: {
  orderData: OrderData;
  isGrocery?: boolean;
  useLinefeed?: boolean;
}) => {
  switch (orderData.order_state) {
    case ORDER_STATE_TYPE.PENDING:
      return "受付待ち";
    case ORDER_STATE_TYPE.HOLD:
      return isGrocery ? "商品準備中" : "保留中";
    case ORDER_STATE_TYPE.LOCAL_AREA_SHOP_APPROVE:
      return useLinefeed ? "配達員\n探し中" : "配達員探し中";
    case ORDER_STATE_TYPE.ONE_STORE_APPROVE:
      return useLinefeed ? "他店舗の\n受付待ち" : "他店舗の受付待ち";
    case ORDER_STATE_TYPE.APPROVE:
      return OrderData.isReservedOrder(orderData.time_type) && !orderData.cooking_start_able_flag
        ? "予約"
        : "受付済";
    case ORDER_STATE_TYPE.LOCAL_AREA_DRIVER_APPROVE:
    case ORDER_STATE_TYPE.ALL_STORES_APPROVE:
      return "受付済";
    case ORDER_STATE_TYPE.FINISH:
      if (orderData.delivery_state === DELIVERY_STATE_TYPE.ON_DELIVERY) {
        return "配達中";
      }
      if (orderData.delivery_state === DELIVERY_STATE_TYPE.DELIVERED) {
        return "配達完了";
      }
      return "準備完了";
    case ORDER_STATE_TYPE.AUTO_CANCEL:
      return useLinefeed ? "自動\nキャンセル" : "自動キャンセル";
    case ORDER_STATE_TYPE.DENY:
    case ORDER_STATE_TYPE.CANCEL:
    case ORDER_STATE_TYPE.PAYMENT_CANCEL:
    case ORDER_STATE_TYPE.OTHER_STORE_CANCEL:
    case ORDER_STATE_TYPE.LOCAL_AREA_DRIVER_NOT_FOUND_CANCEL:
      return orderData.delivery_state === DELIVERY_STATE_TYPE.CREW_UNMATCH_CANCEL
        ? useLinefeed
          ? "クルー\nアンマッチ"
          : "クルーアンマッチ"
        : "キャンセル";
    case ORDER_STATE_TYPE.AUTO_CANCEL_ERROR:
    case ORDER_STATE_TYPE.ERROR:
      return "エラー";
    default:
      return "";
  }
};

const getOrderStatusIcon = (orderData: OrderData) => {
  switch (orderData.order_state) {
    default:
      return undefined;
  }
};

const getUpdatedDraftItems = (
  item: DraftItem,
  updatedCount: number,
  isBuyOne: boolean,
  draftItems: DraftItem[],
  minCount: number = 1,
  campaignOrderCount: number = 1,
) => {
  /**
   * 複数行に分かれた同一商品の総数を算出（新buy1の最低数量のチェックのため）
   * 代替商品は考慮しない
   */
  const updatedTargetItemTotalCount = draftItems.reduce((acc, crr) => {
    // 商品IDが異なる、またはis_campaign(新buy1対象)の商品は加算せずスキップ
    if (crr.shop_item_id !== item.shop_item_id || crr.is_campaign) {
      return acc;
    }
    // 注文グループIDが一致した場合、変更後の値を加算
    if (crr.order_shop_item_id_group === item.order_shop_item_id_group) {
      return acc + updatedCount;
    }
    // 注文グループIDが一致しない同一商品の場合、現在の値を加算
    return acc + crr.pick_count;
  }, 0);

  // 数量変更後のdraftItemsの算出
  const updatedDraftItemCounts = draftItems.map(i => {
    // 代替商品の注文グループIDと一致した場合
    if (i.substituteItem?.order_shop_item_id_group === item.order_shop_item_id_group) {
      return {
        ...i,
        substituteItem: {
          ...i.substituteItem,
          pick_count: updatedCount,
        },
      };
    }
    // 注文グループIDと一致した場合
    if (i.order_shop_item_id_group === item.order_shop_item_id_group) {
      return {
        ...i,
        pick_count: updatedCount,
        free_order_count: isBuyOne ? updatedCount : 0,
        total_order_count: isBuyOne ? updatedCount * 2 : updatedCount,
      };
    }
    /**
     * 商品IDが一致、かつis_campaign(新buy1対象)の場合
     * updatedTargetItemTotalCountが最低数量未満の場合数量を0に、最低数量を満たしている場合規定の数量に変更
     */
    if (i.shop_item_id === item.shop_item_id && i.is_campaign) {
      return updatedTargetItemTotalCount < minCount
        ? {
            ...i,
            pick_count: 0,
            total_order_count: 0,
          }
        : {
            ...i,
            pick_count: campaignOrderCount,
            total_order_count: campaignOrderCount,
          };
    }
    return i;
  });
  return updatedDraftItemCounts;
};

const getSplittedPendingOrderList = (orderList: OrderData[]) =>
  orderList.reduce(
    (
      acc: {
        approved: OrderData[];
        unapproved: OrderData[];
      },
      crr: OrderData,
    ) => {
      if (
        crr.time_type === ORDER_TIME_TYPE.SPECIFIED &&
        !crr.cooking_start_able_flag &&
        crr.order_state === ORDER_STATE_TYPE.APPROVE
      ) {
        return Object.assign(acc, {
          ...acc,
          approved: acc.approved.concat(crr),
        });
      }
      return Object.assign(acc, {
        ...acc,
        unapproved: acc.unapproved.concat(crr),
      });
    },
    {
      approved: [],
      unapproved: [],
    },
  );

export const OrderData = Object.freeze({
  initialState,
  create,
  existsOrder,
  isPendingOrder,
  isHoldOrder,
  isUnapprovedOrder,
  isContractedOrder,
  isReservedOrder,
  isDeliveryOrder,
  isFinishStatus,
  isNotFinishedOrder,
  isCancelStatus,
  existsShopSupportPrice,
  existsDriver,
  existsPicker,
  isOwnAssignedOrder,
  canDisplayUpdateOrderStatusButton,
  canDisplayCookingStartTime,
  canDisplayPickerInfo,
  getPresentationReceiveTimeLabelText,
  getPresentationReceiveTypeText,
  getPresentationOrderStateText,
  getOrderStatusIcon,
  getUpdatedDraftItems,
  getSplittedPendingOrderList,
});
