import React from "react";
import { Button, ButtonInner, ButtonMainText, ButtonSubText } from "components/atoms/Button";

type Props = {
  submitting?: boolean;
  disabled?: boolean;
  handleClick?: () => void;
};

export const FinishOrderButton: React.FC<Props> = React.memo(
  ({ submitting, disabled = false, handleClick }) => (
    <Button appearance="primary" handleClick={handleClick} loading={submitting} disabled={disabled}>
      <ButtonInner>
        <ButtonMainText>商品準備完了</ButtonMainText>
        <ButtonSubText>ready</ButtonSubText>
      </ButtonInner>
    </Button>
  ),
);
