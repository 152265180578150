import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { systemOpenedModal } from "modules/app/actions";
import { Button } from "components/atoms/Button";

const Container = styled.div`
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid ${colorsConst.BORDER.THIN};
`;

type Props = {};

export const CancelButtonByRequiredItemShortage: React.FC<Props> = React.memo(() => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(systemOpenedModal("CANCEL_ORDER_BY_REQUIRED_ITEM_SHORTAGE", {}));
  }, [dispatch]);

  return (
    <Container>
      <Button appearance="secondary" handleClick={handleClick}>
        必須商品不足によるキャンセル
      </Button>
    </Container>
  );
});
