import { fork, call, put } from "redux-saga/effects";
import {
  API_KEY,
  ApproveOrderRequest,
  ApproveOrderResponse,
  UpdateOrderItemsCountResponse,
  SetShopStoppedItemReasonsResponse,
} from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { REASON_FOR_ITEMS_STOP_VIEW } from "records/ShopItem";
import { userSubmittedUpdateShopItemStoppedReasons } from "modules/shop/actions";
import {
  updateOrderDetail,
  UserSubmittedApproveOrderForGroceryAction,
  userSubmittedFinishOrder,
} from "../actions";
import { updateOrderItemsCountSaga } from "./updateOrderItemsCountSaga";

export function* userSubmittedApproveOrderForGrocerySaga(
  action: UserSubmittedApproveOrderForGroceryAction,
) {
  try {
    const { orderData, draftItems, add_minutes, shopItemStopReasons } = action.payload;
    const updateOrderItemsCountResult: {
      result: UpdateOrderItemsCountResponse;
      error: unknown;
    } = yield call(updateOrderItemsCountSaga, orderData.order_no, draftItems);
    if (updateOrderItemsCountResult.result && !updateOrderItemsCountResult.error) {
      yield put(updateOrderDetail(updateOrderItemsCountResult.result.order_data));
    } else {
      throw updateOrderItemsCountResult.error;
    }
    const params: ApproveOrderRequest = {
      order_no: orderData.order_no,
      add_minutes,
      add_cooking_start_remind_minutes: 0,
    };
    const { result, error }: { result: ApproveOrderResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.APPROVE_ORDER,
      params,
    );

    if (shopItemStopReasons && shopItemStopReasons?.length > 0) {
      yield put(
        userSubmittedUpdateShopItemStoppedReasons(
          shopItemStopReasons,
          REASON_FOR_ITEMS_STOP_VIEW.ORDER_DETAIL,
        ),
      );
    }

    if (result && !error) {
      yield put(userSubmittedFinishOrder(orderData));
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
