import React, { useContext } from "react";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import { OrderDetailContext } from "./OrderDetailContext";

const Container = styled.div`
  padding: 1em;
`;

const Title = styled.div`
  margin-bottom: 0.5em;
`;

const TextContainer = styled.div`
  margin-bottom: 0.5em;
`;

const List = styled.div`
  margin-bottom: 1em;
  padding: 0.5em 1em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const buttonStyle: React.CSSProperties = {
  width: "auto",
  paddingRight: "2em",
  paddingLeft: "2em",
} as const;

type Props = {};

export const HoldOrder: React.FC<Props> = React.memo(() => {
  const { submitting, handleClickHoldOrderButton } = useContext(OrderDetailContext);
  return (
    <Container>
      <Title>
        <Text weight="BOLD">対応できない商品がある場合</Text>
      </Title>
      <TextContainer>
        <Text size="SMALL">
          以下のような事象が発生して対応が遅れる場合は、お客様に連絡してください。
        </Text>
      </TextContainer>
      <List>
        <Text size="SMALL">・注文された商品が品切れ</Text>
        <br />
        <Text size="SMALL">・必要なカトラリーが不足している</Text>
        <br />
        <Text size="SMALL">・準備が滞っていて対応が遅れる</Text>
      </List>
      <ButtonContainer>
        <Button
          invert
          appearance="primary"
          style={buttonStyle}
          handleClick={handleClickHoldOrderButton}
          loading={submitting}
        >
          お客様に連絡する
        </Button>
      </ButtonContainer>
    </Container>
  );
});
