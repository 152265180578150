import { put, fork, call, all } from "redux-saga/effects";
import { API_KEY, SetDisplayImagesResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateDisplayImages, UserSubmittedUpdateDisplayImagesAction } from "../actions";

export function* userSubmittedUpdateDisplayImagesSaga(
  action: UserSubmittedUpdateDisplayImagesAction,
) {
  try {
    const { enable } = action.payload;
    const params = {
      enable,
    };
    const { result, error }: { result: SetDisplayImagesResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.SET_DISPLAY_IMAGES,
      params,
    );
    if (result && !error) {
      yield put(updateDisplayImages(result.enable));
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
