import { OrderNotification } from "./OrderNotification";

export type OrderNotificationStack = Readonly<{
  notifications: OrderNotification[];
  isDebug: boolean;
  isSimulator: boolean;
}>;

const initialState: OrderNotificationStack = {
  notifications: [],
  isDebug: false,
  isSimulator: false,
};

const create = (args: Partial<OrderNotificationStack> = {}): OrderNotificationStack => ({
  ...initialState,
  ...args,
  notifications: args.notifications ? args.notifications.map(i => OrderNotification.create(i)) : [],
});

export const OrderNotificationStack = Object.freeze({
  initialState,
  create,
});
