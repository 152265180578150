import { fork, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import {
  userTouchedChangeSimulatorPageSaga,
  userTouchedSimulatorApproveOrderButtonSaga,
  userTouchedSimulatorApproveOrderWithUpdateCookingTimeButtonSaga,
  userTouchedSimulatorHoldOrderButtonSaga,
  userTouchedSimulatorFinishOrderButtonSaga,
  userTouchedSimulatorFindCrewForLocalAreaButtonSaga,
  userTouchedSimulatorCancelOrderButtonSaga,
  userTouchedSimulatorCloseFindingCrewDialogButtonSaga,
  userTouchedSimulatorHoldOrderForGroceryButtonSaga,
  userTouchedSimulatorApproveOrderForGroceryButtonSaga,
  systemMountSimulatorSaga,
  systemUnmountSimulatorSaga,
  systemGenerateSimulatorOrderDataSaga,
} from "./sagas";

export function* simulatorSaga() {
  yield fork(watchUserTouchedChangeSimulatorPageSaga);
  yield fork(watchUserTouchedSimulatorApproveOrderButtonSaga);
  yield fork(watchUserTouchedSimulatorApproveOrderWithUpdateCookingTimeButtonSaga);
  yield fork(watchUserTouchedSimulatorHoldOrderButtonSaga);
  yield fork(watchUserTouchedSimulatorFinishOrderButtonSaga);
  yield fork(watchUserTouchedSimulatorFindCrewForLocalAreaButtonSaga);
  yield fork(watchUserTouchedSimulatorCancelOrderButtonSaga);
  yield fork(watchUserTouchedSimulatorCloseFindingCrewDialogButtonSaga);
  yield fork(watchUserTouchedSimulatorHoldOrderForGroceryButtonSaga);
  yield fork(watchUserTouchedSimulatorApproveOrderForGroceryButtonSaga);
  yield fork(watchSystemMountSimulatorSaga);
  yield fork(watchSystemUnmountSimulatorSaga);
  yield fork(watchSystemGenerateSimulatorOrderDataSaga);
}

function* watchUserTouchedChangeSimulatorPageSaga() {
  yield takeLatest(ActionTypes.U_TOUCHED_CHANGE_PAGE_BUTTON, userTouchedChangeSimulatorPageSaga);
}

function* watchUserTouchedSimulatorApproveOrderButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SIMULATOR_APPROVE_ORDER_BUTTON,
    userTouchedSimulatorApproveOrderButtonSaga,
  );
}

function* watchUserTouchedSimulatorApproveOrderWithUpdateCookingTimeButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SIMULATOR_APPROVE_ORDER_WITH_UPDATE_COOKING_TIME_BUTTON,
    userTouchedSimulatorApproveOrderWithUpdateCookingTimeButtonSaga,
  );
}

function* watchUserTouchedSimulatorHoldOrderButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SIMULATOR_HOLD_ORDER_BUTTON,
    userTouchedSimulatorHoldOrderButtonSaga,
  );
}

function* watchUserTouchedSimulatorFinishOrderButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SIMULATOR_FINISH_ORDER_BUTTON,
    userTouchedSimulatorFinishOrderButtonSaga,
  );
}

function* watchUserTouchedSimulatorFindCrewForLocalAreaButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SIMULATOR_FIND_CREW_FOR_LOCAL_AREA_BUTTON,
    userTouchedSimulatorFindCrewForLocalAreaButtonSaga,
  );
}

function* watchUserTouchedSimulatorCancelOrderButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SIMULATOR_CANCEL_ORDER_BUTTON,
    userTouchedSimulatorCancelOrderButtonSaga,
  );
}

function* watchUserTouchedSimulatorCloseFindingCrewDialogButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SIMULATOR_CLOSE_FIND_CREW_DIALOG_BUTTON,
    userTouchedSimulatorCloseFindingCrewDialogButtonSaga,
  );
}

function* watchUserTouchedSimulatorHoldOrderForGroceryButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SIMULATOR_HOLD_ORDER_FOR_GROCERY_BUTTON,
    userTouchedSimulatorHoldOrderForGroceryButtonSaga,
  );
}

function* watchUserTouchedSimulatorApproveOrderForGroceryButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SIMULATOR_APPROVE_ORDER_FOR_GROCERY_BUTTON,
    userTouchedSimulatorApproveOrderForGroceryButtonSaga,
  );
}

function* watchSystemMountSimulatorSaga() {
  yield takeLatest(ActionTypes.S_MOUNT_SIMULATOR, systemMountSimulatorSaga);
}

function* watchSystemUnmountSimulatorSaga() {
  yield takeLatest(ActionTypes.S_UNMOUNT_SIMULATOR, systemUnmountSimulatorSaga);
}

function* watchSystemGenerateSimulatorOrderDataSaga() {
  yield takeLatest(
    ActionTypes.S_GENERATE_SIMULATOR_ORDER_DATA,
    systemGenerateSimulatorOrderDataSaga,
  );
}
