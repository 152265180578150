export type BreakPoints = {
  mobile: number;
  tablet: number;
  desktop: number;
};

const sizes: BreakPoints = {
  mobile: 480,
  tablet: 480,
  desktop: 768,
};

type MediaQueryMap = {
  [K in keyof BreakPoints]: string;
};

const customMediaQueryMin = (minWidth: number) =>
  `@media only screen and (min-width: ${
    minWidth + 1
  }px) and (orientation: portrait), only screen and (min-height: ${
    minWidth + 1
  }px) and (orientation: landscape)`;
const customMediaQueryMax = (minWidth: number) =>
  `@media only screen and (max-width: ${minWidth}px) and (orientation: portrait), only screen and (max-height: ${minWidth}px) and (orientation: landscape)`;

export const media = Object.keys(sizes)
  .map(i => i as keyof BreakPoints)
  .reduce<MediaQueryMap>((acc, label) => {
    if (label === "mobile") {
      // eslint-disable-next-line no-param-reassign
      acc[label] = customMediaQueryMax(sizes[label]);
    } else {
      // eslint-disable-next-line no-param-reassign
      acc[label] = customMediaQueryMin(sizes[label]);
    }
    return acc;
  }, {} as MediaQueryMap);
