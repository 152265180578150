import "firebase/firestore";
import firebase from "firebase/app";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // apiKey: "AIzaSyCxMBBvSGxFD3NXkwnm3nhCBBQy3NRLarY",
  // authDomain: "firestore-takeout-project.firebaseapp.com",
  // projectId: "firestore-takeout-project",
  // storageBucket: "firestore-takeout-project.appspot.com",
  // messagingSenderId: "738336724023",
  // appId: "1:738336724023:web:e154ad175dc9f085641bb2"
};

export const fb = firebase.initializeApp(config);
