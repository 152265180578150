import React, { useMemo, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { keyframes, Keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { OrderData } from "records/OrderData";
import { userSubmittedPrintReceipt } from "modules/app/actions";
import { isPrinterProcessingSelectorFactory } from "modules/app/selectors";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { IconPrinter } from "components/atoms/Icons/IconPrinter";

const Container = styled.button<{ disabled: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  margin: 0;
  ${fontSize.XXLARGE};
  line-height: 1;
  appearance: none;
  border-style: solid;
  border-width: 2px;
  border-color: ${p => (p.disabled ? colorsConst.ICON.DISABLED : colorsConst.TEXT.LINK)};
  background-color: ${colorsConst.BACKGROUND.WHITE};
  border-radius: 3px;
`;

const animations: Keyframes = keyframes`
  0%{ transform: rotate(0); }
  100%{ transform: rotate(360deg); }
`;

const SpinnerIconContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  transform: translate(-50%, -50%);
  svg {
    animation: 1.5s ${animations} linear infinite;
  }
`;

const isPrinterProcessingSelector = isPrinterProcessingSelectorFactory(["connecting", "printing"]);

type Props = {
  orderData: OrderData;
  submitting?: boolean;
};

export const PrintReceiptButton: React.FC<Props> = React.memo(
  ({ orderData, submitting = false }) => {
    const isPrinterProcessing = useSelector(isPrinterProcessingSelector);

    const disabled = useMemo(
      () => submitting || isPrinterProcessing,
      [submitting, isPrinterProcessing],
    );

    const iconColor = useMemo(
      () => (disabled ? colorsConst.ICON.DISABLED : colorsConst.TEXT.LINK),
      [disabled],
    );

    const dispatch = useDispatch();

    const handleClick = useCallback(() => {
      dispatch(userSubmittedPrintReceipt(orderData));
    }, [dispatch, orderData]);

    return (
      <Container onClick={handleClick} disabled={disabled}>
        {isPrinterProcessing ? (
          <SpinnerIconContainer>
            <FontAwesomeIcon icon={faSpinner} />
          </SpinnerIconContainer>
        ) : undefined}
        <div>
          <IconPrinter color={iconColor} />
        </div>
      </Container>
    );
  },
);
