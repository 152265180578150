import { put, select } from "redux-saga/effects";
import { updateShopItemOptions, SystemUpdateShopItemOptionStatusAction } from "../actions";
import { optionSetListSelector } from "../selectors";
import { ShopModel } from "../model";

export function* systemUpdateShopItemOptionStatusSaga(
  action: SystemUpdateShopItemOptionStatusAction,
) {
  const { optionIdsToBeUpdated, submitType } = action.payload;
  const optionSets: ReturnType<typeof optionSetListSelector> = yield select(optionSetListSelector);
  const updatedOptionSets = ShopModel.getUpdatedOptionSetsByStopOrRestartOption(
    optionSets,
    optionIdsToBeUpdated,
    submitType,
  );
  yield put(updateShopItemOptions(updatedOptionSets));
}
