import { replace } from "connected-react-router";
import { put, fork, call } from "redux-saga/effects";
import { API_KEY, LoginShopRequest, LoginShopResponse } from "apis";
import { LocalStorage } from "utils/LocalStorage";
import { EncryptedLocalStorage } from "utils/EncryptedLocalStorage";
import { DEVICE_TOKEN_KEY } from "modules/app/model";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateLoginToken, UserSubmittedLoginShopFormAction } from "../actions";

export function* userSubmittedLoginShopFormSaga(action: UserSubmittedLoginShopFormAction) {
  try {
    const { values } = action.payload;
    const params: LoginShopRequest = {
      ...values,
      device_token: LocalStorage.getItemOrDefault(DEVICE_TOKEN_KEY, ""),
    };
    const { result, error }: { result: LoginShopResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.LOGIN_SHOP,
      params,
    );
    if (result && !error) {
      LocalStorage.setItem("loginId", params.login_id);
      EncryptedLocalStorage.set("loginPassword", params.password);
      LocalStorage.setItem("loginToken", result.token);
      yield put(updateLoginToken(result.token));
      yield put(replace("/orderList"));
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
