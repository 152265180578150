import React, { useMemo, useCallback } from "react";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { Button } from "components/atoms/Button";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
} from "components/molecules/ModalDialog";

const modalBodyStyle: React.CSSProperties = {
  textAlign: "center",
  whiteSpace: "pre-wrap",
  padding: "2em 0",
  lineHeight: 1.5,
};

const MODAL_KEY = "APP_ERROR";
const useModal = modalHooksFactory(MODAL_KEY);

type Props = {
  handleClose?: () => void;
  onOpened?: () => void;
  onClosed?: () => void;
};

export const ErrorDialog: React.FC<Props> = React.memo(({ handleClose, onOpened, onClosed }) => {
  const { data, close } = useModal();

  const title = useMemo(
    () =>
      "payload" in data && "title" in data.payload && data.payload.title !== ""
        ? data.payload.title
        : "エラー",
    [data],
  );

  const message = useMemo(
    () =>
      "payload" in data && "message" in data.payload && data.payload.message !== ""
        ? data.payload.message
        : "エラーが発生しました。",
    [data],
  );

  const handleCloseWrap = useCallback(() => {
    if (typeof handleClose !== "undefined") {
      handleClose();
    }
    close();
  }, [handleClose, close]);

  return (
    <ModalDialog
      isOpen={data.isOpen}
      handleClickBackdrop={handleCloseWrap}
      onOpened={onOpened}
      onClosed={onClosed}
    >
      <ModalDialogHeader>{title}</ModalDialogHeader>
      <ModalBody style={modalBodyStyle}>{message}</ModalBody>
      <ModalFooter>
        <Button appearance="primary" handleClick={handleCloseWrap}>
          閉じる
        </Button>
      </ModalFooter>
    </ModalDialog>
  );
});
