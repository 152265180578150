import React, { useMemo, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { API_KEY } from "apis";
import { CordovaUtil } from "utils/CordovaUtil";
import { PrintUtils } from "utils/PrintUtils";
import {
  AutoPrintSetting,
  PrinterFontSizeType,
  displayablePrinterFontSize,
} from "records/AutoPrintSetting";
import { isConnectedApiSelectorFactory, printerSelector } from "modules/app/selectors";
import {
  userAccessedToPageThatNeedsShopData,
  userSubmittedAutoPrintSetting,
} from "modules/shop/actions";
import { autoPrintSettingSelector } from "modules/shop/selectors";
import { Contents } from "components/atoms/Contents";
import { Text } from "components/atoms/Text";
import { selectStyle } from "components/atoms/FormFields/SelectField";
import { Article } from "components/organisms/Article";
import {
  InputSettingListItem,
  InputSettingListItemInner,
  TextContainer,
  InputContainer,
} from "components/organisms/InputSettingListItem";
import { ToggleButton } from "components/atoms/ToggleButton";

const SelectBox = styled.select`
  ${selectStyle};
  padding-right: 1em;
  padding-left: 1em;
`;

const submittingSelector = isConnectedApiSelectorFactory([API_KEY.SET_AUTO_PRINTING]);

export const PrinterSettingTemplate: React.FC = React.memo(() => {
  const autoPrintSetting = useSelector(autoPrintSettingSelector);
  const { discoveredPrinter } = useSelector(printerSelector);
  const submitting = useSelector(submittingSelector);

  const canDisplayFontSizeSelectField = useMemo(
    () =>
      CordovaUtil.isCordovaEnabled() &&
      (CordovaUtil.getPlatformId() !== "ios" || !PrintUtils.isSmL200(discoveredPrinter.modelName)),
    [discoveredPrinter],
  );

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(
      userSubmittedAutoPrintSetting(
        !AutoPrintSetting.enable(autoPrintSetting),
        autoPrintSetting.print_count,
        autoPrintSetting.font_size,
      ),
    );
  }, [dispatch, autoPrintSetting]);

  /*
  const handleChangePrintCount = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const normalized = parseInt(event.target.value, 10) as PrinterFontSizeType;
      if (Number.isNaN(normalized)) {
        return;
      }
      if (!submitting) {
        dispatch(
          userSubmittedAutoPrintSetting(
            autoPrintSetting.is_auto_printing,
            normalized,
            autoPrintSetting.font_size,
          ),
        );
      }
    },
    [dispatch, autoPrintSetting, submitting],
  );
  */

  const handleChangeFontSize = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const normalized = parseInt(event.target.value, 10) as PrinterFontSizeType;
      if (Number.isNaN(normalized)) {
        return;
      }
      dispatch(
        userSubmittedAutoPrintSetting(
          AutoPrintSetting.enable(autoPrintSetting),
          autoPrintSetting.print_count,
          normalized,
        ),
      );
    },
    [dispatch, autoPrintSetting],
  );

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopData());
  }, [dispatch]);

  return (
    <Article
      withHeader
      title="プリンターの印刷設定"
      goBack="/setting"
      withNavigation
      activePath="SETTING"
    >
      <Contents>
        <InputSettingListItem>
          <InputSettingListItemInner>
            <TextContainer>
              <Text size="LARGE">自動で印刷する</Text>
            </TextContainer>
            <InputContainer>
              <ToggleButton
                active={AutoPrintSetting.enable(autoPrintSetting)}
                handleClick={handleClick}
                disabled={submitting}
              />
            </InputContainer>
          </InputSettingListItemInner>
        </InputSettingListItem>
        {/*
        <InputSettingListItem>
          <InputSettingListItemInner>
            <TextContainer>
              <Text size="LARGE">印刷する部数</Text>
            </TextContainer>
            <InputContainer>
              <SelectBox
                value={autoPrintSetting.print_count}
                onChange={handleChangePrintCount}
                disabled={submitting}
              >
                {[...Array(3)].map((_, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </SelectBox>
            </InputContainer>
          </InputSettingListItemInner>
        </InputSettingListItem>
        */}
        {canDisplayFontSizeSelectField ? (
          <InputSettingListItem>
            <InputSettingListItemInner>
              <TextContainer>
                <Text size="LARGE">文字サイズ</Text>
              </TextContainer>
              <InputContainer>
                <SelectBox
                  value={autoPrintSetting.font_size}
                  onChange={handleChangeFontSize}
                  disabled={submitting}
                >
                  {displayablePrinterFontSize.map(size => (
                    <option key={size} value={size}>
                      {PrintUtils.getPresentationFontSizeText(size)}
                    </option>
                  ))}
                </SelectBox>
              </InputContainer>
            </InputSettingListItemInner>
          </InputSettingListItem>
        ) : undefined}
      </Contents>
    </Article>
  );
});
