/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useEffect } from "react";
import styled, { keyframes, Keyframes } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { SnackBarType } from "records/SnackBar";
import { systemInitSnackBar } from "modules/app/actions";
import { snackBarSelector } from "modules/app/selectors";
import { colorsConst, zIndexConst } from "styles/const";
import { fontSize } from "styles/mixins";

const animations: Keyframes = keyframes`
    0% {
      transform: translateX(100%);
    }
    12.5% {
      transform: translateX(0);
    }
    87.5% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  `;

const Container = styled.div`
  position: fixed;
  z-index: ${zIndexConst.FIXED};
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: calc(6em + constant(safe-area-inset-bottom));
  bottom: calc(6em + env(safe-area-inset-bottom));
  bottom: 6em;
  padding: 0.5em 2em 0.5em 1em;
  transform: translateX(100%);
  color: ${colorsConst.TEXT.WHITE};
  background-color: ${colorsConst.TEXT.PRIMARY};
  animation: 4s ${animations} ease 0s 1 normal;
`;

const IconContainer = styled.div<{ isWarning: boolean }>`
  margin-right: 0.25em;
  ${fontSize.LARGE};
  ${p => (p.isWarning ? `transform: rotate(90deg);` : undefined)}
`;

const TextContainer = styled.div`
  ${fontSize.SMALL};
`;

type SnackBarElementProps = {
  onOpened: () => void;
  handleClick: () => void;
  snackBarType: SnackBarType;
  snackBarText: string;
};

const SnackBarElement: React.FC<SnackBarElementProps> = React.memo(
  ({ handleClick, onOpened, snackBarType, snackBarText }) => {
    useEffect(() => {
      let timerId = 0;
      timerId = window.setTimeout(() => {
        onOpened();
      }, 4000);
      return () => {
        clearTimeout(timerId);
      };
    }, [onOpened]);
    return (
      <Container onClick={handleClick}>
        <IconContainer isWarning={snackBarType === "WARNING"}>
          {snackBarType === "DEFAULT" ? (
            <FontAwesomeIcon icon={faCheckCircle} />
          ) : (
            <FontAwesomeIcon icon={faBan} />
          )}
        </IconContainer>
        <TextContainer>{snackBarText}</TextContainer>
      </Container>
    );
  },
);

type Props = {};

export const SnackBar: React.FC<Props> = React.memo(() => {
  const { isOpen, snackBarType, snackBarText } = useSelector(snackBarSelector);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(systemInitSnackBar());
  }, [dispatch]);

  const onOpened = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <>
      {isOpen ? (
        <SnackBarElement
          handleClick={handleClose}
          onOpened={onOpened}
          snackBarType={snackBarType}
          snackBarText={snackBarText}
        />
      ) : undefined}
    </>
  );
});
