import React, { useMemo, useContext } from "react";
import styled from "styled-components";
import { OrderOptionSet } from "records/OrderOptionSet";
import { PriceUtils } from "utils/PriceUtils";
import { Text } from "components/atoms/Text";

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const OptionNameContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const OptionPriceContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 4em;
  margin-left: 1em;
  text-align: right;
`;

type Props = {
  optionSets: OrderOptionSet[];
};

export const PaymentCancelledItemOptionSetList: React.FC<Props> = React.memo(({ optionSets }) => (
  <>
    {optionSets.map((optionSet, i) => (
      <>
        {optionSet.option_list.map((option, ii) => (
          <RowContainer key={`${i}_${ii}`}>
            <OptionNameContainer>
              <Text size="SMALL">{option.option_name}</Text>
            </OptionNameContainer>
            <OptionPriceContainer>
              <Text size="SMALL">{`-${PriceUtils.getPresentationValue(option.price)}`}</Text>
            </OptionPriceContainer>
          </RowContainer>
        ))}
      </>
    ))}
  </>
));
