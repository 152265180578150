import { put, delay, select } from "redux-saga/effects";
import { OrderData, ORDER_TIME_TYPE } from "records/OrderData";
import { OrderNotification, NOTIFICATION_TYPE } from "records/OrderNotification";
import { OrderNotificationStack } from "records/OrderNotificationStack";
import { updateOrderNotificationStack } from "modules/order/actions";
import {
  updateSimulatorOrderData,
  updateSimulatorOrderList,
  updateCookingStartDateTime,
} from "../actions";
import { isMountedSelector } from "../selectors";

export function* newOrderSaga(orderData: OrderData, cookingStartDateTime: string) {
  yield delay(1000);
  const isMounted: boolean = yield select(isMountedSelector);
  if (isMounted) {
    yield put(
      updateOrderNotificationStack(
        OrderNotificationStack.create({
          notifications: [
            OrderNotification.create({
              notification_type:
                orderData.time_type === ORDER_TIME_TYPE.SPECIFIED
                  ? NOTIFICATION_TYPE.RESERVED_ORDER
                  : NOTIFICATION_TYPE.FASTEST_ORDER,
              order_no: orderData.order_no,
              display_order_no: orderData.order_no,
              receive_type: orderData.receive_type,
              is_combo: orderData.is_combo,
              driver_arrival_datetime: orderData.receive_datetime,
            }),
          ],
          isSimulator: true,
        }),
      ),
    );
    yield put(updateCookingStartDateTime(cookingStartDateTime));
    yield put(updateSimulatorOrderData(orderData));
    yield put(updateSimulatorOrderList([orderData]));
  }
}
