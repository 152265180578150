import { put, delay, select } from "redux-saga/effects";
import { OrderData, ORDER_STATE_TYPE } from "records/OrderData";
import { OrderNotification, NOTIFICATION_TYPE } from "records/OrderNotification";
import { OrderNotificationStack } from "records/OrderNotificationStack";
import { updateOrderNotificationStack } from "modules/order/actions";
import { updateSimulatorOrderData, updateSimulatorOrderList } from "../actions";
import { isMountedSelector } from "../selectors";

export function* foundCrewForLocalAreaSaga(orderData: OrderData) {
  yield delay(5000);
  const isMounted: boolean = yield select(isMountedSelector);
  if (isMounted) {
    yield put(
      updateOrderNotificationStack(
        OrderNotificationStack.create({
          notifications: [
            OrderNotification.create({
              notification_type: NOTIFICATION_TYPE.FOUND_CREW,
              order_no: orderData.order_no,
              display_order_no: orderData.order_no,
              driver_arrival_datetime: orderData.receive_datetime,
            }),
          ],
          isSimulator: true,
        }),
      ),
    );
    const updatedOrderData = OrderData.create({
      ...orderData,
      order_state: ORDER_STATE_TYPE.LOCAL_AREA_DRIVER_APPROVE,
      is_driver_accepted: true,
    });
    yield put(updateSimulatorOrderData(updatedOrderData));
    yield put(updateSimulatorOrderList([updatedOrderData]));
  }
}
