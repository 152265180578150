import React, { useMemo, useCallback } from "react";
import { push } from "connected-react-router";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { serverDateTimeSelector } from "modules/app/selectors";
import { salesSelector } from "modules/sales/selectors";
import { DateUtils } from "utils/DateUtils";
import { PriceUtils } from "utils/PriceUtils";
import { colorsConst } from "styles/const";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";

const HeadTitle = styled.div``;

const Container = styled.div`
  margin-top: 1em;
  padding: 1em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

const ContentsTitle = styled.div``;

const DetailContainer = styled.div`
  margin-top: 0.25em;
  padding-top: 0.25em;
  border-top: 1px solid ${colorsConst.BORDER.THIN};
`;

const ButtonContainer = styled.div`
  margin-top: 1em;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Key = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 1em;
`;

const Value = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
`;

const Description = styled.div``;

type Props = {};

export const SalesDetail: React.FC<Props> = React.memo(() => {
  const sales = useSelector(salesSelector);
  const serverDateTime = useSelector(serverDateTimeSelector);

  const thisMonthText = useMemo(
    () => DateUtils.dateToString(serverDateTime, "YYYY年M月"),
    [serverDateTime],
  );

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(push("/salesMonthly"));
  }, [dispatch]);

  return (
    <>
      <HeadTitle>
        <Text size="DEFAULT" weight="MEDIUM">
          {thisMonthText}
        </Text>
      </HeadTitle>
      <Container>
        <ContentsTitle>
          <Text size="SMALL" weight="MEDIUM">
            今月の売上
          </Text>
        </ContentsTitle>
        <Row>
          <Key>
            <Text size="SMALL" weight="MEDIUM">
              総売上
            </Text>
          </Key>
          <Value>
            <Text size="LARGE" weight="MEDIUM">
              {PriceUtils.getPresentationValue(sales.monthly.sales)}
            </Text>
          </Value>
        </Row>
        <DetailContainer>
          <Row>
            <Key>
              <Text size="SMALL" weight="MEDIUM" color="SECONDARY">
                内訳
              </Text>
            </Key>
            <Value>
              <Row>
                <Key>
                  <Text size="SMALL" color="SECONDARY">
                    menu決済支払い
                  </Text>
                </Key>
                <Value>
                  <Text size="SMALL" color="SECONDARY">
                    {PriceUtils.getPresentationValue(sales.monthly.credit_card_sales)}
                  </Text>
                </Value>
              </Row>
              <Row>
                <Key>
                  <Text size="SMALL" color="SECONDARY">
                    現金払い
                  </Text>
                </Key>
                <Value>
                  <Text size="SMALL" color="SECONDARY">
                    {PriceUtils.getPresentationValue(sales.monthly.local_sales)}
                  </Text>
                </Value>
              </Row>
            </Value>
          </Row>
        </DetailContainer>
        <Description>
          <Text size="SMALL" color="SECONDARY">
            ※取扱高は前日までの情報を表示しており、毎日午前10時ごろに更新しております。
          </Text>
        </Description>
      </Container>
      <ButtonContainer>
        <Button appearance="primary" type="button" handleClick={handleClick}>
          過去の売上を確認する
        </Button>
      </ButtonContainer>
    </>
  );
});
