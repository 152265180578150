import React, { useMemo } from "react";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { OrderItemData } from "records/OrderItemData";
import { ItemName } from "components/molecules/ItemName";
import { OrderItemOptionSet } from "components/molecules/OrderItemOptionSet";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 0.5em;
  padding-top: 0.5em;
  border-top: 1px solid ${colorsConst.BORDER.THIN};
`;

const ItemInfo = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 0.5em;
  padding-left: 0.5em;
  border-left: 1px solid #e3e0df;
`;

const OptionContainer = styled.div`
  margin-top: 0.5em;
`;

const InstructionTextContainer = styled.div<{ isWarning: boolean }>`
  margin-top: 0.5em;
  padding: 0.5em;
  ${p =>
    p.isWarning
      ? `background-color: ${colorsConst.BACKGROUND.WHITE};
    color: ${colorsConst.TEXT.DANGER};`
      : `background-color: ${colorsConst.BACKGROUND.INFO};`};
`;
const OrderCountContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ItemCountValue = styled.span`
  ${fontSize.XXLARGE};
`;

const ItemCountUnit = styled.span<{ isWarning: boolean }>`
  ${fontSize.LARGE};
  ${p => (p.isWarning ? colorsConst.TEXT.WHITE : colorsConst.TEXT.SECONDARY)};
`;

const OrderCount = styled.div<{ isWarning: boolean }>`
  ${fontSize.SMALL};
  ${p => (p.isWarning ? colorsConst.TEXT.WHITE : colorsConst.TEXT.SECONDARY)};
`;

type Props = {
  item: OrderItemData;
  isWarning: boolean;
};

export const OrderItem: React.FC<Props> = React.memo(({ item, isWarning }) => {
  const isBuyOne = useMemo(() => OrderItemData.isBuyOne(item), [item]);

  const displayableItemCount = useMemo(
    () => (isBuyOne ? item.order_count * 2 : item.order_count),
    [isBuyOne, item.order_count],
  );

  return (
    <Container>
      <OrderCountContainer>
        <div>
          <ItemCountValue>{displayableItemCount}</ItemCountValue>
          <ItemCountUnit isWarning={isWarning}>
            {OrderItemData.getPresentationUnitString(item.unit)}
          </ItemCountUnit>
        </div>
        {isBuyOne ? (
          <>
            <OrderCount isWarning={isWarning}>{`注文：${item.order_count}個`}</OrderCount>
            <OrderCount isWarning={isWarning}>{`無料：${item.order_count}個`}</OrderCount>
          </>
        ) : undefined}
      </OrderCountContainer>
      <ItemInfo>
        <ItemName item={item} />
        {item.option_set_list.length > 0 ? (
          <OptionContainer>
            {item.option_set_list.map((optionSet, index) => (
              <OrderItemOptionSet optionSet={optionSet} key={index} isWarning={isWarning} />
            ))}
          </OptionContainer>
        ) : undefined}
        {item.instruction_text !== "" ? (
          <InstructionTextContainer isWarning={isWarning}>
            {item.instruction_text}
          </InstructionTextContainer>
        ) : undefined}
      </ItemInfo>
    </Container>
  );
});
