import React from "react";
import { ConfirmStockCheckDialog } from "components/organisms/ConfirmStockCheckDialog";
import { ConfirmOrderRestartByItemDialog } from "components/organisms/ConfirmOrderRestartByItemDialog";
import { OrderItemImageDialog } from "components/organisms/OrderDetail/OrderItemImageDialog";

export const ShopItemsForStockCheckModal: React.FC = React.memo(() => (
  <>
    <ConfirmStockCheckDialog />
    <ConfirmOrderRestartByItemDialog />
    <OrderItemImageDialog />
  </>
));
