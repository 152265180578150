import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { FieldArray, useFieldArray, FieldArrayRenderProps } from "react-final-form-arrays";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { BusinessTime } from "records/BusinessTime";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { SelectTimeOptions } from "components/atoms/SelectTimeOptions";
import { SelectField } from "components/atoms/FormFields/SelectField";
import { TextLink } from "components/atoms/TextLink";
import { BusinessTimeError } from "./BusinessTimeError";

const Container = styled.div`
  margin-top: 0.5em;
`;

const Label = styled.div`
  ${fontSize.SMALL};
  color: ${colorsConst.TEXT.SECONDARY};
`;

const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.25em;
`;

const FieldContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const Separator = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 0.5em;
  margin-left: 0.5em;
`;

const DeleteButtonContainer = styled.div`
  width: 2em;
`;

const DeleteButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  ${fontSize.LARGE};
  color: ${colorsConst.ICON.DEFAULT};
`;

const AddButtonContainer = styled.div`
  margin-top: 0.5em;
`;

type Props = {
  fieldArrayName: string;
};

export const BusinessBusyTimeField: React.FC<Props> = React.memo(({ fieldArrayName }) => {
  const fieldArrayProps: FieldArrayRenderProps<BusinessTime, HTMLElement> =
    useFieldArray(fieldArrayName);
  const fieldsLength = useMemo(() => fieldArrayProps.fields.length || 0, [fieldArrayProps]);

  const handleArrayPush = useCallback(() => {
    fieldArrayProps.fields.push(BusinessTime.create());
  }, [fieldArrayProps]);

  const handleArrayRemove = useCallback(
    (index: number) => {
      fieldArrayProps.fields.remove(index);
    },
    [fieldArrayProps],
  );

  return (
    <>
      <FieldArray name={fieldArrayName}>
        {({ fields, meta: { error } }) =>
          fields.map((name, index) => (
            <Container key={index}>
              <Label>忙しい時間帯{String.fromCharCode(9312 + index)}</Label>
              <FieldRow>
                <FieldContainer>
                  <SelectField name={`${name}.start_time`} component="select">
                    <SelectTimeOptions />
                  </SelectField>
                </FieldContainer>
                <Separator>〜</Separator>
                <FieldContainer>
                  <SelectField name={`${name}.end_time`} component="select">
                    <SelectTimeOptions />
                  </SelectField>
                </FieldContainer>
                <DeleteButtonContainer>
                  {index > 0 ? (
                    <DeleteButton type="button" onClick={() => handleArrayRemove(index)}>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </DeleteButton>
                  ) : undefined}
                </DeleteButtonContainer>
              </FieldRow>
              {error && error[index] !== null && typeof error[index] !== "undefined" ? (
                <BusinessTimeError error={error[index]} />
              ) : undefined}
            </Container>
          ))
        }
      </FieldArray>
      {fieldsLength < 20 ? (
        <AddButtonContainer>
          <TextLink type="button" onClick={handleArrayPush}>
            ＋追加する
          </TextLink>
        </AddButtonContainer>
      ) : undefined}
    </>
  );
});
