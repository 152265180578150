export type PrinterStatus =
  | "searching"
  | "notFound"
  | "discovered"
  | "connecting"
  | "connected"
  | "connectedFailed"
  | "disconnecting"
  | "disconnect"
  | "disconnectFailed"
  | "printing"
  | "printSuccess"
  | "printFailed"
  | "checking"
  | "checkFailed";

export type PrintCountType = Readonly<{
  [orderNo: string]: number;
}>;

export type Printer = {
  portName: string;
  modelName: string;
  macAddress: string;
};

const initialState: Printer = {
  portName: "",
  modelName: "",
  macAddress: "",
};

const create = (args: Partial<Printer> = {}) => ({
  ...initialState,
  ...args,
});

const isDiscovered = (printer: Printer) => printer.portName !== "";

export const Printer = Object.freeze({
  initialState,
  create,
  isDiscovered,
});
