export type ShopItemOption = {
  shop_option_id: number;
  option_name: string;
  is_order_stop: boolean;
  is_release: boolean;
};

const initialState: ShopItemOption = {
  shop_option_id: 0,
  option_name: "",
  is_order_stop: false,
  is_release: false,
};

const create = (args: Partial<ShopItemOption> = {}) => ({
  ...initialState,
  ...args,
});

export const ShopItemOption = Object.freeze({
  initialState,
  create,
});
