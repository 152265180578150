import { BusinessType, BUSINESS_TYPE } from "./BusinessWeekTime";

export type BusinessTime = Readonly<{
  start_time: string;
  end_time: string;
}>;

const initialState: BusinessTime = {
  start_time: "",
  end_time: "",
};

const create = (args: Partial<BusinessTime> = {}) => ({
  ...initialState,
  ...args,
});

const getUpdatedBusinessTimesByBusinessType = (
  businessTimes: BusinessTime[],
  businessType: BusinessType,
) => {
  const updatedBusinessTimes: BusinessTime[] = businessTimes.slice();
  switch (businessType) {
    case BUSINESS_TYPE.ONCE:
      updatedBusinessTimes.push(initialState);
      return updatedBusinessTimes.slice(0, 1);
    case BUSINESS_TYPE.TWICE:
      Array(2)
        .fill("")
        .forEach(_ => updatedBusinessTimes.push(initialState));
      return updatedBusinessTimes.slice(0, 2);
    default:
      return [];
  }
};

const getPresentationBusinessTimeText = (time: string) => `${time.slice(0, 2)}:${time.slice(2, 4)}`;

export const BusinessTime = Object.freeze({
  initialState,
  create,
  getUpdatedBusinessTimesByBusinessType,
  getPresentationBusinessTimeText,
});
