import { fork, call, put } from "redux-saga/effects";
import { replace } from "connected-react-router";
import {
  API_KEY,
  ApproveOrderRequest,
  ApproveOrderResponse,
  UpdateOrderItemsCountResponse,
} from "apis";
import { REASON_FOR_ITEMS_STOP_VIEW } from "records/ShopItem";
import { ORDER_STATE_TYPE } from "records/OrderData";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { systemOpenedModal, systemClosedModal, updateSnackBar } from "modules/app/actions";
import { userSubmittedUpdateShopItemStoppedReasons } from "modules/shop/actions";
import { updateOrderDetail, UserSubmittedApproveOrderAction } from "../actions";
import { updateOrderItemsCountSaga } from "./updateOrderItemsCountSaga";

export function* userSubmittedApproveOrderSaga(action: UserSubmittedApproveOrderAction) {
  try {
    const {
      order_no,
      add_minutes,
      add_cooking_start_remind_minutes,
      localAreaFlag,
      isRetail,
      isAllowedOutOfStock,
      draftItems,
      withUpdateCookingTime,
      shopItemStopReasons,
    } = action.payload;
    if (isRetail && isAllowedOutOfStock) {
      const updateOrderItemsCountResult: {
        result: UpdateOrderItemsCountResponse;
        error: unknown;
      } = yield call(updateOrderItemsCountSaga, order_no, draftItems);
      if (updateOrderItemsCountResult.result && !updateOrderItemsCountResult.error) {
        yield put(updateOrderDetail(updateOrderItemsCountResult.result.order_data));
      } else {
        throw updateOrderItemsCountResult.error;
      }
    }
    const params: ApproveOrderRequest = {
      order_no,
      add_minutes,
      add_cooking_start_remind_minutes,
    };
    const { result, error }: { result: ApproveOrderResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.APPROVE_ORDER,
      params,
    );

    if (shopItemStopReasons && shopItemStopReasons?.length > 0) {
      yield put(
        userSubmittedUpdateShopItemStoppedReasons(
          shopItemStopReasons,
          REASON_FOR_ITEMS_STOP_VIEW.ORDER_DETAIL,
        ),
      );
    }

    if (result && !error) {
      if (localAreaFlag) {
        yield put(systemOpenedModal("FINDING_CREW", {}));
      } else if (result.order_state === ORDER_STATE_TYPE.ONE_STORE_APPROVE) {
        yield put(systemOpenedModal("WAIT_COMBO_ORDER", {}));
      } else {
        if (withUpdateCookingTime) {
          yield put(systemClosedModal("UPDATE_COOKING_START_TIME"));
        }
        yield put(replace("/orderList", { from: { pathname: `/orderDetail/${order_no}` } }));
        yield put(updateSnackBar("注文を受け付けました"));
      }
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
