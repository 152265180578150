import { put } from "redux-saga/effects";
import { OrderData } from "records/OrderData";
import { systemClosedModal, updateSnackBar } from "modules/app/actions";
import {
  updateSimulatorOrderData,
  updateSimulatorOrderList,
  updateSimulatorFinishedOrderList,
  updateSimulatorPageTransition,
  UserTouchedSimulatorCancelOrderButtonAction,
} from "../actions";

export function* userTouchedSimulatorCancelOrderButtonSaga(
  action: UserTouchedSimulatorCancelOrderButtonAction,
) {
  yield put(systemClosedModal("CANCEL_ORDER"));
  yield put(updateSimulatorOrderData(OrderData.create()));
  yield put(updateSimulatorOrderList([]));
  yield put(updateSimulatorFinishedOrderList([]));
  yield put(updateSimulatorPageTransition(false));
  yield put(updateSnackBar("注文をキャンセルしました"));
}
