import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconCancel: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M24,41c9.4,0,17-7.6,17-17S33.4,7,24,7S7,14.6,7,24S14.6,41,24,41z M24,45c11.6,0,21-9.4,21-21
	c0-11.6-9.4-21-21-21C12.4,3,3,12.4,3,24C3,35.6,12.4,45,24,45z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M8.6,36L35.3,9.2l2.8,2.8L11.4,38.8L8.6,36z"
      />
    </svg>
  );
});
