import React from "react";
import { ShopData } from "records/ShopData";
import { OrderListTabType } from "modules/order/model";

export type OrderListContextValue = {
  shopData: ShopData;
  isSimulator?: boolean;
  reservedOrderOnly?: boolean;
  notDisplayOrderItem?: boolean;
  setReservedOrderOnly?: (value: React.SetStateAction<boolean>) => void;
  setNotDisplayOrderItem?: (value: React.SetStateAction<boolean>) => void;
};

export const OrderListContext = React.createContext<OrderListContextValue>(
  {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
);
