import { put, call, fork } from "redux-saga/effects";
import { API_KEY, RestartOrderByItemRequest, RestartOrderByItemResponse } from "apis";
import { systemOpenedModal, systemClosedModal, updateSnackBar } from "modules/app/actions";
import { commonApiSaga, commonErrorSaga } from "modules/app/sagas";
import { systemUpdateShopItemSelectStopReasonType } from "modules/shop/actions";
import { REASON_FOR_ITEMS_STOP } from "records/ShopItem";
import { systemUpdateShopItemStatus, UserSubmittedRestartOrderByItemsAction } from "../actions";
import { SHOP_ITEM_SUBMIT_TYPE } from "../model";

export function* userSubmittedRestartOrderByItemsSaga(
  action: UserSubmittedRestartOrderByItemsAction,
) {
  try {
    const { shopItemId } = action.payload;
    const params: RestartOrderByItemRequest = {
      shop_item_ids: [shopItemId],
      confirm_flag: action.payload.isConfirmed,
      use_stock_check: false,
    };
    const { result, error }: { result: RestartOrderByItemResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.RESTART_ORDER_BY_ITEM,
      params,
    );
    if (result && !error) {
      if (!result.result) {
        yield put(
          systemOpenedModal("CONFIRM_ORDER_RESTART_BY_ITEMS", {
            selectedItemId: action.payload.shopItemId,
            errorIds: result.error_ids,
          }),
        );
      } else {
        yield put(systemUpdateShopItemStatus([shopItemId], SHOP_ITEM_SUBMIT_TYPE.RESTART));
        yield put(systemClosedModal("CONFIRM_ORDER_RESTART_BY_ITEMS"));
        yield put(updateSnackBar("商品の受付を再開しました"));
      }
    } else {
      throw error;
    }
    yield put(
      systemUpdateShopItemSelectStopReasonType([shopItemId], REASON_FOR_ITEMS_STOP.DEFAULT),
    );
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
