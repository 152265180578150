import firebase from "firebase";
import { eventChannel } from "redux-saga";
import { take, fork } from "redux-saga/effects";
import { Utility } from "utils/Utility";
import { SentryUtil } from "utils/SentryUtil";
import { fb } from "utils/Firebase";
import { FirestoreShopNotificationData } from "records/FirestoreShopNotificationData";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { SystemWatchShopNotificationAction } from "../actions";
import { updateShopNotificationSaga } from "./updateShopNotificationSaga";

export function* systemWatchShopNotificationSaga(action: SystemWatchShopNotificationAction) {
  while (true) {
    const { shopId } = action.payload;
    const hash = `${Utility.getMd5String(shopId.toString())}_${shopId}`;
    const ref = fb
      .firestore()
      .collection(process.env.REACT_APP_FIREBASE_SHOP_NOTIFICATION_COLLECTION_NAME as string)
      .doc(hash)
      .collection("collection");
    const channel = eventChannel(emit => ref.onSnapshot(emit));
    try {
      while (true) {
        const data: firebase.firestore.QuerySnapshot<FirestoreShopNotificationData> = yield take(
          channel,
        );
        yield fork(updateShopNotificationSaga, data);
      }
    } catch (err) {
      SentryUtil.reportError(err);
      yield fork(commonErrorSaga);
    }
  }
}
