/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { setIn } from "final-form";
import { useMemo } from "react";

function setInError<T extends {}>(
  errors: { [K in keyof T]: string },
  innerError: { path: string; message: string },
) {
  return setIn(errors, innerError.path, innerError.message);
}

export function makeValidate<T extends {}>(schema: Yup.ObjectSchema<T>) {
  return async function validate(values: any) {
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err) {
      return err.inner.reduce(setInError, {});
    }
  };
}

export function useValidationSchema<T extends {}>(schema: Yup.ObjectSchema<T>) {
  const validate = useMemo(() => makeValidate(schema), [schema]);
  return validate;
}
