import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { ShopData } from "records/ShopData";
import { OrderModel, OrderListTabType, ORDER_LIST_TAB_TYPE } from "modules/order/model";
import { HeaderTab } from "components/atoms/HeaderTab";

const Inner = styled.div`
  padding: 0.5em 0;
`;

type Props = {
  orderList: OrderData[];
  shopData: ShopData;
  tabType: OrderListTabType;
  currentTabType: OrderListTabType;
  reservedOrderOnly?: boolean;
  handleClickTab?: (tabType: OrderListTabType) => void;
  setReservedOrderOnly?: (value: React.SetStateAction<boolean>) => void;
  setNotDisplayOrderItem?: (value: React.SetStateAction<boolean>) => void;
};

export const OrderListHeaderTab: React.FC<Props> = React.memo(
  ({
    orderList,
    shopData,
    tabType,
    currentTabType,
    reservedOrderOnly = false,
    handleClickTab,
    setReservedOrderOnly,
    setNotDisplayOrderItem,
  }) => {
    const handleClick = useCallback(() => {
      if (tabType !== ORDER_LIST_TAB_TYPE.PENDING && setReservedOrderOnly) {
        setReservedOrderOnly(false);
        if (tabType !== ORDER_LIST_TAB_TYPE.ON_PREPARING && setNotDisplayOrderItem) {
          setNotDisplayOrderItem(true);
        }
      }
      handleClickTab && handleClickTab(tabType);
    }, [tabType, handleClickTab, setReservedOrderOnly, setNotDisplayOrderItem]);

    const orderListLength = useMemo(
      () =>
        OrderModel.getFilteredOrderListByTabType(orderList, tabType, shopData, reservedOrderOnly)
          .length,
      [orderList, tabType, shopData, reservedOrderOnly],
    );

    const existsOrder = useMemo(() => orderListLength > 0, [orderListLength]);

    return (
      <HeaderTab
        active={tabType === currentTabType}
        exists={existsOrder && tabType !== ORDER_LIST_TAB_TYPE.FINISHED}
        handleClick={handleClick}
      >
        <Inner>
          {OrderModel.getPresentationOrderListTabText(tabType)}
          {existsOrder ? `(${orderListLength})` : undefined}
        </Inner>
      </HeaderTab>
    );
  },
);
