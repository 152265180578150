import React from "react";
import styled from "styled-components";
import { fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { selectStyle } from "components/atoms/FormFields/SelectField";
import { reasonForItemsStopList, ReasonForItemsStop } from "records/ShopItem";

const Select = styled.select<{ isSelected: boolean }>`
  ${selectStyle};
  ${p =>
    p.isSelected
      ? `
    ${fontSize.LARGE};
    font-weight: ${fontWeightsConst.BOLD};
  `
      : `
    ${fontSize.DEFAULT};
  `}
`;

type Props = {
  handleChange?: (shopItemId: number, stopReasonType: ReasonForItemsStop) => void;
  isSelected: boolean;
  shopItemId: number;
  defaultValue?: ReasonForItemsStop;
};

export const ReasonForItemsStopSelectField: React.FC<Props> = React.memo(
  ({ handleChange, isSelected, shopItemId, defaultValue }) => (
    <Select
      onChange={event => {
        if (handleChange !== undefined) {
          handleChange(shopItemId, Number(event.target.value) as ReasonForItemsStop);
        }
      }}
      isSelected={isSelected}
      defaultValue={defaultValue}
    >
      {reasonForItemsStopList.map(reason => (
        <option key={reason.key} value={reason.key}>
          {reason.text}
        </option>
      ))}
    </Select>
  ),
);
