import React, { useContext } from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { ShopData } from "records/ShopData";
import { OrderListTabType, displayableOrderListTabType } from "modules/order/model";
import { HeaderContainer, HeaderInner, HeaderTitle } from "components/atoms/HeaderElement";
import { OrderListHeaderTab } from "components/molecules/OrderListHeaderTab";
import { LatestAccessTime } from "./LatestAccessTime";
import { InformationButton } from "./InformationButton";
import { OrderListFilter } from "./OrderListFilter";
import { OrderListContext } from "./OrderListContext";

const Container = styled.div`
  flex-grow: 1;
`;

const OrderListHeaderTop = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OrderListFilterContainer = styled.div`
  padding: 1.5em 1em 0;
`;

const LatestAccessTimeContainer = styled.div`
  position: absolute;
  left: 1em;
  top: 50%;
  transform: translateY(-50%);
`;

const NotificationContainer = styled.div`
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
`;

const OrderListTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: -1.25em;
`;

type Props = {
  orderList: OrderData[];
  shopData: ShopData;
  orderListTabType: OrderListTabType;
  reservedOrderOnly: boolean;
  handleClickTab: (tabType: OrderListTabType) => void;
};

export const OrderListHeader: React.FC<Props> = React.memo(
  ({ orderList, shopData, orderListTabType, reservedOrderOnly, handleClickTab }) => {
    const { setReservedOrderOnly, setNotDisplayOrderItem } = useContext(OrderListContext);
    return (
      <HeaderContainer>
        <HeaderInner>
          <Container>
            <OrderListHeaderTop>
              <LatestAccessTimeContainer>
                <LatestAccessTime />
              </LatestAccessTimeContainer>
              <HeaderTitle>注文一覧</HeaderTitle>
              <NotificationContainer>
                <InformationButton />
              </NotificationContainer>
            </OrderListHeaderTop>
            <OrderListFilterContainer>
              <OrderListFilter tabType={orderListTabType} />
            </OrderListFilterContainer>
            <OrderListTabContainer>
              {displayableOrderListTabType.map(tabType => (
                <OrderListHeaderTab
                  key={tabType}
                  orderList={orderList}
                  shopData={shopData}
                  tabType={tabType}
                  currentTabType={orderListTabType}
                  reservedOrderOnly={reservedOrderOnly}
                  handleClickTab={handleClickTab}
                  setReservedOrderOnly={setReservedOrderOnly}
                  setNotDisplayOrderItem={setNotDisplayOrderItem}
                />
              ))}
            </OrderListTabContainer>
          </Container>
        </HeaderInner>
      </HeaderContainer>
    );
  },
);
