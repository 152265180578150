import React, { useCallback } from "react";
import styled from "styled-components";
import { FontSizes, colorsConst, fontFamilyConst } from "styles/const";
import { fontSize } from "styles/mixins";

const Container = styled.input<{ fontSize?: FontSizes }>`
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  font-family: ${fontFamilyConst};
  appearance: none;
  background-color: ${colorsConst.BACKGROUND.WHITE};
  border: none;
  border-bottom: 2px solid ${colorsConst.BORDER.THICK};
  border-radius: 0;
  ${p => (typeof p.fontSize !== "undefined" ? fontSize[p.fontSize] : fontSize.DEFAULT)};
`;

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  fontSize?: FontSizes;
  inputStyle?: React.CSSProperties;
  handleBlur?: () => void;
};

export const TextInput: React.FC<Props> = React.memo(
  ({ fontSize, inputStyle, handleBlur, ...inputProps }) => {
    const handleBlurWrap = useCallback(() => {
      if (typeof handleBlur !== "undefined") {
        handleBlur();
      }
      const inputs = Array.prototype.slice.call(
        document.querySelectorAll("input,textarea,select"),
        0,
      );
      setTimeout(() => {
        if (!inputs.some((element: HTMLInputElement) => document.activeElement === element)) {
          document.body.scrollTop = 0;
        }
      }, 100);
    }, [handleBlur]);

    return (
      <Container style={inputStyle} fontSize={fontSize} {...inputProps} onBlur={handleBlurWrap} />
    );
  },
);
