import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API_KEY } from "apis";
import { ShopItemParentCategory } from "records/ShopItemParentCategory";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { userAccessedToPageThatNeedsShopItemParentCategories } from "modules/shop/actions";
import { shopItemParentCategoriesSelector } from "modules/shop/selectors";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { NoData } from "components/atoms/NoData";
import { Article } from "components/organisms/Article";
import { ShopItemParentCategoryList } from "components/organisms/ShopItemParentCategoryList";

const isConnectedApiSelector = isConnectedApiSelectorFactory([API_KEY.GET_PARENT_CATEGORY_LIST]);

type Props = {};

export const ShopItemParentCategoriesTemplate: React.FC<Props> = React.memo(() => {
  const loading = useSelector(isConnectedApiSelector);
  const parentCategories = useSelector(shopItemParentCategoriesSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopItemParentCategories());
  }, [dispatch]);

  return (
    <Article
      withHeader
      title="セクションカテゴリの並び替え"
      goBack="/setting"
      withNavigation
      activePath="SETTING"
    >
      {loading ? (
        <LoadingContainer />
      ) : ShopItemParentCategory.exists(parentCategories) ? (
        <ShopItemParentCategoryList categories={parentCategories} />
      ) : (
        <NoData>カテゴリーがありません</NoData>
      )}
    </Article>
  );
});
