import { call } from "redux-saga/effects";
import { API_KEY, GetShopDataResponse } from "apis";
import { SentryUtil } from "utils/SentryUtil";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";

export function* getShopDataSaga() {
  try {
    const { result, error }: { result: GetShopDataResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.GET_SHOP_DATA,
      {},
    );
    if (result && !error) {
      SentryUtil.setShopId(result.shop_data.id);
      return { result };
    }
    throw error;
  } catch (error) {
    return { error };
  }
}
