import React from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { Text } from "components/atoms/Text";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const OrderNumber = styled.div`
  margin-right: 0.5em;
`;

type Props = {
  orderData: OrderData;
};

export const OrderNumberContainer: React.FC<Props> = React.memo(({ orderData }) => (
  <Container>
    <OrderNumber>
      <Text size="XXLARGE" weight="BOLD">
        {orderData.display_order_no}
      </Text>
    </OrderNumber>
    <Text size="SMALL" weight="BOLD">
      {OrderData.getPresentationReceiveTypeText(orderData.receive_type, orderData.time_type)}
    </Text>
  </Container>
));
