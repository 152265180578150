import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShopItem } from "records/ShopItem";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import { systemOpenedModal } from "modules/app/actions";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Text } from "components/atoms/Text";
import { Utility } from "utils/Utility";
import { Thumbnail } from "components/atoms/Thumbnail";
import { shopDataSelector } from "modules/shop/selectors";
import { ShopData } from "records/ShopData";

const ThumbnailSize = 56;

const Container = styled.div`
  display: flex;
`;

const ClickableText = styled.span`
  text-decoration: underline;
  vertical-align: top;
`;

const ThumbnailContainer = styled.div`
  margin: 0 0.7em 0.2em 0;
`;

const TextIconContainer = styled.div`
s`;

const IconContainer = styled.div`
  display: inline-block;
  margin-left: 0.5em;
  ${fontSize.LARGE};
  color: ${colorsConst.TEXT.LINK};
  vertical-align: top;
`;

type Props = {
  item: ShopItem;
  isDetail?: boolean;
};

export const ShopItemName: React.FC<Props> = React.memo(({ item }) => {
  const dispatch = useDispatch();
  const shopData = useSelector(shopDataSelector);

  const handleClick = useCallback(() => {
    dispatch(systemOpenedModal("ORDER_ITEM_IMAGE", { imagePath: item.image_path }));
  }, [dispatch, item]);

  return (
    <Container>
      {ShopData.isShowDisplayImages(shopData) && (
        <ThumbnailContainer onClick={handleClick}>
          <Thumbnail
            imagePath={Utility.getCdnImagePath(item.image_path, ThumbnailSize)}
            width={ThumbnailSize}
            height={ThumbnailSize}
          />
        </ThumbnailContainer>
      )}
      <TextIconContainer>
        <ClickableText onClick={handleClick}>
          <Text size="DEFAULT" weight="BOLD">
            {item.item_name}
          </Text>
        </ClickableText>
        <IconContainer>
          <FontAwesomeIcon icon={faImages} />
        </IconContainer>
      </TextIconContainer>
    </Container>
  );
});
