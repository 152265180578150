import { STAFF_ROLE_TYPE, StaffRoleType } from "../records/ShopStaff";

export type PerformanceType = "normalOnly" | "own" | "others";
export type PermissionData = {
  staff_id: number;
  owner_id: number;
};

type Rule = {
  [role in StaffRoleType]?: {
    static: PerformanceType[];
    dynamic?: {
      [performance in PerformanceType]?: (data: PermissionData) => void;
    };
  };
};

const rules: Rule = {
  [STAFF_ROLE_TYPE.NORMAL]: {
    static: ["normalOnly"],
    dynamic: {
      own: (data: PermissionData) => data.owner_id === data.staff_id,
      others: (data: PermissionData) => data.owner_id !== data.staff_id,
    },
  },
  [STAFF_ROLE_TYPE.PICKER]: {
    static: [],
    dynamic: {
      own: (data: PermissionData) => data.owner_id === data.staff_id,
      others: (data: PermissionData) => data.owner_id !== data.staff_id,
    },
  },
};

const check = (role: StaffRoleType, performance: PerformanceType, data?: PermissionData) => {
  const permissions = rules[role];
  if (typeof permissions === "undefined") {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(performance)) {
    // static rule not provided for performance
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (typeof data !== "undefined" && typeof dynamicPermissions !== "undefined") {
    const permissionCondition = dynamicPermissions[performance];
    if (typeof permissionCondition === "undefined") {
      // dynamic rule not provided for performance
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export const RbacUtils = Object.freeze({
  check,
});
