import React from "react";
import styled from "styled-components";

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type Props = {
  children: React.ReactNode;
};

export const NoData: React.FC<Props> = React.memo(({ children }) => (
  <Container>{children}</Container>
));
