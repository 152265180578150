import React from "react";
import styled from "styled-components";
import { FontSizes, TextColors, colorsConst, FontWeights, fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";

const Container = styled.span<{ size?: FontSizes; color?: TextColors; weight?: FontWeights }>`
  ${p => (typeof p.size !== "undefined" ? fontSize[p.size] : "")};
  ${p => (typeof p.color !== "undefined" ? `color: ${colorsConst.TEXT[p.color]}` : "")};
  ${p => (typeof p.weight !== "undefined" ? `font-weight: ${fontWeightsConst[p.weight]}` : "")};
`;

type Props = {
  children: string | React.ReactNode;
  size?: FontSizes;
  color?: TextColors;
  style?: React.CSSProperties;
  weight?: FontWeights;
};

export const Text: React.FC<Props> = React.memo(({ size, color, weight, children, style }) => (
  <Container size={size} color={color} weight={weight} style={style}>
    {children}
  </Container>
));
