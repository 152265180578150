export type ShopInvitationCode = {
  head_office_invitation_code: string;
  shop_invitation_code: string;
};

const initialState: ShopInvitationCode = {
  head_office_invitation_code: "hoge-hoge",
  shop_invitation_code: "fuga-fuga",
};

const create = (args: Partial<ShopInvitationCode> = {}) => ({
  ...initialState,
  ...args,
});

export const ShopInvitationCode = Object.freeze({
  initialState,
  create,
});
