import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  invert?: boolean;
};

export const IconOrderHistory: React.FC<Props> = React.memo(({ invert }) => {
  const color = invert ? colorsConst.WHITE : colorsConst.ICON.DEFAULT;
  return (
    <svg width="1em" height="1em" viewBox="0 0 34 34" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M18.4,4.8c-6.6,0-12,5.4-12,12.2c0,0.7-0.5,1.2-1.2,1.2S4,17.7,4,17C4,9,10.4,2.4,18.4,2.4 c8,0,14.4,6.5,14.4,14.6c0,8-6.4,14.6-14.4,14.6c-0.7,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2c6.6,0,12-5.4,12-12.2 C30.4,10.3,25,4.8,18.4,4.8z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M14.6,29.7c-0.3,0.7-1,1-1.7,0.7l0,0c-0.7-0.3-1-1-0.7-1.7c0.3-0.7,1-1,1.7-0.7l0,0 C14.6,28.3,14.9,29,14.6,29.7z M10,27.3c-0.5,0.5-1.3,0.5-1.8,0l0,0c-0.5-0.5-0.5-1.3,0-1.8c0.5-0.5,1.3-0.5,1.8,0l0,0 C10.5,25.9,10.5,26.8,10,27.3z M6.7,23.3c-0.7,0.3-1.4,0-1.7-0.7l0,0c-0.3-0.7,0-1.4,0.7-1.7c0.7-0.3,1.4,0,1.7,0.7l0,0 C7.6,22.2,7.3,23,6.7,23.3z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M18.1,8c0.7,0,1.2,0.5,1.2,1.2v6.7h5.5c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2h-6.7 c-0.7,0-1.2-0.5-1.2-1.2V9.2C16.9,8.6,17.5,8,18.1,8z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M1.7,12.3C2.2,11.9,3,12,3.4,12.5l2.1,2.8l2.7-2.1c0.5-0.4,1.3-0.3,1.7,0.2c0.4,0.5,0.3,1.3-0.2,1.7L5.9,18 c-0.3,0.2-0.6,0.3-0.9,0.2c-0.3,0-0.6-0.2-0.8-0.5L1.5,14C1.1,13.4,1.2,12.7,1.7,12.3z"
      />
    </svg>
  );
});
