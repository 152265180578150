import React, { useEffect } from "react";
import styled from "styled-components";
import { colorsConst, zIndexConst } from "styles/const";

const Wrapper = styled.div<{
  isOpen: boolean;
}>`
  position: fixed;
  z-index: ${zIndexConst.MODAL};
  top: 0;
  left: 0;
  display: ${p => (p.isOpen ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  opacity: ${p => (p.isOpen ? "1" : "0")};
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  overflow: hidden;
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: ${zIndexConst.MODAL - 1};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  overflow: hidden;
`;

const Content = styled.div`
  z-index: ${zIndexConst.MODAL};
  @media (orientation: portrait) {
    min-width: 70%;
  }
  @media (orientation: landscape) {
    min-width: 45%;
  }
`;

const Inner = styled.div`
  position: relative;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

type ModalContentProps = {
  children: React.ReactNode;
  contentStyle?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  onOpened?: () => void;
  onClosed?: () => void;
};

const ModalContent: React.FC<ModalContentProps> = React.memo(
  ({ contentStyle, innerStyle, onOpened, onClosed, children }) => {
    useEffect(() => {
      if (typeof onOpened !== "undefined") {
        onOpened();
      }
      return () => {
        if (typeof onClosed !== "undefined") {
          onClosed();
        }
      };
    }, [onOpened, onClosed]);
    return (
      <Content style={contentStyle}>
        <Inner style={innerStyle}>{children}</Inner>
      </Content>
    );
  },
);

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  wrapperStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  backdrop?: boolean;
  backdropStyle?: React.CSSProperties;
  handleClickBackdrop?: (event: React.MouseEvent<HTMLElement>) => void;
  onOpened?: () => void;
  onClosed?: () => void;
};

export const Modal: React.FC<Props> = React.memo(
  ({
    children,
    isOpen,
    wrapperStyle,
    contentStyle,
    innerStyle,
    backdrop = true,
    backdropStyle,
    handleClickBackdrop,
    onOpened,
    onClosed,
  }) => (
    <Wrapper isOpen={isOpen} style={wrapperStyle}>
      {isOpen ? (
        <>
          {backdrop ? <Backdrop style={backdropStyle} onClick={handleClickBackdrop} /> : undefined}
          <ModalContent
            contentStyle={contentStyle}
            innerStyle={innerStyle}
            onOpened={onOpened}
            onClosed={onClosed}
          >
            {children}
          </ModalContent>
        </>
      ) : undefined}
    </Wrapper>
  ),
);
