import { Utility } from "utils/Utility";

export const STAFF_ROLE_TYPE = {
  NORMAL: 1,
  PICKER: 2,
} as const;

export type StaffRoleType = typeof STAFF_ROLE_TYPE.NORMAL | typeof STAFF_ROLE_TYPE.PICKER;

export type ShopStaff = Readonly<{
  staff_id: number;
  login_id: string;
  display_name: string;
  gender: number;
  birthday: string;
  tel: string;
  role: StaffRoleType;
  custom_data: string;
}>;

const initialState: ShopStaff = {
  staff_id: 0,
  login_id: "",
  display_name: "",
  gender: 0,
  birthday: "",
  tel: "",
  role: STAFF_ROLE_TYPE.NORMAL,
  custom_data: "",
};

const create = (args: Partial<ShopStaff> = {}) => ({
  ...initialState,
  ...args,
});

const existsShopStaff = (shopstaff: ShopStaff) => shopstaff.staff_id !== 0;

const getLazonaStaffParentCategoryId = (shopStaff: ShopStaff) => {
  if (!Utility.isJsonString(shopStaff.custom_data)) {
    return undefined;
  }
  const parsedCustomData = JSON.parse(shopStaff.custom_data);
  return "parent_category_id" in parsedCustomData &&
    typeof parsedCustomData.parent_category_id !== "undefined"
    ? (parsedCustomData.parent_category_id as number)
    : undefined;
};

export const ShopStaff = Object.freeze({
  initialState,
  create,
  existsShopStaff,
  getLazonaStaffParentCategoryId,
});
