import React, { useMemo, useContext } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { DateUtils } from "utils/DateUtils";
import { ShopData } from "records/ShopData";
import { shopDataSelector } from "modules/shop/selectors";
import { colorsConst, fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Text } from "components/atoms/Text";
import { TextLink } from "components/atoms/TextLink";
import { PermissionContainer, PermissionContext } from "components/organisms/PermissionContext";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5em;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
`;

const Colomn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  & + & {
    margin-left: 0.5em;
  }
`;

const ReleaseStateLabel = styled.div`
  ${fontSize.XSMALL};
  white-space: nowrap;
`;

const ReleaseStateText = styled.div`
  margin-left: 0.25em;
  ${fontSize.SMALL};
  font-weight: ${fontWeightsConst.BOLD};
  white-space: nowrap;
`;

const ReceiveStateLabel = styled.div`
  ${fontSize.XSMALL};
`;

const ReceiveStateText = styled.div<{ isStop: boolean }>`
  margin-left: 0.25em;
  ${fontSize.SMALL};
  font-weight: ${fontWeightsConst.BOLD};
  white-space: nowrap;
  ${p => (p.isStop ? `color: ${colorsConst.TEXT.DANGER}` : "")}
`;

const ReceiveStateButton = styled(TextLink)`
  ${fontSize.SMALL};
  white-space: nowrap;
`;

const NotPublishedText = styled.div`
  margin-left: 0.5em;
`;

type Props = {};

export const ReleaseState: React.FC<Props> = React.memo(() => {
  const { role } = useContext(PermissionContext);

  const shopData = useSelector(shopDataSelector);

  return (
    <Container>
      <Colomn>
        <Colomn>
          <ReleaseStateLabel>
            menu
            <wbr />
            掲載
          </ReleaseStateLabel>
          <ReleaseStateText>
            {ShopData.getPresentationReleaseStateText(shopData.release_state)}
          </ReleaseStateText>
        </Colomn>
        {ShopData.isPublished(shopData) ? (
          <Colomn>
            <ReceiveStateLabel>
              {ShopData.getPresentationAavailableReceiveTypeText(shopData)}
            </ReceiveStateLabel>
            <ReceiveStateText isStop={shopData.shop_order_stop_flag}>
              {shopData.shop_order_stop_flag ? "停止中" : "受付中"}
            </ReceiveStateText>
          </Colomn>
        ) : undefined}
      </Colomn>
      {ShopData.isPublished(shopData) ? (
        <Colomn>
          <PermissionContainer role={role} performance="normalOnly">
            <Colomn>
              <ReceiveStateButton to="/shopBusinessSetting">店舗受付設定</ReceiveStateButton>
            </Colomn>
          </PermissionContainer>
          {ShopData.existsShopRestartTime(shopData.restart_reserved_datetime) ? (
            <Colomn>
              <Text size="SMALL">
                {`（再開予定時間： ${DateUtils.dateToString(
                  shopData.restart_reserved_datetime,
                  "HH:mm",
                )}）`}
              </Text>
            </Colomn>
          ) : undefined}
        </Colomn>
      ) : (
        <NotPublishedText>
          <Text size="SMALL">
            {ShopData.getPresentationNotPublishedText(shopData.release_state)}
          </Text>
        </NotPublishedText>
      )}
    </Container>
  );
});
