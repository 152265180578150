/* eslint-disable default-param-last */
import { ActionTypes, ShopActions } from "./actions";
import { ShopState, ShopModel } from "./model";

export default function reducer(state: ShopState = ShopModel.initialState, action: ShopActions) {
  switch (action.type) {
    case ActionTypes.R_UPDATE_SHOP_DATA:
      return ShopModel.updateShopData(state, action.payload.value);
    case ActionTypes.R_UPDATE_SHOP_STAFF:
      return ShopModel.updateShopStaff(state, action.payload.value);
    case ActionTypes.R_UPDATE_SHOP_ITEMS:
      return ShopModel.updateShopItems(state, action.payload.value);
    case ActionTypes.R_UPDATE_SHOP_ITEM_OPTIONS:
      return ShopModel.updateShopItemOptionSets(state, action.payload.value);
    case ActionTypes.R_UPDATE_SHOP_ITEM_PARENT_CATEGORIES:
      return ShopModel.updateShopItemParentCategories(state, action.payload.value);
    case ActionTypes.R_UPDATE_SHOP_BUSINESS:
      return ShopModel.updateShopBusiness(state, action.payload.value);
    case ActionTypes.R_UPDATE_SHOP_INVITATION_CODE:
      return ShopModel.updateShopInvitationCode(state, action.payload.value);
    case ActionTypes.R_UPDATE_DISPLAY_IMAGES:
      return ShopModel.updateDisplayImages(state, action.payload.enable);
    case ActionTypes.R_UPDATE_AUTO_PRINT_SETTING:
      return ShopModel.updateAutoPrintSetting(state, action.payload.setting);
    case ActionTypes.R_ENEBLE_IMMEDIATE_DELIVERY_PAUSED:
      return ShopModel.enableImmediateDeliveryPaused(state);
    case ActionTypes.R_UPDATE_PUSH_NOTIFICATION_SETTING:
      return ShopModel.updatePushNotificationSetting(state, action.payload);
    default:
      return state;
  }
}
