import { call, fork } from "redux-saga/effects";
import { GetShopDataResponse } from "apis";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { getShopDataSaga, updateShopDataSaga } from ".";

export function* userAccessedToPageThatNeedsShopDataSaga() {
  try {
    const { result, error }: { result: GetShopDataResponse; error: unknown } = yield call(
      getShopDataSaga,
    );
    if (result && !error) {
      yield fork(updateShopDataSaga, result);
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
