import { put, select } from "redux-saga/effects";
import { CordovaUtil } from "utils/CordovaUtil";
import { systemPrintReceipt, UserSubmittedPrintReceiptAction } from "../actions";
import { enebleBluetoothSelector } from "../selectors";

export function* userSubmittedPrintReceiptSaga(action: UserSubmittedPrintReceiptAction) {
  const enebleBluetooth: ReturnType<typeof enebleBluetoothSelector> = yield select(
    enebleBluetoothSelector,
  );
  if (CordovaUtil.isCordovaEnabled() && enebleBluetooth) {
    yield put(systemPrintReceipt(action.payload.order));
  }
}
