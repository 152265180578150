import React from "react";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { Text } from "components/atoms/Text";
import { OrderItemData } from "records/OrderItemData";

const Container = styled.div`
  padding: 0.25em 0.5em;
  background-color: ${colorsConst.BACKGROUND.INFO};
`;

type Props = {
  item: OrderItemData;
};

export const OrderDetailItemWeightRangeWarning: React.FC<Props> = React.memo(({ item }) => (
  <Container>
    <Text color="DANGER">{`${item.input_min_quantity}〜${
      item.input_max_quantity
    }${OrderItemData.getPresentationUnitString(item.unit)}間の数値を入力してください`}</Text>
  </Container>
));
