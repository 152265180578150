import { call, fork, put } from "redux-saga/effects";
import { API_KEY, SetAutoPrintingRequest, SetAutoPrintingResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateAutoPrintSetting, UserSubmittedAutoPrintSettingAction } from "../actions";

export function* userSubmittedAutoPrintSettingSaga(action: UserSubmittedAutoPrintSettingAction) {
  try {
    const { enable, count, fontSize } = action.payload;
    const params: SetAutoPrintingRequest = {
      enable,
      print_count: count,
      font_size: fontSize,
    };
    const { result, error }: { result: SetAutoPrintingResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.SET_AUTO_PRINTING,
      params,
    );
    if (result && !error) {
      yield put(
        updateAutoPrintSetting({
          is_auto_printing: result.enable,
          print_count: result.print_count,
          font_size: result.font_size,
        }),
      );
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
