import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconUnapproved: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
      <path
        fill={fillColor}
        d="M14.6,9.1c0.6-1.8,2.4-2.8,4.3-2.4l17.8,4.1c2.1,0.5,3.3,2.7,2.6,4.7l-1.9,5.6l4-2l0.9-2.6 c1.3-3.8-1-7.8-4.9-8.7L19.6,3.7c-3.4-0.8-6.8,1.1-7.9,4.4L5.4,27c-0.3,0.8,0.2,1.7,1,2s1.7-0.2,2-1L14.6,9.1z"
      />
      <path
        fill={fillColor}
        d="M33.1,32.3c-0.3,0.2-0.7,0.2-1,0.1l-2.4-0.8c-0.7-0.2-1.1-1-0.8-1.7l0.8-2.4c0.1-0.3,0.3-0.6,0.6-0.7l12.8-6.5 c0.6-0.3,1.4-0.1,1.7,0.6l1.6,3.2c0.3,0.6,0.1,1.4-0.6,1.7L33.1,32.3z"
      />
      <path
        fill={fillColor}
        d="M33.9,33.9c-0.3,0.1-0.5,0.2-0.8,0.3l-1.4,4.3c-0.5,1.6-2.1,2.7-3.7,2.7c0,0-0.1,0-0.1,0 C27.2,41.1,26,41,26,39.4c0.1-0.3,0.1-0.7,0.2-1c0.3-1.3-0.5-2.6-1.8-2.9L3.5,30.6C2.3,30.3,1.1,31,1,32.3c-0.1,1.7,0.3,3.5,1.1,5 c0.5,0.9,1.4,1.4,2.4,1.7l22.2,5.2c0,0,0,0,0,0c3.4,0.6,6.8-1.4,7.9-4.7l2.4-7.1L33.9,33.9z"
      />
      <path
        fill={fillColor}
        d="M17,13c0.1-0.5,0.6-0.8,1.1-0.7l14.3,3.2c0.5,0.1,0.8,0.6,0.7,1.1c-0.1,0.5-0.6,0.8-1.1,0.7l-14.3-3.2 C17.2,14,16.9,13.5,17,13z"
      />
      <path
        fill={fillColor}
        d="M15.7,18.2c0.1-0.5,0.6-0.8,1.1-0.7l11,2.6c0.5,0.1,0.8,0.6,0.7,1.1c-0.1,0.5-0.6,0.8-1.1,0.7l-11-2.6 C15.9,19.2,15.6,18.7,15.7,18.2z"
      />
      <path
        fill={fillColor}
        d="M15.5,22.8c-0.5-0.1-1,0.2-1.1,0.7c-0.1,0.5,0.2,1,0.7,1.1l11,2.6c0.5,0.1,1-0.2,1.1-0.7c0.1-0.5-0.2-1-0.7-1.1 L15.5,22.8z"
      />
    </svg>
  );
});
