import { put, cancel } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { AppError } from "records/AppError";
import { systemOpenedModal } from "../actions";

export function* commonErrorSaga(error?: AppError) {
  yield put(
    systemOpenedModal(
      "APP_ERROR",
      AppError.create(
        error || {
          title: "エラー",
          message: "エラーが発生しました。\nお手数ですが再度ログインしてください。",
        },
      ),
    ),
  );
  yield put(replace("/"));
  yield cancel();
}
