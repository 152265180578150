import React, { useCallback } from "react";
import styled from "styled-components";
import { FieldArray, useFieldArray, FieldArrayRenderProps } from "react-final-form-arrays";
import { BusinessType, BusinessWeekTime } from "records/BusinessWeekTime";
import { BusinessTime } from "records/BusinessTime";
import { DateUtils } from "utils/DateUtils";
import { colorsConst, fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { BusunessTypeField } from "components/molecules/BusunessTypeField";
import { BusinessTimeField } from "components/molecules/BusinessTimeField";
import { BusinessTimeFieldError } from "./BusinessTimeFieldError";

const Container = styled.div`
  margin-top: 1em;
  padding: 0.5em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

const WeekDayContainer = styled.div`
  ${fontSize.LARGE};
  font-weight: ${fontWeightsConst.MEDIUM};
`;

type Props = {
  fieldArrayName: string;
};

export const BusinessWeekTimeField: React.FC<Props> = React.memo(({ fieldArrayName }) => {
  const fieldArrayProps: FieldArrayRenderProps<BusinessWeekTime, HTMLElement> =
    useFieldArray(fieldArrayName);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, type: BusinessType, index: number) => {
      const currentValue = fieldArrayProps.fields.value[index];
      const normalizedBusinessType = parseInt(event.target.value, 10) as BusinessType;
      const updatedBusinessTimes = BusinessTime.getUpdatedBusinessTimesByBusinessType(
        currentValue.business_times,
        normalizedBusinessType,
      );
      const updatedValue = {
        ...currentValue,
        business_type: normalizedBusinessType,
        business_times: updatedBusinessTimes,
      };
      fieldArrayProps.fields.update(index, updatedValue);
    },
    [fieldArrayProps],
  );

  return (
    <FieldArray name={fieldArrayName}>
      {({ fields, meta: { error } }) =>
        fields.map((name, index) => (
          <Container key={index}>
            <WeekDayContainer>
              {DateUtils.getWeekdayStringByWeekNumber(fields.value[index]["week"])}
            </WeekDayContainer>
            <BusunessTypeField name={name} week={index} handleChange={handleChange} />
            <BusinessTimeField name={`${name}.business_times`} />
            <BusinessTimeFieldError index={index} error={error} />
          </Container>
        ))
      }
    </FieldArray>
  );
});
