import { put, call, fork, all } from "redux-saga/effects";
import { API_KEY, StopOrderByOptionSetRequest, StopOrderByOptionSetResponse } from "apis";
import { StopItemsByStopOptionSet } from "records/StopItemsByStopOptionSet";
import { updateSnackBar } from "modules/app/actions";
import { commonApiSaga, commonErrorSaga } from "modules/app/sagas";
import {
  systemUpdateShopItemStatus,
  systemUpdateShopItemOptionStatus,
  UserSubmittedStopOrderByOptionSetsAction,
} from "../actions";
import { ShopModel, SHOP_ITEM_SUBMIT_TYPE } from "../model";

export function* userSubmittedStopOrderByOptionSetsSaga(
  action: UserSubmittedStopOrderByOptionSetsAction,
) {
  try {
    const { optionSet } = action.payload;
    const params: StopOrderByOptionSetRequest = {
      option_set_list: [optionSet],
    };
    const { result, error }: { result: StopOrderByOptionSetResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.STOP_ORDER_BY_OPTION_SET,
      params,
    );
    if (result && !error) {
      const updatedIds = ShopModel.getShopItemIdsAndOptionIdsToBeUpdated(
        [optionSet],
        result.option_set_list,
      );
      if (updatedIds.itemIds.length > 0) {
        yield all([
          put(systemUpdateShopItemStatus(updatedIds.itemIds, SHOP_ITEM_SUBMIT_TYPE.STOP, true)),
          put(systemUpdateShopItemOptionStatus(updatedIds.optionIds, SHOP_ITEM_SUBMIT_TYPE.STOP)),
          put(updateSnackBar("オプションの受付を停止しました")),
        ]);
      } else {
        yield all([
          put(systemUpdateShopItemOptionStatus(updatedIds.optionIds, SHOP_ITEM_SUBMIT_TYPE.STOP)),
          put(updateSnackBar("オプションの受付を停止しました")),
        ]);
      }
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
