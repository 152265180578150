import { OrderData } from "records/OrderData";
import { OrderItemData } from "records/OrderItemData";
import {
  PrinterFontSizeType,
  PRINTER_FONT_SIZE_TYPE,
  PrinterPaperWidthType,
} from "records/AutoPrintSetting";
import { AppModel } from "modules/app/model";
import { Starprnt, RasterObject, Emulator } from "../@types/Starprnt";
import { Utility } from "./Utility";
import { DateUtils } from "./DateUtils";
import { PriceUtils } from "./PriceUtils";

declare const starprnt: Starprnt;

const getEmulatorByModelName = (modelName: string): Emulator => {
  if (/^.*TSP1.*$/.test(modelName)) {
    return "StarGraphic";
  }
  if (/^.*Star Micronics.*$/.test(modelName)) {
    return "StarLine";
  }
  return "StarPRNT";
};

const getPortNameByPlatform = (
  portName: string,
  emulator: Emulator,
  platformId: "ios" | "android" | "browser",
): string | null => (platformId === "ios" && emulator === "StarGraphic" ? null : portName);

const getByteLimitsPerLine = (fontSize: PrinterFontSizeType, paperWidth: PrinterPaperWidthType) => {
  if (paperWidth === 576) {
    switch (fontSize) {
      case PRINTER_FONT_SIZE_TYPE.SMALL:
        return 38;
      case PRINTER_FONT_SIZE_TYPE.MEDIUM:
        return 25;
      case PRINTER_FONT_SIZE_TYPE.LARGE:
      default:
        return 19;
    }
  }
  switch (fontSize) {
    case PRINTER_FONT_SIZE_TYPE.SMALL:
      return 38;
    case PRINTER_FONT_SIZE_TYPE.MEDIUM:
      return 25;
    case PRINTER_FONT_SIZE_TYPE.LARGE:
    default:
      return 16;
  }
};

const getMultiByteLimitsPerLine = (
  fontSize: PrinterFontSizeType,
  paperWidth: PrinterPaperWidthType,
) => {
  if (paperWidth === 576) {
    switch (fontSize) {
      case PRINTER_FONT_SIZE_TYPE.SMALL:
        return 23;
      case PRINTER_FONT_SIZE_TYPE.MEDIUM:
        return 15;
      case PRINTER_FONT_SIZE_TYPE.LARGE:
      default:
        return 12;
    }
  }
  switch (fontSize) {
    case PRINTER_FONT_SIZE_TYPE.SMALL:
      return 22;
    case PRINTER_FONT_SIZE_TYPE.MEDIUM:
      return 15;
    case PRINTER_FONT_SIZE_TYPE.LARGE:
    default:
      return 10;
  }
};

const getMultiByteStringRatio = (
  fontSize: PrinterFontSizeType,
  paperWidth: PrinterPaperWidthType,
) => getByteLimitsPerLine(fontSize, paperWidth) / getMultiByteLimitsPerLine(fontSize, paperWidth);

const getPaperWidthByEmulator = (emulator: Emulator): PrinterPaperWidthType => {
  switch (emulator) {
    case "StarGraphic":
    case "StarLine":
      return 576;
    case "StarPRNT":
    default:
      return 384;
  }
};

const getPresentationFontSizeText = (fontSize: PrinterFontSizeType) => {
  if (fontSize === PRINTER_FONT_SIZE_TYPE.SMALL) {
    return "小";
  }
  if (fontSize === PRINTER_FONT_SIZE_TYPE.MEDIUM) {
    return "中";
  }
  return "大";
};

const getFontSizeValueByFontSizeType = (
  fontSize: PrinterFontSizeType,
  paperWidth: PrinterPaperWidthType,
) => {
  if (paperWidth === 576) {
    if (fontSize === PRINTER_FONT_SIZE_TYPE.SMALL) {
      return 25;
    }
    if (fontSize === PRINTER_FONT_SIZE_TYPE.MEDIUM) {
      return 38;
    }
    return 48;
  }
  if (fontSize === PRINTER_FONT_SIZE_TYPE.SMALL) {
    return 17;
  }
  if (fontSize === PRINTER_FONT_SIZE_TYPE.MEDIUM) {
    return 25;
  }
  return 38;
};

const isSmL200 = (modelName: string) =>
  getEmulatorByModelName(modelName) === "StarPRNT" && /^.*L200.*$/.test(modelName);

const generateSeparator = (fontSize: PrinterFontSizeType, paperWidth: PrinterPaperWidthType) =>
  Array(getByteLimitsPerLine(fontSize, paperWidth))
    .fill("")
    .map(_ => "-")
    .join("");

const search = () =>
  new Promise(resolve => {
    starprnt.portDiscovery(
      "All",
      result => {
        resolve({ result });
      },
      error => {
        resolve({ error });
      },
    );
  });

const connect = (portName: string, modelName: string) =>
  new Promise(resolve => {
    starprnt.connect(portName, getEmulatorByModelName(modelName), false, (error, result) => {
      if (result && !error) {
        resolve({ connectResult: result });
      } else {
        resolve({ connectError: error });
      }
    });
  });

const disconnect = () =>
  new Promise(resolve => {
    starprnt.disconnect(
      result => {
        resolve({ disconnectResult: result });
      },
      error => {
        resolve({ disconnectError: error });
      },
    );
  });

const checkStatus = (portName: string, modelName: string) =>
  new Promise(resolve => {
    starprnt.checkStatus(
      portName,
      getEmulatorByModelName(modelName),
      result => {
        resolve({ checkStatusResult: result });
      },
      error => {
        resolve({ checkStatusError: error });
      },
    );
  });

const printRasterReceipt = (port: string | null, modelName: string, rasterObj: RasterObject) =>
  new Promise(resolve => {
    starprnt.printRasterReceipt(
      port,
      getEmulatorByModelName(modelName),
      rasterObj,
      result => {
        resolve({ printResult: result });
      },
      error => {
        resolve({ printError: error });
      },
    );
  });

const textAlignment = (
  text: string,
  emulator: Emulator,
  alignmrnt: "right" | "center",
  fontSize: PrinterFontSizeType,
) => {
  const paperWidth = getPaperWidthByEmulator(emulator);
  const byteLimitsPerLine = getByteLimitsPerLine(fontSize, paperWidth);
  if (alignmrnt === "right") {
    return splitStringByBytes(
      `${Array(byteLimitsPerLine)
        .fill("")
        .map(_ => " ")
        .join("")}${text}`,
      emulator,
      fontSize,
      true,
    );
  }
  const textBytes = text
    .split("")
    .reduce(
      (acc, crr) =>
        !Utility.isMultiByte(crr) ? acc + 1 : acc + getMultiByteStringRatio(fontSize, paperWidth),
      0,
    );
  return `${Array(Math.floor((byteLimitsPerLine - textBytes) / 2))
    .fill("")
    .map(_ => " ")
    .join("")}${text}`;
};

const generateOrderItemsText = (
  order: OrderData,
  emulator: Emulator,
  fontSize: PrinterFontSizeType,
) =>
  order.item_list.reduce(
    (accum, curr) =>
      `${curr.option_set_list.reduce(
        (acc, crr) =>
          `${acc}${crr.option_list.reduce(
            (ac, cr) =>
              `${ac}${splitStringByBytes(`${"　　"}${cr.option_name}`, emulator, fontSize)}\n`,
            `${splitStringByBytes(`${"　"}${crr.option_set_name}`, emulator, fontSize)}\n`,
          )}`,
        `${accum}${curr.item_name}${curr.is_campaign ? "　+1無料分" : ""}\n`,
      )}${curr.instruction_text !== "" ? `${curr.instruction_text}\n` : ""}${
        curr.supplement_text !== "" ? `${curr.supplement_text}\n` : ""
      }${textAlignment(
        `@\xA5${PriceUtils.getFormattedPrice(OrderItemData.getTotalItemPrice(curr))}${"　"}${
          curr.total_order_count
        }${OrderItemData.getPresentationUnitString(curr.unit)}\n`,
        emulator,
        "right",
        fontSize,
      )}\n`,
    "",
  );

const generatePrintRasterObject = (
  order: OrderData,
  emulator: Emulator,
  fontSize: PrinterFontSizeType,
) => {
  const paperWidth = getPaperWidthByEmulator(emulator);
  const object: RasterObject = {
    text: `menu 加盟店用
${generateSeparator(fontSize, paperWidth)}
${textAlignment(order.display_order_no, emulator, "center", fontSize)}
${generateSeparator(fontSize, paperWidth)}
${
  !OrderData.isFinishStatus(order.order_state)
    ? textAlignment(
        `印刷回数：${AppModel.getPrintCount(order.order_no) + 1}回目`,
        emulator,
        "right",
        fontSize,
      )
    : ""
}

${order.user_info.nick_name} 様
注文日時：${DateUtils.dateToString(order.order_date, "MM月DD日")} ${DateUtils.dateToString(
      order.order_date,
      "HH:mm",
    )}
受取日時：${DateUtils.dateToString(order.receive_datetime, "MM月DD日")} ${DateUtils.dateToString(
      order.receive_datetime,
      "HH:mm",
    )}

${generateSeparator(fontSize, paperWidth)}
${textAlignment(
  OrderData.getPresentationReceiveTypeText(order.receive_type),
  emulator,
  "center",
  fontSize,
)}
${generateSeparator(fontSize, paperWidth)}

${generateOrderItemsText(order, emulator, fontSize)}`,
    paperWidth,
    fontSize: getFontSizeValueByFontSizeType(fontSize, paperWidth),
  };
  return object;
};

const splitStringByBytes = (
  string: string,
  emulator: Emulator,
  fontSize: PrinterFontSizeType,
  reverse: boolean = false,
) => {
  const paperWidth = getPaperWidthByEmulator(emulator);
  const rowByteLimits = getByteLimitsPerLine(fontSize, paperWidth);
  return reverse
    ? string
        .split("")
        .reverse()
        .reduce(
          (acc: { str: string; bytes: number }, crr: string) => {
            const crrBytes = !Utility.isMultiByte(crr)
              ? 1
              : getMultiByteStringRatio(fontSize, paperWidth);
            if (acc.bytes + crrBytes < rowByteLimits) {
              return {
                str: `${crr}${acc.str}`,
                bytes: acc.bytes + crrBytes,
              };
            }
            return acc;
          },
          { str: "", bytes: 0 },
        ).str
    : string.split("").reduce(
        (acc: { str: string; bytes: number }, crr: string) => {
          const crrBytes = !Utility.isMultiByte(crr)
            ? 1
            : getMultiByteStringRatio(fontSize, paperWidth);
          if (acc.bytes + crrBytes < rowByteLimits) {
            return {
              str: `${acc.str}${crr}`,
              bytes: acc.bytes + crrBytes,
            };
          }
          return acc;
        },
        { str: "", bytes: 0 },
      ).str;
};

export const PrintUtils = Object.freeze({
  getEmulatorByModelName,
  getPortNameByPlatform,
  getPresentationFontSizeText,
  isSmL200,
  search,
  connect,
  disconnect,
  checkStatus,
  printRasterReceipt,
  generatePrintRasterObject,
});
