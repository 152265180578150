import { put, fork, call, all } from "redux-saga/effects";
import { API_KEY, StopReleaseByOptionSetRequest, StopReleaseByOptionSetResponse } from "apis";
import { StopItemsByStopOptionSet } from "records/StopItemsByStopOptionSet";
import { updateSnackBar } from "modules/app/actions";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { commonErrorSaga } from "../../app/sagas/commonErrorSaga";
import {
  systemUpdateShopItemStatus,
  systemUpdateShopItemOptionStatus,
  UserSubmittedStopReleaseByOptionSetsAction,
} from "../actions";
import { ShopModel, SHOP_ITEM_SUBMIT_TYPE } from "../model";

export function* userSubmittedStopReleaseByOptionSetsSaga(
  action: UserSubmittedStopReleaseByOptionSetsAction,
) {
  try {
    const { optionSet } = action.payload;
    const params: StopReleaseByOptionSetRequest = {
      option_set_list: [optionSet],
    };
    const { result, error }: { result: StopReleaseByOptionSetResponse; error: unknown } =
      yield call(commonApiSaga, API_KEY.STOP_RELEASE_BY_OPTION_SET, params);
    if (result && !error) {
      const updatedIds = ShopModel.getShopItemIdsAndOptionIdsToBeUpdated(
        [optionSet],
        result.option_set_list,
      );
      if (updatedIds.itemIds.length > 0) {
        yield all([
          put(
            systemUpdateShopItemStatus(updatedIds.itemIds, SHOP_ITEM_SUBMIT_TYPE.SUSPENSION, true),
          ),
          put(
            systemUpdateShopItemOptionStatus(
              updatedIds.optionIds,
              SHOP_ITEM_SUBMIT_TYPE.SUSPENSION,
            ),
          ),
          put(updateSnackBar("オプションを非公開にしました")),
        ]);
      } else {
        yield all([
          put(
            systemUpdateShopItemOptionStatus(
              updatedIds.optionIds,
              SHOP_ITEM_SUBMIT_TYPE.SUSPENSION,
            ),
          ),
          put(updateSnackBar("オプションを非公開にしました")),
        ]);
      }
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
