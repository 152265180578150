export type SalesMonthly = Readonly<{
  sales: number;
  credit_card_sales: number;
  local_sales: number;
  un_approve_count: number;
}>;

const initialState: SalesMonthly = {
  sales: 0,
  credit_card_sales: 0,
  local_sales: 0,
  un_approve_count: 0,
};

const create = (args: Partial<SalesMonthly> = {}) => ({
  ...initialState,
  ...args,
});

export const SalesMonthly = Object.freeze({
  initialState,
  create,
});
