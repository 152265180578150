import React, { useMemo, useCallback, useState } from "react";
import styled from "styled-components";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { AppModel } from "modules/app/model";
import { ModalDialog, ModalBody, ModalFooter } from "components/molecules/ModalDialog";
import { Button } from "../atoms/Button";

const TextContainer = styled.div`
  margin-bottom: 1em;
  text-align: center;
`;

type Props = {
  isOpen?: boolean;
};

const MODAL_KEY = "SHOULD_UPDATE_RESOURCE";
const useModal = modalHooksFactory(MODAL_KEY);

export const ShouldUpdateResourceDialog: React.FC<Props> = React.memo(({ isOpen }) => {
  const { data, close } = useModal();

  const [processingFlag, setProcessingFlag] = useState(false);

  const isDialogOpen = useMemo(() => isOpen || data.isOpen, [isOpen, data]);

  const handleClickUpdateResource = useCallback(() => {
    setProcessingFlag(true);
    AppModel.updateResource();
  }, []);

  return (
    <ModalDialog isOpen={isDialogOpen} close={close}>
      <ModalBody>
        <TextContainer>
          新しいデータがあります。
          <br />
          画面を読み込み直してください。
        </TextContainer>
        <ModalFooter>
          <Button
            appearance="primary"
            handleClick={handleClickUpdateResource}
            loading={processingFlag}
          >
            再読み込み
          </Button>
        </ModalFooter>
      </ModalBody>
    </ModalDialog>
  );
});
