import { Sales } from "records/Sales";
import { MonthlySales } from "records/MonthlySales";

export enum ActionTypes {
  U_ACCESSED_TO_PAGE_THAT_NEEDS_SALES = "sales/U_ACCESSED_TO_PAGE_THAT_NEEDS_SALES",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_MONTHLY_SALES = "sales/U_ACCESSED_TO_PAGE_THAT_NEEDS_MONTHLY_SALES",

  R_UPDATE_SALES = "sales/R_UPDATE_SALES",
  R_UPDATE_MONTHLY_SALES = "sales/R_UPDATE_MONTHLY_SALES",
}

export const userAccessedToPageThatNeedsSales = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SALES,
  } as const);
export type UserAccessedToPageThatNeedsSalesAction = ReturnType<
  typeof userAccessedToPageThatNeedsSales
>;

export const userAccessedToPageThatNeedsMonthlySales = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_MONTHLY_SALES,
  } as const);
export type UserAccessedToPageThatNeedsMonthlySalesAction = ReturnType<
  typeof userAccessedToPageThatNeedsMonthlySales
>;

export const updateSales = (value: Sales) =>
  ({
    type: ActionTypes.R_UPDATE_SALES,
    payload: {
      value,
    },
  } as const);
export type ReducerUpdateSalesAction = ReturnType<typeof updateSales>;

export const updateMonthlySales = (value: MonthlySales) =>
  ({
    type: ActionTypes.R_UPDATE_MONTHLY_SALES,
    payload: {
      value,
    },
  } as const);
export type ReducerUpdateMonthlySalesAction = ReturnType<typeof updateMonthlySales>;

export type SalesActions =
  | UserAccessedToPageThatNeedsSalesAction
  | ReducerUpdateSalesAction
  | ReducerUpdateMonthlySalesAction;
