import React, { useContext } from "react";
import { Button, ButtonInner, ButtonMainText, ButtonSubText } from "components/atoms/Button";
import { OrderDetailContext } from "../OrderDetailContext";

type Props = {
  sub?: boolean;
  submitting?: boolean;
  handleClick?: () => void;
};

export const HoldOrderButton: React.FC<Props> = React.memo(
  ({ sub = false, submitting, handleClick }) =>
    sub ? (
      <Button
        invert
        appearance="primary"
        handleClick={handleClick}
        loading={submitting}
        disabled={false}
        style={{ flexGrow: 1, flexShrink: 1, padding: "0 1em" }}
      >
        <ButtonMainText>保留</ButtonMainText>
        <ButtonSubText>on hold</ButtonSubText>
      </Button>
    ) : (
      <Button appearance="primary" handleClick={handleClick} loading={submitting} disabled={false}>
        <ButtonInner>
          <ButtonMainText>注文を保留にする</ButtonMainText>
          <ButtonSubText>put order on hold</ButtonSubText>
        </ButtonInner>
      </Button>
    ),
);
