import React, { useRef, useMemo, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { GroupedVirtuoso, ListRange, VirtuosoHandle } from "react-virtuoso";
import { colorsConst } from "styles/const";
import { ShopItem } from "records/ShopItem";
import { ShopItemSubmitType } from "modules/shop/model";
import { ShopItemListItem } from "components/organisms/ShopItemListItem";

const listStyle: React.CSSProperties = {
  flexGrow: 1,
} as const;

const BlankContainer = styled.div`
  text-align: center;
  padding: 1em;
`;

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  padding: 1em;
`;

const CategoryName = styled.div`
  padding-top: 0.5em;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
  [data-test-id="virtuoso-top-item-list"] & {
    padding-top: 0;
  }
`;

type Props = {
  shopItems: ShopItem[];
  shopItemsCategories: string[];
  groupCounts: number[];
  selectedParentCategoryId: number;
  submitting: boolean;
  setVisibleRange: React.Dispatch<React.SetStateAction<ListRange>>;
  handleStatus: (itemId: number, submitType: ShopItemSubmitType) => void;
};

export const ShopItemList: React.FC<Props> = React.memo(
  ({
    shopItems,
    shopItemsCategories,
    groupCounts,
    selectedParentCategoryId,
    submitting,
    setVisibleRange,
    handleStatus,
  }) => {
    const ref = useRef<VirtuosoHandle>(null);

    const [isScrolling, setIsScrolling] = useState(false);

    const groupContent = useCallback(
      (index: number) => <CategoryName>カテゴリ：{shopItemsCategories[index]}</CategoryName>,
      [shopItemsCategories],
    );

    const itemContent = useCallback(
      (index: number) => (
        <ShopItemListItem
          item={shopItems[index]}
          submitting={submitting}
          handleStatus={handleStatus}
        />
      ),
      [shopItems, submitting, handleStatus],
    );

    useEffect(() => {
      ref.current?.scrollToIndex(0);
    }, [selectedParentCategoryId]);

    return (
      <Container>
        {shopItems.length > 0 ? (
          <GroupedVirtuoso
            ref={ref}
            style={listStyle}
            isScrolling={setIsScrolling}
            rangeChanged={setVisibleRange}
            groupCounts={groupCounts}
            groupContent={groupContent}
            itemContent={itemContent}
          />
        ) : (
          <BlankContainer>商品がありません</BlankContainer>
        )}
      </Container>
    );
  },
);
