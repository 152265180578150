import React, { useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { DateUtils } from "utils/DateUtils";
import { colorsConst } from "styles/const";
import { Button } from "components/atoms/Button";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
} from "components/molecules/ModalDialog";

const innerStyle: React.CSSProperties = {
  padding: 0,
  backgroundColor: colorsConst.BACKGROUND.SECONDARY,
} as const;

const BodyInner = styled.div`
  padding: 1em;
`;

const DateContainer = styled.div`
  margin-top: 1em;
  padding: 1em;
  background-color: ${colorsConst.BACKGROUND.SECONDARY};
`;

const MODAL_KEY = "CONFIRM_DELETE_HOLIDAY";
const useModal = modalHooksFactory(MODAL_KEY);

type Props = {
  children?: never;
  submitting: boolean;
  handleSubmit: (date: string) => void;
};

export const ConfirmDeleteHolidayDialog: React.FC<Props> = React.memo(
  ({ submitting, handleSubmit }) => {
    const { data, close } = useModal();

    const displayableDateString = useMemo(
      () => ("payload" in data && "date" in data.payload ? data.payload.date : ""),
      [data],
    );

    const handleSubmitDeleteHoliday = useCallback(() => {
      handleSubmit(displayableDateString);
    }, [displayableDateString, handleSubmit]);

    return (
      <ModalDialog
        isOpen={data.isOpen}
        close={close}
        innerStyle={innerStyle}
        handleClickBackdrop={close}
      >
        <ModalDialogHeader handleClose={close}>休業日の削除</ModalDialogHeader>
        <ModalBody>
          <BodyInner>
            以下の休業日を削除してもよろしいですか？
            <DateContainer>{`${DateUtils.dateToString(
              displayableDateString,
              "YYYY年MM月DD日",
            )}（${DateUtils.getWeekdayString(displayableDateString)}）`}</DateContainer>
          </BodyInner>
        </ModalBody>
        <ModalFooter>
          <Button
            appearance="secondary"
            handleClick={handleSubmitDeleteHoliday}
            loading={submitting}
          >
            削除する
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  },
);
