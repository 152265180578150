import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { systemOpenedModal } from "modules/app/actions";

const Container = styled.div`
  text-decoration: underline;
`;

type Props = {};

export const CancelButton: React.FC<Props> = React.memo(() => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(systemOpenedModal("CANCEL_ORDER", {}));
  }, [dispatch]);

  return (
    <Container onClick={handleClick}>店舗都合によりキャンセル（cancel these orders）</Container>
  );
});
