import React from "react";

type Props = React.SVGProps<SVGSVGElement> & {};

export const IconCircle: React.FC<Props> = React.memo(({ ...props }) => (
  <svg viewBox="0 0 13 12" width="1em" height="1em" {...props}>
    <circle
      cx={47.455}
      cy={6}
      r={4.75}
      transform="translate(-41)"
      strokeWidth={2.5}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
));
