import { NewsItem } from "records/NewsItem";

export enum ActionTypes {
  U_ACCESSED_TO_PAGE_THAT_NEEDS_NEWS_LIST = "information/U_ACCESSED_TO_PAGE_THAT_NEEDS_NEWS_LIST",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_NEWS_READ_FLAG = "information/U_ACCESSED_TO_PAGE_THAT_NEEDS_NEWS_READ_FLAG",
  U_SUBMITTED_READ_NEWS = "information/U_SUBMITTED_READ_NEWS",
  R_UPDATE_NEWS_LIST = "information/R_UPDATE_NEWS_LIST",
  R_UPDATE_UNREAD_NEWS_FLAG = "information/R_UPDATE_UNREAD_NEWS_FLAG",
}

export const userAccessedToPageThatNeedsNewsList = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_NEWS_LIST,
  } as const);
export type UserAccessedToPageThatNeedsNewsListAction = ReturnType<
  typeof userAccessedToPageThatNeedsNewsList
>;

export const userAccessedToPageThatNeedsNewsReadFlag = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_NEWS_READ_FLAG,
  } as const);
export type UserAccessedToPageThatNeedsNewsReadFlagAction = ReturnType<
  typeof userAccessedToPageThatNeedsNewsReadFlag
>;

export const userSubmittedReadNews = (id: number) =>
  ({
    type: ActionTypes.U_SUBMITTED_READ_NEWS,
    payload: {
      id,
    },
  } as const);
export type UserSubmittedReadNewsAction = ReturnType<typeof userSubmittedReadNews>;

export const updateNewsList = (list: NewsItem[]) =>
  ({
    type: ActionTypes.R_UPDATE_NEWS_LIST,
    payload: {
      list,
    },
  } as const);
export type ReducerUpdateNewsListAction = ReturnType<typeof updateNewsList>;

export const updateUnreadNewsFlag = (bool: boolean) =>
  ({
    type: ActionTypes.R_UPDATE_UNREAD_NEWS_FLAG,
    payload: {
      bool,
    },
  } as const);
export type ReducerUpdateUnreadNewsFlagAction = ReturnType<typeof updateUnreadNewsFlag>;

export type InformationActions =
  | UserAccessedToPageThatNeedsNewsListAction
  | UserSubmittedReadNewsAction
  | ReducerUpdateNewsListAction
  | ReducerUpdateUnreadNewsFlagAction;
