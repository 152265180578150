import React, { useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { OrderItemData, DraftItem } from "records/OrderItemData";
import { StaffRoleType } from "records/ShopStaff";
import { systemOpenedModal } from "modules/app/actions";
import { shopDataSelector } from "modules/shop/selectors";
import { ItemName } from "components/molecules/ItemName";
import { PermissionContainer } from "components/organisms/PermissionContext";
import { PermissionData } from "utils/RbacUtils";
import { Utility } from "utils/Utility";
import { Thumbnail } from "components/atoms/Thumbnail";
import { ShopData } from "records/ShopData";
import { OrderDetailItemPrice } from "./OrderDetailItemPrice";
import { OrderDetailItemOptionSet } from "./OrderDetailItemOptionSet";
import { OrderDetailItemWeightRangeWarning } from "./OrderDetailItemWeightRangeWarning";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CheckboxContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 0.5em;
`;

const CheckBox = styled.input`
  width: 3em;
  height: 3em;
  margin-top: 0.5em;
`;

const ThumbnailContainer = styled.div`
  margin-right: 0.5em;
`;

const ItemNameContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const ItemPriceContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 0.25em;
  margin-left: 1em;
`;

const InfoContainer = styled.div`
  margin-top: 0.5em;
`;

const InfoLabel = styled.div`
  ${fontSize.SMALL};
  color: ${colorsConst.TEXT.SECONDARY};
`;

const InstructionTextContainer = styled.div`
  padding: 0.5em;
  background-color: ${colorsConst.BACKGROUND.INFO};
`;

type Props = {
  draftItem: DraftItem;
  targetItem: OrderItemData;
  draftItems: DraftItem[];
  isRequired: boolean;
  isContainRequiredItemOrder: boolean;
  isAllRequiredItemsChecked: boolean;
  isSubstitute: boolean;
  isSubsutituteItemActive: boolean;
  isDirty: boolean;
  isValidItemCount: boolean;
  canDisplayCheckBox: boolean;
  submitting: boolean;
  permissionData: PermissionData;
  role: StaffRoleType;
  handleChangePickStatus?: (draftItem: DraftItem, checked: boolean) => void;
};

export const OrderDetailItemInfo: React.FC<Props> = React.memo(
  ({
    draftItem,
    targetItem,
    isRequired,
    isContainRequiredItemOrder,
    isAllRequiredItemsChecked,
    isSubstitute,
    isSubsutituteItemActive,
    isDirty,
    isValidItemCount,
    canDisplayCheckBox,
    submitting,
    permissionData,
    role,
    handleChangePickStatus,
  }) => {
    const dispatch = useDispatch();
    const ThumbnailSize = 56;
    const shopData = useSelector(shopDataSelector);

    const existsOptionSets = useMemo(() => draftItem.option_set_list.length > 0, [draftItem]);

    const existsInstructionText = useMemo(() => draftItem.instruction_text !== "", [draftItem]);

    const canChangeCheckBox = useMemo(
      () =>
        isValidItemCount &&
        !submitting &&
        (!isContainRequiredItemOrder || isRequired || isAllRequiredItemsChecked) &&
        (!isSubstitute || isSubsutituteItemActive),
      [
        submitting,
        isValidItemCount,
        isRequired,
        isContainRequiredItemOrder,
        isAllRequiredItemsChecked,
        isSubstitute,
        isSubsutituteItemActive,
      ],
    );

    const handleCheck = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof handleChangePickStatus === "undefined" || event.target.readOnly) {
          return;
        }
        handleChangePickStatus(draftItem, event.target.checked);
      },
      [handleChangePickStatus, draftItem],
    );

    const handleClick = useCallback(() => {
      dispatch(systemOpenedModal("ORDER_ITEM_IMAGE", { imagePath: targetItem.image_path }));
    }, [dispatch, targetItem]);

    return (
      <>
        <Header>
          {canDisplayCheckBox ? (
            <PermissionContainer role={role} performance="own" data={permissionData}>
              <CheckboxContainer>
                <CheckBox
                  type="checkbox"
                  onChange={handleCheck}
                  checked={draftItem.is_picked}
                  disabled={!canChangeCheckBox}
                />
              </CheckboxContainer>
            </PermissionContainer>
          ) : undefined}
          {ShopData.isShowDisplayImages(shopData) && (
            <ThumbnailContainer onClick={handleClick}>
              <Thumbnail
                imagePath={Utility.getCdnImagePath(targetItem.image_path, ThumbnailSize)}
                width={ThumbnailSize}
                height={ThumbnailSize}
              />
            </ThumbnailContainer>
          )}
          <ItemNameContainer>
            <ItemName isDetail item={targetItem} />
          </ItemNameContainer>
          <ItemPriceContainer>
            <OrderDetailItemPrice item={draftItem} />
          </ItemPriceContainer>
        </Header>
        {existsOptionSets ? (
          <InfoContainer>
            {draftItem.option_set_list.map((optionSet, index) => (
              <OrderDetailItemOptionSet optionSet={optionSet} key={index} />
            ))}
          </InfoContainer>
        ) : undefined}
        {isDirty && !isValidItemCount ? (
          <InfoContainer>
            <OrderDetailItemWeightRangeWarning item={draftItem} />
          </InfoContainer>
        ) : undefined}
        {OrderItemData.existsSupplementText(draftItem) ? (
          <InfoContainer>
            <InfoLabel>補足情報</InfoLabel>
            <div>{draftItem.supplement_text}</div>
          </InfoContainer>
        ) : undefined}
        {existsInstructionText ? (
          <InfoContainer>
            <InfoLabel>備考</InfoLabel>
            <InstructionTextContainer>{draftItem.instruction_text}</InstructionTextContainer>
          </InfoContainer>
        ) : undefined}
      </>
    );
  },
);
