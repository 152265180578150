import { Task } from "redux-saga";
import { fork, takeLatest } from "redux-saga/effects";
import { SystemWatchNotificationAction, ActionTypes } from "../actions";
import { watchFirestoreSaga } from "./watchFirestoreSaga";

export function* systemWatchNotificationSaga(action: SystemWatchNotificationAction) {
  console.log("start");
  const { pollingInterval, shopId } = action.payload;
  const watchTask: Task = yield fork(watchFirestoreSaga, shopId, pollingInterval);
  yield takeLatest(ActionTypes.S_UNWATCH_NOTIFICATION, stopWatchNotificationSaga);
  function* stopWatchNotificationSaga() {
    if (!watchTask.isCancelled()) {
      console.log("stop");
      yield watchTask.cancel();
    }
  }
}
