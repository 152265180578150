import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconBag: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 28 24" width="1em" height="1em" {...props}>
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="2.4"
        strokeLinejoin="round"
        d="M6.2,6.1h15.6l0.7,15.8h-17L6.2,6.1z"
      />
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="2.4"
        strokeLinejoin="round"
        d="M17.6,6.3V4.5c0-1.1-0.9-2-2-2h-3.3c-1.1,0-2,0.9-2,2v1.8"
      />
    </svg>
  );
});
