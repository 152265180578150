import { ShopData } from "records/ShopData";
import { OrderData, OrderReceiveType, OrderTimeType } from "records/OrderData";
import { DraftItem } from "records/OrderItemData";
import { OrderListTabType } from "modules/order/model";

export enum ActionTypes {
  U_TOUCHED_GOTO_SIMULATOR_PAGE_BUTTON = "simulator/U_TOUCHED_GOTO_SIMULATOR_PAGE_BUTTON",
  U_TOUCHED_CHANGE_PAGE_BUTTON = "simulator/U_TOUCHED_CHANGE_PAGE_BUTTON",
  U_TOUCHED_SIMULATOR_HOLD_ORDER_BUTTON = "simulator/U_TOUCHED_SIMULATOR_HOLD_ORDER_BUTTON",
  U_TOUCHED_SIMULATOR_APPROVE_ORDER_BUTTON = "simulator/U_TOUCHED_SIMULATOR_APPROVE_ORDER_BUTTON",
  U_TOUCHED_SIMULATOR_APPROVE_ORDER_WITH_UPDATE_COOKING_TIME_BUTTON = "simulator/U_TOUCHED_SIMULATOR_APPROVE_ORDER_WITH_UPDATE_COOKING_TIME_BUTTON",
  U_TOUCHED_SIMULATOR_FIND_CREW_FOR_LOCAL_AREA_BUTTON = "simulator/U_TOUCHED_SIMULATOR_FIND_CREW_FOR_LOCAL_AREA_BUTTON",
  U_TOUCHED_SIMULATOR_FINISH_ORDER_BUTTON = "simulator/U_TOUCHED_SIMULATOR_FINISH_ORDER_BUTTON",
  U_TOUCHED_SIMULATOR_CANCEL_ORDER_BUTTON = "simulator/U_TOUCHED_SIMULATOR_CANCEL_ORDER_BUTTON",
  U_TOUCHED_SIMULATOR_CLOSE_FIND_CREW_DIALOG_BUTTON = "simulator/U_TOUCHED_SIMULATOR_CLOSE_FIND_CREW_DIALOG_BUTTON",
  U_TOUCHED_SIMULATOR_HOLD_ORDER_FOR_GROCERY_BUTTON = "simulator/U_TOUCHED_SIMULATOR_HOLD_ORDER_FOR_GROCERY_BUTTON",
  U_TOUCHED_SIMULATOR_APPROVE_ORDER_FOR_GROCERY_BUTTON = "simulator/U_TOUCHED_SIMULATOR_APPROVE_ORDER_FOR_GROCERY_BUTTON",
  S_MOUNT_SIMULATOR = "simulator/S_MOUNT_SIMULATOR",
  S_UNMOUNT_SIMULATOR = "simulator/S_UNMOUNT_SIMULATOR",
  S_GENERATE_SIMULATOR_ORDER_DATA = "simulator/S_GENERATE_SIMULATOR_ORDER_DATA",
  R_UPDATE_MOUNT_SIMULATOR = "simulator/R_UPDATE_MOUNT_SIMULATOR",
  R_UPDATE_SIMULATOR_ORDER_DATA = "simulator/R_UPDATE_SIMULATOR_ORDER_DATA",
  R_UPDATE_SIMULATOR_ORDER_LIST = "simulator/R_UPDATE_SIMULATOR_ORDER_LIST",
  R_UPDATE_SIMULATOR_FINISHED_ORDER_LIST = "simulator/R_UPDATE_SIMULATOR_FINISHED_ORDER_LIST",
  R_UPDATE_SIMULATOR_PAGE_TRANSITION = "simulator/R_UPDATE_SIMULATOR_PAGE_TRANSITION",
  R_UPDATE_COOKING_START_DATE_TIME = "simulator/R_UPDATE_COOKING_START_DATE_TIME",
}

export const userTouchedGotoSimulatorPageButton = (isReservedOrder: boolean) =>
  ({
    type: ActionTypes.U_TOUCHED_GOTO_SIMULATOR_PAGE_BUTTON,
    payload: {
      isReservedOrder,
    },
  } as const);
export type UserTouchedGotoSimulatorPageButtonAction = ReturnType<
  typeof userTouchedGotoSimulatorPageButton
>;

export const userTouchedChangeSimulatorPage = (pageTransition: boolean = false) =>
  ({
    type: ActionTypes.U_TOUCHED_CHANGE_PAGE_BUTTON,
    payload: {
      pageTransition,
    },
  } as const);
export type UserTouchedChangeSimulatorPageAction = ReturnType<
  typeof userTouchedChangeSimulatorPage
>;

export const userTouchedSimulatorApproveOrderButton = (
  orderData: OrderData,
  draftItems: DraftItem[],
) =>
  ({
    type: ActionTypes.U_TOUCHED_SIMULATOR_APPROVE_ORDER_BUTTON,
    payload: {
      orderData,
      draftItems,
    },
  } as const);
export type UserTouchedSimulatorApproveOrderButtonAction = ReturnType<
  typeof userTouchedSimulatorApproveOrderButton
>;

export const userTouchedSimulatorHoldOrderButton = (orderData: OrderData, isRetail: boolean) =>
  ({
    type: ActionTypes.U_TOUCHED_SIMULATOR_HOLD_ORDER_BUTTON,
    payload: {
      orderData,
      isRetail,
    },
  } as const);
export type UserTouchedSimulatorHoldOrderButtonAction = ReturnType<
  typeof userTouchedSimulatorHoldOrderButton
>;

export const userTouchedSimulatorApproveOrderWithUpdateCookingTimeButton = (
  orderData: OrderData,
  addMinutes: number,
  draftItems: DraftItem[],
  setTabType: (value: React.SetStateAction<OrderListTabType>) => void,
) =>
  ({
    type: ActionTypes.U_TOUCHED_SIMULATOR_APPROVE_ORDER_WITH_UPDATE_COOKING_TIME_BUTTON,
    payload: {
      orderData,
      addMinutes,
      draftItems,
      setTabType,
    },
  } as const);
export type UserTouchedSimulatorApproveOrderWithUpdateCookingTimeButtonAction = ReturnType<
  typeof userTouchedSimulatorApproveOrderWithUpdateCookingTimeButton
>;

export const userTouchedSimulatorFindCrewForLocalAreaButton = (
  orderData: OrderData,
  draftItems: DraftItem[],
) =>
  ({
    type: ActionTypes.U_TOUCHED_SIMULATOR_FIND_CREW_FOR_LOCAL_AREA_BUTTON,
    payload: {
      orderData,
      draftItems,
    },
  } as const);
export type UserTouchedSimulatorFindCrewForLocalAreaButtonAction = ReturnType<
  typeof userTouchedSimulatorFindCrewForLocalAreaButton
>;

export const userTouchedSimulatorFinishOrderButton = (orderData: OrderData) =>
  ({
    type: ActionTypes.U_TOUCHED_SIMULATOR_FINISH_ORDER_BUTTON,
    payload: {
      orderData,
    },
  } as const);
export type UserTouchedSimulatorFinishOrderButtonAction = ReturnType<
  typeof userTouchedSimulatorFinishOrderButton
>;

export const userTouchedSimulatorCloseFindingCrewDialogButton = (orderData: OrderData) =>
  ({
    type: ActionTypes.U_TOUCHED_SIMULATOR_CLOSE_FIND_CREW_DIALOG_BUTTON,
    payload: {
      orderData,
    },
  } as const);
export type UserTouchedSimulatorCloseFindingCrewDialogButtonAction = ReturnType<
  typeof userTouchedSimulatorCloseFindingCrewDialogButton
>;

export const userTouchedSimulatorCancelOrderButton = (orderData: OrderData) =>
  ({
    type: ActionTypes.U_TOUCHED_SIMULATOR_CANCEL_ORDER_BUTTON,
    payload: {
      orderData,
    },
  } as const);
export type UserTouchedSimulatorCancelOrderButtonAction = ReturnType<
  typeof userTouchedSimulatorCancelOrderButton
>;

export const userTouchedSimulatorHoldOrderForGroceryButton = (orderData: OrderData) =>
  ({
    type: ActionTypes.U_TOUCHED_SIMULATOR_HOLD_ORDER_FOR_GROCERY_BUTTON,
    payload: {
      orderData,
    },
  } as const);
export type UserTouchedSimulatorHoldOrderForGroceryButtonAction = ReturnType<
  typeof userTouchedSimulatorHoldOrderForGroceryButton
>;

export const userTouchedSimulatorApproveOrderForGroceryButton = (
  orderData: OrderData,
  draftItems: DraftItem[],
) =>
  ({
    type: ActionTypes.U_TOUCHED_SIMULATOR_APPROVE_ORDER_FOR_GROCERY_BUTTON,
    payload: {
      orderData,
      draftItems,
    },
  } as const);
export type UserTouchedSimulatorApproveOrderForGroceryButtonAction = ReturnType<
  typeof userTouchedSimulatorApproveOrderForGroceryButton
>;

export const systemMountSimulator = () =>
  ({
    type: ActionTypes.S_MOUNT_SIMULATOR,
  } as const);
export type SystemMountSimulatorAction = ReturnType<typeof systemMountSimulator>;

export const systemUnmountSimulator = () =>
  ({
    type: ActionTypes.S_UNMOUNT_SIMULATOR,
  } as const);
export type SystemUnmountSimulatorAction = ReturnType<typeof systemUnmountSimulator>;

export const systemGenerateSimulatorOrderData = (
  shopData: ShopData,
  orderReceiveType: OrderReceiveType,
  orderTimeType: OrderTimeType,
) =>
  ({
    type: ActionTypes.S_GENERATE_SIMULATOR_ORDER_DATA,
    payload: {
      shopData,
      orderReceiveType,
      orderTimeType,
    },
  } as const);
export type SystemGenerateSimulatorOrderDataAction = ReturnType<
  typeof systemGenerateSimulatorOrderData
>;

export const updateMountSimulator = (value: boolean) =>
  ({
    type: ActionTypes.R_UPDATE_MOUNT_SIMULATOR,
    payload: {
      value,
    },
  } as const);
type UpdateMountSimulatorAction = ReturnType<typeof updateMountSimulator>;

export const updateSimulatorOrderData = (value: OrderData) =>
  ({
    type: ActionTypes.R_UPDATE_SIMULATOR_ORDER_DATA,
    payload: {
      value,
    },
  } as const);
type UpdateSimulatorOrderDataAction = ReturnType<typeof updateSimulatorOrderData>;

export const updateSimulatorOrderList = (value: OrderData[]) =>
  ({
    type: ActionTypes.R_UPDATE_SIMULATOR_ORDER_LIST,
    payload: {
      value,
    },
  } as const);
type UpdateSimulatorOrderListAction = ReturnType<typeof updateSimulatorOrderList>;

export const updateSimulatorFinishedOrderList = (value: OrderData[]) =>
  ({
    type: ActionTypes.R_UPDATE_SIMULATOR_FINISHED_ORDER_LIST,
    payload: {
      value,
    },
  } as const);
type UpdateSimulatorFinishedOrderListAction = ReturnType<typeof updateSimulatorFinishedOrderList>;

export const updateSimulatorPageTransition = (value: boolean) =>
  ({
    type: ActionTypes.R_UPDATE_SIMULATOR_PAGE_TRANSITION,
    payload: {
      value,
    },
  } as const);
type UpdateSimulatorPageTransitionAction = ReturnType<typeof updateSimulatorPageTransition>;

export const updateCookingStartDateTime = (value: string) =>
  ({
    type: ActionTypes.R_UPDATE_COOKING_START_DATE_TIME,
    payload: {
      value,
    },
  } as const);
type UpdateCookingStartDateTimeAction = ReturnType<typeof updateCookingStartDateTime>;

export type SimulatorActions =
  | UserTouchedGotoSimulatorPageButtonAction
  | UserTouchedChangeSimulatorPageAction
  | UserTouchedSimulatorHoldOrderButtonAction
  | UserTouchedSimulatorApproveOrderButtonAction
  | UserTouchedSimulatorApproveOrderWithUpdateCookingTimeButtonAction
  | UserTouchedSimulatorFindCrewForLocalAreaButtonAction
  | UserTouchedSimulatorCloseFindingCrewDialogButtonAction
  | UserTouchedSimulatorCancelOrderButtonAction
  | SystemMountSimulatorAction
  | SystemUnmountSimulatorAction
  | SystemGenerateSimulatorOrderDataAction
  | UpdateMountSimulatorAction
  | UpdateSimulatorOrderDataAction
  | UpdateSimulatorOrderListAction
  | UpdateSimulatorFinishedOrderListAction
  | UpdateSimulatorPageTransitionAction
  | UpdateCookingStartDateTimeAction;
