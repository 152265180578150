import React from "react";
import styled from "styled-components";
import { NewsItem } from "records/NewsItem";
import { NewsListItem } from "./NewsListItem";

const Container = styled.div``;

type Props = {
  list: NewsItem[];
};

export const NewsList: React.FC<Props> = React.memo(({ list }) => (
  <Container>
    {list.map(item => (
      <NewsListItem key={item.id} item={item} />
    ))}
  </Container>
));
