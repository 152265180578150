import React from "react";
import styled from "styled-components";
import { OrderOptionSet } from "records/OrderOptionSet";
import { PriceUtils } from "utils/PriceUtils";
import { Text } from "components/atoms/Text";

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
const OptionName = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;
const Price = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

type Props = {
  optionSet: OrderOptionSet;
};

export const OrderDetailItemOptionSet: React.FC<Props> = React.memo(({ optionSet }) => (
  <Container>
    <div>
      <Text size="SMALL" color="SECONDARY">
        {optionSet.option_set_name}
      </Text>
    </div>
    {optionSet.option_list.map((option, index) => (
      <Row key={index}>
        <OptionName>
          <Text>・{option.option_name}</Text>
        </OptionName>
        <Price>
          <Text>{PriceUtils.getPresentationValue(option.price)}</Text>
        </Price>
      </Row>
    ))}
  </Container>
));
