import { put, call } from "redux-saga/effects";
import { CordovaUtil } from "utils/CordovaUtil";
import { DiagnosticUtil } from "utils/DiagnosticUtil";
import { setEnebleBluetooth } from "../actions";
import { AppModel } from "../model";
import { AuthorizationStatusType } from "../../../@types/Diagnostic";

export function* bluetoothAuthorizationSaga() {
  if (CordovaUtil.isCordovaEnabled()) {
    const permission: AuthorizationStatusType | undefined = yield call(
      DiagnosticUtil.getBluetoothAuthorizationStatus,
    );
    const isGranted = AppModel.isGrantedBluetooth(permission);
    yield put(setEnebleBluetooth(isGranted));
    if (!isGranted && !AppModel.isNeverAskBluetoothAuthorization(permission)) {
      yield call(DiagnosticUtil.requestBluetoothAuthorization);
    }
  }
}
