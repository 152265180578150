/* eslint-disable import/extensions */
import React, { useMemo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { ShopData } from "records/ShopData";
import { OrderNotification, NotificationType, NOTIFICATION_TYPE } from "records/OrderNotification";
import { userTouchedOrderNotificationDialog } from "modules/order/actions";
import { userTouchedChangeSimulatorPage } from "modules/simulator/actions";
import { Utility } from "utils/Utility";
import { colorsConst } from "styles/const";
import { Text } from "components/atoms/Text";
import { IconNewOrder } from "components/atoms/Icons/IconNewOrder";
import { IconCookingStart } from "components/atoms/Icons/IconCookingStart";
import { IconNotFinishedOrder } from "components/atoms/Icons/IconNotFinishedOrder";
import { IconCancelOrder } from "components/atoms/Icons/IconCancelOrder";
import { IconCancelOrderForLocalArea } from "components/atoms/Icons/IconCancelOrderForLocalArea";
import { IconDisableApproval } from "components/atoms/Icons/IconDisableApproval";
import { IconRestartOrderByShop } from "components/atoms/Icons/IconRestartOrderByShop";
import { IconRemindHoldOrder } from "components/atoms/Icons/IconRemindHoldOrder";
import newOrderSoundfile from "components/atoms/Sounds/newOrder.mp3";
import reservedOrderSoundfile from "components/atoms/Sounds/reservedOrder.mp3";
import remindOrderSoundfile from "components/atoms/Sounds/remindOrder.mp3";

const Container = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 0 1em;
  text-align: center;
`;

const RowContainer = styled.div`
  margin-bottom: 2em;
  @media (orientation: landscape) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const TextContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (orientation: portrait) {
    margin-bottom: 2em;
  }
  @media (orientation: landscape) {
    width: 50%;
  }
`;

const IconContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  @media (orientation: landscape) {
    width: 25%;
    margin-left: 2em;
  }
  @media (orientation: portrait) {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ReceiveTypeLabel = styled.div`
  align-self: stretch;
  margin-bottom: 1em;
  padding: 0.5em 0;
  border-radius: 2em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

const CaptionTextJpContainer = styled.div`
  margin-bottom: 1em;
`;

const NotificationIcon = styled.div`
  svg {
    width: 100%;
    height: auto;
  }
`;

type Props = {
  notification: OrderNotification;
  shopData: ShopData;
  handleClose: () => void;
  isDebug: boolean;
  isSimulator: boolean;
};

export const NotificationDialog: React.FC<Props> = React.memo(
  ({ notification, shopData, handleClose, isDebug, isSimulator }) => {
    const labelText = useMemo(() => {
      switch (notification.notification_type) {
        case NOTIFICATION_TYPE.FASTEST_ORDER:
        case NOTIFICATION_TYPE.RESERVED_ORDER:
          return typeof notification.receive_type !== "undefined"
            ? OrderData.getPresentationReceiveTypeText(
                notification.receive_type,
                notification.time_type,
              )
            : undefined;
        case NOTIFICATION_TYPE.CREW_MISMATCH:
          return `注文番号：${notification.display_order_no}`;
        default:
          return undefined;
      }
    }, [notification]);

    const containerStyle: React.CSSProperties = useMemo(
      () => ({
        backgroundColor: (
          [
            NOTIFICATION_TYPE.NOT_FOUND_CREW,
            NOTIFICATION_TYPE.CANCEL_COMBO_ORDER,
            NOTIFICATION_TYPE.CREW_MISMATCH,
          ] as NotificationType[]
        ).includes(notification.notification_type)
          ? colorsConst.BACKGROUND.CANCEL
          : colorsConst.BACKGROUND.DANGER,
      }),
      [notification],
    );

    const labelTextColor = useMemo(
      () =>
        notification.notification_type === NOTIFICATION_TYPE.CREW_MISMATCH ? "SECONDARY" : "DANGER",
      [notification],
    );

    const titleText = useMemo(
      () => OrderNotification.getNotificationDialogTitleText(notification, shopData, isSimulator),
      [notification, shopData, isSimulator],
    );

    const existsTitleTextEn = useMemo(
      () => typeof titleText.en !== "undefined" && titleText.en !== "",
      [titleText],
    );

    const captionText: Readonly<{ jp?: string; en?: string }> | undefined = useMemo(
      () => OrderNotification.getNotificationDialogCaptionText(notification, shopData, isSimulator),
      [notification, shopData, isSimulator],
    );

    const renderIcon = useMemo(() => {
      switch (notification.notification_type) {
        case NOTIFICATION_TYPE.FASTEST_ORDER:
        case NOTIFICATION_TYPE.RESERVED_ORDER:
        case NOTIFICATION_TYPE.COOKING_START:
          return <IconNewOrder />;
        case NOTIFICATION_TYPE.FOUND_CREW:
        case NOTIFICATION_TYPE.APPROVE_COMBO_ORDER:
          return <IconCookingStart />;
        case NOTIFICATION_TYPE.EXIST_NOT_FINISHED_ORDER:
        case NOTIFICATION_TYPE.BEFORE_AUTO_CANCEL:
          return <IconNotFinishedOrder />;
        case NOTIFICATION_TYPE.CANCEL_COMBO_ORDER:
          return <IconCancelOrder />;
        case NOTIFICATION_TYPE.CREW_MISMATCH:
        case NOTIFICATION_TYPE.NOT_FOUND_CREW:
          return <IconCancelOrderForLocalArea />;
        case NOTIFICATION_TYPE.BEFORE_DISABLE_APPROVAL:
          return <IconDisableApproval />;
        case NOTIFICATION_TYPE.REMIND_HOLD_ORDER:
        case NOTIFICATION_TYPE.REMIND_RESERVED_ORDER:
          return <IconRemindHoldOrder />;
        case NOTIFICATION_TYPE.ORDER_RESTART_BY_SHOP:
          return <IconRestartOrderByShop />;
        default:
          return undefined;
      }
    }, [notification]);

    const closeDialogText: Readonly<{ jp: string; en: string }> = useMemo(
      () => OrderNotification.getNotificationCloseDialogText(notification, shopData),
      [notification, shopData],
    );

    const audio: HTMLAudioElement = useMemo(() => {
      switch (notification.notification_type) {
        case NOTIFICATION_TYPE.RESERVED_ORDER:
          return new Audio(reservedOrderSoundfile);
        case NOTIFICATION_TYPE.EXIST_NOT_FINISHED_ORDER:
          return new Audio(remindOrderSoundfile);
        default:
          return new Audio(newOrderSoundfile);
      }
    }, [notification]);

    const dispatch = useDispatch();

    const handleClick = useCallback(() => {
      if (isSimulator) {
        dispatch(userTouchedChangeSimulatorPage(true));
        handleClose();
      } else if (isDebug || notification.order_no === "") {
        handleClose();
      } else {
        dispatch(userTouchedOrderNotificationDialog(notification, shopData));
      }
    }, [dispatch, notification, shopData, isDebug, isSimulator, handleClose]);

    useEffect(() => {
      const PLAY_LIMIT_COUNT = 299;
      let playCount = 0;
      let canceled = false;
      const sleep = (duration: number) =>
        new Promise(resolve => {
          setTimeout(resolve, duration);
        });
      const playSound = async () => {
        try {
          audio.currentTime = 0;
          await audio.play();
          await sleep(Math.floor(audio.duration * 1000));
          playCount += 1;
          if (PLAY_LIMIT_COUNT > playCount && !canceled) {
            playSound();
          }
        } catch (e) {
          console.log(e);
        }
      };
      playSound();
      return () => {
        canceled = true;
        audio.pause();
      };
    }, [audio]);

    return (
      <Container onClick={handleClick} style={containerStyle}>
        <RowContainer>
          <TextContainer>
            {typeof labelText !== "undefined" ? (
              <ReceiveTypeLabel>
                <Text size="XXLARGE" color={labelTextColor} weight="BOLD">
                  {labelText}
                </Text>
              </ReceiveTypeLabel>
            ) : undefined}
            {Utility.splitTextByLineFeed(titleText.jp).map((text, index) => (
              <div key={index}>
                <Text size="XXXLARGE" color="WHITE" weight="BOLD">
                  {text}
                </Text>
              </div>
            ))}
            {existsTitleTextEn ? (
              <div>
                <Text color="WHITE" weight="BOLD">
                  {titleText.en}
                </Text>
              </div>
            ) : undefined}
            {typeof captionText !== "undefined" ? (
              <>
                {typeof captionText.jp !== "undefined" ? (
                  <CaptionTextJpContainer>
                    <Text size="LARGE" color="WHITE">
                      {captionText.jp}
                    </Text>
                  </CaptionTextJpContainer>
                ) : undefined}
                {typeof captionText.en !== "undefined"
                  ? Utility.splitTextByLineFeed(captionText.en).map((text, index) => (
                      <div key={index}>
                        <Text size="SMALL" color="WHITE">
                          {text}
                        </Text>
                      </div>
                    ))
                  : undefined}
              </>
            ) : undefined}
          </TextContainer>
          <IconContainer>
            <NotificationIcon>{renderIcon}</NotificationIcon>
          </IconContainer>
        </RowContainer>
        <div>
          <Text size="LARGE" color="WHITE">
            {closeDialogText.jp}
          </Text>
        </div>
        <div>
          <Text size="SMALL" color="WHITE">
            {closeDialogText.en}
          </Text>
        </div>
      </Container>
    );
  },
);
