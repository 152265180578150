import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconCrewUnmatch: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4946 14.8848C27.885 14.8848 27.3908 15.3789 27.3908 15.9886C27.3908 16.5982 27.885 17.0923 28.4946 17.0923H29.806C30.2827 17.0923 30.6692 17.4788 30.6692 17.9556C30.6692 18.4323 30.2827 18.8188 29.806 18.8188H29.2106C29.1898 18.8176 29.1688 18.817 29.1477 18.817H19.9684C19.4748 18.817 19.0569 19.141 18.9157 19.5879L17.6659 22.8375C17.3499 22.7831 17.025 22.7547 16.6935 22.7547C13.5493 22.7547 11 25.3041 11 28.4482C11 31.5923 13.5493 34.1417 16.6935 34.1417C19.8376 34.1417 22.3869 31.5923 22.3869 28.4482C22.3869 26.419 21.325 24.6375 19.7266 23.6292L20.7283 21.0246H23.9049V21.0264H27.0822L28.0839 23.6307C26.4873 24.6393 25.4269 26.4198 25.4269 28.4476C25.4269 31.5918 27.9762 34.1411 31.1203 34.1411C34.2645 34.1411 36.8138 31.5918 36.8138 28.4476C36.8138 25.3035 34.2645 22.7542 31.1203 22.7542C30.7875 22.7542 30.4613 22.7827 30.1441 22.8376L29.4475 21.0264H29.806C31.5019 21.0264 32.8768 19.6515 32.8768 17.9556C32.8768 16.2596 31.5019 14.8848 29.806 14.8848H28.4946ZM16.6935 24.9623C16.745 24.9623 16.7963 24.9634 16.8473 24.9657L15.6616 28.0485C15.4428 28.6175 15.7266 29.2562 16.2956 29.475C16.8645 29.6938 17.5032 29.41 17.722 28.841L18.9084 25.7565C19.6845 26.3959 20.1793 27.3644 20.1793 28.4482C20.1793 30.3731 18.6184 31.9341 16.6935 31.9341C14.7686 31.9341 13.2076 30.3731 13.2076 28.4482C13.2076 26.5233 14.7686 24.9623 16.6935 24.9623ZM27.6345 28.4476C27.6345 27.3652 28.128 26.3979 28.9023 25.7585L30.0879 28.841C30.3067 29.41 30.9454 29.6938 31.5143 29.475C32.0833 29.2562 32.3671 28.6175 32.1483 28.0485L30.9624 24.9653C31.0148 24.9629 31.0674 24.9617 31.1203 24.9617C33.0453 24.9617 34.6062 26.5227 34.6062 28.4476C34.6062 30.3725 33.0453 31.9335 31.1203 31.9335C29.1954 31.9335 27.6345 30.3725 27.6345 28.4476Z"
        fill={fillColor}
      />
      <path
        d="M18.6561 14.8848C18.0465 14.8848 17.5523 15.3789 17.5523 15.9886C17.5523 16.5982 18.0465 17.0923 18.6561 17.0923H21.2788C21.8884 17.0923 22.3826 16.5982 22.3826 15.9886C22.3826 15.3789 21.8884 14.8848 21.2788 14.8848H18.6561Z"
        fill={fillColor}
      />
      <path
        d="M24 41.5C20.1844 41.5 16.6713 40.2049 13.8764 38.0303L37.0302 14.8764C39.2049 17.6713 40.5 21.1844 40.5 25C40.5 34.1127 33.1127 41.5 24 41.5ZM33.3988 11.4368L10.4368 34.3988C8.58518 31.7318 7.5 28.4926 7.5 25C7.5 15.8873 14.8873 8.5 24 8.5C27.4926 8.5 30.7318 9.58518 33.3988 11.4368ZM24 46.5C35.8741 46.5 45.5 36.8741 45.5 25C45.5 13.1259 35.8741 3.5 24 3.5C12.1259 3.5 2.5 13.1259 2.5 25C2.5 36.8741 12.1259 46.5 24 46.5Z"
        fill={fillColor}
        stroke="white"
      />
    </svg>
  );
});
