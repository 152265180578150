import React, { useRef, useMemo, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { GroupedVirtuoso, ListRange, VirtuosoHandle } from "react-virtuoso";
import { ShopItemOptionSet, SubmittableOptionSetType } from "records/ShopItemOptionSet";
import { ShopItemOption } from "records/ShopItemOption";
import { colorsConst } from "styles/const";
import { ShopModel, ShopItemSubmitType } from "modules/shop/model";
import { ShopOptionSetContainer } from "components/organisms/ShopOptionSetContainer";
import { ShopOptionListItem } from "components/molecules/ShopOptionListItem";

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  padding: 1em;
`;

const listStyle: React.CSSProperties = {
  flexGrow: 1,
  backgroundColor: colorsConst.BACKGROUND.WHITE,
} as const;

const BlankContainer = styled.div`
  text-align: center;
  padding: 1em;
`;

type Props = {
  options: ShopItemOption[];
  optionSets: ShopItemOptionSet[];
  groupCounts: number[];
  submitting: boolean;
  setVisibleRange: React.Dispatch<React.SetStateAction<ListRange>>;
  handleStatus: (optionSet: SubmittableOptionSetType, submitType: ShopItemSubmitType) => void;
};

export const ShopOptionSetList: React.FC<Props> = React.memo(
  ({ options, optionSets, groupCounts, submitting, setVisibleRange, handleStatus }) => {
    const ref = useRef<VirtuosoHandle>(null);

    const [isScrolling, setIsScrolling] = useState(false);

    const groupContent = useCallback(
      (index: number) => <ShopOptionSetContainer optionSet={optionSets[index]} />,
      [optionSets],
    );

    const itemContent = useCallback(
      (index: number, groupIndex: number) => (
        <ShopOptionListItem
          optionSetId={optionSets[groupIndex].option_set_id}
          option={options[index]}
          submitting={submitting}
          handleStatus={handleStatus}
        />
      ),
      [optionSets, options, submitting, handleStatus],
    );

    return (
      <Container>
        {options.length > 0 ? (
          <GroupedVirtuoso
            ref={ref}
            style={listStyle}
            isScrolling={setIsScrolling}
            rangeChanged={setVisibleRange}
            groupCounts={groupCounts}
            groupContent={groupContent}
            itemContent={itemContent}
          />
        ) : (
          <BlankContainer>オプションセットがありません</BlankContainer>
        )}
      </Container>
    );
  },
);
