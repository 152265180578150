import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { OrderData, ORDER_STATE_TYPE } from "records/OrderData";
import { OrderItemData, DraftItem } from "records/OrderItemData";
import { StaffRoleType } from "records/ShopStaff";
import { PermissionData } from "utils/RbacUtils";
import { Text } from "components/atoms/Text";
import { PermissionContainer } from "components/organisms/PermissionContext";
import { OrderDetailItemCountChange } from "./OrderDetailItemCountChange";
import { OutOfStockButton } from "./OutOfStockButton";
import { OrderDetailItemCountInput } from "./OrderDetailItemCountInput";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5em;
`;

const TotalOrderCount = styled.div<{ isModified: boolean }>`
  ${fontSize.XXLARGE};
  ${p => (p.isModified ? `color: ${colorsConst.TEXT.DANGER};` : "")}
`;

const ItemCountValueContainer = styled.div``;

const OrderCount = styled.div`
  ${fontSize.SMALL};
  color: ${colorsConst.TEXT.SECONDARY};
`;

type Props = {
  active?: boolean;
  isHistory: boolean;
  draftItem: DraftItem;
  targetItem: OrderItemData;
  orderData: OrderData;
  draftItems: DraftItem[];
  canChangeItemCount: boolean;
  maxCount: number;
  isGrocery: boolean;
  isContainRequiredItemOrder: boolean;
  isAllRequiredItemsChecked: boolean;
  permissionData: PermissionData;
  role: StaffRoleType;
  submitting: boolean;
  setDraftItems?: (value: React.SetStateAction<DraftItem[]>) => void;
  setIsValidItemCount: (value: React.SetStateAction<boolean>) => void;
  setIsDirty: (value: React.SetStateAction<boolean>) => void;
};

export const OrderDetailItemCount: React.FC<Props> = React.memo(
  ({
    active = true,
    isHistory,
    draftItem,
    targetItem,
    orderData,
    draftItems,
    canChangeItemCount,
    maxCount,
    isGrocery,
    isContainRequiredItemOrder,
    isAllRequiredItemsChecked,
    permissionData,
    role,
    submitting,
    setDraftItems,
    setIsValidItemCount,
    setIsDirty,
  }) => {
    const isBuyOne = useMemo(() => OrderItemData.isBuyOne(targetItem), [targetItem]);

    const canDisplayCountChange = useMemo(
      () => canChangeItemCount && !draftItem.is_campaign && !OrderItemData.isRequired(draftItem),
      [canChangeItemCount, draftItem],
    );

    const isModified = useMemo(
      () => !isHistory && draftItem.order_count !== draftItem.pick_count,
      [draftItem, isHistory],
    );

    const canChange = useMemo(
      () =>
        active &&
        !draftItem.is_picked &&
        !submitting &&
        (!isContainRequiredItemOrder || isAllRequiredItemsChecked),
      [active, draftItem, submitting, isContainRequiredItemOrder, isAllRequiredItemsChecked],
    );

    const canPlus = useMemo(() => {
      if (!canChange) {
        return false;
      }
      if (OrderItemData.existsSubstituteItem(draftItem)) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        return draftItem.pick_count + draftItem.substituteItem!.pick_count < maxCount;
      }
      return draftItem.pick_count < maxCount;
    }, [draftItem, maxCount, canChange]);

    const canMinus = useMemo(() => canChange && draftItem.pick_count > 0, [draftItem, canChange]);

    const displayableItemCount = useMemo(
      () => (isBuyOne ? draftItem.pick_count * 2 : draftItem.pick_count),
      [isBuyOne, draftItem],
    );

    const disiplayableMaxCount = useMemo(
      () => (isBuyOne ? maxCount * 2 : maxCount),
      [isBuyOne, maxCount],
    );

    const canDisplayItemCountInput = useMemo(
      () => !isHistory && isGrocery && draftItem.is_sell_by_weight,
      [isHistory, isGrocery, draftItem],
    );

    const handleChangeDraftItemCount = useCallback(
      (updatedCount: number) => {
        if (typeof setDraftItems === "undefined") {
          return;
        }
        setDraftItems(
          OrderData.getUpdatedDraftItems(draftItem, updatedCount, isBuyOne, draftItems),
        );
      },
      [draftItems, draftItem, isBuyOne, setDraftItems],
    );

    const handlePlusCount = useCallback(() => {
      if (typeof handleChangeDraftItemCount !== "undefined" && canPlus) {
        handleChangeDraftItemCount(draftItem.pick_count + 1);
      }
    }, [canPlus, draftItem, handleChangeDraftItemCount]);

    const handleMinusCount = useCallback(() => {
      if (typeof handleChangeDraftItemCount !== "undefined" && canMinus) {
        handleChangeDraftItemCount(draftItem.pick_count - 1);
      }
    }, [canMinus, draftItem, handleChangeDraftItemCount]);

    const handleOutOfStock = useCallback(() => {
      handleChangeDraftItemCount(0);
    }, [handleChangeDraftItemCount]);

    return (
      <Container>
        {canDisplayItemCountInput ? (
          <OrderDetailItemCountInput
            draftItem={draftItem}
            targetItem={targetItem}
            orderData={orderData}
            draftItems={draftItems}
            canChange={canChange}
            setIsDirty={setIsDirty}
            setDraftItems={setDraftItems}
            setIsValidItemCount={setIsValidItemCount}
          />
        ) : (
          <>
            <TotalOrderCount isModified={isModified}>
              <ItemCountValueContainer>
                <Text weight="BOLD">
                  <Text size="XXXLARGE">{displayableItemCount}</Text>
                  <Text size="SMALL">
                    {isGrocery && orderData.order_state === ORDER_STATE_TYPE.HOLD ? (
                      <PermissionContainer role={role} performance="own" data={permissionData}>
                        <Text color="SECONDARY">
                          {`/${disiplayableMaxCount}${OrderItemData.getPresentationUnitString(
                            draftItem.unit,
                          )}`}
                        </Text>
                      </PermissionContainer>
                    ) : (
                      OrderItemData.getPresentationUnitString(draftItem.unit)
                    )}
                  </Text>
                </Text>
              </ItemCountValueContainer>
            </TotalOrderCount>
            {isBuyOne ? (
              <>
                <OrderCount>{`注文：${
                  draftItem.pick_count
                }${OrderItemData.getPresentationUnitString(draftItem.unit)}`}</OrderCount>
                <OrderCount>{`無料：${
                  draftItem.pick_count
                }${OrderItemData.getPresentationUnitString(draftItem.unit)}`}</OrderCount>
              </>
            ) : undefined}
            {canDisplayCountChange ? (
              isGrocery ? (
                <PermissionContainer role={role} performance="own" data={permissionData}>
                  <OrderDetailItemCountChange
                    canPlus={canPlus}
                    canMinus={canMinus}
                    handlePlusCount={handlePlusCount}
                    handleMinusCount={handleMinusCount}
                  />
                  <OutOfStockButton
                    handleClick={handleOutOfStock}
                    disabled={draftItem.is_picked || submitting}
                  >
                    在庫なし
                  </OutOfStockButton>
                </PermissionContainer>
              ) : (
                <OrderDetailItemCountChange
                  canPlus={canPlus}
                  canMinus={canMinus}
                  handlePlusCount={handlePlusCount}
                  handleMinusCount={handleMinusCount}
                />
              )
            ) : undefined}
          </>
        )}
      </Container>
    );
  },
);
