import React, { useMemo } from "react";
import styled from "styled-components";
import { BusinessTime as TBusinessTime } from "records/BusinessTime";
import { BusinessWeekTime, BusinessType } from "records/BusinessWeekTime";
import { BusinessBusyTime } from "records/BusinessBusyTime";
import { DateUtils } from "utils/DateUtils";
import { fontSize } from "styles/mixins";
import { TextLink } from "components/atoms/TextLink";

const Container = styled.div`
  padding: 0.5em 1em;
  background-color: #fff;
  & + & {
    margin-top: 1em;
  }
`;

const WeekdayContainer = styled.div`
  ${fontSize.LARGE};
`;

const BusinessTimeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
`;

const BusinessTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 9em;
  justify-content: flex-end;
`;

const Label = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  color: #666;
  white-space: nowrap;
`;

const Value = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

type Props = {
  weekNumber: number;
  businessType: BusinessType;
  businessTimes: TBusinessTime[];
  busyTime?: BusinessBusyTime;
};

export const BusinessTime: React.FC<Props> = React.memo(
  ({ weekNumber, businessType, businessTimes, busyTime }) => {
    const existsBusyTime = useMemo(() => typeof busyTime !== "undefined", [busyTime]);

    return (
      <Container>
        <WeekdayContainer>{DateUtils.getWeekdayStringByWeekNumber(weekNumber)}</WeekdayContainer>
        <BusinessTimeRow>
          <Label>営業タイプ</Label>
          <Value>{BusinessWeekTime.getPresentationBusinessTypeString(businessType)}</Value>
        </BusinessTimeRow>
        {!BusinessWeekTime.isHoliday(businessType) ? (
          <>
            <BusinessTimeRow>
              <Label>営業時間</Label>
              <BusinessTimeContainer>
                {businessTimes.map((businessTime, index) => (
                  <TimeContainer key={`${weekNumber}_${index}`}>
                    <div>{String.fromCharCode(9312 + index)}</div>
                    <div>
                      {TBusinessTime.getPresentationBusinessTimeText(businessTime.start_time)}
                    </div>
                    <div>〜</div>
                    <div>
                      {TBusinessTime.getPresentationBusinessTimeText(businessTime.end_time)}
                    </div>
                  </TimeContainer>
                ))}
              </BusinessTimeContainer>
            </BusinessTimeRow>

            <BusinessTimeRow>
              <Label>忙しい時間帯</Label>
              {existsBusyTime ? (
                <BusinessTimeContainer>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    busyTime!.busy_times.map((busy_time, index) => (
                      <TimeContainer key={index}>
                        <div>
                          {TBusinessTime.getPresentationBusinessTimeText(busy_time.start_time)}
                        </div>
                        <div>〜</div>
                        <div>
                          {TBusinessTime.getPresentationBusinessTimeText(busy_time.end_time)}
                        </div>
                      </TimeContainer>
                    ))
                  }
                </BusinessTimeContainer>
              ) : (
                <TextLink to={`/shopBusinessBusyTime/${weekNumber}`}>登録する</TextLink>
              )}
            </BusinessTimeRow>
            {existsBusyTime ? (
              <BusinessTimeRow>
                <TextLink to={`/shopBusinessBusyTime/${weekNumber}`}>編集する</TextLink>
              </BusinessTimeRow>
            ) : undefined}
          </>
        ) : undefined}
      </Container>
    );
  },
);
