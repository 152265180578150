/* eslint-disable default-param-last */
import { ActionTypes, SalesActions } from "./actions";
import { SalesState, SalesModel } from "./model";

export default function reducer(state: SalesState = SalesModel.initialState, action: SalesActions) {
  switch (action.type) {
    case ActionTypes.R_UPDATE_SALES:
      return SalesModel.updateSales(state, action.payload.value);
    case ActionTypes.R_UPDATE_MONTHLY_SALES:
      return SalesModel.updateMonthlySales(state, action.payload.value);
    default:
      return state;
  }
}
