import React, { useMemo } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
} from "components/molecules/ModalDialog";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Text } from "components/atoms/Text";

const innerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND.PRIMARY,
} as const;

const ContentTitle = styled.div`
  margin-bottom: 0.5em;
`;

const ContentListContainer = styled.div`
  margin-bottom: 0.5em;
  padding: 0.5em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

const ContentListText = styled.div`
  margin-bottom: 0.5em;
`;

const ContentList = styled.div`
  margin-bottom: 0.5em;
  padding-left: 1em;
  text-indent: -1em;
`;

const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  color: ${colorsConst.BUTTON.TEXT.DEFAULT};
  background-color: ${colorsConst.BUTTON.BACKGROUND.PRIMARY};
  text-decoration: none;
`;

const IconContainer = styled.div`
  ${fontSize.LARGE};
`;

const TelContainer = styled.div`
  margin-left: 0.5em;
`;

const TelText = styled.div`
  ${fontSize.XSMALL};
  text-align: center;
`;

type Props = {
  children?: never;
};

const MODAL_KEY = "HOLD_ORDER";
const useModal = modalHooksFactory(MODAL_KEY);

export const HoldOrderDialog: React.FC<Props> = React.memo(() => {
  const { data, close } = useModal();

  const userInfoTel = useMemo(
    () => ("payload" in data && "userInfoTel" in data.payload ? data.payload.userInfoTel : ""),
    [data],
  );

  const isRetail = useMemo(
    () => ("payload" in data && "isRetail" in data.payload ? data.payload.isRetail : false),
    [data],
  );

  return (
    <ModalDialog
      isOpen={data.isOpen}
      close={close}
      handleClickBackdrop={close}
      innerStyle={innerStyle}
    >
      <ModalDialogHeader handleClose={close}>
        {isRetail ? "注文を保留する" : "注文を保留にしてお客様へ連絡する"}
      </ModalDialogHeader>
      <ModalBody>
        <ContentTitle>
          <Text weight="BOLD">
            {isRetail
              ? "注文を保留にしました。在庫を確認してください"
              : "注文を保留にしました。お客様へ連絡してください。"}
          </Text>
        </ContentTitle>
        {isRetail ? (
          <>
            <ContentListContainer>
              <ContentListText>
                <Text size="SMALL">
                  以下のような事象が発生して対応が遅れる場合は、お客様に連絡してください。
                </Text>
              </ContentListText>
              <ContentList>
                <Text size="SMALL" weight="BOLD">
                  ・注文された商品が品切れ
                </Text>
              </ContentList>
              <ContentList>
                <Text size="SMALL" weight="BOLD">
                  ・必要なカトラリーが不足している
                </Text>
              </ContentList>
              <ContentList>
                <Text size="SMALL" weight="BOLD">
                  ・準備が滞っていて対応が遅れる
                </Text>
              </ContentList>
            </ContentListContainer>

            <Text size="SMALL">
              通話ができない端末の場合は、通話可能な端末から連絡してください。
            </Text>
          </>
        ) : (
          <>
            <ContentList>
              <Text size="SMALL">
                1.通話ができない端末の場合は、通話可能な端末から連絡してください。
              </Text>
            </ContentList>
            <ContentList>
              <Text size="SMALL">
                2.お客様へ連絡後、注文内容に変更がなければ
                <Text weight="BOLD">「注文を受け付ける」</Text>
                ボタンを押してください。お客様に再度注文していただく場合は
                <Text weight="BOLD">「店舗都合でキャンセルする」</Text>
                を押してください。
              </Text>
            </ContentList>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button href={`tel:${userInfoTel}`}>
          <IconContainer>
            <FontAwesomeIcon icon={faPhoneAlt} />
          </IconContainer>
          <TelContainer>
            <Text size="LARGE" weight="BOLD">
              {userInfoTel}
            </Text>
          </TelContainer>
        </Button>
        <TelText>この電話番号は転送用です。注文が完了するまで有効です。</TelText>
      </ModalFooter>
    </ModalDialog>
  );
});
