import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { PriceUtils } from "utils/PriceUtils";
import { colorsConst, fontFamilyConst } from "styles/const";
import { OrderItemData } from "records/OrderItemData";
import { Text } from "components/atoms/Text";
import { PaymentCancelledItem } from "./PaymentCancelledItem";

const Container = styled.div`
  background-color: ${colorsConst.BACKGROUND.SECONDARY};
`;

const ToggleButton = styled.button.attrs({ type: "button" })`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5em 1em;
  appearance: none;
  border: none;
  background-color: inherit;
`;

const ButtonText = styled(Text)`
  font-family: ${fontFamilyConst};
`;

const IconContainer = styled.div<{ isOpen: boolean }>`
  transition: all 0.25s ease-out;
  ${p => (p.isOpen ? "transform: rotate(180deg);" : "")}
`;

const ToggleContainer = styled.div`
  overflow: hidden;
`;

const Inner = styled.div<{ isOpen: boolean }>`
  padding-bottom: 0.5em;
  transition: all 0.25s ease-out;
  ${p => (!p.isOpen ? "margin-top: -100%;" : "")}
`;

const TotalPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 1em;
`;

const TotalPriceLabel = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 1em;
`;

const TotalPriceValue = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

type Props = {
  items: OrderItemData[];
  paymentCancelPrice: number;
};

export const PaymentCancelItemList: React.FC<Props> = React.memo(
  ({ items, paymentCancelPrice }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
      setIsOpen(!isOpen);
    }, [isOpen]);

    return (
      <Container>
        <ToggleButton onClick={handleClick}>
          <ButtonText size="SMALL" color="PRIMARY">
            店舗負担の決済があります
          </ButtonText>
          <IconContainer isOpen={isOpen}>
            <FontAwesomeIcon icon={faAngleDown} />
          </IconContainer>
        </ToggleButton>
        <ToggleContainer>
          <Inner isOpen={isOpen}>
            {items.map(item => (
              <PaymentCancelledItem key={item.order_shop_item_id_group} item={item} />
            ))}
            <TotalPriceContainer>
              <TotalPriceLabel>
                <Text size="SMALL">合計</Text>
              </TotalPriceLabel>
              <TotalPriceValue>
                <Text size="SMALL">{`-${PriceUtils.getPresentationValue(
                  paymentCancelPrice,
                )}`}</Text>
              </TotalPriceValue>
            </TotalPriceContainer>
          </Inner>
        </ToggleContainer>
      </Container>
    );
  },
);
