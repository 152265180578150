import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconHold: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M24,42c9.9,0,18-8.1,18-18S33.9,6,24,6S6,14.1,6,24S14.1,42,24,42z M24,45c11.6,0,21-9.4,21-21 c0-11.6-9.4-21-21-21C12.4,3,3,12.4,3,24C3,35.6,12.4,45,24,45z"
      />
      <path
        fill={fillColor}
        d="M15,17.5c0-0.6,0.4-1,1-1h4c0.6,0,1,0.4,1,1v13c0,0.6-0.4,1-1,1h-4c-0.6,0-1-0.4-1-1V17.5z"
      />
      <path
        fill={fillColor}
        d="M27,17.5c0-0.6,0.4-1,1-1h4c0.6,0,1,0.4,1,1v13c0,0.6-0.4,1-1,1h-4c-0.6,0-1-0.4-1-1V17.5z"
      />
    </svg>
  );
});
