import * as Yup from "yup";
import { ValidateUtils } from "utils/ValidateUtils";
import { LocalStorage } from "utils/LocalStorage";
import { EncryptedLocalStorage } from "utils/EncryptedLocalStorage";

export type LoginShopForm = {
  login_id: string;
  password: string;
};

export const initialLoginShopForm: LoginShopForm = {
  login_id: LocalStorage.getItemOrDefault("loginId", ""),
  password: EncryptedLocalStorage.get("loginPassword"),
};

export const loginShopFormValidationSchema = Yup.object({
  login_id: Yup.string()
    // .matches(ValidateUtils.validEmailRegex, "メールアドレスの形式が正しくありません")
    .required("ログインIDを入力して下さい"),
  password: Yup.string()
    .matches(ValidateUtils.validPasswordRegex, "半角英数記号で入力してください")
    .required("パスワードを入力して下さい"),
})
  .strict(true)
  .noUnknown();
