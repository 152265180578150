import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconReserved: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
      <path
        fill={fillColor}
        d="M11.5,27c-0.6,0-1,0.4-1,1v4.7c0,0.6,0.4,1,1,1h4.7c0.6,0,1-0.4,1-1V28c0-0.6-0.4-1-1-1H11.5z"
      />
      <path
        fill={fillColor}
        d="M20.5,28c0-0.6,0.4-1,1-1h4.7c0.6,0,1,0.4,1,1v4.7c0,0.6-0.4,1-1,1h-4.7c-0.6,0-1-0.4-1-1V28z"
      />
      <path
        fill={fillColor}
        d="M31.5,27c-0.6,0-1,0.4-1,1v4.7c0,0.6,0.4,1,1,1h4.7c0.6,0,1-0.4,1-1V28c0-0.6-0.4-1-1-1H31.5z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M15.3,5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5v1.7H4.5c-2.2,0-4,1.8-4,4V41c0,2.2,1.8,4,4,4h38.7 c2.2,0,4-1.8,4-4V10.7c0-2.2-1.8-4-4-4h-7.8V5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5v1.7h-17V5z M32.3,11.7v-2h-17v2 c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5v-2H4.5c-0.6,0-1,0.4-1,1v6.2h40.7v-6.2c0-0.6-0.4-1-1-1h-7.8v2 c0,0.8-0.7,1.5-1.5,1.5C33,13.2,32.3,12.5,32.3,11.7z M3.5,41V19.8h40.7V41c0,0.6-0.4,1-1,1H4.5C3.9,42,3.5,41.5,3.5,41z"
      />
    </svg>
  );
});
