import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconDelivered: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 48 48" width="1em" height="1em" fill="none" {...props}>
      <path d="M8 18V42H40V18" stroke={fillColor} strokeWidth="3" strokeLinejoin="round" />
      <path
        d="M3 22.75L24 4.5L45 22.75"
        stroke={fillColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 26.6667L21.4545 32L31 24"
        stroke={fillColor}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 18V5H34C34 5 34 8.70423 34 13"
        stroke={fillColor}
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  );
});
