import React, { useMemo, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { API_KEY } from "apis";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { userAccessedToPageThatNeedsShopBusiness } from "modules/shop/actions";
import { DateUtils } from "utils/DateUtils";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Contents } from "components/atoms/Contents";
import { Calendar } from "components/atoms/Calendar";
import { Article } from "components/organisms/Article";
import { ShopBusinessHoliday } from "components/organisms/ShopBusinessHoliday";

const CalendarContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const isConnectedApiSelector = isConnectedApiSelectorFactory([API_KEY.GET_SHOP_BUSINESS]);

type Props = {};

export const ShopBusinessHolidayTemplate: React.FC<Props> = React.memo(() => {
  const loading = useSelector(isConnectedApiSelector);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [isOpenedCalendar, setIsOpenedCalendar] = useState<boolean>(false);

  const today = useMemo(() => new Date(), []);
  const maxDate = DateUtils.getDateWithAddedMonth(today, 6);

  const dispatch = useDispatch();

  const toggleCalendar = useCallback(() => {
    setIsOpenedCalendar(!isOpenedCalendar);
  }, [isOpenedCalendar]);

  const handleSelectDate = useCallback(
    (date: Date) => {
      setSelectedDate(date);
      toggleCalendar();
    },
    [toggleCalendar],
  );

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopBusiness());
  }, [dispatch]);

  return (
    <>
      <Article
        withHeader
        title="臨時休業日の設定"
        goBack="/setting"
        withNavigation
        activePath="SETTING"
      >
        {loading ? (
          <LoadingContainer />
        ) : (
          <Contents>
            <ShopBusinessHoliday
              today={today}
              maxDate={maxDate}
              selectedDate={selectedDate}
              toggleCalendar={toggleCalendar}
            />
          </Contents>
        )}
      </Article>
      <CalendarContainer>
        <Calendar
          isOpen={isOpenedCalendar}
          handleClick={handleSelectDate}
          handleClickBackDrop={toggleCalendar}
          minDate={today}
          maxDate={maxDate}
          maxDetail="month"
          minDetail="month"
        />
      </CalendarContainer>
    </>
  );
});
