import { put, cancel } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { AppError } from "records/AppError";
import { systemOpenedModal } from "../actions";

export function* networkErrorSaga() {
  yield put(
    systemOpenedModal(
      "APP_ERROR",
      AppError.create({
        title: "通信エラー",
        message: `通信エラーが発生しました。\n回線状況の良い場所で再度お試しください。`,
      }),
    ),
  );
  yield put(replace("/"));
  yield cancel();
}
