import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import {
  OrderModel,
  OrderHistoryStateFilterType,
  ORDER_HISTORY_STATE_FILTER_TYPE,
} from "modules/order/model";
import { fontSize } from "styles/mixins";
import { HeaderTab } from "components/atoms/HeaderTab";

const FilteredListLength = styled.span`
  ${fontSize.XXLARGE};
  vertical-align: middle;
  padding: 0 0.25em;
`;

const FilteredListLengthUnit = styled.span`
  vertical-align: middle;
`;

type Props = {
  orderHistoryList: OrderData[];
  filterType: OrderHistoryStateFilterType;
  displayableType: OrderHistoryStateFilterType;
  setDisplayableType: (value: React.SetStateAction<OrderHistoryStateFilterType>) => void;
};

export const OrderHistoryHeaderTab: React.FC<Props> = React.memo(
  ({ orderHistoryList, filterType, displayableType, setDisplayableType }) => {
    const isActiveTab = useMemo(
      () => filterType === displayableType,
      [filterType, displayableType],
    );

    const filteredOrderHistoryList = useMemo(
      () => OrderModel.getFilteredOrderHistoryList(orderHistoryList, filterType),
      [orderHistoryList, filterType],
    );

    const handleClick = useCallback(() => {
      setDisplayableType(filterType);
    }, [filterType, setDisplayableType]);

    return (
      <HeaderTab handleClick={handleClick} active={isActiveTab}>
        {OrderModel.getPresentationOrderHistoryFilterTypeText(filterType)}
        {filterType !== ORDER_HISTORY_STATE_FILTER_TYPE.ALL ? (
          <>
            <FilteredListLength>{filteredOrderHistoryList.length}</FilteredListLength>
            <FilteredListLengthUnit>件</FilteredListLengthUnit>
          </>
        ) : undefined}
      </HeaderTab>
    );
  },
);
