/* eslint-disable @typescript-eslint/no-explicit-any */
import { Task } from "redux-saga";
import { put, cancel, fork, join, call, select, retry } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { API_KEY, ApiKey, ForceUpdateResponse } from "apis";
import { ShopData } from "records/ShopData";
import { systemStartedConnectApi, systemFinishedConnectApi, initLoginToken } from "../actions";
import {
  AppModel,
  ERROR_MESSAGE_INVALID_TOKEN,
  FETCH_RETRY_TIMES,
  RETRY_INTERVAL_MILLI_SECOUNDS,
} from "../model";
import { appVersionSelector, loginTokenSelector } from "../selectors";
import { shopDataSelector } from "../../shop/selectors";
import { commonApiSuccessSaga } from "./commonApiSuccessSaga";
import { commonApiFailedSaga, ERROR_CODE } from "./commonApiFailedSaga";
import { commonErrorSaga } from "./commonErrorSaga";
import { networkErrorSaga } from "./networkErrorSaga";

function createRequest(
  method: ApiKey,
  params: any,
  token: string | null,
  shopId: number,
  appVersion: string,
) {
  const platform = AppModel.getPlatform();
  const requestBody = {
    jsonrpc: "2.0",
    method,
    params,
    common_params: {
      token,
      build_version: appVersion,
      platform,
      shop_id: shopId,
    },
    id: 0,
  };
  if (requestBody === null) {
    const pathName = window.location.pathname;
    throw new Error(
      `Invalid Request (JSON.stringfy Contents is null) from method:${method} params:${JSON.stringify(
        params,
      )} shopId:${shopId} platform:${platform} pathName:${pathName} token:${token}`,
    );
  }
  return new Request(
    `${process.env.REACT_APP_API_BASE_PATH}/${method}?method=${method}${
      ShopData.existsShopData(shopId) ? `&shop_id=${shopId}` : ""
    }` as string,
    {
      mode: "cors",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    },
  );
}

function createFetch(
  method: ApiKey,
  params: any,
  token: string | null,
  shopId: number,
  appVersion: string,
) {
  const request: Request = createRequest(method, params, token, shopId, appVersion);
  return new Promise((resolve, reject) => {
    fetch(request)
      .then((response: Response) => {
        if (response.status === 400) {
          response
            .clone()
            .json()
            .then(data => {
              if (
                typeof data.error !== "undefined" &&
                data.error.code === ERROR_CODE.InvalidTokenException
              ) {
                throw new Error(ERROR_MESSAGE_INVALID_TOKEN);
              } else {
                resolve(response);
              }
            })
            .catch((error: Error) => reject(error));
        } else {
          resolve(response);
        }
      })
      .catch((error: Error) => reject(error));
  });
}

export function* commonApiSaga(method: ApiKey, params: any) {
  try {
    const token: ReturnType<typeof loginTokenSelector> = yield select(loginTokenSelector);
    const appVersion: ReturnType<typeof appVersionSelector> = yield select(appVersionSelector);
    const shopData: ReturnType<typeof shopDataSelector> = yield select(shopDataSelector);
    yield put(systemStartedConnectApi(method));
    const response: Response = yield retry(FETCH_RETRY_TIMES, RETRY_INTERVAL_MILLI_SECOUNDS, () =>
      createFetch(method, params, token, shopData.id, appVersion),
    );
    if (response) {
      if (response.status === 401) {
        yield put(replace("/maintenance"));
        yield cancel();
      } else if (response.status === 409) {
        const task: Task = yield fork(commonApiSuccessSaga, response);
        yield join(task);
        if (task.isCancelled()) {
          yield cancel();
        }
        if (!task.isRunning()) {
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          const { result, error }: { result: ForceUpdateResponse; error: unknown } = task.result()!;
          if (result && !error) {
            // yield put(systemOpenedModal("FORCE_UPDATE", result));
          } else {
            yield call(commonErrorSaga);
          }
          yield cancel();
        }
      } else if (response.status >= 400) {
        const task: Task = yield fork(commonApiFailedSaga, response);
        yield join(task);
        if (task.isCancelled()) {
          yield cancel();
        } else {
          return task.result();
        }
      } else {
        const task: Task = yield fork(commonApiSuccessSaga, response);
        yield join(task);
        if (task.isCancelled()) {
          yield cancel();
        } else {
          return task.result();
        }
      }
    } else {
      yield call(networkErrorSaga);
      yield cancel();
    }
  } catch (error) {
    // リトライ後のトークンエラーのときの挙動
    if (error.message === ERROR_MESSAGE_INVALID_TOKEN) {
      yield put(initLoginToken());
      yield call(commonErrorSaga);
    } else {
      yield call(networkErrorSaga);
    }
    yield cancel();
  } finally {
    yield put(systemFinishedConnectApi(method));
  }
}
