import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API_KEY } from "apis";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { userAccessedToPageThatNeedsNewsList } from "modules/information/actions";
import { newsListSelector } from "modules/information/selectors";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Contents } from "components/atoms/Contents";
import { NoData } from "components/atoms/NoData";
import { Article } from "components/organisms/Article";
import { NewsList } from "components/organisms/NewsList";

const isConnectedApiSelector = isConnectedApiSelectorFactory([API_KEY.GET_INFORMATION]);

type Props = {};

export const InformationTemplate: React.FC<Props> = React.memo(() => {
  const loading = useSelector(isConnectedApiSelector);
  const newsList = useSelector(newsListSelector);

  const existsNewsList = useMemo(() => newsList.length > 0, [newsList]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsNewsList());
  }, [dispatch]);

  return (
    <Article withHeader title="お知らせ" withNavigation activePath="ORDER_LIST" goBack="/orderList">
      {loading ? (
        <LoadingContainer />
      ) : existsNewsList ? (
        <Contents>
          <NewsList list={newsList} />
        </Contents>
      ) : (
        <NoData>現在お知らせはありません。</NoData>
      )}
    </Article>
  );
});
