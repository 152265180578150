import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import { API_KEY } from "apis";
import { ShopData } from "records/ShopData";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { userAccessedToPageThatNeedsShopData } from "modules/shop/actions";
import {
  userAccessedToPageThatNeedsOrderList,
  userTouchedOrderListTab,
  systemInitOrderListTabType,
} from "modules/order/actions";
import { OrderListTabType, REG_EXP_ORDER_DETAIL_PATH } from "modules/order/model";
import { orderListSelector, orderListTabTypeSelector } from "modules/order/selectors";
import { shopDataSelector } from "modules/shop/selectors";
import { userAccessedToPageThatNeedsNewsReadFlag } from "modules/information/actions";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Article } from "components/organisms/Article";
import { OrderListContainer } from "components/organisms/OrderListContainer";
import { OrderListHeader } from "components/organisms/OrderListHeader";
import { OrderListContext, OrderListContextValue } from "components/organisms/OrderListContext";

const isConnectedApiSelector = isConnectedApiSelectorFactory([API_KEY.GET_ORDER_LIST]);

type Props = {};

export const OrderListTemplate: React.FC<Props> = React.memo(() => {
  const loading = useSelector(isConnectedApiSelector);
  const orderList = useSelector(orderListSelector);
  const shopData = useSelector(shopDataSelector);
  const orderListTabType = useSelector(orderListTabTypeSelector);

  const [reservedOrderOnly, setReservedOrderOnly] = useState<boolean>(false);
  const [notDisplayOrderItem, setNotDisplayOrderItem] = useState<boolean>(true);

  const dispatch = useDispatch();

  const handleClickTab = useCallback(
    (tabType: OrderListTabType) => {
      if (tabType === orderListTabType) {
        return;
      }
      dispatch(userTouchedOrderListTab(tabType));
    },
    [dispatch, orderListTabType],
  );

  useEffect(() => {
    batch(() => {
      dispatch(userAccessedToPageThatNeedsShopData());
      dispatch(userAccessedToPageThatNeedsOrderList());
      dispatch(userAccessedToPageThatNeedsNewsReadFlag());
    });
    /* eslint-disable no-restricted-globals */
    if (
      !(
        history.state &&
        history.state.state &&
        history.state.state.from &&
        history.state.state.from.pathname &&
        REG_EXP_ORDER_DETAIL_PATH.exec(history.state.state.from.pathname)
      )
    ) {
      // 注文一覧画面以外からの遷移時、タブ選択を初期化
      dispatch(systemInitOrderListTabType());
    }
    /* eslint-enable no-restricted-globals */
  }, [dispatch]);

  useEffect(() => {
    if (ShopData.isGrocery(shopData.shop_type)) {
      setNotDisplayOrderItem(true);
    }
  }, [shopData]);

  const contextValue = useMemo<OrderListContextValue>(
    () => ({
      shopData,
      reservedOrderOnly,
      notDisplayOrderItem,
      setReservedOrderOnly,
      setNotDisplayOrderItem,
    }),
    [shopData, reservedOrderOnly, notDisplayOrderItem],
  );

  return (
    <OrderListContext.Provider value={contextValue}>
      <Article
        withHeader
        headerComponent={
          <OrderListHeader
            orderList={orderList}
            shopData={shopData}
            orderListTabType={orderListTabType}
            reservedOrderOnly={reservedOrderOnly}
            handleClickTab={handleClickTab}
          />
        }
        withNavigation
        activePath="ORDER_LIST"
      >
        {loading ? (
          <LoadingContainer />
        ) : (
          <OrderListContainer
            orderList={orderList}
            shopData={shopData}
            orderListTabType={orderListTabType}
            reservedOrderOnly={reservedOrderOnly}
          />
        )}
      </Article>
    </OrderListContext.Provider>
  );
});
