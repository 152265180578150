import { fork, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import {
  userAccessedToPageThatNeedsOrderListSaga,
  userAccessedToPageThatNeedsOrderDetailSaga,
  userAccessedToPageThatNeedsDailyOrderHistorySaga,
  userTouchedOrderNotificationDialogSaga,
  userTouchedOrderNotificationBackdropSaga,
  userSubmittedApproveOrderSaga,
  userSubmittedFinishOrderSaga,
  userSubmittedFinishOrderForLocalAreaAndAutoApproveSaga,
  userSubmittedHoldOrderSaga,
  userSubmittedDenyOrderSaga,
  userSubmittedDenyOrderByRequiredItemShortageSaga,
  userSubmittedDenyOrderAfterApproveSaga,
  userTouchedHoldOrderForGroceryButtonSaga,
  userSubmittedApproveOrderForGrocerySaga,
  userChangedPickStatusSaga,
  userDebugUpdateNotificationStackSaga,
  userTouchedOrderListTabSaga,
  systemUpdateOrderDataSaga,
  systemInitOrderDataSaga,
  systemWatchNotificationSaga,
  systemInitNotificationStackSaga,
  systemWatchPickerAssignmentsSaga,
  systemInitOrderListTabTypeSaga,
} from "./sagas";

export function* orderSaga() {
  yield fork(watchUserAccessedToPageThatNeedsOrderListSaga);
  yield fork(watchUserAccessedToPageThatNeedsOrderDetailSaga);
  yield fork(watchUserAccessedToPageThatNeedsDailyOrderHistorySaga);
  yield fork(watchUserTouchedOrderNotificationDialogSaga);
  yield fork(watchUserTouchedOrderNotificationBackdropSaga);
  yield fork(watchUserSubmittedApproveOrderSaga);
  yield fork(watchUserSubmittedFinishOrderSaga);
  yield fork(watchUserSubmittedFinishOrderForLocalAreaAndAutoApproveSaga);
  yield fork(watchUserSubmittedHoldOrderSaga);
  yield fork(watchUserSubmittedDenyOrderSaga);
  yield fork(watchUserSubmittedDenyOrderByRequiredItemShortageSaga);
  yield fork(watchUserSubmittedDenyOrderAfterApproveSaga);
  yield fork(watchUserTouchedHoldOrderForGroceryButtonSaga);
  yield fork(watchUserSubmittedApproveOrderForGrocerySaga);
  yield fork(watchUserChangedPickStatusSaga);
  yield fork(watchUserDebugUpdateNotificationStackSaga);
  yield fork(watchUserTouchedOrderListTabSaga);
  yield fork(watchSystemUpdateOrderDataSaga);
  yield fork(watchSystemInitOrderDataSaga);
  yield fork(watchSystemWatchNotificationSaga);
  yield fork(watchSystemInitNotificationStackSaga);
  yield fork(watchSystemWatchPickerAssignmentsSaga);
  yield fork(watchSystemInitOrderListTabTypeSaga);
}

function* watchUserAccessedToPageThatNeedsOrderListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_LIST,
    userAccessedToPageThatNeedsOrderListSaga,
  );
}

function* watchUserAccessedToPageThatNeedsOrderDetailSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_DETAIL,
    userAccessedToPageThatNeedsOrderDetailSaga,
  );
}

function* watchUserAccessedToPageThatNeedsDailyOrderHistorySaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_DAILY_ORDER_HISTORY,
    userAccessedToPageThatNeedsDailyOrderHistorySaga,
  );
}

function* watchUserTouchedOrderNotificationDialogSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_ORDER_NOTIFICATION_DIALOG,
    userTouchedOrderNotificationDialogSaga,
  );
}

function* watchUserTouchedOrderNotificationBackdropSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_ORDER_NOTIFICATION_BACKDROP,
    userTouchedOrderNotificationBackdropSaga,
  );
}

function* watchUserSubmittedApproveOrderSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_APPROVE_ORDER, userSubmittedApproveOrderSaga);
}

function* watchUserSubmittedFinishOrderSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_FINISH_ORDER, userSubmittedFinishOrderSaga);
}

function* watchUserSubmittedFinishOrderForLocalAreaAndAutoApproveSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_FINISH_ORDER_FOR_LOCAL_AREA_AND_AUTO_APPROVE,
    userSubmittedFinishOrderForLocalAreaAndAutoApproveSaga,
  );
}

function* watchUserSubmittedHoldOrderSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_HOLD_ORDER, userSubmittedHoldOrderSaga);
}

function* watchUserSubmittedDenyOrderSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_DENY_ORDER, userSubmittedDenyOrderSaga);
}

function* watchUserSubmittedDenyOrderByRequiredItemShortageSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_DENY_ORDER_BY_REQUIRED_ITEM_SHORTAGE,
    userSubmittedDenyOrderByRequiredItemShortageSaga,
  );
}

function* watchUserSubmittedDenyOrderAfterApproveSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_DENY_ORDER_AFTER_APPROVE,
    userSubmittedDenyOrderAfterApproveSaga,
  );
}

function* watchUserTouchedHoldOrderForGroceryButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_HOLD_ORDER_FOR_GROCERY_BUTTON,
    userTouchedHoldOrderForGroceryButtonSaga,
  );
}

function* watchUserSubmittedApproveOrderForGrocerySaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_APPROVE_ORDER_FOR_GROCERY,
    userSubmittedApproveOrderForGrocerySaga,
  );
}

function* watchUserChangedPickStatusSaga() {
  yield takeLatest(ActionTypes.U_CHANGED_PICK_STATUS, userChangedPickStatusSaga);
}

function* watchUserDebugUpdateNotificationStackSaga() {
  yield takeLatest(
    ActionTypes.U_DEBUG_UPDATE_NOTIFICATION_STACK,
    userDebugUpdateNotificationStackSaga,
  );
}
function* watchUserTouchedOrderListTabSaga() {
  yield takeLatest(ActionTypes.U_TOUCHED_ORDER_LIST_TAB, userTouchedOrderListTabSaga);
}

function* watchSystemUpdateOrderDataSaga() {
  yield takeLatest(ActionTypes.S_UPDATE_ORDER_DATA, systemUpdateOrderDataSaga);
}

function* watchSystemInitOrderDataSaga() {
  yield takeLatest(ActionTypes.S_INIT_ORDER_DATA, systemInitOrderDataSaga);
}

function* watchSystemWatchNotificationSaga() {
  yield takeLatest(ActionTypes.S_WATCH_NOTIFICATION, systemWatchNotificationSaga);
}

function* watchSystemInitNotificationStackSaga() {
  yield takeLatest(ActionTypes.S_INIT_NOTIFICATION_STACK, systemInitNotificationStackSaga);
}

function* watchSystemWatchPickerAssignmentsSaga() {
  yield takeLatest(ActionTypes.S_WATCH_PICKER_ASSIGNMENTS, systemWatchPickerAssignmentsSaga);
}

function* watchSystemInitOrderListTabTypeSaga() {
  yield takeLatest(ActionTypes.S_INIT_ORDER_LIST_TAB_TYPE, systemInitOrderListTabTypeSaga);
}
