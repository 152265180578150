import firebase from "firebase";
import { eventChannel } from "redux-saga";
import { take, fork, delay } from "redux-saga/effects";
import { fb } from "utils/Firebase";
import { Utility } from "utils/Utility";
import { SentryUtil } from "utils/SentryUtil";
import { FirestoreNotificationData } from "records/FirestoreNotificationData";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateFirestoreOrderNotificationSaga } from "./updateFirestoreOrderNotificationSaga";

export function* watchFirestoreSaga(shopId: number, pollingInterval: number) {
  while (true) {
    yield delay(pollingInterval);
    const hash = `${Utility.getMd5String(shopId.toString())}_${shopId}`;
    const ref = fb
      .firestore()
      .collection(process.env.REACT_APP_FIREBASE_ORDER_NOTIFICATION_COLLECTION_NAME as string)
      .doc(hash)
      .collection("collection");
    const channel = eventChannel(emit => ref.onSnapshot(emit));
    try {
      while (true) {
        const data: firebase.firestore.QuerySnapshot<FirestoreNotificationData> = yield take(
          channel,
        );
        yield fork(updateFirestoreOrderNotificationSaga, data);
      }
    } catch (err) {
      SentryUtil.reportError(err);
      yield fork(commonErrorSaga);
    }
  }
}
