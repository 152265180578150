import { put, call, delay, select } from "redux-saga/effects";
import { OrderData } from "records/OrderData";
import { Printer } from "records/Printer";
import { PrintUtils } from "utils/PrintUtils";
import { CordovaUtil } from "utils/CordovaUtil";
import { autoPrintSettingSelector } from "modules/shop/selectors";
import { systemSetPrinterStatus } from "../actions";
import { AppModel, AUTO_PRINT_INTERVAL } from "../model";

export function* printReceiptSaga(orderData: OrderData, printer: Printer, printCount: number = 1) {
  const { portName, modelName } = printer;
  const platformId = CordovaUtil.getPlatformId();
  const emulator = PrintUtils.getEmulatorByModelName(modelName);

  const { font_size }: ReturnType<typeof autoPrintSettingSelector> = yield select(
    autoPrintSettingSelector,
  );

  let currentCount = 1;

  while (currentCount <= printCount) {
    yield put(systemSetPrinterStatus("printing"));
    yield call(
      PrintUtils.printRasterReceipt,
      PrintUtils.getPortNameByPlatform(portName, emulator, platformId),
      modelName,
      PrintUtils.generatePrintRasterObject(
        orderData,
        PrintUtils.getEmulatorByModelName(modelName),
        font_size,
      ),
    );
    yield put(systemSetPrinterStatus("printSuccess"));
    // ストレージ上の印刷回数を加算
    AppModel.increasePrintCount(orderData.order_no);
    if (currentCount < printCount) {
      // 設定枚数未満の場合、次のループへ
      yield delay(AUTO_PRINT_INTERVAL);
    } else {
      // 設定枚数を満たした場合、自動印刷を完了として処理終了
      AppModel.setAutoPrintCompleted(orderData.order_no);
    }
    currentCount += 1;
  }
}
