import React, { useCallback } from "react";
import styled from "styled-components";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { Text } from "components/atoms/Text";
import { Button, ButtonMainText, ButtonSubText } from "components/atoms/Button";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
} from "components/molecules/ModalDialog";

const TextContainer = styled.div`
  & + & {
    margin-top: 1em;
  }
`;

type Props = {
  children?: never;
  submitting?: boolean;
  handleClilckCancelOrderButton?: () => void;
};

const MODAL_KEY = "CANCEL_ORDER";
const useModal = modalHooksFactory(MODAL_KEY);

export const CancelOrderDialog: React.FC<Props> = React.memo(
  ({ submitting, handleClilckCancelOrderButton }) => {
    const { data, close } = useModal();

    const handleSubmit = useCallback(() => {
      if (typeof handleClilckCancelOrderButton !== "undefined") {
        handleClilckCancelOrderButton();
      }
    }, [handleClilckCancelOrderButton]);

    return (
      <ModalDialog isOpen={data.isOpen} close={close} handleClickBackdrop={close}>
        <ModalDialogHeader handleClose={close}>キャンセルの確認</ModalDialogHeader>
        <ModalBody>
          <TextContainer>
            <Text>
              本当にこの注文をキャンセルしますか？
              <br />
              お客様には店舗都合のキャンセルの通知が行きます。
            </Text>
          </TextContainer>
          <TextContainer>
            <Text>
              Are you sure you want to cancel this order?
              <br />
              Your customer receive a cancel notification by shop circumstances.
            </Text>
          </TextContainer>
        </ModalBody>
        <ModalFooter>
          <Button appearance="secondary" handleClick={handleSubmit} loading={submitting}>
            <ButtonMainText>店舗都合キャンセルする</ButtonMainText>
            <ButtonSubText>Yes, cancel</ButtonSubText>
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  },
);
