import React, { useMemo, useContext } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { isImmediateDeliveryPausedSelector } from "modules/shop/selectors";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { NavigationItem, PathType } from "./NavigationItem";
import { PermissionContainer, PermissionContext } from "./PermissionContext";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media (orientation: landscape) {
    flex-direction: column;
  }
  @media (orientation: portrait) {
    flex-direction: row;
  }
`;

const Badge = styled.div<{ badgeColor: string }>`
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 0.5em;
  white-space: nowrap;
  ${fontSize.XSMALL};
  color: ${colorsConst.TEXT.WHITE};
  background-color: ${p => p.badgeColor};
  border-radius: 1em;
  @media (orientation: landscape) {
    bottom: -0.75em;
  }
  @media (orientation: portrait) {
    top: -0.75em;
  }
`;

type Props = {
  activePath?: PathType;
};

export const Navigation: React.FC<Props> = React.memo(({ activePath }) => {
  const isImmediateDeliveryPaused = useSelector(isImmediateDeliveryPausedSelector);

  const renderBadge = useMemo(
    () =>
      isImmediateDeliveryPaused ? (
        <Badge badgeColor={colorsConst.BADGE.DANGER}>即配停止中</Badge>
      ) : undefined,
    [isImmediateDeliveryPaused],
  );

  const { role } = useContext(PermissionContext);
  return (
    <Container>
      <NavigationItem path="ORDER_LIST" activePath={activePath} renderBadge={renderBadge} />
      <PermissionContainer role={role} performance="normalOnly">
        <NavigationItem path="ORDER_HISTORY" activePath={activePath} />
        <NavigationItem path="SHOP_ITEM" activePath={activePath} />
      </PermissionContainer>
      <NavigationItem path="SETTING" activePath={activePath} />
    </Container>
  );
});
