import { fork, call } from "redux-saga/effects";
import { ORDER_TIME_TYPE } from "records/OrderData";
import { NOTIFICATION_TYPE } from "records/OrderNotification";
import { API_KEY, HoldOrderRequest, HoldOrderResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { UserTouchedHoldOrderForGroceryButtonAction } from "../actions";
import { OrderModel } from "../model";
import { updateOrderDetailSaga } from "./updateOrderDetailSaga";

export function* userTouchedHoldOrderForGroceryButtonSaga(
  action: UserTouchedHoldOrderForGroceryButtonAction,
) {
  try {
    const { orderData, addMinutes } = action.payload;
    const params: HoldOrderRequest = {
      order_no: orderData.order_no,
      add_minutes: addMinutes,
    };
    const { result, error }: { result: HoldOrderResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.HOLD_ORDER,
      params,
    );
    if (result && !error) {
      yield fork(updateOrderDetailSaga, orderData.order_no);
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
