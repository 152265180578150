import { BusinessTime } from "./BusinessTime";

export const BUSINESS_TYPE = {
  ONCE: 11,
  TWICE: 12,
  HOLIDAY: 21,
  UNSET: 31,
} as const;

export type BusinessType = ValueOf<typeof BUSINESS_TYPE>;

export const displayableBusinessType: ReadonlyArray<BusinessType> = [
  BUSINESS_TYPE.ONCE,
  BUSINESS_TYPE.TWICE,
  BUSINESS_TYPE.HOLIDAY,
] as const;

export type BusinessWeekTime = Readonly<{
  week: number;
  business_type: BusinessType;
  business_times: BusinessTime[];
}>;

const initialState: BusinessWeekTime = {
  week: 0,
  business_type: BUSINESS_TYPE.ONCE,
  business_times: [],
};

const create = (args: Partial<BusinessWeekTime> = {}) => ({
  ...initialState,
  ...args,
  business_times: args.business_times ? args.business_times.map(i => BusinessTime.create(i)) : [],
});

const isHoliday = (type: BusinessType) => type === BUSINESS_TYPE.HOLIDAY;

const getPresentationBusinessTypeString = (type: BusinessType) => {
  switch (type) {
    case BUSINESS_TYPE.ONCE:
      return "1日1回営業";
    case BUSINESS_TYPE.TWICE:
      return "1日2回営業";
    case BUSINESS_TYPE.HOLIDAY:
      return "休業日";
    case BUSINESS_TYPE.UNSET:
      return "未設定";
    // skip default case
  }
};

export const BusinessWeekTime = Object.freeze({
  initialState,
  create,
  isHoliday,
  getPresentationBusinessTypeString,
});
