import { fork, call } from "redux-saga/effects";
import { API_KEY, UpdatePickStatusRequest, UpdatePickStatusResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { UserChangedPickStatusAction } from "../actions";

export function* userChangedPickStatusSaga(action: UserChangedPickStatusAction) {
  try {
    const { order_no, order_shop_item_id_group, draft_item_counts, is_picked, callback } =
      action.payload;
    const params: UpdatePickStatusRequest = {
      order_no,
      order_shop_item_id_group,
      pick_count: draft_item_counts,
      is_picked,
    };
    const { result, error }: { result: UpdatePickStatusResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.UPDATE_PICK_STATUS,
      params,
    );
    if (result && !error) {
      if (typeof callback !== "undefined") {
        callback();
      }
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
