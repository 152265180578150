import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconMail: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 24 18" width="1em" height="1em" {...props}>
      <g>
        <path
          fill={fillColor}
          d="M3.5,2.6c-0.6,0-1.2,0.5-1.2,1.1v10.5c0,0.6,0.5,1.1,1.2,1.1h17c0.6,0,1.2-0.5,1.2-1.1V3.8 c0-0.6-0.5-1.1-1.2-1.1H3.5z M20.5,17.3h-17c-1.7,0-3.2-1.4-3.2-3V3.8c0-1.7,1.4-3.1,3.2-3.1h17c1.7,0,3.2,1.4,3.2,3v10.5 C23.7,15.9,22.3,17.3,20.5,17.3z"
        />
        <path fill={fillColor} d="M11.9,12.7L0.8,6.8l1-1.7l10.1,5.4l10.4-5.4l0.9,1.7L11.9,12.7z" />
      </g>
    </svg>
  );
});
