import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { colorsConst, fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";

const Container = styled.div<{ active: boolean; exists: boolean }>`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${(1 / 3) * 100}%;
  padding: 0.5em 0;
  color: ${colorsConst.TEXT.SECONDARY};
  font-weight: ${fontWeightsConst.BOLD};
  ${fontSize.SMALL};
  ${p => (p.exists ? `background-color: ${rgba(colorsConst.BACKGROUND.DANGER, 0.25)};` : "")}
  ${p =>
    p.active
      ? `
      color: ${colorsConst.TEXT.PRIMARY};
      &::after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 6px;
        left: 0;
        bottom: 0;
        background-color: ${colorsConst.BORDER.THICK};
      }
  `
      : ""}
`;

type Props = {
  children: React.ReactNode;
  active: boolean;
  exists?: boolean;
  handleClick: () => void;
};

export const HeaderTab: React.FC<Props> = React.memo(
  ({ children, active, exists = false, handleClick }) => (
    <Container onClick={handleClick} active={active} exists={exists}>
      {children}
    </Container>
  ),
);
