import React, { useMemo, useEffect } from "react";
import styled from "styled-components";
import { colorsConst, fontWeightsConst } from "styles/const";
import { fontSize, media } from "styles/mixins";
import { Modal } from "./Modal";

const ModalDialogHeaderElement = styled.div`
  position: relative;
  padding: 1em 0;
  text-align: center;
  font-weight: ${fontWeightsConst.BOLD};
  line-height: 1;
  background-color: ${colorsConst.BACKGROUND.WHITE};
  border-bottom: 1px solid ${colorsConst.BORDER.DEFAULT};
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 50%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  transform: translateY(-50%);
  ${fontSize.XLARGE};
  line-height: 1;
  appearance: none;
  border: none;
  background-color: inherit;
  &::before {
    content: "×";
  }
`;

type ModalDialogHeaderProps = {
  children: React.ReactNode;
  handleClose?: () => void;
};

const ModalDialogHeader: React.FC<ModalDialogHeaderProps> = React.memo(
  ({ handleClose, children }) => (
    <ModalDialogHeaderElement>
      {typeof handleClose !== "undefined" ? <ModalCloseButton onClick={handleClose} /> : undefined}
      {children}
    </ModalDialogHeaderElement>
  ),
);

const ModalBody = styled.div<{ justify?: string }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${p => (p.justify ? p.justify : "center")};
  align-items: stretch;
  padding: 1em;
`;

const ModalFooter = styled.div`
  padding: 1em;
`;

const ScrollContainer = styled.div`
  flex-grow: 1;
  position: relative;
  @media ${media.mobile} {
    margin: 0;
  }
`;

const ScrollInner = styled.div<{ inherit?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 1em;
  background-color: ${p => (p.inherit ? "inherit" : "#fff")};
  &::-webkit-scrollbar {
    width: 8px;
    background-color: ${colorsConst.BACKGROUND.DISABLED};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorsConst.TEXT.LINK};
  }
`;

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  close?: () => void;
  wrapperStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  backdrop?: boolean;
  backdropStyle?: React.CSSProperties;
  handleClickBackdrop?: (event: React.MouseEvent<HTMLElement>) => void;
  onOpened?: () => void;
  onClosed?: () => void;
};

const ModalDialog: React.FC<Props> = React.memo(
  ({
    children,
    isOpen,
    close,
    wrapperStyle,
    contentStyle,
    innerStyle,
    backdrop = true,
    backdropStyle,
    handleClickBackdrop,
    onOpened,
    onClosed,
  }) => {
    const modalDialogWrapperStyle: React.CSSProperties = useMemo(
      () => ({
        padding: "1.5em",
        ...wrapperStyle,
      }),
      [wrapperStyle],
    );

    const modalDialogContentStyle: React.CSSProperties = useMemo(
      () => ({
        ...contentStyle,
      }),
      [contentStyle],
    );

    const modalDialogInnerStyle: React.CSSProperties = useMemo(
      () => ({
        borderRadius: "6px",
        ...innerStyle,
      }),
      [innerStyle],
    );

    useEffect(
      () => () => {
        if (typeof close !== "undefined") {
          close();
        }
      },
      [close],
    );

    return (
      <Modal
        isOpen={isOpen}
        wrapperStyle={modalDialogWrapperStyle}
        contentStyle={modalDialogContentStyle}
        innerStyle={modalDialogInnerStyle}
        backdrop={backdrop}
        backdropStyle={backdropStyle}
        handleClickBackdrop={handleClickBackdrop}
        onOpened={onOpened}
        onClosed={onClosed}
      >
        {children}
      </Modal>
    );
  },
);

export {
  ModalDialog,
  ModalDialogHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ScrollContainer,
  ScrollInner,
};
