import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {};

export const IconMenuPass: React.FC<Props> = React.memo(({ ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 28 18" {...props}>
    <path
      fill={colorsConst.ICON.ACTIVE}
      stroke={colorsConst.ICON.WHITE}
      d="M3,0.5h22c1.4,0,2.5,1.1,2.5,2.5v11.6c0,1.4-1.1,2.5-2.5,2.5H3c-1.4,0-2.5-1.1-2.5-2.5V3C0.5,1.6,1.6,0.5,3,0.5z"
    />
    <path
      fill={colorsConst.ICON.WHITE}
      d="M5.3,11.8h-1V5.1h1v0.5C5.7,5.2,6.1,5,6.6,5c0.6,0,1.1,0.2,1.5,0.7c0.4,0.4,0.6,1,0.6,1.6 c0,0.6-0.2,1.2-0.6,1.6C7.7,9.3,7.2,9.5,6.7,9.5c-0.5,0-1-0.2-1.3-0.6V11.8z M7.7,7.3c0-0.4-0.1-0.7-0.3-1C7.1,6,6.8,5.9,6.5,5.9 C6.1,5.9,5.8,6,5.6,6.3c-0.2,0.3-0.3,0.6-0.3,1c0,0.4,0.1,0.7,0.3,1c0.2,0.3,0.5,0.4,0.9,0.4c0.3,0,0.6-0.1,0.8-0.4 C7.6,8,7.7,7.7,7.7,7.3z"
    />
    <path
      fill={colorsConst.ICON.WHITE}
      d="M13.5,5.1h1v4.3h-1V9c-0.4,0.4-0.8,0.6-1.3,0.6c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-1-0.6-1.6 c0-0.6,0.2-1.2,0.6-1.6C11.1,5.2,11.6,5,12.2,5c0.5,0,0.9,0.2,1.3,0.6V5.1z M11.2,7.3c0,0.4,0.1,0.7,0.3,1c0.2,0.3,0.5,0.4,0.9,0.4 c0.4,0,0.7-0.1,0.9-0.4c0.2-0.3,0.3-0.6,0.3-1c0-0.4-0.1-0.7-0.3-1C13,6,12.7,5.9,12.4,5.9c-0.3,0-0.6,0.1-0.9,0.4 C11.3,6.6,11.2,6.9,11.2,7.3z"
    />
    <path
      fill={colorsConst.ICON.WHITE}
      d="M19,5.9l-0.8,0.4C18,6,17.9,5.9,17.7,5.9c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.2,0.2,0.3,0.6,0.5 c0.5,0.2,0.9,0.4,1.1,0.6c0.2,0.2,0.3,0.4,0.3,0.8c0,0.4-0.2,0.8-0.5,1c-0.3,0.3-0.7,0.4-1.1,0.4c-0.7,0-1.2-0.4-1.5-1.1L17,8.1 c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.3,0,0.5-0.2,0.5-0.5c0-0.2-0.1-0.3-0.4-0.5c-0.1,0-0.2-0.1-0.3-0.1 c-0.1,0-0.2-0.1-0.3-0.1C17,7.3,16.8,7.1,16.6,7c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.4,0.1-0.7,0.4-0.9c0.3-0.2,0.6-0.4,1-0.4 C18.3,5,18.7,5.3,19,5.9z"
    />
    <path
      fill={colorsConst.ICON.WHITE}
      d="M23.5,5.9l-0.8,0.4c-0.1-0.3-0.3-0.4-0.5-0.4c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2 c0,0.2,0.2,0.3,0.6,0.5c0.5,0.2,0.9,0.4,1.1,0.6c0.2,0.2,0.3,0.4,0.3,0.8c0,0.4-0.2,0.8-0.5,1c-0.3,0.3-0.7,0.4-1.1,0.4 c-0.7,0-1.2-0.4-1.5-1.1l0.8-0.4c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.3,0,0.5-0.2,0.5-0.5c0-0.2-0.1-0.3-0.4-0.5 c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.5-0.3-0.6-0.4C21.1,6.8,21,6.6,21,6.3c0-0.4,0.1-0.7,0.4-0.9 c0.3-0.2,0.6-0.4,1-0.4C22.9,5,23.3,5.3,23.5,5.9z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={colorsConst.ICON.WHITE}
      d="M22.3,13.2H1v1h21.7L22.3,13.2z M23.6,14.2h0.8l-0.5-1h-0.8L23.6,14.2z M26.7,14.2h-1.4l-0.5-1h1.9V14.2z"
    />
  </svg>
));
