import { DateUtils } from "utils/DateUtils";
import { OrderReceiveType, OrderTimeType, ORDER_TIME_TYPE } from "records/OrderData";
import { ShopData } from "records/ShopData";

export const NOTIFICATION_TYPE = {
  FASTEST_ORDER: 1,
  RESERVED_ORDER: 2,
  COOKING_START: 3,
  AUTO_CANCEL: 4,
  USER_ARRIVED: 5,
  EXIST_NOT_FINISHED_ORDER: 6,
  FOUND_CREW: 7,
  NOT_FOUND_CREW: 8,
  APPROVE_COMBO_ORDER: 9,
  CANCEL_COMBO_ORDER: 10,
  CREW_MISMATCH: 11,
  BEFORE_AUTO_CANCEL: 12,
  BEFORE_DISABLE_APPROVAL: 13,
  REMIND_HOLD_ORDER: 14,
  ORDER_RESTART_BY_SHOP: 15,
  REMIND_RESERVED_ORDER: 16,
} as const;

export type NotificationType = ValueOf<typeof NOTIFICATION_TYPE>;

export type OrderNotification = {
  notification_type: NotificationType;
  order_no: string;
  display_order_no?: string;
  receive_type?: OrderReceiveType;
  time_type: OrderTimeType;
  is_combo: boolean;
  driver_arrival_datetime: string;
};

const initialState: OrderNotification = {
  notification_type: NOTIFICATION_TYPE.FASTEST_ORDER,
  order_no: "",
  display_order_no: "",
  receive_type: undefined,
  time_type: ORDER_TIME_TYPE.FASTEST,
  is_combo: false,
  driver_arrival_datetime: "",
} as const;

const create = (args: Partial<OrderNotification> = {}) => ({
  ...initialState,
  ...args,
});

const getNotificationPriority = (type: NotificationType) => {
  switch (type) {
    case NOTIFICATION_TYPE.BEFORE_AUTO_CANCEL:
      return 1;
    case NOTIFICATION_TYPE.FASTEST_ORDER:
    case NOTIFICATION_TYPE.RESERVED_ORDER:
      return 2;
    default:
      return 99;
  }
};

const getNotificationDialogTitleText = (
  notification: OrderNotification,
  shopData: ShopData,
  isSimulator: boolean,
): { jp: string; en?: string } => {
  switch (notification.notification_type) {
    case NOTIFICATION_TYPE.FASTEST_ORDER:
      if (shopData.is_costco && isSimulator) {
        return {
          jp: "予約注文の準備開始時間になりました",
        };
      }
      if (notification.is_combo) {
        return {
          jp: "コンボ注文が入りました",
        };
      }
      if (shopData.is_auto_hold) {
        return {
          jp: "新規注文を保留にしました。準備を開始してください。",
        };
      }
      return { jp: "新規注文が入りました" };
    case NOTIFICATION_TYPE.RESERVED_ORDER:
      return { jp: "予約注文が入りました" };
    case NOTIFICATION_TYPE.COOKING_START:
      return {
        jp: "予約注文の準備開始時間になりました",
      };
    case NOTIFICATION_TYPE.EXIST_NOT_FINISHED_ORDER:
      return {
        jp: "準備時間を過ぎている注文があります",
      };
    case NOTIFICATION_TYPE.FOUND_CREW:
    case NOTIFICATION_TYPE.APPROVE_COMBO_ORDER:
      return {
        jp: "注文が成立しました！\n準備を開始してください",
      };
    case NOTIFICATION_TYPE.NOT_FOUND_CREW:
      return {
        jp: "配達員が見つかりませんでした。注文はキャンセルされました。",
      };
    case NOTIFICATION_TYPE.CANCEL_COMBO_ORDER:
      return {
        jp: "他のお店が受付できませんでした。注文はキャンセルされました。",
      };
    case NOTIFICATION_TYPE.CREW_MISMATCH:
      return {
        jp: "配達員が見つかりませんでした。注文はキャンセルされました。",
      };
    case NOTIFICATION_TYPE.BEFORE_AUTO_CANCEL:
      return {
        jp: "もうすぐ自動キャンセルになる注文があります",
      };
    case NOTIFICATION_TYPE.BEFORE_DISABLE_APPROVAL:
      return {
        jp: "速やかに受付操作を！\nやむを得ずキャンセルするにはキャンセル操作が必要になります。",
        en: "PLEASE ACCEPT THE ORDER IMMEDIATELY.",
      };
    case NOTIFICATION_TYPE.REMIND_HOLD_ORDER:
      return {
        jp: "保留中の注文が残っています。速やかに準備開始してください。",
      };
    case NOTIFICATION_TYPE.ORDER_RESTART_BY_SHOP:
      return {
        jp: "営業時間が再開しました",
      };
    case NOTIFICATION_TYPE.REMIND_RESERVED_ORDER:
      return {
        jp: "対応が必要な注文が残っています。速やかに準備開始してください。",
      };
    default:
      return { jp: "" };
  }
};

const getNotificationDialogCaptionText = (
  notification: OrderNotification,
  shopData: ShopData,
  isSimulator: boolean,
): { jp?: string; en?: string } | undefined => {
  switch (notification.notification_type) {
    case NOTIFICATION_TYPE.FASTEST_ORDER:
      if (shopData.is_costco && isSimulator) {
        return {
          en: "It`s time to prepare for pre-order",
        };
      }
      if (notification.is_combo) {
        return {
          jp: "この注文は2店舗同時に配達するため他の店舗が受付を完了するまで成立しません",
          en: "You got new combo order. This order must be done another shop receive their order.",
        };
      }
      if (shopData.is_auto_hold) {
        return {
          en: "New order was placed on hold. Please start preparing.",
        };
      }
      return {
        en: "You got new order",
      };
    case NOTIFICATION_TYPE.RESERVED_ORDER:
      return { en: "You got new pre-order" };
    case NOTIFICATION_TYPE.COOKING_START:
      return {
        en: "It`s time to prepare for pre-order",
      };
    case NOTIFICATION_TYPE.EXIST_NOT_FINISHED_ORDER:
      return {
        en: "Prep time has already run order",
      };
    case NOTIFICATION_TYPE.FOUND_CREW:
    case NOTIFICATION_TYPE.APPROVE_COMBO_ORDER:
      return notification.driver_arrival_datetime !== ""
        ? {
            jp: `配達員は${DateUtils.dateToString(
              notification.driver_arrival_datetime,
              "HH:mm",
            )}に到着予定です`,
            en: `Matching order! Please start preparing\nCrew will arrive ${DateUtils.dateToString(
              notification.driver_arrival_datetime,
              "HH:mm",
            )}`,
          }
        : {
            en: "Matching order! Please start preparing",
          };
    case NOTIFICATION_TYPE.NOT_FOUND_CREW:
      return {
        en: "Crew couldn't be found out. Your order was canceled",
      };
    case NOTIFICATION_TYPE.CANCEL_COMBO_ORDER:
      return {
        en: "Another shop declined order. Your order was canceled",
      };
    case NOTIFICATION_TYPE.CREW_MISMATCH:
      return {
        en: "Crew couldn't be found out. Your order was canceled. Please discard your order However, your payment will add to sales.",
      };
    case NOTIFICATION_TYPE.BEFORE_AUTO_CANCEL:
      return {
        en: "Your order will be canceled automatically",
      };
    case NOTIFICATION_TYPE.BEFORE_DISABLE_APPROVAL:
      return {
        en: "If you wish to cancel your order due to unavoidable circumstances, you will need to cancel the order from your tablet device.",
      };
    case NOTIFICATION_TYPE.REMIND_HOLD_ORDER:
      return {
        en: "Orders on hold still exist. Please start preparing immediately.",
      };
    case NOTIFICATION_TYPE.ORDER_RESTART_BY_SHOP:
      return {
        en: "Shop is opened.",
      };
    case NOTIFICATION_TYPE.REMIND_RESERVED_ORDER:
      return {
        en: "Orders that need to be addressed still exist. Please start preparing immediately.",
      };
    default:
      return undefined;
  }
};

/**
 * タップして閉じるだけの通知
 * - 各種キャンセル通知
 * - 店舗受付再開、保留注文リマインド
 * - 自動保留が有効の店舗の新規注文
 */
const isNotificationToCloseOnly = (notification: OrderNotification, shopData: ShopData) =>
  OrderNotification.isCancelOrder(notification.notification_type) ||
  (
    [
      NOTIFICATION_TYPE.ORDER_RESTART_BY_SHOP,
      NOTIFICATION_TYPE.REMIND_HOLD_ORDER,
      NOTIFICATION_TYPE.REMIND_RESERVED_ORDER,
    ] as NotificationType[]
  ).includes(notification.notification_type) ||
  (shopData.is_auto_hold && notification.notification_type === NOTIFICATION_TYPE.FASTEST_ORDER);

const isNotificationThatNeedsUpdateShopData = (notification_type: NotificationType) =>
  notification_type === NOTIFICATION_TYPE.ORDER_RESTART_BY_SHOP;

const getNotificationCloseDialogText = (
  notification: OrderNotification,
  shopData: ShopData,
): { jp: string; en: string } => {
  if (isNotificationToCloseOnly(notification, shopData)) {
    return { jp: "画面をタッチして閉じる", en: "Tap to close" };
  }
  return { jp: "内容を確認するには画面をタッチしてください", en: "Tap to check your order" };
};

const getNotificationBannerText = (notification: OrderNotification): string => {
  switch (notification.notification_type) {
    case NOTIFICATION_TYPE.FASTEST_ORDER:
    case NOTIFICATION_TYPE.RESERVED_ORDER:
      return "注文が入りました。";
    case NOTIFICATION_TYPE.COOKING_START:
      return "予約注文の準備開始時間になりました。";
    case NOTIFICATION_TYPE.EXIST_NOT_FINISHED_ORDER:
      return "準備時間を過ぎている注文があります。";
    case NOTIFICATION_TYPE.FOUND_CREW:
    case NOTIFICATION_TYPE.APPROVE_COMBO_ORDER:
      return "注文が成立しました。準備を開始してください。";
    case NOTIFICATION_TYPE.NOT_FOUND_CREW:
    case NOTIFICATION_TYPE.CREW_MISMATCH:
      return "配達員が見つかりませんでした。\n注文はキャンセルされました。";
    case NOTIFICATION_TYPE.CANCEL_COMBO_ORDER:
      return "他のお店が受け付けできませんでした。\n注文はキャンセルされました。";
    case NOTIFICATION_TYPE.AUTO_CANCEL:
      return "注文が自動キャンセルされました";
    case NOTIFICATION_TYPE.BEFORE_AUTO_CANCEL:
      return "もうすぐ自動キャンセルになる注文があります。";
    case NOTIFICATION_TYPE.BEFORE_DISABLE_APPROVAL:
      return "もうすぐ受け付けできなくなる注文があります。";
    case NOTIFICATION_TYPE.REMIND_HOLD_ORDER:
      return "保留中の注文が残っています";
    case NOTIFICATION_TYPE.ORDER_RESTART_BY_SHOP:
      return "営業時間が再開しました";
    case NOTIFICATION_TYPE.REMIND_RESERVED_ORDER:
      return "注文を速やかに準備開始してください";
    default:
      return "";
  }
};

const isNewOrder = (type: NotificationType) =>
  (
    [NOTIFICATION_TYPE.FASTEST_ORDER, NOTIFICATION_TYPE.RESERVED_ORDER] as NotificationType[]
  ).includes(type);

const isCancelOrder = (type: NotificationType) =>
  (
    [
      NOTIFICATION_TYPE.AUTO_CANCEL,
      NOTIFICATION_TYPE.NOT_FOUND_CREW,
      NOTIFICATION_TYPE.CANCEL_COMBO_ORDER,
    ] as NotificationType[]
  ).includes(type);

export const OrderNotification = Object.freeze({
  initialState,
  create,
  getNotificationPriority,
  getNotificationDialogTitleText,
  getNotificationDialogCaptionText,
  isNotificationToCloseOnly,
  isNotificationThatNeedsUpdateShopData,
  getNotificationCloseDialogText,
  getNotificationBannerText,
  isNewOrder,
  isCancelOrder,
});
