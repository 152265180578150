import { fork, call, put } from "redux-saga/effects";
import { replace } from "connected-react-router";
import {
  API_KEY,
  UpdateBusinessNationalHolidayRequest,
  UpdateBusinessNationalHolidayResponse,
} from "apis";
import { BusinessType } from "records/BusinessWeekTime";
import { updateSnackBar } from "modules/app/actions";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateShopBusiness, UserSubmittedUpdateBusinessNationalHolidayAction } from "../actions";

export function* userSubmittedUpdateBusinessNationalHolidaySaga(
  action: UserSubmittedUpdateBusinessNationalHolidayAction,
) {
  try {
    const { business_national_holiday } = action.payload.values;
    const params: UpdateBusinessNationalHolidayRequest = {
      business_national_holiday: {
        business_type: parseInt(
          business_national_holiday.business_type.toString(),
          10,
        ) as BusinessType,
        business_times: business_national_holiday.business_times,
      },
    };
    const { result, error }: { result: UpdateBusinessNationalHolidayResponse; error: unknown } =
      yield call(commonApiSaga, API_KEY.UPDATE_BUSINESS_NATIONAL_HOLIDAY, params);
    if (result && !error) {
      yield put(replace("/shopBusiness"));
      yield put(updateShopBusiness(result.shop_business));
      yield put(updateSnackBar("祝日の営業時間を編集しました"));
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
