import React from "react";
import { OrderData } from "records/OrderData";
import { DraftItem } from "records/OrderItemData";
import { ShopData } from "records/ShopData";
import { ShopItemParentCategory } from "records/ShopItemParentCategory";
import { ReasonForItemsStop } from "records/ShopItem";

export type OrderDetailContextValue = {
  isHistory?: boolean;
  isSimulator?: boolean;
  orderData: OrderData;
  shopData: ShopData;
  isBusy?: boolean;
  isRetail?: boolean;
  isGrocery?: boolean;
  addMinutes?: number;
  setAddMinutes?: (value: React.SetStateAction<number>) => void;
  draftItems: DraftItem[];
  setDraftItems?: (value: React.SetStateAction<DraftItem[]>) => void;
  draftTotalItemCounts: number;
  canChangeItemCount?: boolean;
  isContainRequiredItemOrder?: boolean;
  isAllRequiredItemsChecked?: boolean;
  canDisplayPrintReceiptButton?: boolean;
  submitting?: boolean;
  shopItemParentCategories: ShopItemParentCategory[];
  handleClickApproveOrderButton?: () => void;
  handleClickApproveOrderWithUpdateCookingStartTimeButton?: (remindMinutes: number) => void;
  handleClickHoldOrderButton?: () => void;
  handleClickFinishOrderButton?: () => void;
  handleClickFindCrewForLocalAreaButton?: () => void;
  handleClilckCancelOrderButton?: () => void;
  handleClilckCancelOrderButtonByRequiredItemShortage?: () => void;
  handleCloseFindingCrewDialog?: () => void;
  handleCloseWaitingComboOrderDialog?: () => void;
  handleClickHoldOrderForGroceryButton?: () => void;
  handleClickApproveOrderForGroceryButton?: () => void;
  handleChangePickStatus?: (draftItem: DraftItem, checked: boolean) => void;
  handleChangeSelectStopReasonShopItems?: (
    shopItemId: number,
    stopReasonType: ReasonForItemsStop,
  ) => void;
};

export const OrderDetailContext = React.createContext<OrderDetailContextValue>(
  {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
);
