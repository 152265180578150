import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { API_KEY } from "apis";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { userSubmittedStopFastestOrder } from "modules/shop/actions";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
} from "components/molecules/ModalDialog";

const MODAL_KEY = "STOP_FASTEST_ORDER";
const useModal = modalHooksFactory(MODAL_KEY);

const isConnectedApiSelector = isConnectedApiSelectorFactory([
  API_KEY.STOP_IMMEDIATE_DELIVERY_ORDER,
]);

export const StopFastestOrderDialog: React.FC = React.memo(() => {
  const submitting = useSelector(isConnectedApiSelector);

  const { data, close } = useModal();

  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    dispatch(userSubmittedStopFastestOrder());
  }, [dispatch]);

  return (
    <ModalDialog isOpen={data.isOpen} close={close} handleClickBackdrop={close}>
      <ModalDialogHeader handleClose={close}>即配注文を停止</ModalDialogHeader>
      <ModalBody>
        <div>
          <Text>
            次の時間帯まで即配注文を停止してもよろしいですか？予約注文のみ受付中になります。
          </Text>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button appearance="secondary" handleClick={handleSubmit} loading={submitting}>
          停止する
        </Button>
      </ModalFooter>
    </ModalDialog>
  );
});
