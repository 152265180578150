import React, { useRef, useMemo, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { ShopItemParentCategory } from "records/ShopItemParentCategory";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${colorsConst.BACKGROUND.WHITE};
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  user-select: none;
  & + & {
    margin-top: 1em;
  }
`;

const IconContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.25em 0.5em;
  ${fontSize.XXLARGE};
`;

const CategoryNameContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.5em 0.5em 0.5em 0;
`;

type Props = {
  index: number;
  category: ShopItemParentCategory;
  moveList: (dragIndex: number, hoverIndex: number) => void;
};

export const ItemTypes = {
  LIST: "list",
};

type DragItem = {
  index: number;
  category: ShopItemParentCategory;
};

export const ListItem: React.FC<Props> = React.memo(({ index, category, moveList }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.LIST,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveList(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      Object.assign(item, { index: hoverIndex });
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.LIST,
    item: () => ({ category, index }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const style: React.CSSProperties = useMemo(
    () => ({ opacity: isDragging ? 0.5 : 1 }),
    [isDragging],
  );

  useEffect(() => {
    if (ref.current) {
      drag(drop(ref));
    }
  }, [drag, drop]);

  return (
    <Container style={style}>
      <IconContainer ref={ref} data-handler-id={handlerId}>
        <FontAwesomeIcon icon={faBars} />
      </IconContainer>
      <CategoryNameContainer>{category.parent_category_name}</CategoryNameContainer>
    </Container>
  );
});
