/* eslint-disable func-names*/
import * as Yup from "yup";
import { BusinessType, BUSINESS_TYPE } from "records/BusinessWeekTime";
import { BusinessNationalHoliday } from "records/BusinessNationalHoliday";

export type UpdateBusinessNationalHolidayForm = {
  business_national_holiday: BusinessNationalHoliday;
};

export const updateBusinessNationalHolidayFormValidationSchema = Yup.object({
  business_national_holiday: Yup.object().shape({
    business_type: Yup.mixed<BusinessType>().oneOf(Object.values(BUSINESS_TYPE)),
    business_times: Yup.array().of(
      Yup.object().shape({
        start_time: Yup.string().required("時間帯を選択してください"),
        end_time: Yup.string()
          .required("時間帯を選択してください")
          .test("isAfter", "正しい時間帯を選択してください", function (value) {
            if (typeof value === "undefined") {
              return false;
            }
            const startTimeInt = parseInt(this.parent["start_time"], 10);
            const endTimeInt = parseInt(value, 10);
            if (Number.isNaN(startTimeInt) || Number.isNaN(endTimeInt)) {
              return false;
            }
            return startTimeInt < endTimeInt;
          }),
      }),
    ),
    busy_times: Yup.array().of(
      Yup.object().shape({
        start_time: Yup.string().required("時間帯を選択してください"),
        end_time: Yup.string()
          .required("時間帯を選択してください")
          .test("isAfter", "正しい時間帯を選択してください", function (value) {
            if (typeof value === "undefined") {
              return false;
            }
            const startTimeInt = parseInt(this.parent["start_time"], 10);
            const endTimeInt = parseInt(value, 10);
            if (Number.isNaN(startTimeInt) || Number.isNaN(endTimeInt)) {
              return false;
            }
            return startTimeInt < endTimeInt;
          }),
      }),
    ),
  }),
})
  .strict(true)
  .noUnknown();
