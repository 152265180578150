import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { DraftItem } from "records/OrderItemData";
import { ShopData } from "records/ShopData";
import { Printer } from "records/Printer";
import { printerSelector } from "modules/app/selectors";
import { userAccessedToPageThatNeedsOrderDetail } from "modules/order/actions";
import { OrderModel } from "modules/order/model";
import { orderDataSelector } from "modules/order/selectors";
import { userAccessedToPageThatNeedsShopItemParentCategories } from "modules/shop/actions";
import { shopDataSelector, shopItemParentCategoriesSelector } from "modules/shop/selectors";
import { DateUtils } from "utils/DateUtils";
import { Contents } from "components/atoms/Contents";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Article } from "components/organisms/Article";
import { OrderDetail } from "components/organisms/OrderDetail";
import { OrderItemImageDialog } from "components/organisms/OrderDetail/OrderItemImageDialog";
import {
  OrderDetailContext,
  OrderDetailContextValue,
} from "components/organisms/OrderDetail/OrderDetailContext";

const Container = styled.div``;

type Props = RouteComponentProps<{ orderId: string }> & {};

const OrderHistoryDetailTemplate: React.FC<Props> = React.memo(
  ({
    match: {
      params: { orderId },
    },
  }) => {
    const orderData = useSelector(orderDataSelector);
    const shopData = useSelector(shopDataSelector);

    const { discoveredPrinter, isDiscoveredPrinter, isConnectedPrinter } =
      useSelector(printerSelector);

    const shopItemParentCategories = useSelector(shopItemParentCategoriesSelector);

    const [draftItems, setDraftItems] = useState<DraftItem[]>([]);

    const draftTotalItemCounts = useMemo(
      () => OrderModel.getDraftTotalItemsCount(draftItems, orderData),
      [draftItems, orderData],
    );

    const orderDate = useMemo(
      () =>
        DateUtils.isValidDateString(orderData.order_date)
          ? DateUtils.dateToString(orderData.order_date, "YYYYMMDD")
          : "",
      [orderData],
    );

    /**
     * 印刷ボタン表示条件
     * 未受付状態でない、キャンセル済でない状態
     */
    const canDisplayPrintReceiptButton = useMemo(
      () => isDiscoveredPrinter && OrderModel.canDisplayPrintReceiptButton(orderData),
      [orderData, isDiscoveredPrinter],
    );

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(userAccessedToPageThatNeedsShopItemParentCategories());
    }, [dispatch]);

    useEffect(() => {
      dispatch(userAccessedToPageThatNeedsOrderDetail(orderId));
    }, [dispatch, orderId]);

    useEffect(() => {
      if (OrderData.existsOrder(orderData)) {
        setDraftItems(
          orderData.item_list.map(i => ({
            ...i,
            pick_count: i.order_count,
          })),
        );
      }
    }, [orderData]);

    const contextValue = useMemo<OrderDetailContextValue>(
      () => ({
        isHistory: true,
        isSimulator: false,
        orderData,
        draftItems,
        shopData,
        shopItemParentCategories,
        isGrocery: ShopData.isGrocery(shopData.shop_type),
        draftTotalItemCounts,
        canDisplayPrintReceiptButton,
      }),
      [
        orderData,
        draftItems,
        shopData,
        shopItemParentCategories,
        draftTotalItemCounts,
        canDisplayPrintReceiptButton,
      ],
    );

    return (
      <OrderDetailContext.Provider value={contextValue}>
        <Article
          withHeader
          title="注文履歴"
          withNavigation
          activePath="ORDER_HISTORY"
          goBack={`/orderHistory/${orderDate}`}
        >
          {OrderData.existsOrder(orderData) ? (
            <Contents>
              <OrderDetail />
            </Contents>
          ) : (
            <LoadingContainer />
          )}
        </Article>
        <OrderItemImageDialog />
      </OrderDetailContext.Provider>
    );
  },
);

export default withRouter(OrderHistoryDetailTemplate);
