import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { userAccessedToPageThatNeedsShopBusiness } from "modules/shop/actions";
import { existsShopBusinessDataSelector } from "modules/shop/selectors";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Article } from "components/organisms/Article";
import { ShopBusinessTimeEdit } from "components/organisms/ShopBusinessTimeEdit";

const Container = styled.div``;

type Props = {};

export const ShopBusinessTimeEditTemplate: React.FC<Props> = React.memo(() => {
  const existsShopBusinessData = useSelector(existsShopBusinessDataSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopBusiness());
  }, [dispatch]);

  return (
    <Article
      withHeader
      title="曜日別の営業時間設定"
      goBack="/shopBusiness"
      withNavigation
      activePath="SETTING"
    >
      {!existsShopBusinessData ? <LoadingContainer /> : <ShopBusinessTimeEdit />}
    </Article>
  );
});
