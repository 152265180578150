import React from "react";
import { OrderStopByShopDialog } from "./OrderStopDialog/OrderStopByShopDialog";
import { OrderRestartByShopDialog } from "./OrderStopDialog/OrderRestartByShopDialog";
import { StopFastestOrderDialog } from "./StopFastestOrderDialog";

type Props = {};

export const OrderStopModal: React.FC<Props> = React.memo(() => (
  <>
    <OrderStopByShopDialog />
    <OrderRestartByShopDialog />
    <StopFastestOrderDialog />
  </>
));
