import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ShopData } from "records/ShopData";
import { shopDataSelector } from "modules/shop/selectors";
import { Utility } from "utils/Utility";
import { colorsConst } from "styles/const";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Text } from "components/atoms/Text";
import { TextLink } from "components/atoms/TextLink";
import { MapContainer } from "components/molecules/MapContainer";
import { ContactInfo } from "components/molecules/ContactInfo";

const Container = styled.div``;

const TextButtonContainer = styled.div`
  text-align: center;
`;

const ShopDataContainer = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

const ShopDataMapContainer = styled.div`
  margin-top: 1em;
`;

const ShopDataKey = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 7em;
`;

const ShopDataVal = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const ShopImageContainer = styled.div`
  max-width: 450px;
`;

const ShopImage = styled.div<{ src: string }>`
  background: url(${p => p.src});
  background-size: cover;
  width: 100%;
  padding-top: 100%;
`;

type Props = {};

export const ShopDetail: React.FC<Props> = React.memo(() => {
  const shopData = useSelector(shopDataSelector);

  const existsShopData = useMemo(() => ShopData.existsShopData(shopData.id), [shopData]);

  const presentationShopCategoryText = useMemo(
    () =>
      existsShopData ? shopData.category_tag.map((category: string) => category).join("・") : "",
    [existsShopData, shopData],
  );

  return !existsShopData ? (
    <LoadingContainer />
  ) : (
    <Container>
      <TextButtonContainer>
        <TextLink to="/shopBusinessSetting/shop">店舗の受付設定を変更する</TextLink>
      </TextButtonContainer>
      <ShopDataContainer>
        <ShopDataKey>
          <Text color="SECONDARY">店舗画像</Text>
        </ShopDataKey>
        <ShopDataVal>
          <ShopImageContainer>
            <ShopImage src={Utility.getImagePath(shopData.header_image_path)} />
          </ShopImageContainer>
        </ShopDataVal>
      </ShopDataContainer>
      <ShopDataContainer>
        <ShopDataKey>
          <Text color="SECONDARY">店舗名</Text>
        </ShopDataKey>
        <ShopDataVal>
          <div>
            <Text size="SMALL">{shopData.shop_name_ruby}</Text>
          </div>
          <div>
            <Text size="LARGE">{shopData.shop_name}</Text>
          </div>
        </ShopDataVal>
      </ShopDataContainer>
      <ShopDataContainer>
        <ShopDataKey>
          <Text color="SECONDARY">店舗電話番号</Text>
        </ShopDataKey>
        <ShopDataVal>
          <Text size="LARGE">{shopData.tel}</Text>
        </ShopDataVal>
      </ShopDataContainer>
      <ShopDataContainer>
        <ShopDataKey>
          <Text color="SECONDARY">店舗住所</Text>
        </ShopDataKey>
        <ShopDataVal>
          <Text size="LARGE">{shopData.address}</Text>
        </ShopDataVal>
      </ShopDataContainer>
      {ShopData.existsLatLng(shopData.coordinates) ? (
        <ShopDataMapContainer>
          <MapContainer
            latitude={parseFloat(shopData.coordinates.latitude)}
            longitude={parseFloat(shopData.coordinates.longitude)}
          />
        </ShopDataMapContainer>
      ) : undefined}
      <ShopDataContainer>
        <ShopDataKey>
          <Text color="SECONDARY">店舗説明</Text>
        </ShopDataKey>
        <ShopDataVal>
          <Text size="LARGE">{shopData.description}</Text>
        </ShopDataVal>
      </ShopDataContainer>
      <ShopDataContainer>
        <ShopDataKey>ジャンル</ShopDataKey>
        <ShopDataVal>
          <Text size="LARGE">{presentationShopCategoryText}</Text>
        </ShopDataVal>
      </ShopDataContainer>
      <ContactInfo />
    </Container>
  );
});
