import React, { useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { OrderItemData } from "records/OrderItemData";
import { ShopData } from "records/ShopData";
import { AutoPrintSetting } from "records/AutoPrintSetting";
import { OrderModel } from "modules/order/model";
import { DateUtils } from "utils/DateUtils";
import { PermissionData } from "utils/RbacUtils";
import { colorsConst } from "styles/const";
import { PermissionContainer, PermissionContext } from "components/organisms/PermissionContext";
import { ShopStaff } from "records/ShopStaff";
import { ShopModel } from "modules/shop/model";
import { shopStaffSelector } from "modules/shop/selectors";
import { OrderDetailContext } from "./OrderDetailContext";
import { OrderDetailButton } from "./OrderDetailButton";
import { CancelButton } from "./CancelButton";
import { OrderDetailButtonContainerText } from "./OrderDetailButtonContainerText";
import { AutoPrintNotice } from "./AutoPrintNotice";
import { HoldOrderButton } from "./OrderDetailButton/HoldOrderButton";

const Container = styled.div`
  padding: 1em 2em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

const TextContainer = styled.div`
  text-align: center;
  line-height: 1.2;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1em;
`;

const HoldOrderButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin-right: 1em;
`;

const MainButtonContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const CancelButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
`;

type Props = {};

export const OrderDetailButtonContainer: React.FC<Props> = React.memo(() => {
  const { id, role } = useContext(PermissionContext);
  const {
    isHistory,
    orderData,
    shopData,
    submitting,
    draftItems,
    draftTotalItemCounts,
    canDisplayPrintReceiptButton,
    handleClickApproveOrderButton,
    handleClickHoldOrderButton,
    handleClickHoldOrderForGroceryButton,
    handleClickFinishOrderButton,
    handleClickFindCrewForLocalAreaButton,
    handleClickApproveOrderForGroceryButton,
  } = useContext(OrderDetailContext);

  const shopStaff = useSelector(shopStaffSelector);
  const permissionData: PermissionData = useMemo(
    () =>
      ShopModel.isLazonaStaff(shopData, shopStaff)
        ? {
            staff_id: ShopStaff.getLazonaStaffParentCategoryId(shopStaff) as number,
            owner_id: ShopStaff.getLazonaStaffParentCategoryId(orderData.staff) as number,
          }
        : {
            staff_id: id,
            owner_id: orderData.staff.staff_id,
          },
    [orderData, shopStaff, shopData, id],
  );

  const presentationText = useMemo(
    () =>
      OrderModel.getPresentationOrderDetailButtonContainerText(
        orderData,
        ShopData.isRetail(shopData.shop_type),
        ShopData.isGrocery(shopData.shop_type),
      ),
    [orderData, shopData],
  );

  const isAllChecked = useMemo(
    () =>
      draftItems.every(i =>
        OrderItemData.existsSubstituteItem(i)
          ? /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            i.is_picked && (i.pick_count === i.order_count || i.substituteItem!.is_picked)
          : i.is_picked,
      ),
    [draftItems],
  );

  const canDisplayCancelButton = useMemo(
    () =>
      orderData.is_operable &&
      (OrderData.isUnapprovedOrder(orderData.order_state) || orderData.can_deny_after_approve_flag),
    [orderData],
  );

  const canDisplayCancelButtonForGrocery = useMemo(
    () => canDisplayCancelButton && isAllChecked && draftTotalItemCounts === 0,
    [canDisplayCancelButton, isAllChecked, draftTotalItemCounts],
  );

  const canDisplayHoldOrderButton = useMemo(
    () =>
      !ShopData.isRetail(shopData.shop_type) &&
      !ShopData.isGrocery(shopData.shop_type) &&
      OrderData.isPendingOrder(orderData.order_state),
    [orderData, shopData],
  );

  const canDisplayCookingStartTime = useMemo(
    () => OrderData.canDisplayCookingStartTime(orderData),
    [orderData],
  );

  const canDisplayAutoPrintNotice = useMemo(
    () =>
      !isHistory &&
      canDisplayPrintReceiptButton &&
      AutoPrintSetting.enable(shopData.auto_print_setting),
    [isHistory, canDisplayPrintReceiptButton, shopData],
  );

  return (
    <Container>
      <TextContainer>
        <OrderDetailButtonContainerText presentationText={presentationText} />
        {canDisplayAutoPrintNotice ? <AutoPrintNotice /> : undefined}
      </TextContainer>
      {canDisplayCookingStartTime ? (
        <TextContainer>{`（${DateUtils.dateToString(
          orderData.cooking_start_datetime,
          "M月D日 HH:mm",
        )}に準備開始の通知）`}</TextContainer>
      ) : undefined}
      <ButtonContainer>
        {canDisplayHoldOrderButton ? (
          <HoldOrderButtonContainer>
            <HoldOrderButton sub submitting={submitting} handleClick={handleClickHoldOrderButton} />
          </HoldOrderButtonContainer>
        ) : undefined}
        <MainButtonContainer>
          <OrderDetailButton
            orderData={orderData}
            shopData={shopData}
            submitting={submitting}
            isAllChecked={isAllChecked}
            draftTotalItemCounts={draftTotalItemCounts}
            handleClickApproveOrderButton={handleClickApproveOrderButton}
            handleClickHoldOrderButton={handleClickHoldOrderButton}
            handleClickHoldOrderForGroceryButton={handleClickHoldOrderForGroceryButton}
            handleClickFinishOrderButton={handleClickFinishOrderButton}
            handleClickFindCrewForLocalAreaButton={handleClickFindCrewForLocalAreaButton}
            handleClickApproveOrderForGroceryButton={handleClickApproveOrderForGroceryButton}
          />
        </MainButtonContainer>
      </ButtonContainer>
      {ShopData.isGrocery(shopData.shop_type) ? (
        canDisplayCancelButtonForGrocery ? (
          <PermissionContainer role={role} performance="own" data={permissionData}>
            <CancelButtonContainer>
              <CancelButton />
            </CancelButtonContainer>
          </PermissionContainer>
        ) : undefined
      ) : canDisplayCancelButton ? (
        <CancelButtonContainer>
          <CancelButton />
        </CancelButtonContainer>
      ) : undefined}
    </Container>
  );
});
