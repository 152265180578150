import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { API_KEY } from "apis";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { userAccessedToPageThatNeedsMonthlySales } from "modules/sales/actions";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Contents } from "components/atoms/Contents";
import { Article } from "components/organisms/Article";
import { MonthlySalesDetail } from "components/organisms/MonthlySalesDetail";

const Container = styled.div``;

const isConnectedApiSelector = isConnectedApiSelectorFactory([API_KEY.GET_MONTHLY_SALES]);

type Props = {};

export const SalesMonthlyTemplate: React.FC<Props> = React.memo(() => {
  const loading = useSelector(isConnectedApiSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsMonthlySales());
  }, [dispatch]);

  return (
    <Article withHeader title="売上管理" goBack="/sales" withNavigation activePath="SETTING">
      {loading ? (
        <LoadingContainer />
      ) : (
        <Contents innerStyle={{ padding: 0 }}>
          <MonthlySalesDetail />
        </Contents>
      )}
    </Article>
  );
});
