import React, { useCallback } from "react";
import styled from "styled-components";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import { IconFindingCrew } from "components/atoms/Icons/IconFindingCrew";
import { ModalDialog, ModalBody, ModalFooter } from "components/molecules/ModalDialog";

const Title = styled.div`
  margin-bottom: 1em;
  text-align: center;
`;

const Caption = styled.div`
  text-align: center;
`;

const IconContainer = styled.div`
  text-align: center;
  svg {
    width: 100%;
    max-width: 174px;
    height: auto;
  }
`;

const MODAL_KEY = "FINDING_CREW";
const useModal = modalHooksFactory(MODAL_KEY);

type Props = {
  handleCloseFindingCrewDialog?: () => void;
};

export const FindingCrewDialog: React.FC<Props> = React.memo(({ handleCloseFindingCrewDialog }) => {
  const { data, close } = useModal();

  const handleClose = useCallback(() => {
    if (typeof handleCloseFindingCrewDialog !== "undefined") {
      handleCloseFindingCrewDialog();
    } else {
      close();
    }
  }, [handleCloseFindingCrewDialog, close]);

  return (
    <ModalDialog isOpen={data.isOpen} close={close} handleClickBackdrop={handleClose}>
      <ModalBody>
        <Title>
          <Text size="LARGE" color="DANGER">
            まだ準備はお待ちください
          </Text>
        </Title>
        <IconContainer>
          <IconFindingCrew />
        </IconContainer>
        <Caption>
          <Text>
            ただいま配達員を探しています。
            <br />
            配達員が見つかり次第、お知らせいたします。
          </Text>
        </Caption>
      </ModalBody>
      <ModalFooter>
        <Button appearance="primary" handleClick={handleClose}>
          OK
        </Button>
      </ModalFooter>
    </ModalDialog>
  );
});
