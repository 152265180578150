export const PLATFORM = {
  IOS: "iOS",
  ANDROID: "Android",
  OTHER: "Other",
} as const;

export type TPlatform = ValueOf<typeof PLATFORM>;

const getPlatform = (): TPlatform => {
  if (!("navigator" in window) || !("userAgent" in window.navigator)) {
    return PLATFORM.OTHER;
  }
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf("ipad") !== -1) {
    return PLATFORM.IOS;
  }
  if (userAgent.indexOf("android") !== -1) {
    return PLATFORM.ANDROID;
  }
  return PLATFORM.OTHER;
};

export const DeviceUtils = Object.freeze({
  getPlatform,
});
