import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconShop: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 34 32" width="1em" height="1em" {...props}>
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="2.5"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M5.4,15.2c-2.1,0-3.3-1.9-2.8-4l1.8-6.7h6.3l-0.9,6.7C9.5,13.4,7.5,15.2,5.4,15.2z"
      />
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="2.5"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M13.1,15.2c-2.1,0-3.6-1.9-3.3-4l0.9-6.7h6.3l0,6.7C16.9,13.4,15.2,15.2,13.1,15.2z"
      />
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="2.5"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M20.8,15.2c-2.1,0-3.8-1.9-3.8-4l0-6.7h6.3l0.9,6.7C24.4,13.4,22.9,15.2,20.8,15.2z"
      />
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="2.5"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M28.5,15.2c-2.1,0-4.1-1.9-4.4-4l-0.9-6.7h6.3l1.8,6.7C31.9,13.4,30.6,15.2,28.5,15.2z"
      />
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="2.5"
        d="M5.1,14.8v11.1c0,1.1,0.9,2,2,2h19.7c1.1,0,2-0.9,2-2V14.8"
      />
      <rect
        x="9.5"
        y="18.5"
        fill="none"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinejoin="round"
        width="5"
        height="9"
      />
      <rect
        x="18.5"
        y="18.5"
        fill="none"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinejoin="round"
        width="6"
        height="5"
      />
    </svg>
  );
});
