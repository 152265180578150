import { put } from "redux-saga/effects";
import { OrderNotificationStack } from "records/OrderNotificationStack";
import { updateOrderNotificationStack, UserDebugUpdateNotificationStackAction } from "../actions";

export function* userDebugUpdateNotificationStackSaga(
  action: UserDebugUpdateNotificationStackAction,
) {
  const { notification } = action.payload;
  const orderNotificationStack = OrderNotificationStack.create({
    notifications: [notification],
    isDebug: true,
  });
  yield put(updateOrderNotificationStack(orderNotificationStack));
}
