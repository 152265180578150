import { put, select } from "redux-saga/effects";
import { OrderData, ORDER_STATE_TYPE } from "records/OrderData";
import { shopStaffSelector } from "modules/shop/selectors";
import {
  updateSimulatorOrderData,
  updateSimulatorOrderList,
  UserTouchedSimulatorHoldOrderForGroceryButtonAction,
} from "../actions";

export function* userTouchedSimulatorHoldOrderForGroceryButtonSaga(
  action: UserTouchedSimulatorHoldOrderForGroceryButtonAction,
) {
  const { orderData } = action.payload;
  const shopStaff: ReturnType<typeof shopStaffSelector> = yield select(shopStaffSelector);
  const updatedOrderData = OrderData.create({
    ...orderData,
    order_state: ORDER_STATE_TYPE.HOLD,
    staff: shopStaff,
  });
  yield put(updateSimulatorOrderData(updatedOrderData));
  yield put(updateSimulatorOrderList([updatedOrderData]));
}
