import { put, fork, call } from "redux-saga/effects";
import { API_KEY, StopOrderByItemRequest, StopOrderByItemResponse } from "apis";
import { updateSnackBar } from "modules/app/actions";
import { commonApiSaga, commonErrorSaga } from "modules/app/sagas";
import { systemUpdateShopItemStatus, UserSubmittedStopOrderByItemsAction } from "../actions";
import { SHOP_ITEM_SUBMIT_TYPE } from "../model";

export function* userSubmittedStopOrderByItemsSaga(action: UserSubmittedStopOrderByItemsAction) {
  try {
    const { shopItemId } = action.payload;
    const params: StopOrderByItemRequest = { shop_item_ids: [shopItemId], use_stock_check: false };
    const {
      result,
      error,
    }: {
      result: StopOrderByItemResponse;
      error: unknown;
    } = yield call(commonApiSaga, API_KEY.STOP_ORDER_BY_ITEM, params);
    if (result && !error) {
      yield put(systemUpdateShopItemStatus([shopItemId], SHOP_ITEM_SUBMIT_TYPE.STOP));
      yield put(updateSnackBar("商品の受付を停止しました"));
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
