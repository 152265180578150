import { call } from "redux-saga/effects";
import { API_KEY, GetOrderNotificationsByStaffResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";

export function* getOrderNotificationsByStaffSaga() {
  try {
    const { result, error }: { result: GetOrderNotificationsByStaffResponse; error: unknown } =
      yield call(commonApiSaga, API_KEY.GET_ORDER_NOTIFICATIONS_BY_STAFF, {});
    if (result && !error) {
      return { result };
    }
    throw error;
  } catch (error) {
    return { error };
  }
}
