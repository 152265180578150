import React from "react";
import styled from "styled-components";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import { IconLocalPayment } from "components/atoms/Icons/IconLocalPayment";
import { ModalDialog, ModalBody, ModalFooter } from "components/molecules/ModalDialog";

const Title = styled.div`
  margin-bottom: 1em;
  text-align: center;
`;

const Caption = styled.div`
  text-align: center;
`;

const IconContainer = styled.div`
  margin-bottom: 1em;
  text-align: center;
  svg {
    width: 100%;
    max-width: 216px;
    height: auto;
  }
`;

const MODAL_KEY = "LOCAL_PAYMENT_NOTICE";
const useModal = modalHooksFactory(MODAL_KEY);

type Props = {};

export const LocalPaymentNoticeDialog: React.FC<Props> = React.memo(() => {
  const { data, close } = useModal();

  return (
    <ModalDialog isOpen={data.isOpen} close={close} handleClickBackdrop={close}>
      <ModalBody>
        <Title>
          <Text size="LARGE" color="DANGER">
            まだ未決済です！
          </Text>
        </Title>
        <IconContainer>
          <IconLocalPayment />
        </IconContainer>
        <Caption>
          <Text>店頭で代金を受け取ってください！</Text>
        </Caption>
      </ModalBody>
      <ModalFooter>
        <Button appearance="primary" handleClick={close}>
          OK
        </Button>
      </ModalFooter>
    </ModalDialog>
  );
});
