import React, { useMemo, useCallback, useEffect } from "react";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";

const Container = styled.button.attrs({ type: "button" })<{ active: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  display: block;
  width: 50%;
  padding: 0.25em;
  appearance: none;
  ${fontSize.DEFAULT};
  font-weight: bold;
  background-color: #fff;
  border: 1px solid ${colorsConst.BORDER.THICK};
  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  active: boolean;
  activeStyle?: React.CSSProperties;
  disabled?: boolean;
  handleClick: () => void;
};

export const TabButton: React.FC<Props> = React.memo(
  ({ children, style, active, activeStyle, disabled, handleClick }) => {
    const buttonStyle: React.CSSProperties = useMemo(
      () =>
        !active
          ? {
              ...style,
            }
          : typeof activeStyle !== "undefined"
          ? {
              ...style,
              ...activeStyle,
            }
          : {
              ...style,
              color: colorsConst.TEXT.WHITE,
              backgroundColor: colorsConst.TAB.ACTIVE,
            },
      [style, active, activeStyle],
    );

    const handleClickWrap = useCallback(() => {
      if (!disabled) {
        handleClick();
      }
    }, [disabled, handleClick]);

    return (
      <Container style={buttonStyle} active={active} onClick={handleClickWrap}>
        {children}
      </Container>
    );
  },
);
