import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {};

export const IconCancelOrderForLocalArea: React.FC<Props> = React.memo(({ ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 194 194" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={colorsConst.ICON.WHITE}
      d="M103.6,59.7c3-2.7,3.3-7.3,0.6-10.3c-2.7-3-7.2-3.3-10.2-0.6c-3,2.7-3.3,7.3-0.6,10.3 C96,62.1,100.6,62.4,103.6,59.7z M73.2,84c-2.1,3.8-0.7,8.6,3,10.7c0.3,0.2,0.6,0.4,0.9,0.5l15.4,6.7l-1.4,18.5 c-0.1,1.9,1.2,3.6,3.1,3.9c2,0.3,3.9-1.1,4.2-3.1l3.1-20.6c0-0.1,0.1-0.1,0.1-0.2c1.1-2.5-0.1-5.4-2.5-6.4l-10.7-4.6l6.8-12.6 l4.2,4.7c0.6,0.8,1.6,1.3,2.6,1.4l11.9,1.1c2,0.1,3.8-1.4,3.9-3.4c0.1-2-1.4-3.8-3.4-3.9l-9.1-1.3l-9.2-8.5 c-0.2-0.3-0.4-0.5-0.7-0.7c-0.6-0.8-1.4-1.5-2.4-2c-3.9-2.1-8.7-0.7-10.8,3.2L73.2,84z M113.6,91.6c-0.2-0.4-0.3-0.8-0.3-1.2 c0-1.3,0.9-2.4,2.2-2.6c0.2,0,0.4-0.1,0.6-0.1h6.9c1.2,0,2.2-1,2.2-2.2V85c0-0.9-0.7-1.6-1.6-1.6c-1.5,0-2.7-1.2-2.7-2.7 c0-1.5,1.2-2.7,2.7-2.7c3.9,0,7,3.1,7,7v0.5c0,4.2-3.4,7.6-7.6,7.6h-2.9l2.1,4.8c1.8-0.5,3.8-0.8,5.7-0.8c11.8,0,21.4,9.6,21.4,21.5 c0,11.9-9.6,21.5-21.4,21.5c-11.8,0-21.4-9.6-21.4-21.5c0-7.9,4.3-14.9,10.7-18.6L113.6,91.6z M111.5,118.6c0-6,3.1-11.2,7.8-14.1 l7.2,16.1c0.6,1.4,2.2,2,3.6,1.4c1.4-0.6,2-2.2,1.4-3.6l-7.1-16c1.2-0.3,2.4-0.4,3.7-0.4c9.1,0,16.5,7.4,16.5,16.6 c0,9.2-7.4,16.6-16.5,16.6S111.5,127.7,111.5,118.6z M75,96.5c1.4,0.6,2,2.2,1.4,3.5l-0.4,1c6.3,3.7,10.6,10.7,10.6,18.5 c0,11.9-9.6,21.5-21.4,21.5c-11.8,0-21.4-9.6-21.4-21.5c0-11.9,9.6-21.5,21.4-21.5c2,0,4,0.3,5.9,0.8l0.4-0.9 C72.1,96.6,73.6,95.9,75,96.5z M62.8,118.5l6.3-15c-1.3-0.3-2.6-0.5-4-0.5c-9.1,0-16.5,7.4-16.5,16.6c0,9.2,7.4,16.6,16.5,16.6 c9.1,0,16.5-7.4,16.5-16.6c0-5.9-3-11-7.6-14l-6.3,14.9c-0.6,1.4-2.2,2-3.5,1.4C62.9,121.4,62.3,119.8,62.8,118.5z"
    />
    <circle fill="none" stroke={colorsConst.ICON.DANGER} strokeWidth="14" cx="97" cy="97" r="80" />
    <path
      fill="none"
      stroke={colorsConst.ICON.DANGER}
      strokeWidth="14"
      d="M41.3,40.1l111.4,111.4"
    />
  </svg>
));
