import React from "react";
import styled from "styled-components";
import { colorsConst, fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { IconTel } from "components/atoms/Icons/IconTel";
import { IconMail } from "components/atoms/Icons/IconMail";
import { Text } from "components/atoms/Text";
import { textLinkStyle } from "components/atoms/TextLink";

const Container = styled.div`
  text-align: center;
`;

const Description = styled.div`
  margin-top: 2em;
`;

const ButtonContainer = styled.div`
  margin-bottom: 1em;
`;

const Title = styled.div`
  margin-top: 1em;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${fontSize.SMALL};
`;

const Metohd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & + & {
    margin-left: 0.5em;
  }
`;

const IconContainer = styled.div`
  margin-top: 0.125em;
  ${fontSize.LARGE};
`;

const Value = styled.div`
  margin-left: 0.25em;
`;

const Button = styled.a`
  ${textLinkStyle};
`;

export const ContactInfo: React.FC = React.memo(() => (
  <Container>
    <Description>
      <div>店舗情報の変更をしたい場合はこちら</div>
      <ButtonContainer>
        <Button href={`${process.env.REACT_APP_STORE_MANAGER_DOMAIN}/chain/login/index`}>
          ストアマネージャーに移動する
        </Button>
      </ButtonContainer>
      <div>不明な点がありましたら、下記にご連絡ください</div>
    </Description>
    <Title>
      <Text weight="BOLD">menu運営サポート</Text>
    </Title>
    <Row>
      {/*
      <Metohd>
        <IconContainer>
          <IconTel color={colorsConst.ICON.ACTIVE} />
        </IconContainer>
        <Value>0120-945-179</Value>
      </Metohd>
      */}
      <Metohd>
        <IconContainer>
          <IconMail color={colorsConst.ICON.ACTIVE} />
        </IconContainer>
        <Value>sup@menu.inc</Value>
      </Metohd>
    </Row>
  </Container>
));
