import React, { useCallback } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { fontSize } from "styles/mixins";

const Container = styled.button`
  padding: 0.25em;
  ${fontSize.XXLARGE};
  line-height: 1;
  appearance: none;
  border: none;
  background: none;
`;

type Props = {
  handleClick: () => void;
};

export const BackButton: React.FC<Props> = React.memo(({ handleClick }) => (
  <Container onClick={handleClick}>
    <FontAwesomeIcon icon={faArrowLeft} />
  </Container>
));
