import React, { useState, useMemo, useCallback, useEffect } from "react";
import { batch, useSelector, useDispatch } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Transition } from "react-transition-group";
import styled from "styled-components";
import { ShopData } from "records/ShopData";
import {
  OrderData,
  OrderReceiveType,
  OrderTimeType,
  ORDER_STATE_TYPE,
  ORDER_TIME_TYPE,
} from "records/OrderData";
import { DraftItem } from "records/OrderItemData";
import { ShopItemParentCategory } from "records/ShopItemParentCategory";
import { systemOpenedModal } from "modules/app/actions";
import { printerSelector } from "modules/app/selectors";
import { OrderModel, OrderListTabType, ORDER_LIST_TAB_TYPE } from "modules/order/model";
import {
  userAccessedToPageThatNeedsShopData,
  userAccessedToPageThatNeedsShopItemParentCategories,
} from "modules/shop/actions";
import { ShopModel } from "modules/shop/model";
import {
  shopDataSelector,
  shopItemParentCategoriesSelector,
  shopStaffSelector,
} from "modules/shop/selectors";
import { transitionConst } from "styles/const";
import {
  systemMountSimulator,
  systemUnmountSimulator,
  systemGenerateSimulatorOrderData,
  userTouchedChangeSimulatorPage,
  userTouchedSimulatorApproveOrderButton,
  userTouchedSimulatorHoldOrderButton,
  userTouchedSimulatorApproveOrderWithUpdateCookingTimeButton,
  userTouchedSimulatorFindCrewForLocalAreaButton,
  userTouchedSimulatorFinishOrderButton,
  userTouchedSimulatorCloseFindingCrewDialogButton,
  userTouchedSimulatorCancelOrderButton,
  userTouchedSimulatorHoldOrderForGroceryButton,
  userTouchedSimulatorApproveOrderForGroceryButton,
} from "modules/simulator/actions";
import { SIMULATOR_SHOP_ITEM_PARENT_CATEGORIES } from "modules/simulator/model";
import {
  orderDataSelector,
  orderListSelector,
  pageTransitionSelector,
} from "modules/simulator/selectors";
import { Contents } from "components/atoms/Contents";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Article } from "components/organisms/Article";
import { OrderListContainer } from "components/organisms/OrderListContainer";
import { OrderDetail } from "components/organisms/OrderDetail";
import { OrderDetailModal } from "components/organisms/OrderDetail/OrderDetailModal";
import { OrderDetailButtonContainer } from "components/organisms/OrderDetail/OrderDetailButtonContainer";
import {
  OrderDetailContext,
  OrderDetailContextValue,
} from "components/organisms/OrderDetail/OrderDetailContext";
import { OrderListContext, OrderListContextValue } from "components/organisms/OrderListContext";
import { SimulatorHeader } from "components/organisms/SimulatorHeader";

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
`;

const TransitionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  will-change: opacity;
  transition-property: opacity;
  transition-duration: ${(transitionConst.DURATION / 1000) * 0.8}s;
  transition-timing-function: linear;
`;

const ButtonContainer = styled.div`
  position: relative;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2);
`;

const TRANSITION_STYLE = {
  entering: {
    opacity: 0,
    zIndex: 2,
  },
  entered: {
    opacity: 1,
    zIndex: 2,
  },
  exiting: {
    opacity: 1,
    zIndex: 1,
  },
  exited: {
    opacity: 0,
    zIndex: 1,
  },
} as const;

type Props = RouteComponentProps<{ receiveType: string; timeType: string }> & {};

const SimulatorTemplate: React.FC<Props> = React.memo(
  ({
    match: {
      params: { receiveType, timeType },
    },
  }) => {
    const { isDiscoveredPrinter } = useSelector(printerSelector);

    const shopData = useSelector(shopDataSelector);

    const orderData = useSelector(orderDataSelector);
    const orderList = useSelector(orderListSelector);

    const shopItemParentCategories = useSelector(shopItemParentCategoriesSelector);

    const pageTransition = useSelector(pageTransitionSelector);

    const shopStaff = useSelector(shopStaffSelector);

    const [addMinutes, setAddMinutes] = useState(0);
    const [draftItems, setDraftItems] = useState<DraftItem[]>([]);

    const [tabType, setTabType] = useState<OrderListTabType>(ORDER_LIST_TAB_TYPE.PENDING);

    const isRetail = useMemo(() => ShopData.isRetail(shopData.shop_type), [shopData]);

    const isGrocery = useMemo(() => ShopData.isGrocery(shopData.shop_type), [shopData]);

    const orderReceiveType = useMemo(
      () => parseInt(receiveType, 10) as OrderReceiveType,
      [receiveType],
    );

    const orderTimeType = useMemo(() => parseInt(timeType, 10) as OrderTimeType, [timeType]);

    const existsOrder = useMemo(() => OrderData.existsOrder(orderData), [orderData]);

    const canGoBack = useMemo(() => !OrderData.isPendingOrder(orderData.order_state), [orderData]);

    const canChangeItemCount = useMemo(
      () => OrderData.existsOrder(orderData) && OrderModel.canChangeItemCount(orderData, shopData),
      [orderData, shopData],
    );

    const draftTotalItemCounts = useMemo(
      () => OrderModel.getDraftTotalItemsCount(draftItems, orderData),
      [draftItems, orderData],
    );

    const canDisplayUpdateOrderStatusButton = useMemo(
      () =>
        OrderData.canDisplayUpdateOrderStatusButton(orderData.order_state) &&
        !(
          ShopModel.isLazonaStaff(shopData, shopStaff) &&
          OrderData.isPendingOrder(orderData.order_state)
        ),
      [orderData, shopData, shopStaff],
    );

    // 保留状態かどうか
    const canDisplayPrintReceiptButton = useMemo(
      () => isDiscoveredPrinter && OrderModel.canDisplayPrintReceiptButton(orderData),
      [orderData, isDiscoveredPrinter],
    );

    const simulatorShopItemParentCategories = useMemo(() => {
      if (!ShopItemParentCategory.exists(shopItemParentCategories)) {
        return [];
      }
      return SIMULATOR_SHOP_ITEM_PARENT_CATEGORIES;
    }, [shopItemParentCategories]);

    const dispatch = useDispatch();

    const goBack = useCallback(() => {
      dispatch(userTouchedChangeSimulatorPage(false));
    }, [dispatch]);

    const handleClickApproveOrderButton = useCallback(() => {
      if (!orderData.cooking_start_able_flag && orderData.time_type === ORDER_TIME_TYPE.SPECIFIED) {
        dispatch(
          systemOpenedModal("UPDATE_COOKING_START_TIME", {
            orderId: orderData.order_no,
            receiveDatetime: orderData.receive_datetime,
          }),
        );
      } else {
        setTabType(ORDER_LIST_TAB_TYPE.ON_PREPARING);
        dispatch(userTouchedSimulatorApproveOrderButton(orderData, draftItems));
      }
    }, [dispatch, orderData, draftItems]);

    const handleClickApproveOrderWithUpdateCookingStartTimeButton = useCallback(
      (remindMinutes: number) => {
        dispatch(
          userTouchedSimulatorApproveOrderWithUpdateCookingTimeButton(
            orderData,
            remindMinutes,
            draftItems,
            setTabType,
          ),
        );
      },
      [dispatch, orderData, draftItems],
    );

    const handleClickHoldOrderButton = useCallback(() => {
      if (orderData.order_state === ORDER_STATE_TYPE.HOLD) {
        dispatch(
          systemOpenedModal("HOLD_ORDER", {
            userInfoTel: orderData.user_info.tel,
            isRetail,
          }),
        );
      } else {
        dispatch(userTouchedSimulatorHoldOrderButton(orderData, isRetail));
      }
    }, [dispatch, orderData, isRetail]);

    const handleClickFinishOrderButton = useCallback(() => {
      dispatch(userTouchedSimulatorFinishOrderButton(orderData));
    }, [dispatch, orderData]);

    const handleClickFindCrewForLocalAreaButton = useCallback(() => {
      dispatch(userTouchedSimulatorFindCrewForLocalAreaButton(orderData, draftItems));
    }, [dispatch, orderData, draftItems]);

    const handleClilckCancelOrderButton = useCallback(() => {
      dispatch(userTouchedSimulatorCancelOrderButton(orderData));
    }, [dispatch, orderData]);

    const handleCloseFindingCrewDialog = useCallback(() => {
      dispatch(userTouchedSimulatorCloseFindingCrewDialogButton(orderData));
    }, [dispatch, orderData]);

    const handleClickHoldOrderForGroceryButton = useCallback(() => {
      dispatch(userTouchedSimulatorHoldOrderForGroceryButton(orderData));
    }, [dispatch, orderData]);

    const handleClickApproveOrderForGroceryButton = useCallback(() => {
      dispatch(userTouchedSimulatorApproveOrderForGroceryButton(orderData, draftItems));
    }, [dispatch, orderData, draftItems]);

    const handleChangePickStatus = useCallback(
      (draftItem: DraftItem, checked: boolean) => {
        const updatedDraftItemCounts = draftItems.map(i => {
          if (i.order_shop_item_id_group === draftItem.order_shop_item_id_group) {
            return {
              ...i,
              is_picked: checked,
            };
          }
          if (i.substituteItem?.order_shop_item_id_group === draftItem.order_shop_item_id_group) {
            return {
              ...i,
              substituteItem: {
                ...i.substituteItem,
                is_picked: checked,
              },
            };
          }
          return i;
        });
        setDraftItems(updatedDraftItemCounts);
      },
      [draftItems],
    );

    useEffect(() => {
      if (ShopData.existsShopData(shopData.id)) {
        dispatch(systemGenerateSimulatorOrderData(shopData, orderReceiveType, orderTimeType));
      }
    }, [dispatch, shopData, orderReceiveType, orderTimeType]);

    useEffect(() => {
      if (existsOrder) {
        setDraftItems(
          orderData.item_list.map(i => {
            const draftCount = OrderModel.getOrderItemDraftCount(i, orderData, isGrocery);
            const substituteItem = OrderModel.getSubstituteItem(i, orderData);
            return {
              ...i,
              order_count: draftCount,
              total_order_count: i.free_order_count + draftCount,
              substituteItem,
            };
          }),
        );
      }
    }, [existsOrder, orderData, isGrocery]);

    useEffect(() => {
      batch(() => {
        dispatch(systemMountSimulator());
        dispatch(userAccessedToPageThatNeedsShopData());
        dispatch(userAccessedToPageThatNeedsShopItemParentCategories());
      });
      return () => {
        dispatch(systemUnmountSimulator());
      };
    }, [dispatch]);

    const orderListContextValue = useMemo<OrderListContextValue>(
      () => ({
        shopData,
        isSimulator: true,
      }),
      [shopData],
    );

    const orderDetailContextValue = useMemo<OrderDetailContextValue>(
      () => ({
        isSimulator: true,
        orderData,
        shopData,
        addMinutes,
        isRetail,
        isGrocery,
        isLocalArea: orderData.local_area_flag,
        setAddMinutes,
        canChangeItemCount,
        draftItems,
        setDraftItems,
        draftTotalItemCounts,
        canDisplayPrintReceiptButton,
        shopItemParentCategories: simulatorShopItemParentCategories,
        handleClickApproveOrderButton,
        handleClickApproveOrderWithUpdateCookingStartTimeButton,
        handleClickHoldOrderButton,
        handleClickFinishOrderButton,
        handleClickFindCrewForLocalAreaButton,
        handleClilckCancelOrderButton,
        handleCloseFindingCrewDialog,
        handleClickHoldOrderForGroceryButton,
        handleClickApproveOrderForGroceryButton,
        handleChangePickStatus,
      }),
      [
        orderData,
        shopData,
        addMinutes,
        isRetail,
        isGrocery,
        setAddMinutes,
        canChangeItemCount,
        draftItems,
        setDraftItems,
        draftTotalItemCounts,
        canDisplayPrintReceiptButton,
        simulatorShopItemParentCategories,
        handleClickApproveOrderButton,
        handleClickApproveOrderWithUpdateCookingStartTimeButton,
        handleClickHoldOrderButton,
        handleClickFinishOrderButton,
        handleClickFindCrewForLocalAreaButton,
        handleClilckCancelOrderButton,
        handleCloseFindingCrewDialog,
        handleClickHoldOrderForGroceryButton,
        handleClickApproveOrderForGroceryButton,
        handleChangePickStatus,
      ],
    );

    return (
      <Article
        withHeader
        withNavigation={!pageTransition}
        headerComponent={
          !pageTransition ? (
            <SimulatorHeader orderList={orderList} shopData={shopData} orderListTabType={tabType} />
          ) : undefined
        }
        title={pageTransition ? "注文詳細" : undefined}
        goBack={pageTransition ? undefined : "/setting"}
        handleGoBack={pageTransition && canGoBack ? goBack : undefined}
        activePath="SETTING"
        watch={false}
      >
        <Container>
          <Transition in={!pageTransition} timeout={250}>
            {state => (
              <TransitionContainer style={TRANSITION_STYLE[state]}>
                <OrderListContext.Provider value={orderListContextValue}>
                  <OrderListContainer
                    orderList={orderList}
                    shopData={shopData}
                    orderListTabType={tabType}
                    isSimulator={true}
                  />
                </OrderListContext.Provider>
              </TransitionContainer>
            )}
          </Transition>
          <Transition in={pageTransition} timeout={250}>
            {state => (
              <TransitionContainer style={TRANSITION_STYLE[state]}>
                {existsOrder ? (
                  <OrderDetailContext.Provider value={orderDetailContextValue}>
                    <Contents containerStyle={{ position: "relative", zIndex: 1 }}>
                      <OrderDetail />
                    </Contents>
                    {canDisplayUpdateOrderStatusButton ? (
                      <ButtonContainer>
                        <OrderDetailButtonContainer />
                      </ButtonContainer>
                    ) : undefined}
                    <OrderDetailModal />
                  </OrderDetailContext.Provider>
                ) : (
                  <LoadingContainer />
                )}
              </TransitionContainer>
            )}
          </Transition>
        </Container>
      </Article>
    );
  },
);

export default withRouter(SimulatorTemplate);
