/* eslint-disable no-alert */
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import route from "route";
import reportWebVitals from "reportWebVitals";
import { store } from "store";
import { AppModel } from "modules/app/model";
import { SentryUtil } from "utils/SentryUtil";
import { CordovaUtil, PlatformId } from "utils/CordovaUtil";
import { InsomniaUtil } from "utils/InsomniaUtil";
import { UrlSearchParams } from "utils/UrlSearchParams";

(async () => {
  if (!("ResizeObserver" in window)) {
    // Loads polyfill asynchronously, only if required.
    const module = await import("@juggle/resize-observer");
    window.ResizeObserver = module.ResizeObserver;
  }
})();

SentryUtil.init();

const startApp = () => {
  if (CordovaUtil.isCordovaEnabled()) {
    InsomniaUtil.keepAwake();
  }
  // アップデートフラグが立っていたらブラウザをハードリロード
  AppModel.hardReloadIfShouldUpdateResource();
  const container = document.getElementById("root");
  if (!container) {
    throw new Error("Failed to find the root element");
  }
  const root = ReactDOM.createRoot(container);
  root.render(<Provider store={store}>{route}</Provider>);
  // unregister();
};

const initApp = () => {
  if (CordovaUtil.isCordovaEnabled()) {
    CordovaUtil.initialize(() => {
      startApp();
    });
  } else {
    startApp();
  }
};

const loadScript = (callback: (script: HTMLScriptElement) => void) => {
  const platform = UrlSearchParams.exists("platform") ? UrlSearchParams.get("platform") : "browser";
  const appVersion = UrlSearchParams.get("appVersion");
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `${process.env.PUBLIC_URL}${AppModel.getCordovaFolder(
    platform as PlatformId,
    appVersion,
  )}`;
  script.onload = () => callback(script);
  script.onerror = () =>
    alert("アプリの起動に失敗しました。\n一度アプリを閉じて再度起動してください。");
  document.body.appendChild(script);
};

loadScript(initApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
