import { fork, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { API_KEY, DenyOrderRequest, DenyOrderResponse } from "apis";
import { OrderData } from "records/OrderData";
import { systemClosedModal, updateSnackBar } from "modules/app/actions";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateOrderDetail, UserSubmittedDenyOrderAction } from "../actions";

export function* userSubmittedDenyOrderByRequiredItemShortageSaga(
  action: UserSubmittedDenyOrderAction,
) {
  try {
    const { orderData } = action.payload;
    const params: DenyOrderRequest = {
      order_no: orderData.order_no,
    };
    const { result, error }: { result: DenyOrderResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.DENY_ORDER,
      params,
    );

    if (result && !error) {
      yield all([
        put(systemClosedModal("CANCEL_ORDER_BY_REQUIRED_ITEM_SHORTAGE")),
        put(updateOrderDetail(OrderData.create())),
        put(replace("/orderList", { from: { pathname: `/orderDetail/${orderData.order_no}` } })),
        put(updateSnackBar("注文をキャンセルしました")),
      ]);
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
