import React, { useMemo, useState, useCallback, useEffect, useContext } from "react";
import { batch, useSelector, useDispatch } from "react-redux";
import { replace } from "connected-react-router";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { API_KEY, StopReasonShopItems } from "apis";
import { OrderData, ORDER_STATE_TYPE, ORDER_TIME_TYPE } from "records/OrderData";
import { OrderItemData, DraftItem } from "records/OrderItemData";
import { ShopData } from "records/ShopData";
import { Printer } from "records/Printer";
import { AutoPrintSetting } from "records/AutoPrintSetting";
import { ReasonForItemsStop, REASON_FOR_ITEMS_STOP } from "records/ShopItem";
import { systemOpenedModal, systemClosedModal, systemAutoPrintReceipt } from "modules/app/actions";
import { printerSelector, isConnectedApiSelectorFactory } from "modules/app/selectors";
import {
  userAccessedToPageThatNeedsOrderDetail,
  userSubmittedHoldOrder,
  userSubmittedApproveOrder,
  userSubmittedFinishOrder,
  userSubmittedFinishOrderForLocalAreaAndAutoApprove,
  userSubmittedDenyOrder,
  userSubmittedDenyOrderByRequiredItemShortage,
  userSubmittedDenyOrderAfterApprove,
  userTouchedHoldOrderForGroceryButton,
  userSubmittedApproveOrderForGrocery,
  userChangedPickStatus,
  systemInitOrderData,
} from "modules/order/actions";
import { OrderModel } from "modules/order/model";
import { orderDataSelector, busyFlagSelector } from "modules/order/selectors";
import {
  userAccessedToPageThatNeedsShopData,
  userAccessedToPageThatNeedsShopItemParentCategories,
} from "modules/shop/actions";
import { ShopModel } from "modules/shop/model";
import {
  shopDataSelector,
  shopItemParentCategoriesSelector,
  shopStaffSelector,
} from "modules/shop/selectors";
import { Contents } from "components/atoms/Contents";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Article } from "components/organisms/Article";
import { OrderDetail } from "components/organisms/OrderDetail";
import { OrderDetailModal } from "components/organisms/OrderDetail/OrderDetailModal";
import { OrderDetailButtonContainer } from "components/organisms/OrderDetail/OrderDetailButtonContainer";
import {
  OrderDetailContext,
  OrderDetailContextValue,
} from "components/organisms/OrderDetail/OrderDetailContext";
import { PermissionContext } from "components/organisms/PermissionContext";

const ButtonContainer = styled.div`
  position: relative;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2);
`;

const submittingUpdateOrderSelector = isConnectedApiSelectorFactory([
  API_KEY.DENY_ORDER,
  API_KEY.APPROVE_ORDER,
  API_KEY.HOLD_ORDER,
  API_KEY.FINISH_ORDER_FOR_LUXURY,
  API_KEY.FINISH_ORDER,
  API_KEY.CONFIRM_ARRIVAL_FOR_LUXURY,
  API_KEY.PAY_ORDER,
  API_KEY.UPDATE_ORDER_ITEMS_COUNT,
]);

type Props = RouteComponentProps<{ orderId: string }> & {};

const OrderDetailTemplate: React.FC<Props> = React.memo(
  ({
    match: {
      params: { orderId },
    },
  }) => {
    const { id } = useContext(PermissionContext);
    const [reasonForItemsStop, setReasonForItemsStop] = useState<StopReasonShopItems[]>([]);

    const orderData = useSelector(orderDataSelector);

    const isBusy = useSelector(busyFlagSelector);

    const shopData = useSelector(shopDataSelector);
    const submitting = useSelector(submittingUpdateOrderSelector);
    const shopStaff = useSelector(shopStaffSelector);

    const shopItemParentCategories = useSelector(shopItemParentCategoriesSelector);

    const { isDiscoveredPrinter } = useSelector(printerSelector);

    const [draftItems, setDraftItems] = useState<DraftItem[]>([]);
    const [addMinutes, setAddMinutes] = useState<number>(0);

    const isRetail = useMemo(() => ShopData.isRetail(shopData.shop_type), [shopData]);

    const isGrocery = useMemo(() => ShopData.isGrocery(shopData.shop_type), [shopData]);

    const canStopPolling = useMemo(
      () => OrderModel.canStopPolling(shopData, orderData, id),
      [shopData, orderData, id],
    );

    const canChangeItemCount = useMemo(
      () => OrderModel.canChangeItemCount(orderData, shopData),
      [shopData, orderData],
    );

    const draftTotalItemCounts = useMemo(
      () => OrderModel.getDraftTotalItemsCount(draftItems, orderData),
      [draftItems, orderData],
    );

    const isContainRequiredItemOrder = useMemo(
      () => orderData.item_list.some(i => OrderItemData.isRequired(i)),
      [orderData],
    );

    const isAllRequiredItemsChecked = useMemo(() => {
      const requiredItems = draftItems.filter(i => OrderItemData.isRequired(i));
      if (requiredItems.length === 0) {
        return false;
      }
      return requiredItems.every(i => i.is_picked);
    }, [draftItems]);

    const canDisplayUpdateOrderStatusButton = useMemo(
      () =>
        OrderData.canDisplayUpdateOrderStatusButton(orderData.order_state) &&
        !(
          ShopModel.isLazonaStaff(shopData, shopStaff) &&
          OrderData.isPendingOrder(orderData.order_state)
        ),
      [orderData, shopData, shopStaff],
    );

    const canDisplayPrintReceiptButton = useMemo(
      () => isDiscoveredPrinter && OrderModel.canDisplayPrintReceiptButton(orderData),
      [orderData, isDiscoveredPrinter],
    );

    const canAutoPrint = useMemo(
      () =>
        AutoPrintSetting.enable(shopData.auto_print_setting) &&
        isDiscoveredPrinter &&
        OrderData.existsOrder(orderData) &&
        !OrderData.isCancelStatus(orderData.order_state) &&
        !OrderData.isFinishStatus(orderData.order_state),
      [isDiscoveredPrinter, orderData, shopData],
    );

    const goBack = useMemo(
      () =>
        (ShopData.isAcceptingReservedOrder(shopData) &&
          OrderData.isReservedOrder(orderData.time_type)) ||
        !OrderData.isPendingOrder(orderData.order_state) ||
        ShopModel.isLazonaStaff(shopData, shopStaff)
          ? "/orderList"
          : undefined,
      [orderData, shopData, shopStaff],
    );

    const dispatch = useDispatch();

    const handleClickApproveOrderButton = useCallback(() => {
      if (!orderData.cooking_start_able_flag && orderData.time_type === ORDER_TIME_TYPE.SPECIFIED) {
        dispatch(
          systemOpenedModal("UPDATE_COOKING_START_TIME", {
            orderId: orderData.order_no,
            receiveDatetime: orderData.receive_datetime,
          }),
        );
      } else {
        dispatch(
          userSubmittedApproveOrder(
            orderData.order_no,
            addMinutes,
            0,
            orderData.local_area_flag,
            isRetail,
            orderData.is_allowed_out_of_stock,
            draftItems,
            undefined,
            reasonForItemsStop,
          ),
        );
      }
    }, [dispatch, orderData, addMinutes, isRetail, draftItems, reasonForItemsStop]);

    const handleClickApproveOrderWithUpdateCookingStartTimeButton = useCallback(
      (remindMinutes: number) => {
        dispatch(
          userSubmittedApproveOrder(
            orderData.order_no,
            addMinutes,
            remindMinutes,
            orderData.local_area_flag,
            isRetail,
            orderData.is_allowed_out_of_stock,
            draftItems,
            true,
          ),
        );
      },
      [dispatch, orderData, addMinutes, isRetail, draftItems],
    );

    const handleClickHoldOrderButton = useCallback(() => {
      if (orderData.order_state === ORDER_STATE_TYPE.HOLD) {
        dispatch(
          systemOpenedModal("HOLD_ORDER", {
            userInfoTel: orderData.user_info.tel,
            isRetail,
          }),
        );
      } else {
        dispatch(userSubmittedHoldOrder(orderData, isRetail));
      }
    }, [dispatch, orderData, isRetail]);

    const handleClickFinishOrderButton = useCallback(() => {
      if (
        ShopData.isShopTypeThatCanChangeItemCount(shopData.shop_type) &&
        ShopData.isLocalAreaAndAutoApprove(shopData)
      ) {
        dispatch(
          userSubmittedFinishOrderForLocalAreaAndAutoApprove(
            orderData,
            draftItems,
            orderData.is_allowed_out_of_stock,
          ),
        );
      } else {
        dispatch(userSubmittedFinishOrder(orderData));
      }
    }, [dispatch, orderData, shopData, draftItems]);

    const handleClickFindCrewForLocalAreaButton = useCallback(() => {
      dispatch(
        userSubmittedApproveOrder(
          orderData.order_no,
          addMinutes,
          0,
          true,
          isRetail,
          orderData.is_allowed_out_of_stock,
          draftItems,
        ),
      );
    }, [dispatch, orderData, addMinutes, isRetail, draftItems]);

    const handleClilckCancelOrderButton = useCallback(() => {
      if (orderData.can_deny_after_approve_flag) {
        dispatch(userSubmittedDenyOrderAfterApprove(orderData, reasonForItemsStop));
      } else {
        dispatch(userSubmittedDenyOrder(orderData, reasonForItemsStop));
      }
    }, [dispatch, orderData, reasonForItemsStop]);

    const handleClilckCancelOrderButtonByRequiredItemShortage = useCallback(() => {
      dispatch(userSubmittedDenyOrderByRequiredItemShortage(orderData, reasonForItemsStop));
    }, [dispatch, orderData, reasonForItemsStop]);

    const handleCloseFindingCrewDialog = useCallback(() => {
      batch(() => {
        dispatch(userAccessedToPageThatNeedsOrderDetail(orderId));
        dispatch(systemClosedModal("FINDING_CREW"));
      });
    }, [dispatch, orderId]);

    const handleCloseWaitingComboOrderDialog = useCallback(() => {
      batch(() => {
        dispatch(userAccessedToPageThatNeedsOrderDetail(orderId));
        dispatch(systemClosedModal("WAIT_COMBO_ORDER"));
      });
    }, [dispatch, orderId]);

    const handleClickHoldOrderForGroceryButton = useCallback(() => {
      dispatch(userTouchedHoldOrderForGroceryButton(orderData, addMinutes));
    }, [dispatch, orderData, addMinutes]);

    const handleClickApproveOrderForGroceryButton = useCallback(() => {
      dispatch(
        userSubmittedApproveOrderForGrocery(orderData, draftItems, addMinutes, reasonForItemsStop),
      );
    }, [dispatch, orderData, draftItems, addMinutes, reasonForItemsStop]);

    const handleChangePickStatus = useCallback(
      (draftItem: DraftItem, checked: boolean) => {
        const freeOrderItem = draftItem.is_campaign
          ? undefined
          : draftItems.find(i => i.is_campaign && i.shop_item_id === draftItem.shop_item_id);
        const updatedDraftItemCounts = draftItems.map(i => {
          if (i.order_shop_item_id_group === draftItem.order_shop_item_id_group) {
            return {
              ...i,
              is_picked: checked,
            };
          }
          if (i.substituteItem?.order_shop_item_id_group === draftItem.order_shop_item_id_group) {
            return {
              ...i,
              substituteItem: {
                ...i.substituteItem,
                is_picked: checked,
              },
            };
          }
          return i;
        });
        const callback = () => setDraftItems(updatedDraftItemCounts);
        if (typeof freeOrderItem !== "undefined") {
          dispatch(
            userChangedPickStatus(
              orderData.order_no,
              freeOrderItem.order_shop_item_id_group,
              freeOrderItem.pick_count,
              freeOrderItem.is_picked,
            ),
          );
        }
        dispatch(
          userChangedPickStatus(
            orderData.order_no,
            draftItem.order_shop_item_id_group,
            draftItem.pick_count,
            checked,
            callback,
          ),
        );
      },
      [dispatch, draftItems, orderData],
    );

    const handleChangeSelectStopReasonShopItems = useCallback(
      (shopItemId: number, stopReasonType: ReasonForItemsStop) => {
        const reasonForItemsStopList = [{ shop_item_id: shopItemId, reason_type: stopReasonType }]
          .concat(reasonForItemsStop)
          .filter(
            (value, index, self) =>
              index === self.findIndex(t => t.shop_item_id === value.shop_item_id),
          );

        setReasonForItemsStop(
          reasonForItemsStopList.filter(
            reasonForItemsStop => reasonForItemsStop.reason_type !== REASON_FOR_ITEMS_STOP.DEFAULT,
          ),
        );
      },
      [reasonForItemsStop],
    );

    useEffect(() => {
      batch(() => {
        dispatch(userAccessedToPageThatNeedsShopData());
        dispatch(userAccessedToPageThatNeedsShopItemParentCategories());
      });
      return () => {
        dispatch(systemInitOrderData());
      };
    }, [dispatch]);

    useEffect(() => {
      dispatch(userAccessedToPageThatNeedsOrderDetail(orderId));
    }, [dispatch, orderId]);

    useEffect(() => {
      if (OrderData.existsOrder(orderData)) {
        setDraftItems(
          orderData.item_list.map(i => {
            const draftCount = OrderModel.getOrderItemDraftCount(i, orderData, isGrocery);
            const substituteItem = OrderModel.getSubstituteItem(i, orderData);
            return {
              ...i,
              pick_count: draftCount,
              total_order_count: i.free_order_count + draftCount,
              substituteItem,
            };
          }),
        );
      }
    }, [orderData, isGrocery]);

    useEffect(() => {
      if (canAutoPrint) {
        dispatch(systemAutoPrintReceipt(orderData, 1)); // FIXME: 複数回の印刷が不安定なため1回に固定
      }
    }, [dispatch, canAutoPrint, orderData, shopData]);

    const contextValue = useMemo<OrderDetailContextValue>(
      () => ({
        isHistory: false,
        isSimulator: false,
        orderData,
        shopData,
        isBusy,
        isRetail,
        isGrocery,
        addMinutes,
        setAddMinutes,
        draftItems,
        setDraftItems,
        draftTotalItemCounts,
        canChangeItemCount,
        isContainRequiredItemOrder,
        isAllRequiredItemsChecked,
        canDisplayPrintReceiptButton,
        submitting,
        shopItemParentCategories,
        handleClickApproveOrderButton,
        handleClickApproveOrderWithUpdateCookingStartTimeButton,
        handleClickHoldOrderButton,
        handleClickFinishOrderButton,
        handleClickFindCrewForLocalAreaButton,
        handleClilckCancelOrderButton,
        handleClilckCancelOrderButtonByRequiredItemShortage,
        handleCloseFindingCrewDialog,
        handleCloseWaitingComboOrderDialog,
        handleClickHoldOrderForGroceryButton,
        handleClickApproveOrderForGroceryButton,
        handleChangePickStatus,
        handleChangeSelectStopReasonShopItems,
      }),
      [
        orderData,
        shopData,
        isBusy,
        isRetail,
        isGrocery,
        addMinutes,
        setAddMinutes,
        draftItems,
        setDraftItems,
        draftTotalItemCounts,
        canChangeItemCount,
        isContainRequiredItemOrder,
        isAllRequiredItemsChecked,
        canDisplayPrintReceiptButton,
        submitting,
        shopItemParentCategories,
        handleClickApproveOrderButton,
        handleClickApproveOrderWithUpdateCookingStartTimeButton,
        handleClickHoldOrderButton,
        handleClickFinishOrderButton,
        handleClickFindCrewForLocalAreaButton,
        handleClilckCancelOrderButton,
        handleClilckCancelOrderButtonByRequiredItemShortage,
        handleCloseFindingCrewDialog,
        handleCloseWaitingComboOrderDialog,
        handleClickHoldOrderForGroceryButton,
        handleClickApproveOrderForGroceryButton,
        handleChangePickStatus,
        handleChangeSelectStopReasonShopItems,
      ],
    );

    return (
      <OrderDetailContext.Provider value={contextValue}>
        <Article
          watch={!canStopPolling}
          withHeader
          title="注文詳細"
          activePath="ORDER_LIST"
          goBack={goBack}
          onOrderDetail
        >
          {OrderData.existsOrder(orderData) ? (
            <>
              <Contents containerStyle={{ position: "relative", zIndex: 1 }}>
                <OrderDetail />
              </Contents>
              {canDisplayUpdateOrderStatusButton ? (
                <ButtonContainer>
                  <OrderDetailButtonContainer />
                </ButtonContainer>
              ) : undefined}
            </>
          ) : (
            <LoadingContainer />
          )}
        </Article>
        <OrderDetailModal />
      </OrderDetailContext.Provider>
    );
  },
);

export default withRouter(OrderDetailTemplate);
