import { Task } from "redux-saga";
import { put, takeLatest, fork } from "redux-saga/effects";
import { systemClosedModal } from "modules/app/actions";
import {
  ActionTypes,
  updateSimulatorPageTransition,
  UserTouchedSimulatorCloseFindingCrewDialogButtonAction,
} from "../actions";
import { foundCrewForLocalAreaSaga } from "./foundCrewForLocalAreaSaga";

export function* userTouchedSimulatorCloseFindingCrewDialogButtonSaga(
  action: UserTouchedSimulatorCloseFindingCrewDialogButtonAction,
) {
  const { orderData } = action.payload;
  yield put(systemClosedModal("FINDING_CREW"));
  yield put(updateSimulatorPageTransition(false));
  const searchDriverTask: Task = yield fork(foundCrewForLocalAreaSaga, orderData);
  const stopTask: Task = yield takeLatest(ActionTypes.S_UNMOUNT_SIMULATOR, stopTaskSaga);
  function* stopTaskSaga() {
    if (!searchDriverTask.isCancelled()) {
      yield searchDriverTask.cancel();
      yield stopTask.cancel();
    }
  }
}
