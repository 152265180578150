const setItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const getItem = (key: string) => localStorage.getItem(key);

const getItemOrDefault = (key: string, def: string) => {
  const value = localStorage.getItem(key);
  return value !== null ? value : def;
};

const removeItem = (key: string) => {
  localStorage.removeItem(key);
};

const hasItem = (key: string) => getItem(key) !== null;

export const LocalStorage = Object.freeze({
  setItem,
  getItem,
  getItemOrDefault,
  removeItem,
  hasItem,
});
