import { put, fork, call, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { API_KEY, StopOrderByShopRequest, StopOrderByShopResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { systemClosedModal, updateSnackBar } from "modules/app/actions";
import { UserSubmittedStopOrderByShopAction } from "../actions";

export function* userSubmittedStopOrderByShopSaga(action: UserSubmittedStopOrderByShopAction) {
  try {
    const { params }: { params: StopOrderByShopRequest } = action.payload;
    const {
      result,
      error,
    }: {
      result: StopOrderByShopResponse;
      error: unknown;
    } = yield call(commonApiSaga, API_KEY.STOP_ORDER_BY_SHOP, params);
    if (result && !error) {
      yield all([put(push("/orderList")), put(updateSnackBar("店舗の受付を停止しました"))]);
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  } finally {
    yield put(systemClosedModal("ORDER_STOP_BY_SHOP"));
  }
}
