import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ShopData } from "records/ShopData";
import { ShopStaff } from "records/ShopStaff";
import { systemLaunched, systemSendDailyLogin } from "modules/app/actions";
import { launchedSelector } from "modules/app/selectors";
import { systemWatchShopNotification } from "modules/shop/actions";
import { shopDataSelector, shopStaffSelector } from "modules/shop/selectors";
import { systemWatchPickerAssignments } from "modules/order/actions";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { PermissionContextValue, PermissionProvider } from "./PermissionContext";

type Props = {
  children: React.ReactNode;
};

export const AppContainer: React.FC<Props> = React.memo(({ children }) => {
  const launched = useSelector(launchedSelector);
  const shopData = useSelector(shopDataSelector);
  const shopStaff = useSelector(shopStaffSelector);

  const permissionContextValue: PermissionContextValue = useMemo(
    () => ({
      id: shopStaff.staff_id,
      role: shopStaff.role,
    }),
    [shopStaff],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!launched) {
      dispatch(systemLaunched());
    } else if (ShopData.existsShopData(shopData.id)) {
      dispatch(systemWatchShopNotification(shopData.id));
      if (ShopStaff.existsShopStaff(shopStaff)) {
        dispatch(
          systemSendDailyLogin(shopData.id, shopStaff.login_id, shopData.is_firestore_notification),
        );
        /*
        if (ShopData.isGrocery(shopData.shop_type)) {
          dispatch(
            systemWatchPickerAssignments(
              shopData.id,
              shopStaff.staff_id,
              shopData.is_firestore_notification,
            ),
          );
        }
        */
      }
    }
  }, [dispatch, launched, shopData, shopStaff]);

  return launched ? (
    <PermissionProvider value={permissionContextValue}>{children}</PermissionProvider>
  ) : (
    <LoadingContainer />
  );
});
