import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { replace } from "connected-react-router";
import { useDispatch } from "react-redux";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { IconOrderList } from "components/atoms/Icons/Navigation/IconOrderList";
import { IconOrderHistory } from "components/atoms/Icons/Navigation/IconOrderHistory";
import { IconShopItem } from "components/atoms/Icons/Navigation/IconShopItem";
import { IconSetting } from "components/atoms/Icons/Navigation/IconSetting";

export type PathType = "ORDER_LIST" | "ORDER_HISTORY" | "SHOP_ITEM" | "SETTING";

const Container = styled.div<{ active: boolean }>`
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-bottom: calc(0.6em + constant(safe-area-inset-bottom));
  padding-bottom: calc(0.6em + env(safe-area-inset-bottom));
  @media (orientation: landscape) {
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 1.5em;
    padding-left: 1.5em;
    border-bottom: 1px solid ${colorsConst.BORDER.THIN};
  }
  @media (orientation: portrait) {
    width: 25%;
    flex-grow: 1;
    flex-shrink: 1;
    & + & {
      border-left: 1px solid ${colorsConst.BORDER.THIN};
    }
  }
  background-color: ${p => (p.active ? colorsConst.TAB.ACTIVE : colorsConst.BACKGROUND.WHITE)};
`;

const IconContainer = styled.div`
  ${fontSize.XXXLARGE};
  line-height: 1;
`;

const LinkText = styled.div<{ active: boolean }>`
  ${fontSize.DEFAULT};
  color: ${p => (p.active ? colorsConst.TEXT.WHITE : colorsConst.TEXT.SECONDARY)};
`;

type Props = {
  path: PathType;
  activePath?: PathType;
  renderBadge?: JSX.Element;
};

export const NavigationItem: React.FC<Props> = React.memo(({ activePath, path, renderBadge }) => {
  const isActive = useMemo(() => path === activePath, [path, activePath]);

  const renderIcon = useMemo(() => {
    switch (path) {
      case "ORDER_LIST":
        return <IconOrderList invert={isActive} />;
      case "ORDER_HISTORY":
        return <IconOrderHistory invert={isActive} />;
      case "SHOP_ITEM":
        return <IconShopItem invert={isActive} />;
      case "SETTING":
        return <IconSetting invert={isActive} />;
      default:
        return undefined;
    }
  }, [path, isActive]);

  const renderText = useMemo(() => {
    switch (path) {
      case "ORDER_LIST":
        return "注文一覧";
      case "ORDER_HISTORY":
        return "注文履歴";
      case "SHOP_ITEM":
        return "商品一覧";
      case "SETTING":
        return "その他";
      default:
        return undefined;
    }
  }, [path]);

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    switch (path) {
      case "ORDER_LIST":
        dispatch(replace("/orderList", { isBack: true }));
        break;
      case "ORDER_HISTORY":
        dispatch(replace("/orderHistory", { isBack: isActive }));
        break;
      case "SHOP_ITEM":
        dispatch(replace("/shopItems", { isBack: isActive }));
        break;
      case "SETTING":
        dispatch(replace("/setting", { isBack: isActive }));
        break;
      default:
        break;
    }
  }, [dispatch, path, isActive]);

  return (
    <Container active={isActive} onClick={handleClick}>
      {renderBadge ?? undefined}
      <IconContainer>{renderIcon}</IconContainer>
      <LinkText active={isActive}>{renderText}</LinkText>
    </Container>
  );
});
