import { put, fork, call } from "redux-saga/effects";
import {
  API_KEY,
  UpdateSortForShopManagementRequest,
  UpdateSortForShopManagementResponse,
} from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateSnackBar } from "modules/app/actions";
import {
  updateShopItemParentCategories,
  UserSubmittedSortShopItemParentCategoryAction,
} from "../actions";

export function* userSubmittedSortShopItemParentCategorySaga(
  action: UserSubmittedSortShopItemParentCategoryAction,
) {
  try {
    const { categories } = action.payload;
    const params: UpdateSortForShopManagementRequest = {
      sorted_parent_category_ids: categories.map(category => category.parent_category_id),
    };
    const { result, error }: { result: UpdateSortForShopManagementResponse; error: unknown } =
      yield call(commonApiSaga, API_KEY.UPDATE_SORT_FOR_SHOP_MANAGEMENT, params);
    if (result && !error) {
      yield put(updateShopItemParentCategories(categories));
      yield put(updateSnackBar("並び替えの設定を保存しました"));
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
