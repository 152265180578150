import React, { useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API_KEY, StopOrderByShopRequest } from "apis";
import styled from "styled-components";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { userSubmittedStopOrderByShop } from "modules/shop/actions";
import {
  OrderStopReasonByShopType,
  ORDER_STOP_REASON_BY_SHOP,
  ShopReopenTimeValueType,
} from "modules/shop/model";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import { TextInput } from "components/atoms/FormFields/TextInput";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
} from "components/molecules/ModalDialog";
import { OrderStopReasonField } from "./OrderStopReasonField";
import { ShopReopenTimeField } from "./ShopReopenTimeField";

const NoticeContainer = styled.div`
  padding-top: 1em;
`;

const FieldContainer = styled.div`
  padding-top: 1em;
`;

const FieldLabel = styled.div`
  margin-bottom: 0.25em;
`;

const MODAL_KEY = "ORDER_STOP_BY_SHOP";
const useModal = modalHooksFactory(MODAL_KEY);

const isConnectedApiSelector = isConnectedApiSelectorFactory([API_KEY.STOP_ORDER_BY_SHOP]);

type Props = {};

export const OrderStopByShopDialog: React.FC<Props> = React.memo(() => {
  const submitting = useSelector(isConnectedApiSelector);
  const { data, close } = useModal();

  const [stopOrderByShopParams, setStopOrderByShopParams] = useState<StopOrderByShopRequest>({
    stop_reason: ORDER_STOP_REASON_BY_SHOP.NOT_SELECTED,
    stop_reason_text: "",
    restart_reserved_minutes_after: 0,
  });

  const canSubmitStopShop = useMemo(
    () =>
      stopOrderByShopParams.stop_reason !== ORDER_STOP_REASON_BY_SHOP.NOT_SELECTED &&
      (stopOrderByShopParams.stop_reason !== ORDER_STOP_REASON_BY_SHOP.OTHER ||
        stopOrderByShopParams.stop_reason_text !== ""),
    [stopOrderByShopParams],
  );

  const isActiveShopReopenField = useMemo(
    () =>
      (
        [
          ORDER_STOP_REASON_BY_SHOP.SOLD_OUT,
          ORDER_STOP_REASON_BY_SHOP.BUSY,
          ORDER_STOP_REASON_BY_SHOP.OTHER,
        ] as OrderStopReasonByShopType[]
      ).includes(stopOrderByShopParams.stop_reason),
    [stopOrderByShopParams],
  );

  const dispatch = useDispatch();

  const handleSelectReason = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const targetValue = parseInt(event.target.value, 10);
    if (Number.isNaN(targetValue)) {
      return;
    }
    setStopOrderByShopParams({
      stop_reason: targetValue as OrderStopReasonByShopType,
      stop_reason_text: "",
      restart_reserved_minutes_after: 0,
    });
  }, []);

  const handleChangeReasonText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStopOrderByShopParams({
        ...stopOrderByShopParams,
        stop_reason_text: event.target.value,
      });
    },
    [stopOrderByShopParams],
  );

  const handleSelectReopenTime = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const targetValue = parseInt(event.target.value, 10);
      if (Number.isNaN(targetValue)) {
        return;
      }
      setStopOrderByShopParams({
        stop_reason: stopOrderByShopParams.stop_reason,
        stop_reason_text: stopOrderByShopParams.stop_reason_text,
        restart_reserved_minutes_after: targetValue as ShopReopenTimeValueType,
      });
    },
    [stopOrderByShopParams],
  );

  const handleSubmit = useCallback(() => {
    dispatch(userSubmittedStopOrderByShop(stopOrderByShopParams));
  }, [dispatch, stopOrderByShopParams]);

  const onClosed = useCallback(() => {
    setStopOrderByShopParams({
      stop_reason: ORDER_STOP_REASON_BY_SHOP.NOT_SELECTED,
      stop_reason_text: "",
      restart_reserved_minutes_after: 0,
    });
  }, []);

  return (
    <ModalDialog isOpen={data.isOpen} close={close} onClosed={onClosed}>
      <ModalDialogHeader handleClose={close}>店舗の受付停止</ModalDialogHeader>
      <ModalBody>
        <div>
          <Text>
            店舗の受付を停止します
            <br />
            ユーザーページに表示させる、停止理由を選んでください。
          </Text>
        </div>
        <NoticeContainer>
          <Text size="SMALL">
            ※再開予約を設定しない場合、停止後、次の営業開始時間に受付を再開します。（当日2回目の営業開始時間または翌営業日）
            <br />
            ※店舗受付設定から受付を再開することもできます。
            <br />
            ※終日受付を停止する場合はストアマネージャーから休日設定を行ってください。
          </Text>
        </NoticeContainer>
        <FieldContainer>
          <FieldLabel>
            <Text size="SMALL" weight="BOLD">
              停止理由
            </Text>
          </FieldLabel>
          <OrderStopReasonField
            isSelected={
              stopOrderByShopParams.stop_reason !== ORDER_STOP_REASON_BY_SHOP.NOT_SELECTED
            }
            handleChange={handleSelectReason}
          />
        </FieldContainer>
        {stopOrderByShopParams.stop_reason === ORDER_STOP_REASON_BY_SHOP.OTHER ? (
          <FieldContainer>
            <TextInput
              id="reasonText"
              name="reasonText"
              placeholder="停止する理由を入力してください"
              onChange={handleChangeReasonText}
              value={stopOrderByShopParams.stop_reason_text}
            />
          </FieldContainer>
        ) : undefined}
        <FieldContainer>
          <FieldLabel style={!isActiveShopReopenField ? { opacity: 0.7 } : undefined}>
            <Text size="SMALL">再開予約</Text>
          </FieldLabel>
          <ShopReopenTimeField
            value={stopOrderByShopParams.restart_reserved_minutes_after}
            disabled={!isActiveShopReopenField}
            handleChange={handleSelectReopenTime}
          />
        </FieldContainer>
      </ModalBody>
      <ModalFooter>
        <Button
          appearance="secondary"
          handleClick={handleSubmit}
          loading={submitting}
          disabled={!canSubmitStopShop}
        >
          店舗受付を停止する
        </Button>
      </ModalFooter>
    </ModalDialog>
  );
});
