import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {};

export const IconBusinessSetting: React.FC<Props> = React.memo(({ ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 38 33" fill="none" {...props}>
    <path
      d="M6.33412 21.2605C6.24368 21.2605 6.15188 21.2455 6.06145 21.2141C5.63258 21.0637 5.40787 20.5963 5.55859 20.1686L11.0859 4.48626L11.0955 4.46303C12.1067 2.07139 14.6114 0.673307 17.1833 1.06554L29.9178 3.00208C31.6264 3.26174 33.1049 4.26623 33.9722 5.75725C34.8409 7.24826 34.982 9.02764 34.3613 10.6376C33.9832 11.6161 29.4389 24.0217 29.0416 25.0767C28.8812 25.5004 28.4085 25.715 27.9824 25.5564C27.5576 25.3965 27.3425 24.925 27.5015 24.5C27.9002 23.4408 32.4472 11.0312 32.8267 10.0485C33.2679 8.90464 33.1679 7.64049 32.55 6.58134C31.9334 5.52218 30.8838 4.80879 29.6698 4.62429L16.9353 2.68775C15.1157 2.41032 13.344 3.39431 12.6192 5.07939L7.10965 20.7139C6.99044 21.0501 6.67392 21.2605 6.33412 21.2605Z"
      fill={colorsConst.TEXT.LINK}
    />
    <path
      d="M5.99223 21.4026C5.4593 21.2158 5.17932 20.6343 5.3669 20.1019L10.8978 4.40966L10.9082 4.38496C11.9552 1.90886 14.5482 0.461678 17.2103 0.867624L29.9448 2.80417C31.7131 3.0729 33.2441 4.11295 34.142 5.65638C35.0413 7.19994 35.1874 9.04284 34.5449 10.7093C34.3254 11.2773 32.7058 15.6857 31.306 19.4958C30.2922 22.2552 29.3937 24.7009 29.2257 25.147C29.0263 25.6739 28.4385 25.9405 27.9096 25.7437C27.382 25.5451 27.1134 24.9584 27.3111 24.4298C27.4764 23.9907 28.3648 21.573 29.3717 18.8332C30.7782 15.0056 32.4161 10.5485 32.6371 9.97628C33.0565 8.88897 32.9614 7.68844 32.3741 6.68194C31.7881 5.67519 30.791 4.99726 29.6367 4.82184L16.9021 2.88529C15.1746 2.6219 13.4929 3.5545 12.8025 5.15212L7.29522 20.7802C7.14675 21.1989 6.75266 21.4604 6.33106 21.4604C6.2183 21.4604 6.1038 21.4414 5.99223 21.4026Z"
      fill={colorsConst.TEXT.LINK}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.67759 21.0838L2.67062 21.0825C1.69423 20.8949 0.783709 21.6481 0.801024 22.6436C0.835951 24.5498 1.3577 25.8796 1.87788 26.7365C2.13756 27.1643 2.396 27.473 2.59142 27.676C2.68912 27.7776 2.77109 27.8527 2.82964 27.9032C2.85891 27.9285 2.88233 27.9476 2.89895 27.9607L2.91865 27.9761L2.92445 27.9804L2.92629 27.9818L2.92721 27.9825C2.92732 27.9825 2.92742 27.9826 3.04532 27.8211L2.92721 27.9825L2.96032 28.0066L2.99998 28.0159C6.54847 28.8417 18.474 31.7054 21.6648 32.4722C21.9237 32.5646 22.1944 32.6292 22.4729 32.6652L22.4891 32.5394L22.4918 32.5297L22.4743 32.6654C22.6478 32.6877 22.8243 32.699 23.0027 32.699C23.3755 32.699 23.7584 32.6488 24.1375 32.5489C26.2048 32.0073 27.8866 30.1285 28.5571 27.6787C28.6247 27.4339 28.6825 27.2113 28.7388 26.9924L28.7537 26.9352C28.9053 26.3564 29.0516 25.7981 29.3638 24.8611C29.5412 24.327 29.2513 23.7487 28.7146 23.572C28.1799 23.3955 27.601 23.6856 27.4236 24.2196C27.0804 25.252 26.9243 25.85 26.7608 26.4806C26.7061 26.6932 26.6501 26.9072 26.586 27.1424C26.2003 28.5525 25.2091 30.1606 23.6196 30.5766C22.8651 30.7734 22.1999 30.6169 21.7705 30.1838C21.4465 29.8571 21.2404 29.3636 21.1771 28.7239C21.1139 28.0848 21.1949 27.3127 21.4321 26.4499C21.5805 25.9074 21.2616 25.3462 20.7167 25.1968C20.634 25.1743 20.5503 25.1625 20.4678 25.1607L2.67759 21.0838ZM1.00099 22.6401C1.0695 26.3792 3.04532 27.8211 3.04532 27.8211C6.59863 28.648 18.5477 31.5174 21.7221 32.2802C21.9652 32.3679 22.2198 32.4297 22.4824 32.4647L22.4985 32.4668L22.4999 32.4672L22.502 32.4677L22.4985 32.4668C22.2302 32.4322 21.9701 32.3697 21.7221 32.2802C18.5477 31.5174 6.59863 28.648 3.04532 27.8211C3.04532 27.8211 1.0695 26.3792 1.00099 22.6401ZM22.5011 32.4672L22.502 32.4677L22.5089 32.4682C22.6708 32.4886 22.8358 32.499 23.0027 32.499C22.833 32.499 22.6656 32.4883 22.5011 32.4672Z"
      fill={colorsConst.TEXT.LINK}
    />
    <path
      d="M27.8439 10.601C27.7959 10.601 27.748 10.5969 27.7 10.5901L14.9572 8.67678C14.4338 8.59751 14.0721 8.11099 14.1516 7.58893C14.231 7.06686 14.7188 6.70607 15.2422 6.78534L27.985 8.69865C28.5084 8.77791 28.8702 9.26444 28.7907 9.7865C28.7194 10.2607 28.3111 10.601 27.8439 10.601Z"
      fill={colorsConst.TEXT.LINK}
    />
    <path
      d="M22.5023 14.4275C22.4488 14.4275 22.394 14.4234 22.3392 14.4138L13.57 12.9105C13.048 12.8217 12.6972 12.3269 12.7876 11.8062C12.8781 11.2856 13.3727 10.9357 13.8948 11.0259L22.664 12.5292C23.186 12.618 23.5368 13.1128 23.4463 13.6335C23.3655 14.0995 22.9613 14.4275 22.5023 14.4275Z"
      fill={colorsConst.TEXT.LINK}
    />
    <path
      d="M20.8578 18.6638C20.8043 18.6638 20.7495 18.6597 20.6947 18.6501L11.9255 17.1468C11.4034 17.058 11.0527 16.5633 11.1431 16.0426C11.2322 15.5219 11.7282 15.172 12.2502 15.2622L21.0194 16.7655C21.5415 16.8544 21.8923 17.3491 21.8018 17.8698C21.721 18.3358 21.3168 18.6638 20.8578 18.6638Z"
      fill={colorsConst.TEXT.LINK}
    />
    <path
      d="M34.6732 16.3268L34.1542 16.8459L34.6732 16.3268C33.9069 15.5605 32.6645 15.5605 31.8983 16.3268L29 19.2251L26.1017 16.3268C25.3355 15.5606 24.0931 15.5605 23.3268 16.3268C22.5605 17.0931 22.5605 18.3355 23.3268 19.1018L26.2251 22.0001L23.327 24.8982C22.5607 25.6645 22.5607 26.9069 23.327 27.6732C24.0932 28.4395 25.3356 28.4395 26.1019 27.6732L25.5716 27.1428L26.1019 27.6732L29 24.775L31.8981 27.6732C32.6644 28.4395 33.9068 28.4395 34.673 27.6732C35.4393 26.9069 35.4393 25.6645 34.673 24.8983L31.7749 22.0001L34.6732 19.1017C35.4395 18.3355 35.4395 17.0931 34.6732 16.3268Z"
      fill={colorsConst.TEXT.LINK}
      stroke="#F3F6F9"
      strokeWidth="1.5"
    />
  </svg>
));
