import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconSearch: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M22.1,35.7c8.1,0,14.6-6.5,14.6-14.6c0-8.1-6.5-14.6-14.6-14.6C14,6.5,7.5,13,7.5,21.1 C7.5,29.1,14,35.7,22.1,35.7z M22.1,38.7c9.7,0,17.6-7.9,17.6-17.6c0-9.7-7.9-17.6-17.6-17.6c-9.7,0-17.6,7.9-17.6,17.6 C4.5,30.8,12.4,38.7,22.1,38.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M15.8,14.2c-3.8,3.8-3.8,10,0,13.9c2.6,2.6,6.3,3.4,9.6,2.5c0.5-0.2,1.1,0.2,1.2,0.7c0.2,0.5-0.2,1.1-0.7,1.2 c-4,1.1-8.5,0.1-11.6-3c-4.6-4.6-4.6-12.1,0-16.7c0.4-0.4,1-0.4,1.4,0C16.2,13.2,16.2,13.8,15.8,14.2z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M39.7,43.9l-9.2-10l2.9-2.7l9.2,10c0.7,0.8,0.7,2.1-0.1,2.8C41.7,44.7,40.5,44.7,39.7,43.9z"
      />
    </svg>
  );
});
