import React from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { DateUtils } from "utils/DateUtils";
import { fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";

const Container = styled.div``;

const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.25em;
`;

const DateContainer = styled.div`
  margin-right: 0.5em;
  font-weight: ${fontWeightsConst.MEDIUM};
`;

const TimeContainer = styled.div`
  ${fontSize.XLARGE};
  font-weight: ${fontWeightsConst.BOLD};
`;

type Props = {
  orderData: OrderData;
};

export const ReceiveProgress: React.FC<Props> = React.memo(({ orderData }) => (
  <Container>
    {OrderData.isDeliveryOrder(orderData.receive_type) && !orderData.is_driver_accepted ? (
      "-"
    ) : orderData.is_today_receive ? (
      <DateTimeContainer>
        <TimeContainer>{DateUtils.dateToString(orderData.receive_datetime, "HH:mm")}</TimeContainer>
      </DateTimeContainer>
    ) : (
      <DateTimeContainer>
        <DateContainer>
          {DateUtils.dateToString(orderData.receive_datetime, "M月D日")}
        </DateContainer>
        <TimeContainer>{DateUtils.dateToString(orderData.receive_datetime, "HH:mm")}</TimeContainer>
      </DateTimeContainer>
    )}
  </Container>
));
