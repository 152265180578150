import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { API_KEY } from "apis";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { shopDataSelector } from "modules/shop/selectors";
import { Contents } from "components/atoms/Contents";
import { Text } from "components/atoms/Text";
import { Article } from "components/organisms/Article";
import { ToggleButton } from "components/atoms/ToggleButton";
import { ShopData } from "records/ShopData";
import {
  InputSettingListItem,
  InputSettingListItemInner,
  TextContainer,
  InputContainer,
} from "components/organisms/InputSettingListItem";
import { systemOpenedModal } from "modules/app/actions";
import { userSubmittedUpdateDisplayImages } from "modules/shop/actions";

const submittingSelector = isConnectedApiSelectorFactory([API_KEY.SET_DISPLAY_IMAGES]);

export const ShowImageSettingTemplate: React.FC = React.memo(() => {
  const shopData = useSelector(shopDataSelector);
  const submitting = useSelector(submittingSelector);
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (ShopData.isShowDisplayImages(shopData)) {
      dispatch(userSubmittedUpdateDisplayImages(!ShopData.isShowDisplayImages(shopData)));
    } else {
      dispatch(systemOpenedModal("CONFIRM_SHOW_THUMBNAIL_DIALOG", {}));
    }
  }, [dispatch, shopData]);

  return (
    <Article
      withHeader
      title="商品画像の表示設定"
      goBack="/setting"
      withNavigation
      activePath="SETTING"
    >
      <Contents>
        <InputSettingListItem>
          <InputSettingListItemInner>
            <TextContainer>
              <Text color="SECONDARY" size="SMALL" weight="MEDIUM">
                商品画像を表示する
              </Text>
            </TextContainer>
            <InputContainer>
              <ToggleButton
                active={ShopData.isShowDisplayImages(shopData)}
                handleClick={handleClick}
                disabled={submitting}
              />
            </InputContainer>
          </InputSettingListItemInner>
        </InputSettingListItem>
      </Contents>
    </Article>
  );
});
