import { ShopData } from "records/ShopData";
import { ShopStaff } from "records/ShopStaff";
import { ShopItemCategory } from "records/ShopItemCategory";
import { ShopItemOptionSet, SubmittableOptionSetType } from "records/ShopItemOptionSet";
import { ShopItemParentCategory } from "records/ShopItemParentCategory";
import { ReasonForItemsStop, ReasonForItemsStopView } from "records/ShopItem";
import { ShopBusiness } from "records/ShopBusiness";
import { ShopInvitationCode } from "records/ShopInvitationCode";
import { AutoPrintSetting, PrinterFontSizeType } from "records/AutoPrintSetting";
import { UpdateBusinessTimeForm } from "forms/updateBusinessTime";
import { UpdateBusinessBusyTimeForm } from "forms/updateBusinessBusyTime";
import { UpdateBusinessNationalHolidayForm } from "forms/updateBusinessNationalHoliday";
import { StopOrderByShopRequest, StopReasonShopItems } from "apis";
import { ShopItemSubmitType, SetPushNotificationType } from "./model";

export enum ActionTypes {
  U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_DATA = "shop/U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_DATA",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_ITEMS = "shop/U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_ITEMS",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_OPTIONS = "shop/U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_OPTIONS",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_ITEM_PARENT_CATEGORIES = "shop/U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_ITEM_PARENT_CATEGORIES",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_BUSINESS = "shop/U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_BUSINESS",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_INVITATION_CODE = "shop/U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_INVITATION_CODE",
  U_SUBMITTED_UPDATE_BUSINESS_TIME = "shop/U_SUBMITTED_UPDATE_BUSINESS_TIME",
  U_SUBMITTED_UPDATE_BUSINESS_BUSY_TIME = "shop/U_SUBMITTED_UPDATE_BUSINESS_BUSY_TIME",
  U_SUBMITTED_REGISTER_BUSINESS_HOLIDAY = "shop/U_SUBMITTED_REGISTER_BUSINESS_HOLIDAY",
  U_SUBMITTED_DELETE_BUSINESS_HOLIDAY = "shop/U_SUBMITTED_DELETE_BUSINESS_HOLIDAY",
  U_SUBMITTED_UPDATE_BUSINESS_NATIONAL_HOLIDAY = "shop/U_SUBMITTED_UPDATE_BUSINESS_NATIONAL_HOLIDAY",
  U_SUBMITTED_STOP_ORDER_BY_SHOP = "shop/U_SUBMITTED_STOP_ORDER_BY_SHOP",
  U_SUBMITTED_RESTART_ORDER_BY_SHOP = "shop/U_SUBMITTED_RESTART_ORDER_BY_SHOP",
  U_SUBMITTED_SORT_SHOP_ITEM_PARENT_CATEGORY = "shop/U_SUBMITTED_SORT_SHOP_ITEM_PARENT_CATEGORY",
  U_SUBMITTED_STOP_FASTEST_ORDER = "shop/U_SUBMITTED_STOP_FASTEST_ORDER",
  U_SUBMITTED_UPDATE_DISPLAY_IMAGES = "shop/U_SUBMITTED_UPDATE_DISPLAY_IMAGES",

  U_SUBMITTED_STOP_ORDER_BY_ITEMS = "shop/U_SUBMITTED_STOP_ORDER_BY_ITEMS",
  U_SUBMITTED_RESTART_ORDER_BY_ITEMS = "shop/U_SUBMITTED_RESTART_ORDER_BY_ITEMS",
  U_SUBMITTED_STOP_RELEASE_BY_ITEMS = "shop/U_SUBMITTED_STOP_RELEASE_BY_ITEMS",
  U_SUBMITTED_STOP_ORDER_BY_OPTION_SETS = "shop/U_SUBMITTED_STOP_ORDER_BY_OPTION_SETS",
  U_SUBMITTED_STOP_RELEASE_BY_OPTION_SETS = "shop/U_SUBMITTED_STOP_RELEASE_BY_OPTION_SETS",
  U_SUBMITTED_RESTART_ORDER_BY_OPTION_SETS = "shop/U_SUBMITTED_RESTART_ORDER_BY_OPTION_SETS",
  U_SUBMITTED_STOP_ORDER_BY_FILTERED_OPTIONS = "shop/U_SUBMITTED_STOP_ORDER_BY_FILTERED_OPTIONS",
  U_SUBMITTED_STOP_RELEASE_BY_FILTERED_OPTIONS = "shop/U_SUBMITTED_STOP_RELEASE_BY_FILTERED_OPTIONS",

  U_SUBMITTED_STOP_ORDER_BY_STOCK_CHECK = "shop/U_SUBMITTED_STOP_ORDER_BY_STOCK_CHECK",
  U_SUBMITTED_RESTART_ORDER_BY_STOCK_CHECK = "shop/U_SUBMITTED_RESTART_ORDER_BY_STOCK_CHECK",
  U_SUBMITTED_AUTO_PRINT_SETTING = "shop/U_SUBMITTED_AUTO_PRINT_SETTING",
  U_SUBMITTED_UPDATE_SHOP_ITEMS_STOPPED_REASONS = "shop/U_SUBMITTED_UPDATE_SHOP_ITEMS_STOPPED_REASONS",
  U_SUBMITTED_PUSH_NOTIFICATION_SETTING = "shop/U_SUBMITTED_PUSH_NOTIFICATION_SETTING",

  S_UPDATE_SHOP_ITEM_STATUS = "shop/S_UPDATE_SHOP_ITEM_STATUS",
  S_UPDATE_SHOP_ITEM_OPTION_STATUS = "shop/S_UPDATE_SHOP_ITEM_OPTION_STATUS",
  S_UPDATE_SHOP_ITEM_SELECT_STOP_REASON_TYPE = "shop/S_UPDATE_SHOP_ITEM_SELECT_STOP_REASON_TYPE",
  S_WATCH_SHOP_NOTIFICATION = "shop/S_WATCH_SHOP_NOTIFICATION",

  R_UPDATE_SHOP_DATA = "shop/R_UPDATE_SHOP_DATA",
  R_UPDATE_SHOP_STAFF = "shop/R_UPDATE_SHOP_STAFF",
  R_UPDATE_SHOP_ITEMS = "shop/R_UPDATE_SHOP_ITEMS",
  R_UPDATE_SHOP_ITEM_OPTIONS = "shop/R_UPDATE_SHOP_ITEM_OPTIONS",
  R_UPDATE_SHOP_ITEM_PARENT_CATEGORIES = "shop/R_UPDATE_SHOP_ITEM_PARENT_CATEGORIES",
  R_UPDATE_SHOP_BUSINESS = "shop/R_UPDATE_SHOP_BUSINESS",
  R_UPDATE_SHOP_INVITATION_CODE = "shop/R_UPDATE_SHOP_INVITATION_CODE",
  R_UPDATE_AUTO_PRINT_SETTING = "shop/R_UPDATE_AUTO_PRINT_SETTING",
  R_ENEBLE_IMMEDIATE_DELIVERY_PAUSED = "shop/R_ENEBLE_IMMEDIATE_DELIVERY_PAUSED",
  R_UPDATE_DISPLAY_IMAGES = "shop/R_UPDATE_DISPLAY_IMAGES",
  R_UPDATE_PUSH_NOTIFICATION_SETTING = "shop_R_UPDATE_PUSH_NOTIFICATION_SETTING",
}

export const userAccessedToPageThatNeedsShopData = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_DATA,
  } as const);
export type UserAccessedToPageThatNeedsShopDataAction = ReturnType<
  typeof userAccessedToPageThatNeedsShopData
>;

export const userAccessedToPageThatNeedsShopItems = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_ITEMS,
  } as const);
export type UserAccessedToPageThatNeedsShopItemsAction = ReturnType<
  typeof userAccessedToPageThatNeedsShopItems
>;

export const userAccessedToPageThatNeedsShopItemOptions = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_OPTIONS,
  } as const);
export type UserAccessedToPageThatNeedsShopItemOptionsAction = ReturnType<
  typeof userAccessedToPageThatNeedsShopItemOptions
>;

export const userAccessedToPageThatNeedsShopItemParentCategories = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_ITEM_PARENT_CATEGORIES,
  } as const);
export type UserAccessedToPageThatNeedsShopItemParentCategoriesAction = ReturnType<
  typeof userAccessedToPageThatNeedsShopItemParentCategories
>;

export const userAccessedToPageThatNeedsShopBusiness = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_BUSINESS,
  } as const);
export type UserAccessedToPageThatNeedsShopBusinessAction = ReturnType<
  typeof userAccessedToPageThatNeedsShopBusiness
>;

export const userAccessedToPageThatNeedsShopInvitationCode = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_INVITATION_CODE,
  } as const);
export type UserAccessedToPageThatNeedsShopInvitationCodeAction = ReturnType<
  typeof userAccessedToPageThatNeedsShopInvitationCode
>;

export const userSubmittedUpdateBusinessTime = (values: UpdateBusinessTimeForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_UPDATE_BUSINESS_TIME,
    payload: {
      values,
    },
  } as const);
export type UserSubmittedUpdateBusinessTimeAction = ReturnType<
  typeof userSubmittedUpdateBusinessTime
>;

export const userSubmittedUpdateBusinessBusyTime = (values: UpdateBusinessBusyTimeForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_UPDATE_BUSINESS_BUSY_TIME,
    payload: {
      values,
    },
  } as const);
export type UserSubmittedUpdateBusinessBusyTimeAction = ReturnType<
  typeof userSubmittedUpdateBusinessBusyTime
>;

export const userSubmittedRegisterBusinessHoliday = (value: Date) =>
  ({
    type: ActionTypes.U_SUBMITTED_REGISTER_BUSINESS_HOLIDAY,
    payload: {
      value,
    },
  } as const);
export type UserSubmittedRegisterBusinessHolidayAction = ReturnType<
  typeof userSubmittedRegisterBusinessHoliday
>;

export const userSubmittedDeleteBusinessHoliday = (value: string) =>
  ({
    type: ActionTypes.U_SUBMITTED_DELETE_BUSINESS_HOLIDAY,
    payload: {
      value,
    },
  } as const);
export type UserSubmittedDeleteBusinessHolidayAction = ReturnType<
  typeof userSubmittedDeleteBusinessHoliday
>;

export const userSubmittedUpdateBusinessNationalHoliday = (
  values: UpdateBusinessNationalHolidayForm,
) =>
  ({
    type: ActionTypes.U_SUBMITTED_UPDATE_BUSINESS_NATIONAL_HOLIDAY,
    payload: {
      values,
    },
  } as const);
export type UserSubmittedUpdateBusinessNationalHolidayAction = ReturnType<
  typeof userSubmittedUpdateBusinessNationalHoliday
>;

export const userSubmittedStopOrderByShop = (params: StopOrderByShopRequest) =>
  ({
    type: ActionTypes.U_SUBMITTED_STOP_ORDER_BY_SHOP,
    payload: {
      params,
    },
  } as const);
export type UserSubmittedStopOrderByShopAction = ReturnType<typeof userSubmittedStopOrderByShop>;

export const userSubmittedRestartOrderByShop = () =>
  ({
    type: ActionTypes.U_SUBMITTED_RESTART_ORDER_BY_SHOP,
  } as const);
export type UserSubmittedRestartOrderByShopAction = ReturnType<
  typeof userSubmittedRestartOrderByShop
>;

export const userSubmittedSortShopItemParentCategory = (categories: ShopItemParentCategory[]) =>
  ({
    type: ActionTypes.U_SUBMITTED_SORT_SHOP_ITEM_PARENT_CATEGORY,
    payload: {
      categories,
    },
  } as const);
export type UserSubmittedSortShopItemParentCategoryAction = ReturnType<
  typeof userSubmittedSortShopItemParentCategory
>;

export const userSubmittedStopFastestOrder = () =>
  ({
    type: ActionTypes.U_SUBMITTED_STOP_FASTEST_ORDER,
  } as const);
export type UserSubmittedStopFastestOrderAction = ReturnType<typeof userSubmittedStopFastestOrder>;

// 注文受付停止（商品）
export const userSubmittedStopOrderByItems = (shopItemId: number) =>
  ({
    type: ActionTypes.U_SUBMITTED_STOP_ORDER_BY_ITEMS,
    payload: {
      shopItemId,
    },
  } as const);
export type UserSubmittedStopOrderByItemsAction = ReturnType<typeof userSubmittedStopOrderByItems>;

export const userSubmittedRestartOrderByItems = (shopItemId: number, isConfirmed: boolean) =>
  ({
    type: ActionTypes.U_SUBMITTED_RESTART_ORDER_BY_ITEMS,
    payload: {
      shopItemId,
      isConfirmed,
    },
  } as const);
export type UserSubmittedRestartOrderByItemsAction = ReturnType<
  typeof userSubmittedRestartOrderByItems
>;

export const userSubmittedStopReleaseByItems = (shopItemId: number) =>
  ({
    type: ActionTypes.U_SUBMITTED_STOP_RELEASE_BY_ITEMS,
    payload: {
      shopItemId,
    },
  } as const);
export type UserSubmittedStopReleaseByItemsAction = ReturnType<
  typeof userSubmittedStopReleaseByItems
>;

export const userSubmittedStopOrderByOptionSets = (
  optionSet: SubmittableOptionSetType,
  isConfirmed: boolean,
) =>
  ({
    type: ActionTypes.U_SUBMITTED_STOP_ORDER_BY_OPTION_SETS,
    payload: {
      optionSet,
      isConfirmed,
    },
  } as const);
export type UserSubmittedStopOrderByOptionSetsAction = ReturnType<
  typeof userSubmittedStopOrderByOptionSets
>;

export const userSubmittedStopReleaseByOptionSets = (optionSet: SubmittableOptionSetType) =>
  ({
    type: ActionTypes.U_SUBMITTED_STOP_RELEASE_BY_OPTION_SETS,
    payload: {
      optionSet,
    },
  } as const);
export type UserSubmittedStopReleaseByOptionSetsAction = ReturnType<
  typeof userSubmittedStopReleaseByOptionSets
>;

export const userSubmittedRestartOrderByOptionSets = (optionSet: SubmittableOptionSetType) =>
  ({
    type: ActionTypes.U_SUBMITTED_RESTART_ORDER_BY_OPTION_SETS,
    payload: {
      optionSet,
    },
  } as const);
export type UserSubmittedRestartOrderByOptionSetsAction = ReturnType<
  typeof userSubmittedRestartOrderByOptionSets
>;

export const userSubmittedStopOrderByFilteredOptions = (optionSets: ShopItemOptionSet[]) =>
  ({
    type: ActionTypes.U_SUBMITTED_STOP_ORDER_BY_FILTERED_OPTIONS,
    payload: {
      optionSets,
    },
  } as const);
export type UserSubmittedStopOrderByFilteredOptionsAction = ReturnType<
  typeof userSubmittedStopOrderByFilteredOptions
>;

export const userSubmittedStopReleaseByFilteredOptions = (optionSets: ShopItemOptionSet[]) =>
  ({
    type: ActionTypes.U_SUBMITTED_STOP_RELEASE_BY_FILTERED_OPTIONS,
    payload: {
      optionSets,
    },
  } as const);
export type UserSubmittedStopReleaseByFilteredOptionsAction = ReturnType<
  typeof userSubmittedStopReleaseByFilteredOptions
>;

export const userSubmittedStopOrderByStockCheck = (shopItemIds: number[], callback?: () => void) =>
  ({
    type: ActionTypes.U_SUBMITTED_STOP_ORDER_BY_STOCK_CHECK,
    payload: {
      shopItemIds,
      callback,
    },
  } as const);
export type UserSubmittedStopOrderByStockCheckAction = ReturnType<
  typeof userSubmittedStopOrderByStockCheck
>;

export const userSubmittedRestartOrderByStockCheck = (
  shopItemIds: number[],
  callback?: () => void,
) =>
  ({
    type: ActionTypes.U_SUBMITTED_RESTART_ORDER_BY_STOCK_CHECK,
    payload: {
      shopItemIds,
      callback,
    },
  } as const);
export type UserSubmittedRestartOrderByStockCheckAction = ReturnType<
  typeof userSubmittedRestartOrderByStockCheck
>;

export const userSubmittedAutoPrintSetting = (
  enable: boolean,
  count: number,
  fontSize: PrinterFontSizeType,
) =>
  ({
    type: ActionTypes.U_SUBMITTED_AUTO_PRINT_SETTING,
    payload: {
      enable,
      count,
      fontSize,
    },
  } as const);
export type UserSubmittedAutoPrintSettingAction = ReturnType<typeof userSubmittedAutoPrintSetting>;

export const userSubmittedUpdateDisplayImages = (enable: boolean) =>
  ({
    type: ActionTypes.U_SUBMITTED_UPDATE_DISPLAY_IMAGES,
    payload: {
      enable,
    },
  } as const);
export type UserSubmittedUpdateDisplayImagesAction = ReturnType<
  typeof userSubmittedUpdateDisplayImages
>;

export const userSubmittedUpdateShopItemStoppedReasons = (
  items: StopReasonShopItems[],
  view_type: ReasonForItemsStopView,
) =>
  ({
    type: ActionTypes.U_SUBMITTED_UPDATE_SHOP_ITEMS_STOPPED_REASONS,
    payload: {
      view_type,
      items,
    },
  } as const);
export type UserSubmittedUpdateShopItemStoppedReasonsAction = ReturnType<
  typeof userSubmittedUpdateShopItemStoppedReasons
>;

export const userSubmittedPushNotificationSetting = (
  notificationType: SetPushNotificationType,
  enable: boolean,
) =>
  ({
    type: ActionTypes.U_SUBMITTED_PUSH_NOTIFICATION_SETTING,
    payload: {
      notificationType,
      enable,
    },
  } as const);
export type UserSubmittedPushNotificationSettingAction = ReturnType<
  typeof userSubmittedPushNotificationSetting
>;

export const systemUpdateShopItemStatus = (
  shopItemIds: number[],
  submitType: ShopItemSubmitType,
  withOptionStop: boolean = false,
) =>
  ({
    type: ActionTypes.S_UPDATE_SHOP_ITEM_STATUS,
    payload: {
      shopItemIds,
      submitType,
      withOptionStop,
    },
  } as const);
export type SystemUpdateShopItemStatusAction = ReturnType<typeof systemUpdateShopItemStatus>;

export const systemUpdateShopItemOptionStatus = (
  optionIdsToBeUpdated: number[],
  submitType: ShopItemSubmitType,
) =>
  ({
    type: ActionTypes.S_UPDATE_SHOP_ITEM_OPTION_STATUS,
    payload: {
      optionIdsToBeUpdated,
      submitType,
    },
  } as const);
export type SystemUpdateShopItemOptionStatusAction = ReturnType<
  typeof systemUpdateShopItemOptionStatus
>;

export const systemUpdateShopItemSelectStopReasonType = (
  shopItemIds: number[],
  reasonForItemsStop: ReasonForItemsStop,
) =>
  ({
    type: ActionTypes.S_UPDATE_SHOP_ITEM_SELECT_STOP_REASON_TYPE,
    payload: {
      shopItemIds,
      reasonForItemsStop,
    },
  } as const);
export type SystemUpdateShopItemSelectStopReasonTypeAction = ReturnType<
  typeof systemUpdateShopItemSelectStopReasonType
>;

export const systemWatchShopNotification = (shopId: number) =>
  ({
    type: ActionTypes.S_WATCH_SHOP_NOTIFICATION,
    payload: {
      shopId,
    },
  } as const);
export type SystemWatchShopNotificationAction = ReturnType<typeof systemWatchShopNotification>;

export const updateShopData = (value: ShopData) =>
  ({
    type: ActionTypes.R_UPDATE_SHOP_DATA,
    payload: {
      value,
    },
  } as const);
export type ReducerUpdateShopDataAction = ReturnType<typeof updateShopData>;

export const updateShopStaff = (value: ShopStaff) =>
  ({
    type: ActionTypes.R_UPDATE_SHOP_STAFF,
    payload: {
      value,
    },
  } as const);
export type ReducerUpdateShopStaffAction = ReturnType<typeof updateShopStaff>;

export const updateShopItems = (value: ShopItemCategory[]) =>
  ({
    type: ActionTypes.R_UPDATE_SHOP_ITEMS,
    payload: {
      value,
    },
  } as const);
export type ReducerUpdateShopItemsAction = ReturnType<typeof updateShopItems>;

export const updateShopItemOptions = (value: ShopItemOptionSet[]) =>
  ({
    type: ActionTypes.R_UPDATE_SHOP_ITEM_OPTIONS,
    payload: {
      value,
    },
  } as const);
export type ReducerUpdateShopItemOptionsAction = ReturnType<typeof updateShopItemOptions>;

export const updateShopItemParentCategories = (value: ShopItemParentCategory[]) =>
  ({
    type: ActionTypes.R_UPDATE_SHOP_ITEM_PARENT_CATEGORIES,
    payload: {
      value,
    },
  } as const);
export type ReducerUpdateShopItemParentCategoriesAction = ReturnType<
  typeof updateShopItemParentCategories
>;

export const updateShopBusiness = (value: ShopBusiness) =>
  ({
    type: ActionTypes.R_UPDATE_SHOP_BUSINESS,
    payload: {
      value,
    },
  } as const);
export type ReducerUpdateShopBusinessAction = ReturnType<typeof updateShopBusiness>;

export const updateShopInvitationCode = (value: ShopInvitationCode) =>
  ({
    type: ActionTypes.R_UPDATE_SHOP_INVITATION_CODE,
    payload: {
      value,
    },
  } as const);
export type ReducerUpdateShopInvitationCodeAction = ReturnType<typeof updateShopInvitationCode>;

export const updateAutoPrintSetting = (setting: AutoPrintSetting) =>
  ({
    type: ActionTypes.R_UPDATE_AUTO_PRINT_SETTING,
    payload: {
      setting,
    },
  } as const);
export type ReducerUpdateAutoPrintSettingAction = ReturnType<typeof updateAutoPrintSetting>;

export const enableImmediateDeliveryPaused = () =>
  ({
    type: ActionTypes.R_ENEBLE_IMMEDIATE_DELIVERY_PAUSED,
  } as const);
export type ReducerEnableImmediateDeliveryPausedAction = ReturnType<
  typeof enableImmediateDeliveryPaused
>;

export const updateDisplayImages = (enable: boolean) =>
  ({
    type: ActionTypes.R_UPDATE_DISPLAY_IMAGES,
    payload: {
      enable,
    },
  } as const);
export type ReducerUpdateDisplayImagesAction = ReturnType<typeof updateDisplayImages>;

export const updatePushNotificationSetting = (
  notificationType: SetPushNotificationType,
  enable: boolean,
) =>
  ({
    type: ActionTypes.R_UPDATE_PUSH_NOTIFICATION_SETTING,
    payload: {
      notificationType,
      enable,
    },
  } as const);

export type ReducerUpdatePushNotificationSettingAction = ReturnType<
  typeof updatePushNotificationSetting
>;

export type ShopActions =
  | UserAccessedToPageThatNeedsShopDataAction
  | UserAccessedToPageThatNeedsShopItemsAction
  | UserAccessedToPageThatNeedsShopItemOptionsAction
  | UserAccessedToPageThatNeedsShopItemParentCategoriesAction
  | UserAccessedToPageThatNeedsShopBusinessAction
  | UserAccessedToPageThatNeedsShopInvitationCodeAction
  | UserSubmittedUpdateBusinessTimeAction
  | UserSubmittedUpdateBusinessBusyTimeAction
  | UserSubmittedRegisterBusinessHolidayAction
  | UserSubmittedDeleteBusinessHolidayAction
  | UserSubmittedUpdateBusinessNationalHolidayAction
  | UserSubmittedStopOrderByShopAction
  | UserSubmittedRestartOrderByShopAction
  | UserSubmittedSortShopItemParentCategoryAction
  | UserSubmittedStopFastestOrderAction
  | UserSubmittedStopOrderByItemsAction
  | UserSubmittedRestartOrderByItemsAction
  | UserSubmittedStopReleaseByItemsAction
  | UserSubmittedStopOrderByOptionSetsAction
  | UserSubmittedStopReleaseByOptionSetsAction
  | UserSubmittedRestartOrderByOptionSetsAction
  | UserSubmittedStopOrderByFilteredOptionsAction
  | UserSubmittedStopReleaseByFilteredOptionsAction
  | UserSubmittedStopOrderByStockCheckAction
  | UserSubmittedRestartOrderByStockCheckAction
  | UserSubmittedAutoPrintSettingAction
  | UserSubmittedUpdateDisplayImagesAction
  | UserSubmittedUpdateShopItemStoppedReasonsAction
  | UserSubmittedPushNotificationSettingAction
  | SystemUpdateShopItemStatusAction
  | SystemUpdateShopItemOptionStatusAction
  | SystemUpdateShopItemSelectStopReasonTypeAction
  | SystemWatchShopNotificationAction
  | ReducerUpdateShopDataAction
  | ReducerUpdateShopStaffAction
  | ReducerUpdateShopItemsAction
  | ReducerUpdateShopItemOptionsAction
  | ReducerUpdateShopItemParentCategoriesAction
  | ReducerUpdateShopBusinessAction
  | ReducerUpdateShopInvitationCodeAction
  | ReducerUpdateAutoPrintSettingAction
  | ReducerEnableImmediateDeliveryPausedAction
  | ReducerUpdateDisplayImagesAction
  | ReducerUpdatePushNotificationSettingAction;
