import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconCancelOrder: React.FC<Props> = React.memo(({ color, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 174 174" fill="none" {...props}>
    <path
      d="M43.1097 85.131C35.0277 85.131 30.3624 78.3382 32.5304 70.5394L39.3592 46H63.1981L59.7768 70.5439C58.6883 78.3428 51.1917 85.131 43.1097 85.131Z"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M72.3767 85.131C64.2947 85.131 58.6871 78.3382 59.7757 70.5394L63.197 46H87.0359L87.0222 70.5439C87.0176 78.3428 80.4632 85.131 72.3767 85.131Z"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M101.651 85.131C93.5686 85.131 87.0189 78.3382 87.0234 70.5394L87.0372 46H110.876L114.265 70.5439C115.349 78.3428 109.733 85.131 101.651 85.131Z"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M131.174 85.131C123.092 85.131 115.591 78.3382 114.507 70.5394L111.086 46H134.925L141.749 70.5439C143.922 78.3428 139.256 85.131 131.174 85.131Z"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.0469 83.6758V129.399C42.0469 130.504 42.9423 131.399 44.0469 131.399H130.47C131.575 131.399 132.47 130.504 132.47 129.399V83.6758"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
    />
    <rect
      x="57.9531"
      y="94.5625"
      width="20.0941"
      height="36.8391"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
      strokeLinejoin="round"
    />
    <rect
      x="90.6094"
      y="94.5625"
      width="28.4666"
      height="22.6058"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
      strokeLinejoin="round"
    />
    <circle cx="87" cy="87" r="80" stroke={colorsConst.ICON.DANGER} strokeWidth="14" />
    <path d="M31.3164 30.0664L142.686 141.436" stroke={colorsConst.ICON.DANGER} strokeWidth="14" />
  </svg>
));
