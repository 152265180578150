import React, { useCallback } from "react";
import CalendarComponent, { Detail } from "react-calendar";
import styled from "styled-components";
import { DateUtils } from "utils/DateUtils";
import { colorsConst, zIndexConst } from "../../styles/const";
import { fontSize } from "../../styles/mixins";

const Container = styled.div<{ isOpen: boolean }>`
  ${p => (!p.isOpen ? "display: none;" : "")}
`;

const CalendarContainer = styled(CalendarComponent)`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background: ${colorsConst.BACKGROUND.DISABLED};
  z-index: ${zIndexConst.MODAL - 1};
  .react-calendar__tile:disabled {
    color: ${colorsConst.TEXT.SECONDARY};
  }
  .react-calendar__navigation {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    button,
    button:enabled:hover {
      appearance: none;
      border: none;
      background: ${colorsConst.BACKGROUND.WHITE};
      &[disabled] {
        background: ${colorsConst.BUTTON.BACKGROUND.DISABLED};
      }
    }
    &__label {
      flex-shrink: 1;
      flex-grow: 1;
      background: ${colorsConst.BACKGROUND.WHITE} !important;
      ${fontSize.LARGE};
      line-height: 2.5;
    }
    &__next-button,
    &__prev-button {
      flex-shrink: 0;
      flex-grow: 0;
      width: 2em;
      ${fontSize.XXLARGE};
    }
    &__next2-button,
    &__prev2-button {
      display: none;
    }
  }
  .react-calendar__month-view {
    &__weekdays {
      background: ${colorsConst.BUTTON.BACKGROUND.DEFAULT};
      &__weekday {
        font-weight: bold;
        ${fontSize.LARGE};
        line-height: 2;
        color: ${colorsConst.TEXT.WHITE};
        text-align: center;
        border-right: solid 1px ${colorsConst.BORDER.THIN};
        &:nth-last-child(1) {
          border: 0;
        }
        abbr {
          text-decoration: none;
        }
      }
    }
    &__days {
      button {
        line-height: 2.5;
        white-space: nowrap;
        appearance: none;
        border: none;
        background: ${colorsConst.BUTTON.BACKGROUND.WHITE};
        &[disabled] {
          background: ${colorsConst.BUTTON.BACKGROUND.DISABLED};
        }
      }
      &__day {
        margin: 0;
        font-weight: bold;
        ${fontSize.DEFAULT};
        color: ${colorsConst.TEXT.PRIMARY};
        border-right: 1px solid ${colorsConst.BORDER.DEFAULT};
        border-bottom: 1px solid ${colorsConst.BORDER.DEFAULT};
        &--weekend {
          color: ${colorsConst.TEXT.BLACK};
        }
        &--neighboringMonth {
          abbr {
            opacity: 0.5;
            color: ${colorsConst.TEXT.SECONDARY};
          }
        }
      }
    }
  }
`;

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: ${zIndexConst.MODAL - 2};
`;

type Props = {
  isOpen: boolean;
  handleClick?: (value: Date) => void;
  handleClickBackDrop?: () => void;
  maxDate?: Date;
  minDate?: Date;
  maxDetail?: Detail;
  minDetail?: Detail;
};

export const Calendar: React.FC<Props> = React.memo(
  ({ isOpen, handleClick, handleClickBackDrop, maxDate, minDate, maxDetail, minDetail }) => {
    const formatterCallback = useCallback(
      (locale: string, date: Date) => DateUtils.dateToString(date, "D"),
      [],
    );
    return (
      <Container isOpen={isOpen}>
        <CalendarContainer
          onClickDay={handleClick}
          maxDate={maxDate}
          minDate={minDate}
          maxDetail={maxDetail}
          minDetail={minDetail}
          formatDay={formatterCallback}
        />
        <BackDrop onClick={handleClickBackDrop} />
      </Container>
    );
  },
);
