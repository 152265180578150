import { put, fork, call } from "redux-saga/effects";
import { API_KEY, ReadNewsRequest, ReadNewsResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateNewsList, UserSubmittedReadNewsAction } from "../actions";

export function* userSubmittedReadNewsSaga(action: UserSubmittedReadNewsAction) {
  try {
    const params: ReadNewsRequest = { news_id: action.payload.id };
    const { result, error }: { result: ReadNewsResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.READ_NEWS,
      params,
    );
    if (result && !error) {
      yield put(updateNewsList(result.news_list));
    } else {
      throw error;
    }
  } catch (exception) {
    yield fork(commonErrorSaga);
  }
}
