import { fork, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import {
  userAccessedToPageThatNeedsSalesSaga,
  userAccessedToPageThatNeedsMonthlySalesSaga,
} from "./sagas";

export function* salesSaga() {
  yield fork(watchUserAccessedToPageThatNeedsOrderListSaga);
  yield fork(watchUserAccessedToPageThatNeedsMonthlySalesSaga);
}

function* watchUserAccessedToPageThatNeedsOrderListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SALES,
    userAccessedToPageThatNeedsSalesSaga,
  );
}

function* watchUserAccessedToPageThatNeedsMonthlySalesSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_MONTHLY_SALES,
    userAccessedToPageThatNeedsMonthlySalesSaga,
  );
}
