import styled from "styled-components";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";

const HeaderContainer = styled.div`
  position: relative;
  z-index: 2;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  background-color: ${colorsConst.BACKGROUND.WHITE};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
`;

const HeaderInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.25em 0;
`;

const HeaderTitle = styled.div`
  ${fontSize.XLARGE};
`;

const BackButtonContainer = styled.div`
  position: absolute;
  z-index: 3;
  top: 0.75em;
  left: 0.75em;
  ${fontSize.XXLARGE};
`;

export { HeaderContainer, HeaderInner, HeaderTitle, BackButtonContainer };
