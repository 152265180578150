import { fork, call, put } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { API_KEY } from "apis";
import { updateSnackBar } from "modules/app/actions";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import {
  UpdateBusinessBusyTimeRequest,
  UpdateBusinessBusyTimeResponse,
} from "apis/updateBusinessBusyTime";
import { updateShopBusiness } from "../actions";

export function* updateBusinessBusyTimeSaga(
  params: UpdateBusinessBusyTimeRequest,
  withUpdateNationalHoliday: boolean = false,
) {
  try {
    const { result, error }: { result: UpdateBusinessBusyTimeResponse; error: unknown } =
      yield call(commonApiSaga, API_KEY.UPDATE_BUSINESS_BUSY_TIME, params);
    if (result && !error) {
      yield put(replace("/shopBusiness"));
      yield put(updateShopBusiness(result.shop_business));
      if (withUpdateNationalHoliday) {
        yield put(updateSnackBar("祝日の忙しい時間帯を編集しました"));
      } else {
        yield put(updateSnackBar("忙しい時間帯を編集しました"));
      }
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
