import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userAccessedToPageThatNeedsShopData } from "modules/shop/actions";
import { Contents } from "components/atoms/Contents";
import { Article } from "components/organisms/Article";
import { ShopDetail } from "components/organisms/ShopDetail";
import { OrderStopModal } from "components/organisms/OrderStopModal";

type Props = {};

export const ShopTemplate: React.FC<Props> = React.memo(() => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopData());
  }, [dispatch]);

  return (
    <>
      <Article
        withHeader
        title="店舗情報の確認"
        goBack="/setting"
        withNavigation
        activePath="SETTING"
      >
        <Contents>
          <ShopDetail />
        </Contents>
      </Article>
      <OrderStopModal />
    </>
  );
});
