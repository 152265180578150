import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { NewsItem } from "records/NewsItem";
import { userSubmittedReadNews } from "modules/information/actions";
import { colorsConst } from "styles/const";
import { Text } from "components/atoms/Text";

const Container = styled.div`
  position: relative;
  padding: 1em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
  border-radius: 1em;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  & + & {
    margin-top: 1em;
  }
`;

const UnreadIcon = styled.div`
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  background-color: ${colorsConst.BACKGROUND.DANGER};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 1em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid ${colorsConst.BORDER.THIN};
`;

const TitleContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const DatetimeContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const DetailContainer = styled.div<{ isOpen: boolean }>`
  white-space: pre-wrap;
  ${p =>
    !p.isOpen
      ? `
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  `
      : ""}
`;

const ReadmoreButton = styled.div`
  text-align: right;
  color: ${colorsConst.TEXT.LINK};
  text-decoration: underline;
`;

type Props = {
  item: NewsItem;
};

export const NewsListItem: React.FC<Props> = React.memo(({ item }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (!isOpen && !item.is_read) {
      dispatch(userSubmittedReadNews(item.id));
    }
    setIsOpen(!isOpen);
  }, [dispatch, item, isOpen]);

  return (
    <Container onClick={handleClick}>
      {!item.is_read ? <UnreadIcon /> : undefined}
      <Header>
        <TitleContainer>
          <Text size="LARGE" weight={item.is_read ? "REGULAR" : "BOLD"}>
            {item.title}
          </Text>
        </TitleContainer>
        <DatetimeContainer>
          <Text color="SECONDARY">{item.start_datetime}</Text>
        </DatetimeContainer>
      </Header>
      <DetailContainer isOpen={isOpen}>{item.detail_text}</DetailContainer>
      <ReadmoreButton>{isOpen ? "閉じる" : "もっと読む"}</ReadmoreButton>
    </Container>
  );
});
