import { fork, call, put } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { API_KEY, UpdateBusinessTimeRequest, UpdateBusinessTimeResponse } from "apis";
import { BusinessWeekTime, BusinessType } from "records/BusinessWeekTime";
import { updateSnackBar } from "modules/app/actions";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateShopBusiness, UserSubmittedUpdateBusinessTimeAction } from "../actions";

export function* userSubmittedUpdateBusinessTimeSaga(
  action: UserSubmittedUpdateBusinessTimeAction,
) {
  try {
    const { business_week_time } = action.payload.values;
    const formattedBusinessWeekTime = business_week_time.reduce(
      (acc: BusinessWeekTime[], crr: BusinessWeekTime) => {
        acc.push({
          ...crr,
          business_type: parseInt(crr.business_type.toString(), 10) as BusinessType,
        });
        return acc;
      },
      [],
    );
    const params: UpdateBusinessTimeRequest = { business_week_time: formattedBusinessWeekTime };
    const { result, error }: { result: UpdateBusinessTimeResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.UPDATE_BUSINESS_TIME,
      params,
    );
    if (result && !error) {
      yield put(replace("/shopBusiness"));
      yield put(updateShopBusiness(result.shop_business));
      yield put(updateSnackBar("曜日別の営業時間を編集しました"));
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
