import React, { useCallback } from "react";
import styled from "styled-components";
import { BusinessType, BUSINESS_TYPE } from "records/BusinessWeekTime";
import { Text } from "components/atoms/Text";

const Container = styled.div``;

const Label = styled.label`
  display: block;
`;

type Props = {
  current: BusinessType;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, type: BusinessType) => void;
};

export const BusinessNationalHolidaySelectField: React.FC<Props> = React.memo(
  ({ current, handleChange }) => {
    const handleSet = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(event, BUSINESS_TYPE.ONCE);
      },
      [handleChange],
    );
    const handleUnset = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(event, BUSINESS_TYPE.UNSET);
      },
      [handleChange],
    );
    return (
      <Container>
        <Label htmlFor="unset">
          <input
            type="radio"
            id="unset"
            name="setNatinalHoliday"
            checked={`${current}` === `${BUSINESS_TYPE.UNSET}`}
            onChange={handleUnset}
          />
          <Text size="SMALL">設定しない</Text>
        </Label>
        <Label htmlFor="set">
          <input
            type="radio"
            id="set"
            name="setNatinalHoliday"
            checked={`${current}` !== `${BUSINESS_TYPE.UNSET}`}
            onChange={handleSet}
          />
          <Text size="SMALL">祝日の営業時間を設定する</Text>
        </Label>
      </Container>
    );
  },
);
