import React, { useMemo, useContext } from "react";
import styled from "styled-components";
import { DateUtils } from "utils/DateUtils";
import { OrderData } from "records/OrderData";
import { ShopData } from "records/ShopData";
import { OrderModel } from "modules/order/model";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { PickerInfo } from "components/atoms/PickerInfo";
import { OrderDetailInfo } from "./OrderDetailInfo";
import { OrderDetailItem } from "./OrderDetailItem";
import { OrderState } from "./OrderState";
import { ReceiveInfo } from "./ReceiveInfo";
import { PaymentInfo } from "./PaymentInfo";
import { PaymentCancelItemList } from "./PaymentCancelItemList";
import { ExtendCookingTime } from "./ExtendCookingTime";
import { HoldOrder } from "./HoldOrder";
import { OrderDetailContext } from "./OrderDetailContext";

const Container = styled.div`
  margin: -1em;
`;

const Inner = styled.div`
  background-color: ${colorsConst.BACKGROUND.WHITE};
  padding-bottom: 1em;
`;

const OrderInfoContainer = styled.div`
  padding: 0.5em;
`;

const ReceiveInfoContainer = styled.div`
  padding: 0 1em 1em;
  border-bottom: 1px solid ${colorsConst.BORDER.THIN};
`;

const OrderItemContainer = styled.div`
  border-bottom: 1px solid ${colorsConst.BORDER.THIN};
`;

const OrderDateTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.25em 0;
  border-top: 1px solid ${colorsConst.BORDER.THIN};
  border-bottom: 1px solid ${colorsConst.BORDER.THIN};
`;
const OrderDateTimeKey = styled.div`
  ${fontSize.SMALL};
  color: ${colorsConst.TEXT.SECONDARY};
`;
const OrderDateTimeValue = styled.div`
  margin-left: 0.5em;
  ${fontSize.SMALL};
`;

const PaymentCancelItemListContainer = styled.div`
  padding: 0 1em 1em;
`;

type Props = {};

export const OrderDetail: React.FC<Props> = React.memo(() => {
  const {
    orderData,
    shopData,
    canChangeItemCount = false,
    draftItems,
    draftTotalItemCounts,
    shopItemParentCategories,
    isContainRequiredItemOrder = false,
    isAllRequiredItemsChecked = false,
    isSimulator = false,
    isHistory = false,
    isRetail = false,
    isGrocery = false,
    isBusy = false,
    submitting = false,
    setDraftItems,
    handleChangePickStatus,
    handleChangeSelectStopReasonShopItems,
  } = useContext(OrderDetailContext);

  const canDisplayPickerInfo = useMemo(
    () => OrderData.canDisplayPickerInfo(isGrocery, orderData),
    [isGrocery, orderData],
  );

  const canDisplayCheckBox = useMemo(
    () => OrderModel.canDisplayPickedCheckBox(orderData, shopData),
    [orderData, shopData],
  );

  const canDisplayExtendCookingTime = useMemo(
    () =>
      !ShopData.isLazona(shopData.shop_commercial_facility_type) &&
      OrderModel.canDisplayExtendCookingTime(orderData, shopData, isBusy),
    [orderData, shopData, isBusy],
  );

  const canDisplayHoldOrder = useMemo(
    () =>
      !isGrocery &&
      (isRetail
        ? OrderData.isHoldOrder(orderData.order_state)
        : OrderData.isPendingOrder(orderData.order_state)),
    [orderData, isRetail, isGrocery],
  );

  const canDisplayPaymentCancelItems = useMemo(
    () => orderData.payment_cancel_item_list.length > 0,
    [orderData],
  );

  return (
    <Container>
      <OrderState
        orderData={orderData}
        shopData={shopData}
        isGrocery={isGrocery}
        isSimulator={isSimulator}
      />
      {canDisplayPickerInfo ? (
        <PickerInfo>{`対応者：${orderData.staff.display_name}`}</PickerInfo>
      ) : undefined}
      <Inner>
        <OrderInfoContainer>
          <OrderDetailInfo />
        </OrderInfoContainer>
        <ReceiveInfoContainer>
          <ReceiveInfo />
        </ReceiveInfoContainer>
        <OrderItemContainer>
          {draftItems.map(draftItem => (
            <OrderDetailItem
              key={draftItem.order_shop_item_id_group}
              draftItem={draftItem}
              orderData={orderData}
              shopData={shopData}
              canChangeItemCount={canChangeItemCount}
              canDisplayCheckBox={canDisplayCheckBox}
              draftItems={draftItems}
              shopItemParentCategories={shopItemParentCategories}
              isContainRequiredItemOrder={isContainRequiredItemOrder}
              isAllRequiredItemsChecked={isAllRequiredItemsChecked}
              isHistory={isHistory}
              isGrocery={isGrocery}
              submitting={submitting}
              setDraftItems={setDraftItems}
              handleChangePickStatus={handleChangePickStatus}
              handleChangeSelectStopReasonShopItems={handleChangeSelectStopReasonShopItems}
            />
          ))}
        </OrderItemContainer>
        <PaymentInfo
          orderData={orderData}
          shopData={shopData}
          draftItems={draftItems}
          draftTotalItemCounts={draftTotalItemCounts}
        />
        {canDisplayPaymentCancelItems ? (
          <PaymentCancelItemListContainer>
            <PaymentCancelItemList
              items={orderData.payment_cancel_item_list}
              paymentCancelPrice={orderData.payment_cancel_price}
            />
          </PaymentCancelItemListContainer>
        ) : undefined}
        <OrderDateTimeContainer>
          <OrderDateTimeKey>注文日時：</OrderDateTimeKey>
          <OrderDateTimeValue>
            {DateUtils.dateToString(orderData.order_date, "YYYY年M月D日 HH:mm")}
          </OrderDateTimeValue>
        </OrderDateTimeContainer>
      </Inner>
      {canDisplayExtendCookingTime ? <ExtendCookingTime /> : undefined}
      {canDisplayHoldOrder ? <HoldOrder /> : undefined}
    </Container>
  );
});
