import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  invert?: boolean;
};

export const IconOrderList: React.FC<Props> = React.memo(({ invert }) => {
  const color = invert ? colorsConst.WHITE : colorsConst.ICON.DEFAULT;
  return (
    <svg width="1em" height="1em" viewBox="0 0 34 34" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M1,9.8c0.2-0.3,0.6-0.5,1-0.5h23.5c0.4,0,0.7,0.2,0.9,0.5c0.2,0.3,0.3,0.7,0.2,1L24.1,22 c-0.1,0.5-0.6,0.9-1.2,0.9H5.2c-0.5,0-1-0.4-1.2-0.9L0.8,10.9C0.7,10.5,0.8,10.1,1,9.8z M3.6,11.7l2.5,8.8h15.9l2.1-8.8H3.6z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M26.2,2.3c0.1-0.5,0.6-0.9,1.2-0.9H32c0.7,0,1.2,0.5,1.2,1.2S32.7,3.8,32,3.8h-3.7L23,26.4 c-0.1,0.5-0.6,0.9-1.2,0.9H6.4c-0.7,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2h14.6L26.2,2.3z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M6.4,27.3c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5C7.8,28,7.2,27.3,6.4,27.3z  M2.5,28.8c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9c0,2.1-1.7,3.9-3.9,3.9C4.2,32.6,2.5,30.9,2.5,28.8z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M21.2,27.3c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5C22.6,28,22,27.3,21.2,27.3z  M17.3,28.8c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9c0,2.1-1.7,3.9-3.9,3.9C19,32.6,17.3,30.9,17.3,28.8z"
      />
    </svg>
  );
});
