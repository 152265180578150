/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ShopData } from "records/ShopData";
import { systemWatchNotification, systemUnwatchNotification } from "modules/order/actions";
import { DEFAULT_POLLING_INTERVAL } from "modules/order/model";
import { shopDataSelector } from "modules/shop/selectors";

type Props = {
  children: React.ReactNode;
  watch: boolean;
  pollingInterval?: number;
};

export const WatchOrderContainer: React.FC<Props> = React.memo(
  ({ children, watch, pollingInterval = DEFAULT_POLLING_INTERVAL }) => {
    const shopData = useSelector(shopDataSelector);

    const { id, is_firestore_notification } = useMemo(() => shopData, [shopData]);

    const dispatch = useDispatch();

    useEffect(() => {
      if (ShopData.existsShopData(id)) {
        if (watch) {
          dispatch(systemWatchNotification(id, is_firestore_notification, pollingInterval));
        } else {
          dispatch(systemUnwatchNotification());
        }
      }
    }, [dispatch, watch, pollingInterval, id, is_firestore_notification]);

    return <>{children}</>;
  },
);
