/* eslint-disable default-param-last */
import { ActionTypes, OrderActions } from "./actions";
import { OrderState, OrderModel } from "./model";

export default function reducer(state: OrderState = OrderModel.initialState, action: OrderActions) {
  switch (action.type) {
    case ActionTypes.R_UPDATE_ORDER_LIST:
      return OrderModel.updateOrderList(state, action.payload.orderList);
    case ActionTypes.R_UPDATE_ORDER_DETAIL:
      return OrderModel.updateOrderDetail(state, action.payload.order);
    case ActionTypes.R_UPDATE_BUSY_FLAG:
      return OrderModel.updateBusyFlag(state, action.payload.flag);
    case ActionTypes.R_UPDATE_ORDER_HISTORY:
      return OrderModel.updateOrderHistory(state, action.payload.orderList);
    case ActionTypes.R_UPDATE_ORDER_NOTIFICATION_STACK:
      return OrderModel.updateNotificationStack(state, action.payload.notification);
    case ActionTypes.R_UPDATE_ORDER_ITEM_TOTAL_PRICE:
      return OrderModel.updateOrderItemTotalPrice(state, action.payload.orderItemTotalPrice);
    case ActionTypes.R_UPDATE_ORDER_LIST_TAB_TYPE:
      return OrderModel.updateOrderListTabType(state, action.payload.tabType);
    default:
      return state;
  }
}
