import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  invert?: boolean;
  color?: string;
};

export const IconShopItem: React.FC<Props> = React.memo(({ invert, color }) => {
  const fillColor = invert ? colorsConst.WHITE : color || colorsConst.ICON.DEFAULT;
  return (
    <svg width="1em" height="1em" viewBox="0 0 34 34" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M8.9,15.7c0.7,0,1.2,0.5,1.2,1.2v14c0,0.7-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2v-14C7.7,16.2,8.2,15.7,8.9,15.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M25.9,2.7c0.7,0,1.2,0.5,1.2,1.2v27c0,0.7-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2v-27C24.7,3.2,25.2,2.7,25.9,2.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M20.9,2.7c0.7,0,1.2,0.5,1.2,1.2v7.5c0,1.5,1.3,2.8,2.8,2.8h2c1.5,0,2.8-1.3,2.8-2.8V3.9 c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2v7.5c0,2.9-2.3,5.2-5.2,5.2h-2c-2.9,0-5.2-2.3-5.2-5.2V3.9C19.7,3.2,20.2,2.7,20.9,2.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M8.9,15c2.4,0,4.6-2.1,4.6-5.1c0-3.2-2.3-5.6-4.6-5.6S4.3,6.7,4.3,9.9C4.3,12.9,6.5,15,8.9,15z M8.9,17.4 c3.9,0,7-3.4,7-7.5c0-4.1-3-8-7-8s-7,3.9-7,8C1.9,14,5,17.4,8.9,17.4z"
      />
    </svg>
  );
});
