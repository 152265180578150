export type OrderUserInfo = Readonly<{
  nick_name: string;
  tel: string;
  is_menu_pass_member: boolean;
}>;

const initialState: OrderUserInfo = {
  nick_name: "",
  tel: "",
  is_menu_pass_member: false,
};

const create = (args: Partial<OrderUserInfo> = {}) => ({
  ...initialState,
  ...args,
});

export const OrderUserInfo = Object.freeze({
  initialState,
  create,
});
