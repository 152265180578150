import React, { useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { BusinessTime } from "records/BusinessTime";
import { BusinessBusyTime } from "records/BusinessBusyTime";
import {
  UpdateBusinessBusyTimeForm,
  updateBusinessBusyTimeFormValidationSchema,
} from "forms/updateBusinessBusyTime";
import { useValidationSchema } from "forms/utils/useValidationSchema";
import { API_KEY } from "apis";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { userSubmittedUpdateBusinessBusyTime } from "modules/shop/actions";
import { businessBusyTimeSelector } from "modules/shop/selectors";
import { DateUtils } from "utils/DateUtils";
import { colorsConst, fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Button } from "components/atoms/Button";
import { BusinessBusyTimeField } from "components/molecules/BusinessBusyTimeField";

const Container = styled.form`
  padding: 0.5em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
`;

const WeekDayContainer = styled.div`
  ${fontSize.LARGE};
  font-weight: ${fontWeightsConst.MEDIUM};
`;

const ButtonContainer = styled.div`
  padding: 1em;
`;

const isConnectedApiSelector = isConnectedApiSelectorFactory([API_KEY.UPDATE_BUSINESS_BUSY_TIME]);

type Props = {
  week: number;
  canSubmit: boolean;
};

export const ShopBusinessBusyTimeEdit: React.FC<Props> = React.memo(({ week, canSubmit }) => {
  const validate = useValidationSchema(updateBusinessBusyTimeFormValidationSchema);
  const businessBusyTime = useSelector(businessBusyTimeSelector);
  const loading = useSelector(isConnectedApiSelector);

  const targetBusyTimes = useMemo(
    () => businessBusyTime.find(i => i.week === week),
    [week, businessBusyTime],
  );

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values: UpdateBusinessBusyTimeForm) => {
      dispatch(userSubmittedUpdateBusinessBusyTime(values));
    },
    [dispatch],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        business_busy_time:
          targetBusyTimes ||
          BusinessBusyTime.create({
            week,
            busy_times: [BusinessTime.create()],
          }),
      }}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ handleSubmit, valid, submitting, values, dirty }) => (
        <>
          <Container>
            <WeekDayContainer>{DateUtils.getWeekdayStringByWeekNumber(week)}</WeekDayContainer>
            <BusinessBusyTimeField fieldArrayName="business_busy_time[busy_times]" />
          </Container>
          <ButtonContainer>
            <Button
              appearance="primary"
              type="button"
              disabled={!dirty || !valid}
              loading={loading || submitting}
              handleClick={handleSubmit}
            >
              保存する
            </Button>
          </ButtonContainer>
        </>
      )}
    </Form>
  );
});
