/* eslint-disable react/jsx-no-useless-fragment */
import React, { createContext } from "react";
import { STAFF_ROLE_TYPE, StaffRoleType } from "../../records/ShopStaff";
import { RbacUtils, PerformanceType, PermissionData } from "../../utils/RbacUtils";

export type PermissionContextValue = {
  id: number;
  role: StaffRoleType;
};

export const PermissionContext = createContext<PermissionContextValue>({
  id: 0,
  role: STAFF_ROLE_TYPE.NORMAL,
});

export const PermissionProvider = PermissionContext.Provider;

type Props = {
  children: React.ReactNode;
  role: StaffRoleType;
  performance: PerformanceType;
  data?: PermissionData;
  yes?: () => void;
  no?: () => void;
};

export const PermissionContainer: React.FC<Props> = React.memo(
  ({ children, role, performance, data, yes, no }) => (
    <>
      {!RbacUtils.check(role, performance, data)
        ? typeof no !== "undefined"
          ? no()
          : undefined
        : typeof yes !== "undefined"
        ? yes()
        : children}
    </>
  ),
);
