import { call } from "redux-saga/effects";
import { API_KEY, GetOrderDetailRequest, GetOrderDetailResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";

export function* getOrderDetailSaga(orderNo: string) {
  try {
    const params: GetOrderDetailRequest = {
      order_no: orderNo,
    };
    const { result, error }: { result: GetOrderDetailResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.GET_ORDER_DETAIL,
      params,
    );
    if (result && !error) {
      return { result };
    }
    throw error;
  } catch (error: unknown) {
    return { error };
  }
}
