import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Switch } from "react-router";
import styled from "styled-components";
import { ReduxModel } from "reducer";

const Wrapper = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: streach;
`;

const Container = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: streach;
  &.TransitionWrapper-enter,
  &.TransitionWrapper-enter-active,
  &.TransitionWrapper-exit,
  &.TransitionWrapper-exit-active,
  &.TransitionWrapperPush-enter,
  &.TransitionWrapperPush-enter-active,
  &.TransitionWrapperPush-exit,
  &.TransitionWrapperPush-exit-active,
  &.TransitionWrapperPop-enter,
  &.TransitionWrapperPop-enter-active,
  &.TransitionWrapperPop-exit,
  &.TransitionWrapperPop-exit-active {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &.TransitionWrapper-enter,
  &.TransitionWrapper-enter-active,
  &.TransitionWrapperPush-enter,
  &.TransitionWrapperPush-enter-active,
  &.TransitionWrapperPop-exit,
  &.TransitionWrapperPop-exit-active {
    z-index: 2;
  }
  &.TransitionWrapperPush-enter + .TransitionWrapperPop-exit,
  &.TransitionWrapperPush-enter-active + .TransitionWrapperPop-exit-active {
    z-index: 1;
  }
  &.TransitionWrapper-exit,
  &.TransitionWrapper-exit-active,
  &.TransitionWrapperPush-exit,
  &.TransitionWrapperPush-exit-active,
  &.TransitionWrapperPop-enter,
  &.TransitionWrapperPop-enter-active {
    z-index: 1;
  }
  &.TransitionWrapperPop-enter + .TransitionWrapperPush-exit,
  &.TransitionWrapperPop-enter-active + .TransitionWrapperPush-exit-active {
    z-index: 2;
  }
`;

const locationSelector = (state: ReduxModel) => state.router.location;
const routerActionTypeSelector = (state: ReduxModel) => state.router.action;

type Props = React.HTMLAttributes<HTMLDivElement> & {};

export const TransitionWrapper: React.FC<Props> = React.memo(({ children }) => {
  const location = useSelector(locationSelector);
  const routerActionType = useSelector(routerActionTypeSelector);

  const getCSSTransitionClassNames = useMemo(() => {
    let suffix = "";
    // eslint-disable-next-line no-restricted-globals
    if (history.state && history.state.state && history.state.state.isBack) {
      suffix = "Pop";
    } else {
      switch (routerActionType) {
        case "POP":
          suffix = "Pop";
          break;
        case "PUSH":
        case "REPLACE":
        default:
          suffix = "Push";
          break;
      }
    }
    return `TransitionWrapper${suffix}`;
  }, [routerActionType]);

  return (
    <TransitionGroup component={Wrapper}>
      <CSSTransition key={location.pathname} classNames={getCSSTransitionClassNames} timeout={250}>
        <Container>
          <Switch location={location}>{children}</Switch>
        </Container>
      </CSSTransition>
    </TransitionGroup>
  );
});
