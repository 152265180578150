import React, { useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Contents } from "components/atoms/Contents";
import { Article } from "components/organisms/Article";

type FaqContentsType = {
  title: string;
  article: string[];
};

const Container = styled.div``;

const Item = styled.div`
  background-color: ${colorsConst.BACKGROUND.WHITE};
  & + & {
    margin-top: 1em;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1em;
`;

const Title = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 1em;
`;

const IconContainer = styled.div<{ isOpen: boolean }>`
  flex-grow: 0;
  flex-shrink: 0;
  ${fontSize.XXLARGE};
  color: ${colorsConst.TEXT.LINK};
  ${p => (p.isOpen ? "transform: rotate(180deg)" : "")};
`;

const ContentsContainer = styled.div<{ isOpen: boolean }>`
  display: ${p => (p.isOpen ? "block" : "none")};
  padding: 1em;
  border-top: 1px solid ${colorsConst.BORDER.THIN};
`;

const Text = styled.div`
  & + & {
    margin-top: 1em;
  }
`;

type Props = {};

export const FaqTemplate: React.FC<Props> = React.memo(() => {
  const [openedIds, setOpenedIds] = useState(new Set<number>());

  const faqContents: FaqContentsType[] = useMemo(
    () => [
      {
        title: "配布された端末は電源をOFFにしたり、充電をしなくても良いですか？",
        article: [
          "配布された端末と店舗アプリは常に起動した状態にして、電源のOFFはしないでください。",
          "充電も常にした状態にして置いてください。",
        ],
      },
      {
        title: "通常の注文が入ってからの操作を教えてください",
        article: [
          "■通常注文",
          "①注文が入ると、新着情報通知の画面が音と共に表示されるのでタップしてください。",
          "②「注文詳細」から[注文を受け付ける]ボタンを選択してください。",
          "　何らかの事情で料理を提供できない場合には「キャンセル」を選択してください。",
          "③[受付済]という状態になりますので、残り時間に注意しながら各注文の準備とテイクアウトの準備をしてください。",
          "④お渡しする準備が全て完了したら[受付済]項目を選択、「注文詳細」から[準備完了を連絡する]または[配達員を探す]を選択してください。",
          " ",
          "準備完了後、または来店予定時刻にお客様がお店に到着します。",
          "お客様の名前、注文番号を確認してから商品の受渡しをしてください。",
          "受渡し間違いにはご注意ください。",
        ],
      },
      {
        title: "予約の注文が入ってからの操作を教えてください",
        article: [
          "■予約注文",
          "①注文が入ると、予約情報通知の画面が音と共に表示されるのでタップしてください。",
          "②「注文詳細」からご来店予定を確認して[注文を受け付ける]ボタンを選択してください。",
          "　何らかの事情で料理を提供できない場合には「キャンセル」を選択してください。",
          "③準備開始時間を設定します。",
          "④準備開始時間になったら準備開始通知の画面が音と共に表示されるのでタップしてください。",
          "⑤ご来店予定時間に合わせて各注文の準備とテイクアウトの準備をしてください。",
          "⑥お渡しする準備が全て完了したら「注文詳細」から[準備完了を連絡する]または[配達員を探す]ボタンを選択してください。",
          " ",
          "来店予定時刻にお客様がお店に到着します。",
          "お客様の名前、注文番号を確認してから商品の受渡しをしてください。",
          "受渡し間違いにはご注意ください。",
        ],
      },
      {
        title: "品切れになったときはどうしたらいいですか？",
        article: [
          "①「商品一覧」で品切れになった商品を選択してください。",
          "②[受付設定を変更する]を選択してください。",
          "③[本日売り切れ]を選択してください。",
          " ",
          "上記を行いますと品切れ状態となり、注文ができなくなり、翌営業日になると該当の商品は注文可能となります。",
        ],
      },
      {
        title: "臨時休業や貸し切りで注文を受け付けられなくなったときはどうしたらいいですか？",
        article: [
          "①「注文一覧」下部にある[店舗受付設定]を選択してください。",
          "②店舗の受付を停止する理由を選択して[店舗受付を停止する]を選択してください。",
          " ",
          "上記を行いますと受付不可状態となり、翌営業日に再開されます。",
        ],
      },
      {
        title: "注文受付後に、何らかの理由で料理を提供できなくなったらどうしたらいいですか？",
        article: [
          "原則的に、料理を提供できることを前提に注文を受けていただいていますが、やむを得ない事情で提供ができなくなった場合には、menu運営サポートまでご連絡ください。",
          "電話：0570-053-150",
        ],
      },
      {
        title: "お客様が受取りに来れなくなったときはどうしたらいいですか？",
        article: [
          "準備中や準備完了後にお客様が受取りに来れなくなった場合、売上はキャンセルされません。",
          "受取り可能時間から2時間、または当日の営業時間の終わりまでは受取りに備えて準備いただき、それ以降は申し訳ありませんが廃棄をしてください。",
        ],
      },
      {
        title: "売上はいつ振り込まれますか？",
        article: [
          "合計で5000円以上に達した売上につきましては、月末締め・翌月末振込でお支払いたします。",
          "振込時にはシステム手数料、カード手数料、振込手数料を差し引かせていただいておりますのでご了承ください。",
        ],
      },
      {
        title: "振込先の口座が変更になった場合どうすればいいですか？",
        article: [
          "振込先口座などの情報を変更したい場合には、menu運営サポートまでご連絡ください。",
          "メール：sup@menu.inc",
        ],
      },
    ],
    [],
  );

  const handleClick = useCallback(
    (index: number) => {
      const newOpenedId = new Set(openedIds);
      openedIds.has(index) ? newOpenedId.delete(index) : newOpenedId.add(index);
      setOpenedIds(newOpenedId);
    },
    [openedIds],
  );

  return (
    <Article withHeader title="よくある質問" goBack="/setting" withNavigation activePath="SETTING">
      <Contents>
        {faqContents.map((contents: FaqContentsType, index: number) => (
          <Item key={index}>
            <TitleContainer onClick={() => handleClick(index)}>
              <Title>{contents.title}</Title>
              <IconContainer isOpen={openedIds.has(index)}>
                <FontAwesomeIcon icon={faAngleDown} />
              </IconContainer>
            </TitleContainer>
            <ContentsContainer isOpen={openedIds.has(index)}>
              {contents.article.map((text, i) => (
                <Text key={`${index}_${i}`}>{text}</Text>
              ))}
            </ContentsContainer>
          </Item>
        ))}
      </Contents>
    </Article>
  );
});
