export type TextColors =
  | "PRIMARY"
  | "SECONDARY"
  | "TERTIARY"
  | "QUATERNARY"
  | "WARNING"
  | "DANGER"
  | "WHITE"
  | "BLACK"
  | "LINK";

const baseColors = {
  PRIMARY: "#393939",
  SECONDARY: "#8b8b8b",
  TERTIARY: "#4e4e4e",
  QUATERNARY: "#c0c0c0",
  WARNING: "#f5c344",
  DANGER: "#e61e0a",
  WHITE: "#fff",
  BLACK: "#000",
  LINK: "#069edf",
};

export const colorsConst = {
  TEXT: {
    PRIMARY: baseColors.PRIMARY,
    SECONDARY: baseColors.SECONDARY,
    TERTIARY: baseColors.TERTIARY,
    QUATERNARY: baseColors.QUATERNARY,
    WARNING: baseColors.WARNING,
    DANGER: baseColors.DANGER,
    WHITE: baseColors.WHITE,
    BLACK: baseColors.BLACK,
    LINK: baseColors.LINK,
  },
  BUTTON: {
    TEXT: {
      DEFAULT: baseColors.WHITE,
      PRIMARY: baseColors.LINK,
      SECONDARY: baseColors.WARNING,
      DISABLED: baseColors.QUATERNARY,
    },
    BACKGROUND: {
      DEFAULT: baseColors.SECONDARY,
      PRIMARY: baseColors.LINK,
      SECONDARY: baseColors.WARNING,
      TERTIARY: baseColors.TERTIARY,
      DANGER: baseColors.DANGER,
      DISABLED: baseColors.QUATERNARY,
      WHITE: baseColors.WHITE,
      BLACK: baseColors.BLACK,
    },
  },
  TAB: {
    DEFAULT: baseColors.QUATERNARY,
    ACTIVE: baseColors.TERTIARY,
  },
  ICON: {
    DEFAULT: baseColors.SECONDARY,
    ACTIVE: baseColors.PRIMARY,
    INVERT: baseColors.WHITE,
    DISABLED: baseColors.QUATERNARY,
    WHITE: baseColors.WHITE,
    DANGER: baseColors.DANGER,
  },
  BORDER: {
    DEFAULT: baseColors.SECONDARY,
    THIN: "#e3e0df",
    THICK: baseColors.TERTIARY,
    WHITE: baseColors.WHITE,
  },
  BACKGROUND: {
    PRIMARY: "#e9ecef",
    SECONDARY: "#f3f6f9",
    TERTIARY: baseColors.TERTIARY,
    DISABLED: baseColors.QUATERNARY,
    SUCCESS: baseColors.LINK,
    WARNING: baseColors.WARNING,
    DANGER: baseColors.DANGER,
    INFO: "#fffce2",
    OVERLAY: "rgba(0, 0, 0, 0.5)",
    WHITE: baseColors.WHITE,
    BLACK: baseColors.BLACK,
    CANCEL: baseColors.SECONDARY,
  },
  BADGE: {
    PRIMARY: baseColors.LINK,
    DANGER: baseColors.DANGER,
  },
  WARNING: baseColors.WARNING,
  DANGER: baseColors.DANGER,
  WHITE: baseColors.WHITE,
  BLACK: baseColors.BLACK,
} as const;
