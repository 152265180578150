import produce from "immer";
import { ShopData, SHOP_RELEASE_STATE } from "records/ShopData";
import { ShopStaff } from "records/ShopStaff";
import { ShopItemCategory } from "records/ShopItemCategory";
import { ShopItem, SOLD_OUT_FLAG, RELEASE_FLAG, ReasonForItemsStop } from "records/ShopItem";
import { ShopItemOptionSet, SubmittableOptionSetType } from "records/ShopItemOptionSet";
import { ShopItemOption } from "records/ShopItemOption";
import { ShopItemParentCategory } from "records/ShopItemParentCategory";
import { ShopBusiness } from "records/ShopBusiness";
import { BusinessBusyTime } from "records/BusinessBusyTime";
import { ShopInvitationCode } from "records/ShopInvitationCode";
import { AutoPrintSetting } from "records/AutoPrintSetting";
import { Utility } from "utils/Utility";

export const DEFAULT_SELECTED_PARENT_CATEGORY_ID = 0;

export type FilteredShopItemList = Readonly<{
  items: ShopItem[];
  categories: string[];
  groupCounts: number[];
}>;

export type FilteredOptionList = Readonly<{
  options: ShopItemOption[];
  optionSets: ShopItemOptionSet[];
  groupCounts: number[];
}>;

export const SHOP_ITEM_SUBMIT_TYPE = {
  RESTART: 1,
  STOP: 2,
  SUSPENSION: 3,
} as const;

export type ShopItemSubmitType = ValueOf<typeof SHOP_ITEM_SUBMIT_TYPE>;

export const displayableShopItemSubmitType: ReadonlyArray<ShopItemSubmitType> = [
  SHOP_ITEM_SUBMIT_TYPE.RESTART,
  SHOP_ITEM_SUBMIT_TYPE.STOP,
  SHOP_ITEM_SUBMIT_TYPE.SUSPENSION,
] as const;

export const ORDER_STOP_TYPE = {
  SHOP: "shop",
  ITEM: "item",
  OPTION_SET: "optonSet",
  ITEM_RELEASE: "itemRelease",
  OPTION_SET_RELEASE: "optonSetRelease",
} as const;

export type OrderStopType = ValueOf<typeof ORDER_STOP_TYPE>;

export const ORDER_STOP_REASON_BY_SHOP = {
  NOT_SELECTED: 0,
  SOLD_OUT: 1,
  BUSY: 2,
  TEMPORARY_CLOSED: 3,
  CLOSED: 4,
  OTHER: 99,
} as const;

export type OrderStopReasonByShopType = ValueOf<typeof ORDER_STOP_REASON_BY_SHOP>;

export const displayableOrderStopReasonByShop: ReadonlyArray<OrderStopReasonByShopType> = [
  ORDER_STOP_REASON_BY_SHOP.NOT_SELECTED,
  ORDER_STOP_REASON_BY_SHOP.SOLD_OUT,
  ORDER_STOP_REASON_BY_SHOP.BUSY,
  ORDER_STOP_REASON_BY_SHOP.TEMPORARY_CLOSED,
  ORDER_STOP_REASON_BY_SHOP.CLOSED,
  ORDER_STOP_REASON_BY_SHOP.OTHER,
] as const;

export type ShopReopenTimeValueType = 0 | 60 | 120;

export const displayableShopReopenTime: ReadonlyArray<ShopReopenTimeValueType> = [
  0, 60, 120,
] as const;

export const SET_PUSH_NOTIFICATION_TYPE = {
  BEFORE_ARRIVE: 1,
  ARRIVED: 2,
} as const;

export type SetPushNotificationType = ValueOf<typeof SET_PUSH_NOTIFICATION_TYPE>;

export type ShopState = Readonly<{
  shopData: ShopData;
  shopStaff: ShopStaff;
  shopItems: ShopItemCategory[];
  shopItemOptionSets: ShopItemOptionSet[];
  shopBusiness: ShopBusiness;
  shopInvitationCode: ShopInvitationCode;
  shopItemParentCategories: ShopItemParentCategory[];
}>;

const initialState: ShopState = {
  shopData: ShopData.create(),
  shopStaff: ShopStaff.create(),
  shopItems: [],
  shopItemOptionSets: [],
  shopBusiness: ShopBusiness.create(),
  shopInvitationCode: ShopInvitationCode.create(),
  shopItemParentCategories: [],
};

const updateShopData = (state: ShopState, value: ShopData) =>
  produce(state, draft => {
    draft.shopData = ShopData.create(value);
  });

const updateShopStaff = (state: ShopState, value: ShopStaff) =>
  produce(state, draft => {
    draft.shopStaff = ShopStaff.create(value);
  });

const updateShopItems = (state: ShopState, value: ShopItemCategory[]) =>
  produce(state, draft => {
    draft.shopItems = value.map(i => ShopItemCategory.create(i));
  });

const updateShopItemOptionSets = (state: ShopState, value: ShopItemOptionSet[]) =>
  produce(state, draft => {
    draft.shopItemOptionSets = value.map(i => ShopItemOptionSet.create(i));
  });

const updateShopItemParentCategories = (state: ShopState, value: ShopItemParentCategory[]) =>
  produce(state, draft => {
    draft.shopItemParentCategories = value.map(i => ShopItemParentCategory.create(i));
  });

const updateShopBusiness = (state: ShopState, value: ShopBusiness) =>
  produce(state, draft => {
    draft.shopBusiness = ShopBusiness.create(value);
  });

const updateShopInvitationCode = (state: ShopState, value: ShopInvitationCode) =>
  produce(state, draft => {
    draft.shopInvitationCode = ShopInvitationCode.create(value);
  });

const updateAutoPrintSetting = (state: ShopState, setting: AutoPrintSetting) =>
  produce(state, draft => {
    draft.shopData = ShopData.create({
      ...state.shopData,
      auto_print_setting: AutoPrintSetting.create(setting),
    });
  });

const updateDisplayImages = (state: ShopState, enable: boolean) =>
  produce(state, draft => {
    draft.shopData = ShopData.create({
      ...state.shopData,
      is_display_images: enable,
    });
  });

const updatePushNotificationSetting = (
  state: ShopState,
  payload: { notificationType: SetPushNotificationType; enable: boolean },
) => {
  if (payload.notificationType === SET_PUSH_NOTIFICATION_TYPE.BEFORE_ARRIVE) {
    return produce(state, draft => {
      draft.shopData = ShopData.create({
        ...state.shopData,
        is_crew_about_to_arrive_notification: payload.enable,
      });
    });
  }
  return produce(state, draft => {
    draft.shopData = ShopData.create({
      ...state.shopData,
      is_crew_arrived_notification: payload.enable,
    });
  });
};

const enableImmediateDeliveryPaused = (state: ShopState) =>
  produce(state, draft => {
    draft.shopData = ShopData.create({
      ...state.shopData,
      is_immediate_delivery_paused: true,
    });
  });

const existsShopData = (state: ShopState) => ShopData.existsShopData(state.shopData.id);

const existsShopBusinessData = (state: ShopState) => ShopBusiness.exists(state.shopBusiness);

const existsShopStaffData = (state: ShopState) => ShopStaff.existsShopStaff(state.shopStaff);

const getShopItemById = (shopItems: ShopItemCategory[], stopItemId: number) => {
  if (stopItemId === 0) {
    return undefined;
  }
  const stopItem = shopItems.find(shopItem =>
    shopItem.item_list.some(item => item.shop_item_id === stopItemId),
  );
  if (typeof stopItem === "undefined") {
    return undefined;
  }
  return stopItem.item_list.find(item => item.shop_item_id === stopItemId);
};

const getOptionSetById = (optionSetList: ShopItemOptionSet[], optionSetId: number) =>
  optionSetList.find(item => item.option_set_id === optionSetId);

const getOptionListByStopOtionIds = (optionList: ShopItemOption[], stopOptionIds: number[]) =>
  optionList.filter(option => stopOptionIds.includes(option.shop_option_id));

const getFilteredShopItemList = (
  shopItems: ShopItemCategory[],
  searchWord: string,
  soldOutFlag: boolean,
  selectedParentCategory: number,
): FilteredShopItemList =>
  shopItems.reduce(
    (acc: FilteredShopItemList, crr: ShopItemCategory) => {
      if (
        selectedParentCategory === DEFAULT_SELECTED_PARENT_CATEGORY_ID ||
        selectedParentCategory === crr.parent_category_id
      ) {
        /* 検索ワードをスペースで分割、正規表現で使用する記号をエスケープ */
        const escapedSearchWordArray = Utility.getEscapedSearchWordArray(searchWord);
        const filterdItemList = crr.item_list.filter(item => {
          if (escapedSearchWordArray.length > 0) {
            return escapedSearchWordArray.every(str => {
              const regex = new RegExp(str, "i");
              return item.item_name.match(regex) && (!soldOutFlag || item.sold_out_flag);
            });
          }
          return !soldOutFlag || item.sold_out_flag;
        });
        /* フィルタリングしたshopItemが1以上の場合itemsに連結、categoriesに追加、数量をgroupCountsに追加 */
        if (filterdItemList.length > 0) {
          return {
            items: acc.items.concat(filterdItemList),
            categories: acc.categories.concat(crr.category_name),
            groupCounts: acc.groupCounts.concat(filterdItemList.length),
          };
        }
      }
      return acc;
    },
    {
      items: [],
      categories: [],
      groupCounts: [],
    },
  );

const getFilteredOptionList = (
  optionSetList: ShopItemOptionSet[],
  searchWord: string,
  soldOutFlag: boolean,
): FilteredOptionList =>
  optionSetList.reduce(
    (acc: FilteredOptionList, crr: ShopItemOptionSet) => {
      /* soldOutFlagが正の場合はsold_out_flagが正のみのoptionを返す */
      const filteredOptionList = soldOutFlag
        ? crr.option_list.filter(o => o.is_order_stop)
        : crr.option_list;
      if (filteredOptionList.length > 0) {
        /* フィルタリングしたoptionが1以上の場合 */
        /* 検索ワードをスペースで分割、正規表現で使用する記号をエスケープ */
        const escapedSearchWordArray = Utility.getEscapedSearchWordArray(searchWord);
        if (escapedSearchWordArray.length > 0) {
          /* 検索ワードがある場合 */
          const isOptionSetMatch = escapedSearchWordArray.every(str => {
            const regex = new RegExp(str, "i");
            return crr.option_set_name.match(regex);
          });
          if (isOptionSetMatch) {
            /* optionSet名が検索ワードにマッチする場合、optionsに連結、optionSetsに追加、数量をgroupCountsに追加 */
            return {
              options: acc.options.concat(filteredOptionList),
              optionSets: acc.optionSets.concat(crr),
              groupCounts: acc.groupCounts.concat(filteredOptionList.length),
            };
          }
          /* 検索ワードにマッチするoption_nameをフィルタリング */
          const matchedOptionList = filteredOptionList.filter(option =>
            escapedSearchWordArray.every(str => {
              const regex = new RegExp(str, "i");
              return option.option_name.match(regex);
            }),
          );
          if (matchedOptionList.length > 0) {
            /* フィルタリングしたoptionが1以上の場合、optionsに連結、optionSetsに追加、数量をgroupCountsに追加 */
            return {
              options: acc.options.concat(matchedOptionList),
              optionSets: acc.optionSets.concat(crr),
              groupCounts: acc.groupCounts.concat(matchedOptionList.length),
            };
          }
        } else {
          /* 検索ワードがない場合、optionsに連結、optionSetsに追加、数量をgroupCountsに追加 */
          return {
            options: acc.options.concat(filteredOptionList),
            optionSets: acc.optionSets.concat(crr),
            groupCounts: acc.groupCounts.concat(filteredOptionList.length),
          };
        }
      }
      /* フィルタリングしたoptionが0の場合、またはoptionSet名が検索ワードにマッチしない場合次へ */
      return acc;
    },
    {
      options: [],
      optionSets: [],
      groupCounts: [],
    },
  );

const getDisplayableOptionSetList = (filteredOptionList: FilteredOptionList) => {
  const { options, optionSets, groupCounts } = filteredOptionList;
  let i = 0;
  return optionSets.reduce((acc: ShopItemOptionSet[], crr: ShopItemOptionSet, index) => {
    const targetOptionSet = ShopItemOptionSet.create({
      ...crr,
      option_list: options.slice(i, i + groupCounts[index]),
    });
    i += groupCounts[index];
    acc.push(targetOptionSet);
    return acc;
  }, []);
};

const getUpdatedShopItemListByStopOrRestartShopItem = (
  itemList: ShopItem[],
  targetShopItemIds: number[],
  submitType: ShopItemSubmitType,
  withOptionStop: boolean,
) =>
  itemList.map(item => {
    if (!targetShopItemIds.includes(item.shop_item_id)) {
      return item;
    }
    return ShopItem.create({
      ...item,
      sold_out_flag:
        submitType === SHOP_ITEM_SUBMIT_TYPE.RESTART
          ? SOLD_OUT_FLAG.ON_SALE
          : submitType === SHOP_ITEM_SUBMIT_TYPE.STOP
          ? SOLD_OUT_FLAG.SOLD_OUT
          : item.sold_out_flag,
      is_release:
        submitType === SHOP_ITEM_SUBMIT_TYPE.SUSPENSION
          ? RELEASE_FLAG.NOT_RELEASED
          : withOptionStop // オプション売切に伴う商品の売切の場合、公開状態を維持する
          ? item.is_release
          : RELEASE_FLAG.RELEASED,
    });
  });

const getUpdatedShopItemsByStopOrRestartShopItem = (
  shopItems: ShopItemCategory[],
  shopItemIds: number[],
  submitType: ShopItemSubmitType,
  withOptionStop: boolean,
) =>
  shopItems.map(category => {
    if (!category.item_list.some(item => shopItemIds.includes(item.shop_item_id))) {
      return category;
    }
    return ShopItemCategory.create({
      ...category,
      item_list: getUpdatedShopItemListByStopOrRestartShopItem(
        category.item_list,
        shopItemIds,
        submitType,
        withOptionStop,
      ),
    });
  });

const getUpdatedOptionsByStopOrRestartOption = (
  optionList: ShopItemOption[],
  optionIdsToBeUpdated: number[],
  submitType: ShopItemSubmitType,
) =>
  optionList.map(option => {
    if (!optionIdsToBeUpdated.includes(option.shop_option_id)) {
      return option;
    }
    return ShopItemOption.create({
      ...option,
      is_order_stop:
        submitType === SHOP_ITEM_SUBMIT_TYPE.SUSPENSION
          ? option.is_order_stop
          : submitType !== SHOP_ITEM_SUBMIT_TYPE.RESTART,
      is_release: submitType !== SHOP_ITEM_SUBMIT_TYPE.SUSPENSION,
    });
  });

const getUpdatedOptionSetsByStopOrRestartOption = (
  optionSets: ShopItemOptionSet[],
  optionIdsToBeUpdated: number[],
  submitType: ShopItemSubmitType,
) =>
  optionSets.map(optionSet =>
    ShopItemOptionSet.create({
      ...optionSet,
      option_list: getUpdatedOptionsByStopOrRestartOption(
        optionSet.option_list,
        optionIdsToBeUpdated,
        submitType,
      ),
    }),
  );

const getUpdatedShopItemsBySelectStopReasonType = (
  shopItems: ShopItemCategory[],
  shopItemIds: number[],
  reasonForItemsStop: ReasonForItemsStop,
) =>
  shopItems.map(category => {
    if (!category.item_list.some(item => shopItemIds.includes(item.shop_item_id))) {
      return category;
    }
    return ShopItemCategory.create({
      ...category,
      item_list: getUpdatedShopItemListByBySelectStopReasonType(
        category.item_list,
        shopItemIds,
        reasonForItemsStop,
      ),
    });
  });

const getUpdatedShopItemListByBySelectStopReasonType = (
  itemList: ShopItem[],
  targetShopItemIds: number[],
  reasonForItemsStop: ReasonForItemsStop,
) =>
  itemList.map(item => {
    if (!targetShopItemIds.includes(item.shop_item_id)) {
      return item;
    }
    return ShopItem.create({
      ...item,
      sold_out_reason_type: reasonForItemsStop,
    });
  });

const getShopItemIdsAndOptionIdsToBeUpdated = (
  submittedOptionSets: SubmittableOptionSetType[],
  optionSetsToBeUpdated: { option_id_list: number[]; shop_item_id_list?: number[] }[],
) => {
  const submittedOptionIds = submittedOptionSets.reduce(
    (acc: number[], crr: SubmittableOptionSetType) => acc.concat(crr.stop_option_id_list),
    [],
  );
  return optionSetsToBeUpdated.reduce(
    (
      acc: {
        itemIds: number[];
        optionIds: number[];
      },
      crr: { option_id_list: number[]; shop_item_id_list?: number[] },
    ) => {
      Object.assign(acc, {
        optionIds: Array.from(new Set([...acc.optionIds, ...crr.option_id_list])),
        itemIds: crr.shop_item_id_list
          ? Array.from(new Set([...acc.itemIds, ...crr.shop_item_id_list]))
          : acc.itemIds,
      });
      return acc;
    },
    {
      itemIds: [],
      optionIds: submittedOptionIds,
    },
  );
};

const getShopBusinessBusyTimeByWeek = (busyTimes: BusinessBusyTime[], week: number) =>
  busyTimes.find(busyTime => busyTime.week === week);

const getFilteredShopItemListByOptionSetId = (shopItems: ShopItemCategory[], optionSetId: number) =>
  shopItems.reduce((acc: ShopItem[], crr: ShopItemCategory) => {
    const targetShopItems = crr.item_list.filter(i => {
      const optionSetIds = i.option_set_list.map(o => o.option_set_id);
      return optionSetIds.includes(optionSetId);
    });
    if (targetShopItems.length > 0) {
      targetShopItems.forEach(i => acc.push(i));
    }
    return acc;
  }, []);

const getPresentationShopItemSubmitTypeText = (type: ShopItemSubmitType) => {
  switch (type) {
    case SHOP_ITEM_SUBMIT_TYPE.RESTART:
      return "受付中";
    case SHOP_ITEM_SUBMIT_TYPE.STOP:
      return "本日売り切れ";
    case SHOP_ITEM_SUBMIT_TYPE.SUSPENSION:
      return "再販予定なし";
    // skip default case
  }
};

const getDisplayableItemListForStockCheck = (
  shopItems: ShopItemCategory[],
  parentCategoryId: number,
  isOnSale: boolean,
) =>
  shopItems
    .filter(shopItemCategory => shopItemCategory.parent_category_id === parentCategoryId)
    .map(shopItemCategory => ({
      ...shopItemCategory,
      item_list: shopItemCategory.item_list.filter(
        item => item.is_release && ShopItem.isSoldOut(item) !== isOnSale,
      ),
    }));

const isPublished = (shopData: ShopData) => shopData.release_state === SHOP_RELEASE_STATE.PUBLISHED;

const isGrocery = (state: ShopState) => ShopData.isGrocery(state.shopData.shop_type);

const isLazonaStaff = (shopData: ShopData, shopStaff: ShopStaff) => {
  if (!ShopData.isLazona(shopData.shop_commercial_facility_type)) {
    return false;
  }
  const parentCategoryId = ShopStaff.getLazonaStaffParentCategoryId(shopStaff);
  return typeof parentCategoryId !== "undefined" && parentCategoryId !== 0;
};

const isLazonaServiceCenter = (shopData: ShopData, shopStaff: ShopStaff) => {
  if (!ShopData.isLazona(shopData.shop_commercial_facility_type)) {
    return false;
  }
  const parentCategoryId = ShopStaff.getLazonaStaffParentCategoryId(shopStaff);
  return typeof parentCategoryId !== undefined && parentCategoryId === 0;
};

const existsShopItemParentCategories = (state: ShopState) =>
  ShopItemParentCategory.exists(state.shopItemParentCategories);

export const ShopModel = Object.freeze({
  initialState,
  updateShopData,
  updateShopStaff,
  updateShopItems,
  updateShopItemOptionSets,
  updateShopItemParentCategories,
  updateShopBusiness,
  updateShopInvitationCode,
  updateAutoPrintSetting,
  updateDisplayImages,
  updatePushNotificationSetting,
  enableImmediateDeliveryPaused,
  existsShopData,
  existsShopBusinessData,
  existsShopStaffData,
  getShopItemById,
  getOptionSetById,
  getOptionListByStopOtionIds,
  getFilteredShopItemList,
  getFilteredOptionList,
  getDisplayableOptionSetList,
  getUpdatedShopItemsByStopOrRestartShopItem,
  getUpdatedOptionSetsByStopOrRestartOption,
  getUpdatedShopItemsBySelectStopReasonType,
  getShopItemIdsAndOptionIdsToBeUpdated,
  getShopBusinessBusyTimeByWeek,
  getFilteredShopItemListByOptionSetId,
  getPresentationShopItemSubmitTypeText,
  getDisplayableItemListForStockCheck,
  isPublished,
  isGrocery,
  isLazonaStaff,
  isLazonaServiceCenter,
  existsShopItemParentCategories,
});
