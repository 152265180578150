export const NEWS_TYPE = {
  ONLY_TITLE: 1,
  TEXT: 2,
  HTML: 3,
} as const;

type NewsType = ValueOf<typeof NEWS_TYPE>;

export const NEWS_RELEASE_TYPE = {
  USER: 1,
  SHOP: 2,
  BOTH: 3,
} as const;

type NewsReleaseType = ValueOf<typeof NEWS_RELEASE_TYPE>;

export type NewsItem = Readonly<{
  id: number;
  release_type: NewsReleaseType;
  news_type: NewsType;
  path: string;
  title: string;
  detail_text: string;
  start_datetime: string;
  is_read: boolean;
}>;

const initialState: NewsItem = {
  id: 0,
  release_type: NEWS_RELEASE_TYPE.USER,
  news_type: NEWS_TYPE.ONLY_TITLE,
  path: "",
  title: "",
  detail_text: "",
  start_datetime: "",
  is_read: false,
};

const create = (args: Partial<NewsItem> = {}) => ({
  ...initialState,
  ...args,
});

export const NewsItem = Object.freeze({
  initialState,
  create,
});
