import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { colorsConst, zIndexConst } from "styles/const";
import { printerSelector } from "modules/app/selectors";

const Container = styled.div`
  z-index: ${zIndexConst.FIXED};
  position: fixed;
  left: 1em;
  bottom: 8em;
  color: ${colorsConst.TEXT.DANGER};
`;

type Props = {};

export const PrinterStatus: React.FC<Props> = React.memo(() => {
  const { printerStatus } = useSelector(printerSelector);
  return <Container>{printerStatus}</Container>;
});
