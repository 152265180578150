import React, { useMemo, useCallback } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { ShopData } from "records/ShopData";
import { userTouchedChangeSimulatorPage } from "modules/simulator/actions";
import { OrderModel, OrderListTabType, ORDER_LIST_TAB_TYPE } from "modules/order/model";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { NoData } from "components/atoms/NoData";
import { Contents } from "components/atoms/Contents";
import { PendingOrderListContainer } from "./PendingOrderListContainer";
import { ReleaseState } from "./ReleaseState";
import { OrderList } from "./OrderList";

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const SimulatorNotice = styled.div`
  margin-top: 0.5em;
  ${fontSize.XXLARGE};
  text-align: center;
  color: ${colorsConst.TEXT.SECONDARY};
`;

type Props = {
  orderList: OrderData[];
  shopData: ShopData;
  orderListTabType: OrderListTabType;
  isSimulator?: boolean;
  reservedOrderOnly?: boolean;
};

export const OrderListContainer: React.FC<Props> = React.memo(
  ({ orderList, shopData, orderListTabType, isSimulator = false, reservedOrderOnly = false }) => {
    const filteredOrderList = useMemo(
      () =>
        OrderModel.getFilteredOrderListByTabType(
          orderList,
          orderListTabType,
          shopData,
          reservedOrderOnly,
        ),
      [orderList, orderListTabType, shopData, reservedOrderOnly],
    );

    const noDataText = useMemo(
      () =>
        !ShopData.isPublished(shopData) || shopData.shop_order_stop_flag
          ? "現在注文を受け付けていません"
          : "現在注文を受付中です",
      [shopData],
    );

    const existsOrder = useMemo(() => filteredOrderList.length > 0, [filteredOrderList]);

    const dispatch = useDispatch();

    const handleClick = useCallback(
      (orderId: string) => {
        if (isSimulator) {
          dispatch(userTouchedChangeSimulatorPage(true));
        } else {
          dispatch(push(`orderDetail/${orderId}`));
        }
      },
      [dispatch, isSimulator],
    );

    return (
      <Container>
        {!existsOrder ? (
          <NoData>
            <div>{noDataText}</div>
            {isSimulator ? <SimulatorNotice>この画面はデモ画面です</SimulatorNotice> : undefined}
          </NoData>
        ) : (
          <Contents>
            {orderListTabType === ORDER_LIST_TAB_TYPE.PENDING ? (
              <PendingOrderListContainer
                orderList={filteredOrderList}
                shopData={shopData}
                handleClick={handleClick}
              />
            ) : (
              <OrderList list={filteredOrderList} handleClick={handleClick} />
            )}
            {isSimulator ? <SimulatorNotice>この画面はデモ画面です</SimulatorNotice> : undefined}
          </Contents>
        )}
        {!isSimulator ? <ReleaseState /> : undefined}
      </Container>
    );
  },
);
