import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import styled, { css } from "styled-components";
import { colorsConst } from "styles/const";
import { ArrowContainer } from "components/molecules/ArrowContainer";

const listItemStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  background-color: ${colorsConst.BACKGROUND.WHITE};
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  & + & {
    margin-top: 1em;
  }
`;

const Container = styled.button.attrs({ type: "button" })`
  ${listItemStyle};
  width: 100%;
  padding: 0;
  appearance: none;
  border: none;
  text-align: left;
  overflow: hidden;
`;

const SettingListItemInner = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding-top: 1em;
  padding-right: 2em;
  padding-bottom: 0.25em;
`;
const SettingListItemTitle = styled.div`
  margin-bottom: 0.25em;
  padding-left: 1em;
  padding-bottom: 0.25em;
  border-bottom: 1px solid ${colorsConst.BORDER.THIN};
`;
const SettingListItemCaption = styled.div`
  padding-left: 1em;
`;

type Props = {
  children: React.ReactNode;
  withArrow?: boolean;
  to?: string;
  handleClick?: () => void;
};

const SettingListItem: React.FC<Props> = React.memo(
  ({ children, withArrow = false, to, handleClick }) => {
    const dispatch = useDispatch();

    const handleButtonClick = useCallback(() => {
      if (typeof handleClick !== "undefined") {
        handleClick();
      } else if (typeof to !== "undefined") {
        dispatch(push(to));
      }
    }, [dispatch, to, handleClick]);

    return (
      <Container onClick={handleButtonClick}>
        {children}
        {withArrow && <ArrowContainer />}
      </Container>
    );
  },
);

export {
  SettingListItem,
  SettingListItemInner,
  SettingListItemTitle,
  SettingListItemCaption,
  listItemStyle,
};
