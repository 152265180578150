import { put, fork, call, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { API_KEY, StopImmediateDeliveryOrderResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateSnackBar } from "modules/app/actions";
import { enableImmediateDeliveryPaused } from "../actions";

export function* userSubmittedStopFastestOrderSaga() {
  try {
    const { result, error }: { result: StopImmediateDeliveryOrderResponse; error: unknown } =
      yield call(commonApiSaga, API_KEY.STOP_IMMEDIATE_DELIVERY_ORDER, {});
    if (result && !error) {
      yield all([
        put(enableImmediateDeliveryPaused()),
        put(push("/orderList")),
        put(updateSnackBar("即配注文を停止しました")),
      ]);
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
