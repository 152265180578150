import produce from "immer";
import { Sales } from "records/Sales";
import { MonthlySales } from "records/MonthlySales";

export type SalesState = Readonly<{
  sales: Sales;
  monthlySales: MonthlySales;
}>;

const initialState: SalesState = {
  sales: Sales.create(),
  monthlySales: MonthlySales.create(),
};

const updateSales = (state: SalesState, value: Sales) =>
  produce(state, draft => {
    draft.sales = Sales.create(value);
  });

const updateMonthlySales = (state: SalesState, value: MonthlySales) =>
  produce(state, draft => {
    draft.monthlySales = MonthlySales.create(value);
  });

export const SalesModel = Object.freeze({
  initialState,
  updateSales,
  updateMonthlySales,
});
