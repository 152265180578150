import { ShopItem } from "./ShopItem";

export type ShopItemCategory = {
  category_id: number;
  category_name: string;
  item_list: ShopItem[];
  alcohol: boolean;
  parent_category_id: number;
};

const initialState: ShopItemCategory = {
  category_id: 0,
  category_name: "",
  item_list: [],
  alcohol: false,
  parent_category_id: 0,
};

const create = (args: Partial<ShopItemCategory> = {}) => ({
  ...initialState,
  ...args,
  item_list: args.item_list ? args.item_list.map(i => ShopItem.create(i)) : [],
});

const hasParentCategory = (category: ShopItemCategory) => category.parent_category_id !== 0;

export const ShopItemCategory = Object.freeze({
  initialState,
  create,
  hasParentCategory,
});
