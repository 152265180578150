import * as React from "react";
import { Route } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { Helmet } from "react-helmet";
import { history } from "store";
import { DEVICE_TOKEN_KEY } from "modules/app/model";
import { UrlSearchParams } from "utils/UrlSearchParams";
import { LocalStorage } from "utils/LocalStorage";
import { BaseStyle } from "styles/global/base";
import { AppContainer } from "components/organisms/AppContainer";
import { TransitionWrapper } from "components/organisms/TransitionWrapper";
import { TopTemplate } from "components/templates/TopTemplate";
import { LoginTemplate } from "components/templates/LoginTemplate";
import { OrderListTemplate } from "components/templates/OrderListTemplate";
import OrderDetailTemplate from "components/templates/OrderDetailTemplate";
import { InformationTemplate } from "components/templates/InformationTemplate";
import OrderHistoryTemplate from "components/templates/OrderHistoryTemplate";
import OrderHistoryDetailTemplate from "components/templates/OrderHistoryDetailTemplate";
import { ShopItemsTemplate } from "components/templates/ShopItemsTemplate";
import { SettingTemplate } from "components/templates/SettingTemplate";
import { ShopTemplate } from "components/templates/ShopTemplate";
import { ShopBusinessTemplate } from "components/templates/ShopBusinessTemplate";
import { ShopBusinessHolidayTemplate } from "components/templates/ShopBusinessHolidayTemplate";
import { ShopBusinessTimeEditTemplate } from "components/templates/ShopBusinessTimeEditTemplate";
import ShopBusinessBusyTimeEditTemplate from "components/templates/ShopBusinessBusyTimeEditTemplate";
import { ShopBusinessNationalHolidayEditTemplate } from "components/templates/ShopBusinessNationalHolidayEditTemplate";
import { ShopItemParentCategoriesTemplate } from "components/templates/ShopItemParentCategoriesTemplate";
import ShopBusinessSettingTemplate from "components/templates/ShopBusinessSettingTemplate";
import { SalesTemplate } from "components/templates/SalesTemplate";
import { SalesMonthlyTemplate } from "components/templates/SalesMonthlyTemplate";
import { FaqTemplate } from "components/templates/FaqTemplate";
import SimulatorTemplate from "components/templates/SimulatorTemplate";
import { PrinterSettingTemplate } from "components/templates/PrinterSettingTemplate";
import { PrinterTestTemplate } from "components/templates/PrinterTestTemplate";
import { ShopItemsStockCheckTemplate } from "components/templates/ShopItemsStockCheckTemplate";
import { ShowImageSettingTemplate } from "components/templates/ShowImageSettingTemplate";
import { PushNotificationSettingTemplate } from "components/templates/PushNotificationSettingTemplate";

if (UrlSearchParams.exists(DEVICE_TOKEN_KEY)) {
  LocalStorage.setItem(DEVICE_TOKEN_KEY, UrlSearchParams.get(DEVICE_TOKEN_KEY) as string);
}

export default (
  <ConnectedRouter history={history}>
    <Helmet htmlAttributes={{ lang: "ja" }}>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, viewport-fit=cover" />
      <title>{process.env.REACT_APP_APP_TITLE}</title>
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&amp;display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <BaseStyle />
    <AppContainer>
      <TransitionWrapper>
        <Route exact path="/" component={TopTemplate} />
        <Route exact path="/login" component={LoginTemplate} />

        <Route exact path="/orderList" component={OrderListTemplate} />
        <Route exact path="/orderDetail/:orderId" component={OrderDetailTemplate} />

        <Route exact path="/information" component={InformationTemplate} />

        <Route exact path="/orderHistory" component={OrderHistoryTemplate} />
        <Route exact path="/orderHistory/:date" component={OrderHistoryTemplate} />
        <Route exact path="/orderHistoryDetail/:orderId" component={OrderHistoryDetailTemplate} />
        <Route exact path="/shopItems" component={ShopItemsTemplate} />
        <Route exact path="/setting" component={SettingTemplate} />

        <Route exact path="/shop" component={ShopTemplate} />
        <Route exact path="/shopBusiness" component={ShopBusinessTemplate} />
        <Route exact path="/shopBusinessHoliday" component={ShopBusinessHolidayTemplate} />
        <Route exact path="/shopBusinessTime/edit" component={ShopBusinessTimeEditTemplate} />
        <Route
          exact
          path="/shopBusinessBusyTime/:week"
          component={ShopBusinessBusyTimeEditTemplate}
        />
        <Route
          exact
          path="/shopBusinessNationalHoliday/edit"
          component={ShopBusinessNationalHolidayEditTemplate}
        />
        <Route exact path="/shopBusinessSetting" component={ShopBusinessSettingTemplate} />
        <Route exact path="/shopBusinessSetting/:goBack" component={ShopBusinessSettingTemplate} />

        <Route
          exact
          path="/shopItemParentCategories"
          component={ShopItemParentCategoriesTemplate}
        />
        <Route exact path="/showImageSettingTemplate" component={ShowImageSettingTemplate} />

        <Route exact path="/sales" component={SalesTemplate} />
        <Route exact path="/salesMonthly" component={SalesMonthlyTemplate} />
        <Route exact path="/faq" component={FaqTemplate} />

        <Route exact path="/simulator/:receiveType/:timeType" component={SimulatorTemplate} />

        <Route exact path="/printerSetting" component={PrinterSettingTemplate} />
        <Route exact path="/printerTest" component={PrinterTestTemplate} />

        <Route exact path="/shopItemsStockCheck" component={ShopItemsStockCheckTemplate} />
        <Route exact path="/pushNotificationSetting" component={PushNotificationSettingTemplate} />

        <Route exact path="*" component={TopTemplate} />
      </TransitionWrapper>
    </AppContainer>
  </ConnectedRouter>
);
