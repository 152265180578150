import { select, put, call, cancel, fork } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { GetShopDataResponse } from "apis";
import { ModalManager } from "records/ModalManager";
import { AppModel } from "../model";
import { modalManagerSelector, loginTokenSelector } from "../selectors";
import { commonErrorSaga } from "./commonErrorSaga";
import { getShopDataSaga, updateShopDataSaga } from "../../shop/sagas";

export function* systemPreLoginSaga() {
  try {
    const modalManager: ReturnType<typeof modalManagerSelector> = yield select(
      modalManagerSelector,
    );
    const existsError = ModalManager.existsModal(modalManager, "APP_ERROR");
    const token: ReturnType<typeof loginTokenSelector> = yield select(loginTokenSelector);
    if (existsError || !AppModel.existsLoginToken(token)) {
      yield cancel();
    }
    const { result, error }: { result: GetShopDataResponse; error?: unknown } = yield call(
      getShopDataSaga,
    );
    if (typeof error !== "undefined") {
      throw error;
    }
    yield fork(updateShopDataSaga, result);
    yield put(replace("/orderList"));
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
