import { Task } from "redux-saga";
import { put, select, fork, all, takeLatest } from "redux-saga/effects";
import { OrderData, ORDER_STATE_TYPE } from "records/OrderData";
import { systemClosedModal, updateSnackBar } from "modules/app/actions";
import { DateUtils } from "utils/DateUtils";
import {
  ActionTypes,
  updateSimulatorOrderData,
  updateSimulatorOrderList,
  updateSimulatorPageTransition,
  updateCookingStartDateTime,
  UserTouchedSimulatorApproveOrderWithUpdateCookingTimeButtonAction,
} from "../actions";
import { SimulatorModel } from "../model";
import { simulatorCookingStartDateTimeSelector } from "../selectors";
import { cookingStartSaga } from "./cookingStartSaga";

export function* userTouchedSimulatorApproveOrderWithUpdateCookingTimeButtonSaga(
  action: UserTouchedSimulatorApproveOrderWithUpdateCookingTimeButtonAction,
) {
  const { orderData, addMinutes, draftItems, setTabType } = action.payload;
  const cookingStartDateTime: string = yield select(simulatorCookingStartDateTimeSelector);
  const updatedOrderItems = SimulatorModel.getUpdatedOrderItems(orderData, draftItems);
  const updatedOrderData = OrderData.create({
    ...orderData,
    order_state: ORDER_STATE_TYPE.APPROVE,
    item_list: updatedOrderItems,
  });
  const updatedCookingStartDateTime = DateUtils.dateToString(
    DateUtils.getDateWithAddMinutes(cookingStartDateTime, addMinutes * -1),
    "YYYY-MM-DD HH:mm:ss",
  );
  yield all([
    put(updateSimulatorOrderData(updatedOrderData)),
    put(updateSimulatorOrderList([updatedOrderData])),
    put(updateCookingStartDateTime(updatedCookingStartDateTime)),
    put(systemClosedModal("UPDATE_COOKING_START_TIME")),
    put(updateSimulatorPageTransition(false)),
    put(updateSnackBar("注文を受け付けました")),
  ]);
  const cookingStartTask: Task = yield fork(cookingStartSaga, updatedOrderData, setTabType);
  const stopTask: Task = yield takeLatest(ActionTypes.S_UNMOUNT_SIMULATOR, stopTaskSaga);
  function* stopTaskSaga() {
    if (!cookingStartTask.isCancelled()) {
      yield cookingStartTask.cancel();
      yield stopTask.cancel();
    }
  }
}
