import React, { useCallback } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { OrderData } from "records/OrderData";
import { OrderList } from "components/organisms/OrderList";

type Props = {
  orderHistoryList: OrderData[];
};

export const OrderHistory: React.FC<Props> = React.memo(({ orderHistoryList }) => {
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (orderId: string) => {
      dispatch(push(`/orderHistoryDetail/${orderId}`));
    },
    [dispatch],
  );

  return <OrderList list={orderHistoryList} isHistory handleClick={handleClick} />;
});
