/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo, useEffect } from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { ShopData } from "records/ShopData";
import { OrderItemData, DraftItem } from "records/OrderItemData";
import { ShopItemParentCategory } from "records/ShopItemParentCategory";
import { colorsConst } from "styles/const";
import { Text } from "components/atoms/Text";
import { ReasonForItemsStopSelectField } from "components/organisms/OrderStopDialog/ReasonForItemsStopSelectField";
import { ReasonForItemsStop, REASON_FOR_ITEMS_STOP } from "records/ShopItem";
import { OrderDetailItemWeightRange } from "./OrderDetailItemWeightRange";
import { OrderDetailItemCountContainer } from "./OrderDetailItemCountContainer";
import { CancelButtonByRequiredItemShortage } from "./CancelButtonByRequiredItemShortage";

const Container = styled.div<{ isRequired: boolean }>`
  padding: 0 1em;
  ${p => (p.isRequired ? `background-color: #ffeaea;` : "")}
  & + & > div {
    border-top: 1px solid ${colorsConst.BORDER.THIN};
  }
`;

const Inner = styled.div`
  padding-bottom: 1em;
`;

const ParentCategoryContainer = styled.div`
  padding: 0.25em 0;
  text-align: center;
  border-bottom: 1px solid ${colorsConst.BORDER.THIN};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SubstituteItemContainer = styled.div<{ active: boolean }>`
  position: relative;
  margin: 0 -1em;
  padding: 0 1em 1em;
  background-color: #ffeaea;
  &::after {
    content: "▲";
    position: absolute;
    top: -1em;
    left: 3em;
    color: #ffeaea;
  }
  ${p => (!p.active ? "opacity: 0.5;" : "")}
`;

const SubstituteItemTextContainer = styled.div`
  padding-top: 1em;
`;

const SelectContainer = styled.div`
  margin-top: 0.5em;
  margin-bottom: 1.5em;
`;

type Props = {
  draftItem: DraftItem;
  orderData: OrderData;
  shopData: ShopData;
  canChangeItemCount: boolean;
  canDisplayCheckBox: boolean;
  draftItems: DraftItem[];
  shopItemParentCategories: ShopItemParentCategory[];
  isContainRequiredItemOrder: boolean;
  isAllRequiredItemsChecked: boolean;
  isHistory: boolean;
  isGrocery: boolean;
  submitting: boolean;
  setDraftItems?: (value: React.SetStateAction<DraftItem[]>) => void;
  handleChangePickStatus?: (draftItem: DraftItem, checked: boolean) => void;
  handleChangeSelectStopReasonShopItems?: (
    shopItemId: number,
    stopReasonType: ReasonForItemsStop,
  ) => void;
};

export const OrderDetailItem: React.FC<Props> = React.memo(
  ({
    draftItem,
    orderData,
    shopData,
    canChangeItemCount,
    canDisplayCheckBox,
    draftItems,
    shopItemParentCategories,
    isContainRequiredItemOrder,
    isAllRequiredItemsChecked,
    isHistory,
    isGrocery,
    submitting,
    setDraftItems,
    handleChangePickStatus,
    handleChangeSelectStopReasonShopItems,
  }) => {
    const canDisplayWeightRange = useMemo(
      () =>
        isGrocery &&
        draftItem.is_sell_by_weight &&
        OrderData.isUnapprovedOrder(orderData.order_state),
      [orderData, isGrocery, draftItem],
    );

    const isRequired = useMemo(() => OrderItemData.isRequired(draftItem), [draftItem]);

    const displayableParentCategory = useMemo(() => {
      if (!ShopItemParentCategory.exists(shopItemParentCategories)) {
        return undefined;
      }
      return ShopItemParentCategory.getShopItemParentCategoryById(
        draftItem.shop_item_parent_category_id,
        shopItemParentCategories,
      );
    }, [draftItem, shopItemParentCategories]);

    const canDisplayParentCategory = useMemo(
      () =>
        (isRequired || typeof displayableParentCategory !== "undefined") &&
        draftItem.shop_item_parent_category_group_order === 1,
      [draftItem, isRequired, displayableParentCategory],
    );

    const canDisplayOrderCancelButton = useMemo(() => {
      if (isHistory || !isRequired || !OrderData.isHoldOrder(orderData.order_state)) {
        return false;
      }
      return (
        orderData.item_list.filter(i => OrderItemData.isRequired(i)).length ===
        draftItem.shop_item_parent_category_group_order
      );
    }, [isHistory, isRequired, orderData, draftItem]);

    const canDisplaySubstituteItem = useMemo(
      () => canChangeItemCount && OrderItemData.existsSubstituteItem(draftItem),
      [draftItem, canChangeItemCount],
    );

    const substituteItemMaxCount = useMemo(
      () => draftItem.order_count - draftItem.pick_count,
      [draftItem],
    );

    const isSubsutituteItemActive = useMemo(
      () => substituteItemMaxCount > 0,
      [substituteItemMaxCount],
    );

    const containerStyle: React.CSSProperties = useMemo(
      () =>
        canChangeItemCount &&
        isContainRequiredItemOrder &&
        !isRequired &&
        !isAllRequiredItemsChecked
          ? {
              opacity: 0.5,
            }
          : {},
      [canChangeItemCount, isRequired, isContainRequiredItemOrder, isAllRequiredItemsChecked],
    );

    const canDisplaySelectBox = useMemo(() => {
      if (
        isHistory ||
        draftItem.is_sell_by_weight ||
        ShopData.isLazona(shopData.shop_commercial_facility_type)
      ) {
        return false;
      }

      if (OrderItemData.existsSubstituteItem(draftItem)) {
        return draftItem.pick_count + draftItem.substituteItem!.pick_count < draftItem.order_count;
      }

      return draftItem.pick_count < draftItem.order_count;
    }, [draftItem, isHistory, shopData]);

    useEffect(() => {
      handleChangeSelectStopReasonShopItems &&
        handleChangeSelectStopReasonShopItems(
          draftItem.shop_item_id,
          REASON_FOR_ITEMS_STOP.DEFAULT,
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canDisplaySelectBox, draftItem.shop_item_id, draftItem.is_sell_by_weight]);

    return (
      <Container isRequired={isRequired} style={containerStyle}>
        <Inner>
          {canDisplayParentCategory ? (
            <ParentCategoryContainer>
              {isRequired ? (
                <Text size="DEFAULT" color="DANGER" weight="BOLD">
                  必須商品
                  {!isHistory ? (
                    <Text size="XSMALL" weight="REGULAR">
                      ※先に確認してください
                    </Text>
                  ) : undefined}
                </Text>
              ) : (
                <Text size="XSMALL" color="SECONDARY">
                  セクションカテゴリ：
                  <Text size="DEFAULT" color="PRIMARY" weight="BOLD">
                    {displayableParentCategory!.parent_category_name}
                  </Text>
                </Text>
              )}
            </ParentCategoryContainer>
          ) : undefined}
          <OrderDetailItemCountContainer
            isHistory={isHistory}
            draftItem={draftItem}
            orderData={orderData}
            draftItems={draftItems}
            canChangeItemCount={canChangeItemCount}
            canDisplayCheckBox={canDisplayCheckBox}
            maxCount={draftItem.order_count}
            isContainRequiredItemOrder={isContainRequiredItemOrder}
            isAllRequiredItemsChecked={isAllRequiredItemsChecked}
            isGrocery={isGrocery}
            submitting={submitting}
            setDraftItems={setDraftItems}
            handleChangePickStatus={handleChangePickStatus}
          />
          {canDisplayWeightRange ? <OrderDetailItemWeightRange item={draftItem} /> : undefined}
          {canDisplayOrderCancelButton ? <CancelButtonByRequiredItemShortage /> : undefined}
        </Inner>
        {canDisplaySubstituteItem ? (
          <SubstituteItemContainer active={isSubsutituteItemActive}>
            <SubstituteItemTextContainer>
              <Text size="SMALL" weight="BOLD">
                これは代替商品です。上の商品が不足している場合は代替可能か確認してください。
              </Text>
            </SubstituteItemTextContainer>
            <OrderDetailItemCountContainer
              isHistory={isHistory}
              draftItem={draftItem.substituteItem!}
              isSubstitute={true}
              orderData={orderData}
              draftItems={draftItems}
              canChangeItemCount={canChangeItemCount}
              canDisplayCheckBox={canDisplayCheckBox}
              maxCount={substituteItemMaxCount}
              isContainRequiredItemOrder={isContainRequiredItemOrder}
              isAllRequiredItemsChecked={isAllRequiredItemsChecked}
              isSubsutituteItemActive={isSubsutituteItemActive}
              isGrocery={isGrocery}
              submitting={submitting}
              setDraftItems={setDraftItems}
              handleChangePickStatus={handleChangePickStatus}
            />
          </SubstituteItemContainer>
        ) : undefined}
        {canDisplaySelectBox && (
          <SelectContainer>
            <ReasonForItemsStopSelectField
              isSelected={true}
              handleChange={handleChangeSelectStopReasonShopItems}
              shopItemId={draftItem.shop_item_id}
            />
          </SelectContainer>
        )}
      </Container>
    );
  },
);
