import React, { useContext } from "react";
import { UpdateCookingStartTimeDialog } from "./UpdateCookingStartTimeDialog";
import { LocalPaymentNoticeDialog } from "./LocalPaymentNoticeDialog";
import { FindingCrewDialog } from "./FindingCrewDialog";
import { CancelOrderDialog } from "./CancelOrderDialog";
import { CancelOrderDialogByRequiredItemShortage } from "./CancelOrderDialogByRequiredItemShortage";
import { HoldOrderDialog } from "./HoldOrderDialog";
import { OrderItemImageDialog } from "./OrderItemImageDialog";
import { WaitComboOrderDialog } from "./WaitComboOrderDialog";
import { UnableApproveOrderDialog } from "./UnableApproveOrderDialog";
import { OrderDetailContext } from "./OrderDetailContext";

type Props = {};

export const OrderDetailModal: React.FC<Props> = React.memo(() => {
  const {
    orderData,
    submitting,
    handleClilckCancelOrderButton,
    handleCloseFindingCrewDialog,
    handleClickApproveOrderWithUpdateCookingStartTimeButton,
    handleCloseWaitingComboOrderDialog,
    handleClilckCancelOrderButtonByRequiredItemShortage,
  } = useContext(OrderDetailContext);

  return (
    <>
      <UpdateCookingStartTimeDialog
        orderData={orderData}
        handleClickApproveOrderWithUpdateCookingStartTimeButton={
          handleClickApproveOrderWithUpdateCookingStartTimeButton
        }
      />
      <LocalPaymentNoticeDialog />
      <FindingCrewDialog handleCloseFindingCrewDialog={handleCloseFindingCrewDialog} />
      <CancelOrderDialog
        submitting={submitting}
        handleClilckCancelOrderButton={handleClilckCancelOrderButton}
      />
      <CancelOrderDialogByRequiredItemShortage
        submitting={submitting}
        handleClilckCancelOrderButtonByRequiredItemShortage={
          handleClilckCancelOrderButtonByRequiredItemShortage
        }
      />
      <HoldOrderDialog />
      <OrderItemImageDialog />
      <WaitComboOrderDialog
        handleCloseWaitingComboOrderDialog={handleCloseWaitingComboOrderDialog}
      />
      <UnableApproveOrderDialog />
    </>
  );
});
