import firebase from "firebase";
import { fork, call } from "redux-saga/effects";
import { GetShopDataResponse } from "apis";
import { FirestoreShopNotificationData } from "records/FirestoreShopNotificationData";
import { readShopNotificationSaga } from "./readShopNotificationSaga";
import { getShopDataSaga, updateShopDataSaga } from ".";

export function* updateShopNotificationSaga(
  data: firebase.firestore.QuerySnapshot<FirestoreShopNotificationData>,
) {
  const notifications = data.docs.map(doc => doc.data());
  if (notifications.length > 0) {
    yield call(readShopNotificationSaga, notifications[0].notification_type);
    const { result, error }: { result: GetShopDataResponse; error: unknown } = yield call(
      getShopDataSaga,
    );
    if (result && !error) {
      yield fork(updateShopDataSaga, result);
    } else {
      throw error;
    }
  }
}
