/* eslint-disable import/extensions */
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { API_KEY } from "apis";
import { systemAutoLogin, userTouchedLoginButton } from "modules/app/actions";
import { existsLoginTokenSelector, isConnectedApiSelectorFactory } from "modules/app/selectors";
import { media } from "styles/mixins";
import Img from "components/atoms/Images/bg_top.jpg";
import { Logo } from "components/atoms/Logo";
import { Button } from "components/atoms/Button";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Article } from "components/organisms/Article";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url(${Img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 65%;
  max-width: 331px;
  transform: translate(-50%, -50%);
  svg {
    width: 100%;
    height: auto;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 7%;
  width: 100%;
  padding: 0 10%;
  ${media.mobile} {
    bottom: 17%;
  }
`;

const isConnectedApiSelector = isConnectedApiSelectorFactory(API_KEY.GET_SHOP_DATA);

type Props = {};

export const TopTemplate: React.FC<Props> = React.memo(() => {
  const existsLoginToken = useSelector(existsLoginTokenSelector);
  const loading = useSelector(isConnectedApiSelector);

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(userTouchedLoginButton());
  }, [dispatch]);

  useEffect(() => {
    dispatch(systemAutoLogin());
  }, [dispatch]);

  return existsLoginToken && loading ? (
    <LoadingContainer />
  ) : (
    <Article>
      <Container>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <ButtonContainer>
          <Button appearance="primary" handleClick={handleClick}>
            ログイン
          </Button>
        </ButtonContainer>
      </Container>
    </Article>
  );
});
