export type FontSizes =
  | "XXSMALL"
  | "XSMALL"
  | "SMALL"
  | "DEFAULT"
  | "LARGE"
  | "XLARGE"
  | "XXLARGE"
  | "XXXLARGE";

export const fontFamilyConst = "'Noto Sans JP', sans-serif";

export const fontSizesConst: { [K in FontSizes]: { default: string; tablet: string } } = {
  XXSMALL: {
    default: "1.1rem",
    tablet: "1.3rem",
  },
  XSMALL: {
    default: "1.2rem",
    tablet: "1.4rem",
  },
  SMALL: {
    default: "1.4rem",
    tablet: "1.6rem",
  },
  DEFAULT: {
    default: "1.6rem",
    tablet: "1.8rem",
  },
  LARGE: {
    default: "1.8rem",
    tablet: "2.0rem",
  },
  XLARGE: {
    default: "2.0rem",
    tablet: "2.2rem",
  },
  XXLARGE: {
    default: "2.4rem",
    tablet: "2.6rem",
  },
  XXXLARGE: {
    default: "3.0rem",
    tablet: "3.4rem",
  },
} as const;

export type FontWeights = "REGULAR" | "MEDIUM" | "BOLD";

export const fontWeightsConst: { [K in FontWeights]: number } = {
  REGULAR: 400,
  MEDIUM: 500,
  BOLD: 700,
} as const;
