import React, { useMemo } from "react";
import styled from "styled-components";
import { OrderData, ORDER_RECEIVE_TYPE, DELIVERY_STATE_TYPE } from "records/OrderData";
import { DateUtils } from "utils/DateUtils";
import { Text } from "components/atoms/Text";

const Container = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LabelTextContainer = styled.div`
  white-space: nowrap;
`;

type Props = {
  orderData: OrderData;
};

export const ReceiveDateTime: React.FC<Props> = React.memo(({ orderData }) => {
  const { monthDay, weekday, time } = useMemo(() => {
    const targetDateTime =
      orderData.receive_type === ORDER_RECEIVE_TYPE.TAKEOUT ||
      orderData.delivery_state !== DELIVERY_STATE_TYPE.DELIVERED
        ? orderData.receive_datetime
        : orderData.order_finish_datetime;
    return {
      monthDay: DateUtils.dateToString(targetDateTime, "M月D日"),
      weekday: DateUtils.getWeekdayString(targetDateTime),
      time: DateUtils.dateToString(targetDateTime, "HH:mm"),
    };
  }, [orderData]);
  return (
    <Container>
      <LabelTextContainer>
        <Text size="SMALL">{OrderData.getPresentationReceiveTimeLabelText(orderData)}</Text>
      </LabelTextContainer>
      <div>
        <Text size="SMALL">{`${monthDay}（${weekday}）`}</Text>
      </div>
      <div>
        <Text size="XXLARGE" weight="BOLD">
          {time}
        </Text>
      </div>
    </Container>
  );
});
