import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { BusinessBusyTime } from "records/BusinessBusyTime";
import { BusinessNationalHoliday } from "records/BusinessNationalHoliday";
import { ShopModel } from "modules/shop/model";
import {
  businessWeekTimeSelector,
  businessBusyTimeSelector,
  businessNationalHolidaySelector,
} from "modules/shop/selectors";
import { fontSize } from "styles/mixins";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import { BusinessTime } from "components/molecules/BusinessTime";

const Container = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
`;

const Title = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  ${fontSize.LARGE};
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const BusinessTimeContainer = styled.div`
  margin-bottom: 2em;
`;

type Props = {};

export const ShopBusinessTime: React.FC<Props> = React.memo(() => {
  const businessWeekTimes = useSelector(businessWeekTimeSelector);
  const businessBusyTimes = useSelector(businessBusyTimeSelector);
  const businessNationalHoliday = useSelector(businessNationalHolidaySelector);

  const nationalHolidayBusyTimes = useMemo(
    () =>
      businessNationalHoliday.busy_times.length > 0
        ? BusinessBusyTime.create({
            week: 7,
            busy_times: businessNationalHoliday.busy_times,
          })
        : undefined,
    [businessNationalHoliday],
  );

  const disPatch = useDispatch();

  const handleClickBusinessTimeEdit = useCallback(() => {
    disPatch(push("/shopBusinessTime/edit"));
  }, [disPatch]);

  const handleClickHolidayBusinessTimeEdit = useCallback(() => {
    disPatch(push("/shopBusinessNationalHoliday/edit"));
  }, [disPatch]);

  return (
    <Container>
      <TitleContainer>
        <Title>曜日別の営業時間</Title>
        <ButtonContainer>
          <Button appearance="primary" handleClick={handleClickBusinessTimeEdit}>
            編集する
          </Button>
        </ButtonContainer>
      </TitleContainer>
      <BusinessTimeContainer>
        {businessWeekTimes.map(weekTime => (
          <BusinessTime
            key={weekTime.week}
            weekNumber={weekTime.week}
            businessType={weekTime.business_type}
            businessTimes={weekTime.business_times}
            busyTime={ShopModel.getShopBusinessBusyTimeByWeek(businessBusyTimes, weekTime.week)}
          />
        ))}
      </BusinessTimeContainer>
      <TitleContainer>
        <Title>祝日別の営業時間</Title>
        <ButtonContainer>
          <Button appearance="primary" handleClick={handleClickHolidayBusinessTimeEdit}>
            編集する
          </Button>
        </ButtonContainer>
      </TitleContainer>
      {BusinessNationalHoliday.existsNationalHolidaySetting(
        businessNationalHoliday.business_type,
      ) ? (
        <BusinessTime
          weekNumber={7}
          businessType={businessNationalHoliday.business_type}
          businessTimes={businessNationalHoliday.business_times}
          busyTime={nationalHolidayBusyTimes}
        />
      ) : (
        <div>
          <Text>祝日の営業時間は設定されていません。</Text>
          <br />
          <Text>※未設定の場合、曜日別営業時間が適用されます。</Text>
        </div>
      )}
    </Container>
  );
});
