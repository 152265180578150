import { put, call, fork, all } from "redux-saga/effects";
import { API_KEY, StopReleaseByOptionSetRequest, StopReleaseByOptionSetResponse } from "apis";
import { SubmittableOptionSetType } from "records/ShopItemOptionSet";
import { StopItemsByStopOptionSet } from "records/StopItemsByStopOptionSet";
import { systemClosedModal, updateSnackBar } from "modules/app/actions";
import { commonApiSaga, commonErrorSaga } from "modules/app/sagas";
import {
  systemUpdateShopItemStatus,
  systemUpdateShopItemOptionStatus,
  UserSubmittedStopOrderByFilteredOptionsAction,
} from "../actions";
import { ShopModel, SHOP_ITEM_SUBMIT_TYPE } from "../model";

export function* userSubmittedStopReleaseByFilteredOptionsSaga(
  action: UserSubmittedStopOrderByFilteredOptionsAction,
) {
  try {
    const { optionSets } = action.payload;
    const stopOptionSets: SubmittableOptionSetType[] = optionSets.map(optionSet => ({
      option_set_id: optionSet.option_set_id,
      stop_option_id_list: optionSet.option_list.map(option => option.shop_option_id),
    }));
    const params: StopReleaseByOptionSetRequest = {
      option_set_list: stopOptionSets,
    };
    const { result, error }: { result: StopReleaseByOptionSetResponse; error: unknown } =
      yield call(commonApiSaga, API_KEY.STOP_RELEASE_BY_OPTION_SET, params);
    if (result && !error) {
      const updatedIds = ShopModel.getShopItemIdsAndOptionIdsToBeUpdated(
        stopOptionSets,
        result.option_set_list,
      );
      if (updatedIds.itemIds.length > 0) {
        yield all([
          put(systemClosedModal("CONFIRM_BULK_UPDATE_OPTION_STATUS")),
          put(
            systemUpdateShopItemStatus(updatedIds.itemIds, SHOP_ITEM_SUBMIT_TYPE.SUSPENSION, true),
          ),
          put(
            systemUpdateShopItemOptionStatus(
              updatedIds.optionIds,
              SHOP_ITEM_SUBMIT_TYPE.SUSPENSION,
            ),
          ),
          put(updateSnackBar("オプションを非公開にしました")),
        ]);
      } else {
        yield all([
          put(systemClosedModal("CONFIRM_BULK_UPDATE_OPTION_STATUS")),
          put(
            systemUpdateShopItemOptionStatus(
              updatedIds.optionIds,
              SHOP_ITEM_SUBMIT_TYPE.SUSPENSION,
            ),
          ),
          put(updateSnackBar("オプションを非公開にしました")),
        ]);
      }
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
