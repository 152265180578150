import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconOnDelivery: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <path
        d="M27.5477 4.53624C26.2936 3.1144 24.1274 2.98215 22.7097 4.24085C21.2922 5.49931 21.1596 7.67167 22.4134 9.09322C23.6675 10.5151 25.8337 10.6473 27.2514 9.3886C28.6689 8.13015 28.8015 5.95779 27.5477 4.53624Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4519 11.1252C20.6401 10.1388 18.3741 10.8122 17.3898 12.6295L13.3198 20.1466C12.3503 21.937 12.9868 24.1704 14.7393 25.1792C14.8675 25.2695 15.0066 25.3481 15.1557 25.4131L21.9817 28.3886L21.3545 36.6355C21.2855 37.5682 21.9436 38.4192 22.8833 38.5624C23.8691 38.713 24.7901 38.0326 24.9404 37.0441L26.346 27.7828C26.3549 27.7638 26.3635 27.7446 26.3719 27.7252C26.8905 26.5289 26.3444 25.1368 25.1512 24.617L20.4926 22.5864L23.3923 17.2309L25.1246 19.1717C25.4427 19.5705 25.9136 19.816 26.4219 19.8477L31.7732 20.3343L31.7777 20.3345C32.7731 20.3953 33.6286 19.6351 33.6895 18.638C33.7503 17.6427 32.9969 16.7856 32.0046 16.7216L27.9622 16.1451L23.8631 12.338C23.7699 12.2219 23.665 12.1203 23.5515 12.0335C23.2553 11.6726 22.8862 11.3618 22.4519 11.1252ZM14.8206 25.0518L14.7456 25.1828L14.7393 25.1792L14.7332 25.1749L14.8206 25.0518Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.1671 17.3503C35.4111 17.3503 34.7982 17.9632 34.7982 18.7192C34.7982 19.4752 35.4111 20.0881 36.1671 20.0881C36.4863 20.0881 36.745 20.3468 36.745 20.666V20.9093C36.745 21.3628 36.3773 21.7305 35.9238 21.7305H32.7973C32.6662 21.7268 32.5332 21.7421 32.4023 21.7778C31.8726 21.9209 31.4703 22.3739 31.4017 22.9303C31.3854 23.0609 31.3877 23.1948 31.4104 23.3279C31.4317 23.4547 31.4704 23.5756 31.524 23.688L33.1538 27.3551C30.2833 29.0765 28.3566 32.2261 28.3566 35.8189C28.3566 41.2515 32.762 45.672 38.1783 45.672C43.5949 45.672 48 41.2515 48 35.8189C48 30.3862 43.5946 25.9657 38.1783 25.9657C37.3142 25.9657 36.4756 26.0783 35.6764 26.2896L34.867 24.4683H35.9238C37.8894 24.4683 39.4828 22.8749 39.4828 20.9093V20.666C39.4828 18.8348 37.9984 17.3503 36.1671 17.3503ZM39.8516 35.6839L36.7156 28.6278C37.1883 28.5312 37.6774 28.4804 38.1783 28.4804C42.2108 28.4804 45.4924 31.772 45.4924 35.8185C45.4924 39.8654 42.2108 43.1569 38.1783 43.1569C34.1461 43.1569 30.8645 39.8654 30.8645 35.8185C30.8645 33.2502 32.1865 30.9855 34.1844 29.6738L37.3497 36.7958C37.6568 37.4867 38.4658 37.7979 39.1567 37.4908C39.8475 37.1837 40.1587 36.3747 39.8516 35.6839Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3108 25.7311C13.614 25.4377 12.8113 25.7647 12.5179 26.4615L12.3906 26.7638C11.5713 26.5409 10.71 26.422 9.82168 26.422C4.40543 26.422 0 30.8425 0 36.2752C0 41.7078 4.40543 46.1283 9.82168 46.1283C15.2379 46.1283 19.6434 41.7078 19.6434 36.2752C19.6434 32.7071 17.7431 29.5761 14.9052 27.8471L15.0412 27.524C15.3346 26.8272 15.0076 26.0245 14.3108 25.7311ZM2.50791 36.2752C2.50791 32.2283 5.78951 28.9367 9.82168 28.9367C10.3646 28.9367 10.8937 28.9964 11.403 29.1094L8.62435 35.7086C8.33097 36.4054 8.65799 37.2081 9.35479 37.5015C10.0516 37.7949 10.8543 37.4679 11.1477 36.7711L13.9157 30.197C15.8576 31.5181 17.1358 33.7499 17.1358 36.2752C17.1358 40.3217 13.8542 43.6133 9.82168 43.6133C5.7895 43.6133 2.50791 40.322 2.50791 36.2752Z"
        fill={fillColor}
      />
      <rect
        x="9.93359"
        y="5.55859"
        width="8.11839"
        height="10.5906"
        rx="1"
        transform="rotate(29.4089 9.93359 5.55859)"
        fill={fillColor}
      />
    </svg>
  );
});
