import React, { useCallback } from "react";
import styled from "styled-components";
import { colorsConst } from "styles/const";
import { SubmittableOptionSetType } from "records/ShopItemOptionSet";
import { ShopItemOption } from "records/ShopItemOption";
import { ShopItemSubmitType } from "modules/shop/model";
import { ShopItemStatus } from "./ShopItemStatus";

const Container = styled.div`
  margin: 0 0.5em;
  padding: 0.25em 0 0.5em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
  border-top: 1px solid ${colorsConst.BORDER.THIN};
`;

const OptionName = styled.div`
  margin-bottom: 0.25em;
`;

type Props = {
  optionSetId: number;
  option: ShopItemOption;
  submitting: boolean;
  handleStatus: (optionSet: SubmittableOptionSetType, submitType: ShopItemSubmitType) => void;
};

export const ShopOptionListItem: React.FC<Props> = React.memo(
  ({ optionSetId, option, submitting, handleStatus }) => {
    const handleStatusWrap = useCallback(
      (itemId: number, submitType: ShopItemSubmitType) => {
        handleStatus({ option_set_id: optionSetId, stop_option_id_list: [itemId] }, submitType);
      },
      [optionSetId, handleStatus],
    );
    return (
      <Container>
        <OptionName>{option.option_name}</OptionName>
        <ShopItemStatus
          itemId={option.shop_option_id}
          isReleased={option.is_release}
          isOrderStop={option.is_order_stop}
          submitting={submitting}
          handleStatus={handleStatusWrap}
        />
      </Container>
    );
  },
);
