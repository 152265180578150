import React from "react";
import styled from "styled-components";
import { transitionConst } from "styles/const";

const Container = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  .TransitionWrapper-enter & {
    opacity: 0;
  }
  .TransitionWrapper-enter-active & {
    opacity: 1;
    will-change: opacity;
    transition-property: opacity;
    transition-timing-function: ease-out;
    transition-duration: ${transitionConst.DURATION / 2000}s;
    transition-delay: ${transitionConst.DURATION / 2000}s;
  }
  .TransitionWrapper-exit & {
    opacity: 1;
  }
  .TransitionWrapper-exit-active & {
    opacity: 0;
    will-change: opacity;
    transition-property: opacity;
    transition-timing-function: ease-in;
    transition-duration: ${transitionConst.DURATION / 2000}s;
    transition-delay: 0s;
  }
  .TransitionWrapperPush-enter & {
    opacity: 0;
    /* transform: translate3d(100%, 0, 0); */
  }
  .TransitionWrapperPush-enter-active & {
    opacity: 1;
    will-change: opacity;
    transition-property: opacity;
    transition-duration: ${(transitionConst.DURATION / 1000) * 0.8}s;
    transition-timing-function: ease-out;
    /* transform: translate3d(0, 0, 0); */
  }
  .TransitionWrapperPop-enter-active & {
    opacity: 0;
    /* transform: none; */
  }
  .TransitionWrapperPop-enter-active + .TransitionWrapperPush-exit-active & {
    opacity: 0;
    will-change: opacity;
    transition-property: opacity;
    transition-duration: ${(transitionConst.DURATION / 1000) * 0.8}s;
    transition-timing-function: linear;
    /* transform: translate3d(100%, 0, 0); */
  }
  .TransitionWrapperPop-exit & {
    /* transform: translate3d(0, 0, 0); */
  }
  .TransitionWrapperPop-exit-active & {
    will-change: opacity;
    transition-property: opacity;
    transition-duration: ${(transitionConst.DURATION / 1000) * 0.8}s;
    transition-timing-function: ease-in;
    /* transform: translate3d(100%, 0, 0); */
  }
  .TransitionWrapperPush-enter + .TransitionWrapperPop-exit-active & {
    transform: none;
  }
`;

export const TransitionContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = React.memo(
  (props: React.HTMLAttributes<HTMLDivElement>) => <Container {...props} />,
);
