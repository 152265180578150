import { DateUtils } from "utils/DateUtils";
import { LatLng } from "./LatLng";
import { AutoPrintSetting } from "./AutoPrintSetting";

/**
 * 店舗公開ステータス
 */
export const SHOP_RELEASE_STATE = {
  PRIVATE: 0, // 新規
  STOP: 1, // 一時停止
  PUBLISHED: 2, // 公開
  LIMITED: 9, // 限定公開
  CANCEL: 99, // 解約
} as const;

export type ShopReleaseState = ValueOf<typeof SHOP_RELEASE_STATE>;

export const BANK_REGISTER_STATE = {
  UNREGISTERED: 1, // 未登録
  UNEXAMINED: 2, // 未検証
  CORRECT: 3, // 正常
  INCORRECT: 4, // 異常
  WAITING_RE_REGISTER: 5, // 再入力待ち
} as const;

export type BankRegisterState = ValueOf<typeof BANK_REGISTER_STATE>;

export const SHOP_TYPE = {
  NORMAL: 1,
  RETAIL: 2,
  GROCERY: 3,
};
export type ShopType = ValueOf<typeof SHOP_TYPE>;

export const SHOP_COMMERCIAL_FACILITY_TYPE = {
  NOT_COMMERCIAL_FACILITY: 0, // 複合商業施設ではない
  LAZONA: 1, // ラゾーナ川崎
} as const;

export type ShopCommercialFacilityType = ValueOf<typeof SHOP_COMMERCIAL_FACILITY_TYPE>;

export type ShopData = {
  id: number;
  person_in_charge_name: string;
  person_in_charge_name_ruby: string;
  mail: string;
  shop_name: string;
  shop_name_ruby: string;
  shop_name_en: string;
  tel: string;
  fax: string;
  postal_code: string;
  address: string;
  address_en: string;
  business_start_hours: string;
  business_end_hours: string;
  description: string;
  standard_cooking_time: number;
  standard_cooking_add_time: number;
  display_standard_cooking_time: string;
  category_tag: string[];
  meta_tag: string[];
  header_image_path: string;
  icon_image_path: string;
  release_state: ShopReleaseState;
  mail_notification_flag: number;
  tel_notification_flag: number;
  shop_order_stop_flag: boolean;
  item_order_stop_count: number;
  is_luxury_flag: boolean;
  coordinates: LatLng;
  is_campaign_period: boolean;
  is_takeout: boolean;
  is_delivery: boolean;
  bank_state: BankRegisterState;
  is_local_area: boolean;
  is_firestore_notification: boolean;
  shop_type: ShopType;
  is_reserve_order: boolean;
  is_auto_hold: boolean;
  restart_reserved_datetime: string;
  is_costco: boolean;
  auto_print_setting: AutoPrintSetting;
  is_immediate_reserve_hybrid: boolean;
  is_immediate_delivery_paused: boolean;
  is_reserve_order_pick_time: boolean;
  is_standby_area_auto_approve: boolean;
  shop_commercial_facility_type: ShopCommercialFacilityType;
  is_display_images: boolean;
  is_crew_about_to_arrive_notification: boolean;
  is_crew_arrived_notification: boolean;
};

const initialState: ShopData = {
  id: 0,
  person_in_charge_name: "",
  person_in_charge_name_ruby: "",
  mail: "",
  shop_name: "",
  shop_name_ruby: "",
  shop_name_en: "",
  tel: "",
  fax: "",
  postal_code: "",
  address: "",
  address_en: "",
  business_start_hours: "",
  business_end_hours: "",
  description: "",
  standard_cooking_time: 0,
  standard_cooking_add_time: 0,
  display_standard_cooking_time: "",
  category_tag: [],
  meta_tag: [],
  header_image_path: "",
  icon_image_path: "",
  release_state: SHOP_RELEASE_STATE.PRIVATE,
  mail_notification_flag: 0,
  tel_notification_flag: 0,
  shop_order_stop_flag: false,
  item_order_stop_count: 0,
  is_luxury_flag: false,
  coordinates: LatLng.create(),
  is_campaign_period: false,
  is_takeout: false,
  is_delivery: false,
  bank_state: BANK_REGISTER_STATE.UNREGISTERED,
  is_local_area: false,
  is_firestore_notification: true,
  shop_type: SHOP_TYPE.NORMAL,
  is_reserve_order: false,
  is_auto_hold: false,
  restart_reserved_datetime: "",
  is_costco: false,
  auto_print_setting: AutoPrintSetting.create(),
  is_immediate_reserve_hybrid: false,
  is_immediate_delivery_paused: false,
  is_reserve_order_pick_time: false,
  is_standby_area_auto_approve: false,
  shop_commercial_facility_type: SHOP_COMMERCIAL_FACILITY_TYPE.NOT_COMMERCIAL_FACILITY,
  is_display_images: false,
  is_crew_about_to_arrive_notification: true,
  is_crew_arrived_notification: true,
};

const create = (args: Partial<ShopData> = {}) => ({
  ...initialState,
  ...args,
  coordinates: args.coordinates ? LatLng.create(args.coordinates) : LatLng.create(),
  auto_print_setting: args.auto_print_setting
    ? AutoPrintSetting.create(args.auto_print_setting)
    : AutoPrintSetting.create(),
});

const existsShopData = (id: number) => id !== 0;

const existsLatLng = (latlng: LatLng) => latlng.latitude !== "" && latlng.longitude !== "";

const isPublished = (shopData: ShopData) => shopData.release_state === SHOP_RELEASE_STATE.PUBLISHED;

const isRetail = (type: ShopType) => type === SHOP_TYPE.RETAIL;

const isGrocery = (type: ShopType) => type === SHOP_TYPE.GROCERY;

const isShopTypeThatCanChangeItemCount = (type: ShopType) =>
  ([SHOP_TYPE.RETAIL, SHOP_TYPE.GROCERY] as Array<ShopType>).includes(type);

const isLocalAreaAndAutoApprove = (shopData: ShopData) =>
  shopData.is_local_area && shopData.is_standby_area_auto_approve;

const isAcceptingReservedOrder = (shopData: ShopData) =>
  shopData.is_reserve_order || shopData.is_immediate_reserve_hybrid;

const isLazona = (type: ShopCommercialFacilityType) =>
  type === SHOP_COMMERCIAL_FACILITY_TYPE.LAZONA;

const canDisplayBankRegisterError = (shopData: ShopData) => {
  const { id, bank_state } = shopData;
  return (
    existsShopData(id) &&
    (
      [
        BANK_REGISTER_STATE.UNREGISTERED,
        BANK_REGISTER_STATE.INCORRECT,
        BANK_REGISTER_STATE.WAITING_RE_REGISTER,
      ] as Array<BankRegisterState>
    ).includes(bank_state)
  );
};

const existsShopRestartTime = (time: string) => DateUtils.isValidDateString(time);

const getPresentationBankRegisterStateText = (state: BankRegisterState) => {
  switch (state) {
    case BANK_REGISTER_STATE.UNREGISTERED:
      return "口座情報が登録されていないため、入金ができません。\n受取口座を登録してください。";
    case BANK_REGISTER_STATE.WAITING_RE_REGISTER:
    case BANK_REGISTER_STATE.INCORRECT:
      return "口座情報誤り、またはお客様のご依頼により口座情報をリセットいたしました。\n受取口座を更新してください。";
    default:
      return "";
  }
};

const getPresentationAavailableReceiveTypeText = (shopData: ShopData) =>
  shopData.is_takeout === shopData.is_delivery
    ? "持ち帰り・配達"
    : shopData.is_takeout
    ? "持ち帰り"
    : "配達";

const getPresentationReleaseStateText = (state: ShopReleaseState) => {
  switch (state) {
    case SHOP_RELEASE_STATE.PRIVATE:
    case SHOP_RELEASE_STATE.LIMITED:
      return "準備中";
    case SHOP_RELEASE_STATE.STOP:
      return "停止中";
    case SHOP_RELEASE_STATE.PUBLISHED:
      return "公開中";
    case SHOP_RELEASE_STATE.CANCEL:
      return "解約済み";
    default:
      return "";
  }
};

const getPresentationNotPublishedText = (releaseState: ShopReleaseState) => {
  switch (releaseState) {
    case SHOP_RELEASE_STATE.PRIVATE:
    case SHOP_RELEASE_STATE.LIMITED:
      return "現在準備中、アプリ上非公開です。";
    case SHOP_RELEASE_STATE.STOP:
      return "公開が停止されました。詳細はsup@menu.incへメールでお問い合わせください。";
    default:
      return "";
  }
};

const isShowDisplayImages = (shopData: ShopData) => shopData.is_display_images;

export const ShopData = Object.freeze({
  initialState,
  create,
  existsShopData,
  existsLatLng,
  isPublished,
  isRetail,
  isGrocery,
  isShopTypeThatCanChangeItemCount,
  isLocalAreaAndAutoApprove,
  isAcceptingReservedOrder,
  isLazona,
  canDisplayBankRegisterError,
  existsShopRestartTime,
  getPresentationBankRegisterStateText,
  getPresentationAavailableReceiveTypeText,
  getPresentationReleaseStateText,
  getPresentationNotPublishedText,
  isShowDisplayImages,
});
