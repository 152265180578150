import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { replace } from "connected-react-router";
import styled from "styled-components";
import { API_KEY } from "apis";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { orderHistorySelector, orderItemTotalPriceSelector } from "modules/order/selectors";
import { userAccessedToPageThatNeedsDailyOrderHistory } from "modules/order/actions";
import {
  OrderModel,
  OrderHistoryStateFilterType,
  ORDER_HISTORY_STATE_FILTER_TYPE,
} from "modules/order/model";
import { userAccessedToPageThatNeedsShopData } from "modules/shop/actions";
import { shopDataSelector } from "modules/shop/selectors";
import { colorsConst } from "styles/const";
import { DateUtils } from "utils/DateUtils";
import { PriceUtils } from "utils/PriceUtils";
import { Contents } from "components/atoms/Contents";
import { Text } from "components/atoms/Text";
import { Calendar } from "components/atoms/Calendar";
import { NoData } from "components/atoms/NoData";
import { Article } from "components/organisms/Article";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { OrderHistoryHeader } from "components/molecules/OrderHistoryHeader";
import { OrderHistory } from "components/organisms/OrderHistory";
import { OrderListContext, OrderListContextValue } from "components/organisms/OrderListContext";

const CalendarContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const TotalPriceContainer = styled.div`
  position: relative;
  z-index: 2;
  margin: -1em -1em 1em;
  padding: 0.5em;
  text-align: center;
  background-color: ${colorsConst.BACKGROUND.INFO};
`;

const NoDataContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const isConnectedApiSelector = isConnectedApiSelectorFactory([API_KEY.GET_DAILY_ORDER_HISTORY]);

type Props = RouteComponentProps<{ date?: string }> & {};

const OrderHistoryTemplate: React.FC<Props> = React.memo(
  ({
    match: {
      params: { date },
    },
  }) => {
    const shopData = useSelector(shopDataSelector);
    const loading = useSelector(isConnectedApiSelector);
    const orderHistoryList = useSelector(orderHistorySelector);
    const orderItemTotalPrice = useSelector(orderItemTotalPriceSelector);

    const [selectedDate, setSelectedDate] = useState<string>(
      typeof date !== "undefined" && DateUtils.isValidDateString(date)
        ? DateUtils.dateToString(date, "YYYYMMDD")
        : DateUtils.dateToString(new Date(), "YYYYMMDD"),
    );
    const [displayCalendar, setDisplayCalendar] = useState(false);

    const [displayableType, setDisplayableType] = useState<OrderHistoryStateFilterType>(
      ORDER_HISTORY_STATE_FILTER_TYPE.ALL,
    );

    const filteredOrderHistoryList = useMemo(
      () => OrderModel.getFilteredOrderHistoryList(orderHistoryList, displayableType),
      [orderHistoryList, displayableType],
    );

    const existsList = useMemo(
      () => filteredOrderHistoryList.length > 0,
      [filteredOrderHistoryList],
    );

    const dispatch = useDispatch();

    const handleGoBack = useCallback(() => {
      dispatch(replace("/orderList"));
    }, [dispatch]);

    const toggleDisplayCalendar = useCallback(() => {
      setDisplayCalendar(!displayCalendar);
    }, [displayCalendar]);

    const handleClickCalendar = useCallback((date: Date) => {
      setSelectedDate(DateUtils.dateToString(date, "YYYYMMDD"));
      setDisplayCalendar(false);
    }, []);

    const handleClickPrevDate = useCallback(() => {
      const prevDate = DateUtils.subtractOneDay(selectedDate);
      setSelectedDate(prevDate);
      setDisplayCalendar(false);
    }, [selectedDate]);

    const handleClickNextDate = useCallback(() => {
      const nextDate = DateUtils.addOneDay(selectedDate);
      if (!DateUtils.isToday(selectedDate)) {
        setSelectedDate(nextDate);
      }
      setDisplayCalendar(false);
    }, [selectedDate]);

    useEffect(() => {
      dispatch(userAccessedToPageThatNeedsShopData());
    }, [dispatch]);

    useEffect(() => {
      dispatch(userAccessedToPageThatNeedsDailyOrderHistory(selectedDate));
    }, [dispatch, selectedDate]);

    const contextValue = useMemo<OrderListContextValue>(
      () => ({
        shopData,
      }),
      [shopData],
    );

    return (
      <OrderListContext.Provider value={contextValue}>
        <Article
          withHeader
          headerComponent={
            <OrderHistoryHeader
              orderHistoryList={orderHistoryList}
              selectedDate={selectedDate}
              handleGoBack={handleGoBack}
              toggleDisplayCalendar={toggleDisplayCalendar}
              handleClickPrevDate={handleClickPrevDate}
              handleClickNextDate={handleClickNextDate}
              displayableType={displayableType}
              setDisplayableType={setDisplayableType}
            />
          }
          title="注文履歴"
          withNavigation
          activePath="ORDER_HISTORY"
        >
          {loading ? (
            <LoadingContainer />
          ) : (
            <Contents>
              <TotalPriceContainer>
                <Text size="LARGE" weight="MEDIUM">
                  商品代金合計：
                  <Text weight="BOLD">{PriceUtils.getPresentationValue(orderItemTotalPrice)}</Text>
                </Text>
              </TotalPriceContainer>
              {existsList ? (
                <OrderHistory orderHistoryList={filteredOrderHistoryList} />
              ) : (
                <NoDataContainer>
                  <NoData>注文履歴はありません</NoData>
                </NoDataContainer>
              )}
            </Contents>
          )}
        </Article>
        <CalendarContainer>
          <Calendar
            isOpen={displayCalendar}
            handleClick={handleClickCalendar}
            handleClickBackDrop={toggleDisplayCalendar}
            maxDate={new Date()}
            maxDetail="month"
            minDetail="month"
          />
        </CalendarContainer>
      </OrderListContext.Provider>
    );
  },
);

export default withRouter(OrderHistoryTemplate);
