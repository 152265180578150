import { OrderData } from "records/OrderData";
import { DraftItem } from "records/OrderItemData";
import { ShopData } from "records/ShopData";
import { OrderNotification, NotificationType } from "records/OrderNotification";
import { OrderNotificationStack } from "records/OrderNotificationStack";
import { StopReasonShopItems } from "apis";
import { OrderListTabType, DEFAULT_POLLING_INTERVAL } from "./model";

export enum ActionTypes {
  U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_LIST = "order/U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_LIST",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_DETAIL = "order/U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_DETAIL",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_DAILY_ORDER_HISTORY = "order/U_ACCESSED_TO_PAGE_THAT_NEEDS_DAILY_ORDER_HISTORY",
  U_TOUCHED_ORDER_NOTIFICATION_DIALOG = "order/U_TOUCHED_ORDER_NOTIFICATION_DIALOG",
  U_TOUCHED_ORDER_NOTIFICATION_BACKDROP = "order/U_TOUCHED_ORDER_NOTIFICATION_BACKDROP",
  U_SUBMITTED_FINISH_ORDER = "order/U_SUBMITTED_FINISH_ORDER",
  U_SUBMITTED_FINISH_ORDER_FOR_LOCAL_AREA_AND_AUTO_APPROVE = "order/U_SUBMITTED_FINISH_ORDER_FOR_LOCAL_AREA_AND_AUTO_APPROVE",
  U_SUBMITTED_APPROVE_ORDER = "order/U_SUBMITTED_APPROVE_ORDER",
  U_SUBMITTED_HOLD_ORDER = "order/U_SUBMITTED_HOLD_ORDER",
  U_SUBMITTED_DENY_ORDER = "order/U_SUBMITTED_DENY_ORDER",
  U_SUBMITTED_DENY_ORDER_BY_REQUIRED_ITEM_SHORTAGE = "order/U_SUBMITTED_DENY_ORDER_BY_REQUIRED_ITEM_SHORTAGE",
  U_SUBMITTED_DENY_ORDER_AFTER_APPROVE = "order/U_SUBMITTED_DENY_ORDER_AFTER_APPROVE",
  U_TOUCHED_HOLD_ORDER_FOR_GROCERY_BUTTON = "order/U_TOUCHED_HOLD_ORDER_FOR_GROCERY_BUTTON",
  U_SUBMITTED_APPROVE_ORDER_FOR_GROCERY = "order/U_SUBMITTED_APPROVE_ORDER_FOR_GROCERY",
  U_CHANGED_PICK_STATUS = "order/U_CHANGED_PICK_STATUS",
  U_DEBUG_UPDATE_NOTIFICATION_STACK = "order/U_DEBUG_UPDATE_NOTIFICATION_STACK",
  U_TOUCHED_ORDER_LIST_TAB = "order/U_TOUCHED_ORDER_LIST_TAB",

  S_UPDATE_ORDER_DATA = "order/S_UPDATE_ORDER_DATA",
  S_INIT_ORDER_DATA = "order/S_INIT_ORDER_DATA",
  S_WATCH_NOTIFICATION = "order/S_WATCH_NOTIFICATION",
  S_UNWATCH_NOTIFICATION = "order/S_UNWATCH_NOTIFICATION",
  S_INIT_NOTIFICATION_STACK = "order/S_INIT_NOTIFICATION_STACK",
  S_WATCH_PICKER_ASSIGNMENTS = "order/S_WATCH_PICKER_ASSIGNMENTS",
  S_INIT_ORDER_LIST_TAB_TYPE = "order/S_INIT_ORDER_LIST_TAB_TYPE",

  R_UPDATE_ORDER_LIST = "order/R_UPDATE_ORDER_LIST",
  R_UPDATE_ORDER_DETAIL = "order/R_UPDATE_ORDER_DETAIL",
  R_UPDATE_BUSY_FLAG = "order/R_UPDATE_BUSY_FLAG",
  R_UPDATE_ORDER_HISTORY = "order/R_UPDATE_ORDER_HISTORY",
  R_UPDATE_ORDER_NOTIFICATION_STACK = "order/R_UPDATE_ORDER_NOTIFICATION_STACK",
  R_UPDATE_ORDER_ITEM_TOTAL_PRICE = "order/R_UPDATE_ORDER_ITEM_TOTAL_PRICE",
  R_UPDATE_ORDER_LIST_TAB_TYPE = "order/R_UPDATE_ORDER_LIST_TAB_TYPE",
}

export const userAccessedToPageThatNeedsOrderList = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_LIST,
  } as const);
export type UserAccessedToPageThatNeedsOrderListAction = ReturnType<
  typeof userAccessedToPageThatNeedsOrderList
>;

export const userAccessedToPageThatNeedsOrderDetail = (orderId: string) =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_DETAIL,
    payload: {
      orderId,
    },
  } as const);
export type UserAccessedToPageThatNeedsOrderDetailAction = ReturnType<
  typeof userAccessedToPageThatNeedsOrderDetail
>;

export const userAccessedToPageThatNeedsDailyOrderHistory = (date: string) =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_DAILY_ORDER_HISTORY,
    payload: {
      date,
    },
  } as const);
export type UserAccessedToPageThatNeedsDailyOrderHistoryAction = ReturnType<
  typeof userAccessedToPageThatNeedsDailyOrderHistory
>;

export const userTouchedOrderNotificationDialog = (
  notifcation: OrderNotification,
  shopData: ShopData,
) =>
  ({
    type: ActionTypes.U_TOUCHED_ORDER_NOTIFICATION_DIALOG,
    payload: {
      notifcation,
      shopData,
    },
  } as const);
export type UserTouchedOrderNotificationDialogAction = ReturnType<
  typeof userTouchedOrderNotificationDialog
>;

export const userTouchedOrderNotificationBackdrop = (
  orderId: string,
  notifcationType: NotificationType,
) =>
  ({
    type: ActionTypes.U_TOUCHED_ORDER_NOTIFICATION_BACKDROP,
    payload: {
      orderId,
      notifcationType,
    },
  } as const);
export type UserTouchedOrderNotificationBackdropAction = ReturnType<
  typeof userTouchedOrderNotificationBackdrop
>;

export const userSubmittedApproveOrder = (
  order_no: string,
  add_minutes: number,
  add_cooking_start_remind_minutes: number,
  localAreaFlag: boolean,
  isRetail: boolean,
  isAllowedOutOfStock: boolean,
  draftItems: DraftItem[],
  withUpdateCookingTime?: boolean,
  shopItemStopReasons?: StopReasonShopItems[],
) =>
  ({
    type: ActionTypes.U_SUBMITTED_APPROVE_ORDER,
    payload: {
      order_no,
      add_minutes,
      add_cooking_start_remind_minutes,
      localAreaFlag,
      isRetail,
      isAllowedOutOfStock,
      draftItems,
      withUpdateCookingTime,
      shopItemStopReasons,
    },
  } as const);
export type UserSubmittedApproveOrderAction = ReturnType<typeof userSubmittedApproveOrder>;

export const userSubmittedFinishOrder = (orderData: OrderData) =>
  ({
    type: ActionTypes.U_SUBMITTED_FINISH_ORDER,
    payload: {
      orderData,
    },
  } as const);
export type UserSubmittedFinishOrderAction = ReturnType<typeof userSubmittedFinishOrder>;

export const userSubmittedFinishOrderForLocalAreaAndAutoApprove = (
  orderData: OrderData,
  draftItems: DraftItem[],
  isAllowedOutOfStock: boolean,
) =>
  ({
    type: ActionTypes.U_SUBMITTED_FINISH_ORDER_FOR_LOCAL_AREA_AND_AUTO_APPROVE,
    payload: {
      orderData,
      draftItems,
      isAllowedOutOfStock,
    },
  } as const);
export type UserSubmittedFinishOrderForLocalAreaAndAutoApproveAction = ReturnType<
  typeof userSubmittedFinishOrderForLocalAreaAndAutoApprove
>;

export const userSubmittedHoldOrder = (orderData: OrderData, isRetail: boolean) =>
  ({
    type: ActionTypes.U_SUBMITTED_HOLD_ORDER,
    payload: {
      orderData,
      isRetail,
    },
  } as const);
export type UserSubmittedHoldOrderAction = ReturnType<typeof userSubmittedHoldOrder>;

export const userSubmittedDenyOrder = (
  orderData: OrderData,
  shopItemStopReasons?: StopReasonShopItems[],
) =>
  ({
    type: ActionTypes.U_SUBMITTED_DENY_ORDER,
    payload: {
      orderData,
      shopItemStopReasons,
    },
  } as const);
export type UserSubmittedDenyOrderAction = ReturnType<typeof userSubmittedDenyOrder>;

export const userSubmittedDenyOrderByRequiredItemShortage = (
  orderData: OrderData,
  shopItemStopReasons?: StopReasonShopItems[],
) =>
  ({
    type: ActionTypes.U_SUBMITTED_DENY_ORDER_BY_REQUIRED_ITEM_SHORTAGE,
    payload: {
      orderData,
      shopItemStopReasons,
    },
  } as const);
export type UserSubmittedDenyOrderByRequiredItemShortage = ReturnType<
  typeof userSubmittedDenyOrderByRequiredItemShortage
>;

export const userSubmittedDenyOrderAfterApprove = (
  orderData: OrderData,
  shopItemStopReasons?: StopReasonShopItems[],
) =>
  ({
    type: ActionTypes.U_SUBMITTED_DENY_ORDER_AFTER_APPROVE,
    payload: {
      orderData,
      shopItemStopReasons,
    },
  } as const);
export type UserSubmittedDenyOrderAfterApproveAction = ReturnType<
  typeof userSubmittedDenyOrderAfterApprove
>;

export const userTouchedHoldOrderForGroceryButton = (orderData: OrderData, addMinutes: number) =>
  ({
    type: ActionTypes.U_TOUCHED_HOLD_ORDER_FOR_GROCERY_BUTTON,
    payload: {
      orderData,
      addMinutes,
    },
  } as const);
export type UserTouchedHoldOrderForGroceryButtonAction = ReturnType<
  typeof userTouchedHoldOrderForGroceryButton
>;

export const userSubmittedApproveOrderForGrocery = (
  orderData: OrderData,
  draftItems: DraftItem[],
  add_minutes: number,
  shopItemStopReasons?: StopReasonShopItems[],
) =>
  ({
    type: ActionTypes.U_SUBMITTED_APPROVE_ORDER_FOR_GROCERY,
    payload: {
      orderData,
      draftItems,
      add_minutes,
      shopItemStopReasons,
    },
  } as const);
export type UserSubmittedApproveOrderForGroceryAction = ReturnType<
  typeof userSubmittedApproveOrderForGrocery
>;

export const userChangedPickStatus = (
  order_no: string,
  order_shop_item_id_group: number,
  draft_item_counts: number,
  is_picked: boolean,
  callback?: () => void,
) =>
  ({
    type: ActionTypes.U_CHANGED_PICK_STATUS,
    payload: {
      order_no,
      order_shop_item_id_group,
      draft_item_counts,
      is_picked,
      callback,
    },
  } as const);
export type UserChangedPickStatusAction = ReturnType<typeof userChangedPickStatus>;

export const userDebugUpdateNotificationStack = (notification: OrderNotification) =>
  ({
    type: ActionTypes.U_DEBUG_UPDATE_NOTIFICATION_STACK,
    payload: {
      notification,
    },
  } as const);
export type UserDebugUpdateNotificationStackAction = ReturnType<
  typeof userDebugUpdateNotificationStack
>;

export const userTouchedOrderListTab = (tabType: OrderListTabType) =>
  ({
    type: ActionTypes.U_TOUCHED_ORDER_LIST_TAB,
    payload: {
      tabType,
    },
  } as const);
export type UserTouchedOrderListTabAction = ReturnType<typeof userTouchedOrderListTab>;

export const systemUpdateOrderData = () =>
  ({
    type: ActionTypes.S_UPDATE_ORDER_DATA,
  } as const);
export type SystemUpdateOrderDataAction = ReturnType<typeof systemUpdateOrderData>;

export const systemInitOrderData = () =>
  ({
    type: ActionTypes.S_INIT_ORDER_DATA,
  } as const);
export type SystemInitOrderDataAction = ReturnType<typeof systemInitOrderData>;

// firestore監視開始
export const systemWatchNotification = (
  shopId: number,
  useFireStore: boolean,
  pollingInterval: number = DEFAULT_POLLING_INTERVAL,
) =>
  ({
    type: ActionTypes.S_WATCH_NOTIFICATION,
    payload: {
      shopId,
      useFireStore,
      pollingInterval,
    },
  } as const);
export type SystemWatchNotificationAction = ReturnType<typeof systemWatchNotification>;

// firestore監視停止
export const systemUnwatchNotification = () =>
  ({
    type: ActionTypes.S_UNWATCH_NOTIFICATION,
  } as const);
export type SystemUnwatchNotificationAction = ReturnType<typeof systemUnwatchNotification>;

export const systemInitNotificationStack = () =>
  ({
    type: ActionTypes.S_INIT_NOTIFICATION_STACK,
  } as const);
export type SystemInitNotificationStackAction = ReturnType<typeof systemInitNotificationStack>;

export const systemWatchPickerAssignments = (
  shopId: number,
  shopStaffId: number,
  useFirestore: boolean,
) =>
  ({
    type: ActionTypes.S_WATCH_PICKER_ASSIGNMENTS,
    payload: {
      shopId,
      shopStaffId,
      useFirestore,
    },
  } as const);
export type SystemWatchPickerAssignmentsAction = ReturnType<typeof systemWatchPickerAssignments>;

export const systemInitOrderListTabType = () =>
  ({
    type: ActionTypes.S_INIT_ORDER_LIST_TAB_TYPE,
  } as const);
export type SystemInitOrderListTabTypeAction = ReturnType<typeof systemInitOrderListTabType>;

export const updateOrderList = (orderList: OrderData[]) =>
  ({
    type: ActionTypes.R_UPDATE_ORDER_LIST,
    payload: {
      orderList,
    },
  } as const);
export type ReducerUpdateOrderListAction = ReturnType<typeof updateOrderList>;

export const updateOrderDetail = (order: OrderData) =>
  ({
    type: ActionTypes.R_UPDATE_ORDER_DETAIL,
    payload: {
      order,
    },
  } as const);
export type ReducerUpdateOrderDetailAction = ReturnType<typeof updateOrderDetail>;

export const updateBusyFlag = (flag: boolean) =>
  ({
    type: ActionTypes.R_UPDATE_BUSY_FLAG,
    payload: {
      flag,
    },
  } as const);
export type ReducerUpdateBusyFlagAction = ReturnType<typeof updateBusyFlag>;

export const updateOrderHistory = (orderList: OrderData[]) =>
  ({
    type: ActionTypes.R_UPDATE_ORDER_HISTORY,
    payload: {
      orderList,
    },
  } as const);
export type ReducerUpdateOrderHistoryAction = ReturnType<typeof updateOrderHistory>;

export const updateOrderNotificationStack = (notification: OrderNotificationStack) =>
  ({
    type: ActionTypes.R_UPDATE_ORDER_NOTIFICATION_STACK,
    payload: {
      notification,
    },
  } as const);
export type ReducerUpdateOrderNotificationStackAction = ReturnType<
  typeof updateOrderNotificationStack
>;

export const updateOrderItemTotalPrice = (orderItemTotalPrice: number) =>
  ({
    type: ActionTypes.R_UPDATE_ORDER_ITEM_TOTAL_PRICE,
    payload: {
      orderItemTotalPrice,
    },
  } as const);
export type ReducerUpdateOrderItemTotalPriceAction = ReturnType<typeof updateOrderItemTotalPrice>;

export const updateOrderListTabType = (tabType: OrderListTabType) =>
  ({
    type: ActionTypes.R_UPDATE_ORDER_LIST_TAB_TYPE,
    payload: {
      tabType,
    },
  } as const);
export type ReducerUpdateOrderListTabTypeAction = ReturnType<typeof updateOrderListTabType>;

export type OrderActions =
  | UserAccessedToPageThatNeedsOrderListAction
  | UserAccessedToPageThatNeedsOrderDetailAction
  | UserAccessedToPageThatNeedsDailyOrderHistoryAction
  | UserTouchedOrderNotificationDialogAction
  | UserTouchedOrderNotificationBackdropAction
  | UserSubmittedApproveOrderAction
  | UserSubmittedFinishOrderAction
  | UserSubmittedFinishOrderForLocalAreaAndAutoApproveAction
  | UserSubmittedHoldOrderAction
  | UserSubmittedDenyOrderAction
  | UserSubmittedDenyOrderByRequiredItemShortage
  | UserSubmittedDenyOrderAfterApproveAction
  | UserTouchedHoldOrderForGroceryButtonAction
  | UserSubmittedApproveOrderForGroceryAction
  | UserChangedPickStatusAction
  | UserDebugUpdateNotificationStackAction
  | UserTouchedOrderListTabAction
  | SystemUpdateOrderDataAction
  | SystemInitOrderDataAction
  | SystemWatchNotificationAction
  | SystemUnwatchNotificationAction
  | SystemInitNotificationStackAction
  | SystemWatchPickerAssignmentsAction
  | SystemInitOrderListTabTypeAction
  | ReducerUpdateOrderListAction
  | ReducerUpdateOrderDetailAction
  | ReducerUpdateBusyFlagAction
  | ReducerUpdateOrderHistoryAction
  | ReducerUpdateOrderNotificationStackAction
  | ReducerUpdateOrderItemTotalPriceAction
  | ReducerUpdateOrderListTabTypeAction;
