import { put, fork, call } from "redux-saga/effects";
import { API_KEY, GetShopBusinessResponse } from "apis";
import { commonApiSaga } from "modules/app/sagas/commonApiSaga";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateShopBusiness } from "../actions";

export function* userAccessedToPageThatNeedsShopBusinessSaga() {
  try {
    const { result, error }: { result: GetShopBusinessResponse; error: unknown } = yield call(
      commonApiSaga,
      API_KEY.GET_SHOP_BUSINESS,
      {},
    );
    if (result && !error) {
      yield put(updateShopBusiness(result.shop_business));
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
