import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {};

export const IconRestartOrderByShop: React.FC<Props> = React.memo(({ ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 168 142" fill="none" {...props}>
    <rect
      x="3"
      y="67.2969"
      width="162.211"
      height="71.7018"
      rx="4"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
    />
    <path
      d="M34.7344 67.3013L75.8747 16.168M92.3309 16.7557L133.471 67.3013"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
    />
    <circle cx="84.1073" cy="12.0526" r="9.55263" stroke={colorsConst.ICON.WHITE} strokeWidth="5" />
    <path
      d="M43.9085 90.1602C35.9343 90.1602 29.7656 95.4261 29.7656 104.378C29.7656 110.923 34.0536 118.333 43.2691 118.333C50.9423 118.333 57.4496 113.18 57.4496 104.002C57.4496 97.4949 53.2368 90.1602 43.9085 90.1602ZM43.6828 96.4793C48.8359 96.4793 50.9047 100.692 50.9047 104.416C50.9047 109.343 47.7075 112.352 43.5324 112.352C38.3416 112.352 36.3105 108.253 36.3105 104.416C36.3105 99.6765 39.282 96.4793 43.6828 96.4793Z"
      fill={colorsConst.ICON.WHITE}
    />
    <path
      d="M68.7114 118.107C69.5766 118.107 69.8022 117.957 69.8022 117.167C69.8022 116.904 69.6894 112.616 69.6894 110.321C69.6894 109.381 69.8775 109.193 70.8178 109.193H72.8866C80.2966 109.193 83.8699 105.018 83.8699 98.9242C83.8699 93.2445 80.3342 90.3858 74.5792 90.3858H69.6518C67.9215 90.3858 64.6115 90.3106 64.3482 90.3106C63.5583 90.3106 63.295 90.5739 63.295 91.3638C63.295 91.6271 63.4079 95.8023 63.4079 97.7958V110.585C63.4079 112.578 63.295 116.904 63.295 117.167C63.295 117.957 63.5207 118.107 64.3858 118.107H68.7114ZM69.6894 97.1188C69.6894 96.1784 69.8775 95.9903 70.8178 95.9903H73.4884C76.2719 95.9903 77.4379 97.2692 77.4379 99.5261C77.4379 102.385 75.7076 103.852 72.849 103.852H70.8178C69.8775 103.852 69.6894 103.664 69.6894 102.723V97.1188Z"
      fill={colorsConst.ICON.WHITE}
    />
    <path
      d="M97.2358 112.352C96.2955 112.352 96.1074 112.164 96.1074 111.224V107.35C96.1074 106.485 96.2578 106.334 97.123 106.334H98.2138C99.9816 106.334 103.781 106.409 104.044 106.409C104.834 106.409 104.984 106.184 104.984 105.319V101.745C104.984 100.88 104.834 100.654 104.044 100.654C103.781 100.654 99.9816 100.73 98.2138 100.73H97.123C96.2578 100.73 96.1074 100.579 96.1074 99.7141V97.4197C96.1074 96.4793 96.2955 96.2912 97.2358 96.2912H99.0789C100.847 96.2912 104.646 96.3665 104.909 96.3665C105.699 96.3665 105.849 96.1408 105.849 95.2757V91.4014C105.849 90.5363 105.699 90.3106 104.909 90.3106C104.646 90.3106 100.847 90.3858 99.0789 90.3858H96.0698C94.3395 90.3858 91.0295 90.3106 90.7662 90.3106C89.9763 90.3106 89.713 90.5739 89.713 91.3638C89.713 91.6271 89.8258 95.8023 89.8258 97.7958V110.697C89.8258 112.691 89.713 116.866 89.713 117.129C89.713 117.919 89.9763 118.183 90.7662 118.183C91.0295 118.183 94.3395 118.107 96.0698 118.107H99.9064C101.674 118.107 105.473 118.183 105.737 118.183C106.526 118.183 106.677 117.957 106.677 117.092V113.368C106.677 112.503 106.526 112.277 105.737 112.277C105.473 112.277 101.674 112.352 99.9064 112.352H97.2358Z"
      fill={colorsConst.ICON.WHITE}
    />
    <path
      d="M119.436 103.476C119.436 103.062 119.549 102.874 119.737 102.874C119.85 102.874 120.001 102.949 120.189 103.175L128.539 112.541C130.081 114.271 131.322 115.662 132.676 117.242C133.278 117.957 133.579 118.107 134.52 118.107H136.4C137.265 118.107 137.491 117.957 137.491 117.167C137.491 116.904 137.378 112.578 137.378 110.585V97.9086C137.378 95.9151 137.491 91.5895 137.491 91.3262C137.491 90.5363 137.265 90.3858 136.4 90.3858H132.225C131.36 90.3858 131.134 90.5363 131.134 91.3262C131.134 91.5895 131.247 95.9151 131.247 97.9086V104.642C131.247 105.055 131.134 105.243 130.984 105.243C130.871 105.243 130.683 105.131 130.495 104.905L122.671 95.9903C121.317 94.4482 120.001 92.9436 118.609 91.251C118.007 90.5363 117.706 90.3858 116.766 90.3858H114.283C113.418 90.3858 113.192 90.5363 113.192 91.3262C113.192 91.5895 113.305 95.9151 113.305 97.9086V110.585C113.305 112.578 113.192 116.904 113.192 117.167C113.192 117.957 113.418 118.107 114.283 118.107H118.458C119.323 118.107 119.549 117.957 119.549 117.167C119.549 116.904 119.436 112.578 119.436 110.585V103.476Z"
      fill={colorsConst.ICON.WHITE}
    />
  </svg>
));
