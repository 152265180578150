import React, { useCallback } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { colorsConst } from "styles/const";
import { Text } from "../atoms/Text";

const Container = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.5em;
  ${p =>
    p.active
      ? `color: ${colorsConst.TEXT.WHITE};
    background-color: ${colorsConst.TAB.ACTIVE};
  `
      : ""};
  border: 1px solid ${colorsConst.BORDER.THICK};
  border-radius: 2px;
  & + & {
    margin-left: 1em;
  }
`;

const IconContainer = styled.div<{ active: boolean }>`
  margin-right: 0.25em;
  color: ${p => (p.active ? colorsConst.ICON.INVERT : colorsConst.ICON.DISABLED)};
`;

type Props = {
  children: React.ReactNode;
  active: boolean;
  handleClick?: (value: React.SetStateAction<boolean>) => void;
};

export const OrderListFilterItem: React.FC<Props> = React.memo(
  ({ active, handleClick, children }) => {
    const handleClickWrap = useCallback(() => {
      if (typeof handleClick !== "undefined") {
        handleClick(!active);
      }
    }, [active, handleClick]);
    return (
      <Container active={active} onClick={handleClickWrap}>
        <IconContainer active={active}>
          <FontAwesomeIcon icon={faCheck} />
        </IconContainer>
        <Text size="SMALL">{children}</Text>
      </Container>
    );
  },
);
