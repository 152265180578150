import { ForceUpdateResponse } from "apis/forceUpdate";
import { AppError } from "records/AppError";
import { SubmittableOptionSetType } from "records/ShopItemOptionSet";
import { StopItemsByStopOptionSet } from "records/StopItemsByStopOptionSet";
import { FilteredOptionList, ShopItemSubmitType } from "modules/shop/model";

export type ModalKey =
  | "APP_ERROR"
  | "SHOULD_UPDATE_RESOURCE"
  | "CANCEL_ORDER"
  | "CANCEL_ORDER_BY_REQUIRED_ITEM_SHORTAGE"
  | "LOCAL_PAYMENT_NOTICE"
  | "UPDATE_COOKING_START_TIME"
  | "ORDER_STOP_BY_SHOP"
  | "ORDER_RESTART_BY_SHOP"
  | "CONFIRM_ORDER_RESTART_BY_ITEMS"
  | "CONFIRM_BULK_UPDATE_OPTION_STATUS"
  | "FILTERED_SHOP_ITEMS_BY_OPTION_SET"
  | "FINDING_CREW"
  | "HOLD_ORDER"
  | "WAIT_COMBO_ORDER"
  | "UNABLE_APPROVE_ORDER"
  | "ORDER_ITEM_IMAGE"
  | "FORCE_UPDATE"
  | "CONFIRM_DELETE_HOLIDAY"
  | "STOP_FASTEST_ORDER"
  | "CONFIRM_STOCK_CHECK"
  | "CONFIRM_SHOW_THUMBNAIL_DIALOG";

// tslint:disable-next-line:interface-over-type-literal
type ModalPayloadMap = {
  ["APP_ERROR"]: AppError;
  ["LOCAL_PAYMENT_NOTICE"]: { orderId: string; isDebug?: boolean; isSimulator?: boolean };
  ["CONFIRM_ORDER_RESTART_BY_ITEMS"]: {
    selectedItemId: number;
    errorIds: {
      shop_item_id: number;
      option_set_ids: number[];
    }[];
  };
  ["CONFIRM_BULK_UPDATE_OPTION_STATUS"]: {
    filteredOptions: FilteredOptionList;
    submitType: ShopItemSubmitType;
  };
  ["FILTERED_SHOP_ITEMS_BY_OPTION_SET"]: {
    optionSetId: number;
  };
  ["ORDER_ITEM_IMAGE"]: {
    imagePath: string;
  };
  ["CONFIRM_DELETE_HOLIDAY"]: {
    date: string;
  };
  ["HOLD_ORDER"]: {
    isRetail: boolean;
    userInfoTel: string;
  };
  ["FORCE_UPDATE"]: ForceUpdateResponse;
  ["CONFIRM_STOCK_CHECK"]: {
    isOnSale: boolean;
    selectedItemIds: number[];
    callback: () => void;
  };
};

export type ModalPayload<T extends ModalKey> = T extends keyof ModalPayloadMap
  ? ModalPayloadMap[T]
  : {};

type ModalData<T extends ModalKey> = {
  key: T;
  payload: ModalPayload<T>;
};

export type ModalManager = {
  list: Array<ModalData<ModalKey>>;
};

const initialState: ModalManager = {
  list: [],
};

const create = (args: Partial<ModalManager> = {}) => ({
  ...initialState,
  ...args,
});

const existsModal = (data: ModalManager, key: ModalKey) =>
  data.list.some(modalData => modalData.key === key);

const getPriority = (key: ModalKey) => {
  switch (key) {
    case "APP_ERROR":
      return 1;
    case "SHOULD_UPDATE_RESOURCE":
      return 2;
    case "CANCEL_ORDER":
      return 3;
    case "LOCAL_PAYMENT_NOTICE":
    case "UPDATE_COOKING_START_TIME":
      return 4;
    default:
      return 99;
  }
};

const canDisplay = (data: ModalManager, key: ModalKey) => getDisplayable(data)?.key === key;

const getPayload = <T extends ModalKey>(data: ModalManager, key: T) => {
  const displayable = getDisplayable(data);

  if (displayable?.key !== key) {
    return undefined;
  }

  return displayable.payload as ModalPayload<T>;
};

const getDisplayable = (data: ModalManager) => data.list.slice().sort(sortByPriority)[0];

const sortByPriority = (a: ModalData<ModalKey>, b: ModalData<ModalKey>) => {
  const [pA, pB] = [getPriority(a.key), getPriority(b.key)];
  if (pA === pB) {
    return 0;
  }
  return pA > pB ? 1 : -1;
};

export const ModalManager = {
  initialState,
  create,
  existsModal,
  canDisplay,
  getPayload,
};
