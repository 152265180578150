import React, { useMemo } from "react";
import styled from "styled-components";
import { OrderData } from "records/OrderData";
import { PriceUtils } from "utils/PriceUtils";
import { OrderModel } from "modules/order/model";
import { Text } from "components/atoms/Text";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const OrderItemTotalCount = styled.div`
  margin-right: 1em;
`;

const TotalPrice = styled.div``;

type Props = {
  orderData: OrderData;
};

export const OrderPrice: React.FC<Props> = React.memo(({ orderData }) => {
  const totalItemCounts = useMemo(
    () => OrderModel.getTotalOrderItemsCount(orderData.item_list),
    [orderData],
  );

  return (
    <Container>
      <OrderItemTotalCount>
        <Text size="SMALL">
          計
          <Text size="DEFAULT" weight="BOLD">
            {totalItemCounts}
          </Text>
          個
        </Text>
      </OrderItemTotalCount>
      <TotalPrice>
        <Text size="DEFAULT" weight="BOLD">
          {PriceUtils.getPresentationValue(orderData.total_price)}
        </Text>
      </TotalPrice>
    </Container>
  );
});
