import React, { useMemo } from "react";
import styled from "styled-components";
import { AppModel } from "modules/app/model";
import { colorsConst } from "styles/const";
import { TransitionContainer } from "components/atoms/TransitionContainer";
import { ConfirmShowThumbnailDialog } from "components/organisms/ConfirmShowThumbnailDialog";
import { WatchOrderContainer } from "./WatchOrderContainer";
import { Navigation } from "./Navigation";
import { PathType } from "./NavigationItem";
import { Header } from "./Header";
import { OrderNotification } from "./OrderNotification";
import { SnackBar } from "./SnackBar";
import { ShouldUpdateResourceDialog } from "./ShouldUpdateResourceDialog";
import { ErrorDialog } from "./ErrorDialog";
import { ReloadButton } from "./ReloadButton";
import { PrinterStatus } from "./PrinterStatus";

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
  align-items: stretch;
  flex-direction: column;
  @media (orientation: landscape) {
    flex-direction: row;
  }
  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

const ContentsContainer = styled.div`
  position: relative;
  z-index: 1;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  @media (orientation: landscape) {
    order: 1;
  }
  @media (orientation: portrait) {
    order: 0;
  }
`;

const NavigationContainer = styled.div`
  position: relative;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: ${colorsConst.BACKGROUND.WHITE};
  @media (orientation: landscape) {
    order: 0;
    box-shadow: 4px 0 12px rgba(0, 0, 0, 0.2);
  }
  @media (orientation: portrait) {
    order: 1;
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2);
  }
`;

type Props = {
  children: React.ReactNode;
  withHeader?: boolean;
  headerComponent?: React.ReactNode;
  title?: string;
  goBack?: string;
  handleGoBack?: () => void;
  withNavigation?: boolean;
  activePath?: PathType;
  watch?: boolean;
  pollingInterval?: number;
  onOrderDetail?: boolean;
};

export const Article: React.FC<Props> = React.memo(
  ({
    children,
    withHeader = false,
    headerComponent,
    title,
    goBack,
    handleGoBack,
    withNavigation = false,
    activePath,
    watch = true,
    pollingInterval,
    onOrderDetail = false,
  }) => {
    const renderHeader = useMemo(() => {
      if (withHeader) {
        if (typeof headerComponent !== "undefined") {
          return headerComponent;
        }
        return <Header title={title} goBack={goBack} handleGoBack={handleGoBack} />;
      }
      return undefined;
    }, [withHeader, headerComponent, title, goBack, handleGoBack]);

    return (
      <>
        <WatchOrderContainer watch={watch} pollingInterval={pollingInterval}>
          <Container>
            <ContentsContainer>
              {renderHeader}
              <TransitionContainer>{children}</TransitionContainer>
            </ContentsContainer>
            {withNavigation ? (
              <NavigationContainer>
                <Navigation activePath={activePath} />
              </NavigationContainer>
            ) : undefined}
          </Container>
          <OrderNotification onOrderDetail={onOrderDetail} />
          <SnackBar />
          <ShouldUpdateResourceDialog />
          <ErrorDialog />
          <ConfirmShowThumbnailDialog />
        </WatchOrderContainer>
        {AppModel.isDevelopment() ? (
          <>
            <ReloadButton />
            <PrinterStatus />
          </>
        ) : undefined}
      </>
    );
  },
);
