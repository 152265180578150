import styled, { css } from "styled-components";
import { Field } from "react-final-form";
import { colorsConst, fontFamilyConst } from "styles/const";
import { fontSize } from "styles/mixins";

const selectStyle = css`
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  ${fontSize.DEFAULT};
  font-family: ${fontFamilyConst};
  border: 1px solid ${colorsConst.BORDER.DEFAULT};
  border-radius: 4px;
`;

const SelectField = styled(Field)`
  ${selectStyle};
`;

export { SelectField, selectStyle };
