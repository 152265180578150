import { fork, spawn, takeLatest, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import {
  checkUpdateSaga,
  userTouchedLoginButtonSaga,
  userSubmittedLoginShopFormSaga,
  userTouchedLogoutButtonSaga,
  userSubmittedPrintReceiptSaga,
  systemLaunchedSaga,
  systemAutoLoginSaga,
  systemOpenedModalSaga,
  systemClosedModalSaga,
  systemUpdatedModalSaga,
  systemStartedConnectApiSaga,
  systemFinishedConnectApiSaga,
  systemInitSnackBarSaga,
  systemSendDailyLoginSaga,
  systemSearchPrinterSaga,
  systemSetPrinterStatusSaga,
  systemPrintReceiptSaga,
  systemAutoPrintReceiptSaga,
  systemDisconnectPrinterSaga,
} from "./sagas";

export function* appSaga() {
  yield spawn(checkUpdateSaga);
  yield fork(watchUserTouchedLoginWithTokenButtonSaga);
  yield fork(watchUserSubmittedLoginShopFormSaga);
  yield fork(watchUserTouchedLogoutButtonSaga);
  yield fork(watchUserSubmittedPrintReceiptSaga);
  yield fork(watchSystemLaunchedSaga);
  yield fork(watchSysyemAutoLoginSaga);
  yield fork(watchSystemOpenedModalSaga);
  yield fork(watchSystemClosedModalSaga);
  yield fork(watchSystemUpdatedModalSaga);
  yield fork(watchSystemStartedConnectApiSaga);
  yield fork(watchSystemFinishedConnectApiSaga);
  yield fork(watchSystemInitSnackBarSaga);
  yield fork(watchSystemSendDailyLoginSaga);
  yield fork(watchSystemSearchPrinterSaga);
  yield fork(watchSystemSetPrinterStatusSaga);
  yield fork(watchSystemPrintReceiptSaga);
  yield fork(watchSystemAutoPrintReceiptSaga);
  yield fork(watchSystemDisconnectPrinterSaga);
}

function* watchUserTouchedLoginWithTokenButtonSaga() {
  yield takeLatest(ActionTypes.U_TOUCHED_LOGIN_BUTTON, userTouchedLoginButtonSaga);
}

function* watchUserSubmittedLoginShopFormSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_LOGIN_SHOP_FORM, userSubmittedLoginShopFormSaga);
}

function* watchUserTouchedLogoutButtonSaga() {
  yield takeLatest(ActionTypes.U_TOUCHED_LOGOUT_BUTTON, userTouchedLogoutButtonSaga);
}

function* watchUserSubmittedPrintReceiptSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_PRINT_RECEIPT, userSubmittedPrintReceiptSaga);
}

function* watchSystemLaunchedSaga() {
  yield takeLatest(ActionTypes.S_LAUNCHED, systemLaunchedSaga);
}

function* watchSysyemAutoLoginSaga() {
  yield takeLatest(ActionTypes.S_AUTO_LOGIN, systemAutoLoginSaga);
}

function* watchSystemOpenedModalSaga() {
  yield takeEvery(ActionTypes.S_OPENED_MODAL, systemOpenedModalSaga);
}

function* watchSystemClosedModalSaga() {
  yield takeEvery(ActionTypes.S_CLOSED_MODAL, systemClosedModalSaga);
}

function* watchSystemUpdatedModalSaga() {
  yield takeEvery(ActionTypes.S_UPDATED_MODAL, systemUpdatedModalSaga);
}

function* watchSystemStartedConnectApiSaga() {
  yield takeLatest(ActionTypes.S_STARTED_CONNECT_API, systemStartedConnectApiSaga);
}

function* watchSystemFinishedConnectApiSaga() {
  yield takeLatest(ActionTypes.S_FINISHED_CONNECT_API, systemFinishedConnectApiSaga);
}

function* watchSystemInitSnackBarSaga() {
  yield takeLatest(ActionTypes.S_INIT_SNACK_BAR, systemInitSnackBarSaga);
}

function* watchSystemSendDailyLoginSaga() {
  yield takeLatest(ActionTypes.S_SEND_DAILY_LOGIN, systemSendDailyLoginSaga);
}

function* watchSystemSearchPrinterSaga() {
  yield takeLatest(ActionTypes.S_SEARCH_PRINTER, systemSearchPrinterSaga);
}

function* watchSystemSetPrinterStatusSaga() {
  yield takeLatest(ActionTypes.S_SET_PRINTER_STATUS, systemSetPrinterStatusSaga);
}

function* watchSystemPrintReceiptSaga() {
  yield takeLatest(ActionTypes.S_PRINT_RECEIPT, systemPrintReceiptSaga);
}

function* watchSystemAutoPrintReceiptSaga() {
  yield takeLatest(ActionTypes.S_AUTO_PRINT_RECEIPT, systemAutoPrintReceiptSaga);
}

function* watchSystemDisconnectPrinterSaga() {
  yield takeLatest(ActionTypes.S_DISCONNECT_PRINTER, systemDisconnectPrinterSaga);
}
