import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconWait: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M24,4.5H11.3v3.3c0,4.2,1.6,8.2,4.4,11.3l0.9,1c1.7,1.9,1.8,4.8,0.2,6.8l-1.9,2.4c-2.3,3-3.6,6.6-3.6,10.4v3.5 H24v-2.9h-9.8v-0.6c0-3.1,1-6.1,3-8.6l1.9-2.4c2.5-3.1,2.3-7.6-0.4-10.5l-0.9-1c-2.3-2.6-3.6-5.9-3.6-9.4V7.4H24V4.5z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M24,4.5h12.7v3.3c0,4.2-1.6,8.2-4.4,11.3l-0.9,1c-1.7,1.9-1.8,4.8-0.2,6.8l1.9,2.4c2.3,3,3.6,6.6,3.6,10.4v3.5 H24v-2.9h9.8v-0.6c0-3.1-1-6.1-3-8.6l-1.9-2.4c-2.5-3.1-2.3-7.6,0.4-10.5l0.9-1c2.3-2.6,3.6-5.9,3.6-9.4V7.4H24V4.5z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M7.2,5.9c0-0.8,0.6-1.4,1.4-1.4h30.7c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4H8.7C7.9,7.4,7.2,6.7,7.2,5.9 z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M7.2,41.7c0-0.8,0.6-1.4,1.4-1.4h30.7c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4H8.7C7.9,43.2,7.2,42.5,7.2,41.7z "
      />
      <path
        fill={fillColor}
        d="M25.5,18.7c-0.8,1-2.3,1-3.1,0l-3.3-4.5c-0.9-1.3,0-3,1.5-3h6.6c1.6,0,2.5,1.8,1.5,3L25.5,18.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M19.9,13.6l3.3,4.5c0.4,0.5,1.2,0.5,1.5,0l3.3-4.5c0.5-0.6,0-1.5-0.8-1.5h-6.6C19.9,12.1,19.5,13,19.9,13.6z  M22.5,18.7c0.8,1,2.3,1,3.1,0l3.3-4.5c0.9-1.3,0-3-1.5-3h-6.6c-1.6,0-2.5,1.8-1.5,3L22.5,18.7z"
      />
    </svg>
  );
});
