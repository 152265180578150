import { put, call, all, delay } from "redux-saga/effects";
import { PrintUtils } from "utils/PrintUtils";
import { systemSetPrinterStatus, updateConnectedPrinter } from "../actions";
import { DISCONNECT_PRINTER_RETRY_INTERVAL } from "../model";

export function* disconnectPrinterSaga() {
  let isDisconnected = false;
  while (!isDisconnected) {
    yield put(systemSetPrinterStatus("disconnecting"));
    const { disconnectResult, disconnectError } = yield call(PrintUtils.disconnect);
    if (disconnectResult && !disconnectError) {
      isDisconnected = true;
      yield all([put(updateConnectedPrinter(false)), put(systemSetPrinterStatus("disconnect"))]);
    } else {
      yield put(systemSetPrinterStatus("disconnectFailed"));
      yield delay(DISCONNECT_PRINTER_RETRY_INTERVAL);
    }
  }
}
