import React, { useCallback } from "react";
import styled from "styled-components";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { IconWaitComboOrder } from "components/atoms/Icons/IconWaitComboOrder";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import { ModalDialog, ModalBody, ModalFooter } from "components/molecules/ModalDialog";

const Title = styled.div`
  margin-bottom: 1em;
  text-align: center;
`;

const Caption = styled.div`
  text-align: center;
`;

const IconContainer = styled.div`
  text-align: center;
  svg {
    width: 100%;
    max-width: 174px;
    height: auto;
  }
`;

type Props = {
  children?: never;
  handleCloseWaitingComboOrderDialog?: () => void;
};

const MODAL_KEY = "WAIT_COMBO_ORDER";
const useModal = modalHooksFactory(MODAL_KEY);

export const WaitComboOrderDialog: React.FC<Props> = React.memo(
  ({ handleCloseWaitingComboOrderDialog }) => {
    const { data, close } = useModal();

    const handleClose = useCallback(() => {
      if (typeof handleCloseWaitingComboOrderDialog !== "undefined") {
        handleCloseWaitingComboOrderDialog();
      } else {
        close();
      }
    }, [handleCloseWaitingComboOrderDialog, close]);

    return (
      <ModalDialog isOpen={data.isOpen} close={close} handleClickBackdrop={handleClose}>
        <ModalBody>
          <Title>
            <Text size="LARGE" color="DANGER">
              まだ準備はお待ちください
            </Text>
          </Title>
          <IconContainer>
            <IconWaitComboOrder />
          </IconContainer>
          <Caption>
            <Text>他店舗が受付次第、お知らせいたします。</Text>
          </Caption>
        </ModalBody>
        <ModalFooter>
          <Button appearance="primary" handleClick={handleClose}>
            OK
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  },
);
