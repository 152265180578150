import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {};

export const IconDisableApproval: React.FC<Props> = React.memo(({ ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 194 194" fill={colorsConst.ICON.DANGER} {...props}>
    <path
      d="M123.898 50.0908L106.493 67.8651C105.839 68.6985 105.448 69.742 105.459 70.8997C105.485 73.4986 107.609 75.6043 110.231 75.5786C111.531 75.5658 112.707 75.0108 113.549 74.1519L120.333 67.2329L130.65 56.7113"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M130.652 56.7146L120.64 66.9024C120.522 66.9981 120.429 67.1171 120.336 67.2362C119.518 68.213 119.129 69.4927 119.332 70.9085C119.562 72.6547 120.853 74.2015 122.56 74.7282C124.432 75.3005 126.222 74.6922 127.368 73.5232L148.497 52.357C153.831 47.0117 156.808 39.7521 156.734 32.1917L166.091 22.6483"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M117.173 43.4935L101.265 59.6936L94.6681 66.4217C93.8023 67.2809 93.2941 68.4673 93.3071 69.7904C93.3326 72.3893 95.4799 74.4947 98.0788 74.4692C99.3782 74.4564 100.554 73.9014 101.396 73.0425L106.497 67.865L123.902 50.0906"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M116.804 28.1397C117.238 29.0333 117.084 30.1217 116.382 30.8375L94.5316 53.0977C93.6658 53.9568 93.1576 55.1433 93.1704 56.4427C93.1959 59.0416 95.3432 61.1471 97.9421 61.1215C99.2415 61.1088 100.418 60.5774 101.26 59.6948L117.168 43.4947"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M142.413 29.8471C137.361 35.0478 129.045 35.1768 123.844 30.1241L119.741 26.1476L105.723 27.9157C103.858 28.1467 101.987 27.6452 100.51 26.4783C99.0339 25.3114 98.0485 23.6198 97.8177 21.779L97.5346 19.419L120.638 16.4983C122.833 16.2404 125.053 16.0768 127.25 16.0552C131.526 15.966 135.596 14.2011 138.544 11.1713L140.907 8.76158L146.049 3.08744"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 89H108L110 178H8L10 89Z"
      fill={colorsConst.ICON.DANGER}
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81 102L81 87C81 75.402 71.598 66 60 66V66C48.402 66 39 75.402 39 87L39 102"
      stroke={colorsConst.ICON.WHITE}
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="39" cy="106" r="6" fill={colorsConst.ICON.WHITE} />
    <circle cx="81" cy="106" r="6" fill={colorsConst.ICON.WHITE} />
    <path
      d="M23.2216 36.5428C25.4438 40.116 25.5142 49.697 24.665 50.2251C23.8158 50.7532 15.2537 46.4533 13.0315 42.8801C11.1202 39.8069 10.82 35.2832 14.1029 33.2415C17.3858 31.1999 21.3104 33.4696 23.2216 36.5428Z"
      fill="#F49E96"
    />
    <path
      d="M44.3315 37.9362C43.8522 42.1167 37.9539 49.6671 36.9604 49.5532C35.9669 49.4393 31.9303 40.75 32.4096 36.5695C32.8218 32.974 35.3975 29.2431 39.2383 29.6834C43.0791 30.1237 44.7437 34.3407 44.3315 37.9362Z"
      fill="#F49E96"
    />
    <path
      d="M7.1737 62.8662C11.1352 64.285 20.5155 62.3336 20.8527 61.3921C21.1899 60.4507 15.1813 52.9877 11.2198 51.5689C7.81265 50.3487 3.32735 51.009 2.02384 54.6487C0.720329 58.2883 3.76657 61.646 7.1737 62.8662Z"
      fill="#F49E96"
    />
  </svg>
));
