/* eslint-disable default-param-last */
import { ActionTypes, SimulatorActions } from "./actions";
import { SimulatorModel, SimulatorState } from "./model";

export default function reducer(
  state: SimulatorState = SimulatorModel.initialState,
  action: SimulatorActions,
) {
  switch (action.type) {
    case ActionTypes.R_UPDATE_MOUNT_SIMULATOR:
      return SimulatorModel.updateMountSimulator(state, action.payload.value);
    case ActionTypes.R_UPDATE_SIMULATOR_ORDER_DATA:
      return SimulatorModel.updateSimulatorOrderData(state, action.payload.value);
    case ActionTypes.R_UPDATE_SIMULATOR_ORDER_LIST:
      return SimulatorModel.updateSimulatorOrderList(state, action.payload.value);
    case ActionTypes.R_UPDATE_SIMULATOR_FINISHED_ORDER_LIST:
      return SimulatorModel.updateSimulatorFinishedOrderList(state, action.payload.value);
    case ActionTypes.R_UPDATE_SIMULATOR_PAGE_TRANSITION:
      return SimulatorModel.updateSimulatorPageTransition(state, action.payload.value);
    case ActionTypes.R_UPDATE_COOKING_START_DATE_TIME:
      return SimulatorModel.updateSimulatorCookingStartDateTime(state, action.payload.value);
    default:
      return state;
  }
}
