import { put } from "redux-saga/effects";
import { userAccessedToPageThatNeedsOrderDetail } from "../actions";
import { REG_EXP_ORDER_DETAIL_PATH } from "../model";

export function* systemUpdateOrderDataSaga() {
  const pathName = window.location.pathname;
  const result = REG_EXP_ORDER_DETAIL_PATH.exec(pathName);
  if (result !== null) {
    yield put(userAccessedToPageThatNeedsOrderDetail(result[1]));
  }
  /**
   * FIXME: 外部要因によるorder_data更新処理。現在のところフラグによらず注文詳細画面の場合に限り毎回実行。
   */
  /* else {
    yield fork(userAccessedToPageThatNeedsOrderList);
  } */
}
