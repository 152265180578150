import React, { useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import update from "immutability-helper";
import styled from "styled-components";
import isEqual from "lodash/isEqual";
import { API_KEY } from "apis";
import { ShopItemParentCategory } from "records/ShopItemParentCategory";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { userSubmittedSortShopItemParentCategory } from "modules/shop/actions";
import { colorsConst } from "styles/const";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import { Contents } from "components/atoms/Contents";
import { ListItem } from "./ListItem";

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const ContentsContainer = styled.div`
  position: relative;
  z-index: 1;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const TextContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 1em;
`;

const ButtonContainer = styled.div`
  position: relative;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 1em 2em;
  background-color: ${colorsConst.BACKGROUND.WHITE};
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2);
`;

const isConnectedApiSelector = isConnectedApiSelectorFactory([
  API_KEY.UPDATE_SORT_FOR_SHOP_MANAGEMENT,
]);

type Props = {
  categories: ShopItemParentCategory[];
};

export const ShopItemParentCategoryList: React.FC<Props> = React.memo(({ categories }) => {
  const submitting = useSelector(isConnectedApiSelector);

  const [displayableCategories, setDisplayableCategories] =
    useState<ShopItemParentCategory[]>(categories);

  const canDisplayButtonContainer = useMemo(
    () =>
      !isEqual(
        categories.map(i => i.parent_category_id),
        displayableCategories.map(i => i.parent_category_id),
      ),
    [categories, displayableCategories],
  );

  const dispatch = useDispatch();

  const moveList = useCallback((dragIndex: number, hoverIndex: number) => {
    setDisplayableCategories((prevLists: ShopItemParentCategory[]) =>
      update(prevLists, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevLists[dragIndex]],
        ],
      }),
    );
  }, []);

  const handleClick = useCallback(() => {
    dispatch(userSubmittedSortShopItemParentCategory(displayableCategories));
  }, [dispatch, displayableCategories]);

  return (
    <DndProvider backend={TouchBackend}>
      <Container>
        <ContentsContainer>
          <TextContainer>
            <Text>ドラッグ＆ドロップで任意の場所に並び替えができます。</Text>
          </TextContainer>
          <Contents>
            {displayableCategories.map((category, index) => (
              <ListItem
                key={category.parent_category_id}
                index={index}
                category={category}
                moveList={moveList}
              />
            ))}
          </Contents>
        </ContentsContainer>
        {canDisplayButtonContainer ? (
          <ButtonContainer>
            <Button
              type="button"
              appearance="primary"
              handleClick={handleClick}
              loading={submitting}
            >
              並び替えを確定する
            </Button>
          </ButtonContainer>
        ) : undefined}
      </Container>
    </DndProvider>
  );
});
