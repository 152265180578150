import { OrderOption } from "records/OrderOption";

export type OrderOptionSet = Readonly<{
  option_set_name: string;
  option_list: OrderOption[];
}>;

const initialState: OrderOptionSet = {
  option_set_name: "",
  option_list: [],
};

const create = (args: Partial<OrderOptionSet> = {}) => ({
  ...initialState,
  ...args,
});

const getOptionSetPrice = (optionSet: OrderOptionSet) =>
  optionSet.option_list.reduce((acc, crr) => acc + crr.price, 0);

export const OrderOptionSet = Object.freeze({
  initialState,
  create,
  getOptionSetPrice,
});
