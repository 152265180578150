import React from "react";
import { colorsConst } from "styles/const";

type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

export const IconBicycle: React.FC<Props> = React.memo(({ color, ...props }) => {
  const fillColor = color || colorsConst.ICON.DEFAULT;
  return (
    <svg viewBox="0 0 28 24" width="1em" height="1em" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M18.6,1.5c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2H20c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9h-0.6 c0,0,0,0-0.1,0H9.5C9,5.7,8.6,6,8.4,6.5L7.1,9.9C6.7,9.9,6.4,9.9,6,9.9c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6 c0-2.2-1.1-4-2.8-5.1L10.3,8h3.4v0h3.4l1.1,2.8c-1.7,1.1-2.8,3-2.8,5.1c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6s-2.7-6-6-6 c-0.4,0-0.7,0-1,0.1L19.6,8H20c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3H18.6z M6,12.2c0.1,0,0.1,0,0.2,0l-1.3,3.3 C4.7,16.1,5,16.8,5.6,17c0.6,0.2,1.3-0.1,1.5-0.7L8.4,13c0.8,0.7,1.3,1.7,1.3,2.9c0,2-1.7,3.7-3.7,3.7c-2,0-3.7-1.7-3.7-3.7 S4,12.2,6,12.2z M17.7,15.9c0-1.1,0.5-2.2,1.3-2.9l1.3,3.3c0.2,0.6,0.9,0.9,1.5,0.7c0.6-0.2,0.9-0.9,0.7-1.5l-1.3-3.3 c0.1,0,0.1,0,0.2,0c2,0,3.7,1.7,3.7,3.7s-1.7,3.7-3.7,3.7S17.7,17.9,17.7,15.9z"
      />
      <path
        fill={fillColor}
        d="M8.1,1.5C7.5,1.5,7,2,7,2.7c0,0.6,0.5,1.2,1.2,1.2h2.8c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2H8.1z"
      />
    </svg>
  );
});
