import React, { useState, useMemo, useCallback, useEffect } from "react";
import { batch, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { API_KEY } from "apis";
import { systemOpenedModal } from "modules/app/actions";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import {
  userAccessedToPageThatNeedsShopItems,
  userAccessedToPageThatNeedsShopItemParentCategories,
} from "modules/shop/actions";
import { ShopModel } from "modules/shop/model";
import { shopItemsSelector, shopItemParentCategoriesSelector } from "modules/shop/selectors";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Contents } from "components/atoms/Contents";
import { Text } from "components/atoms/Text";
import { NoData } from "components/atoms/NoData";
import { Button } from "components/atoms/Button";
import { TabButton } from "components/atoms/TabButton";
import { ShopItemParentCategorySelect } from "components/molecules/ShopItemParentCategorySelect";
import { Article } from "components/organisms/Article";
import { ShopItemListForStockCheck } from "components/organisms/ShopItemListForStockCheck";
import { ShopItemsForStockCheckModal } from "components/organisms/ShopItemsForStockCheckModal";

const CategorySelectContainer = styled.div`
  margin-bottom: 1em;
`;

const CategorySelectLabel = styled.div`
  margin-bottom: 0.25em;
`;

const TabButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
`;

const SubmitButtonContainer = styled.div`
  position: relative;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 1em;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
`;

const isConnectedApiSelector = isConnectedApiSelectorFactory([
  API_KEY.GET_SHOP_ITEM_LIST,
  API_KEY.GET_PARENT_CATEGORY_LIST,
]);

type Props = {};

export const ShopItemsStockCheckTemplate: React.FC<Props> = React.memo(() => {
  const loading = useSelector(isConnectedApiSelector);

  const shopItems = useSelector(shopItemsSelector);
  const parentCategories = useSelector(shopItemParentCategoriesSelector);

  const [isOnSale, setIsOnSale] = useState<boolean>(true);
  const [selectedParentCategoryId, setSelectedParentCategoryId] = useState<number>(0);

  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);

  const dispatch = useDispatch();

  const parentCategoriesForStockCheck = useMemo(
    () => parentCategories.filter(parentCategory => parentCategory.is_stock_check),
    [parentCategories],
  );

  const displayableItemList = useMemo(
    () =>
      ShopModel.getDisplayableItemListForStockCheck(shopItems, selectedParentCategoryId, isOnSale),
    [shopItems, selectedParentCategoryId, isOnSale],
  );

  const existsDisplayableItem = useMemo(
    () => displayableItemList.some(shopItemCategory => shopItemCategory.item_list.length > 0),
    [displayableItemList],
  );

  const canDisplaySubmitButton = useMemo(() => selectedItemIds.length > 0, [selectedItemIds]);

  const buttonText = useMemo(() => (isOnSale ? "本日売り切れ" : "受付再開"), [isOnSale]);

  const handleCheck = useCallback(
    (itemId: number, checked: boolean) => {
      const updatedIds = checked
        ? selectedItemIds.includes(itemId)
          ? selectedItemIds
          : [...selectedItemIds, itemId]
        : selectedItemIds.filter(id => id !== itemId);
      setSelectedItemIds(updatedIds);
    },
    [selectedItemIds],
  );

  const initSelectedItemIds = useCallback(() => {
    setSelectedItemIds([]);
  }, []);

  const handleChangeTab = useCallback(
    (onSale: boolean) => {
      setIsOnSale(onSale);
      initSelectedItemIds();
    },
    [initSelectedItemIds],
  );

  const setSelectedParentCategoryIdWrap = useCallback(
    (value: React.SetStateAction<number>) => {
      initSelectedItemIds();
      setSelectedParentCategoryId(value);
    },
    [initSelectedItemIds],
  );

  const handleSubmit = useCallback(() => {
    dispatch(
      systemOpenedModal("CONFIRM_STOCK_CHECK", {
        isOnSale,
        selectedItemIds,
        callback: initSelectedItemIds,
      }),
    );
  }, [dispatch, isOnSale, selectedItemIds, initSelectedItemIds]);

  useEffect(() => {
    batch(() => {
      dispatch(userAccessedToPageThatNeedsShopItems());
      dispatch(userAccessedToPageThatNeedsShopItemParentCategories());
    });
  }, [dispatch]);

  useEffect(() => {
    if (parentCategoriesForStockCheck.length > 0) {
      setSelectedParentCategoryId(parentCategoriesForStockCheck[0].parent_category_id);
    }
  }, [parentCategoriesForStockCheck]);

  return (
    <>
      <Article withHeader title="在庫チェック" activePath="SETTING" goBack="/setting">
        {loading ? (
          <LoadingContainer />
        ) : parentCategoriesForStockCheck.length > 0 ? (
          <Contents>
            <TabButtonContainer>
              <TabButton handleClick={() => handleChangeTab(true)} active={isOnSale}>
                在庫あり
              </TabButton>
              <TabButton handleClick={() => handleChangeTab(false)} active={!isOnSale}>
                在庫なし
              </TabButton>
            </TabButtonContainer>
            <CategorySelectContainer>
              <CategorySelectLabel>
                <Text size="SMALL" weight="BOLD">
                  セクションカテゴリで絞り込み
                </Text>
              </CategorySelectLabel>
              <ShopItemParentCategorySelect
                parentCategories={parentCategoriesForStockCheck}
                selectedParentCategoryId={selectedParentCategoryId}
                setSelectedParentCategoryId={setSelectedParentCategoryIdWrap}
              />
            </CategorySelectContainer>
            {existsDisplayableItem ? (
              displayableItemList.map(shopItemCategory =>
                shopItemCategory.item_list.length > 0 ? (
                  <ShopItemListForStockCheck
                    key={shopItemCategory.category_id}
                    shopItemCategory={shopItemCategory}
                    selectedItemIds={selectedItemIds}
                    handleCheck={handleCheck}
                  />
                ) : undefined,
              )
            ) : (
              <NoData>商品がありません</NoData>
            )}
          </Contents>
        ) : (
          <NoData>在庫確認対象の親カテゴリがありません</NoData>
        )}
      </Article>
      {canDisplaySubmitButton ? (
        <SubmitButtonContainer>
          <Button appearance="primary" handleClick={handleSubmit}>
            {buttonText}
          </Button>
        </SubmitButtonContainer>
      ) : undefined}
      <ShopItemsForStockCheckModal />
    </>
  );
});
