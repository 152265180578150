import { put, delay, select } from "redux-saga/effects";
import { OrderData } from "records/OrderData";
import { updateSimulatorOrderData, updateSimulatorOrderList } from "../actions";
import { isMountedSelector } from "../selectors";

export function* searchDriverSaga(orderData: OrderData) {
  yield delay(5000);
  const isMounted: boolean = yield select(isMountedSelector);
  if (isMounted) {
    const updatedOrderData = OrderData.create({
      ...orderData,
      is_driver_accepted: true,
    });
    yield put(updateSimulatorOrderData(updatedOrderData));
    yield put(updateSimulatorOrderList([updatedOrderData]));
  }
}
