import { put, fork, call } from "redux-saga/effects";
import { GetOrderDetailResponse } from "apis";
import { commonErrorSaga } from "modules/app/sagas/commonErrorSaga";
import { updateOrderDetail, updateBusyFlag } from "../actions";
import { getOrderDetailSaga } from "./getOrderDetailSaga";

export function* updateOrderDetailSaga(orderId: string) {
  try {
    const { result, error }: { result: GetOrderDetailResponse; error: unknown } = yield call(
      getOrderDetailSaga,
      orderId,
    );
    if (result && !error) {
      yield put(updateOrderDetail(result.order_data));
      yield put(updateBusyFlag(result.busy_flag));
    } else {
      throw error;
    }
  } catch (error) {
    yield fork(commonErrorSaga);
  }
}
