import { put, select } from "redux-saga/effects";
import { updateShopItems, SystemUpdateShopItemStatusAction } from "../actions";
import { shopItemsSelector } from "../selectors";
import { ShopModel } from "../model";

export function* systemUpdateShopItemStatusSaga(action: SystemUpdateShopItemStatusAction) {
  const { shopItemIds, submitType, withOptionStop } = action.payload;
  const shopItems: ReturnType<typeof shopItemsSelector> = yield select(shopItemsSelector);
  const updatedShopItems = ShopModel.getUpdatedShopItemsByStopOrRestartShopItem(
    shopItems,
    shopItemIds,
    submitType,
    withOptionStop,
  );
  yield put(updateShopItems(updatedShopItems));
}
