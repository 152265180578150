/* eslint-disable default-param-last */
import { ActionTypes, InformationActions } from "./actions";
import { InformationModel, InformationState } from "./model";

export default function reducer(
  state: InformationState = InformationModel.initialState,
  action: InformationActions,
): InformationState {
  switch (action.type) {
    case ActionTypes.R_UPDATE_NEWS_LIST:
      return InformationModel.updateNewsList(state, action.payload.list);
    case ActionTypes.R_UPDATE_UNREAD_NEWS_FLAG:
      return InformationModel.updateUnreadNewsFlag(state, action.payload.bool);
    default:
      return state;
  }
}
