import React, { useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { API_KEY } from "apis";
import { modalHooksFactory } from "hooks/modalHooksFactory";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import {
  userSubmittedStopOrderByStockCheck,
  userSubmittedRestartOrderByStockCheck,
} from "modules/shop/actions";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";
import {
  ModalDialog,
  ModalDialogHeader,
  ModalBody,
  ModalFooter,
} from "components/molecules/ModalDialog";

const MODAL_KEY = "CONFIRM_STOCK_CHECK";
const useModal = modalHooksFactory(MODAL_KEY);

const isConnectedApiSelector = isConnectedApiSelectorFactory([
  API_KEY.STOP_ORDER_BY_ITEM,
  API_KEY.RESTART_ORDER_BY_ITEM,
]);

export const ConfirmStockCheckDialog: React.FC = React.memo(() => {
  const submitting = useSelector(isConnectedApiSelector);

  const { data, close } = useModal();

  const isOnSale = useMemo(
    () => "payload" in data && "isOnSale" in data.payload && data.payload.isOnSale,
    [data],
  );

  const selectedItemIds = useMemo(
    () =>
      "payload" in data && "selectedItemIds" in data.payload ? data.payload.selectedItemIds : [],
    [data],
  );

  const callback = useMemo(
    () => ("payload" in data && "callback" in data.payload ? data.payload.callback : undefined),
    [data],
  );

  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    if (isOnSale) {
      dispatch(userSubmittedStopOrderByStockCheck(selectedItemIds, callback));
    } else {
      dispatch(userSubmittedRestartOrderByStockCheck(selectedItemIds, callback));
    }
  }, [dispatch, isOnSale, selectedItemIds, callback]);

  return (
    <ModalDialog isOpen={data.isOpen} close={close} handleClickBackdrop={close}>
      <ModalDialogHeader handleClose={close}>
        {isOnSale ? "商品の売り切れ設定" : "商品の受付再開"}
      </ModalDialogHeader>
      <ModalBody>
        <div>
          <Text>
            {isOnSale
              ? "チェックをつけた商品を本日売り切れに設定してよろしいですか？売り切れ商品は「在庫なし」タブに移動します。"
              : "チェックをつけた商品を受付再開してよろしいですか？受付再開した商品は「在庫あり」タブに移動します。"}
          </Text>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button appearance="secondary" handleClick={handleSubmit} loading={submitting}>
          確定する
        </Button>
      </ModalFooter>
    </ModalDialog>
  );
});
