import React, { useMemo } from "react";
import styled from "styled-components";
import { OrderItemData } from "records/OrderItemData";
import { PriceUtils } from "utils/PriceUtils";
import { Text } from "components/atoms/Text";

const Container = styled.div`
  text-align: right;
`;

const QuantityContainer = styled.div`
  line-height: 1;
`;

type Props = {
  item: OrderItemData;
};

export const OrderDetailItemPrice: React.FC<Props> = React.memo(({ item }) => {
  const displayablePrice = useMemo(
    () =>
      item.is_sell_by_weight
        ? Math.round(item.user_display_quantity * item.unit_price)
        : item.unit_price,
    [item],
  );

  return (
    <Container>
      <Text size="LARGE">{PriceUtils.getPresentationValue(displayablePrice)}</Text>
      {item.is_sell_by_weight ? (
        <QuantityContainer>
          <Text size="SMALL">{`／${
            item.user_display_quantity
          }${OrderItemData.getPresentationUnitString(item.unit)}`}</Text>
        </QuantityContainer>
      ) : undefined}
    </Container>
  );
});
