import React, { useCallback } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { ShopItemOptionSet } from "records/ShopItemOptionSet";
import { systemOpenedModal } from "modules/app/actions";
import { colorsConst } from "styles/const";
import { Text } from "components/atoms/Text";
import { Button } from "components/atoms/Button";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em;
  background-color: #fff;
  border-top: 1px solid ${colorsConst.BORDER.DEFAULT};
  [data-test-id="virtuoso-top-item-list"] & {
    border-top: none;
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const ButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const buttonStyle: React.CSSProperties = {
  paddingTop: "0.25em",
  paddingBottom: "0.25em",
} as const;

type Props = {
  optionSet: ShopItemOptionSet;
};

export const ShopOptionSetContainer: React.FC<Props> = React.memo(({ optionSet }) => {
  const dispatch = useDispatch();

  const handleOpenModal = useCallback(() => {
    dispatch(
      systemOpenedModal("FILTERED_SHOP_ITEMS_BY_OPTION_SET", {
        optionSetId: optionSet.option_set_id,
      }),
    );
  }, [dispatch, optionSet]);

  return (
    <Container>
      <TextContainer>
        <Text weight="BOLD">
          {`${optionSet.option_set_name}（${ShopItemOptionSet.getPresentstionSelectTypeText(
            optionSet.option_select_type,
          )}）`}
        </Text>
      </TextContainer>
      <ButtonContainer>
        <Button appearance="primary" style={buttonStyle} invert handleClick={handleOpenModal}>
          対象商品
        </Button>
      </ButtonContainer>
    </Container>
  );
});
