export type SnackBarType = "DEFAULT" | "WARNING";

export type SnackBar = Readonly<{
  isOpen: boolean;
  snackBarType: SnackBarType;
  snackBarText: string;
}>;

const initialState: SnackBar = {
  isOpen: false,
  snackBarType: "DEFAULT",
  snackBarText: "",
};

const create = (args: Partial<SnackBar> = {}) => ({
  ...initialState,
  ...args,
});

export const SnackBar = Object.freeze({
  initialState,
  create,
});
