import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { API_KEY } from "apis";
import { isConnectedApiSelectorFactory } from "modules/app/selectors";
import { userAccessedToPageThatNeedsShopBusiness } from "modules/shop/actions";
import { LoadingContainer } from "components/atoms/LoadingContainer";
import { Contents } from "components/atoms/Contents";
import { Article } from "components/organisms/Article";
import { ShopBusinessTime } from "components/organisms/ShopBusinessTime";

const Container = styled.div``;

const isConnectedApiSelector = isConnectedApiSelectorFactory([API_KEY.GET_SHOP_BUSINESS]);

type Props = {};

export const ShopBusinessTemplate: React.FC<Props> = React.memo(() => {
  const loading = useSelector(isConnectedApiSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopBusiness());
  }, [dispatch]);

  return (
    <Article
      withHeader
      title="営業時間の設定"
      goBack="/setting"
      withNavigation
      activePath="SETTING"
    >
      {loading ? (
        <LoadingContainer />
      ) : (
        <Contents>
          <ShopBusinessTime />
        </Contents>
      )}
    </Article>
  );
});
