import React, { useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { replace } from "connected-react-router";
import {
  HeaderContainer,
  HeaderInner,
  HeaderTitle,
  BackButtonContainer,
} from "../atoms/HeaderElement";
import { BackButton } from "../atoms/BackButton";

type Props = {
  title?: string;
  goBack?: string;
  handleGoBack?: () => void;
};

export const Header: React.FC<Props> = React.memo(({ title, goBack, handleGoBack }) => {
  const location = useLocation();
  const canDisplayBackButton = useMemo(
    () => typeof goBack !== "undefined" || typeof handleGoBack !== "undefined",
    [goBack, handleGoBack],
  );

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (typeof handleGoBack !== "undefined") {
      handleGoBack();
    } else if (typeof goBack !== "undefined") {
      dispatch(replace(goBack, { isBack: true, from: location }));
    }
  }, [dispatch, goBack, handleGoBack, location]);

  return (
    <HeaderContainer>
      <HeaderInner>
        {canDisplayBackButton ? (
          <BackButtonContainer>
            <BackButton handleClick={handleClick} />
          </BackButtonContainer>
        ) : undefined}
        {typeof title !== "undefined" && <HeaderTitle>{title}</HeaderTitle>}
      </HeaderInner>
    </HeaderContainer>
  );
});
