import React from "react";
import styled from "styled-components";
import { OrderDetailButtonContainerTextType } from "modules/order/model";
import { Text } from "components/atoms/Text";

const Container = styled.div``;

type Props = {
  presentationText: OrderDetailButtonContainerTextType[];
};

export const OrderDetailButtonContainerText: React.FC<Props> = React.memo(
  ({ presentationText }) => (
    <>
      {presentationText.map((i, index) => (
        <Container key={index}>
          <Text size="XSMALL" color={i.emphasis ? "DANGER" : "PRIMARY"}>
            {i.text}
          </Text>
        </Container>
      ))}
    </>
  ),
);
