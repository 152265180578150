import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";

dayjs.extend(isToday);

export type DateFormat =
  | "YYYYMMDD"
  | "YYYY-MM-DD"
  | "YYYY年M月"
  | "YYYY年M月D日"
  | "YYYY年MM月DD日"
  | "D"
  | "M月D日"
  | "MM月DD日"
  | "HH:mm"
  | "YYYY年M月D日 HH:mm"
  | "M月D日 HH:mm"
  | "YYYY-MM-DD HH:mm:ss";

export class DateUtils {
  public static isValidDateString = (str: Date | string) => dayjs(str).isValid();

  // APIから返ってきた日時文字列をISO8601に変換
  public static dateStrToIsoDateString(time: string) {
    const regexp =
      /^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9]) (?:([0-2][0-9]):([0-5][0-9]):([0-5][0-9]))?$/;
    return time.replace(
      regexp,
      (match, year, month, day, hour, minutes, seconds) =>
        `${year}-${month}-${day}T${hour}:${minutes}:${seconds}.000+09:00`,
    );
  }

  public static dateStringToDate(datetime: string) {
    return new Date(DateUtils.dateStrToIsoDateString(datetime));
  }

  public static getWeekdayString(date: Date | string) {
    if (!DateUtils.isValidDateString(date)) {
      return "";
    }
    switch (dayjs(date).day()) {
      case 0:
        return "日";
      case 1:
        return "月";
      case 2:
        return "火";
      case 3:
        return "水";
      case 4:
        return "木";
      case 5:
        return "金";
      case 6:
        return "土";
      default:
        return "";
    }
  }

  public static getWeekdayStringByWeekNumber(number: number): string {
    switch (number) {
      case 0:
        return "日曜日";
      case 1:
        return "月曜日";
      case 2:
        return "火曜日";
      case 3:
        return "水曜日";
      case 4:
        return "木曜日";
      case 5:
        return "金曜日";
      case 6:
        return "土曜日";
      case 7:
        return "祝日";
      default:
        return "";
    }
  }

  public static dateToString(time: Date | string, format: DateFormat) {
    return typeof time !== "string" || DateUtils.isValidDateString(time)
      ? dayjs(time).format(format)
      : "";
  }

  public static addOneDay(time: string) {
    return dayjs(time).add(1, "days").format("YYYYMMDD");
  }

  public static subtractOneDay(time: string) {
    return dayjs(time).subtract(1, "days").format("YYYYMMDD");
  }

  public static isToday(time: string | Date) {
    return dayjs(time).isToday();
  }

  public static isAfter = (timeA: string | Date, timeB?: string | Date) =>
    typeof timeB !== "undefined"
      ? dayjs(timeA).isAfter(dayjs(timeB))
      : dayjs(timeA).isAfter(dayjs(new Date()));

  // 24時から30時の間かどうか
  public static isOverMidNight(time: string) {
    const timeMoment = dayjs(time, ["HHmm", "YYYY-MM-DD HH:mm:ss"]);
    return timeMoment.hour() >= 0 && timeMoment.hour() < 6;
  }

  public static isReserved(time: string) {
    const today = dayjs().hour(6).minute(0).second(0).millisecond(0);
    return this.isOverMidNight(time)
      ? dayjs(time).isAfter(today.add(1, "day"))
      : dayjs(time).isAfter(today);
  }

  public static diffTime(a: string | Date, b: string | Date) {
    if (!DateUtils.isValidDateString(a) || !DateUtils.isValidDateString(b)) {
      return 0;
    }
    return Number(dayjs(a).diff(dayjs(b)));
  }

  public static diffMinutes(a: string | Date, b: string | Date) {
    if (!DateUtils.isValidDateString(a) || !DateUtils.isValidDateString(b)) {
      return 0;
    }
    return Number(dayjs(dayjs(a).diff(dayjs(b))).format("mm"));
  }

  public static getDateWithAddSeconds(dateString: string | Date, minutes: number) {
    if (!DateUtils.isValidDateString(dateString)) {
      return new Date();
    }
    return dayjs(dateString).add(minutes, "seconds").toDate();
  }

  public static getDateWithAddMinutes(dateString: string | Date, minutes: number) {
    if (!DateUtils.isValidDateString(dateString)) {
      return new Date();
    }
    return dayjs(dateString).add(minutes, "minutes").toDate();
  }

  public static getRemainingTimeToReceive(
    diff: number,
    isToday: boolean,
    receiveDateTime: string,
  ): { time: number; unit: string } {
    let result: { time: number; unit: string };
    if (isToday) {
      let m = diff < 0 ? Math.ceil(Math.abs(diff) / 60000) : Math.floor(Math.abs(diff) / 60000);
      if (Math.abs(diff) > 21600000) {
        m = 360;
        if (diff < 0) {
          result = { time: m * -1, unit: "分以上" };
        } else {
          result = { time: m, unit: "分以上" };
        }
      } else if (diff < 0) {
        result = { time: m * -1, unit: "分" };
      } else {
        result = { time: m, unit: "分" };
      }
    } else {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const isoDateString = DateUtils.dateStrToIsoDateString(`${receiveDateTime}:00`);
      const receiveDate = new Date(isoDateString);
      receiveDate.setHours(0, 0, 0, 0);
      const d = dayjs(receiveDate).diff(dayjs(now), "days");
      result = d > 0 ? { time: d, unit: "日後" } : { time: d, unit: "日前" };
    }
    return result;
  }

  public static getPresentationDiffMinutesText(timeA: string | Date, timeB: string | Date) {
    return dayjs(timeA).diff(dayjs(timeB), "minutes", false);
  }

  public static getDateWithAddedDays(date: Date, days: number) {
    return dayjs(date).add(days, "d").toDate();
  }

  public static getDateWithAddedMonth(date: Date, month: number) {
    return dayjs(date).add(month, "M").toDate();
  }
}
