import { ShopItemOptionSet } from "./ShopItemOptionSet";

export const INSTRUCTION_TEXT_FLAG = {
  HIDDEN: 0,
  VISIBLE: 1,
} as const;

type InstructionTextFlag = ValueOf<typeof INSTRUCTION_TEXT_FLAG>;

export const SOLD_OUT_FLAG = {
  ON_SALE: 0,
  SOLD_OUT: 1,
} as const;

export type SoldOutFlag = ValueOf<typeof SOLD_OUT_FLAG>;

export const RELEASE_FLAG = {
  NOT_RELEASED: 0,
  RELEASED: 1,
} as const;

export type RleaseFlag = ValueOf<typeof RELEASE_FLAG>;

export const REASON_FOR_ITEMS_STOP = {
  DEFAULT: 0,
  OUT_OF_STOCK: 1,
  DISCOUNTED: 2,
  DISCREPANCY_INFORMATION: 3,
  PRICING_ERROR: 4,
  SOME_ITEMS_MISSING: 5,
  OTHER: 6,
} as const;

export type ReasonForItemsStop = ValueOf<typeof REASON_FOR_ITEMS_STOP>;

export const reasonForItemsStopList: ReadonlyArray<{
  key: ReasonForItemsStop;
  text: string;
}> = [
  { key: REASON_FOR_ITEMS_STOP.DEFAULT, text: "欠品理由を選んで下さい(任意)" },
  { key: REASON_FOR_ITEMS_STOP.OUT_OF_STOCK, text: "在庫なし" },
  { key: REASON_FOR_ITEMS_STOP.DISCOUNTED, text: "値引き商品" },
  { key: REASON_FOR_ITEMS_STOP.DISCREPANCY_INFORMATION, text: "商品情報の相違" },
  { key: REASON_FOR_ITEMS_STOP.PRICING_ERROR, text: "価格設定の誤り" },
  { key: REASON_FOR_ITEMS_STOP.SOME_ITEMS_MISSING, text: "一部欠品(個数不足)" },
  { key: REASON_FOR_ITEMS_STOP.OTHER, text: "その他" },
] as const;

export const REASON_FOR_ITEMS_STOP_VIEW = {
  ORDER_DETAIL: 1,
  ITEM_LIST: 2,
  SHOP_ITEMS_STOCK_CHECK: 3,
} as const;

export type ReasonForItemsStopView = ValueOf<typeof REASON_FOR_ITEMS_STOP_VIEW>;

export type ShopItem = {
  shop_item_id: number;
  item_name: string;
  sold_out_flag: SoldOutFlag;
  is_release: RleaseFlag;
  option_set_list: ShopItemOptionSet[];
  category_name: string;
  image_path: string;
  sold_out_reason_type: ReasonForItemsStop;
};

const initialState: ShopItem = {
  shop_item_id: 0,
  item_name: "",
  sold_out_flag: SOLD_OUT_FLAG.ON_SALE,
  is_release: RELEASE_FLAG.RELEASED,
  option_set_list: [],
  category_name: "",
  image_path: "",
  sold_out_reason_type: REASON_FOR_ITEMS_STOP.DEFAULT,
};

const create = (args: Partial<ShopItem> = {}) => ({
  ...initialState,
  ...args,
  option_set_list: args.option_set_list
    ? args.option_set_list.map(i => ShopItemOptionSet.create(i))
    : [],
});

const isSoldOut = (item: ShopItem) => item.sold_out_flag === SOLD_OUT_FLAG.SOLD_OUT;

const isNotReleased = (item: ShopItem) => item.is_release === RELEASE_FLAG.NOT_RELEASED;

export const ShopItem = Object.freeze({
  initialState,
  create,
  isSoldOut,
  isNotReleased,
});
