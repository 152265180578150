import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { colorsConst, fontWeightsConst } from "styles/const";
import { fontSize } from "styles/mixins";
import { Loading } from "./Loading";

type Appearance = "default" | "primary" | "secondary";

const buttonStyle = css`
  appearance: none;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  ${fontSize.DEFAULT};
  font-weight: ${fontWeightsConst.BOLD};
  border-radius: 4px;
`;

const Container = styled.button`
  ${buttonStyle};
`;

const ButtonInner = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonMainText = styled.div`
  ${fontSize.XLARGE};
`;

const ButtonSubText = styled.div`
  ${fontSize.SMALL};
  opacity: 0.8;
`;

const getTextColor = (appearance: Appearance, invert: boolean, disabled: boolean) => {
  if (disabled) {
    return invert ? colorsConst.BUTTON.TEXT.DISABLED : colorsConst.BUTTON.TEXT.DEFAULT;
  }
  if (!invert) {
    return appearance === "secondary" ? colorsConst.TEXT.PRIMARY : colorsConst.BUTTON.TEXT.DEFAULT;
  }
  switch (appearance) {
    case "primary":
      return colorsConst.BUTTON.TEXT.PRIMARY;
    case "secondary":
      return colorsConst.BUTTON.TEXT.SECONDARY;
    default:
      return colorsConst.TEXT.SECONDARY;
  }
};

const getBackgroundColor = (appearance: Appearance, invert: boolean, disabled: boolean) => {
  if (disabled) {
    return invert ? colorsConst.BUTTON.BACKGROUND.WHITE : colorsConst.BUTTON.BACKGROUND.DISABLED;
  }
  if (invert) {
    return colorsConst.BUTTON.BACKGROUND.WHITE;
  }
  switch (appearance) {
    case "primary":
      return colorsConst.BUTTON.BACKGROUND.PRIMARY;
    case "secondary":
      return colorsConst.BUTTON.BACKGROUND.SECONDARY;
    default:
      return colorsConst.BUTTON.BACKGROUND.DEFAULT;
  }
};

type Props = {
  type?: "button" | "submit";
  appearance?: Appearance;
  invert?: boolean;
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  handleClick?: () => void;
};

const Button: React.FC<Props> = React.memo(
  ({
    type = "button",
    appearance = "default",
    invert = false,
    disabled = false,
    loading = false,
    style,
    children,
    handleClick,
  }) => {
    const buttonStyle: React.CSSProperties = useMemo(() => {
      if (disabled) {
        return {
          ...style,
          color: getTextColor(appearance, invert, disabled),
          backgroundColor: getBackgroundColor(appearance, invert, disabled),
        };
      }
      return {
        color: getTextColor(appearance, invert, disabled),
        backgroundColor: getBackgroundColor(appearance, invert, disabled),
        border: invert ? `1px solid ${getTextColor(appearance, invert, disabled)}` : "none",
        ...style,
      };
    }, [appearance, invert, disabled, style]);

    return (
      <Container
        type={type}
        onClick={handleClick}
        style={buttonStyle}
        disabled={disabled || loading}
      >
        {loading ? <Loading invert={true} /> : children}
      </Container>
    );
  },
);

export { Button, buttonStyle, ButtonInner, ButtonMainText, ButtonSubText };
