import React from "react";
import { Button, ButtonInner, ButtonMainText, ButtonSubText } from "components/atoms/Button";

type Props = {};

export const ReservedOrderButton: React.FC<Props> = React.memo(() => (
  <Button loading={false} disabled={true}>
    <ButtonInner>
      <ButtonMainText>予約の受付済み</ButtonMainText>
      <ButtonSubText>receipt</ButtonSubText>
    </ButtonInner>
  </Button>
));
