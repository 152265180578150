import { Task } from "redux-saga";
import { fork, takeLatest } from "redux-saga/effects";
import { OrderData, ORDER_RECEIVE_TYPE, ORDER_TIME_TYPE, MOCK_ORDER_DATA } from "records/OrderData";
import { Utility } from "utils/Utility";
import { DateUtils } from "utils/DateUtils";
import { ActionTypes, SystemGenerateSimulatorOrderDataAction } from "../actions";
import { newOrderSaga } from "./newOrderSaga";

export function* systemGenerateSimulatorOrderDataSaga(
  action: SystemGenerateSimulatorOrderDataAction,
) {
  const { shopData, orderReceiveType, orderTimeType } = action.payload;
  const orderNo = Utility.generateSimulatorOrderNo();
  const orderData: OrderData = OrderData.create({
    ...MOCK_ORDER_DATA,
    order_no: orderNo,
    display_order_no: orderNo,
    time_type: orderTimeType,
    receive_datetime: DateUtils.dateToString(
      DateUtils.getDateWithAddMinutes(new Date(), 30),
      "YYYY-MM-DD HH:mm:ss",
    ),
    order_date: DateUtils.dateToString(new Date(), "YYYY-MM-DD HH:mm:ss"),
    cooking_start_able_flag: orderTimeType === ORDER_TIME_TYPE.FASTEST,
    receive_type: orderReceiveType,
    local_area_flag: orderReceiveType === ORDER_RECEIVE_TYPE.DELIVERY && shopData.is_local_area,
  });
  const cookingStartDateTime = DateUtils.dateToString(
    DateUtils.getDateWithAddMinutes(new Date(), 15),
    "YYYY-MM-DD HH:mm:ss",
  );
  const newOrderTask: Task = yield fork(newOrderSaga, orderData, cookingStartDateTime);
  const stopTask: Task = yield takeLatest(ActionTypes.S_UNMOUNT_SIMULATOR, stopTaskSaga);
  function* stopTaskSaga() {
    if (!newOrderTask.isCancelled()) {
      yield newOrderTask.cancel();
      yield stopTask.cancel();
    }
  }
}
