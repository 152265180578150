import * as Sentry from "@sentry/browser";

export const SentryUtil = {
  init() {
    const dsn = process.env.REACT_APP_SENTRY_DSN;
    const environment = process.env.REACT_APP_ENV;
    if (typeof dsn === "undefined" || dsn.trim() === "") {
      console.warn("sentry dsn is undefined. please set to REACT_APP_SENTRY_DSN.");
      return;
    }
    Sentry.init({
      dsn,
      environment,
      ignoreErrors: ["ResizeObserver loop"],
    });
  },
  reportError(error: unknown) {
    const exception = typeof error === "string" ? new Error(error) : error;
    Sentry.captureException(exception);
  },
  setShopId(id: number) {
    Sentry.configureScope(scope => scope.setUser({ id: `${id}` }));
  },
};
