import React, { useMemo, useCallback } from "react";
import { OrderData, ORDER_STATE_TYPE, ORDER_TIME_TYPE } from "records/OrderData";
import { ShopData } from "records/ShopData";
import { ApproveOrderButton } from "./ApproveOrderButton";
import { HoldOrderButton } from "./HoldOrderButton";
import { FindCrewForLocalAreaButton } from "./FindCrewForLocalAreaButton";
import { WaitingOtherShopButton } from "./WaitingOtherShopButton";
import { ReservedOrderButton } from "./ReservedOrderButton";
import { FinishOrderButton } from "./FinishOrderButton";
import { FindingCrewButton } from "./FindingCrewButton";
import { HoldOrderForGroceryButton } from "./HoldOrderForGroceryButton";
import { ApproveOrderForGroceryButton } from "./ApproveOrderForGroceryButton";

type Props = {
  orderData: OrderData;
  shopData: ShopData;
  submitting?: boolean;
  isAllChecked: boolean;
  draftTotalItemCounts: number;
  handleClickApproveOrderButton?: () => void;
  handleClickHoldOrderButton?: () => void;
  handleClickHoldOrderForGroceryButton?: () => void;
  handleClickFinishOrderButton?: () => void;
  handleClickFindCrewForLocalAreaButton?: () => void;
  handleClickApproveOrderForGroceryButton?: () => void;
};

export const OrderDetailButton: React.FC<Props> = React.memo(
  ({
    orderData,
    shopData,
    submitting,
    isAllChecked,
    draftTotalItemCounts,
    handleClickApproveOrderButton,
    handleClickHoldOrderButton,
    handleClickHoldOrderForGroceryButton,
    handleClickFinishOrderButton,
    handleClickFindCrewForLocalAreaButton,
    handleClickApproveOrderForGroceryButton,
  }) => {
    const disabledHoldOrderForGrocery = useMemo(
      () => ShopData.isAcceptingReservedOrder(shopData) && !orderData.cooking_start_able_flag,
      [shopData, orderData],
    );

    const disabledUpdateOrderForGrocery = useMemo(
      () => draftTotalItemCounts === 0 || !isAllChecked || !orderData.is_operable,
      [draftTotalItemCounts, isAllChecked, orderData],
    );

    const renderButton = useCallback(() => {
      switch (orderData.order_state) {
        case ORDER_STATE_TYPE.PENDING:
          return ShopData.isGrocery(shopData.shop_type) ? (
            <HoldOrderForGroceryButton
              submitting={submitting}
              disabled={disabledHoldOrderForGrocery}
              handleClick={handleClickHoldOrderForGroceryButton}
            />
          ) : ShopData.isRetail(shopData.shop_type) ? (
            <HoldOrderButton submitting={submitting} handleClick={handleClickHoldOrderButton} />
          ) : orderData.local_area_flag ? (
            <FindCrewForLocalAreaButton
              submitting={submitting}
              disabled={draftTotalItemCounts === 0}
              handleClick={handleClickFindCrewForLocalAreaButton}
            />
          ) : (
            <ApproveOrderButton
              submitting={submitting}
              disabled={draftTotalItemCounts === 0}
              handleClick={handleClickApproveOrderButton}
            />
          );
        case ORDER_STATE_TYPE.HOLD:
          return ShopData.isGrocery(shopData.shop_type) ? (
            <ApproveOrderForGroceryButton
              submitting={submitting}
              disabled={disabledUpdateOrderForGrocery}
              handleClick={handleClickApproveOrderForGroceryButton}
            />
          ) : orderData.local_area_flag ? (
            <FindCrewForLocalAreaButton
              submitting={submitting}
              disabled={draftTotalItemCounts === 0}
              handleClick={handleClickFindCrewForLocalAreaButton}
            />
          ) : (
            <ApproveOrderButton
              submitting={submitting}
              disabled={draftTotalItemCounts === 0}
              handleClick={handleClickApproveOrderButton}
            />
          );
        case ORDER_STATE_TYPE.ONE_STORE_APPROVE:
          return <WaitingOtherShopButton />;
        case ORDER_STATE_TYPE.APPROVE:
        case ORDER_STATE_TYPE.ALL_STORES_APPROVE:
          if (
            !orderData.cooking_start_able_flag &&
            orderData.time_type === ORDER_TIME_TYPE.SPECIFIED
          ) {
            return <ReservedOrderButton />;
          }
          return (
            <FinishOrderButton submitting={submitting} handleClick={handleClickFinishOrderButton} />
          );
        case ORDER_STATE_TYPE.LOCAL_AREA_SHOP_APPROVE:
          return <FindingCrewButton />;
        case ORDER_STATE_TYPE.LOCAL_AREA_DRIVER_APPROVE:
          return ShopData.isGrocery(shopData.shop_type) ? (
            <FinishOrderButton
              submitting={submitting}
              disabled={disabledUpdateOrderForGrocery}
              handleClick={handleClickFinishOrderButton}
            />
          ) : (
            <FinishOrderButton
              submitting={submitting}
              disabled={draftTotalItemCounts === 0}
              handleClick={handleClickFinishOrderButton}
            />
          );
        default:
          return undefined;
      }
    }, [
      orderData,
      shopData,
      submitting,
      disabledHoldOrderForGrocery,
      disabledUpdateOrderForGrocery,
      draftTotalItemCounts,
      handleClickApproveOrderButton,
      handleClickHoldOrderButton,
      handleClickHoldOrderForGroceryButton,
      handleClickFinishOrderButton,
      handleClickFindCrewForLocalAreaButton,
      handleClickApproveOrderForGroceryButton,
    ]);

    return <>{renderButton()}</>;
  },
);
