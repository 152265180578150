import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { colorsConst } from "styles/const";
import { fontSize } from "styles/mixins";

const Container = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  background-color: ${colorsConst.BUTTON.BACKGROUND.PRIMARY};
`;

const IconContainer = styled.div`
  ${fontSize.XLARGE};
  color: ${colorsConst.TEXT.WHITE};
`;

type Props = {
  style?: React.CSSProperties;
};

export const ArrowContainer: React.FC<Props> = React.memo(({ style }) => (
  <Container style={style}>
    <IconContainer>
      <FontAwesomeIcon icon={faAngleRight} />
    </IconContainer>
  </Container>
));
