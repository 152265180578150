import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { ShopItemParentCategory } from "records/ShopItemParentCategory";
import { DEFAULT_SELECTED_PARENT_CATEGORY_ID } from "modules/shop/model";
import { selectStyle } from "components/atoms/FormFields/SelectField";

const Container = styled.select`
  ${selectStyle};
`;

const renderOptions = (categories: ShopItemParentCategory[], includeAllOption: boolean) => {
  const options = categories.map(categoy => ({
    parent_category_id: categoy.parent_category_id,
    parent_category_name: categoy.parent_category_name,
  }));

  if (includeAllOption) {
    options.unshift({
      parent_category_id: DEFAULT_SELECTED_PARENT_CATEGORY_ID,
      parent_category_name: "全て",
    });
  }

  return (
    <>
      {options.map(option => (
        <option value={option.parent_category_id} key={option.parent_category_id}>
          {option.parent_category_name}
        </option>
      ))}
    </>
  );
};

type Props = {
  parentCategories: ShopItemParentCategory[];
  selectedParentCategoryId: number;
  setSelectedParentCategoryId: (value: React.SetStateAction<number>) => void;
  includeAllOption?: boolean;
};

export const ShopItemParentCategorySelect: React.FC<Props> = React.memo(
  ({
    parentCategories,
    selectedParentCategoryId,
    includeAllOption = false,
    setSelectedParentCategoryId,
  }) => {
    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLSelectElement>) => {
        const normalized = parseInt(event.target.value, 10);
        setSelectedParentCategoryId(normalized);
      },
      [setSelectedParentCategoryId],
    );

    return (
      <Container onChange={handleChange} value={selectedParentCategoryId}>
        {renderOptions(parentCategories, includeAllOption)}
      </Container>
    );
  },
);
